import { packagecontentConstants } from '../_constants';
import { packagecontentService } from '../_services';

export const packageContentActions = {
    getPackageContent,
    clearPackageContent
};

function getPackageContent(packageContent) {
    return dispatch => {
        dispatch(request({ packageContent }));
        packagecontentService.getPackageContent(packageContent)
            .then(
                packageContentObj => {
                    dispatch(success(packageContentObj));
                },
                error => {
                    dispatch(failure(error));
                }
        )
    };

    function request(packageContentObj) { return { type: packagecontentConstants.GET_PACKAGE_CONTENT_REQUEST, packageContentObj } }
    function success(packageContentObj) { return { type: packagecontentConstants.GET_PACKAGE_CONTENT_SUCCESS, packageContentObj } }
    function failure(error) { return { type: packagecontentConstants.GET_PACKAGE_CONTENT_FAILURE, error } }
}

function clearPackageContent() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('packageContentObj');
        }
    };

    function clear() { return { type: packagecontentConstants.GET_PACKAGE_CONTENT_CLEAR } }
}
