import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bookingActions } from '../../_actions';
import { useLocation, Link } from 'react-router-dom';

const BookingViewD = ({ t }) => {
    const dispatch = useDispatch()
    const bookingDObj = useSelector(state => state.bookingViewD.bookingDObj);
    const acc = useSelector(state => state.authentication.accInfo);

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();
    const id = query.get("id")

    useEffect(() => {
        if (id && acc != undefined) {
            const getBookDView = async () => {
                dispatch(bookingActions.requestBookingViewD(id, acc.CustId));
            }
            getBookDView()
        }
    }, [])

    return (
        <div className="container">
            <div className={`py-2 px-4`} style={{ display: 'flex', flexDirection: 'row' }}>
                <h1 className="font-bold text-xl">Booking View Detail</h1>
            </div>
            {bookingDObj &&
                <div className="justify-center mx-4 sm:flex flex-col text-xs sm:text-sm">
                    <div className="container mx-auto rounded-md shadow border my-2 pt-2">
                        <div className="row">
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>No Booking</label>
                            <label className="col-md-3">{bookingDObj.BookNo}</label>
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>No. Resi</label>
                            <label className="col-md-3">{bookingDObj.ResiNo}</label>
                        </div>
                        <div className="row">
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Kantor Asal</label>
                            <label className="col-md-3">{bookingDObj.OriginDestCode}</label>
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Tujuan</label>
                            <label className="col-md-3">{bookingDObj.Destination}</label>
                        </div>
                        <div className="row">
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Nama Pengirim</label>
                            <label className="col-md-3">{bookingDObj.CustName}</label>
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>No. Handphone</label>
                            <label className="col-md-3">{bookingDObj.CustMobilePhoneNo}</label>
                        </div>
                        <div className="row">
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Nama Penerima</label>
                            <label className="col-md-3">{bookingDObj.RecipientName}</label>
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Jalur</label>
                            <label className="col-md-3">{bookingDObj.TransportType}</label>
                        </div>
                    </div>

                    <div className="container mx-auto rounded-md shadow border my-2">
                        <div className="row rounded-sm flex flex-row bg-blue-300  px-3 py-1">
                            <label className="font-bold pt-1">
                                Info Barang
                            </label>
                        </div>
                        <div className="row pt-2">
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>No Barang</label>
                            <label className="col-md-3">{bookingDObj.ItemCodeNo}</label>
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Nama Barang</label>
                            <label className="col-md-3">{bookingDObj.ItemName}</label>
                        </div>
                        <div className="row">
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Berat Asli (Kg)</label>
                            <label className="col-md-3">{new Intl.NumberFormat("en", {
                                minimumFractionDigits: 2
                            }).format(bookingDObj.ItemWeight)}</label>
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Berat Final (Kg)</label>
                            <label className="col-md-3">{new Intl.NumberFormat("en", {
                                minimumFractionDigits: 2
                            }).format(bookingDObj.FinalWeight)}</label>
                        </div>
                        <div className="row">
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Berat Volume (Kg)</label>
                            <label className="col-md-3">{new Intl.NumberFormat("en", {
                                minimumFractionDigits: 2
                            }).format(bookingDObj.VolWeight)}</label>
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Panjang Dimensi</label>
                            <label className="col-md-3">{new Intl.NumberFormat("en", {
                                minimumFractionDigits: 0
                            }).format(bookingDObj.DimP)}</label>
                        </div>
                        <div className="row">
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Lebar Dimensi</label>
                            <label className="col-md-3">{new Intl.NumberFormat("en", {
                                minimumFractionDigits: 0
                            }).format(bookingDObj.DimL)}</label>
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Tinggi Dimensi</label>
                            <label className="col-md-3">{new Intl.NumberFormat("en", {
                                minimumFractionDigits: 0
                            }).format(bookingDObj.DimT)}</label>
                        </div>
                        <div className="row">
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Total Barang</label>
                            <label className="col-md-3">{new Intl.NumberFormat("en", {
                                minimumFractionDigits: 0
                            }).format(bookingDObj.Qty)}</label>
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Harga Barang</label>
                            <label className="col-md-3">{new Intl.NumberFormat("en", {
                                minimumFractionDigits: 0
                            }).format(bookingDObj.EstItemPriceAmt)}</label>
                        </div>
                        <div className="row">
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Catatan</label>
                            <label className="col-md-3">{bookingDObj.FinalWeightNotes}</label>
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Status</label>
                            <label className="col-md-3">{bookingDObj.CustBookDStat}</label>
                        </div>
                        <div className="row">
                            <label className="col-md-3" style={{ fontWeight: "bold" }}>Packing</label>
                            <label className="col-md-3">
                                {bookingDObj.IsPacking ? `Ya (${bookingDObj.PackingKayu ? "Kayu" : ""}${bookingDObj.PackingKayu && bookingDObj.BubbleWrap ? ", " : ""}${bookingDObj.BubbleWrap ? "Bubble Wrap" : ""}${(bookingDObj.PackingKayu || bookingDObj.BubbleWrap) && bookingDObj.Wrapping ? ", " : ""}${bookingDObj.Wrapping ? "Wrapping" : ""})` : "Tidak"}
                            </label>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
export default BookingViewD;