import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { Markup } from "interweave";
import { Link } from "react-router-dom";

const ComplaintMessagesTable = ({ isDashboard }) => {
  const complaintMessagesObj = useSelector(
    (state) => state.getComplaintMessages.getComplaintMessagesObj
  );

  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (
      complaintMessagesObj &&
      complaintMessagesObj.Result &&
      complaintMessagesObj.Result.length > 0
    ) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, [complaintMessagesObj]);

  return (
    <>
      <div className="hidden sm:block">
        {complaintMessagesObj && complaintMessagesObj.Result && (
          <div className="text-xs sm:text-sm">
            <table className="table table-striped bg-white" aria-labelledby="tabelLabel">
              <thead>
                <tr>
                  <th className="text-center">Tanggal Input</th>
                  {isDashboard && (
                    <>
                      <th className="text-center">No Resi</th>
                      <th className="text-center">Tanggal Transaksi</th>
                    </>
                  )}
                  <th>Catatan Awal</th>
                  <th>Catatan Follow Up</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {complaintMessagesObj.Result.map((cm) => (
                  <tr key={cm.InputDt}>
                    <td align="center">
                      {moment(cm.InputDt).format("DD-MM-YY HH:mm")}
                    </td>
                    {isDashboard && (
                      <>
                        <td align="center">
                          <Link
                            target={"_blank"}
                            to={{
                              pathname: `/ResiHView?resiHNo=${cm.RefNo}`,
                            }}
                          >
                            {cm.RefNo}
                          </Link>
                        </td>
                        <td align="center">
                          {moment(cm.ValueDt).format("DD-MM-YY HH:mm")}
                        </td>
                      </>
                    )}
                    <td>{cm.Notes}</td>
                    <td>
                      <Markup content={cm.FollowUpNotes} />
                    </td>
                    <td>{cm.MsgStat}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="block sm:hidden">
        <div className="rounded px-3">
          {complaintMessagesObj &&
            complaintMessagesObj.Result &&
            complaintMessagesObj.Result.length > 0 &&
            complaintMessagesObj.Result.map((cm) => (
              <div
                className="h-full w-full bg-white shadow flex rounded items-center my-2"
                key={cm.InputDt}
              >
                <div className="relative h-full w-full flex items-center">
                  <div className="text-xs w-full pb-1">
                    <div className="text-sm font-bold pt-2 flex px-3">
                      <div className="flex flex-1">
                        {moment(cm.InputDt).format("DD-MM-YY HH:mm")}
                      </div>
                      <div className="flex flex-1 justify-end mr-1">
                        <div className="flex items-center">
                          <div
                            className={`w-2.5 h-2.5 rounded-full ${cm.MsgStat === "Selesai"
                                ? "bg-green-600"
                                : "bg-blue-600"
                              }`}
                          ></div>
                        </div>
                        <p
                          className={`flex items-center font-semibold text-xs sm:text-sm ml-1 ${cm.MsgStat === "Selesai"
                              ? "text-green-600"
                              : "text-blue-600"
                            }`}
                        >
                          {cm.MsgStat}
                        </p>
                      </div>
                    </div>
                    <div className="flex px-3">
                      <label>
                        <b>Notes: </b>
                        {cm.Notes}
                      </label>
                    </div>
                    <hr className="pb-2" />
                    <div className="flex text-blue-500 px-3">
                      {cm.FollowUpNotes == null ? (
                        "Tidak ada follow up notes"
                      ) : (
                        <Markup content={cm.FollowUpNotes} />
                      )}
                    </div>
                  </div>
                  <span
                    className={`absolute right-0 h-full rounded-r w-3 ${cm.MsgStat === "Selesai" ? "bg-green-600" : "bg-blue-600"
                      }`}
                  ></span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ComplaintMessagesTable;
