import { uploadConstants } from "../_constants";

let uploadMonitoringObj = JSON.parse(
  localStorage.getItem("uploadMonitoring")
);
const initialStateMonitoring = uploadMonitoringObj
  ? {
      loggedIn: true,
      uploadMonitoring: uploadMonitoringObj.uploadMonitoring,
    }
  : {};

export function uploadMonitoring(state = initialStateMonitoring, action) {
  switch (action.type) {
    case uploadConstants.UPLOAD_MONITORING_REQUEST:
      return {
        uploadMonitoring: action.uploadMonitoring.uploadMonitoring,
      };
    case uploadConstants.UPLOAD_MONITORING_SUCCESS:
      return {
        uploadMonitoring: action.uploadMonitoring.uploadMonitoring,
      };
    case uploadConstants.UPLOAD_MONITORING_FAILURE:
      return { errPengirimanMonitoring: action.error };
    default:
      return state;
  }
}

let uploadDetailObj = JSON.parse(localStorage.getItem("uploadDetail"));
const initialStateDetail = uploadDetailObj
  ? { loggedIn: true, uploadDetail: uploadDetailObj.uploadDetail }
  : {};
export function uploadDetail(state = initialStateDetail, action) {
  switch (action.type) {
    case uploadConstants.UPLOAD_DETAIL_REQUEST:
      return {
        uploadDetail: action.uploadDetail.uploadDetail,
      };
    case uploadConstants.UPLOAD_DETAIL_SUCCESS:
      return {
        uploadDetail: action.uploadDetail.uploadDetail,
      };
    case uploadConstants.UPLOAD_DETAIL_FAILURE:
      return {};
    default:
      return state;
  }
}
