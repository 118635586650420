import React from 'react';

const SCFileBase64 = ({ setFiles, multiple, inputRef }) => {

    function handleChange(e) {

        // get the files
        let files = e.target.files;

        // Process each file
        var allFiles = [];
        for (var i = 0; i < files.length; i++) {

            let file = files[i];

            console.log("MASUK HANDLE CHANGE")
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {

                // Make a fileInfo Object
                let fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result.replace(file.type, "").replace("data:;base64,",""),
                    file: file,
                };

                // Push it to the state
                allFiles.push(fileInfo);

                // If all files have been proceed
                if (allFiles.length == files.length) {
                    // Apply Callback function
                    if (multiple) setFiles(allFiles);
                    else setFiles(allFiles[0]);
                }

            } // reader.onload

        } // for

    }

    return (
        <input
            ref={inputRef}
            type="file"
            accept=".xls, .xlsx"
            onChange={(e) => handleChange(e)}
            multiple={multiple} />
    );
}


export default SCFileBase64;