import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { FaRegCopy } from "react-icons/fa";

const PickupReqDetailTable = ({ sortConfig, setSortConfig }) => {
  const pickupReqDetail = useSelector(
    (state) => state.pickupReqDetail.pickupReqDetail
  );
  function sort(key) {
    let direction = "ASC";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ASC"
    ) {
      direction = "DESC";
    }
    setSortConfig({ key, direction });
  }

  function copyToClipboard(e, resiNo) {
    e.stopPropagation();
    navigator.clipboard.writeText(resiNo);
  }

  return (
    <div className="text-xs sm:text-sm">
      {pickupReqDetail &&
        pickupReqDetail.Result &&
        pickupReqDetail.Result.length == 0 && (
          <div className="justify-center flex flex-row">
            <p>Tidak ada request pickup</p>
          </div>
        )}
      {pickupReqDetail &&
        pickupReqDetail.Result != null &&
        pickupReqDetail.Result.length > 0 && (
          <>
            <div className="hidden sm:block text-xs sm:text-sm mx-auto">
              <table
                className="table table-striped table-fixed bg-white"
                aria-labelledby="tabelLabel"
              >
                <thead>
                  <tr className="font-bold">
                    <td className="text-center">Resi No</td>
                    <td>Contact Person</td>
                    <td>Address</td>
                    <td className="text-right">Total Item</td>
                    <td className="text-right">Est. Weight</td>
                    <td className="text-center">Est. Pickup Time</td>
                    <td>Status Pickup Request</td>
                    <td>Cancel By</td>
                    <td>Cancel Notes</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {pickupReqDetail.Result.map((prd) => (
                    <tr key={prd.PickupReqId}>
                      <td align="center">{prd.BookResiNo}</td>
                      <td>{`${prd.ContactPerson}, ${prd.PhoneNo}`}</td>
                      <td>{prd.Addr}</td>
                      <td align="right">{prd.TotalItem}</td>
                      <td align="right">
                        {prd.EstWeight.toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}
                      </td>
                      <td align="center">
                        {moment(prd.PickupTime).format("DD-MM-YY HH:mm")}
                      </td>
                      <td>
                        {prd.PickupReqStat === "NEW"
                          ? "Baru"
                          : prd.PickupReqStat === "DONE"
                          ? "Selesai"
                          : prd.PickupReqStat === "CAN"
                          ? "Cancel"
                          : prd.PickupReqStat === "FAILED"
                          ? "Gagal"
                          : "On Process"}
                      </td>
                      <td>
                        {prd.PickupReqStat == "CAN" && prd.CancelBy != null
                          ? prd.CancelBy
                          : "-"}
                      </td>
                      <td>
                        {prd.PickupReqStat == "CAN" && prd.CancelNotes != null
                          ? prd.CancelNotes
                          : "-"}
                      </td>
                      <td align="center">
                        <Link
                          target={"_blank"}
                          to={{
                            pathname: `/PickupRequestView?pickupReqId=${prd.PickupReqId}`,
                          }}
                        >
                          Detail
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="block sm:hidden">
              <div className="rounded px-3">
                {pickupReqDetail.Result.map((prd) => (
                  <div
                    className="h-full w-full bg-white shadow flex rounded items-center my-2"
                    key={prd.PickupReqId}
                  >
                    <div className="relative h-full w-full flex items-center">
                      <div className="text-sm font-medium w-full pb-1">
                        <div className="pt-2 flex px-3">
                          <div className="flex flex-1">
                            <Link
                              target={"_blank"}
                              to={{
                                pathname: `/PickupRequestView?pickupReqId=${prd.PickupReqId}`,
                              }}
                              style={{ color: "#000000" }}
                            >
                              {`${prd.BookResiNo} `}
                            </Link>
                            <div className="flex items-center">
                              <FaRegCopy
                                onClick={(e) =>
                                  copyToClipboard(e, prd.BookResiNo)
                                }
                                style={{ display: "inline-block" }}
                              />
                            </div>
                          </div>
                          <div className="flex flex-1 justify-end mr-1">
                            <div className="flex items-center">
                              <div
                                className={`w-2.5 h-2.5 rounded-full ${
                                  prd.PickupReqStat === "NEW"
                                    ? "bg-blue-600"
                                    : prd.PickupReqStat === "DONE"
                                    ? "bg-green-600"
                                    : prd.PickupReqStat === "CAN"
                                    ? "bg-red-600"
                                    : prd.PickupReqStat === "FAILED"
                                    ? "bg-red-600"
                                    : "bg-yellow-600"
                                }`}
                              ></div>
                            </div>
                            <p
                              className={`flex items-center font-semibold text-xs sm:text-sm ml-1 ${
                                prd.PickupReqStat === "NEW"
                                  ? "text-blue-600"
                                  : prd.PickupReqStat === "DONE"
                                  ? "text-green-600"
                                  : prd.PickupReqStat === "CAN"
                                  ? "text-red-600"
                                  : prd.PickupReqStat === "FAILED"
                                  ? "text-red-600"
                                  : "text-yellow-600"
                              }`}
                            >
                              {prd.PickupReqStat === "NEW"
                                ? "Baru"
                                : prd.PickupReqStat === "DONE"
                                ? "Selesai"
                                : prd.PickupReqStat === "CAN"
                                ? "Cancel"
                                : prd.PickupReqStat === "FAILED"
                                ? "Gagal"
                                : "On Process"}
                            </p>
                          </div>
                        </div>
                        <div className="flex text-red-500 px-3">
                          <div className="flex-1 text-xs sm:text-sm flex items-end">
                            {prd.ContactPerson}
                          </div>
                        </div>
                        <div className="flex text-red-500 px-3">
                          <div className="flex-1 text-xs sm:text-sm flex items-end">
                            {prd.PhoneNo}
                          </div>
                        </div>
                        <div className="flex text-red-500 px-3">
                          <div className="flex-1 text-xs sm:text-sm flex items-end">
                            {prd.Addr}
                          </div>
                        </div>
                        <div className="flex px-3">
                          <div className="flex-1 text-xs sm:text-sm flex items-end">
                            {`${prd.TotalItem.toLocaleString(
                              "en",
                              {
                                minimumFractionDigits: 0,
                              }
                            )}
                            Koli,
                            ${prd.EstWeight.toLocaleString("en", {
                              minimumFractionDigits: 2,
                            })}
                            Kg, ${prd.PaymentType}`}
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm mr-2">
                            {`Estimasi : 
                            ${moment(prd.PickupTime).format(
                              "DD MMM YYYY HH:mm"
                            )}`}
                          </div>
                        </div>
                      </div>
                      <span
                        className={`absolute right-0 h-full rounded-r w-3 ${
                          prd.PickupReqStat === "NEW"
                            ? "bg-blue-600"
                            : prd.PickupReqStat === "DONE"
                            ? "bg-green-600"
                            : prd.PickupReqStat === "CAN"
                            ? "bg-red-600"
                            : prd.PickupReqStat === "FAILED"
                            ? "bg-red-600"
                            : "bg-yellow-600"
                        }`}
                      ></span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default PickupReqDetailTable;
