import { accConstants } from '../_constants';

let menus = JSON.parse(localStorage.getItem('menu'));
const initialState = menus ? { loading: true, accMenus: menus.value } : {};

export function accounts(state = initialState, action) {
    switch (action.type) {
        default:
            return state
    }
}