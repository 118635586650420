import { pickupConstants } from "../_constants";

let pickupAddrObj = JSON.parse(localStorage.getItem("pickupAddrObj"));
const initialState = pickupAddrObj
    ? { loggedIn: true, pickupAddrObj: pickupAddrObj.pickupAddrObj }
    : {};

export function pickupAddr(state = initialState, action) {
    switch (action.type) {
        case pickupConstants.GET_PICKUP_ADDR_REQUEST:
            return {
                pickupAddrObj: action.pickupAddrObj.pickupAddrObj,
            };
        case pickupConstants.GET_PICKUP_ADDR_SUCCESS:
            return {
                pickupAddrObj: action.pickupAddrObj.pickupAddrObj,
            };
        case pickupConstants.GET_PICKUP_ADDR_FAILURE:
            return { errPickupAddrObj: action.error };
        case pickupConstants.GET_PICKUP_ADDR_CLEAR:
            return {};
        default:
            return state;
    }
}

let pickupMonitoringObj = JSON.parse(localStorage.getItem("pickupMonitoring"));
const initialStateMonitoring = pickupMonitoringObj
    ? { loggedIn: true, pickupMonitoring: pickupMonitoringObj.pickupMonitoring }
    : {};
export function pickupMonitoring(state = initialStateMonitoring, action) {
    switch (action.type) {
        case pickupConstants.PICKUP_MONITORING_REQUEST:
            return {
                pickupMonitoring: action.pickupMonitoring.pickupMonitoring,
            };
        case pickupConstants.PICKUP_MONITORING_SUCCESS:
            return {
                pickupMonitoring: action.pickupMonitoring.pickupMonitoring,
            };
        case pickupConstants.PICKUP_MONITORING_FAILURE:
            return {};
        default:
            return state;
    }
}

let pickupOrderHInfoObj = JSON.parse(localStorage.getItem("pickupOrderHInfo"));
const initialStatePickupOrderHInfo = pickupOrderHInfoObj
    ? { loggedIn: true, pickupOrderHInfo: pickupOrderHInfoObj.pickupOrderHInfo }
    : {};
export function pickupOrderHInfo(state = initialStatePickupOrderHInfo, action) {
    switch (action.type) {
        case pickupConstants.PICKUP_ORDER_H_INFO_REQUEST:
            return {
                pickupOrderHInfo: action.pickupOrderHInfo.pickupOrderHInfo,
            };
        case pickupConstants.PICKUP_ORDER_H_INFO_SUCCESS:
            return {
                pickupOrderHInfo: action.pickupOrderHInfo.pickupOrderHInfo,
            };
        case pickupConstants.PICKUP_ORDER_H_INFO_FAILURE:
            return {};
        default:
            return state;
    }
}

let pickupOrderDetailObj = JSON.parse(
    localStorage.getItem("pickupOrderDetail")
);
const initialStatePickupOrderDetail = pickupOrderDetailObj
    ? { loggedIn: true, pickupMonitoring: pickupOrderDetailObj.pickupMonitoring }
    : {};
export function pickupOrderDetail(
    state = initialStatePickupOrderDetail,
    action
) {
    switch (action.type) {
        case pickupConstants.PICKUP_ORDER_DETAIL_REQUEST:
            return {
                pickupOrderDetail: action.pickupOrderDetail.pickupOrderDetail,
            };
        case pickupConstants.PICKUP_ORDER_DETAIL_SUCCESS:
            return {
                pickupOrderDetail: action.pickupOrderDetail.pickupOrderDetail,
            };
        case pickupConstants.PICKUP_ORDER_DETAIL_FAILURE:
            return {};
        default:
            return state;
    }
}

let pickupReqMonitoringObj = JSON.parse(
    localStorage.getItem("pickupReqMonitoringObj")
);
const initialStateReqMonitoring = pickupReqMonitoringObj
    ? {
        loggedIn: true,
        pickupReqMonitoringObj: pickupReqMonitoringObj.pickupReqMonitoringObj,
    }
    : {};
export function pickupReqMonitoring(state = initialStateReqMonitoring, action) {
    switch (action.type) {
        case pickupConstants.PICKUP_REQ_MONITORING_REQUEST:
            return {
                pickupReqMonitoringObj:
                    action.pickupReqMonitoringObj.pickupReqMonitoringObj,
            };
        case pickupConstants.PICKUP_REQ_MONITORING_SUCCESS:
            return {
                pickupReqMonitoringObj:
                    action.pickupReqMonitoringObj.pickupReqMonitoringObj,
            };
        case pickupConstants.PICKUP_REQ_MONITORING_FAILURE:
            return { errPickupReqMonitoringObj: action.error };
        case pickupConstants.PICKUP_REQ_MONITORING_CLEAR:
            return {};
        default:
            return state;
    }
}

let pickupReqDetailObj = JSON.parse(localStorage.getItem("pickupReqDetail"));
const initialStateReqDetail = pickupReqDetailObj
    ? { loggedIn: true, pickupReqDetailObj: pickupReqDetailObj.pickupReqDetail }
    : {};
export function pickupReqDetail(state = initialStateReqDetail, action) {
    switch (action.type) {
        case pickupConstants.PICKUP_REQ_DETAIL_REQUEST:
            return {
                pickupReqDetail: action.pickupReqDetail.pickupReqDetail,
            };
        case pickupConstants.PICKUP_REQ_DETAIL_SUCCESS:
            return {
                pickupReqDetail: action.pickupReqDetail.pickupReqDetail,
            };
        case pickupConstants.PICKUP_REQ_DETAIL_FAILURE:
            return { errPickupReqDetail: action.error };
        default:
            return state;
    }
}


let pickupReqGrpObj = JSON.parse(
    localStorage.getItem("pickupReqGrpObj")
);
const initialStateReqPickupReqGrpAddEdit = pickupReqGrpObj
    ? {
        loggedIn: true,
        pickupReqGrpObj: pickupReqGrpObj.pickupReqGrpObj,
    }
    : {};
export function pickupReqGrpAddEdit(state = initialStateReqPickupReqGrpAddEdit, action) {
    switch (action.type) {
        case pickupConstants.PICKUP_REQ_GRP_ADD_EDIT_REQUEST:
            return {
                pickupReqGrpObj:
                    action.pickupReqGrpObj.pickupReqGrpObj,
            };
        case pickupConstants.PICKUP_REQ_GRP_ADD_EDIT_SUCCESS:
            return {
                pickupReqGrpObj:
                    action.pickupReqGrpObj.pickupReqGrpObj,
            };
        case pickupConstants.PICKUP_REQ_GRP_ADD_EDIT_FAILURE:
            return { errPickupReqGrpObj: action.error };
        case pickupConstants.PICKUP_REQ_GRP_ADD_EDIT_CLEAR:
            return {};
        default:
            return state;
    }
}

let pickupAddrByIdObj = JSON.parse(localStorage.getItem("pickupAddrByIdObj"));
const initialStateGetAddrById = pickupAddrByIdObj
    ? { loggedIn: true, pickupAddrByIdObj: pickupAddrByIdObj.pickupAddrByIdObj }
    : {};

export function pickupAddrById(state = initialStateGetAddrById, action) {
    switch (action.type) {
        case pickupConstants.GET_PICKUP_ADDR_BY_ID_REQUEST:
            return {
                pickupAddrByIdObj: action.pickupAddrByIdObj.pickupAddrByIdObj,
            };
        case pickupConstants.GET_PICKUP_ADDR_BY_ID_SUCCESS:
            return {
                pickupAddrByIdObj: action.pickupAddrByIdObj.pickupAddrByIdObj,
            };
        case pickupConstants.GET_PICKUP_ADDR_BY_ID_FAILURE:
            return { errPickupAddrByIdObj: action.error };
        case pickupConstants.GET_PICKUP_ADDR_BY_ID_CLEAR:
            return {};
        default:
            return state;
    }
}

let pickupAddrListObj = JSON.parse(localStorage.getItem("pickupAddrListObj"));
const initialStatePickupAddrListObj = pickupAddrListObj
    ? { loggedIn: true, pickupAddrListObj: pickupAddrListObj.pickupAddrListObj }
    : {};
export function pickupAddrList(state = initialStatePickupAddrListObj, action) {
    switch (action.type) {
        case pickupConstants.GET_LIST_PICKUP_ADDR_REQUEST:
            return {
                pickupAddrListObj: action.pickupAddrListObj.pickupAddrListObj,
            };
        case pickupConstants.GET_LIST_PICKUP_ADDR_SUCCESS:
            return {
                pickupAddrListObj: action.pickupAddrListObj.pickupAddrListObj,
            };
        case pickupConstants.GET_LIST_PICKUP_ADDR_FAILURE:
            return { errPickupAddrListObj: action.error };
        case pickupConstants.GET_LIST_PICKUP_ADDR_CLEAR:
            return {};
        default:
            return state;
    }
}

let selectedPickupAddrObj = JSON.parse(localStorage.getItem("selectedPickupAddrObj"));
const initialStateSelectedPickupAddrObj = selectedPickupAddrObj
    ? { loggedIn: true, selectedPickupAddrObj: selectedPickupAddrObj }
    : {};
export function selectedPickupAddr(state = initialStateSelectedPickupAddrObj, action) {
    
    switch (action.type) {
        case pickupConstants.SELECT_PICKUP_ADDR_SUCCESS:
        
            return {
                selectedPickupAddrObj: action.selectedPickupAddrObj,
            };
        case pickupConstants.SELECT_PICKUP_ADDR_CLEAR:
            return {};
        default:
            return state;
    }
}
