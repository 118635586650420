import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

const PickupOrderDetailTable = ({ t }) => {
  const pickupOrderDetail = useSelector(
    (state) => state.pickupOrderDetail.pickupOrderDetail
  );

  return (
    <div className="text-xs sm:text-sm">
      <table
        className="table table-fixed table-striped bg-white"
        aria-labelledby="tabelLabel"
      >
        <thead>
          <tr>
            <th>Pickup Req No</th>
            <th>Partner Reference</th>
            <th>Sumber</th>
            <th>Requestor</th>
            <th>Request</th>
            <th>Request Pickup</th>
            <th>Check In</th>
            <th>Check Out</th>
            <th>Perkiraan Berat</th>
            <th>Jumlah Barang</th>
            <th>Catatan</th>
            <th>Status</th>
            <th>Detail</th>
          </tr>
        </thead>
        <tbody>
          {pickupOrderDetail &&
            pickupOrderDetail.pickupOrderDs.map((pod) => (
              <tr key={pod.PickupReqId}>
                <td>{pod.PickupReqNo}</td>
                <td>{pod.PrtRef}</td>
                <td>{pod.PickupReqSrc}</td>
                <td>{`${pod.RequestorType} [${pod.Requestor}]`}</td>
                <td>{moment(pod.ReqDt).format("DD MMM YY - HH:mm")}</td>
                <td>{moment(pod.PickupTime).format("DD MMM YY - HH:mm")}</td>
                <td>
                  {pod.CheckInDt != null
                    ? moment(pod.CheckInDt).format("DD MMM YY - HH:mm")
                    : ""}
                </td>
                <td>
                  {pod.CheckOutDt != null
                    ? moment(pod.CheckOutDt).format("DD MMM YY - HH:mm")
                    : ""}
                </td>
                <td>
                  {pod.EstWeight.toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td>{pod.TotalItem}</td>
                <td>{pod.PickupNotes}</td>
                <td>{pod.PickupOrderDStat}</td>
                <td className="text-sky-600">
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/PickupRequestView?pickupReqId=${pod.PickupReqId}`,
                    }}
                  >
                    Detail
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default PickupOrderDetailTable;
