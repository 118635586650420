import React, { useState, useEffect } from "react";
import { profileActions } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
import { AlertMessage } from '../../components';

const QRCodeMember = ({ t }) => {
    const acc = useSelector((state) => state.authentication.accInfo);
    const dispatch = useDispatch();
    const getQrCodeMemberObj = useSelector((state) => state.getQrCodeMember.getQrCodeMemberObj);
    const sendEmailQrCodeCustObj = useSelector((state) => state.sendEmailQrCodeCust.sendEmailQrCodeCustObj);
    const [alert, setAlert] = useState({
        Type: "",
        StatusMessage: "",
    });
    const [isError, setIsError] = useState(false);
    const errSendEmailQrCodeCustObj = useSelector(
        (state) => state.sendEmailQrCodeCust.errSendEmailQrCodeCustObj
    );

    useEffect(() => {
        setAlert({
            Type: "",
            StatusMessage: "",
        });
        dispatch(profileActions.clearQrCodeMember());
        if (acc != undefined) {
            const qrCode = async () => {
                dispatch(profileActions.getQrCodeMember(acc.CustId));
            }
            qrCode()
        }
    }, []);

    function sendEmailQrCodeCust() {
        if (acc && acc.CustId) {
            const sendEmailQrCodeCustObj = {
                base64: getQrCodeMemberObj,
                CustId: acc.CustId
            };
            dispatch(profileActions.sendEmailQrCodeCust(sendEmailQrCodeCustObj));
            setIsError(true);
            setAlert({ Type: "Info", StatusMessage: "Berhasil kirim email" });
        }
    }

    useEffect(() => {
        if (
            errSendEmailQrCodeCustObj &&
            errSendEmailQrCodeCustObj.StatusCode !== "200"
        ) {
            setIsError(true);
            setAlert({
                Type: "Error",
                StatusMessage: errSendEmailQrCodeCustObj.StatusMessage,
            });
        }
    }, [errSendEmailQrCodeCustObj]);

    useEffect(() => {
        dispatch(profileActions.getProfileData(acc.CustId));
    }, []);

    function downloadPDF(getQrCodeMemberObj) {
        console.log('Masuk Download Pdf')
        const linkSource = `data:image/png;base64,${getQrCodeMemberObj}`;
        const downloadLink = document.createElement("a");
        const fileName = "MemberQR_" + acc.CustId + ".png";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        dispatch(profileActions.clearQrCodeMember());
        window.close();
    }

    return (
        <>
            <div className="hidden sm:block text-sm">
                {
                    isError == true &&
                    <AlertMessage isError={isError} alert={alert} setAlert={setAlert} setIsError={setIsError} />
                }
                <div className="flex justify-center items-center mt-8">
                    {
                        getQrCodeMemberObj && getQrCodeMemberObj != '' && getQrCodeMemberObj != null &&
                        <>
                            <embed src={`data:image/png;base64, ${getQrCodeMemberObj}`} style={{ width: 337, height: 502 }} />
                        </>
                    }
                </div>
                <div className="flex flex-col justify-center items-center">
                    <div>
                        <b className="flex justify-center">Silahkan simpan QR Code ini dengan aman.</b>
                        <p>Kegunaan QR Code :</p>
                        <p>1. Mengenali customer member Sentral Cargo</p>
                        <p>2. Otorisasi validasi jenis pembayaran kredit.</p>
                        <p>3. Tidak berlaku di semua Sentral Cargo Point.</p>
                        <p>Notes : Tunjukan QR Code Member, setiap kali transaksi pengiriman Walk In</p>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <button
                        type="button"
                        onClick={(e) => downloadPDF(getQrCodeMemberObj)}
                        className="w-25 mx-2 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                    >
                        Download
                    </button>
                    <button
                        type="button"
                        onClick={(e) => sendEmailQrCodeCust()}
                        className="w-25 mx-2 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                    >
                        Kirim ke Email
                    </button>
                </div>
            </div>
            <div className="block sm:hidden text-xs mx-2">
                {
                    isError == true &&
                    <AlertMessage isError={isError} alert={alert} setAlert={setAlert} setIsError={setIsError} />
                }
                <div className="flex justify-center items-center mt-2">
                    {
                        getQrCodeMemberObj && getQrCodeMemberObj != '' && getQrCodeMemberObj != null &&
                        <>
                            <embed src={`data:image/png;base64, ${getQrCodeMemberObj}`} style={{ width: 337, height: 502 }} />
                        </>
                    }
                </div>
                <div className="flex flex-col justify-center items-center">
                    <div>
                        <b className="flex justify-center">Silahkan simpan QR Code ini dengan aman.</b>
                        <p>Kegunaan QR Code :</p>
                        <p>1. Mengenali customer member Sentral Cargo</p>
                        <p>2. Otorisasi validasi jenis pembayaran kredit.</p>
                        <p>3. Tidak berlaku di semua Sentral Cargo Point.</p>
                        <p>Notes : Tunjukan QR Code Member, setiap kali transaksi pengiriman Walk In</p>
                    </div>
                </div>
                <div>
                    <button
                        type="button"
                        onClick={(e) => downloadPDF(getQrCodeMemberObj)}
                        className="mx-3 w-40 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                    >
                        Download
                    </button>
                    <button
                        type="button"
                        onClick={(e) => sendEmailQrCodeCust()}
                        className="mx-3 w-40 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                    >
                        Kirim ke Email
                    </button>
                </div>
            </div>
        </>
    );
}

export default QRCodeMember;