import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { FaRegCopy } from "react-icons/fa";
import { CircularProgress } from "@mui/material";

const PenerimaanMonitoringTable = ({ t }) => {
  const { penerimaanMonitoring, isLoading } = useSelector(
    (state) => state.penerimaanMonitoring
  );

  function openInNewTab(e, url) {
    e.preventDefault();
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }

  function copyToClipboard(e, resiNo) {
    e.stopPropagation();
    navigator.clipboard.writeText(resiNo);
  }

  let context = null;
  if (isLoading) {
    context = (
      <div className="justify-center text-center mb-4">
        <CircularProgress />
      </div>
    );
  } else if (
    penerimaanMonitoring &&
    penerimaanMonitoring.Result &&
    penerimaanMonitoring.Result.length > 0
  ) {
    context = (
      <>
        <div className="hidden sm:block mx-auto">
          <table
            className="table table-auto table-striped bg-white"
            aria-labelledby="tabelLabel"
          >
            <thead>
              <tr>
                <th className="text-center">Tanggal Resi</th>
                <th>No Resi</th>
                <th>Pengirim</th>
                <th>Asal</th>
                <th>Jenis Pembayaran</th>
                <th className="text-right">Koli</th>
                <th className="text-right">Berat</th>
                <th className="text-right">Tagihan</th>
                <th className="text-center">Tanggal</th>
                <th>Tracking</th>
                <th>Komplain</th>
                <th className="text-center">Waktu Sampai</th>
                <th>Detail</th>
              </tr>
            </thead>
            <tbody>
              {penerimaanMonitoring.Result.map((pm) => (
                <tr key={pm.valuedt}>
                  <td align="center">
                    {moment(pm.valuedt).format("DD-MM-YY HH:mm")}
                  </td>
                  <td>
                    <Link
                      target={"_blank"}
                      to={{
                        pathname: `/ResiHView?resiHId=${pm.resihid}`,
                      }}
                    >
                      {pm.resino}
                    </Link>
                  </td>
                  <td>{pm.custname}</td>
                  <td>{pm.DestName}</td>
                  <td>{pm.paymenttype}</td>
                  <td align="right">
                    {pm.totalitem.toLocaleString("en", {
                      minimumFractionDigits: 0,
                    })}
                  </td>
                  <td align="right">
                    {pm.finaltotalweight.toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td align="right">{pm.finalAccReceivableAmt}</td>
                  <td align="center">
                    {pm.podDt && moment(pm.podDt).format("DD-MM-YY HH:mm")}
                  </td>
                  <td>{pm.tracknotes}</td>
                  <td className="text-sky-600">
                    <Link
                      target={"_blank"}
                      to={{
                        pathname: `/ResiComplaintForm?resiHId=${pm.resihid}&commLinkSrc=RCPN`,
                      }}
                    >
                      Komplain
                    </Link>
                  </td>
                  <td align="center">
                    <div
                      className={
                        pm.PodRating === "GOOD"
                          ? "circle-green"
                          : pm.PodRating === "STANDARD"
                            ? "circle-yellow"
                            : pm.PodRating === "SLOW"
                              ? "circle-red"
                              : "circle-black"
                      }
                    ></div>
                  </td>
                  <td className="text-sky-600">
                    <Link
                      target={"_blank"}
                      to={{
                        pathname: `/ResiHView?resiHId=${pm.resihid}`,
                      }}
                    >
                      Detail
                    </Link>
                  </td>
                </tr>
              ))}
              <tr className="font-bold">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td align="right">
                  {_.sum(_.map(penerimaanMonitoring, (pm) => pm.totalitem))}
                </td>
                <td align="right">
                  {_.sum(
                    _.map(penerimaanMonitoring, (pm) => pm.finaltotalweight)
                  )}
                </td>
                <td align="right">
                  {_.sum(
                    _.map(
                      penerimaanMonitoring,
                      (pm) => pm.finalAccReceivableAmt
                    )
                  )}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="block sm:hidden w-full">
          <div className="rounded text-xs px-3">
            {penerimaanMonitoring.Result.map((pm) => (
              <div
                className="h-full w-full bg-white shadow flex rounded items-center my-2"
                key={pm.resihid}
                onClick={(e) =>
                  openInNewTab(e, `/ResiHView?resiHId=${pm.resihid}`)
                }
              >
                <div className="relative h-full w-full flex items-center">
                  <div className="text-xs w-full pb-1">
                    <div className="pt-2 flex px-3">
                      <div className="font-medium">
                        {pm.resino}
                        <FaRegCopy
                          onClick={(e) => copyToClipboard(e, pm.resino)}
                          style={{ display: "inline-block" }}
                        />
                      </div>
                      <div className="flex flex-1 justify-end mr-1">
                        <div className="flex items-center">
                          <div
                            className={`w-2.5 h-2.5 rounded-full ${pm.PodRating === "GOOD"
                                ? "bg-green-600"
                                : pm.PodRating === "STANDARD"
                                  ? "bg-yellow-600"
                                  : pm.PodRating === "SLOW"
                                    ? "bg-red-600"
                                    : "bg-blue-600"
                              }`}
                          ></div>
                        </div>
                        {pm.PodRating === "GOOD" ? (
                          <p className="flex items-center font-semibold ml-1 text-green-600">
                            Cepat
                          </p>
                        ) : pm.PodRating === "STANDARD" ? (
                          <p className="flex items-center font-semibold ml-1 text-yellow-600">
                            Standar
                          </p>
                        ) : pm.PodRating === "SLOW" ? (
                          <p className="flex items-center font-semibold ml-1 text-red-600">
                            Lambat
                          </p>
                        ) : (
                          <p className="flex items-center font-semibold ml-1 text-blue-600">
                            On Process
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex px-3">
                      <div className="text-red-600">{`${pm.DestName} - ${pm.custname}`}</div>
                    </div>
                    <div className="flex px-3">
                      {`${pm.totalitem
                        } Koli, ${pm.finaltotalweight.toLocaleString(
                          "en",
                          { minimumFractionDigits: 2 }
                        )} Kg`}
                      <div className="flex flex-1 font-medium justify-end mr-1">
                        {`Kredit: Rp.${pm.finalAccReceivableAmt.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}`}
                      </div>
                    </div>
                    <hr />
                    <div className="flex px-3 text-blue-600">
                      {`${pm.podDt && moment(pm.podDt).format("DD-MM-YY HH:mm")
                        } - ${pm.tracknotes}`}
                    </div>
                  </div>
                  <span
                    className={`absolute right-0 h-full rounded-r w-3 ${pm.PodRating === "GOOD"
                        ? "bg-green-600"
                        : pm.PodRating === "STANDARD"
                          ? "bg-yellow-600"
                          : pm.PodRating === "SLOW"
                            ? "bg-red-600"
                            : "bg-blue-600"
                      }`}
                  ></span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="text-xs sm:text-sm flex-1">
      {penerimaanMonitoring &&
        penerimaanMonitoring.Result &&
        penerimaanMonitoring.Result.length == 0 && (
          <div className="justify-center flex flex-row">
            <p>Tidak ada penerimaan</p>
          </div>
        )}
      {context}
    </div>
  );
};

export default PenerimaanMonitoringTable;
