import React from "react";

const WaktuSampaiFooter = () => {
  return (
    <div className="flex flex-row justify-end text-xs">
      <div className="flex mr-2">
        <div className="circle-green"></div>
        <div className="ml-1">Cepat</div>
      </div>
      <div className="flex mr-2">
        <div className="circle-yellow"></div>
        <div className="ml-1">Standard</div>
      </div>
      <div className="flex mr-2">
        <div className="circle-red"></div>
        <div className="ml-1">Lambat</div>
      </div>
      <div className="flex">
        <div className="circle-black"></div>
        <div className="ml-1">Belum POD</div>
      </div>
    </div>
  );
};

export default WaktuSampaiFooter;
