import React, { useState, useEffect } from "react";
import NavMenu from "../components/NavMenu";
import "../styles/tailwind.css";
import { accActions } from "../_actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import background from "../assets/bg.png"

const AUTO_LOGOUT_TIME = 1000 * 60 * 30; // 30 minutes

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const [sidebar, setSidebar] = useState(false);
  const acc = useSelector((state) => state.authentication.accInfo);
  const pickupAddrSelectedObj = useSelector((state) => state.selectedPickupAddr.selectedPickupAddrObj);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const history = useHistory();
  const getData = (data) => {
    setSidebar(data);
  };
  
  useEffect(() => {
    const handleStorageChange = () => {
      if (!localStorage.getItem("acc")) {
        // Redirect to '/' if cookie is missing
        window.location.href = "/";
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    // Function to update last active timestamp in local storage
    const updateLastActiveTime = () => {
      localStorage.setItem('lastActiveTime', Date.now().toString());
    };

    // Event listener for mouse move and key press to update activity
    const activityHandler = () => {
      updateLastActiveTime();
    };

    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];
    for (let i in events) {
      window.addEventListener(events[i], activityHandler);
    }

    // Initialize last active time on component mount
    const lastActiveTime = localStorage.getItem('lastActiveTime');
    if (!lastActiveTime) {
      updateLastActiveTime();
    }

    // Check for idle timeout periodically
    const idleTimeout = setInterval(() => {
      const currentTime = Date.now();
      const lastActiveTime = parseInt(localStorage.getItem('lastActiveTime'), 10);

      if (currentTime - lastActiveTime > AUTO_LOGOUT_TIME) {
        setIsLoggedIn(false);
        clearInterval(idleTimeout);
        dispatch(accActions.logout());
        history.push("/");
        // Perform any additional logout logic here
      }
    }, 1000 * 60); // Check every minute

    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], activityHandler);
      }
      clearInterval(idleTimeout);
    };
  }, []);


  const myStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat-y',
    minheight: '100%', // Ensures full-screen coverage
  };

  return (
    <div>
      <NavMenu onSubmit={getData} />
      <div
        style={
          sidebar
            ? {
              backgroundColor: "rgba(0,0,0,.59)",
              position: "fixed",
              zIndex: 98,
              width: "100%",
              height: "100%",
              transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
            }
            : {}
        }
      ></div>
      <div className="mt-8 sm:px-3 sm:mt-12" >{children}</div>
    </div>
  );
};

export default Layout;
