export const pickupConstants = {
    GET_PICKUP_ADDR_REQUEST: "GET_PICKUP_ADDR_REQUEST",
    GET_PICKUP_ADDR_SUCCESS: "GET_PICKUP_ADDR_SUCCESS",
    GET_PICKUP_ADDR_FAILURE: "GET_PICKUP_ADDR_FAILURE",
    GET_PICKUP_ADDR_CLEAR: "GET_PICKUP_ADDR_CLEAR",

    PICKUP_MONITORING_REQUEST: "PICKUP_MONITORING_REQUEST",
    PICKUP_MONITORING_SUCCESS: "PICKUP_MONITORING_SUCCESS",
    PICKUP_MONITORING_FAILURE: "PICKUP_MONITORING_FAILURE",

    PICKUP_ORDER_H_INFO_REQUEST: "PICKUP_ORDER_H_INFO_REQUEST",
    PICKUP_ORDER_H_INFO_SUCCESS: "PICKUP_ORDER_H_INFO_SUCCESS",
    PICKUP_ORDER_H_INFO_FAILURE: "PICKUP_ORDER_H_INFO_FAILURE",

    CANCEL_PICKUP_ORDER_REQUEST: "CANCEL_PICKUP_ORDER_REQUEST",
    CANCEL_PICKUP_ORDER_SUCCESS: "CANCEL_PICKUP_ORDER_SUCCESS",
    CANCEL_PICKUP_ORDER_FAILURE: "CANCEL_PICKUP_ORDER_FAILURE",

    PICKUP_ORDER_DETAIL_REQUEST: "PICKUP_ORDER_DETAIL_REQUEST",
    PICKUP_ORDER_DETAIL_SUCCESS: "PICKUP_ORDER_DETAIL_SUCCESS",
    PICKUP_ORDER_DETAIL_FAILURE: "PICKUP_ORDER_DETAIL_FAILURE",
    
    PICKUP_REQ_MONITORING_REQUEST: "PICKUP_MONITORING_REQ_REQUEST",
    PICKUP_REQ_MONITORING_SUCCESS: "PICKUP_MONITORING_REQ_SUCCESS",
    PICKUP_REQ_MONITORING_FAILURE: "PICKUP_MONITORING_REQ_FAILURE",
    PICKUP_REQ_MONITORING_CLEAR: "PICKUP_MONITORING_REQ_CLEAR",

    PICKUP_REQ_DETAIL_REQUEST: "PICKUP_DETAIL_REQ_REQUEST",
    PICKUP_REQ_DETAIL_SUCCESS: "PICKUP_DETAIL_REQ_SUCCESS",
    PICKUP_REQ_DETAIL_FAILURE: "PICKUP_DETAIL_REQ_FAILURE",

    PICKUP_REQ_GRP_ADD_EDIT_REQUEST: "PICKUP_REQ_GRP_ADD_EDIT_REQUEST",
    PICKUP_REQ_GRP_ADD_EDIT_SUCCESS: "PICKUP_REQ_GRP_ADD_EDIT_SUCCESS",
    PICKUP_REQ_GRP_ADD_EDIT_FAILURE: "PICKUP_REQ_GRP_ADD_EDIT_FAILURE",
    PICKUP_REQ_GRP_ADD_EDIT_CLEAR: "PICKUP_REQ_GRP_ADD_EDIT_CLEAR",

    GET_PICKUP_ADDR_BY_ID_REQUEST: "GET_PICKUP_ADDR_BY_ID_REQUEST",
    GET_PICKUP_ADDR_BY_ID_SUCCESS: "GET_PICKUP_ADDR_BY_ID_SUCCESS",
    GET_PICKUP_ADDR_BY_ID_FAILURE: "GET_PICKUP_ADDR_BY_ID_FAILURE",
    GET_PICKUP_ADDR_BY_ID_CLEAR: "GET_PICKUP_ADDR_BY_ID_CLEAR",

    GET_LIST_PICKUP_ADDR_REQUEST: "GET_LIST_PICKUP_ADDR_REQUEST",
    GET_LIST_PICKUP_ADDR_SUCCESS: "GET_LIST_PICKUP_ADDR_SUCCESS",
    GET_LIST_PICKUP_ADDR_FAILURE: "GET_LIST_PICKUP_ADDR_FAILURE",
    GET_LIST_PICKUP_ADDR_CLEAR: "GET_LIST_PICKUP_ADDR_CLEAR",

    SELECT_PICKUP_ADDR_CLEAR: "SELECT_PICKUP_ADDR_CLEAR",
    SELECT_PICKUP_ADDR_SUCCESS: "SELECT_PICKUP_ADDR_SUCCESS",
};
