import { uploadConstants } from "../_constants";
import { uploadService } from "../_services";

export const uploadActions = {
  uploadMonitoring,
  uploadDetail
};

function uploadMonitoring(uploadMonitoringObj) {
  return (dispatch) => {
    dispatch(request({ uploadMonitoringObj }));
    uploadService.uploadMonitoring(uploadMonitoringObj).then(
      (uploadMonitoring) => {
        dispatch(success(uploadMonitoring));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(uploadMonitoring) {
    return {
      type: uploadConstants.UPLOAD_MONITORING_REQUEST,
      uploadMonitoring,
    };
  }
  function success(uploadMonitoring) {
    return {
      type: uploadConstants.UPLOAD_MONITORING_SUCCESS,
      uploadMonitoring,
    };
  }
  function failure(error) {
    return { type: uploadConstants.UPLOAD_MONITORING_FAILURE, error };
  }
}

function uploadDetail(uploadDetailObj) {
  return (dispatch) => {
    dispatch(request({ uploadDetailObj }));
    uploadService.uploadDetail(uploadDetailObj).then(
      (uploadDetail) => {
        dispatch(success(uploadDetail));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(uploadDetail) {
    return {
      type: uploadConstants.UPLOAD_DETAIL_REQUEST,
      uploadDetail,
    };
  }
  function success(uploadDetail) {
    return {
      type: uploadConstants.UPLOAD_DETAIL_SUCCESS,
      uploadDetail,
    };
  }
  function failure(error) {
    return { type: uploadConstants.UPLOAD_DETAIL_FAILURE, error };
  }
}
