import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { masterSettingConstants } from "../../_constants";
import {
    districtActions,
    bookingActions,
    packageContentActions,
    originActions,
    transportTypeActions,
    customerActions,
} from "../../_actions";
import {
    TableDetailBarangEdit,
    AlertMessage
} from "../../components";
import Input from "react-phone-number-input/input";
import { NumericFormat } from "react-number-format";
import { formatPhoneNumber } from "react-phone-number-input";
import "../../custom.css";
import "react-phone-number-input/style.css";
import Select, { createFilter } from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

const BookingDetailEditV2 = ({ }) => {
    const dispatch = useDispatch();
    const acc = useSelector((state) => state.authentication.accInfo);
    const packageContentsObj = useSelector(
        (state) => state.packagecontent.packageContentObj
    );
    const districtObj = useSelector((state) => state.district.districtObj);
    const respCalcObj = useSelector(
        (state) => state.calculateBookingPrice.calcBookingObj
    );
    const respSubmitObj = useSelector(
        (state) => state.submitBooking.submitBookingObj
    );
    const errCalcObj = useSelector(
        (state) => state.calculateBookingPrice.errCalcBookingObj
    );
    const errSubmitObj = useSelector(
        (state) => state.submitBooking.errSubmitBookingObj
    );
    const originObj = useSelector((state) => state.origin.origin);
    const transportTypeObj = useSelector(
        (state) => state.transportType.transportTypeObj
    );
    const errTransportTypeObj = useSelector(
        (state) => state.transportType.errTransportTypeObj
    );
    const custObj = useSelector((state) => state.custById.custObj);
    const listPenerimaObj = useSelector(
        (state) => state.penerimaByHint.penerimaByHintObj
    );
    const penerimaObj = useSelector((state) => state.penerimaById.penerimaObj);
    const custBookHDetailObj = useSelector(
        (state) => state.getCustBookHDetail.custBookHDetailObj
    );
    const cancelKoliBookingObj = useSelector(
        (state) => state.cancelKoliBooking.cancelKoliBookingObj
    );

    const history = useHistory();
    const [isError, setIsError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [simpanDataBarang, setSimpanDataBarang] = useState(false);
    const [enableSubmitted, setEnableSubmitted] = useState(false);
    const [newPickupAddr, setNewPickupAddr] = useState(false);
    const [packageCategory, setPackageCategory] = useState("");
    const [sellerName, setSellerName] = useState(acc && acc.CustName);
    const [sellerAliasName, setSellerAliasName] = useState(acc && acc.CustName);
    const [sellerMobilePhnNo, setSellerMobilePhnNo] = useState(
        acc && acc.MobilePhoneNo
    );
    const [originDestId, setOriginDestId] = useState(0);
    const [originDestCode, setOriginDestCode] = useState("");
    const [referenceNo, setReferenceNo] = useState("");
    const [recipientId, setRecipientId] = useState(0);
    const [recipientName, setRecipientName] = useState("");
    const [recipientMobilePhnNo, setRecipientMobilePhnNo] = useState("");
    const [recipientAddress, setRecipientAddress] = useState("");
    const [dropType, setDropType] = useState("PICKUP");
    const [paymentType, setPaymentType] = useState("");
    const [transportType, setTransportType] = useState("");
    const [estItemWeight, setEstItemWeight] = useState(0);
    const [packageContent, setPackageContent] = useState("");
    const [packageContentNotes, setPackageContentNotes] = useState("");
    const [pickupContactPerson, setPickupContactPerson] = useState("");
    const [pickupAddress, setPickupAddress] = useState("");
    const [pickupNotes, setPickupNotes] = useState("");
    const [pickupPhnNo, setPickupPhnNo] = useState("");
    const [pickupTime, setPickupTime] = useState(
        moment(new Date()).format("YYYY-MM-DD")
    );
    const [minPickupTime, setMinPickupTime] = useState(
        moment(new Date()).format("YYYY-MM-DD")
    );
    const [pickupAddrType, setPickupAddrType] = useState("");
    const [bookingNotes, setBookingNotes] = useState("");
    const [isInsurance, setIsInsurance] = useState("0");
    const [nilaiBarang, setNilaiBarang] = useState(0);
    const [totalKoli, setTotalKoli] = useState(1);
    const [totalNonCanKoli, setTotalNonCanKoli] = useState(0);
    const [arrTempBarang, setArrTempBarang] = useState([]);
    const [arrDetailBarangMbl, setArrDetailBarangMbl] = useState([]);
    const [detailBarangMbl, setDetailBarangMbl] = useState({
        CustBookDId: 0,
        ItemCodeNo: "",
        ItemWeight: 0,
        DimP: 0,
        DimL: 0,
        DimT: 0,
        IsPacking: false,
        PackingKayu: false,
        BubbleWrap: false,
        Wrapping: false
    });
    const [alert, setAlert] = useState({
        Type: "",
        StatusMessage: "",
    });
    const [showPenerimaForm, setShowPenerimaForm] = useState(false);
    const [showKoliForm, setShowKoliForm] = useState(false);
    const [errors, setErrors] = useState({});

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();
    const _trxNo = query.get("trxNo");
    const noResi = query.get("noResi");
    function dateParser(value) {
        const date = new Date(value);
        const dateParsed =
            date.getFullYear() +
            "-" +
            date.toLocaleString("en-us", { month: "2-digit" }) +
            "-" +
            date.toLocaleString("en-us", { day: "2-digit" });
        return dateParsed;
    }
    const maxDate = new Date();
    const minDate = new Date();
    const finalMinDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
    const [requestObj, setRequestObj] = useState({
        trxDtFrom: dateParser(finalMinDate),
        trxDtTo: dateParser(maxDate),
    });
    const [options, setOptions] = useState([]);
    const [selectedPckDist, setSelectedPckDist] = useState();
    const [selectedDest, setSelectedDest] = useState();
    const [penerimaOptions, setPenerimaOptions] = useState([]);
    const [hideCOD, setHideCOD] = useState(true);
    const [hideCredit, setHideCredit] = useState(true);
    const [hideReceiverCredit, setHideReceiverCredit] = useState(true);
    const [isMember, setIsMember] = useState(false);
    const [isEditKoli, setIsEditKoli] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (acc) {
            dispatch(customerActions.requestGetCustById(acc.CustId));

            var phone = sellerMobilePhnNo.substring(1, 20);
            var area = sellerMobilePhnNo.substring(0, 1);
            if (area === "0") {
                setSellerMobilePhnNo("+62" + phone);
            }

            setPickupTime(moment(new Date()).format("YYYY-MM-DD"));
            setMinPickupTime(moment(new Date()).format("YYYY-MM-DD"));
            setSelectedPckDist({ value: 0, label: "Pilih Kecamatan / Kabupaten" });
            if (!districtObj) {
                dispatch(districtActions.getDistrict());
            }
        }

        if (custBookHDetailObj) {
            setPickupAddress(custBookHDetailObj.Pengirim.PickupAddr);
            setPickupContactPerson(custBookHDetailObj.Pengirim.PickupContactPerson);
            setPickupPhnNo(custBookHDetailObj.Pengirim.PickupPhoneNo);
            setPickupAddrType(custBookHDetailObj.Pengirim.PickupAddrType);
        }

        if (_trxNo !== 0) {
            getCustBookHDetail();
            setShowPenerimaForm(true);
        }
    }, []);

    useEffect(() => {
        if (errCalcObj && errCalcObj.StatusCode != "200") {
            setIsError(true);
            setSubmitted(false);
            setAlert({ Type: "Error", StatusMessage: errCalcObj.StatusMessage });
            setArrDetailBarangMbl((arrDetailBarangMbl) => [
                ...arrDetailBarangMbl.filter(
                    (rw) => rw.CustBookDId != arrDetailBarangMbl.length
                ),
            ]);
        }
    }, [errCalcObj]);

    useEffect(() => {
        if (respCalcObj && respCalcObj.BookingD.length > 0) {
            setArrTempBarang(respCalcObj.BookingD.map(bookingD => {
                let splitString = bookingD.PackingType.split(';');
                let kayu = splitString.findIndex(x => x == "KAYU") != -1 ? true : false;
                let bubbleWrap = splitString.findIndex(x => x == "BUBBLEWRAP") != -1 ? true : false;
                let wrapping = splitString.findIndex(x => x == "WRAPPING") != -1 ? true : false;

                return {
                    ...bookingD,
                    IsPacking: kayu || bubbleWrap || wrapping,
                    PackingKayu: kayu,
                    BubbleWrap: bubbleWrap,
                    Wrapping: wrapping
                }
            }).sort((a, b) => a.SeqNo - b.SeqNo));
            setDetailBarangMbl({
                CustBookDId: 0,
                ItemName: "",
                ItemWeight: 0,
                DimP: 0,
                DimL: 0,
                DimT: 0,
                IsPacking: false,
                PackingKayu: false,
                BubbleWrap: false,
                Wrapping: false
            });
        } else {
            setArrTempBarang([]);
        }
    }, [respCalcObj]);

    useEffect(() => {
        if (errSubmitObj && errSubmitObj.StatusCode != "200") {
            setIsError(true);
            setSubmitted(false);
            setAlert({ Type: "Error", StatusMessage: errSubmitObj.StatusMessage });
        }
    }, [errSubmitObj]);

    useEffect(() => {
        if (errTransportTypeObj && errTransportTypeObj.StatusCode != "200") {
            setIsError(true);
            setSubmitted(false);
            setAlert({
                Type: "Error",
                StatusMessage: errTransportTypeObj.StatusMessage,
            });
        }
    }, [errTransportTypeObj]);

    useEffect(() => {
        if (detailBarangMbl.CustBookDId != 0) {
            setShowKoliForm(false);
        } else {
            setShowKoliForm(true);
        }
    }, [detailBarangMbl]);

    useEffect(() => {
        if (custObj != null) {
            setHideCredit(!custObj.IsAllowCredit);
        }
    }, [custObj]);

    useEffect(() => {
        setPackageContent("");
        setEnableSubmitted(false);
        setIsInsurance("0");
        if (packageCategory !== null && packageCategory !== "") {

            dispatch(packageContentActions.getPackageContent(packageCategory));
        } else {
            dispatch(packageContentActions.clearPackageContent());
        }
    }, [packageCategory]);

    useEffect(() => {
        if (respSubmitObj && respSubmitObj.ResiNo !== "") {
            history.push(`/booking-details?noResi=${respSubmitObj.ResiNo}`)
            clearDataPenerima();
        }
    }, [respSubmitObj]);

    useEffect(() => {
        if (!errSubmitObj && !errCalcObj && arrDetailBarangMbl.length > 0) {
            setAlert({ Type: "", StatusMessage: "" });
        }
        if (
            isError == false &&
            selectedDest &&
            selectedDest.value != 0 &&
            paymentType &&
            transportType &&
            packageCategory &&
            packageContent &&
            arrDetailBarangMbl.length > 0 &&
            originDestId > 0 && (isInsurance == "0" || (isInsurance == "1" && nilaiBarang > 0))
        ) {
            let _bookingD = [];

            arrDetailBarangMbl.map(
                (detail, i) => {
                    let _arrTempObj = arrTempBarang.find(x => x.CustBookDId == detail.CustBookDId);
                    if (_arrTempObj != undefined && _arrTempObj != null) {
                        var packingType = "";
                        if (detail.PackingKayu) {
                            packingType = "KAYU"
                        }
                        if (detail.BubbleWrap) {
                            packingType = (packingType != "" ? packingType + ";" : "") + "BUBBLEWRAP"
                        }
                        if (detail.Wrapping) {
                            packingType = (packingType != "" ? packingType + ";" : "") + "WRAPPING"
                        }

                        let newDetailBarang = {
                            CustBookDId: detail.CustBookDId,
                            ItemCodeNo: "",
                            ItemWeight: detail.ItemWeight,
                            DimP: detail.DimP,
                            DimL: detail.DimL,
                            DimT: detail.DimT,
                            CustBookDStat: _arrTempObj.CustBookDStat,
                            IsPacking: detail.IsPacking,
                            PackingType: packingType
                        }
                        _bookingD.push(newDetailBarang);
                    }
                }
            )

            const bookingHObj = {
                CustId: acc.CustId,
                DestDistrictId: selectedDest.value,
                PaymentType: paymentType,
                TransportType: transportType,
                PackageCategory: packageCategory,
                PackageContentCode: packageContent,
                BookingD: _bookingD,
                EstItemPriceAmt: nilaiBarang,
                OriginDestCode: originDestCode,
            };
            dispatch(bookingActions.requestCalculateBooking(bookingHObj));
            setEnableSubmitted(true);
        }
        if (arrDetailBarangMbl.length == 0 && custBookHDetailObj == 0) {
            setArrTempBarang([]);
        }
    }, [
        arrDetailBarangMbl,
        packageCategory,
        packageContent,
        transportType,
        selectedDest,
        paymentType,
        originDestId
    ]);

    useEffect(() => {
        if (districtObj) {
            setOptions(
                districtObj.map((dist, i) => ({
                    value: dist.DistrictId,
                    label: dist.DistrictDescr,
                }))
            );
            if (custBookHDetailObj && custBookHDetailObj.DistrictId !== undefined && custBookHDetailObj.DistrictId !== null && custBookHDetailObj.DistrictId > 0) {
                if (custBookHDetailObj.Pengirim !== null) {
                    setSelectedPckDist({
                        value: custBookHDetailObj.Pengirim.PickupDistrictId,
                        label: custBookHDetailObj.Pengirim.PickupDistrictDescr,
                    });

                    setOriginDestId(custBookHDetailObj.Pengirim.OriginDestId);
                    setOriginDestCode(custBookHDetailObj.OriginDestCode);
                }
            }
        }
    }, [custBookHDetailObj, districtObj, originObj]);

    useEffect(() => {
        if (custBookHDetailObj && packageContentsObj) {
            let selectedPackageContent = packageContentsObj.filter(
                (rw) => rw.PackageContentCode === custBookHDetailObj.PackageContentCode
            );

            setPackageContentNotes(custBookHDetailObj.PackageContentNotes);
            if (selectedPackageContent.length > 0) {
                setPackageContent(selectedPackageContent[0].PackageContentCode);
                setIsInsurance(selectedPackageContent[0].IsInsurance);
            }
        }
    }, [packageContentsObj, custBookHDetailObj])

    useEffect(() => {
        if (custBookHDetailObj) {
            setSelectedDest({ value: custBookHDetailObj.DistrictId, label: custBookHDetailObj.DistrictDescr });
            setPackageCategory(custBookHDetailObj.PackageCategory);
            setTransportType(custBookHDetailObj.TransportType);
            setPaymentType(custBookHDetailObj.PaymentType);
            setOriginDestCode(custBookHDetailObj.OriginDestCode);
            setRecipientName(custBookHDetailObj.RecipientName);
            let PhoneNo = custBookHDetailObj.RecipientMblPhoneNo;
            if (PhoneNo && PhoneNo.length > 1) {
                var phone = PhoneNo.substring(1, 20);
                var area = PhoneNo.substring(0, 1);
                if (area === "0") {
                    PhoneNo = "+62" + phone;
                }
                setRecipientMobilePhnNo(PhoneNo);
            }
            setRecipientAddress(custBookHDetailObj.RecipientAddr);
            setReferenceNo(custBookHDetailObj.CustRefNo);
            setBookingNotes(custBookHDetailObj.BookNotes);
            setTotalKoli(custBookHDetailObj.ActualTotalItem);
            setArrTempBarang(custBookHDetailObj.CustBookDs.sort((a, b) => a.SeqNo - b.SeqNo));
            setArrDetailBarangMbl(custBookHDetailObj.CustBookDs.map(
                (detail, i) => {
                    var packingType = [];

                    packingType = detail.PackingType.split(';');
                    detail.PackingKayu = packingType.findIndex(x => x == "KAYU") != -1;
                    detail.BubbleWrap = packingType.findIndex(x => x == "BUBBLEWRAP") != -1;
                    detail.Wrapping = packingType.findIndex(x => x == "WRAPPING") != -1;

                    return detail;
                }
            ));

            setIsInsurance(custBookHDetailObj.IsInsurance)
            setNilaiBarang(custBookHDetailObj.EstItemPriceAmt)
            setPackageContentNotes(custBookHDetailObj.PackageContentNotes);
            setPickupAddress(custBookHDetailObj.Pengirim.PickupAddr);
            setPickupContactPerson(custBookHDetailObj.Pengirim.PickupContactPerson);
            setPickupPhnNo(custBookHDetailObj.Pengirim.PickupPhoneNo);
            setPickupAddrType(custBookHDetailObj.Pengirim.PickupAddrType);
            setShowPenerimaForm(true);
        }
    }, [custBookHDetailObj]);

    useEffect(() => {
        getTransportType();
    }, [selectedDest]);

    useEffect(() => {
        if (
            listPenerimaObj != null &&
            listPenerimaObj.Result &&
            listPenerimaObj.Result.length > 0
        ) {
            setPenerimaOptions(
                listPenerimaObj.Result.map((penerima, i) => ({
                    value: penerima.CustId,
                    label: penerima.CustName,
                }))
            );
        }
    }, [listPenerimaObj]);

    useEffect(() => {
        if (recipientName != "" && recipientName.length >= 3) {
            dispatch(customerActions.requestGetPenerimaByHint(recipientName));
        }
    }, [recipientName]);

    useEffect(() => {
        if (penerimaObj != null) {
            setHideReceiverCredit(!penerimaObj.IsAllowReceiverCredit);
        }
    }, [penerimaObj]);

    useEffect(() => {
        if (cancelKoliBookingObj && cancelKoliBookingObj.CustBookDs && cancelKoliBookingObj.CustBookDs.length > 0) {
            const newArrTempBarang = [];
            cancelKoliBookingObj.CustBookDs.map((atb) => {
                if (atb.CustBookDStat == "CAN") {
                    let newTempBarang = {
                        ...atb,
                        CustBookDId: atb.CustBookDId,
                        SeqNo: atb.SeqNo
                    }
                    newArrTempBarang.push(newTempBarang);
                }
                else {
                    arrDetailBarangMbl.map(
                        (x, i) => {
                            if (x.CustBookDId == atb.CustBookDId) {
                                let newTempBarang = {
                                    ...x,
                                    CustBookDId: x.CustBookDId,
                                    SeqNo: x.SeqNo
                                }
                                newArrTempBarang.push(newTempBarang);
                            }
                        }
                    )
                }
            });

            let idx = newArrTempBarang.length;
            arrTempBarang.map((atb) => {
                if (atb.CustBookDStat == "NEW") {
                    idx++;
                    let newTempBarang = {
                        ...atb,
                        CustBookDId: atb.CustBookDId,
                        SeqNo: idx
                    }
                    newArrTempBarang.push(newTempBarang);
                }
            });
            setArrTempBarang(newArrTempBarang.sort((a, b) => a.SeqNo - b.SeqNo));
            setArrDetailBarangMbl(newArrTempBarang.sort((a, b) => a.SeqNo - b.SeqNo));
        }
    }, [cancelKoliBookingObj]);

    useEffect(() => {
        if (arrTempBarang && arrTempBarang.length > 0) {
            setTotalNonCanKoli(arrTempBarang.filter(x => x.CustBookDStat != "CAN").length);
        }
    }, [arrTempBarang]);

    useEffect(() => {
        if (alert.Type != "") scrollToTop()
    }, [alert])

    function handlePackageContentChange(_packageContent) {
        setPackageContent(_packageContent);
        if (
            _packageContent !==
            masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN &&
            _packageContent !==
            masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN
        ) {
            setPackageContentNotes("");
        }
        handleIsInsurance(_packageContent);
    }

    function handleError(input, errorMsg) {
        setErrors((prevState) => ({
            ...prevState,
            [input]: errorMsg,
        }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        let isValid = true;

        if (recipientName == "") {
            isValid = false;
            setAlert({
                Type: "Error",
                StatusMessage: "Harap input setidaknya 1 detail penerima",
            });
        } else if (totalNonCanKoli == 0) {
            isValid = false;
            setAlert({
                Type: "Error",
                StatusMessage: "Harap input setidaknya 1 detail barang",
            });
        }

        if (isInsurance == "1") {
            let isValidNilaiBarang = true;
            let isValidPackageContentNotes = true;

            if (nilaiBarang <= 0) {
                isValidNilaiBarang = false;
                handleError("nilaiBarang", "Harap isi nilai barang.");
            }
            if (packageContentNotes == "") {
                isValidPackageContentNotes = false;
            }

            if (!isValidNilaiBarang || !isValidPackageContentNotes) {
                isValid = false;
                setAlert({
                    Type: "Error",
                    StatusMessage: "Harap isi form dengan benar.",
                });
            }
        }

        if (
            isValid &&
            totalKoli == totalNonCanKoli &&
            enableSubmitted &&
            sellerName &&
            sellerMobilePhnNo &&
            recipientName &&
            paymentType &&
            packageCategory &&
            packageContent &&
            dropType &&
            transportType &&
            totalNonCanKoli > 0 &&
            originDestId > 0 &&
            selectedDest &&
            selectedDest.value !== 0
        ) {
            if (
                ((packageContent ===
                    masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN ||
                    packageContent ===
                    masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN) &&
                    packageContentNotes) ||
                (packageContent !==
                    masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN &&
                    packageContent !==
                    masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN)
            ) {
                if (
                    ((dropType === masterSettingConstants.DROP_TYPE_PICKUP &&
                        pickupTime &&
                        pickupPhnNo &&
                        pickupContactPerson &&
                        selectedPckDist &&
                        selectedPckDist.value != 0 &&
                        originDestId > 0) ||
                        dropType === masterSettingConstants.DROP_TYPE_DROP) &&
                    ((newPickupAddr === true && pickupAddrType) || !newPickupAddr)
                ) {
                    handleError("nilaiBarang", null);

                    if (!errSubmitObj && !errCalcObj && arrDetailBarangMbl.length > 0 && totalKoli == arrDetailBarangMbl.length) {
                        setAlert({ Type: "", StatusMessage: "" });
                    }

                    let newArrDetailBarangMbl = arrDetailBarangMbl;
                    newArrDetailBarangMbl.map((newDetail, i) => {
                        var packingType = "";
                        if (newDetail.PackingKayu) {
                            packingType = "KAYU"
                        }
                        if (newDetail.BubbleWrap) {
                            packingType = (packingType != "" ? packingType + ";" : "") + "BUBBLEWRAP"
                        }
                        if (newDetail.Wrapping) {
                            packingType = (packingType != "" ? packingType + ";" : "") + "WRAPPING"
                        }
                        newDetail.PackingType = packingType

                        return newDetail;
                    })

                    const bookingHObj = {
                        CustId: acc.CustId,
                        SellerName: sellerName,
                        AliasName: sellerAliasName,
                        SellerMblPhoneNo: formatPhoneNumber(sellerMobilePhnNo),
                        Username: acc.Username,
                        CustRefNo: referenceNo,
                        RecipientAddr: recipientAddress,
                        RecipientMblPhoneNo: formatPhoneNumber(recipientMobilePhnNo),
                        RecipientName: recipientName,
                        PaymentType: paymentType,
                        PackageCategory: packageCategory,
                        PackageContentCode: packageContent,
                        EstTotalWeight: estItemWeight,
                        PackageContentNotes: packageContentNotes,
                        PickupNotes: pickupNotes,
                        PickupTime: pickupTime,
                        PickupPhoneNo: pickupPhnNo,
                        PickupContactPerson: pickupContactPerson,
                        PickupDistrictId: selectedPckDist.value,
                        PickupAddr: pickupAddress,
                        DropType: dropType,
                        TotalItem: totalNonCanKoli,
                        BookNotes: bookingNotes,
                        PickupAddrType: pickupAddrType,
                        EstItemPriceAmt: nilaiBarang,
                        DestDistrictId: selectedDest.value,
                        DestDistrictName: selectedDest.label,
                        TransportType: transportType,
                        BookDetails: newArrDetailBarangMbl,
                        OriginDestCode: originDestCode,
                        CustBookHId: _trxNo !== 0 ? _trxNo : 0
                    };

                    const submitBooking = async () => {
                        dispatch(bookingActions.requestSubmitBooking(bookingHObj));
                    };
                    submitBooking();
                }
            }
        }
    }

    function clearDataPenerima() {
        setReferenceNo("");
        setRecipientId(0);
        setRecipientName("");
        setRecipientMobilePhnNo("");
        setRecipientAddress("");
        setDropType("PICKUP");
        setPaymentType("");
        setTransportType("");
        setEstItemWeight(0);
        setPackageContent("");
        setPackageContentNotes("");
        setBookingNotes("");
        setPackageCategory("");
        setArrTempBarang([]);
        setArrDetailBarangMbl([]);
        setIsInsurance("0");
        setNilaiBarang(0);
        setSelectedDest({ value: 0, label: "Pilih Tujuan" });
        const clearData = async () => {
            dispatch(packageContentActions.clearPackageContent());
            dispatch(bookingActions.clearCalculateBooking());
            dispatch(bookingActions.clearSubmitBooking());
            dispatch(originActions.getOrigin());
            dispatch(bookingActions.clearCustBookHDetail());
            dispatch(bookingActions.clearCancelKoliBooking());
        };
        clearData();
        setSubmitted(false);
        setEnableSubmitted(false);
        setTotalKoli(1);
    }

    function detailKoliOnChange(event, isNumeric = true) {
        const { name, value, type, checked } = event.target;
        let val = type === 'checkbox' ? checked : value;

        if (isNumeric) {
            let num = 0;
            if (val != "") {
                const str = val.replace(/,/g, "");
                num = parseFloat(str);
            }

            setDetailBarangMbl((prevState) => ({
                ...prevState,
                [name]: num,
            }));
        } else {
            setDetailBarangMbl((prevState) => ({
                ...prevState,
                [name]: val,
            }));
        }
    }

    function handleNilaiBarang(event) {
        let isValidNilaiBarang = true;

        if (event.target.value != "") {
            const str = event.target.value.replace(/,/g, "");
            setNilaiBarang(parseFloat(str));

            if (event.target.value == 0) {
                isValidNilaiBarang = false;
            }
        } else {
            isValidNilaiBarang = false;
        }

        if (isValidNilaiBarang) {
            handleError("nilaiBarang", null);
        } else {
            setNilaiBarang(0);
            setIsError(true);
            setAlert({
                Type: "Info",
                StatusMessage: "Harap lengkapi data penerima terlebih dahulu",
            });
            handleError("nilaiBarang", "Harap isi nilai barang.");
        }
    }

    function handleIsInsurance(_packageContent = "") {
        if (_packageContent == "") {
            _packageContent = packageContent;
        }

        if (packageContentsObj) {
            let selectedPackageContent = packageContentsObj.find(
                (rw) => rw.PackageContentCode === _packageContent
            );

            setIsInsurance(selectedPackageContent.IsInsurance);
            setNilaiBarang(0);
            setPackageContentNotes('');
        }
    }

    function getCustBookHDetail() {
        if (acc && acc.CustId && _trxNo !== 0) {
            const custBookHDetailObj = {
                CustId: acc.CustId,
                CustBookHId: _trxNo
            };
            dispatch(bookingActions.getCustBookHDetail(custBookHDetailObj));
        }
    }

    function getTransportType() {
        if (selectedDest && selectedDest.value != 0) {
            const transportTypeObj = {
                OriginDestId: originDestId,
                DestDistrictId: selectedDest.value,
            };

            dispatch(transportTypeActions.getTransportType(transportTypeObj));
        } else {
            dispatch(transportTypeActions.clearTransportType());
        }
    }

    function onSubmitDetailBarang() {
        setEnableSubmitted(false);
        setSimpanDataBarang(true);
        setIsError(false);

        setArrDetailBarangMbl(arrDetailBarangMbl.map(
            (detail, i) => {
                if (detail.CustBookDId == detailBarangMbl.CustBookDId) {
                    detail.ItemWeight = detailBarangMbl.ItemWeight;
                    detail.DimP = detailBarangMbl.DimP;
                    detail.DimL = detailBarangMbl.DimL;
                    detail.DimT = detailBarangMbl.DimT;
                    detail.IsPacking = detailBarangMbl.PackingKayu || detailBarangMbl.BubbleWrap || detailBarangMbl.Wrapping;
                    detail.PackingKayu = detailBarangMbl.PackingKayu;
                    detail.BubbleWrap = detailBarangMbl.BubbleWrap;
                    detail.Wrapping = detailBarangMbl.Wrapping;
                }
                return detail;
            }
        ));

        setSimpanDataBarang(false);
    }

    function onAddDetailBarang() {
        if (
            recipientName &&
            transportType &&
            paymentType &&
            packageCategory &&
            packageContent &&
            selectedDest &&
            selectedDest.value != 0 &&
            (isInsurance == "0" || (isInsurance == "1" && nilaiBarang > 0))
        ) {
            setIsError(false);
            handleError("nilaiBarang", null);
        } else {
            setIsError(true);
            setAlert({
                Type: "Info",
                StatusMessage: "Harap lengkapi data penerima terlebih dahulu",
            });

            if (isInsurance == "1" && nilaiBarang <= 0) {
                handleError("nilaiBarang", "Harap isi nilai barang.");
            }
            return false;
        }
    }

    function generateKoli() {
        onAddDetailBarang();

        if (isError) return;

        let newArrTempBarang = [...arrTempBarang];
        let filterArrDetail = [];

        arrTempBarang.map(
            (detail, i) => {
                let newDetailBarang = {
                    CustBookDId: detail.CustBookDId,
                    ItemCodeNo: "",
                    ItemWeight: detail.ItemWeight,
                    DimP: detail.DimP,
                    DimL: detail.DimL,
                    DimT: detail.DimT,
                    CustBookDStat: detail.CustBookDStat,
                    IsPacking: detail.IsPacking,
                    PackingKayu: detail.PackingKayu,
                    BubbleWrap: detail.BubbleWrap,
                    Wrapping: detail.Wrapping
                }
                filterArrDetail.push(newDetailBarang);
            }
        )
        let arrTempBarangLength = arrTempBarang.length;

        if (totalKoli <= totalNonCanKoli) return;
        else {
            const newTempArrDetailBarangMbl = [...filterArrDetail];

            const countToAdd = totalKoli - totalNonCanKoli;
            for (let i = 0; i < countToAdd; i++) {
                let newObj = {
                    CustBookDId: arrTempBarangLength + (i + 1),
                    ItemCodeNo: "",
                    ItemWeight: 0,
                    DimP: 0,
                    DimL: 0,
                    DimT: 0,
                    CustBookDStat: "NEW",
                    IsPacking: false,
                    PackingKayu: false,
                    BubbleWrap: false,
                    Wrapping: false
                }
                newTempArrDetailBarangMbl.push(newObj);

                newObj.SeqNo = arrTempBarangLength + (i + 1);
                newObj.VolWeight = 0;
                newObj.FinalWeight = 0;
                newObj.TotalCalcSrc = "Item";
                newArrTempBarang.push(newObj);
            }
            setArrDetailBarangMbl(newTempArrDetailBarangMbl);
            setArrTempBarang(newArrTempBarang.sort((a, b) => a.SeqNo - b.SeqNo));
        }
    }

    const handleChangeDest = (selectedOption) => {
        setSelectedDest(selectedOption);
        handleTagihTujuan(selectedOption.value);
    };

    function handleTagihTujuan(districtId) {
        let _districtObj = districtObj.find((x) => x.DistrictId == districtId);
        let custCOD = custObj.IsAllowCOD;
        let tujuanCOD = false;
        let isAllowRcvPayment = "1";

        if (_districtObj != null) {
            isAllowRcvPayment = _districtObj.IsAllowRcvPayment;
            tujuanCOD = isAllowRcvPayment == "1" ? true : false;
            if (custCOD && tujuanCOD) setHideCOD(false);
            else setHideCOD(true);
        } else {
            setHideCOD(!custCOD);
        }
    }

    function handleCancel(ev) {
        if (ev.CustBookDStat == "ACT") {
            let reqObj = {
                Id: ev.CustBookDId,
                username: acc.Username
            }
            dispatch(bookingActions.cancelKoliBooking(reqObj));
        } else if (ev.CustBookDStat == "NEW") {
            const newArrTempBarang = [];
            let idx = 1;
            arrTempBarang.map((atb) => {
                if (ev.CustBookDId != atb.CustBookDId) {
                    let newTempBarang = {
                        ...atb,
                        CustBookDId: atb.CustBookDId,
                        SeqNo: idx
                    }
                    newArrTempBarang.push(newTempBarang);
                    idx++;
                }
            });
            setArrTempBarang(newArrTempBarang);
            setArrDetailBarangMbl(newArrTempBarang);
        }
    }

    const style = {
        control: (base, state) => ({
            ...base,
            border: "1px solid rgba(209, 213, 219, var(--tw-border-opacity))",
            boxShadow: "none",
            "&:hover": {
                border: "1px solid rgba(209, 213, 219, var(--tw-border-opacity))",
            },
        }),
    };

    const styleMobile = {
        control: (base, state) => ({
            ...base,
            border: "none",
            boxShadow: "none",
            "&:hover": {
                border: "none",
            },
        }),
    };

    return (
        <>
            <div className="hidden sm:block max-w-6xl mx-auto">
                <div className="flex flex-row">
                    <h4 className="w-full text-center text-xl font-semibold py-2">
                        Buat Booking
                    </h4>
                </div>

                <AlertMessage
                    isError={isError}
                    alert={alert}
                    setAlert={setAlert}
                    setIsError={setIsError}
                />
                <form onSubmit={handleSubmit} method="POST">
                    {showPenerimaForm && (
                        <>
                            <div className="h-full w-full text-xs sm:text-sm font-medium">
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                                        Alias Pengirim<p className="text-red-500">&nbsp;*</p>
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <input
                                            id="sellerAliasName"
                                            type="text"
                                            name="sellerAliasName"
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            placeholder="Isi nama alias pengirim"
                                            value={sellerAliasName}
                                            onChange={(e) => setSellerAliasName(e.target.value)}
                                        />
                                        {submitted && !sellerAliasName && (
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                Harap isi alias pengirim
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                                        Nomor Ponsel Pengirim<p className="text-red-500">&nbsp;*</p>
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <Input
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            country="ID"
                                            value={sellerMobilePhnNo}
                                            onChange={setSellerMobilePhnNo}
                                            placeholder="Isi nomor ponsel pengirim"
                                        />
                                        {submitted && !sellerMobilePhnNo && (
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                Harap isi nomor ponsel pengirim
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                                        Nama Penerima<p className="text-red-500">&nbsp;*</p>
                                    </div>
                                    {!isMember && (
                                        <div className="col-md-3 text-xs sm:text-sm">
                                            <input
                                                id="recipientName"
                                                type="text"
                                                name="recipientName"
                                                className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                                placeholder="Isi nama penerima"
                                                value={recipientName}
                                                onChange={(e) => setRecipientName(e.target.value)}
                                            />
                                            {submitted && !recipientName && (
                                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                    Harap isi nama penerima
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="col-md-3 text-xs sm:text-sm font-bold">
                                        Nomor Ponsel Penerima
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <Input
                                            id="recipientMobilePhnNo"
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            country="ID"
                                            value={recipientMobilePhnNo}
                                            onChange={setRecipientMobilePhnNo}
                                            placeholder="Isi nomor ponsel penerima"
                                        />

                                    </div>
                                </div>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-xs sm:text-sm font-bold">
                                        Alamat Penerima
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <textarea
                                            id="recipientAddress"
                                            name="recipientAddress"
                                            rows="3"
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            style={{
                                                boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)",
                                            }}
                                            value={recipientAddress}
                                            onChange={(e) => setRecipientAddress(e.target.value)}
                                            placeholder="Isi alamat penerima"
                                        />

                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                                        Nomor Referensi{acc.IsCustRefNoMandatory && <p className="text-red-500">&nbsp;*</p>}
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <input
                                            id="referenceNo"
                                            type="text"
                                            name="referenceNo"
                                            placeholder="Isi customer reference no"
                                            maxLength="50"
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            onChange={(e) => setReferenceNo(e.target.value)}
                                            value={referenceNo}
                                        />
                                        {acc.IsCustRefNoMandatory && submitted && !referenceNo && (
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                Harap isi Nomor Referensi.
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                                        Tujuan<p className="text-red-500">&nbsp;*</p>
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        {
                                            districtObj && selectedDest &&
                                            <Select
                                                filterOption={createFilter({ ignoreAccents: false })}
                                                value={selectedDest}
                                                options={options}
                                                onChange={handleChangeDest}
                                                className="rounded-sm text-xs sm:text-sm border-gray-300 w-100"
                                                styles={style}
                                            />
                                        }
                                        {submitted &&
                                            (!selectedDest || selectedDest.value === 0) && (
                                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                    Harap isi tujuan
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                                        Jalur<p className="text-red-500">&nbsp;*</p>
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <select
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            id="transportType"
                                            name="transportType"
                                            value={transportType}
                                            onChange={(e) => setTransportType(e.target.value)}
                                        >
                                            <option value="" disabled hidden>
                                                Pilih jalur
                                            </option>
                                            {transportTypeObj &&
                                                transportTypeObj.TransportTypes.map(
                                                    (transportTypeObj) => (
                                                        <option
                                                            key={transportTypeObj.TransportTypeCode}
                                                            value={transportTypeObj.TransportTypeCode}
                                                        >
                                                            {transportTypeObj.TransportTypeName}
                                                        </option>
                                                    )
                                                )}
                                        </select>
                                        {submitted && !transportType && (
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                Harap pilih metode pengiriman
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm font-bold flex">
                                        Pembayaran<p className="text-red-500">&nbsp;*</p>
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <select
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            id="paymentType"
                                            name="paymentType"
                                            value={paymentType}
                                            onChange={(e) => setPaymentType(e.target.value)}
                                        >
                                            <option value="" disabled hidden>
                                                Pilih metode pembayaran
                                            </option>
                                            <option value={masterSettingConstants.PAYTYPE_LUNAS}>
                                                Lunas
                                            </option>
                                            <option
                                                value={masterSettingConstants.PAYTYPE_KREDIT}
                                                hidden={hideCredit}
                                            >
                                                Kredit
                                            </option>
                                            <option
                                                value={masterSettingConstants.PAYTYPE_TAGIHTUJUAN}
                                                hidden={hideCOD}
                                            >
                                                Tagih Tujuan
                                            </option>
                                            <option
                                                value={
                                                    masterSettingConstants.PAYTYPE_KREDITPENERIMA
                                                }
                                                hidden={hideReceiverCredit}
                                            >
                                                Kredit Penerima
                                            </option>
                                        </select>

                                        {submitted && !paymentType && (
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                Harap pilih metode pembayaran
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-xs sm:text-sm font-bold flex">
                                        Jenis Barang<p className="text-red-500">&nbsp;*</p>
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <select
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            id="packageCategory"
                                            name="packageCategory"
                                            value={packageCategory}
                                            onChange={(e) => setPackageCategory(e.target.value)}
                                        >
                                            <option value="" disabled hidden>
                                                Pilih jenis barang
                                            </option>
                                            <option
                                                value={
                                                    masterSettingConstants.PACKAGE_CATEGORY_ELEKTRONIK
                                                }
                                            >
                                                ELEKTRONIK
                                            </option>
                                            <option
                                                value={
                                                    masterSettingConstants.PACKAGE_CATEGORY_NON_ELEKTRONIK
                                                }
                                            >
                                                NON ELEKTRONIK
                                            </option>
                                        </select>
                                        {(submitted || simpanDataBarang) &&
                                            !packageCategory && (
                                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                    Harap pilih jenis barang
                                                </div>
                                            )}
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm font-bold flex">
                                        Tipe Barang<p className="text-red-500">&nbsp;*</p>
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <select
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            id="packageContent"
                                            name="packageContent"
                                            value={packageContent}
                                            onChange={(e) => handlePackageContentChange(e.target.value)}
                                        >
                                            <option value="" disabled hidden>
                                                Pilih tipe barang
                                            </option>
                                            {packageContentsObj &&
                                                packageContentsObj.map((packageContentsObj) => (
                                                    <option
                                                        key={packageContentsObj.PackageContentCode}
                                                        value={packageContentsObj.PackageContentCode}
                                                    >
                                                        {packageContentsObj.PackageContentName}
                                                    </option>
                                                ))}
                                        </select>
                                        {(submitted || simpanDataBarang) && !packageContent && (
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                Harap pilih tipe barang
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-xs sm:text-sm font-bold flex">
                                        Total Koli<p className="text-red-500">&nbsp;*</p>
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <NumericFormat
                                            id="totalKoli"
                                            name="totalKoli"
                                            thousandSeparator={true}
                                            allowLeadingZeros={false}
                                            decimalScale={0}
                                            fixedDecimalScale={true}
                                            className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            onChange={(e) => setTotalKoli(e.target.value)}
                                            value={totalKoli}
                                        />
                                        {submitted && (totalKoli != totalNonCanKoli) && (
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                Total Koli tidak sama dengan detail barang
                                            </div>
                                        )}
                                    </div>
                                    {isInsurance && isInsurance === "1" && (
                                        <>
                                            <div className="col-md-3 text-xs sm:text-sm font-bold flex">
                                                Nilai Barang<p className="text-red-500">&nbsp;*</p>
                                            </div>
                                            <div className="col-md-3 text-xs sm:text-sm">
                                                <NumericFormat
                                                    id="estItemPriceAmt"
                                                    name="estItemPriceAmt"
                                                    thousandSeparator={true}
                                                    decimalScale={0}
                                                    fixedDecimalScale={true}
                                                    className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                                    onChange={(e) => handleNilaiBarang(e)}
                                                    value={nilaiBarang}
                                                    placeholder="Isi Nilai barang"
                                                />
                                                {errors?.nilaiBarang && (
                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                        {errors?.nilaiBarang}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-xs sm:text-sm font-bold">
                                        Catatan Order
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <textarea
                                            id="bookNotes"
                                            name="bookNotes"
                                            rows="3"
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            style={{
                                                boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)",
                                            }}
                                            value={bookingNotes}
                                            onChange={(e) => setBookingNotes(e.target.value)}
                                            placeholder="Isi catatan order"
                                        />
                                    </div>
                                    {(packageContent ===
                                        masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN ||
                                        packageContent ===
                                        masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN ||
                                        isInsurance === "1") && (
                                            <>
                                                <div className="col-md-3 text-xs sm:text-sm font-bold flex">
                                                    Catatan Paket<p className="text-red-500">&nbsp;*</p>
                                                </div>
                                                <div className="col-md-3 text-xs sm:text-sm">
                                                    <textarea
                                                        id="packageContentNotes"
                                                        name="packageContentNotes"
                                                        rows="3"
                                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                                        style={{
                                                            boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)",
                                                        }}
                                                        value={packageContentNotes}
                                                        onChange={(e) =>
                                                            setPackageContentNotes(e.target.value)
                                                        }
                                                        placeholder="Isi catatan paket"
                                                    />
                                                    {submitted && !packageContentNotes && (
                                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                            Harap isi catatan paket
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                </div>
                            </div>
                            <div className="h-full w-full text-xs sm:text-sm font-medium">
                                {!showKoliForm && (
                                    <>
                                        <div className="row px-2 py-1 vertical-center">
                                            <div className="col-md-6 text-sm sm:text-md font-bold vertical-center">
                                                <p style={{ fontSize: 30 }}>Detail Koli</p>
                                            </div>
                                        </div>

                                        <div className="row px-2 py-1 vertical-center">
                                            <div className="col-md-3 text-xs sm:text-sm font-bold">
                                                Estimasi Berat Barang (kg)
                                            </div>
                                            <div className="col-md-3 text-xs sm:text-sm">
                                                <NumericFormat
                                                    id="ItemWeight"
                                                    name="ItemWeight"
                                                    thousandSeparator={true}
                                                    allowLeadingZeros={false}
                                                    decimalScale={2}
                                                    fixedDecimalScale={false}
                                                    className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                                    onChange={(e) => detailKoliOnChange(e, true)}
                                                    value={detailBarangMbl.ItemWeight}
                                                    placeholder="Isi estimasi berat barang"
                                                />
                                            </div>
                                        </div>
                                        <div className="row px-2 py-1 vertical-center">
                                            <div className="col-md-3 text-xs sm:text-sm font-bold">
                                                Panjang (cm)
                                            </div>
                                            <div className="col-md-3 text-xs sm:text-sm">
                                                <NumericFormat
                                                    id="DimP"
                                                    name="DimP"
                                                    thousandSeparator={true}
                                                    allowLeadingZeros={false}
                                                    decimalScale={0}
                                                    fixedDecimalScale={true}
                                                    className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                                    onChange={(e) => detailKoliOnChange(e, true)}
                                                    value={detailBarangMbl.DimP}
                                                    placeholder="Isi dimensi panjang barang"
                                                />
                                            </div>
                                        </div>
                                        <div className="row px-2 py-1 vertical-center">
                                            <div className="col-md-3 text-xs sm:text-sm font-bold">
                                                Lebar (cm)
                                            </div>
                                            <div className="col-md-3 text-xs sm:text-sm">
                                                <NumericFormat
                                                    id="DimL"
                                                    name="DimL"
                                                    thousandSeparator={true}
                                                    allowLeadingZeros={false}
                                                    decimalScale={0}
                                                    fixedDecimalScale={true}
                                                    className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                                    onChange={(e) => detailKoliOnChange(e, true)}
                                                    value={detailBarangMbl.DimL}
                                                    placeholder="Isi dimensi lebar barang"
                                                />
                                            </div>
                                        </div>
                                        <div className="row px-2 py-1 vertical-center">
                                            <div className="col-md-3 text-xs sm:text-sm font-bold">
                                                Tinggi (cm)
                                            </div>
                                            <div className="col-md-3 text-xs sm:text-sm">
                                                <NumericFormat
                                                    id="DimT"
                                                    name="DimT"
                                                    thousandSeparator={true}
                                                    allowLeadingZeros={false}
                                                    decimalScale={0}
                                                    fixedDecimalScale={true}
                                                    className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                                    onChange={(e) => detailKoliOnChange(e, true)}
                                                    value={detailBarangMbl.DimT}
                                                    placeholder="Isi dimensi tinggi barang"
                                                />
                                            </div>
                                        </div>
                                        <div className="row px-2 py-1 justify-center">
                                            <div className="col-md-3 text-xs sm:text-sm font-bold items-top">
                                                Packing
                                            </div>
                                            <div className="col-md-3 text-xs sm:text-sm flex flex-col">
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="PackingKayu"
                                                        name="PackingKayu"
                                                        checked={detailBarangMbl.PackingKayu}
                                                        onChange={(e) => detailKoliOnChange(e, false)}
                                                    />
                                                    &nbsp;Kayu
                                                </div>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="BubbleWrap"
                                                        name="BubbleWrap"
                                                        checked={detailBarangMbl.BubbleWrap}
                                                        onChange={(e) => detailKoliOnChange(e, false)}
                                                    />
                                                    &nbsp;Bubble Wrap
                                                </div>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="Wrapping"
                                                        name="Wrapping"
                                                        checked={detailBarangMbl.Wrapping}
                                                        onChange={(e) => detailKoliOnChange(e, false)}
                                                    />
                                                    &nbsp;Wrapping
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-2 py-1">
                                            <div className="row px-2 py-1 vertical-center">
                                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                                    <button
                                                        type="button"
                                                        className="w-100 mx-2 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-yellow-600"
                                                        onClick={() => {
                                                            setShowKoliForm(true);
                                                            setShowPenerimaForm(true);
                                                        }}
                                                    >
                                                        Batal
                                                    </button>
                                                </div>
                                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                                    <button
                                                        type="button"
                                                        className="w-100 mx-2 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                                                        onClick={(e) => {
                                                            onSubmitDetailBarang();
                                                            setShowKoliForm(true);
                                                            setShowPenerimaForm(true);
                                                        }}
                                                    >
                                                        Simpan Data Koli
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {showKoliForm && (
                                    <>
                                        <div className="px-2 py-1">
                                            <div
                                                className="vertical-center text-xs sm:text-sm font-bold"
                                                style={{
                                                    alignContent: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <button
                                                    type="button"
                                                    className="w-25 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                                                    onClick={(e) => {
                                                        generateKoli();
                                                    }}
                                                >
                                                    Generate Koli
                                                </button>
                                            </div>
                                        </div>
                                        <div className="px-2 py-1">
                                            <TableDetailBarangEdit
                                                arrTempBarang={arrTempBarang}
                                                setDetailBarangMbl={setDetailBarangMbl}
                                                setIsEditKoli={setIsEditKoli}
                                                handleCancel={handleCancel}
                                            />
                                        </div>
                                        <div className="px-2 py-1">
                                            <div className="row px-2 py-1">
                                                <label
                                                    className="col-md-3"
                                                    style={{ fontWeight: "bold" }}
                                                >
                                                    Total Barang
                                                </label>
                                                <label className="col-md-3">
                                                    {respCalcObj && arrDetailBarangMbl.length > 0
                                                        ? new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 2,
                                                        }).format(respCalcObj.TotalItem)
                                                        : new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 2,
                                                        }).format(0)}
                                                </label>
                                                <label
                                                    className="col-md-3"
                                                    style={{ fontWeight: "bold" }}
                                                >
                                                    Estimasi Berat Final (Kg)
                                                </label>
                                                <label className="col-md-3">
                                                    {respCalcObj && arrDetailBarangMbl.length > 0
                                                        ? new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 2,
                                                        }).format(respCalcObj.TotalFinalWeight)
                                                        : new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 2,
                                                        }).format(0)}
                                                </label>
                                            </div>

                                            {false && (
                                                <>
                                                    <div className="row px-2 py-1">
                                                        <label
                                                            className="col-md-3"
                                                            style={{ fontWeight: "bold" }}
                                                        >
                                                            Estimasi Ongkir
                                                        </label>
                                                        <label className="col-md-3">
                                                            {respCalcObj && arrDetailBarangMbl.length > 0
                                                                ? new Intl.NumberFormat(
                                                                    "en",
                                                                    {
                                                                        minimumFractionDigits: 0,
                                                                    }
                                                                ).format(respCalcObj.EstMainChargeAmt)
                                                                : 0}
                                                        </label>
                                                        <label
                                                            className="col-md-3"
                                                            style={{ fontWeight: "bold" }}
                                                        >
                                                            Estimasi Asuransi
                                                        </label>
                                                        <label className="col-md-3">
                                                            {respCalcObj && arrDetailBarangMbl.length > 0
                                                                ? new Intl.NumberFormat(
                                                                    "en",
                                                                    {
                                                                        minimumFractionDigits: 0,
                                                                    }
                                                                ).format(respCalcObj.EstAddChargeAmt)
                                                                : 0}
                                                        </label>
                                                    </div>
                                                    <div className="row px-2 py-1">
                                                        <label
                                                            className="col-md-3"
                                                            style={{ fontWeight: "bold" }}
                                                        >
                                                            Estimasi Tagih Pengirim
                                                        </label>
                                                        <label className="col-md-3">
                                                            {respCalcObj && arrDetailBarangMbl.length > 0
                                                                ? new Intl.NumberFormat(
                                                                    "en",
                                                                    {
                                                                        minimumFractionDigits: 0,
                                                                    }
                                                                ).format(respCalcObj.EstSndrMustBePaidAmt)
                                                                : 0}
                                                        </label>
                                                        <label
                                                            className="col-md-3"
                                                            style={{ fontWeight: "bold" }}
                                                        >
                                                            Estimasi Tagih Penerima
                                                        </label>
                                                        <label className="col-md-3">
                                                            {respCalcObj && arrDetailBarangMbl.length > 0
                                                                ? new Intl.NumberFormat(
                                                                    "en",
                                                                    {
                                                                        minimumFractionDigits: 0,
                                                                    }
                                                                ).format(
                                                                    respCalcObj.EstRcpntMustBePaidAmt
                                                                )
                                                                : 0}
                                                        </label>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    <div className="flex justify-end">
                        {showPenerimaForm && showKoliForm && (
                            <button
                                type="button"
                                className="mx-3 w-40 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-yellow-600"
                                onClick={(e) => {
                                    history.push(`/booking-details?custId=${acc.CustId}&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}`);
                                }}
                            >
                                Batal
                            </button>
                        )}
                        {showPenerimaForm && showKoliForm && (
                            <button
                                type="submit"
                                className="mx-3 w-40 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </form>
            </div>

            <div className="block sm:hidden w-full">
                <div className="bg-blue-600">
                    <h4 className="flex justify-center text-xl font-semibold text-white py-2">
                        Buat Booking
                    </h4>
                </div>
                <div className="mx-2">
                    <AlertMessage
                        isError={isError}
                        alert={alert}
                        setAlert={setAlert}
                        setIsError={setIsError}
                    />
                </div>

                <form onSubmit={handleSubmit} method="POST">
                    {showPenerimaForm && (
                        <div className="flex flex-col text-xs sm:text-sm px-2">
                            <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                    Alias Pengirim<p className="text-red-500">&nbsp;*</p>
                                </div>
                                <div
                                    className={`flex-1 border-b-2 ${submitted && !sellerAliasName
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm py-1`}>
                                    <input
                                        id="sellerAliasName"
                                        type="text"
                                        name="sellerAliasName"
                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                        placeholder="Isi nama alias pengirim"
                                        value={sellerAliasName}
                                        onChange={(e) => setSellerAliasName(e.target.value)}
                                    />
                                </div>
                                {submitted && !sellerAliasName && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 py-1">
                                        Harap isi alias pengirim
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                    Nomor Ponsel Pengirim<p className="text-red-500">&nbsp;*</p>
                                </div>
                                <div
                                    className={`flex-1 border-b-2 ${submitted && !sellerMobilePhnNo
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm py-1`}
                                >
                                    <Input
                                        className="py-1 text-xs sm:text-sm w-100 border-none outline-none rounded-sm border-gray-300"
                                        country="ID"
                                        value={sellerMobilePhnNo}
                                        onChange={setSellerMobilePhnNo}
                                        placeholder="Isi nomor ponsel pengirim"
                                    />
                                </div>
                                {submitted && !sellerMobilePhnNo && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 py-1">
                                        Harap isi nomor ponsel pengirim
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                    Nama Penerima<p className="text-red-500">&nbsp;*</p>
                                </div>
                                <div
                                    className={`flex-1 border-b-2 py-1 ${submitted && !recipientName
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm`}
                                >
                                    <input
                                        id="recipientName"
                                        type="text"
                                        name="recipientName"
                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                        placeholder="Isi nama penerima"
                                        value={recipientName}
                                        onChange={(e) => setRecipientName(e.target.value)}
                                    />
                                </div>
                                {submitted && !recipientName && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                        Harap isi nama penerima
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                    Nomor Ponsel Penerima
                                </div>
                                <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs sm:text-sm">
                                    <Input
                                        id="recipientMobilePhnNo"
                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                        country="ID"
                                        value={recipientMobilePhnNo}
                                        onChange={setRecipientMobilePhnNo}
                                        placeholder="Isi nomor ponsel penerima"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                    Alamat Penerima
                                </div>
                                <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs sm:text-sm">
                                    <input
                                        id="recipientAddress"
                                        name="recipientAddress"
                                        placeholder="Isi alamat penerima"
                                        type="text"
                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                        value={recipientAddress}
                                        onChange={(e) => setRecipientAddress(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm flex flex-row items-center">
                                    Nomor Referensi{acc.IsCustRefNoMandatory && <p className="text-red-500">&nbsp;*</p>}
                                </div>
                                <div
                                    className={`flex-1 border-b-2 ${acc.IsCustRefNoMandatory && submitted && !referenceNo
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm py-1`}
                                >
                                    <input
                                        id="referenceNo"
                                        type="text"
                                        name="referenceNo"
                                        placeholder="Isi customer reference no"
                                        maxLength="50"
                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                        onChange={(e) => setReferenceNo(e.target.value)}
                                        value={referenceNo}
                                    />
                                </div>
                                {acc.IsCustRefNoMandatory && submitted && !referenceNo && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 py-1">
                                        Harap isi Nomor Referensi.
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                    Tujuan<p className="text-red-500">&nbsp;*</p>
                                </div>
                                <div
                                    className={`flex-1 border-b-2 ${submitted &&
                                        (!selectedDest || selectedDest.value === 0)
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm py-1`}
                                >
                                    {
                                        districtObj && selectedDest &&
                                        <Select
                                            filterOption={createFilter({ ignoreAccents: false })}
                                            value={selectedDest}
                                            options={options}
                                            onChange={handleChangeDest}
                                            className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                            styles={styleMobile}
                                        />
                                    }
                                </div>
                                {submitted &&
                                    (!selectedDest || selectedDest.value === 0) && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                            Harap isi tujuan
                                        </div>
                                    )}
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                    Jalur<p className="text-red-500">&nbsp;*</p>
                                </div>
                                <div
                                    className={`flex-1 border-b-2 ${submitted && !transportType
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm py-1`}
                                >
                                    <select
                                        className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                        id="transportType"
                                        name="transportType"
                                        value={transportType}
                                        onChange={(e) => setTransportType(e.target.value)}
                                    >
                                        <option value="" disabled hidden>
                                            Pilih jalur
                                        </option>
                                        {transportTypeObj &&
                                            transportTypeObj.TransportTypes.map(
                                                (transportTypeObj) => (
                                                    <option
                                                        key={transportTypeObj.TransportTypeCode}
                                                        value={transportTypeObj.TransportTypeCode}
                                                    >
                                                        {transportTypeObj.TransportTypeName}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                </div>
                                {submitted && !transportType && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                        Harap pilih metode pengiriman
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                    Pembayaran<p className="text-red-500">&nbsp;*</p>
                                </div>
                                <div
                                    className={`flex-1 border-b-2 ${submitted && !paymentType
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm py-1`}
                                >
                                    <select
                                        className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                        id="paymentType"
                                        name="paymentType"
                                        value={paymentType}
                                        onChange={(e) => setPaymentType(e.target.value)}
                                    >
                                        <option value="" disabled hidden>
                                            Pilih metode pembayaran
                                        </option>
                                        <option value={masterSettingConstants.PAYTYPE_LUNAS}>
                                            Lunas
                                        </option>
                                        <option
                                            value={masterSettingConstants.PAYTYPE_KREDIT}
                                            hidden={hideCredit}
                                        >
                                            Kredit
                                        </option>
                                        <option
                                            value={masterSettingConstants.PAYTYPE_TAGIHTUJUAN}
                                            hidden={hideCOD}
                                        >
                                            Tagih Tujuan
                                        </option>
                                        <option
                                            value={
                                                masterSettingConstants.PAYTYPE_KREDITPENERIMA
                                            }
                                            hidden={hideReceiverCredit}
                                        >
                                            Kredit Penerima
                                        </option>
                                    </select>
                                </div>
                                {submitted && !paymentType && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                        Harap pilih metode pembayaran
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                    Jenis Barang<p className="text-red-500">&nbsp;*</p>
                                </div>
                                <div
                                    className={`flex-1 border-b-2 ${(submitted || simpanDataBarang) &&
                                        !packageCategory
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm py-1`}
                                >
                                    <select
                                        className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                        id="packageCategory"
                                        name="packageCategory"
                                        value={packageCategory}
                                        onChange={(e) => setPackageCategory(e.target.value)}
                                    >
                                        <option value="" disabled hidden>
                                            Pilih jenis barang
                                        </option>
                                        <option
                                            value={
                                                masterSettingConstants.PACKAGE_CATEGORY_ELEKTRONIK
                                            }
                                        >
                                            ELEKTRONIK
                                        </option>
                                        <option
                                            value={
                                                masterSettingConstants.PACKAGE_CATEGORY_NON_ELEKTRONIK
                                            }
                                        >
                                            NON ELEKTRONIK
                                        </option>
                                    </select>
                                </div>
                                {(submitted || simpanDataBarang) &&
                                    !packageCategory && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                            Harap pilih jenis barang
                                        </div>
                                    )}
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                    Tipe Barang<p className="text-red-500">&nbsp;*</p>
                                </div>
                                <div
                                    className={`flex-1 border-b-2 ${(submitted || simpanDataBarang) && !packageContent
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm py-1`}
                                >
                                    <select
                                        className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                        id="packageContent"
                                        name="packageContent"
                                        value={packageContent}
                                        onChange={(e) => handlePackageContentChange(e.target.value)}
                                    >
                                        <option value="" disabled hidden>
                                            Pilih tipe barang
                                        </option>
                                        {packageContentsObj &&
                                            packageContentsObj.map((packageContentsObj) => (
                                                <option
                                                    key={packageContentsObj.PackageContentCode}
                                                    value={packageContentsObj.PackageContentCode}
                                                >
                                                    {packageContentsObj.PackageContentName}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                {(submitted || simpanDataBarang) && !packageContent && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                        Harap pilih tipe barang
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                    Total Koli<p className="text-red-500">&nbsp;*</p>
                                </div>
                                <div
                                    className={`flex-1 border-b-2 py-1 ${submitted && (totalKoli != arrDetailBarangMbl.length && totalKoli != totalNonCanKoli)
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm`}
                                >
                                    <NumericFormat
                                        id="totalKoli"
                                        name="totalKoli"
                                        thousandSeparator={true}
                                        allowLeadingZeros={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                        onChange={(e) => setTotalKoli(e.target.value)}
                                        value={totalKoli}
                                    />
                                </div>
                                {submitted && (totalKoli != arrDetailBarangMbl.length && totalKoli != totalNonCanKoli) && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                        Total Koli tidak sama dengan jumlah detail barang
                                    </div>
                                )}
                            </div>
                            {isInsurance && isInsurance === "1" && (
                                <div className="flex flex-col mx-2 py-1">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        Nilai Barang<p className="text-red-500">&nbsp;*</p>
                                    </div>
                                    <div
                                        className={`flex-1 border-b-2 py-1 ${(submitted || simpanDataBarang) &&
                                            nilaiBarang <= 0 &&
                                            isInsurance == "1"
                                            ? "border-red-500"
                                            : "border-gray-300"
                                            } text-xs sm:text-sm`}
                                    >
                                        <NumericFormat
                                            id="estItemPriceAmt"
                                            name="estItemPriceAmt"
                                            thousandSeparator={true}
                                            decimalScale={0}
                                            fixedDecimalScale={true}
                                            className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                            onChange={(e) => handleNilaiBarang(e)}
                                            value={nilaiBarang}
                                            placeholder="Isi Nilai barang"
                                        />
                                    </div>
                                    {errors?.nilaiBarang && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            {errors?.nilaiBarang}
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                    Catatan Order
                                </div>
                                <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs sm:text-sm">
                                    <input
                                        id="bookNotes"
                                        name="bookNotes"
                                        placeholder="Isi catatan order"
                                        type="text"
                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                        value={bookingNotes}
                                        onChange={(e) => setBookingNotes(e.target.value)}
                                    />
                                </div>
                            </div>
                            {(packageContent ===
                                masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN ||
                                packageContent ===
                                masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN ||
                                isInsurance === "1") && (
                                    <div className="flex flex-col mx-2 py-1">
                                        <div className="flex-1 text-xs sm:text-sm flex items-center">
                                            Catatan Paket<p className="text-red-500">&nbsp;*</p>
                                        </div>
                                        <div
                                            className={`flex-1 border-b-2 py-1 ${submitted && !packageContentNotes
                                                ? "border-red-500"
                                                : "border-gray-300"
                                                } text-xs sm:text-sm`}
                                        >
                                            <input
                                                id="packageContentNotes"
                                                name="packageContentNotes"
                                                placeholder="Isi catatan paket"
                                                type="text"
                                                className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                value={packageContentNotes}
                                                onChange={(e) => setPackageContentNotes(e.target.value)}
                                            />
                                        </div>
                                        {submitted && !packageContentNotes && (
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                Harap isi catatan paket
                                            </div>
                                        )}
                                    </div>
                                )}
                            {!showKoliForm && (
                                <>
                                    <div className="row px-2 py-1 vertical-center">
                                        <div className="col-md-6 text-xs font-semibold vertical-center">
                                            <p style={{ fontSize: 20 }}>Detail Koli</p>
                                        </div>
                                    </div>

                                    <div className="flex flex-col mx-2 py-1">
                                        <div className="flex-1 text-xs flex items-center">
                                            Estimasi Berat Barang (kg)
                                        </div>
                                        <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs">
                                            <NumericFormat
                                                id="ItemWeight"
                                                name="ItemWeight"
                                                thousandSeparator={true}
                                                allowLeadingZeros={false}
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                className="py-1 text-xs border-none outline-none w-100"
                                                onChange={(e) => detailKoliOnChange(e, true)}
                                                value={detailBarangMbl.ItemWeight}
                                                placeholder="Isi estimasi berat barang"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col mx-2 py-1">
                                        <div className="flex-1 text-xs flex items-center">
                                            Panjang (cm)
                                        </div>
                                        <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs">
                                            <NumericFormat
                                                id="DimP"
                                                name="DimP"
                                                thousandSeparator={true}
                                                allowLeadingZeros={false}
                                                decimalScale={0}
                                                fixedDecimalScale={true}
                                                className="py-1 text-xs border-none outline-none w-100"
                                                onChange={(e) => detailKoliOnChange(e, true)}
                                                value={detailBarangMbl.DimP}
                                                placeholder="Isi dimensi panjang barang"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col mx-2 py-1">
                                        <div className="flex-1 text-xs flex items-center">
                                            Lebar (cm)
                                        </div>
                                        <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs">
                                            <NumericFormat
                                                id="DimL"
                                                name="DimL"
                                                thousandSeparator={true}
                                                allowLeadingZeros={false}
                                                decimalScale={0}
                                                fixedDecimalScale={true}
                                                className="py-1 text-xs border-none outline-none w-100"
                                                onChange={(e) => detailKoliOnChange(e, true)}
                                                value={detailBarangMbl.DimL}
                                                placeholder="Isi dimensi lebar barang"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col mx-2 py-1">
                                        <div className="flex-1 text-xs flex items-center">
                                            Tinggi (cm)
                                        </div>
                                        <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs">
                                            <NumericFormat
                                                id="DimT"
                                                name="DimT"
                                                thousandSeparator={true}
                                                allowLeadingZeros={false}
                                                decimalScale={0}
                                                fixedDecimalScale={true}
                                                className="py-1 text-xs border-none outline-none w-100"
                                                onChange={(e) => detailKoliOnChange(e, true)}
                                                value={detailBarangMbl.DimT}
                                                placeholder="Isi dimensi tinggi barang"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col mx-2 py-1">
                                        <div className="flex-1 text-xs flex items-center font-medium items-top">
                                            Packing
                                        </div>
                                        <div className="flex-1 flex flex-row border-b-2 py-1 border-gray-300 text-xs">
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="PackingKayu"
                                                    name="PackingKayu"
                                                    className="py-1 text-xs"
                                                    checked={detailBarangMbl.PackingKayu}
                                                    onChange={(e) => detailKoliOnChange(e, false)}
                                                />
                                                &nbsp;Kayu
                                            </div>&nbsp;&nbsp;&nbsp;
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="BubbleWrap"
                                                    name="BubbleWrap"
                                                    className="py-1 text-xs"
                                                    checked={detailBarangMbl.BubbleWrap}
                                                    onChange={(e) => detailKoliOnChange(e, false)}
                                                />
                                                &nbsp;Bubble Wrap
                                            </div>&nbsp;&nbsp;&nbsp;
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="Wrapping"
                                                    name="Wrapping"
                                                    className="py-1 text-xs"
                                                    checked={detailBarangMbl.Wrapping}
                                                    onChange={(e) => detailKoliOnChange(e, false)}
                                                />
                                                &nbsp;Wrapping
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row py-1 vertical-center justify-around">
                                        <div className="w-40 mx-1 text-xs font-bold">
                                            <button
                                                type="button"
                                                className="w-100 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-yellow-600"
                                                onClick={() => {
                                                    setShowKoliForm(true);
                                                    setShowPenerimaForm(true);
                                                }}
                                            >
                                                Batal
                                            </button>
                                        </div>
                                        <div className="w-40 mx-1 text-xs font-bold">
                                            <button
                                                type="button"
                                                className="w-100 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                                                onClick={(e) => {
                                                    onSubmitDetailBarang();
                                                    setShowKoliForm(true);
                                                    setShowPenerimaForm(true);
                                                }}
                                            >
                                                Simpan Data Koli
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                            {showKoliForm && (
                                <>
                                    <div className="justify-center items-center flex flex-row py-3 text-xs">
                                        <button
                                            type="button"
                                            className="w-100 mx-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                                            onClick={(e) => {
                                                generateKoli();
                                            }}
                                        >
                                            Generate Koli
                                        </button>
                                    </div>
                                    <div className="px-2 py-1">
                                        <TableDetailBarangEdit
                                            arrTempBarang={arrTempBarang}
                                            setDetailBarangMbl={setDetailBarangMbl}
                                            setIsEditKoli={setIsEditKoli}
                                            handleCancel={handleCancel}
                                        />
                                    </div>
                                    <div className="flex flex-col px-2 pt-2">
                                        <div className="flex flex-row">
                                            <label
                                                className="w-28"
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Total Barang
                                            </label>
                                            <label
                                                className="w-28">
                                                {respCalcObj && arrDetailBarangMbl.length > 0
                                                    ? new Intl.NumberFormat("en", {
                                                        minimumFractionDigits: 2,
                                                    }).format(respCalcObj.TotalItem)
                                                    : new Intl.NumberFormat("en", {
                                                        minimumFractionDigits: 2,
                                                    }).format(0)}
                                            </label>
                                        </div>
                                        <div className="flex flex-row">
                                            <label
                                                className="w-28"
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Estimasi Berat Final (Kg)
                                            </label>
                                            <label
                                                className="w-28">
                                                {respCalcObj && arrDetailBarangMbl.length > 0
                                                    ? new Intl.NumberFormat("en", {
                                                        minimumFractionDigits: 2,
                                                    }).format(respCalcObj.TotalFinalWeight)
                                                    : new Intl.NumberFormat("en", {
                                                        minimumFractionDigits: 2,
                                                    }).format(0)}
                                            </label>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    <div className="justify-center items-center flex flex-row py-3 text-xs">
                        {showPenerimaForm && showKoliForm && (
                            <button
                                type="button"
                                className="w-100 mx-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-yellow-600"
                                onClick={(e) => {
                                    history.push(`/booking-details?custId=${acc.CustId}&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}`);
                                }}
                            >
                                Batal
                            </button>
                        )}
                        {showPenerimaForm && showKoliForm && (
                            <button
                                type="submit"
                                className="w-100 mx-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};


export default BookingDetailEditV2;