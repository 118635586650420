import { combineReducers } from "redux";
import { authentication, resetPassword } from "./authentication.reducer";
import {
    bookings,
    bookingDetails,
    bookingViewH,
    bookingViewD,
    calculateBookingPrice,
    submitBooking,
    printBookings,
    exportBookingDetails,
    uploadBooking,
    getCustBookHDetail,
    recipientListBooking,
    cancelKoliBooking
} from "./booking.reducer";
import { destination } from "./destination.reducer";
import { packagecontent } from "./packagecontent.reducer";
import { district } from "./district.reducer";
import { accounts } from "./acc.reducer";
import {
    pickupAddr,
    pickupMonitoring,
    pickupOrderHInfo,
    pickupOrderDetail,
    pickupReqMonitoring,
    pickupReqDetail,
    pickupReqGrpAddEdit,
    pickupAddrById,
    pickupAddrList,
    selectedPickupAddr
} from "./pickup.reducer";
import {
    pengirimanMonitoring,
    pengirimanDetail,
    exportPengirimanData,
} from "./pengiriman.reducer";
import { penerimaanMonitoring } from "./penerimaan.reducer";
import {
    resiHInfo,
    addResiComplaint,
    getComplaintMessages,
    printResi,
    printResiBulky
} from "./resi.reducer";
import {
    summaryPengirimanDashboard,
    summaryPenerimaanDashboard,
    summaryKomplainDashboard,
    summaryBookingDashboard,
    summaryPickupReqDashboard,
    checkOngkir,
    banner
} from "./dashboard.reducer";
import { origin } from "./origin.reducer";
import { transportType } from "./transporttype.reducer";
import {
    profileData, custPointHistTrx, custPointRedemptionTrx, getQrCodeMember, sendEmailQrCodeCust
} from "./profile.reducer";
import { uploadMonitoring, uploadDetail } from "./upload.reducer";
import { custById, penerimaByHint, penerimaById } from "./customer.reducer";

const rootReducer = combineReducers({
    authentication,
    bookings,
    bookingDetails,
    bookingViewH,
    bookingViewD,
    destination,
    packagecontent,
    district,
    calculateBookingPrice,
    pickupAddr,
    submitBooking,
    pengirimanMonitoring,
    pengirimanDetail,
    resiHInfo,
    addResiComplaint,
    penerimaanMonitoring,
    exportPengirimanData,
    printBookings,
    pickupMonitoring,
    pickupOrderHInfo,
    pickupOrderDetail,
    summaryPengirimanDashboard,
    summaryPenerimaanDashboard,
    summaryKomplainDashboard,
    summaryBookingDashboard,
    summaryPickupReqDashboard,
    origin,
    checkOngkir,
    transportType,
    getComplaintMessages,
    pickupReqMonitoring,
    pickupReqDetail,
    pickupReqGrpAddEdit,
    pickupAddrById,
    exportBookingDetails,
    profileData,
    custPointHistTrx,
    custPointRedemptionTrx,
    uploadMonitoring,
    uploadDetail,
    uploadBooking,
    custById,
    penerimaByHint,
    penerimaById,
    resetPassword,
    banner,
    printResi,
    pickupAddrList,
    selectedPickupAddr,
    getCustBookHDetail,
    recipientListBooking,
    cancelKoliBooking,
    getQrCodeMember,
    sendEmailQrCodeCust,
    printResiBulky
});

export default rootReducer;
