import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../_actions";
import _ from "lodash";
import { Link } from "react-router-dom";
import { ShortcutDate } from "../../components";
import moment from "moment";

const SummaryDashboard = ({ t }) => {
  const acc = useSelector((state) => state.authentication.accInfo);
  const pickupAddrSelectedObj = useSelector((state) => state.selectedPickupAddr.selectedPickupAddrObj);

  const summaryPengirimanDashboard = useSelector(
    (state) => state.summaryPengirimanDashboard.summaryPengirimanDashboard
  );
  const summaryPenerimaanDashboard = useSelector(
    (state) => state.summaryPenerimaanDashboard.summaryPenerimaanDashboard
  );
  const summaryKomplainDashboard = useSelector(
    (state) => state.summaryKomplainDashboard.summaryKomplainDashboard
  );
  const summaryBookingDashboard = useSelector(
    (state) => state.summaryBookingDashboard.summaryBookingDashboard
  );
  const summaryPickupReqDashboard = useSelector(
    (state) => state.summaryPickupReqDashboard.summaryPickupReqDashboard
  );

  const maxDate = new Date();
  const minDate = new Date();
  const finalMinDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);

  const dispatch = useDispatch();

  const [requestObj, setRequestObj] = useState({
    trxDtFrom: dateParser(finalMinDate),
    trxDtTo: dateParser(maxDate),
  });
  const [shortcut, setShortcut] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState({
    Type: "",
    StatusMessage: "",
  });

  useEffect(() => {
    hitSummaryDashboard(true);
    setSubmitted(true);
  }, []);

  function dateParser(value) {
    const date = new Date(value);
    const dateParsed =
      date.getFullYear() +
      "-" +
      date.toLocaleString("en-us", { month: "2-digit" }) +
      "-" +
      date.toLocaleString("en-us", { day: "2-digit" });
    return dateParsed;
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setRequestObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleSearch(e) {
    e.preventDefault();

    setSubmitted(true);
    hitSummaryDashboard();
  }

  function hitSummaryDashboard(firstLoad = false) {
    if (requestObj.trxDtFrom && requestObj.trxDtTo && acc && acc.CustId) {
      var reqObj = {
        CustId: acc.CustId,
        TrxDtFrom: requestObj.trxDtFrom,
        TrxDtTo: requestObj.trxDtTo,
        AddrType: (acc.IsSubAccount === true && pickupAddrSelectedObj) ? pickupAddrSelectedObj.AddrType : "" ,
        PickupReqGrpId: (acc.IsSubAccount === true && pickupAddrSelectedObj) ? pickupAddrSelectedObj.PickupReqGrpId : null 
      };

      var reqSummaryBookingDashboardObj = {
        ...reqObj,
        SummaryType: "Booking",
      };
      dispatch(
        dashboardActions.summaryBookingDashboard(reqSummaryBookingDashboardObj)
      );

      var reqSummaryPickupReqDashboardObj = {
        ...reqObj,
        SummaryType: "PickupReq",
      };
      dispatch(
        dashboardActions.summaryPickupReqDashboard(
          reqSummaryPickupReqDashboardObj
        )
      );

      var reqSummaryPengirimanDashboardObj = {
        ...reqObj,
        SummaryType: "Pengiriman",
      };
      dispatch(
        dashboardActions.summaryPengirimanDashboard(
          reqSummaryPengirimanDashboardObj
        )
      );

      var reqSummaryPenerimaanDashboardObj = {
        ...reqObj,
        SummaryType: "Penerimaan",
      };
      dispatch(
        dashboardActions.summaryPenerimaanDashboard(
          reqSummaryPenerimaanDashboardObj
        )
      );

      var reqSummaryKomplainDashboardObj = {
        CustId: acc.CustId,
        TrxDtFrom: firstLoad
          ? moment().subtract(7, "d").format("YYYY-MM-DD")
          : moment(requestObj.trxDtTo).subtract(7, "d").format("YYYY-MM-DD"),
        TrxDtTo: firstLoad ? moment().format("YYYY-MM-DD") : requestObj.trxDtTo,
        SummaryType: "Komplain",
        AddrType: (acc.IsSubAccount === true && pickupAddrSelectedObj.AddrType) ? pickupAddrSelectedObj.AddrType : "" ,
      };
      dispatch(
        dashboardActions.summaryKomplainDashboard(
          reqSummaryKomplainDashboardObj
        )
      );
    }
  }

  const getDate = (dateObj) => {
    setRequestObj({
      trxDtFrom: dateObj.dateFrom,
      trxDtTo: dateObj.dateTo,
    });

    setShortcut(true);
  };

  useEffect(() => {
    if (shortcut) {
      hitSummaryDashboard();
      setSubmitted(true);
      setShortcut(false);
    }
  }, [shortcut]);
  
  useEffect(() => {
    const interval = setInterval(() => {
        console.log("****GET AFTER 5 MINUTES****")
        hitSummaryDashboard();
        setSubmitted(true);
    }, 300000); //set your time here. repeat every 5 minutes
  
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="hidden sm:block">
        <h1 className="py-2 mx-3 text-xl flex font-bold justify-center">
          Summary
        </h1>
        <form onSubmit={handleSearch} method="POST">
          <div className="justify-center px-4 flex flex-row text-xs sm:text-sm">
            <label className="px-3 flex justify-center items-center">
              Tanggal Awal Transaksi
            </label>
            <input
              aria-label="Tanggal Awal Transaksi"
              id="trxDtFrom"
              name="trxDtFrom"
              type="date"
              value={requestObj.trxDtFrom}
              onChange={handleChange}
              className="rounded-sm px-3 text-xs sm:text-sm border border-gray-300"
            />
            {submitted && !requestObj.trxDtFrom && (
              <div className="px-3 flex justify-center items-center text-red-500 text-xs sm:text-sm">
                Tidak boleh kosong
              </div>
            )}
            <label className="px-3 flex justify-center items-center">s.d</label>
            <input
              aria-label="Tanggal Akhir Transaksi"
              id="trxDtTo"
              name="trxDtTo"
              type="date"
              value={requestObj.trxDtTo}
              onChange={handleChange}
              className="rounded-sm px-3 text-xs sm:text-sm border border-gray-300"
            />
            {submitted && !requestObj.trxDtTo && (
              <div className="px-3 flex justify-center items-center text-red-500 text-xs sm:text-sm">
                Tidak boleh kosong
              </div>
            )}
            <button
              type="submit"
              className="relative flex justify-center items-center text-xs sm:text-sm mx-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
            >
              Search
            </button>
          </div>
        </form>
        <ShortcutDate onSubmit={getDate} />
        <div className="flex justify-center content-center pt-3 text-xs sm:text-sm">
          {
            <div className="rounded-md grid grid-rows-2 w-52 h-40 mx-2 shadow-md hover:shadow-xl transition-shadow">
              <div className="flex bg-white flex-col justify-center items-center">
                <div className="h-2 w-full -mt-3 mb-4 rounded-t-lg bg-red-600"></div>
                <div className="font-bold">Booking</div>
                <Link
                  target={"_blank"}
                  to={{
                    pathname: `/booking-monitor?trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : "" : "")}`,
                  }}
                  style={{ color: "#000000" }}
                >
                  <div>
                    {summaryBookingDashboard == null
                      ? 0
                      : summaryBookingDashboard.TotalData.toLocaleString(
                        "en",
                        { minimumFractionDigits: 0 }
                      )}
                  </div>
                </Link>
              </div>
              <div className="bg-white grid grid-cols-2 gap-0">
                <div className="flex flex-col justify-center items-center">
                  <div className="h-2 w-full bg-yellow-500"></div>
                  <div className="font-bold">Drop</div>
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/booking-monitor?dropType=DROP&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : "" : "")}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryBookingDashboard == null
                        ? 0
                        : summaryBookingDashboard.Drop.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
                <div className="bg-white flex flex-col justify-center items-center">
                  <div className="h-2 w-full bg-green-500"></div>
                  <div className="font-bold">Pickup</div>
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/booking-monitor?dropType=PICKUP&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : "" : "")}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryBookingDashboard == null
                        ? 0
                        : summaryBookingDashboard.Pickup.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          }
          {
            <div className="rounded-md grid grid-rows-2 w-64 h-40 mx-2 shadow-md hover:shadow-xl transition-shadow">
              <div className="flex bg-white flex-col justify-center items-center">
                <div className="h-2 w-full -mt-3 mb-4 rounded-t-lg bg-red-600"></div>
                <div className="font-bold">Pickup Request</div>
                <Link
                  target={"_blank"}
                  to=
                  {{
                    pathname: `/PickupReqMonitoring?trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&pickupReqGrpId=${(acc && acc.IsSubAccount === true && pickupAddrSelectedObj ? pickupAddrSelectedObj.PickupReqGrpId : "")}`,
                  }}
                  style={{ color: "#000000" }}
                >
                  <div>
                    {summaryPickupReqDashboard == null
                      ? 0
                      : summaryPickupReqDashboard.TotalData.toLocaleString(
                        "en",
                        { minimumFractionDigits: 0 }
                      )}
                  </div>
                </Link>
              </div>
              <div className="bg-white grid grid-cols-3 gap-0">
                <div className="flex flex-col justify-center items-center">
                  <div className="h-2 w-full bg-blue-500"></div>
                  <div className="font-bold">New</div>
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/PickupReqMonitoring?process=NEW&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&pickupReqGrpId=${(acc && acc.IsSubAccount === true && pickupAddrSelectedObj ? pickupAddrSelectedObj.PickupReqGrpId : "")}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryPickupReqDashboard == null
                        ? 0
                        : summaryPickupReqDashboard.New.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
                <div className="bg-white flex flex-col justify-center items-center">
                  <div className="h-2 w-full bg-yellow-500"></div>
                  <div className="font-bold">On Process</div>
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/PickupReqMonitoring?process=ONPROCESS&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&pickupReqGrpId=${(acc && acc.IsSubAccount === true && pickupAddrSelectedObj ? pickupAddrSelectedObj.PickupReqGrpId : "")}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryPickupReqDashboard == null
                        ? 0
                        : summaryPickupReqDashboard.OnProcess.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
                <div className="bg-white flex flex-col justify-center items-center">
                  <div className="h-2 w-full bg-green-500"></div>
                  <div className="font-bold">Done</div>
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/PickupReqMonitoring?process=DONE&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&pickupReqGrpId=${(acc && acc.IsSubAccount === true && pickupAddrSelectedObj ? pickupAddrSelectedObj.PickupReqGrpId : "")}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryPickupReqDashboard == null
                        ? 0
                        : summaryPickupReqDashboard.Done.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          }
          {
            <div className="rounded-md grid grid-rows-2 w-64 h-40 mx-2 shadow-md hover:shadow-xl transition-shadow">
              <div className="bg-white flex flex-col justify-center items-center">
                <div className="h-2 w-full -mt-3 mb-4 rounded-t-lg bg-red-600"></div>
                <div className="font-bold">Pengiriman</div>
                <Link
                  target={"_blank"}
                  to={{
                    pathname: `/PengirimanMonitoring?trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : "" : "")}`,
                  }}
                  style={{ color: "#000000" }}
                >
                  <div>
                    {summaryPengirimanDashboard == null
                      ? 0
                      : summaryPengirimanDashboard.TotalData.toLocaleString(
                        "en",
                        { minimumFractionDigits: 0 }
                      )}
                  </div>
                </Link>
              </div>
              <div className="bg-white grid grid-cols-3 gap-0">
                <div className="flex flex-col justify-center items-center">
                  <div className="h-2 w-full bg-blue-500"></div>
                  <div className="font-bold">Retur</div>
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/PengirimanMonitoring?process=RETUR&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : ""  : "")}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryPengirimanDashboard == null
                        ? 0
                        : summaryPengirimanDashboard.Retur.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
                <div className="bg-white flex flex-col justify-center items-center">
                  <div className="h-2 w-full bg-yellow-500"></div>
                  <div className="font-bold">On Process</div>
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/PengirimanMonitoring?process=ONPROCESS&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : ""  : "")}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryPengirimanDashboard == null
                        ? 0
                        : summaryPengirimanDashboard.OnProcess.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
                <div className="bg-white flex flex-col justify-center items-center">
                  <div className="h-2 w-full bg-green-500"></div>
                  <div className="font-bold">Delivered</div>
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/PengirimanMonitoring?process=DELIVERED&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : ""  : "")}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryPengirimanDashboard == null
                        ? 0
                        : summaryPengirimanDashboard.Delivered.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          }

          {
            (acc && acc.IsSubAccount === false) && (
              <div className="rounded-md bg-white  grid grid-rows-2 w-52 h-40 mx-2 shadow-md hover:shadow-xl transition-shadow">
                <div className="flex flex-col justify-center items-center">
                  <div className="h-2 w-full -mt-3 mb-4 rounded-t-lg bg-red-600"></div>
                  <div className="font-bold">Penerimaan</div>
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/PenerimaanMonitoring?trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryPenerimaanDashboard == null
                        ? 0
                        : summaryPenerimaanDashboard.TotalData.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
                <div className="grid  bg-white grid-cols-2 gap-0">
                  <div className="flex flex-col justify-center items-center">
                    <div className="h-2 w-full bg-yellow-500"></div>
                    <div className="font-bold">On Process</div>
                    <Link
                      target={"_blank"}
                      to={{
                        pathname: `/PenerimaanMonitoring?process=ONPROCESS&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}`,
                      }}
                      style={{ color: "#000000" }}
                    >
                      <div>
                        {summaryPenerimaanDashboard == null
                          ? 0
                          : summaryPenerimaanDashboard.OnProcess.toLocaleString(
                            "en",
                            { minimumFractionDigits: 0 }
                          )}
                      </div>
                    </Link>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <div className="h-2 w-full bg-green-500"></div>
                    <div className="font-bold">Delivered</div>
                    <Link
                      target={"_blank"}
                      to={{
                        pathname: `/PenerimaanMonitoring?process=DELIVERED&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}`,
                      }}
                      style={{ color: "#000000" }}
                    >
                      <div>
                        {summaryPenerimaanDashboard == null
                          ? 0
                          : summaryPenerimaanDashboard.Delivered.toLocaleString(
                            "en",
                            { minimumFractionDigits: 0 }
                          )}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          {
            <div className="rounded-md  bg-white grid grid-rows-2 w-52 h-40 mx-2 shadow-md hover:shadow-xl transition-shadow">
              <div className="flex flex-col justify-center items-center">
                <div className="h-2 w-full -mt-3 mb-4 rounded-t-lg bg-red-600"></div>
                <div className="font-bold">Komplain</div>
                <Link
                  target={"_blank"}
                  to={{
                    pathname: `/KomplainMonitoring?trxDtFrom=${moment(
                      requestObj.trxDtTo
                    )
                      .days(-6)
                      .format("YYYY-MM-DD")}&trxDtTo=${requestObj.trxDtTo}`,
                  }}
                  style={{ color: "#000000" }}
                >
                  <div>
                    {summaryKomplainDashboard == null
                      ? 0
                      : summaryKomplainDashboard.TotalData.toLocaleString(
                        "en",
                        { minimumFractionDigits: 0 }
                      )}
                  </div>
                </Link>
              </div>
              <div className="grid bg-white grid-cols-2 gap-0">
                <div className="flex flex-col justify-center items-center">
                  <div className="h-2 w-full bg-yellow-500"></div>
                  <div className="font-bold">On Process</div>
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/KomplainMonitoring?process=ONPROCESS&trxDtFrom=${moment(
                        requestObj.trxDtTo
                      )
                        .days(-6)
                        .format("YYYY-MM-DD")}&trxDtTo=${requestObj.trxDtTo}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryKomplainDashboard == null
                        ? 0
                        : summaryKomplainDashboard.OnProcess.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <div className="h-2 w-full bg-green-500"></div>
                  <div className="font-bold">Done</div>
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/KomplainMonitoring?process=DONE&trxDtFrom=${moment(
                        requestObj.trxDtTo
                      )
                        .days(-6)
                        .format("YYYY-MM-DD")}&trxDtTo=${requestObj.trxDtTo}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryKomplainDashboard == null
                        ? 0
                        : summaryKomplainDashboard.Done.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          }
        </div>
        <div className="flex justify-center content-center pt-3 text-xs sm:text-sm"></div>
      </div>
      <div className="block sm:hidden">
        <h1 className="py-2 mx-3 text-xl flex font-bold justify-center">
          Summary
        </h1>
        <form onSubmit={handleSearch} method="POST">
          <div className="grid grid-cols-2 py-2 text-xs sm:text-sm">
            <div className="px-3 font-bold">Filter dari Tanggal</div>
            <div className="px-3 font-bold">sampai Tanggal</div>
          </div>
          <div className="flex px-3">
            <div className="flex-1 text-xs sm:text-sm flex items-center">
              <input
                id="trxDtFrom"
                name="trxDtFrom"
                type="date"
                value={requestObj.trxDtFrom}
                onChange={handleChange}
                className="rounded-sm px-3 py-1 text-xs sm:text-sm border border-gray-300"
                style={{ width: "98%" }}
              />
            </div>
            <div className="flex-1 text-right text-xs sm:text-sm items-center">
              <input
                id="trxDtTo"
                name="trxDtTo"
                type="date"
                value={requestObj.trxDtTo}
                onChange={handleChange}
                className="rounded-sm px-3 py-1 text-xs sm:text-sm border border-gray-300"
                style={{ width: "98%" }}
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex-1 text-xs sm:text-sm flex items-center">
              {submitted && !requestObj.trxDtFrom && (
                <div className="flex flex-1 text-xs sm:text-sm text-red-500 ml-4 py-1">
                  Tidak boleh kosong
                </div>
              )}
            </div>
            <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
              {submitted && !requestObj.trxDtTo && (
                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                  Tidak boleh kosong
                </div>
              )}
            </div>
          </div>
          <ShortcutDate onSubmit={getDate} />
          <div className="flex flex-col justify-center -mt-4">
            <button
              type="submit"
              className="mx-3 my-2 py-2 px-4 text-xs sm:text-sm border border-transparent font-medium rounded-full text-white bg-red-600"
            >
              Search
            </button>
          </div>
        </form>
        <div className="flex flex-col justify-center text-xs sm:text-sm -mt-1 mb-1">
          {
            <div className="rounded-md  bg-white mx-3 my-1 shadow-md hover:shadow-xl transition-shadow">
              <div className="h-2 w-full rounded-t-lg bg-red-600"></div>
              <div className="flex flex-col justify-center items-center py-1">
                <div className="flex-1 font-bold my-1">Booking</div>
                <div className="flex-1 my-1">
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/booking-monitor?trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : ""  : "")}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryBookingDashboard == null
                        ? 0
                        : summaryBookingDashboard.TotalData.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="flex flex-row py-1">
                <div className="flex-1">
                  <div className="flex-col">
                    <div className="h-2 w-full bg-yellow-500"></div>
                    <div className="font-bold text-center my-1 text-xs">
                      Drop
                    </div>
                    <div className="text-center my-1 text-xs">
                      <Link
                        target={"_blank"}
                        to={{
                          pathname: `/booking-monitor?dropType=DROP&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : ""  : "")}`,
                        }}
                        style={{ color: "#000000" }}
                      >
                        <div>
                          {summaryBookingDashboard == null
                            ? 0
                            : summaryBookingDashboard.Drop.toLocaleString(
                              "en",
                              { minimumFractionDigits: 0 }
                            )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex-col">
                    <div className="h-2 w-full bg-green-500"></div>
                    <div className="font-bold text-center my-1 text-xs">
                      Pickup
                    </div>
                    <div className="text-center my-1 text-xs">
                      <Link
                        target={"_blank"}
                        to={{
                          pathname: `/booking-monitor?dropType=PICKUP&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : ""  : "")}`,
                        }}
                        style={{ color: "#000000" }}
                      >
                        <div>
                          {summaryBookingDashboard == null
                            ? 0
                            : summaryBookingDashboard.Pickup.toLocaleString(
                              "en",
                              { minimumFractionDigits: 0 }
                            )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            <div className="rounded-md bg-white  mx-3 my-1 shadow-md hover:shadow-xl transition-shadow">
              <div className="h-2 w-full rounded-t-lg bg-red-600"></div>
              <div className="flex flex-col justify-center items-center py-1">
                <div className="flex-1 font-bold my-1">Pickup Request</div>
                <div className="flex-1 my-1">
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/PickupReqMonitoring?trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&pickupReqGrpId=${(acc && acc.IsSubAccount === true && pickupAddrSelectedObj ? pickupAddrSelectedObj.PickupReqGrpId : "" )}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryPickupReqDashboard == null
                        ? 0
                        : summaryPickupReqDashboard.TotalData.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="flex flex-row py-1">
                <div className="flex-1">
                  <div className="flex-col">
                    <div className="h-2 w-full bg-blue-500"></div>
                    <div className="font-bold text-center my-1 text-xs">
                      New
                    </div>
                    <div className="text-center my-1 text-xs">
                      <Link
                        target={"_blank"}
                        to={{
                          pathname: `/PickupReqMonitoring?process=NEW&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&pickupReqGrpId=${(acc && acc.IsSubAccount === true && pickupAddrSelectedObj ? pickupAddrSelectedObj.PickupReqGrpId : "")}`,
                        }}
                        style={{ color: "#000000" }}
                      >
                        <div>
                          {summaryPickupReqDashboard == null
                            ? 0
                            : summaryPickupReqDashboard.New.toLocaleString(
                              "en",
                              { minimumFractionDigits: 0 }
                            )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex-col">
                    <div className="h-2 w-full bg-yellow-500"></div>
                    <div className="font-bold text-center my-1 text-xs">
                      On Process
                    </div>
                    <div className="text-center my-1 text-xs">
                      <Link
                        target={"_blank"}
                        to={{
                          pathname: `/PickupReqMonitoring?process=ONPROCESS&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&pickupReqGrpId=${(acc && acc.IsSubAccount === true && pickupAddrSelectedObj ? pickupAddrSelectedObj.PickupReqGrpId : "")}`,
                        }}
                        style={{ color: "#000000" }}
                      >
                        <div>
                          {summaryPickupReqDashboard == null
                            ? 0
                            : summaryPickupReqDashboard.OnProcess.toLocaleString(
                              "en",
                              { minimumFractionDigits: 0 }
                            )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex-col">
                    <div className="h-2 w-full bg-green-500"></div>
                    <div className="font-bold text-center my-1 text-xs">
                      Done
                    </div>
                    <div className="text-center my-1 text-xs">
                      <Link
                        target={"_blank"}
                        to={{
                          pathname: `/PickupReqMonitoring?process=DONE&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&pickupReqGrpId=${(acc && acc.IsSubAccount === true && pickupAddrSelectedObj ? pickupAddrSelectedObj.PickupReqGrpId : "")}`,
                        }}
                        style={{ color: "#000000" }}
                      >
                        <div>
                          {summaryPickupReqDashboard == null
                            ? 0
                            : summaryPickupReqDashboard.Done.toLocaleString(
                              "en",
                              { minimumFractionDigits: 0 }
                            )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            <div className="rounded-md bg-white  mx-3 my-1 shadow-md hover:shadow-xl transition-shadow">
              <div className="h-2 w-full mb-2 rounded-t-lg bg-red-600"></div>
              <div className="flex flex-col justify-center items-center py-1">
                <div className="flex-1 font-bold my-1">Pengiriman</div>
                <div className="flex-1 my-1">
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/PengirimanMonitoring?trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : "" : "")}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryPengirimanDashboard == null
                        ? 0
                        : summaryPengirimanDashboard.TotalData.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="flex flex-row py-1">
                <div className="flex-1">
                  <div className="flex-col">
                    <div className="h-2 w-full bg-blue-500"></div>
                    <div className="font-bold text-center my-1 text-xs">
                      Retur
                    </div>
                    <div className="text-center my-1 text-xs">
                      <Link
                        target={"_blank"}
                        to={{
                          pathname: `/PengirimanMonitoring?process=RETUR&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : "" : "")}`,
                        }}
                        style={{ color: "#000000" }}
                      >
                        <div>
                          {summaryPengirimanDashboard == null
                            ? 0
                            : summaryPengirimanDashboard.Retur.toLocaleString(
                              "en",
                              { minimumFractionDigits: 0 }
                            )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex-col">
                    <div className="h-2 w-full bg-yellow-500"></div>
                    <div className="font-bold text-center my-1 text-xs">
                      On Process
                    </div>
                    <div className="text-center my-1 text-xs">
                      <Link
                        target={"_blank"}
                        to={{
                          pathname: `/PengirimanMonitoring?process=ONPROCESS&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : "" : "")}`,
                        }}
                        style={{ color: "#000000" }}
                      >
                        <div>
                          {summaryPengirimanDashboard == null
                            ? 0
                            : summaryPengirimanDashboard.OnProcess.toLocaleString(
                              "en",
                              { minimumFractionDigits: 0 }
                            )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex-col">
                    <div className="h-2 w-full bg-green-500"></div>
                    <div className="font-bold text-center my-1 text-xs">
                      Delivered
                    </div>
                    <div className="text-center my-1 text-xs">
                      <Link
                        target={"_blank"}
                        to={{
                          pathname: `/PengirimanMonitoring?process=DELIVERED&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${(acc && acc.IsSubAccount === true ? pickupAddrSelectedObj ? pickupAddrSelectedObj.AddrType : "" : "")}`,
                        }}
                        style={{ color: "#000000" }}
                      >
                        <div>
                          {summaryPengirimanDashboard == null
                            ? 0
                            : summaryPengirimanDashboard.Delivered.toLocaleString(
                              "en",
                              { minimumFractionDigits: 0 }
                            )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            (acc && acc.IsSubAccount === false) && (
              <div className="rounded-md  bg-white mx-3 my-1 shadow-md hover:shadow-xl transition-shadow">
                <div className="h-2 w-full mb-2 rounded-t-lg bg-red-600"></div>
                <div className="flex flex-col justify-center items-center">
                  <div className="flex-1 font-bold my-1">Penerimaan</div>
                  <div className="flex-1 my-1">
                    <Link
                      target={"_blank"}
                      to={{
                        pathname: `/PenerimaanMonitoring?trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}`,
                      }}
                      style={{ color: "#000000" }}
                    >
                      <div>
                        {summaryPenerimaanDashboard == null
                          ? 0
                          : summaryPenerimaanDashboard.TotalData.toLocaleString(
                            "en",
                            { minimumFractionDigits: 0 }
                          )}
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="flex bg-white flex-row py-1">
                  <div className="flex-1">
                    <div className="flex-col">
                      <div className="h-2 w-full bg-yellow-500"></div>
                      <div className="font-bold text-center my-1 text-xs">
                        On Process
                      </div>
                      <div className="text-center my-1 text-xs">
                        <Link
                          target={"_blank"}
                          to={{
                            pathname: `/PenerimaanMonitoring?process=ONPROCESS&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}`,
                          }}
                          style={{ color: "#000000" }}
                        >
                          <div>
                            {summaryPenerimaanDashboard == null
                              ? 0
                              : summaryPenerimaanDashboard.OnProcess.toLocaleString(
                                "en",
                                { minimumFractionDigits: 0 }
                              )}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 bg-white ">
                    <div className="flex-col">
                      <div className="h-2 w-full bg-green-500"></div>
                      <div className="font-bold text-center my-1 text-xs">
                        Delivered
                      </div>
                      <div className="text-center my-1 text-xs">
                        <Link
                          target={"_blank"}
                          to={{
                            pathname: `/PenerimaanMonitoring?process=DELIVERED&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}`,
                          }}
                          style={{ color: "#000000" }}
                        >
                          <div>
                            {summaryPenerimaanDashboard == null
                              ? 0
                              : summaryPenerimaanDashboard.Delivered.toLocaleString(
                                "en",
                                { minimumFractionDigits: 0 }
                              )}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {
            <div className="rounded-md bg-white mx-3 my-1 shadow-md hover:shadow-xl transition-shadow">
              <div className="h-2 w-full mb-2 rounded-t-lg bg-red-600"></div>
              <div className="flex flex-col justify-center items-center">
                <div className="flex-1 font-bold my-1">Komplain</div>
                <div className="flex-1 my-1">
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/KomplainMonitoring?trxDtFrom=${moment(
                        requestObj.trxDtTo
                      )
                        .days(-6)
                        .format("YYYY-MM-DD")}&trxDtTo=${requestObj.trxDtTo}`,
                    }}
                    style={{ color: "#000000" }}
                  >
                    <div>
                      {summaryKomplainDashboard == null
                        ? 0
                        : summaryKomplainDashboard.TotalData.toLocaleString(
                          "en",
                          { minimumFractionDigits: 0 }
                        )}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="flex flex-row py-1">
                <div className="flex-1">
                  <div className="flex-col">
                    <div className="h-2 w-full bg-yellow-500"></div>
                    <div className="font-bold text-center my-1 text-xs">
                      On Process
                    </div>
                    <div className="text-center my-1 text-xs">
                      <Link
                        target={"_blank"}
                        to={{
                          pathname: `/KomplainMonitoring?process=ONPROCESS&trxDtFrom=${moment(
                            requestObj.trxDtTo
                          )
                            .days(-6)
                            .format("YYYY-MM-DD")}&trxDtTo=${requestObj.trxDtTo}`,
                        }}
                        style={{ color: "#000000" }}
                      >
                        <div>
                          {summaryKomplainDashboard == null
                            ? 0
                            : summaryKomplainDashboard.OnProcess.toLocaleString(
                              "en",
                              { minimumFractionDigits: 0 }
                            )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex-col">
                    <div className="h-2 w-full bg-green-500"></div>
                    <div className="font-bold text-center my-1 text-xs">
                      Done
                    </div>
                    <div className="text-center my-1 text-xs">
                      <Link
                        target={"_blank"}
                        to={{
                          pathname: `/KomplainMonitoring?process=DONE&trxDtFrom=${moment(
                            requestObj.trxDtTo
                          )
                            .days(-6)
                            .format("YYYY-MM-DD")}&trxDtTo=${requestObj.trxDtTo
                            }`,
                        }}
                        style={{ color: "#000000" }}
                      >
                        <div>
                          {summaryKomplainDashboard == null
                            ? 0
                            : summaryKomplainDashboard.Done.toLocaleString(
                              "en",
                              { minimumFractionDigits: 0 }
                            )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div >
    </>
  );
};
export default SummaryDashboard;
