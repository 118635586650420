export const masterSettingConstants = {
  PAYTYPE_LUNAS: "Lunas",
  PAYTYPE_KREDIT: "Kredit",
  PAYTYPE_TAGIHTUJUAN: "Tagih Tujuan",
  PAYTYPE_KREDITPENERIMA: "Kredit Penerima",
  TRANSPORT_TYPE_UDARA: "UDARA",
  TRANSPORT_TYPE_DARAT: "DARAT",
  PACKAGE_CATEGORY_ELEKTRONIK: "ELEKTRONIK",
  PACKAGE_CATEGORY_NON_ELEKTRONIK: "NON ELEKTRONIK",
  DROP_TYPE_PICKUP: "PICKUP",
  DROP_TYPE_DROP: "DROP",
  PACKING_TYPE_KAYU: "KAYU",
  PICKUP_STAT_ACT: "ACT",
  PICKUP_STAT_EXP: "EXP",
  PICKUP_STAT_RJC: "RJC",
  PICKUP_STAT_CAN: "CAN",
  BOOK_STAT_CAN: "CAN",
  BOOK_STAT_PCK: "PCK",
  BOOK_STAT_ACT: "ACT",
  BOOK_STAT_EXP: "EXP",
  PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN: "EDLL",
  PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN: "NEDLL",
  PICKUP_REQ_STAT_DONE: "DONE",
  PICKUP_REQ_STAT_NOTDONE: "NOTDONE",
  PICKUP_REQ_STAT_CAN: "CAN",
  PROCESS_NEW: "NEW",
  PROCESS_DONE: "DONE",
  PROCESS_ONPROCESS: "ONPROCESS",
  PROCESS_DELIVERED: "DELIVERED",
  PROCESS_RETUR: "RETUR",
  UPLOAD_TYPE_BOOKING: "BOOKING",
  UPLOAD_STATUS_REQ: "REQ",
  UPLOAD_STATUS_INP: "INP",
  UPLOAD_STATUS_ERR: "ERR",
  UPLOAD_STATUS_DONE: "DONE",
};
