import { pengirimanConstants } from "../_constants";
import { pengirimanService } from "../_services";

export const pengirimanActions = {
  pengirimanMonitoring,
  pengirimanDetail,
  exportPengirimanData,
};

function pengirimanMonitoring(pengirimanMonitoringObj) {
  return (dispatch) => {
    dispatch(request({ pengirimanMonitoringObj }));
    pengirimanService.pengirimanMonitoring(pengirimanMonitoringObj).then(
      (pengirimanMonitoring) => {
        dispatch(success(pengirimanMonitoring));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(pengirimanMonitoring) {
    return {
      type: pengirimanConstants.PENGIRIMAN_MONITORING_REQUEST,
      pengirimanMonitoring,
    };
  }
  function success(pengirimanMonitoring) {
    return {
      type: pengirimanConstants.PENGIRIMAN_MONITORING_SUCCESS,
      pengirimanMonitoring,
    };
  }
  function failure(error) {
    return { type: pengirimanConstants.PENGIRIMAN_MONITORING_FAILURE, error };
  }
}

function pengirimanDetail(pengirimanDetailObj) {
  return (dispatch) => {
    dispatch(request({ pengirimanDetailObj }));
    pengirimanService.pengirimanDetail(pengirimanDetailObj).then(
      (pengirimanDetail) => {
        dispatch(success(pengirimanDetail));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(pengirimanDetail) {
    return {
      type: pengirimanConstants.PENGIRIMAN_DETAIL_REQUEST,
      pengirimanDetail,
    };
  }
  function success(pengirimanDetail) {
    return {
      type: pengirimanConstants.PENGIRIMAN_DETAIL_SUCCESS,
      pengirimanDetail,
    };
  }
  function failure(error) {
    return { type: pengirimanConstants.PENGIRIMAN_DETAIL_FAILURE, error };
  }
}

function exportPengirimanData(exportPengirimanDataObj) {
  return (dispatch) => {
    dispatch(request({ exportPengirimanDataObj }));
    pengirimanService.exportPengirimanData(exportPengirimanDataObj).then(
      (exportPengirimanData) => {
        dispatch(success(exportPengirimanData));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(exportPengirimanData) {
    return {
      type: pengirimanConstants.PENGIRIMAN_DATA_EXPORT_REQUEST,
      exportPengirimanData,
    };
  }
  function success(exportPengirimanData) {
    return {
      type: pengirimanConstants.PENGIRIMAN_DATA_EXPORT_SUCCESS,
      exportPengirimanData,
    };
  }
  function failure(error) {
    alert(error.StatusMessage);
    return { type: pengirimanConstants.PENGIRIMAN_DATA_EXPORT_FAILURE, error };
  }
}
