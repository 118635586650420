import { penerimaanConstants } from "../_constants";
import { penerimaanService } from "../_services";

export const penerimaanActions = {
  penerimaanMonitoring,
};

function penerimaanMonitoring(penerimaanMonitoringObj) {
  return (dispatch) => {
    dispatch(request({ penerimaanMonitoringObj }));
    penerimaanService.penerimaanMonitoring(penerimaanMonitoringObj).then(
      (penerimaanMonitoring) => {
        dispatch(success(penerimaanMonitoring));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(penerimaanMonitoring) {
    return {
      type: penerimaanConstants.PENERIMAAN_MONITORING_REQUEST,
      penerimaanMonitoring,
    };
  }
  function success(penerimaanMonitoring) {
    return {
      type: penerimaanConstants.PENERIMAAN_MONITORING_SUCCESS,
      penerimaanMonitoring,
    };
  }
  function failure(error) {
    return { type: penerimaanConstants.PENERIMAAN_MONITORING_FAILURE, error };
  }
}
