import { accConstants } from '../_constants';
import { accService } from '../_services';
import { history } from '../_helpers';

export const accActions = {
    login,
    impersonateLogin,
    logout,
    resetPassword,
    clearResetPassword
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));
        accService.login(username, password)
            .then(
                acc => {
                    dispatch(success(acc));
                    //history.push('/Home');
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(acc) { return { type: accConstants.LOGIN_REQUEST, acc } }
    function success(acc) { return { type: accConstants.LOGIN_SUCCESS, acc } }
    function failure(error) { return { type: accConstants.LOGIN_FAILURE, error } }
}

function impersonateLogin(reqImpersonateLogin) {
    return dispatch => {
        dispatch(request(reqImpersonateLogin));
        accService.impersonateLogin(reqImpersonateLogin)
            .then(
                acc => {
                    dispatch(success(acc));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(acc) { return { type: accConstants.IMPERSONATE_LOGIN_REQUEST, acc } }
    function success(acc) { return { type: accConstants.IMPERSONATE_LOGIN_SUCCESS, acc } }
    function failure(error) { return { type: accConstants.IMPERSONATE_LOGIN_FAILURE, error } }
}

function resetPassword(username, newpassword, isSubAccount) {
    return dispatch => {
        dispatch(request({ username }));
        accService.resetPassword(username, newpassword, isSubAccount)
            .then(
                pwdObj => {
                    console.log("SUCCESS PWD", pwdObj)
                    dispatch(success(pwdObj));
                   
                    // history.push('/Home');
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(pwdObj) { return { type: accConstants.RESET_PASSWORD_REQUEST, pwdObj } }
    function success(pwdObj) { return { type: accConstants.RESET_PASSWORD_SUCCESS, pwdObj } }
    function failure(error) { return { type: accConstants.RESET_PASSWORD_FAILURE, error } }
}

function clearResetPassword() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('pwdObj');
        }
    };

    function clear() { return { type: accConstants.RESET_PASSWORD_CLEAR } }
}

function logout() {
    accService.logout();
    history.push('/');
    return { type: accConstants.LOGOUT };
}
