import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { resiActions } from '../../_actions';

const PrintResi = ({ t }) => {
    const dispatch = useDispatch()
    const acc = useSelector(state => state.authentication.accInfo);
    const printResiObj = useSelector(state => state.printResi.printResiObj);

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();
    const trxNo = query.get("trxNo");

    useEffect(() => {
        dispatch(resiActions.clearPrintResi());
        if (trxNo && acc != undefined) {
            const printResi = async () => {
                dispatch(resiActions.requestPrintResi(trxNo, acc.CustId));
            }
            printResi()
        }
    }, [])

    return (
        <>
            {
                printResiObj && printResiObj != '' && printResiObj != null &&
                <>
                    <embed src={`data:application/pdf;base64,${printResiObj}`} style={{ width: "100%", height: "100vh" }} />
                </>
            }
        </>
    );
};
export default PrintResi;