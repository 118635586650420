import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { penerimaanActions } from "../../_actions";
import { useLocation } from "react-router-dom";
import {
  AlertMessage,
  Pagination,
  PenerimaanMonitoringTable,
  ShortcutDate,
} from "../../components";
import WaktuSampaiFooter from "../../components/WaktuSampaiFooter";
import { masterSettingConstants } from "../../_constants";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import axios from "axios";
import { urlConstants } from "../../_constants/url.constants";

const PenerimaanMonitoring = ({ t }) => {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const acc = useSelector((state) => state.authentication.accInfo);
  const penerimaanMonitoring = useSelector(
    (state) => state.penerimaanMonitoring.penerimaanMonitoring
  );
  const errPenerimaanMonitoring = useSelector(
    (state) => state.penerimaanMonitoring.errPenerimaanMonitoring
  );

  const maxDate = new Date();
  const minDate = new Date();
  const finalMinDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);

  const dispatch = useDispatch();

  const _trxDtFrom = query.get("trxDtFrom");
  const _trxDtTo = query.get("trxDtTo");
  const _process = query.get("process");
  const [penerimaanMonitoringForm, setPenerimaanMonitoringForm] = useState({
    trxDtFrom: _trxDtFrom ? _trxDtFrom : dateParser(finalMinDate),
    trxDtTo: _trxDtTo ? _trxDtTo : dateParser(maxDate),
    process: _process ? _process : "",
  });
  const [enabled, setEnabled] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState({
    Type: "",
    StatusMessage: "",
  });
  const [rowPerPage, setRowPerPage] = useState(10);
  let [num, setNum] = useState(1);
  let [cur, setCur] = useState(1);
  const [loadMore, setLoadmore] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [shortcut, setShortcut] = useState(false);
  const [exported, setExported] = useState(false);
  const [exportDataObj, setExportDataObj] = useState();
  const client = axios.create({
    baseURL: urlConstants.URL_EXPORT_PENERIMAAN_DETAIL,
  });

  useEffect(() => {
    callPenerimaanMonitoring();
    setSubmitted(true);
  }, []);

  function dateParser(value) {
    const date = new Date(value);
    const dateParsed =
      date.getFullYear() +
      "-" +
      date.toLocaleString("en-us", { month: "2-digit" }) +
      "-" +
      date.toLocaleString("en-us", { day: "2-digit" });
    return dateParsed;
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setPenerimaanMonitoringForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleSearch(e) {
    e.preventDefault();

    setSubmitted(true);
    if (
      penerimaanMonitoringForm.trxDtFrom &&
      penerimaanMonitoringForm.trxDtTo
    ) {
      callPenerimaanMonitoring();
    }
  }

  useEffect(() => {
    if (
      errPenerimaanMonitoring &&
      errPenerimaanMonitoring.StatusCode !== "200"
    ) {
      setIsError(true);
      setAlert({
        Type: "Error",
        StatusMessage: errPenerimaanMonitoring.StatusMessage,
      });
    }
  }, [errPenerimaanMonitoring]);

  useEffect(() => {
    if (penerimaanMonitoring && penerimaanMonitoring.TotalRecord) {
      setTotalRecord(penerimaanMonitoring.TotalRecord);
    }
  }, [penerimaanMonitoring]);


  const getDate = (dateObj) => {
    setPenerimaanMonitoringForm((prevState) => ({
      ...prevState,
      trxDtFrom: dateObj.dateFrom,
      trxDtTo: dateObj.dateTo,
    }));

    setShortcut(true);
  };

  useEffect(() => {
    if (shortcut) {
      callPenerimaanMonitoring();
      setSubmitted(true);
      setShortcut(false);
    }
  }, [shortcut]);

  function callPenerimaanMonitoring() {
    if (acc && acc.CustId) {
      var requestObj = {
        CustId: acc.CustId,
        TrxDtFrom: penerimaanMonitoringForm.trxDtFrom,
        TrxDtTo: penerimaanMonitoringForm.trxDtTo,
        Process: penerimaanMonitoringForm.process
      };
      dispatch(penerimaanActions.penerimaanMonitoring(requestObj));
    }
  }

  function exportData() {
    setIsError(false);
    var requestObj = {
      CustId: acc.CustId,
      TrxDtFrom: penerimaanMonitoringForm.trxDtFrom,
      TrxDtTo: penerimaanMonitoringForm.trxDtTo,
      Process: penerimaanMonitoringForm.process,
      PodRating: query.get("podRating"),
      RowPerPage: 99999,
      PageNo: 1,
    };
    console.log(requestObj);
    exportPenerimaanMonitoring(requestObj);
    setExported(true);
  }

  const exportPenerimaanMonitoring = async (requestObj) => {
    try {
      let result = await client.post("", requestObj);
      if (result.data.IsError) {
        console.log("Export Error");
        setIsError(true);
        setAlert({
          Type: "Error",
          StatusMessage: result.data.StatusMessage,
        });
      } else {
        setExportDataObj({
          exportPenerimaan: result.data.ObjectResult,
        });
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
      setAlert({
        Type: "Error",
        StatusMessage:
          "An error occure, please contact our admin for further information",
      });
    }
  };

  useEffect(() => {
    if (exportDataObj && exportDataObj.exportPenerimaan && exported) {
      var a = document.createElement("a");
      a.href =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        exportDataObj.exportPenerimaan.ExportDataFile;
      a.download = exportDataObj.exportPenerimaan.Filename;
      a.click();

      setExported(false);
    }
  }, [exportDataObj]);

  return (
    <>
      <div className="hidden sm:block">
        <div className="justify-center py-2 px-4 flex flex-row">
          <h1 className="font-bold text-xl">Penerimaan Monitoring</h1>
        </div>
        <form onSubmit={handleSearch} method="POST">
          <div className="max-w-6xl mx-auto text-xs sm:text-sm font-medium">
            <div className="row px-2 py-1">
              <div className="col-md-3 text-xs sm:text-sm">
                Tanggl Awal Transaksi
              </div>
              <div className="col-md-3 text-xs sm:text-sm">
                <input
                  aria-label="Tanggl Awal Transaksi"
                  id="trxDtFrom"
                  name="trxDtFrom"
                  type="date"
                  value={penerimaanMonitoringForm.trxDtFrom}
                  className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                  onChange={handleChange}
                />
                {submitted && !penerimaanMonitoringForm.trxDtFrom && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                    Tidak boleh kosong
                  </div>
                )}
              </div>
              <div className="col-md-3 text-xs sm:text-sm">
                Tanggal Akhir Transaksi
              </div>
              <div className="col-md-3 text-xs sm:text-sm">
                <input
                  aria-label="Tanggal Akhir Transaksi"
                  id="trxDtTo"
                  name="trxDtTo"
                  type="date"
                  value={penerimaanMonitoringForm.trxDtTo}
                  className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                  onChange={handleChange}
                />
                {submitted && !penerimaanMonitoringForm.trxDtTo && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                    Tidak boleh kosong
                  </div>
                )}
              </div>
            </div>
            <div className="row px-2 py-1">
              <div className="col-md-3 text-xs sm:text-sm">Process</div>
              <div className="col-md-3 text-xs sm:text-sm">
                <select
                  className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                  id="process"
                  name="process"
                  value={penerimaanMonitoringForm.process}
                  onChange={handleChange}
                >
                  <option value="">Semua</option>
                  <option value={masterSettingConstants.PROCESS_ONPROCESS}>
                    On Process
                  </option>
                  <option value={masterSettingConstants.PROCESS_DELIVERED}>
                    Delivered
                  </option>
                </select>
              </div>
            </div>
            <div className="flex justify-end mb-2 -mt-2">
              <ShortcutDate onSubmit={getDate} />
            </div>
            <div className="flex justify-end mb-2 -mt-4">
              <button
                type="submit"
                className="relative w-40 flex justify-center items-center mr-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
              >
                Search
              </button>
              <button
                type="button"
                onClick={exportData}
                className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
              >
                Export
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="block sm:hidden">
        <div className="bg-blue-600">
          <h4 className="flex justify-center text-xl font-semibold text-white py-2">
            Penerimaan Monitoring
          </h4>
        </div>
        <form onSubmit={handleSearch} method="POST">
          <div className="flex flex-col text-xs sm:text-sm px-3">
            <div className="flex flex-row font-bold pt-2">
              <div className="flex-1 text-xs sm:text-sm flex items-center">
                Filter dari Tanggal
              </div>
              <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                sampai Tanggal
              </div>
            </div>
            <div className="flex flex-row mt-1">
              <div className="flex-1 text-xs sm:text-sm flex items-center">
                <input
                  aria-label="Tanggal Awal Transaksi"
                  id="trxDtFrom"
                  name="trxDtFrom"
                  type="date"
                  value={penerimaanMonitoringForm.trxDtFrom}
                  className={`py-1 px-2 ${submitted && !penerimaanMonitoringForm.trxDtFrom
                      ? "border-red-500"
                      : "border-gray-300"
                    } text-xs sm:text-sm outline-none rounded-sm w-100`}
                  placeholder="Tanggal Awal Transaksi"
                  onChange={handleChange}
                />
              </div>
              <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                <input
                  aria-label="Tanggal Akhir Transaksi"
                  id="trxDtTo"
                  name="trxDtTo"
                  type="date"
                  value={penerimaanMonitoringForm.trxDtTo}
                  className={`py-1 px-2 ${submitted && !penerimaanMonitoringForm.trxDtTo
                      ? "border-red-500"
                      : "border-gray-300"
                    } text-xs sm:text-sm outline-none rounded-sm w-100`}
                  placeholder="Tanggal Akhir Transaksi"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="flex-1 text-xs sm:text-sm flex items-center">
                {submitted && !penerimaanMonitoringForm.trxDtFrom && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 ml-4 py-1">
                    Isi tanggal awal
                  </div>
                )}
              </div>
              <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                {submitted && !penerimaanMonitoringForm.trxDtTo && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                    Isi tanggal akhir
                  </div>
                )}
              </div>
            </div>
            {enabled === true && (
              <>
                <div className="flex flex-row mt-1 font-bold">
                  <div className="flex-1 text-xs sm:text-sm flex items-center">
                    Process
                  </div>
                </div>
                <div className="flex flex-row mt-1">
                  <div className="flex-1 text-xs sm:text-sm flex items-center">
                    <select
                      className="py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none rounded-sm w-100"
                      id="process"
                      name="process"
                      value={penerimaanMonitoringForm.process}
                      onChange={handleChange}
                    >
                      <option value="">Semua</option>
                      <option value={masterSettingConstants.PROCESS_ONPROCESS}>
                        On Process
                      </option>
                      <option value={masterSettingConstants.PROCESS_DELIVERED}>
                        Delivered
                      </option>
                    </select>
                  </div>
                  <div className="flex-1"></div>
                </div>
              </>
            )}
          </div>

          <div className="flex">
            <div className="flex flex-1 flex-col text-xs sm:text-sm items-center">
              <button
                className="text-red-600 px-3 pt-2"
                onClick={(e) => setEnabled(!enabled)}
              >
                {enabled === true ? (
                  <>
                    Hide Search
                    <FaCaretUp
                      className="mb-1 w-4 h-4"
                      style={{ display: "inline-block" }}
                    />
                  </>
                ) : (
                  <>
                    Advance Search
                    <FaCaretDown
                      className="mb-1 w-4 h-4"
                      style={{ display: "inline-block" }}
                    />
                  </>
                )}
              </button>
            </div>
          </div>
          <ShortcutDate onSubmit={getDate} />
          <div className="flex flex-row justify-center -mt-4 mx-3">
            <div className="flex-1 mr-1">
              <button
                type="submit"
                className="text-xs sm:text-sm w-100 my-2 py-2 border border-transparent font-medium rounded-full text-white bg-red-600"
              >
                Search
              </button>
            </div>
            <div className="flex-1 ml-1">
              <button
                type="button"
                onClick={exportData}
                className="text-xs sm:text-sm w-100 my-2 py-2 border border-transparent font-medium rounded-full text-white bg-green-600"
              >
                Export
              </button>
            </div>
          </div>
        </form>
      </div>
      {isError && errPenerimaanMonitoring && (
        <AlertMessage
          isError={isError}
          alert={alert}
          setAlert={setAlert}
          setIsError={setIsError}
        />
      )}
      {penerimaanMonitoring && penerimaanMonitoring.Result && (
        <div className="justify-center">
          <PenerimaanMonitoringTable />
          
          <div className="mx-auto mt-4 hidden sm:block">
            <WaktuSampaiFooter />
          </div>
        </div>
      )}
    </>
  );
};
export default PenerimaanMonitoring;
