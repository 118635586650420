import { bookingConstants } from '../_constants';
import { bookingService } from '../_services';

export const bookingActions = {
    requestMonitoring,
    requestBookingDetail,
    requestBookingViewH,
    requestBookingViewD,
    requestCalculateBooking,
    requestSubmitBooking,
    clearCalculateBooking,
    clearSubmitBooking,
    requestPrintBooking,
    clearPrintBooking,
    clearBookingMonitoring,
    clearBookingDetail,
    requestExportBookingDetail,
    clearExportBookingDetail,
    requestUploadBooking,
    clearUploadBooking,
    getCustBookHDetail,
    clearCustBookHDetail,
    getListRecipient,
    clearRecipientList,
    cancelKoliBooking,
    clearCancelKoliBooking
};

function requestMonitoring(monitoringObj) {
    return dispatch => {
        dispatch(request(monitoringObj));
        bookingService.requestMonitoring(monitoringObj)
            .then(
                bookingObj => {
                    dispatch(success(bookingObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(bookingObj) { return { type: bookingConstants.BOOKING_MONITOR_REQUEST, bookingObj } }
    function success(bookingObj) { return { type: bookingConstants.BOOKING_MONITOR_SUCCESS, bookingObj } }
    function failure(error) { return { type: bookingConstants.BOOKING_MONITOR_FAILURE, error } }
}

function clearBookingMonitoring() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('bookingMonitor');
        }
    };

    function clear() { return { type: bookingConstants.BOOKING_MONITOR_CLEAR } }
}

function requestBookingDetail(bookDetailObj) {
    return dispatch => {
        dispatch(request(bookDetailObj));
        bookingService.requestDetail(bookDetailObj)
            .then(
                bookingDetailObj => {
                    dispatch(success(bookingDetailObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(bookingDetailObj) { return { type: bookingConstants.BOOKING_DETAIL_REQUEST, bookingDetailObj } }
    function success(bookingDetailObj) { return { type: bookingConstants.BOOKING_DETAIL_SUCCESS, bookingDetailObj } }
    function failure(error) { return { type: bookingConstants.BOOKING_DETAIL_FAILURE, error } }
}

function clearBookingDetail() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('bookingDetail');
        }
    };

    function clear() { return { type: bookingConstants.BOOKING_DETAIL_CLEAR } }
}

function requestBookingViewH(bookingHId, custId) {
    return dispatch => {
        dispatch(request({ bookingHId, custId }));
        bookingService.requestViewHObj(bookingHId, custId)
            .then(
                bookingHObj => {
                    dispatch(success(bookingHObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(bookingHObj) { return { type: bookingConstants.BOOKING_VIEW_H_REQUEST, bookingHObj } }
    function success(bookingHObj) { return { type: bookingConstants.BOOKING_VIEW_H_SUCCESS, bookingHObj } }
    function failure(error) { return { type: bookingConstants.BOOKING_VIEW_H_FAILURE, error } }
}

function requestBookingViewD(bookingDId, custId) {
    return dispatch => {
        dispatch(request({ bookingDId, custId }));
        bookingService.requestViewDObj(bookingDId, custId)
            .then(
                bookingDObj => {
                    dispatch(success(bookingDObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(bookingDObj) { return { type: bookingConstants.BOOKING_VIEW_D_REQUEST, bookingDObj } }
    function success(bookingDObj) { return { type: bookingConstants.BOOKING_VIEW_D_SUCCESS, bookingDObj } }
    function failure(error) { return { type: bookingConstants.BOOKING_VIEW_D_FAILURE, error } }
}

function requestCalculateBooking(calcBookingObj) {
    return dispatch => {
        dispatch(request(calcBookingObj));
        bookingService.requestCalculateBooking(calcBookingObj)
            .then(
                calcBookingObj => {
                    dispatch(success(calcBookingObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(calcBookingObj) { return { type: bookingConstants.CALCULATE_BOOKING_REQUEST, calcBookingObj } }
    function success(calcBookingObj) { return { type: bookingConstants.CALCULATE_BOOKING_SUCCESS, calcBookingObj } }
    function failure(error) { return { type: bookingConstants.CALCULATE_BOOKING_FAILURE, error } }
}

function clearCalculateBooking() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('calcBookingObj');
        }
    };

    function clear() { return { type: bookingConstants.CALCULATE_BOOKING_CLEAR } }
}

function requestSubmitBooking(submitBookingObj) {
    return dispatch => {
        dispatch(request(submitBookingObj));
        bookingService.requestSubmitBooking(submitBookingObj)
            .then(
                submitBookingObj => {
                    dispatch(success(submitBookingObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(submitBookingObj) { return { type: bookingConstants.SUBMIT_BOOKING_REQUEST, submitBookingObj } }
    function success(submitBookingObj) { return { type: bookingConstants.SUBMIT_BOOKING_SUCCESS, submitBookingObj } }
    function failure(error) { return { type: bookingConstants.SUBMIT_BOOKING_FAILURE, error } }
}

function clearSubmitBooking() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('submitBookingObj');
        }
    };

    function clear() { return { type: bookingConstants.SUBMIT_BOOKING_CLEAR } }
}

function requestPrintBooking(resiNo, itemCodeNo) {
    return dispatch => {
        dispatch(request({ resiNo, itemCodeNo }));
        bookingService.requestPrintBooking(resiNo, itemCodeNo)
            .then(
                printBookingObj => {
                    dispatch(success(printBookingObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(printBookingObj) { return { type: bookingConstants.PRINT_BOOKING_REQUEST, printBookingObj } }
    function success(printBookingObj) { return { type: bookingConstants.PRINT_BOOKING_SUCCESS, printBookingObj } }
    function failure(error) { return { type: bookingConstants.PRINT_BOOKING_FAILURE, error } }
}

function clearPrintBooking() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('printBookingObj');
        }
    };

    function clear() { return { type: bookingConstants.PRINT_BOOKING_CLEAR } }
}

function requestExportBookingDetail(exportBookingDetailObj) {
    return dispatch => {
        dispatch(request(exportBookingDetailObj));
        bookingService.requestExportDetail(exportBookingDetailObj)
            .then(
                exportBookingDetailObj => {
                    dispatch(success(exportBookingDetailObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(exportBookingDetailObj) { return { type: bookingConstants.EXPORT_BOOKING_DETAIL_REQUEST, exportBookingDetailObj } }
    function success(exportBookingDetailObj) { return { type: bookingConstants.EXPORT_BOOKING_DETAIL_SUCCESS, exportBookingDetailObj } }
    function failure(error) { return { type: bookingConstants.EXPORT_BOOKING_DETAIL_FAILURE, error } }
}

function clearExportBookingDetail() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('exportBookingDetail');
        }
    };

    function clear() { return { type: bookingConstants.EXPORT_BOOKING_DETAIL_CLEAR } }
}

function requestUploadBooking(uploadBookingObj) {
    return dispatch => {
        dispatch(request(uploadBookingObj));
        bookingService.requestUploadBooking(uploadBookingObj)
            .then(
                uploadBookingObj => {
                    dispatch(success(uploadBookingObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(uploadBookingObj) { return { type: bookingConstants.UPLOAD_BOOKING_REQUEST, uploadBookingObj } }
    function success(uploadBookingObj) { return { type: bookingConstants.UPLOAD_BOOKING_SUCCESS, uploadBookingObj } }
    function failure(error) { return { type: bookingConstants.UPLOAD_BOOKING_FAILURE, error } }
}

function clearUploadBooking() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('uploadBooking');
        }
    };

    function clear() { return { type: bookingConstants.UPLOAD_BOOKING_CLEAR } }
}

function getCustBookHDetail(custBookHDetailObj) {
    return (dispatch) => {
        dispatch(request(custBookHDetailObj));
        bookingService.getCustBookHDetail(custBookHDetailObj).then(
            (custBookHDetailObj) => {
                dispatch(success(custBookHDetailObj));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(custBookHDetailObj) {
        return { type: bookingConstants.GET_CUST_H_DETAIL_REQUEST, custBookHDetailObj };
    }
    function success(custBookHDetailObj) {
        return { type: bookingConstants.GET_CUST_H_DETAIL_SUCCESS, custBookHDetailObj };
    }
    function failure(error) {
        return { type: bookingConstants.GET_CUST_H_DETAIL_FAILURE, error };
    }
}

function clearCustBookHDetail() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('custBookHDetailObj');
        }
    };

    function clear() { return { type: bookingConstants.GET_CUST_H_DETAIL_CLEAR } }
}

function getListRecipient(recipientListObj) {
    return (dispatch) => {
        localStorage.setItem("recipientListObj", JSON.stringify(recipientListObj));
        dispatch(success(recipientListObj));
    };

    function success(recipientListObj) {
        return { type: bookingConstants.RECIPIENT_LIST_SUCCESS, recipientListObj };
    }
}

function clearRecipientList() {
    return (dispatch) => {
        dispatch(clear());
        if (typeof localStorage !== "undefined") {
            localStorage.removeItem("recipientListObj");
        }
    };

    function clear() {
        return { type: bookingConstants.RECIPIENT_LIST_CLEAR };
    }
}

function cancelKoliBooking(cancelKoliBookingObj) {
    return (dispatch) => {
        dispatch(request(cancelKoliBookingObj));
        bookingService.cancelKoliBooking(cancelKoliBookingObj).then(
            (cancelKoliBookingObj) => {
                dispatch(success(cancelKoliBookingObj));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(cancelKoliBookingObj) {
        return { type: bookingConstants.CAN_KOLI_BOOKING_REQUEST, cancelKoliBookingObj };
    }
    function success(cancelKoliBookingObj) {
        return { type: bookingConstants.CAN_KOLI_BOOKING_SUCCESS, cancelKoliBookingObj };
    }
    function failure(error) {
        return { type: bookingConstants.CAN_KOLI_BOOKING_FAILURE, error };
    }
}

function clearCancelKoliBooking() {
    return (dispatch) => {
        dispatch(clear());
        if (typeof localStorage !== "undefined") {
            localStorage.removeItem("cancelKoliBookingObj");
        }
    };

    function clear() {
        return { type: bookingConstants.CAN_KOLI_BOOKING_CLEAR };
    }
}
