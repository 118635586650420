import { customerConstants } from '../_constants';
import { customerService } from '../_services';

export const customerActions = {
    requestGetCustById,
    clearCust,
    requestGetPenerimaByHint,
    clearPenerimaByHint,
    requestGetPenerimaById,
    clearPenerima
};

function requestGetCustById(custId) {
    return dispatch => {
        dispatch(request(custId));
        customerService.requestGetCustById(custId)
            .then(
                custObj => {
                    dispatch(success(custObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(custObj) { return { type: customerConstants.GET_CUST_BY_ID_REQUEST, custObj } }
    function success(custObj) { return { type: customerConstants.GET_CUST_BY_ID_SUCCESS, custObj } }
    function failure(error) { return { type: customerConstants.GET_CUST_BY_ID_FAILURE, error } }
}

function clearCust() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('custObj');
        }
    };

    function clear() { return { type: customerConstants.GET_CUST_BY_ID_CLEAR } }
}

function requestGetPenerimaByHint(hint) {
    return dispatch => {
        dispatch(request(hint));
        customerService.requestGetPenerimaByHint(hint)
            .then(
                penerimaByHintObj => {
                    dispatch(success(penerimaByHintObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(penerimaByHintObj) { return { type: customerConstants.GET_PENERIMA_BY_HINT_REQUEST, penerimaByHintObj } }
    function success(penerimaByHintObj) { return { type: customerConstants.GET_PENERIMA_BY_HINT_SUCCESS, penerimaByHintObj } }
    function failure(error) { return { type: customerConstants.GET_PENERIMA_BY_HINT_FAILURE, error } }
}

function clearPenerimaByHint() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('penerimaByHintObj');
        }
    };

    function clear() { return { type: customerConstants.GET_PENERIMA_BY_HINT_CLEAR } }
}

function requestGetPenerimaById(custIdj) {
    return dispatch => {
        dispatch(request(custIdj));
        customerService.requestGetPenerimaById(custIdj)
            .then(
                penerimaObj => {
                    dispatch(success(penerimaObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(penerimaObj) { return { type: customerConstants.GET_PENERIMA_BY_ID_REQUEST, penerimaObj } }
    function success(penerimaObj) { return { type: customerConstants.GET_PENERIMA_BY_ID_SUCCESS, penerimaObj } }
    function failure(error) { return { type: customerConstants.GET_PENERIMA_BY_ID_FAILURE, error } }
}

function clearPenerima() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('penerimaObj');
        }
    };

    function clear() { return { type: customerConstants.GET_PENERIMA_BY_ID_CLEAR } }
}

