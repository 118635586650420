import { httpHelpers } from '../_helpers';
import { urlConstants } from '../_constants';

export const customerService = {
    requestGetCustById,
    requestGetPenerimaByHint,
    requestGetPenerimaById
};

function requestGetCustById(custId) {
    const getCustByIdObj = {
        Id: custId,
    };

    const requestOptions = {
        method: 'POST',
        headers: { 
        'Content-Type': 'application/json', 
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0' },
        body: JSON.stringify(getCustByIdObj)
    };

    return fetch(urlConstants.URL_GET_CUST_BY_ID, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            if (result) {
                console.log("result", result);
                var _result = ({
                    custObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('custObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function requestGetPenerimaByHint(hint) {
    const getPenerimaByHintObj = {
        CustName: hint,
    };

    const requestOptions = {
        method: 'POST',
        headers: { 
        'Content-Type': 'application/json', 
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0' },
        body: JSON.stringify(getPenerimaByHintObj)
    };

    return fetch(urlConstants.URL_GET_LIST_CUST_BY_HINT, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            if (result) {
                console.log("_result", result)
                var _result = ({
                    penerimaByHintObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('penerimaByHintObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function requestGetPenerimaById(custId) {
    const getPenerimaByIdObj = {
        Id: custId,
    };

    const requestOptions = {
        method: 'POST',
        headers: { 
        'Content-Type': 'application/json', 
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0' },
        body: JSON.stringify(getPenerimaByIdObj)
    };

    return fetch(urlConstants.URL_GET_CUST_BY_ID, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            if (result) {
                var _result = ({
                    penerimaObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('penerimaObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}