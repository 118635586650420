import { dashboardConstants } from "../_constants";

let summaryPengirimanDashboardObj = JSON.parse(
  localStorage.getItem("summaryPengirimanDashboard")
);
const initialStateSummaryPengirimanDashboard = summaryPengirimanDashboardObj
  ? {
      loggedIn: true,
      summaryPengirimanDashboard:
        summaryPengirimanDashboardObj.summaryPengirimanDashboard,
    }
  : {};

export function summaryPengirimanDashboard(
  state = initialStateSummaryPengirimanDashboard,
  action
) {
  switch (action.type) {
    case dashboardConstants.SUMMARY_PENGIRIMAN_DASHBOARD_REQUEST:
      return {
        summaryPengirimanDashboard:
          action.summaryPengirimanDashboard.summaryPengirimanDashboard,
      };
    case dashboardConstants.SUMMARY_PENGIRIMAN_DASHBOARD_SUCCESS:
      return {
        summaryPengirimanDashboard:
          action.summaryPengirimanDashboard.summaryPengirimanDashboard,
      };
    case dashboardConstants.SUMMARY_PENGIRIMAN_DASHBOARD_FAILURE:
      return {};
    default:
      return state;
  }
}

let summaryPenerimaanDashboardObj = JSON.parse(
  localStorage.getItem("summaryPenerimaanDashboard")
);
const initialStateSummaryPenerimaanDashboard = summaryPenerimaanDashboardObj
  ? {
      loggedIn: true,
      summaryPenerimaanDashboard:
        summaryPenerimaanDashboardObj.summaryPenerimaanDashboard,
    }
  : {};

export function summaryPenerimaanDashboard(
  state = initialStateSummaryPenerimaanDashboard,
  action
) {
  switch (action.type) {
    case dashboardConstants.SUMMARY_PENERIMAAN_DASHBOARD_REQUEST:
      return {
        summaryPenerimaanDashboard:
          action.summaryPenerimaanDashboard.summaryPenerimaanDashboard,
      };
    case dashboardConstants.SUMMARY_PENERIMAAN_DASHBOARD_SUCCESS:
      return {
        summaryPenerimaanDashboard:
          action.summaryPenerimaanDashboard.summaryPenerimaanDashboard,
      };
    case dashboardConstants.SUMMARY_PENERIMAAN_DASHBOARD_FAILURE:
      return {};
    default:
      return state;
  }
}

let summaryKomplainDashboardObj = JSON.parse(
  localStorage.getItem("summaryKomplainDashboard")
);
const initialStateSummaryKomplainDashboard = summaryKomplainDashboardObj
  ? {
      loggedIn: true,
      summaryKomplainDashboard:
        summaryKomplainDashboardObj.summaryKomplainDashboard,
    }
  : {};

export function summaryKomplainDashboard(
  state = initialStateSummaryKomplainDashboard,
  action
) {
  switch (action.type) {
    case dashboardConstants.SUMMARY_KOMPLAIN_DASHBOARD_REQUEST:
      return {
        summaryKomplainDashboard:
          action.summaryKomplainDashboard.summaryKomplainDashboard,
      };
    case dashboardConstants.SUMMARY_KOMPLAIN_DASHBOARD_SUCCESS:
      return {
        summaryKomplainDashboard:
          action.summaryKomplainDashboard.summaryKomplainDashboard,
      };
    case dashboardConstants.SUMMARY_KOMPLAIN_DASHBOARD_FAILURE:
      return {};
    default:
      return state;
  }
}

let summaryBookingDashboardObj = JSON.parse(
  localStorage.getItem("summaryBookingDashboard")
);
const initialStateSummaryBookingDashboard = summaryBookingDashboardObj
  ? {
      loggedIn: true,
      summaryBookingDashboard:
        summaryBookingDashboardObj.summaryBookingDashboard,
    }
  : {};

export function summaryBookingDashboard(
  state = initialStateSummaryBookingDashboard,
  action
) {
  switch (action.type) {
    case dashboardConstants.SUMMARY_BOOKING_DASHBOARD_REQUEST:
      return {
        summaryBookingDashboard:
          action.summaryBookingDashboard.summaryBookingDashboard,
      };
    case dashboardConstants.SUMMARY_BOOKING_DASHBOARD_SUCCESS:
      return {
        summaryBookingDashboard:
          action.summaryBookingDashboard.summaryBookingDashboard,
      };
    case dashboardConstants.SUMMARY_BOOKING_DASHBOARD_FAILURE:
      return {};
    default:
      return state;
  }
}

let summaryPickupReqDashboardObj = JSON.parse(
  localStorage.getItem("summaryPickupReqDashboard")
);
const initialStateSummaryPickupReqDashboard = summaryPickupReqDashboardObj
  ? {
      loggedIn: true,
      summaryPickupReqDashboard:
        summaryPickupReqDashboardObj.summaryPickupReqDashboard,
    }
  : {};

export function summaryPickupReqDashboard(
  state = initialStateSummaryPickupReqDashboard,
  action
) {
  switch (action.type) {
    case dashboardConstants.SUMMARY_PICKUP_REQ_DASHBOARD_REQUEST:
      return {
        summaryPickupReqDashboard:
          action.summaryPickupReqDashboard.summaryPickupReqDashboard,
      };
    case dashboardConstants.SUMMARY_PICKUP_REQ_DASHBOARD_SUCCESS:
      return {
        summaryPickupReqDashboard:
          action.summaryPickupReqDashboard.summaryPickupReqDashboard,
      };
    case dashboardConstants.SUMMARY_PICKUP_REQ_DASHBOARD_FAILURE:
      return {};
    default:
      return state;
  }
}

let checkOngkirObj = JSON.parse(localStorage.getItem("checkOngkir"));
const initialStateCheckOngkir = checkOngkirObj
  ? {
      loggedIn: true,
      checkOngkir: checkOngkirObj.checkOngkir,
    }
  : {};

export function checkOngkir(state = initialStateCheckOngkir, action) {
  switch (action.type) {
    case dashboardConstants.CHECK_ONGKIR_REQUEST:
      return {
        checkOngkir: action.checkOngkir.checkOngkir,
      };
    case dashboardConstants.CHECK_ONGKIR_SUCCESS:
      return {
        checkOngkir: action.checkOngkir.checkOngkir,
      };
    case dashboardConstants.CHECK_ONGKIR_FAILURE:
      return { errCheckOngkir: action.error };
    default:
      return state;
  }
}


let bannerObj = JSON.parse(localStorage.getItem("banner"));
const initialStateBanner = bannerObj
    ? {
        loggedIn: true,
        banner: bannerObj.banner,
    }
    : {};

export function banner(state = initialStateBanner, action) {
    switch (action.type) {
        case dashboardConstants.BANNER_DASHBOARD_REQUEST:
            return {
                banner: {}
,
            };
        case dashboardConstants.BANNER_DASHBOARD_SUCCESS:
            return {
                banner: action.banner.banner,
            };
        case dashboardConstants.BANNER_DASHBOARD_FAILURE:
            return { errBanner: action.error };
        default:
            return state;
    }
}
