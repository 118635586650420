import { accConstants } from '../_constants';

let acc = JSON.parse(localStorage.getItem('acc'));
let pwdObj = JSON.parse(localStorage.getItem('pwdObj'));
const initialState = acc ? { loggedIn: true, acc: acc.acc, accInfo: acc.accInfo } : {};
const initialStatePwd = pwdObj ? { loggedIn: true, pwdObj: pwdObj } : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case accConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                acc: action.acc.acc
            };
        case accConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                acc: action.acc.acc,
                accInfo: action.acc.accInfo
            };
        case accConstants.REGISTER_SUCCESS:
            return {
                loggedIn: true,
                acc: action.acc.acc,
                accInfo: action.acc.accInfo
            };
        case accConstants.LOGIN_FAILURE:
            return { errLoginObj: action.error };
        case accConstants.LOGOUT:
            return {};

        case accConstants.IMPERSONATE_LOGIN_REQUEST:
            return {
                loggingIn: true,
                acc: action.acc.acc
            };
        case accConstants.IMPERSONATE_LOGIN_SUCCESS:
            return {
                loggedIn: true,
                acc: action.acc.acc,
                accInfo: action.acc.accInfo
            };
        case accConstants.IMPERSONATE_LOGIN_FAILURE:
            return { errLoginObj: action.error };
        default:
            return state
    }
}


export function resetPassword(state = initialStatePwd, action) {
    switch (action.type) {
        case accConstants.RESET_PASSWORD_REQUEST:
            return {
                loggingIn: true,
                pwdObj: action.pwdObj
            };
        case accConstants.RESET_PASSWORD_SUCCESS:
            return {
                loggedIn: true,
                pwdObj: action.pwdObj
            };
        case accConstants.RESET_PASSWORD_FAILURE:
            return {
                errPwdObj: action.error
            };
        case accConstants.RESET_PASSWORD_CLEAR:
            return {};
        default:
            return state
    }
}