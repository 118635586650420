import { httpHelpers } from "../_helpers";
import { urlConstants } from "../_constants";

export const pengirimanService = {
  pengirimanMonitoring,
  pengirimanDetail,
  exportPengirimanData,
};

function pengirimanMonitoring(pengirimanMonitoringObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(pengirimanMonitoringObj),
  };

  return fetch(urlConstants.URL_PENGIRIMAN_MONITORING, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          pengirimanMonitoring: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem("pengirimanMonitoring", JSON.stringify(_result));
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}

function pengirimanDetail(pengirimanDetailObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(pengirimanDetailObj),
  };

  return fetch(urlConstants.URL_PENGIRIMAN_DETAIL, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          pengirimanDetail: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem("pengirimanDetail", JSON.stringify(_result));
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}

function exportPengirimanData(exportPengirimanDataObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(exportPengirimanDataObj),
  };

  return fetch(urlConstants.URL_EXPORT_PENGIRIMAN_DATA, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          exportPengirimanData: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem("exportPengirimanData", JSON.stringify(_result));
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}
