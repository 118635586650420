import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadActions } from "../../_actions";
import {
  AlertMessage,
  Pagination,
  UploadMonitoringTable,
  ShortcutDate,
} from "../../components";
import { masterSettingConstants } from "../../_constants";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

const UploadMonitoring = ({ t }) => {
  const acc = useSelector((state) => state.authentication.accInfo);
  const uploadMonitoring = useSelector(
    (state) => state.uploadMonitoring.uploadMonitoring
  );
  const errUploadMonitoring = useSelector(
    (state) => state.uploadMonitoring.errUploadMonitoring
  );

  const maxDate = new Date();
  const minDate = new Date();
  const finalMinDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);

  const dispatch = useDispatch();

  const [uploadMonitoringForm, setPenerimaanMonitoringForm] = useState({
    trxDtFrom: dateParser(finalMinDate),
    trxDtTo: dateParser(maxDate),
    fileName: "",
    uploadType: "",
    uploadStatus: ""
  });
  const [enabled, setEnabled] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState({
    Type: "",
    StatusMessage: "",
  });
  const [rowPerPage, setRowPerPage] = useState(10);
  let [num, setNum] = useState(1);
  let [cur, setCur] = useState(1);
  const [loadMore, setLoadmore] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [shortcut, setShortcut] = useState(false);

  useEffect(() => {
    setTotalRecord(0);
    setLoadmore(1);
    callUploadMonitoring(1, rowPerPage);
    setSubmitted(true);
    setCur(1);
    setNum(1);
  }, []);

  function dateParser(value) {
    const date = new Date(value);
    const dateParsed =
      date.getFullYear() +
      "-" +
      date.toLocaleString("en-us", { month: "2-digit" }) +
      "-" +
      date.toLocaleString("en-us", { day: "2-digit" });
    return dateParsed;
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setPenerimaanMonitoringForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleSearch(e) {
    e.preventDefault();

    setSubmitted(true);
    if (
      uploadMonitoringForm.trxDtFrom &&
      uploadMonitoringForm.trxDtTo
    ) {
      setNum(1);
      setLoadmore(1);
      setTotalRecord(0);
      callUploadMonitoring(1, rowPerPage);
      setCur(1);
    }
  }

  useEffect(() => {
    if (
      errUploadMonitoring &&
      errUploadMonitoring.StatusCode !== "200"
    ) {
      setIsError(true);
      setAlert({
        Type: "Error",
        StatusMessage: errUploadMonitoring.StatusMessage,
      });
    }
  }, [errUploadMonitoring]);

  useEffect(() => {
    if (uploadMonitoring && uploadMonitoring.TotalRecord) {
      setTotalRecord(uploadMonitoring.TotalRecord);
    }
  }, [uploadMonitoring]);

  useEffect(() => {
    if (
      totalRecord > 0 &&
      uploadMonitoringForm.trxDtFrom &&
      uploadMonitoringForm.trxDtTo
    ) {
      callUploadMonitoring(cur, rowPerPage);
    }
  }, [cur]);

  useEffect(() => {
    if (
      totalRecord > 0 &&
      uploadMonitoringForm.trxDtFrom &&
      uploadMonitoringForm.trxDtTo
    ) {
      callUploadMonitoring(1, loadMore * rowPerPage);
    }
  }, [loadMore]);

  const getDate = (dateObj) => {
    setPenerimaanMonitoringForm((prevState) => ({
      ...prevState,
      trxDtFrom: dateObj.dateFrom,
      trxDtTo: dateObj.dateTo,
    }));

    setShortcut(true);
  };

  useEffect(() => {
    if (shortcut) {
      setTotalRecord(0);
      setLoadmore(1);
      setNum(1);
      callUploadMonitoring(1, rowPerPage);
      setSubmitted(true);
      setCur(1);
      setShortcut(false);
    }
  }, [shortcut]);

  function callUploadMonitoring(pageNo, rowPerPages) {
    if (acc && acc.CustId) {
      var requestObj = {
        CustId: acc.CustId,
        TrxDtFrom: uploadMonitoringForm.trxDtFrom,
        TrxDtTo: uploadMonitoringForm.trxDtTo,
        FileName: uploadMonitoringForm.fileName,
        UploadType: uploadMonitoringForm.uploadType,
        UploadStatus: uploadMonitoringForm.uploadStatus,
        RowPerPage: rowPerPages,
        PageNo: pageNo,
      };
      dispatch(uploadActions.uploadMonitoring(requestObj));
    }
  }

  return (
    <>
      <div className="hidden sm:block">
        <div className="justify-center py-2 px-4 flex flex-row">
          <h1 className="font-bold text-xl">Upload Monitoring</h1>
        </div>
        <form onSubmit={handleSearch} method="POST">
          <div className="max-w-6xl mx-auto bg-white text-xs sm:text-sm font-medium">
            <div className="row px-2 py-1">
              <div className="col-md-3 text-xs sm:text-sm">
                Tanggal Awal Transaksi
              </div>
              <div className="col-md-3 text-xs sm:text-sm">
                <input
                  aria-label="Tanggal Awal Transaksi"
                  id="trxDtFrom"
                  name="trxDtFrom"
                  type="date"
                  value={uploadMonitoringForm.trxDtFrom}
                  className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                  onChange={handleChange}
                />
                {submitted && !uploadMonitoringForm.trxDtFrom && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                    Tidak boleh kosong
                  </div>
                )}
              </div>
              <div className="col-md-3 text-xs sm:text-sm">
                Tanggal Akhir Transaksi
              </div>
              <div className="col-md-3 text-xs sm:text-sm">
                <input
                  aria-label="Tanggal Akhir Transaksi"
                  id="trxDtTo"
                  name="trxDtTo"
                  type="date"
                  value={uploadMonitoringForm.trxDtTo}
                  className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                  onChange={handleChange}
                />
                {submitted && !uploadMonitoringForm.trxDtTo && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                    Tidak boleh kosong
                  </div>
                )}
              </div>
            </div>
            <div className="row px-2 py-1">
              <div className="col-md-3 text-xs sm:text-sm">Upload Type</div>
              <div className="col-md-3 text-xs sm:text-sm">
                <select
                  className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                  id="uploadType"
                  name="uploadType"
                  value={uploadMonitoringForm.uploadType}
                  onChange={handleChange}
                >
                  <option value="">Semua</option>
                  <option value={masterSettingConstants.UPLOAD_TYPE_BOOKING}>
                    Booking
                  </option>
                </select>
              </div>
              <div className="col-md-3 text-xs sm:text-sm">Upload Status</div>
              <div className="col-md-3 text-xs sm:text-sm">
                <select
                  className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                  id="uploadStatus"
                  name="uploadStatus"
                  value={uploadMonitoringForm.uploadStatus}
                  onChange={handleChange}
                >
                  <option value="">Semua</option>
                  <option value={masterSettingConstants.UPLOAD_STATUS_REQ}>
                    Request
                  </option>
                  <option value={masterSettingConstants.UPLOAD_STATUS_INP}>
                    On Process
                  </option>
                  <option value={masterSettingConstants.UPLOAD_STATUS_ERR}>
                    Error
                  </option>
                  <option value={masterSettingConstants.UPLOAD_STATUS_DONE}>
                    Selesai
                  </option>
                </select>
              </div>
            </div>
            <div className="row px-2 py-1">
              <div className="col-md-3 text-xs sm:text-sm">
                Nama File
              </div>
              <div className="col-md-3 text-xs sm:text-sm">
                <input
                  aria-label="Nama File"
                  id="fileName"
                  name="fileName"
                  type="text"
                  value={uploadMonitoringForm.fileName}
                  className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex justify-end mb-2 -mt-2">
              <ShortcutDate onSubmit={getDate} />
            </div>
            <div className="flex justify-end mb-2 -mt-4">
              <button
                type="submit"
                className="relative w-40 flex justify-center items-center mr-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="block sm:hidden">
        <div className="bg-blue-600">
          <h4 className="flex justify-center text-xl font-semibold text-white py-2">
            Upload Monitoring
          </h4>
        </div>
        <form onSubmit={handleSearch} method="POST">
          <div className="flex flex-col text-xs sm:text-sm px-3">
            <div className="flex flex-row font-bold pt-2">
              <div className="flex-1 text-xs sm:text-sm flex items-center">
                Filter dari Tanggal
              </div>
              <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                sampai Tanggal
              </div>
            </div>
            <div className="flex flex-row mt-1">
              <div className="flex-1 text-xs sm:text-sm flex items-center">
                <input
                  aria-label="Tanggal Awal Transaksi"
                  id="trxDtFrom"
                  name="trxDtFrom"
                  type="date"
                  value={uploadMonitoringForm.trxDtFrom}
                  className={`py-1 px-2 ${submitted && !uploadMonitoringForm.trxDtFrom
                    ? "border-red-500"
                    : "border-gray-300"
                    } text-xs sm:text-sm outline-none rounded-sm w-100`}
                  placeholder="Tanggal Awal Transaksi"
                  onChange={handleChange}
                />
              </div>
              <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                <input
                  aria-label="Tanggal Akhir Transaksi"
                  id="trxDtTo"
                  name="trxDtTo"
                  type="date"
                  value={uploadMonitoringForm.trxDtTo}
                  className={`py-1 px-2 ${submitted && !uploadMonitoringForm.trxDtTo
                    ? "border-red-500"
                    : "border-gray-300"
                    } text-xs sm:text-sm outline-none rounded-sm w-100`}
                  placeholder="Tanggal Akhir Transaksi"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex-1 text-xs sm:text-sm flex items-center">
                {submitted && !uploadMonitoringForm.trxDtFrom && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 ml-4 py-1">
                    Isi tanggal awal
                  </div>
                )}
              </div>
              <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                {submitted && !uploadMonitoringForm.trxDtTo && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                    Isi tanggal akhir
                  </div>
                )}
              </div>
            </div>
            {enabled === true && (
              <>
                <div className="flex flex-row mt-1 font-bold">
                  <div className="flex-1 text-xs sm:text-sm flex items-center">
                    Upload Type
                  </div>
                  <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                    Upload Status
                  </div>
                </div>
                <div className="flex flex-row mt-1">
                  <div className="flex-1 text-xs sm:text-sm flex items-center">
                    <select
                      className="py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none rounded-sm w-100"
                      id="uploadType"
                      name="uploadType"
                      value={uploadMonitoringForm.uploadType}
                      onChange={handleChange}
                    >
                      <option value="">Semua</option>
                      <option value={masterSettingConstants.UPLOAD_TYPE_BOOKING}>
                        Booking
                      </option>
                    </select>
                  </div>
                  <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                    <select
                      className="py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none rounded-sm w-100"
                      id="uploadStatus"
                      name="uploadStatus"
                      value={uploadMonitoringForm.uploadStatus}
                      onChange={handleChange}
                    >
                      <option value="">Semua</option>
                      <option value={masterSettingConstants.UPLOAD_STATUS_REQ}>
                        Request
                      </option>
                      <option value={masterSettingConstants.UPLOAD_STATUS_INP}>
                        On Process
                      </option>
                      <option value={masterSettingConstants.UPLOAD_STATUS_ERR}>
                        Error
                      </option>
                      <option value={masterSettingConstants.UPLOAD_STATUS_DONE}>
                        Selesai
                      </option>
                    </select>
                  </div>
                </div>
                <div className="flex flex-row font-bold pt-2">
                  <div className="flex-1 text-xs sm:text-sm flex items-center">
                    File Name
                  </div>
                </div>
                <div className="flex flex-row mt-1">
                  <div className="flex-1 text-xs sm:text-sm flex items-center">
                    <input
                      aria-label="File Name"
                      id="fileName"
                      name="fileName"
                      type="text"
                      value={uploadMonitoringForm.fileName}
                      className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                      placeholder="File Name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="flex">
            <div className="flex flex-1 flex-col text-xs sm:text-sm items-center">
              <button
                className="text-red-600 px-3 pt-2"
                onClick={(e) => setEnabled(!enabled)}
              >
                {enabled === true ? (
                  <>
                    Hide Search
                    <FaCaretUp
                      className="mb-1 w-4 h-4"
                      style={{ display: "inline-block" }}
                    />
                  </>
                ) : (
                  <>
                    Advance Search
                    <FaCaretDown
                      className="mb-1 w-4 h-4"
                      style={{ display: "inline-block" }}
                    />
                  </>
                )}
              </button>
            </div>
          </div>
          <ShortcutDate onSubmit={getDate} />
          <div className="flex flex-row justify-center -mt-4 mx-3">
            <div className="flex-1 mr-1">
              <button
                type="submit"
                className="text-xs sm:text-sm w-100 my-2 py-2 border border-transparent font-medium rounded-full text-white bg-red-600"
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
      {isError && errUploadMonitoring && (
        <AlertMessage
          isError={isError}
          alert={alert}
          setAlert={setAlert}
          setIsError={setIsError}
        />
      )}
      {uploadMonitoring && uploadMonitoring.Result && (
        <div className="justify-center">
          <UploadMonitoringTable />
          {uploadMonitoring.Result.length > 0 && (
            <Pagination
              cur={cur}
              setCur={setCur}
              loadMore={loadMore}
              setLoadmore={setLoadmore}
              rowPerPage={rowPerPage}
              totalRecord={totalRecord}
              num={num}
              setNum={setNum}
            />
          )}
        </div>
      )}
    </>
  );
};
export default UploadMonitoring;
