import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resiActions } from "../../_actions";
import { useLocation } from "react-router-dom";
import {
    AlertMessage,
    ComplaintMessagesTable,
    Pagination,
    ShortcutDate,
} from "../../components";
import { masterSettingConstants } from "../../_constants";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

const ResiComplaintMonitoring = ({ t }) => {
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();

    const acc = useSelector((state) => state.authentication.accInfo);
    const complaintMessages = useSelector(
        (state) => state.getComplaintMessages.getComplaintMessagesObj
    );
    const errGetComplaintMessagesObj = useSelector(
        (state) => state.getComplaintMessages.errGetComplaintMessagesObj
    );

    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [alert, setAlert] = useState({
        Type: "",
        StatusMessage: "",
    });
    const [rowPerPage, setRowPerPage] = useState(10);
    let [cur, setCur] = useState(1);
    let [num, setNum] = useState(1);
    const [loadMore, setLoadmore] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [shortcut, setShortcut] = useState(false);
    const maxDate = new Date();
    const minDate = new Date();
    const finalMinDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);

    const _trxDtFrom = query.get("trxDtFrom");
    const _trxDtTo = query.get("trxDtTo");
    const _process = query.get("process");
    const _addrType = query.get("addrType");
    const [resiComplaintMonitoringForm, setResiComplaintMonitoringForm] =
        useState({
            trxDtFrom: _trxDtFrom ? _trxDtFrom : dateParser(finalMinDate),
            trxDtTo: _trxDtTo ? _trxDtTo : dateParser(maxDate),
            process: _process ? _process : "",
            addrType: _addrType ? _addrType : "",
        });
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        setTotalRecord(0);
        setLoadmore(1);
        setNum(1);
        callGetComplaintMessages(1, rowPerPage);
        setSubmitted(true);
        setCur(1);
    }, []);

    function dateParser(value) {
        const date = new Date(value);
        const dateParsed =
            date.getFullYear() +
            "-" +
            date.toLocaleString("en-us", { month: "2-digit" }) +
            "-" +
            date.toLocaleString("en-us", { day: "2-digit" });
        return dateParsed;
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setResiComplaintMonitoringForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    function handleSearch(e) {
        e.preventDefault();
        setNum(1);
        setSubmitted(true);
        if (
            resiComplaintMonitoringForm.trxDtFrom &&
            resiComplaintMonitoringForm.trxDtTo
        ) {
            setLoadmore(1);
            setTotalRecord(0);
            callGetComplaintMessages(1, rowPerPage);
            setCur(1);
        }
    }

    useEffect(() => {
        if (complaintMessages && complaintMessages.TotalRecord) {
            setTotalRecord(complaintMessages.TotalRecord);
        }
    }, [complaintMessages]);

    useEffect(() => {
        if (
            totalRecord > 0 &&
            resiComplaintMonitoringForm.trxDtFrom &&
            resiComplaintMonitoringForm.trxDtTo
        ) {
            callGetComplaintMessages(cur, rowPerPage);
        }
    }, [cur]);

    useEffect(() => {
        if (
            totalRecord > 0 &&
            resiComplaintMonitoringForm.trxDtFrom &&
            resiComplaintMonitoringForm.trxDtTo
        ) {
            callGetComplaintMessages(1, loadMore * rowPerPage);
        }
    }, [loadMore]);

    useEffect(() => {
        if (
            errGetComplaintMessagesObj &&
            errGetComplaintMessagesObj.StatusCode !== "200"
        ) {
            setIsError(true);
            setAlert({
                Type: "Error",
                StatusMessage: errGetComplaintMessagesObj.StatusMessage,
            });
        }
    }, [errGetComplaintMessagesObj]);

    const getDate = (dateObj) => {
        setResiComplaintMonitoringForm((prevState) => ({
            ...prevState,
            trxDtFrom: dateObj.dateFrom,
            trxDtTo: dateObj.dateTo,
        }));

        setShortcut(true);
    };

    useEffect(() => {
        if (shortcut) {
            setTotalRecord(0);
            setLoadmore(1);
            setNum(1);
            callGetComplaintMessages(1, rowPerPage);
            setSubmitted(true);
            setCur(1);
            setShortcut(false);
        }
    }, [shortcut]);

    function callGetComplaintMessages(pageNo, rowPerPages) {
        if (acc && acc.CustId) {
            var requestObj = {
                CustId: acc.CustId,
                ResiNo: null,
                TrxDtFrom: resiComplaintMonitoringForm.trxDtFrom,
                TrxDtTo: resiComplaintMonitoringForm.trxDtTo,
                Process: resiComplaintMonitoringForm.process,
                AddrType: resiComplaintMonitoringForm.addrType,
                PageNo: pageNo,
                RowPerPage: rowPerPages,
                PageNo: 1,
            };
            dispatch(resiActions.getComplaintMessages(requestObj));
        }
    }

    return (
        <>
            <div className="hidden sm:block">
                <div className="justify-center py-2 px-4 flex flex-row">
                    <h1 className="font-bold text-xl">Komplain Monitoring</h1>
                </div>
                <form onSubmit={handleSearch} method="POST">
                    <div className="max-w-6xl mx-auto text-xs sm:text-sm font-medium">
                        <div className="row px-2 py-1">
                            <div className="col-md-3 text-xs sm:text-sm">
                                Tanggal Awal Transaksi
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                <input
                                    aria-label="Tanggal Awal Transaksi"
                                    id="trxDtFrom"
                                    name="trxDtFrom"
                                    type="date"
                                    value={resiComplaintMonitoringForm.trxDtFrom}
                                    className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                    onChange={handleChange}
                                />
                                {submitted && !resiComplaintMonitoringForm.trxDtFrom && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Tidak boleh kosong
                                    </div>
                                )}
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                Tanggal Akhir Transaksi
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                <input
                                    aria-label="Tanggal Akhir Transaksi"
                                    id="trxDtTo"
                                    name="trxDtTo"
                                    type="date"
                                    value={resiComplaintMonitoringForm.trxDtTo}
                                    className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                    onChange={handleChange}
                                />
                                {submitted && !resiComplaintMonitoringForm.trxDtTo && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Tidak boleh kosong
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row px-2 py-1">
                            <div className="col-md-3 text-xs sm:text-sm">Process</div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                <select
                                    className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                    id="process"
                                    name="process"
                                    value={resiComplaintMonitoringForm.process}
                                    onChange={handleChange}
                                >
                                    <option value="">Semua</option>
                                    <option value={masterSettingConstants.PROCESS_ONPROCESS}>
                                        On Process
                                    </option>
                                    <option value={masterSettingConstants.PROCESS_DONE}>
                                        Done
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="flex justify-end mb-2 -mt-2">
                            <ShortcutDate onSubmit={getDate} />
                        </div>
                        <div className="flex justify-end mb-2 -mt-4">
                            <button
                                type="submit"
                                className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="block sm:hidden">
                <div className="bg-blue-600">
                    <h4 className="flex justify-center text-xl font-semibold text-white py-2">
                        Komplain Monitoring
                    </h4>
                </div>

                <form onSubmit={handleSearch} method="POST">
                    <div className="flex flex-col text-xs sm:text-sm px-3">
                        <div className="flex flex-row font-bold pt-2">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                Filter dari Tanggal
                            </div>
                            <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                sampai Tanggal
                            </div>
                        </div>
                        <div className="flex flex-row mt-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                <input
                                    aria-label="Tanggal Awal Transaksi"
                                    id="trxDtFrom"
                                    name="trxDtFrom"
                                    type="date"
                                    value={resiComplaintMonitoringForm.trxDtFrom}
                                    className={`py-1 px-2 ${submitted && !resiComplaintMonitoringForm.trxDtFrom
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm outline-none rounded-sm w-100`}
                                    placeholder="Tanggal Awal Transaksi"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                <input
                                    aria-label="Tanggal Akhir Transaksi"
                                    id="trxDtTo"
                                    name="trxDtTo"
                                    type="date"
                                    value={resiComplaintMonitoringForm.trxDtTo}
                                    className={`py-1 px-2 ${submitted && !resiComplaintMonitoringForm.trxDtTo
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm outline-none rounded-sm w-100`}
                                    placeholder="Tanggal Akhir Transaksi"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="flex flex-row">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                {submitted && !resiComplaintMonitoringForm.trxDtFrom && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 ml-4 py-1">
                                        Isi tanggal awal
                                    </div>
                                )}
                            </div>
                            <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                {submitted && !resiComplaintMonitoringForm.trxDtTo && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Isi tanggal akhir
                                    </div>
                                )}
                            </div>
                        </div>
                        {enabled === true && (
                            <>
                                <div className="flex flex-row mt-1 font-bold">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        Process
                                    </div>
                                </div>
                                <div className="flex flex-row mt-1">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        <select
                                            className="py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none rounded-sm w-100"
                                            id="process"
                                            name="process"
                                            value={resiComplaintMonitoringForm.process}
                                            onChange={handleChange}
                                        >
                                            <option value="">Semua</option>
                                            <option value={masterSettingConstants.PROCESS_ONPROCESS}>
                                                On Process
                                            </option>
                                            <option value={masterSettingConstants.PROCESS_DONE}>
                                                Done
                                            </option>
                                        </select>
                                    </div>
                                    <div className="flex-1">
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="flex">
                        <div className="flex flex-1 flex-col text-xs sm:text-sm items-center">
                            <button
                                className="text-red-600 px-3 pt-2"
                                onClick={(e) => setEnabled(!enabled)}
                            >
                                {enabled === true ? (
                                    <>
                                        Hide Search
                                        <FaCaretUp
                                            className="mb-1 w-4 h-4"
                                            style={{ display: "inline-block" }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        Advance Search
                                        <FaCaretDown
                                            className="mb-1 w-4 h-4"
                                            style={{ display: "inline-block" }}
                                        />
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                    <ShortcutDate onSubmit={getDate} />
                    <div className="flex flex-col justify-center -mt-4">
                        <button
                            type="submit"
                            className="mx-3 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                        >
                            Search
                        </button>
                    </div>
                </form>
            </div>
            {isError && errGetComplaintMessagesObj && (
                <AlertMessage
                    isError={isError}
                    alert={alert}
                    setAlert={setAlert}
                    setIsError={setIsError}
                />
            )}
            {complaintMessages &&
                complaintMessages.Result &&
                complaintMessages.Result.length === 0 && (
                    <div className="flex justify-center">Tidak ada complaint</div>
                )}
            {complaintMessages &&
                complaintMessages.Result &&
                complaintMessages.Result.length > 0 && (
                    <div className="justify-center pt-2">
                        <ComplaintMessagesTable isDashboard={true} />
                        <Pagination
                            cur={cur}
                            setCur={setCur}
                            loadMore={loadMore}
                            setLoadmore={setLoadmore}
                            rowPerPage={rowPerPage}
                            totalRecord={totalRecord}
                            num={num}
                            setNum={setNum}
                        />
                    </div>
                )}
        </>
    );
};
export default ResiComplaintMonitoring;
