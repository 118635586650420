import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    dashboardActions,
    districtActions,
    originActions,
} from "../../_actions";
import _ from "lodash";
import { NumericFormat } from "react-number-format";
import { AlertMessage } from "../../components";
import Select, { createFilter } from "react-select";

const CheckOngkirForm = ({ t }) => {
    const acc = useSelector((state) => state.authentication.accInfo);
    const origin = useSelector((state) => state.origin.origin);
    const district = useSelector((state) => state.district.districtObj);
    const checkOngkir = useSelector((state) => state.checkOngkir.checkOngkir);
    const errCheckOngkir = useSelector((state) => state.checkOngkir.errCheckOngkir);
    const [selectedOrigin, setSelectedOrigin] = useState();
    const [selectedDest, setSelectedDest] = useState();

    const dispatch = useDispatch();

    const [estWeight, setEstWeight] = useState(0);
    const [length, setLength] = useState(1);
    const [width, setWidth] = useState(1);
    const [height, setHeight] = useState(1);
    const [submitted, setSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [alert, setAlert] = useState({
        Type: "",
        StatusMessage: "",
    });
    const [destOptions, setDestOptions] = useState([]);
    const [originOptions, setOriginOptions] = useState([]);

    useEffect(() => {
        district && setDestOptions(district.map((dist, i) => ({ value: dist.DistrictId, label: dist.DistrictDescr })));
    }, [district])

    useEffect(() => {
        origin && setOriginOptions(origin.Result.map((or, i) => ({ value: or.OriginCode, label: or.OriginName })));
    }, [origin])

    const handleChangeOrigin = (selectedOption) => {
        setSelectedOrigin(selectedOption);
    };

    const handleChangeDest = (selectedOption) => {
        setSelectedDest(selectedOption);
    };

    useEffect(() => {
        dispatch(dashboardActions.clearCheckOngkir());
        dispatch(originActions.getOrigin());
        dispatch(districtActions.getDistrict());
        setEstWeight(0);
        setLength(1);
        setWidth(1);
        setHeight(1);
        setSelectedOrigin({ value: '', label: 'Pilih Asal' });
        setSelectedDest({ value: 0, label: 'Pilih Tujuan' });
    }, []);


    useEffect(() => {
        if (errCheckOngkir && errCheckOngkir.StatusCode !== "200") {
            setIsError(true);
            setAlert({ Type: "Error", StatusMessage: errCheckOngkir.StatusMessage })
        }

    }, [errCheckOngkir])

    function handleCheckOngkir(e) {
        e.preventDefault();

        setSubmitted(true);
        if (acc &&
            selectedDest.value != '' &&
            selectedOrigin.value != '' &&
            estWeight &&
            length &&
            width &&
            height
        ) {
            var requestObj = {
                CustId: acc.CustId,
                OriginCode: selectedOrigin.value,
                DestinationId: selectedDest.value,
                EstWeight: estWeight,
                Length: length,
                Width: width,
                Height: height,
            };

            dispatch(dashboardActions.checkOngkir(requestObj));
        }
    }

    function onChange(event) {
        let num = 0;
        if (event.target.value != "") {
            const str = event.target.value.replace(/,/g, "");
            num = parseFloat(str);
        }

        if (event.target.name === "estWeight") {
            setEstWeight(num);
        } else if (event.target.name == "length") {
            setLength(num);
        } else if (event.target.name == "width") {
            setWidth(num);
        } else if (event.target.name == "height") {
            setHeight(num);
        }
    }

    const style = {
        control: (base, state) => ({
            ...base,
            border: '1px solid rgba(209, 213, 219, var(--tw-border-opacity))',
            boxShadow: 'none',
            '&:hover': {
                border: '1px solid rgba(209, 213, 219, var(--tw-border-opacity))',
            }
        })
    };
    const styleMobile = {
        control: (base, state) => ({
            ...base,
            border: 'none',
            boxShadow: 'none',
            '&:hover': {
                border: 'none',
            }
        })
    };

    return (
        <>
            <div className="hidden sm:block">
                <div className="my-3 py-2 container rounded-md max-w-6xl mx-auto shadow-md hover:shadow-xl transition-shadow border-2 border-red-600">
                    <form onSubmit={handleCheckOngkir} method="POST">
                        <h1 className="py-6 mx-3 text-xl flex font-bold justify-center">
                            Cek Ongkir
                        </h1>
                        <div className="row py-1 px-3">
                            <div className="col-md-2 text-xs sm:text-sm font-bold">
                                Asal
                            </div>
                            <div className="col-md-4 text-xs sm:text-sm">
                                <Select
                                    filterOption={createFilter({ ignoreAccents: false })}
                                    value={selectedOrigin}
                                    options={originOptions}
                                    onChange={handleChangeOrigin}
                                    className="rounded-sm text-xs sm:text-sm border-gray-300 w-100"
                                    styles={style}
                                    menuPosition="fixed"
                                />
                                {
                                    submitted && (!selectedOrigin || selectedOrigin.value === '') &&
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Harap isi asal pengiriman
                                    </div>
                                }
                            </div>
                            <div className="col-md-2 text-xs sm:text-sm font-bold">
                                Tujuan
                            </div>
                            <div className="col-md-4 flex flex-col text-xs sm:text-sm">
                                <Select
                                    filterOption={createFilter({ ignoreAccents: false })}
                                    value={selectedDest}
                                    options={destOptions}
                                    onChange={handleChangeDest}
                                    className="rounded-sm text-xs sm:text-sm border-gray-300 w-100"
                                    styles={style}
                                    menuPosition="fixed"
                                />
                                {
                                    submitted && (!selectedDest || selectedDest.value === 0) &&
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Harap isi tujuan
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row py-1 px-3">
                            <div className="col-md-2 text-xs sm:text-sm font-bold">
                                Est. Berat (kg)
                            </div>
                            <div className="col-md-4 text-xs sm:text-sm">
                                <NumericFormat
                                    id="estWeight"
                                    name="estWeight"
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    className="currency rounded-sm py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none w-100"
                                    onChange={(e) => onChange(e)}
                                    placeholder="Isi estimasi berat (kg)"
                                    value={estWeight}
                                />
                                {
                                    submitted && (!estWeight || estWeight <= 0) &&
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 py-1 px-2">
                                        Harap isi estimasi berat barang
                                    </div>
                                }
                            </div>
                            <div className="col-md-2 text-xs sm:text-sm font-bold">
                                Volume Barang (cm)
                            </div>
                            <div className="col-md-4 text-xs sm:text-sm">
                                <div className="flex flex-row justify-between">
                                    <NumericFormat
                                        id="length"
                                        name="length"
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        className="currency rounded-sm py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none w-100"
                                        onChange={(e) => onChange(e)}
                                        value={length}
                                    />
                                    <div className="text-xs mx-1 sm:text-sm mt-2 text-center">
                                        X
                                    </div>
                                    <NumericFormat
                                        id="width"
                                        name="width"
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        className="currency rounded-sm py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none w-100"
                                        onChange={(e) => onChange(e)}
                                        placeholder="Isi estimasi berat (kg)"
                                        value={width}
                                    />
                                    <div className="text-xs mx-1 sm:text-sm mt-2 text-center">
                                        X
                                    </div>
                                    <NumericFormat
                                        id="height"
                                        name="height"
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        className="currency rounded-sm py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none w-100"
                                        onChange={(e) => onChange(e)}
                                        value={height}
                                    />
                                </div>
                                {submitted && (!length || !width || !height) && (
                                    <div className="flex flex-1 justify-center text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Harap isi volume barang
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-end px-3">
                            <button
                                type="submit"
                                className="flex justify-center items-center my-2 py-2 px-4 w-40 border border-transparent font-medium rounded-full text-xs sm:text-sm text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                            >
                                Cek Ongkir
                            </button>
                        </div>
                    </form>
                    {
                        isError == true && errCheckOngkir &&
                        <AlertMessage isError={isError} alert={alert} setAlert={setAlert} setIsError={setIsError} />
                    }

                    {checkOngkir &&
                        checkOngkir.Result &&
                        checkOngkir.Result.length == 0 && (
                            <div className="justify-center flex flex-row">
                                <p className="text-xs sm:text-sm">Tidak ada data</p>
                            </div>
                        )}

                    {checkOngkir &&
                        checkOngkir.Result &&
                        checkOngkir.Result.length > 0 && (
                            <div>
                                <table className="table table-striped text-xs sm:text-sm bg-white">
                                    <thead>
                                        <tr>
                                            <th className="text-right">Estimasi Berat Asli</th>
                                            <th className="text-right">Estimasi Berat Volume</th>
                                            <th className="text-left">Jenis Barang</th>
                                            <th className="text-left">Pengiriman</th>
                                            <th className="text-center">Estimasi Pengiriman</th>
                                            <th className="text-right">Ongkir</th>
                                            <th className="text-center">Tagih Tujuan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {checkOngkir.Result.map((co, i) => (
                                            <tr key={i}>
                                                <td align="right">{co.EstWeight}</td>
                                                <td align="right">{co.VolWeight}</td>
                                                <td align="left">{co.PackageCategory}</td>
                                                <td align="left">{co.TransportType}</td>
                                                <td align="center">{co.DeliveryDays}</td>
                                                <td align="right">
                                                    {new Intl.NumberFormat("en", {
                                                        minimumFractionDigits: 0,
                                                    }).format(co.MustBePaidAmt)}
                                                    <sup className="font-bold">{checkOngkir.IsSpecialPrice == true && "*"}</sup>
                                                </td>
                                                <td align="center">{co.IsTagihTujuan}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {checkOngkir &&
                                    checkOngkir.IsSpecialPrice == true && <p className="text-xs sm:text-sm font-bold py-1"><sup>* </sup>Harga Spesial</p>}
                                {checkOngkir && (
                                    <p className="text-xs sm:text-sm py-1">
                                        <b>VOLUME</b>
                                        <p>Volume Combo : (PxLxT) : 6000 =... Kg x Ongkir</p>
                                        <p>Volume Udara : (PxLxT) : 6000 =... Kg x Ongkir</p>
                                        <p>Volume Darat : (PxLxT) : 4000 = ... Kg x Ongkir</p>
                                        <b>PEMBULATAN TIMBANGAN</b>
                                        {
                                            checkOngkir && checkOngkir.ResultPembulatan && checkOngkir.ResultPembulatan.length > 0 && checkOngkir.ResultPembulatan.map((x, idx) => (
                                                <p>{x}</p>
                                            ))
                                        }
                                        <b className="flex justify-center">Ongkos kirim dapat berubah sewaktu-waktu.</b>
                                    </p>
                                )}
                            </div>
                        )}
                </div>
            </div>

            <div className="block sm:hidden">
                <div className="flex justify-center content-center text-xs sm:text-sm px-3 my-2">
                    <div className="rounded-md h-full w-full shadow-md hover:shadow-xl transition-shadow border-2 border-red-600 px-2">
                        <div className="flex flex-col items-center py-3">
                            <h1 className="text-xl flex font-bold">Cek Ongkir</h1>
                        </div>
                        <form onSubmit={handleCheckOngkir} method="POST">
                            <div className="h-full w-full flex items-center">
                                <div className="h-full w-full flex items-center">
                                    <div className="text-xs sm:text-sm font-medium w-full">
                                        <div className="flex flex-col py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                Asal
                                            </div>
                                            <div
                                                className={`flex-1 border-b-2 ${submitted &&
                                                    (!selectedOrigin || selectedOrigin.value === '')
                                                    ? "border-red-500"
                                                    : "border-gray-300"
                                                    } text-xs sm:text-sm py-1`}
                                            >
                                                <Select
                                                    filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!   
                                                    value={selectedOrigin}
                                                    options={originOptions}
                                                    onChange={handleChangeOrigin}
                                                    className="text-xs sm:text-sm border-none outline-none w-100"
                                                    styles={styleMobile}
                                                />
                                            </div>
                                            {submitted &&
                                                (!selectedOrigin || selectedOrigin.value === '') && (
                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                        Harap masukkan asal
                                                    </div>
                                                )}
                                        </div>

                                        <div className="flex flex-col py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                Tujuan
                                            </div>
                                            <div
                                                className={`flex-1 border-b-2 ${submitted &&
                                                    (!selectedDest || selectedDest.value === 0)
                                                    ? "border-red-500"
                                                    : "border-gray-300"
                                                    } text-xs sm:text-sm py-1`}
                                            >
                                                <Select
                                                    filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!   
                                                    value={selectedDest}
                                                    options={destOptions}
                                                    onChange={handleChangeDest}
                                                    className="text-xs sm:text-sm border-none outline-none w-100"
                                                    styles={styleMobile}
                                                />
                                            </div>
                                            {submitted && (!selectedDest || selectedDest.value === 0) && (
                                                <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                    Harap masukkan tujuan
                                                </div>
                                            )}
                                        </div>

                                        <div className="flex flex-col py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                Estimasi berat (kg)
                                            </div>
                                            <div
                                                className={`flex-1 border-b-2 ${submitted && estWeight <= 0
                                                    ? "border-red-500"
                                                    : "border-gray-300"
                                                    } text-xs sm:text-sm py-1`}
                                            >
                                                <NumericFormat
                                                    id="estWeight"
                                                    name="estWeight"
                                                    thousandSeparator={true}
                                                    allowLeadingZeros={false}
                                                    decimalScale={2}
                                                    fixedDecimalScale={false}
                                                    className="currency py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                                    onChange={(e) => onChange(e)}
                                                    placeholder="Isi estimasi berat (kg)"
                                                    value={estWeight}
                                                />
                                            </div>
                                            {submitted && estWeight <= 0 && (
                                                <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                    Harap masukkan estimasi berat
                                                </div>
                                            )}
                                        </div>

                                        <div className="flex flex-col py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                Volume Barang (cm)
                                            </div>
                                        </div>

                                        <div className="flex flex-col py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                <div className="flex flex-row justify-between">
                                                    <div className={`text-xs border-b-2 ${submitted && (!length) ? 'border-red-500' : 'border-gray-300'}`}>
                                                        <NumericFormat
                                                            id="length"
                                                            name="length"
                                                            thousandSeparator={true}
                                                            allowLeadingZeros={false}
                                                            decimalScale={0}
                                                            fixedDecimalScale={true}
                                                            className="currency py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                                            onChange={(e) => onChange(e)}
                                                            value={length}
                                                        />
                                                    </div>
                                                    <div className="text-xs mx-1 sm:text-sm mt-2 text-center">
                                                        X
                                                    </div>
                                                    <div className={`text-xs border-b-2 ${submitted && (!width) ? 'border-red-500' : 'border-gray-300'}`}>
                                                        <NumericFormat
                                                            id="width"
                                                            name="width"
                                                            thousandSeparator={true}
                                                            allowLeadingZeros={false}
                                                            decimalScale={0}
                                                            fixedDecimalScale={true}
                                                            className="currency py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                                            onChange={(e) => onChange(e)}
                                                            placeholder="Isi estimasi berat (kg)"
                                                            value={width}
                                                        />
                                                    </div>
                                                    <div className="text-xs mx-1 sm:text-sm mt-2 text-center">
                                                        X
                                                    </div>
                                                    <div className={`text-xs border-b-2 ${submitted && (!height) ? 'border-red-500' : 'border-gray-300'}`}>
                                                        <NumericFormat
                                                            id="height"
                                                            name="height"
                                                            thousandSeparator={true}
                                                            allowLeadingZeros={false}
                                                            decimalScale={0}
                                                            fixedDecimalScale={true}
                                                            className="currency py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                                            onChange={(e) => onChange(e)}
                                                            value={height}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {submitted && (!length || !width || !height) && (
                                                <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                    Harap masukkan volume barang
                                                </div>
                                            )}
                                        </div>

                                        <div className="flex flex-row justify-center">
                                            <button
                                                type="submit"
                                                className="w-100 my-2 py-2 px-4 text-xs sm:text-sm border border-transparent font-medium rounded-full text-white bg-red-600"
                                            >
                                                Cek Ongkir
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {
                            isError == true && errCheckOngkir &&
                            <AlertMessage isError={isError} alert={alert} setAlert={setAlert} setIsError={setIsError} />
                        }
                        {checkOngkir &&
                            checkOngkir.Result &&
                            checkOngkir.Result.length == 0 && (
                                <div className="justify-center flex flex-row">
                                    <p className="text-xs sm:text-sm">Tidak ada data</p>
                                </div>
                            )}
                        {checkOngkir &&
                            checkOngkir.Result &&
                            checkOngkir.Result.length > 0 &&
                            checkOngkir.Result.map((co, i) => (
                                <>
                                    <div
                                        className="h-full w-full bg-white shadow flex rounded items-center my-2"
                                        key={i}
                                    >
                                        <div className="relative h-full w-full flex items-center py-2 px-2" key={i}>
                                            <div className="text-sm font-medium w-full mr-3">
                                                <div className="flex py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Estimasi Berat Asli
                                                    </div>
                                                    <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                        {co.EstWeight}
                                                    </div>
                                                </div>
                                                <div className="flex py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Estimasi Berat Volume
                                                    </div>
                                                    <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                        {co.VolWeight}
                                                    </div>
                                                </div>
                                                <div className="flex py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Jenis Barang
                                                    </div>
                                                    <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                        {co.PackageCategory}
                                                    </div>
                                                </div>
                                                <div className="flex py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Pengiriman
                                                    </div>
                                                    <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                        {co.TransportType}
                                                    </div>
                                                </div>
                                                <div className="flex py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Estimasi Pengiriman
                                                    </div>
                                                    <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                        {co.DeliveryDays}
                                                    </div>
                                                </div>
                                                <div className="flex py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Harga
                                                    </div>
                                                    <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                        Rp{" "}
                                                        {new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 0,
                                                        }).format(co.MustBePaidAmt)}
                                                        <sup className="font-bold">{checkOngkir.IsSpecialPrice == true && "*"}</sup>
                                                    </div>
                                                </div>
                                                <div className="flex py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Tagih Tujuan
                                                    </div>
                                                    <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                        {co.IsTagihTujuan}
                                                    </div>
                                                </div>
                                            </div>
                                            <span className={`absolute ${co.TransportType === "DARAT" ? 'bg-green-600' : co.TransportType === "UDARA" ? 'bg-blue-600' : 'bg-red-600'} right-0 rounded-r w-3 h-full text-center item-center`}></span>
                                        </div>
                                    </div>
                                </>
                            ))}
                        {checkOngkir &&
                            checkOngkir.IsSpecialPrice == true && <p className="text-xs sm:text-sm font-bold py-1"><sup>* </sup>Harga Spesial</p>}
                        {checkOngkir && (
                            <p className="text-xs sm:text-sm py-1">
                                <b className="flex justify-center">Ongkos kirim dapat berubah, tanpa ada pemberitahuan</b>
                                <b>VOLUME</b>
                                <p>Volume Combo : (PxLxT) / 6000 =... Kg x Ongkir</p>
                                <p>Volume Udara : (PxLxT) / 6000 =... Kg x Ongkir</p>
                                <p>Volume Darat : (PxLxT) / 4000 = ... Kg x Ongkir</p>
                            </p>)}
                    </div>
                </div>
            </div>
        </>
    );
};
export default CheckOngkirForm;
