import { transportTypeConstants } from '../_constants';
import { transportTypeService } from '../_services';

export const transportTypeActions = {
    getTransportType,
    clearTransportType
};

function getTransportType(transportType) {
    return dispatch => {
        dispatch(request(transportType));
        transportTypeService.getTransportType(transportType)
            .then(
                transportTypeObj => {
                    dispatch(success(transportTypeObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(transportTypeObj) { return { type: transportTypeConstants.GET_TRANSPORT_TYPE_REQUEST, transportTypeObj } }
    function success(transportTypeObj) { return { type: transportTypeConstants.GET_TRANSPORT_TYPE_SUCCESS, transportTypeObj } }
    function failure(error) { return { type: transportTypeConstants.GET_TRANSPORT_TYPE_FAILURE, error } }
}

function clearTransportType() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('transportTypeObj');
        }
    };

    function clear() { return { type: transportTypeConstants.GET_TRANSPORT_TYPE_CLEAR } }
}
