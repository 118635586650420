import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FaAddressBook } from "react-icons/fa";
import "../styles/tailwind.css";
import { useDispatch, useSelector } from 'react-redux';
import { pickupActions } from '../_actions';
import moment from "moment";

const PickupAddressTable = ({ sortConfig, setSortConfig, setIsBtnClick }) => {
    const dispatch = useDispatch();
    const pickupAddressTypeObj = useSelector(state => state.pickupAddr.pickupAddrObj);
    const pickupAddEditObj = useSelector(state => state.pickupReqGrpAddEdit.pickupReqGrpObj);
    const acc = useSelector((state) => state.authentication.accInfo);
    function sort(key) {
        let direction = "ASC";
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === "ASC"
        ) {
            direction = "DESC";
        }
        setSortConfig({ key, direction });
    }

    function deletePickupAddr(e, addrType) {
        e.preventDefault();
        const pickupAddrTypeObj = {
            AddrType: addrType,
            CustId: acc.CustId,
            Username: acc.Username,
            Mode: "DELETE"
        };
        dispatch(pickupActions.addEditPickupReqGrp(pickupAddrTypeObj));
    }

    function reactivatePickupAddr(e, addrType) {
        e.preventDefault();
        const pickupAddrTypeObj = {
            AddrType: addrType,
            CustId: acc.CustId,
            Username: acc.Username,
            Mode: "REACTIVATE"
        };
        dispatch(pickupActions.addEditPickupReqGrp(pickupAddrTypeObj));
    }

    useEffect(() => {
        if (pickupAddEditObj && pickupAddEditObj.PickupReqGrpId != 0) {
            setIsBtnClick(true);
        }
    }, [pickupAddEditObj])


    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    return (
        <>
            <div className="text-xs sm:text-sm">
                {pickupAddressTypeObj &&
                    pickupAddressTypeObj.Result &&
                    (pickupAddressTypeObj.Result.length == 0 || pickupAddressTypeObj.Result == null) && (
                        <div className="justify-center flex flex-row">
                            <p>Tidak ada alamat pickup</p>
                        </div>
                    )}
                {pickupAddressTypeObj && pickupAddressTypeObj.Result != null && pickupAddressTypeObj.Result.length > 0 && (
                    <>
                        <div className="hidden sm:block text-xs sm:text-sm">
                            <table
                                className="table table-striped table-fixed bg-white"
                                aria-labelledby="tabelLabel"
                            >
                                <thead>
                                    <tr>
                                        <th>
                                            <button
                                                type="button"
                                                color="blue"
                                                onClick={() => sort("AddrType")}
                                                className="font-bold"
                                            >
                                                Nama Alamat
                                            </button>
                                        </th>
                                        <th>
                                            <button
                                                type="button"
                                                onClick={() => sort("ContactPerson")}
                                                className="font-bold"
                                            >
                                                Contact Person
                                            </button>
                                        </th>
                                        <th>
                                            <button
                                                type="button"
                                                onClick={() => sort("PhoneNo")}
                                                className="font-bold"
                                            >
                                                Nomor Ponsel
                                            </button>
                                        </th>
                                        <th>
                                            <button
                                                type="button"
                                                onClick={() => sort("Addr")}
                                                className="font-bold"
                                            >
                                                Alamat
                                            </button>
                                        </th>
                                        <th>
                                            <button
                                                type="button"
                                                onClick={() => sort("DistrictName")}
                                                className="font-bold text-left"
                                            >
                                                Kecamatan / Kabupaten
                                            </button>
                                        </th>
                                        <th>
                                            <button
                                                type="button"
                                                onClick={() => sort("PickupReqGrpStat")}
                                                className="font-bold"
                                            >
                                                Status
                                            </button>
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pickupAddressTypeObj.Result.map((pa) => (
                                        <tr key={pa.PickupReqGrpId}>
                                            <td>{pa.AddrType}</td>
                                            <td>{pa.ContactPerson}</td>
                                            <td>{pa.PhoneNo}</td>
                                            <td>{pa.Addr}</td>
                                            <td>{pa.DistrictName}</td>
                                            <td>{pa.PickupReqGrpStat}</td>
                                            <td>
                                                {pa.PickupReqGrpStat == "ACT" ?
                                                    <>
                                                        <Link target={"_blank"} to={{ pathname: `/PickupAddressForm?AddrType=${pa.AddrType}` }} style={{ color: '#337ab7' }}>
                                                            Ubah Alamat
                                                        </Link>
                                                        <label className="mx-2">|</label>
                                                        <Link to="" onClick={(e) => deletePickupAddr(e, pa.AddrType)} style={{ color: '#337ab7' }}>
                                                            Hapus
                                                        </Link>
                                                    </> :
                                                    <>
                                                        <Link to="" onClick={(e) => reactivatePickupAddr(e, pa.AddrType)} style={{ color: '#337ab7' }}>
                                                            Aktifkan
                                                        </Link>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="block sm:hidden">
                            <div className="rounded px-3">
                                {pickupAddressTypeObj.Result.map((pa) => (
                                    <div
                                        className="h-full w-full bg-white shadow flex rounded justify-center items-center my-2"
                                        key={pa.PickupReqGrpId}
                                    >
                                        <div className="relative h-full w-full flex items-center">
                                            <div className="text-sm font-medium w-full pb-1">
                                                <div className="pt-2 flex px-3">
                                                    <div className="flex flex-1">
                                                        <p className="flex items-center font-bold pr-1 text-sm">{pa.AddrType}</p>
                                                    </div>
                                                    <div className="flex flex-1 justify-end mr-1">
                                                        <div className="flex items-center">
                                                            <div className={`w-2.5 h-2.5 rounded-full ${pa.PickupReqGrpStat === "ACT" ? 'bg-green-600' : 'bg-red-600'}`}></div>
                                                        </div>
                                                        <p className={`flex items-center font-semibold text-xs sm:text-sm ml-1 ${pa.PickupReqGrpStat === "ACT" ? 'text-green-600' : 'text-red-600'}`}>
                                                            {pa.PickupReqGrpStat === "ACT" ? "Aktif" : "Tidak Aktif"}</p>
                                                    </div>
                                                </div>

                                                <div className="px-3 py-2 flex flex-row">
                                                    <div className="flex justify-center items-center">
                                                        <FaAddressBook className="text-red-500 w-10 h-10" />
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <div className="flex text-dark px-3">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                                {pa.ContactPerson}
                                                            </div>
                                                        </div>
                                                        <div className="flex text-dark px-3">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                                {pa.PhoneNo}
                                                            </div>
                                                        </div>
                                                        <div className="flex text-dark px-3">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                                {pa.Addr}
                                                            </div>
                                                        </div>
                                                        <div className="flex text-dark px-3">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                                {pa.DistrictName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-3 mt-1 mb-2 flex justify-end">
                                                    {pa.PickupReqGrpStat == "ACT" ?
                                                        <>
                                                            <button type="button" className="text-xs sm:text-sm mx-1 py-1 px-3 font-medium rounded-full text-red-600 bg-white border-2 border-red-600" onClick={(e) => deletePickupAddr(e, pa.AddrType)}>
                                                                Hapus
                                                            </button>
                                                            <button type="button" className="text-xs sm:text-sm mx-1 py-1 px-3 border border-transparent font-medium rounded-full text-white bg-red-600" onClick={(e) => openInNewTab(`/PickupAddressForm?AddrType=${pa.AddrType}`) }>
                                                                Ubah Alamat
                                                            </button>
                                                        </> :
                                                        <button type="button" className="text-xs sm:text-sm mx-1 py-1 px-3 font-medium rounded-full text-red-600 bg-white border-2 border-red-600" onClick={(e) => reactivatePickupAddr(e, pa.AddrType)}>
                                                            Aktikan
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                            <span
                                                className={
                                                    pa.PickupReqGrpStat === "ACT"
                                                        ? "absolute bg-green-600 right-0 h-full rounded-r w-3"
                                                        : "absolute bg-red-600 right-0 h-full rounded-r w-3"
                                                }
                                            ></span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default PickupAddressTable;
