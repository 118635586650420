import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pickupActions } from '../../_actions';
import { PickupAddressListTable } from '../../components';

const PickupAddress = ({ t }) => {
    const dispatch = useDispatch()
    const acc = useSelector((state) => state.authentication.accInfo);
    const pickupAddressList = useSelector((state) => state.pickupAddrList.pickupAddrListObj);

    useEffect(() => {
        const reqObj = { custId: acc.CustId }
        dispatch(pickupActions.getPickupAddrList(reqObj));
    }, [])

    return (
        <div className={`min-h-screen flex py-12 px-8 bg-gray-200`}>
            <div className="max-w-6xl mx-auto">
                <div className="justify-center py-2 px-4 flex flex-row">
                    <h1 className="font-bold text-xl py-2">PILIH ALAMAT</h1>
                </div>
                <div className="justify-center px-3 pt-3 pb-1 bg-white rounded-md">
                    {pickupAddressList && pickupAddressList.length > 0 && <PickupAddressListTable />}
                </div>

            </div>
        </div>
    );
};
export default PickupAddress;