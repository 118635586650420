export const profileConstants = {
  PROFILE_GET_DATA_REQUEST: "PROFILE_GET_DATA_REQUEST",
  PROFILE_GET_DATA_SUCCESS: "PROFILE_GET_DATA_SUCCESS",
  PROFILE_GET_DATA_FAILURE: "PROFILE_GET_DATA_FAILURE",
  
  CUST_POINT_HIST_GET_REQUEST: "CUST_POINT_HIST_GET_REQUEST",
  CUST_POINT_HIST_GET_SUCCESS: "CUST_POINT_HIST_GET_SUCCESS",
  CUST_POINT_HIST_GET_FAILURE: "CUST_POINT_HIST_GET_FAILURE",
  
  CUST_POINT_REDEMPTION_GET_REQUEST: "CUST_POINT_REDEMPTION_GET_REQUEST",
  CUST_POINT_REDEMPTION_GET_SUCCESS: "CUST_POINT_REDEMPTION_GET_SUCCESS",
  CUST_POINT_REDEMPTION_GET_FAILURE: "CUST_POINT_REDEMPTION_GET_FAILURE",

  GET_QR_CODE_REQUEST: "GET_QR_CODE_REQUEST",
  GET_QR_CODE_SUCCESS: "GET_QR_CODE_SUCCESS",
  GET_QR_CODE_FAILURE: "GET_QR_CODE_FAILURE",
  GET_QR_CODE_CLEAR: "GET_QR_CODE_CLEAR",

  SEND_QR_CODE_REQUEST: "SEND_QR_CODE_REQUEST",
  SEND_QR_CODE_SUCCESS: "SEND_QR_CODE_SUCCESS",
  SEND_QR_CODE_FAILURE: "SEND_QR_CODE_FAILURE"
};
