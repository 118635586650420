import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from '../../_actions';
import "../../custom.css";

const ImageSlideshow = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [images, setImages] = useState([]);
    const dispatch = useDispatch()
    const bannerObj = useSelector(state => state.banner.banner);
    //var listOfImages = [];
    //let images = [];

    const goToImage = (index) => {
        setCurrentImage(index);
    };

    useEffect(() => {
        // Clear the interval when the component unmounts to prevent memory leaks
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [images.length]);

    useEffect(() => {
        dispatch(dashboardActions.getBanner());
    }, []);

    useEffect(() => {
        console.log("BANNERS", bannerObj)
        if (bannerObj != undefined && bannerObj.length > 0) {
            setImages(bannerObj)
        }
    }, [bannerObj]);


    return (
        <div className="image-slideshow">
            <img src={images[currentImage]} alt="Slideshow" />
            <div className="dot-pagination">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className={currentImage === index ? 'dot active' : 'dot'}
                        onClick={() => goToImage(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default ImageSlideshow;
