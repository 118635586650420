import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, AlertMessage, PickupAddressTable } from '../../components';
import { pickupActions } from '../../_actions';

const PickupAddressPaging = ({ t }) => {
    const dispatch = useDispatch();
    const acc = useSelector((state) => state.authentication.accInfo);
    const pickupAddressTypeObj = useSelector(state => state.pickupAddr.pickupAddrObj);
    const errPickupAddrObj = useSelector(state => state.pickupAddr.errPickupAddrObj);
    const errPickupReqAddEditGrpObj = useSelector(state => state.pickupReqGrpAddEdit.errPickupReqGrpObj);
    const [cur, setCur] = useState(1);
    const [num, setNum] = useState(1);
    const [submitted, setSubmitted] = useState(false);
    const [isBtnClick, setIsBtnClick] = useState(false);
    const [isError, setIsError] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [loadMore, setLoadmore] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [sortConfig, setSortConfig] = useState({ key: 'AddrType', direction: 'ASC' });
    const [alert, setAlert] = useState({
        Type: '',
        StatusMessage: ''
    });
    const [pickupAddrPaging, setPickupAddrPaging] = useState({
        ContactPerson: '',
        PhoneNo: '',
        PickupReqGrpStat: 'ACT'
    });


    function handleChange(e) {
        const { name, value } = e.target;
        setPickupAddrPaging((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    function getPickupAddrType(pageNo, rowPerPages) {
        setSubmitted(true);
        if (acc && acc.CustId) {
            const pickupAddrTypeObj = {
                CustId: acc.CustId,
                PickupReqGrpStat: pickupAddrPaging.PickupReqGrpStat,
                ContactPerson: pickupAddrPaging.ContactPerson,
                PhoneNo: pickupAddrPaging.PhoneNo,
                RowPerPage: rowPerPages,
                PageNo: pageNo,
                OrderBy: sortConfig,
            };
            dispatch(pickupActions.getPickupAddr(pickupAddrTypeObj));
        }
    }

    function handleSearch(e) {
        e.preventDefault();
        search();
    }

    function search() {
        setSubmitted(true);
        setCur(1);
        setNum(1);
        setTotalRecord(0);
        setLoadmore(1);
        dispatch(pickupActions.clearPickupAddr());
        getPickupAddrType(1, rowPerPage);
    }

    useEffect(() => {
        setSubmitted(false);
        setCur(1);
        setNum(1);
        setTotalRecord(0);
        setLoadmore(1);
        dispatch(pickupActions.clearPickupAddr());
        getPickupAddrType(1, rowPerPage);
    }, []);

    useEffect(() => {
        if (totalRecord > 0 && acc.CustId) {
            console.log('Masuk Load More')
            getPickupAddrType(1, loadMore * rowPerPage);
        }
    }, [loadMore]);

    useEffect(() => {
        if (isBtnClick == true) {
            dispatch(pickupActions.clearAddEditPickupReqGrp());
            search();
            setIsBtnClick(false);
        }
    }, [isBtnClick]);


    useEffect(() => {
        if (pickupAddressTypeObj && pickupAddressTypeObj.TotalRecord) {
            setTotalRecord(pickupAddressTypeObj.TotalRecord);
        }
        else {
            setTotalRecord(0);
        }
    }, [pickupAddressTypeObj]);

    useEffect(() => {
        if (totalRecord > 0 && acc.CustId) {
            console.log('Masuk Cur')
            getPickupAddrType(cur, rowPerPage);
        }
    }, [cur]);


    useEffect(() => {
        console.log('Masuk Sort')
        if (sortConfig != null && sortConfig != undefined) {
            if (totalRecord > 0 && acc && acc.CustId) {
                getPickupAddrType(cur, rowPerPage);
            }
        }
    }, [sortConfig])

    useEffect(() => {
        if (errPickupAddrObj && errPickupAddrObj.StatusCode !== "200") {
            setIsError(true);
            setAlert({ Type: "Error", StatusMessage: errPickupAddrObj.StatusMessage })
        }

    }, [errPickupAddrObj])

    useEffect(() => {
        if (errPickupReqAddEditGrpObj && errPickupReqAddEditGrpObj.StatusCode !== "200") {
            setIsError(true);
            setAlert({ Type: "Error", StatusMessage: errPickupReqAddEditGrpObj.StatusMessage })
        }

    }, [errPickupReqAddEditGrpObj])

    return (
        <>
            <div className="hidden sm:block max-w-6xl mx-auto">
                <div className="justify-center py-2 px-4 flex flex-row">
                    <h1 className="font-bold text-xl">Pickup Address</h1>
                </div>
                <form onSubmit={handleSearch} method="POST">
                    <div className="h-full w-full bg-white text-xs sm:text-sm font-medium">
                        <div className="row px-2 py-1">
                            <div className="col-md-3 text-xs sm:text-sm">
                                Contact Person
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                <input id="ContactPerson" type="text" name="ContactPerson" className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300" placeholder="Isi Contact Person" value={pickupAddrPaging.ContactPerson} onChange={handleChange} />
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                Nomor Ponsel
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                <input id="PhoneNo" type="text" name="PhoneNo" className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300" placeholder="Isi Nomor Ponsel" value={pickupAddrPaging.PhoneNo} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="row px-2 py-1">
                            <div className="col-md-3 text-xs sm:text-sm">
                                Status
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                <select className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300" id="PickupReqGrpStat" name="PickupReqGrpStat" value={pickupAddrPaging.PickupReqGrpStat} onChange={handleChange} >
                                    <option value="">Semua</option>
                                    <option value="ACT" >Aktif</option>
                                    <option value="INACT">Tidak Aktif</option>
                                </select>
                            </div>
                        </div>

                        <div className="flex justify-end mb-2 mx-2">
                            <button type="submit" className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out">
                                Search
                            </button>
                        </div>
                    </div>
                </form>
                {
                    isError == true &&
                    <AlertMessage isError={isError} alert={alert} setAlert={setAlert} setIsError={setIsError} />
                }
                {pickupAddressTypeObj && pickupAddressTypeObj.Result && (
                    <>
                        <PickupAddressTable sortConfig={sortConfig} setSortConfig={setSortConfig} setIsBtnClick={setIsBtnClick} />
                        <Pagination cur={cur} setCur={setCur} loadMore={loadMore} setLoadmore={setLoadmore} rowPerPage={rowPerPage} totalRecord={totalRecord} num={num} setNum={setNum} />
                    </>
                )}
            </div>
            <div className="block sm:hidden w-full">
                <div className="bg-blue-600">
                    <h4 className="flex justify-center text-xl font-semibold text-white py-2">
                        Pickup Address
                    </h4>
                </div>

                <form onSubmit={handleSearch} method="POST">
                    <div className="flex flex-col text-xs sm:text-sm px-3">
                        <div className="flex flex-row font-bold pt-2">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                Contact Person
                            </div>
                            <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                Nomor Ponsel
                            </div>
                        </div>
                        <div className="flex flex-row mt-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                <input id="ContactPerson" type="text" name="ContactPerson" className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100" placeholder="Isi Contact Person" value={pickupAddrPaging.ContactPerson} onChange={handleChange} />
                            </div>
                            <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                <input id="PhoneNo" type="text" name="PhoneNo" className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100" placeholder="Isi Nomor Ponsel" value={pickupAddrPaging.PhoneNo} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="flex flex-row font-bold mt-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                Status
                            </div>
                        </div>
                        <div className="flex flex-row mt-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                <select className="py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none rounded-sm w-100" id="PickupReqGrpStat" name="PickupReqGrpStat" value={pickupAddrPaging.PickupReqGrpStat} onChange={handleChange}  >
                                    <option value="">Semua</option>
                                    <option value="ACT" >Aktif</option>
                                    <option value="INACT">Tidak Aktif</option>
                                </select>
                            </div>
                            <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center">
                        <button type="submit" className="mx-3 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600">
                            Search
                        </button>
                    </div>
                </form>
                {
                    isError == true &&
                    <AlertMessage isError={isError} alert={alert} setAlert={setAlert} setIsError={setIsError} />
                }
                {pickupAddressTypeObj && pickupAddressTypeObj.Result && (
                    <>
                        <PickupAddressTable sortConfig={sortConfig} setSortConfig={setSortConfig} setCur={setCur} setLoadmore={setLoadmore} rowPerPages={rowPerPage} setIsBtnClick={setIsBtnClick} />
                        <Pagination cur={cur} setCur={setCur} loadMore={loadMore} setLoadmore={setLoadmore} rowPerPage={rowPerPage} totalRecord={totalRecord} num={num} setNum={setNum} />
                    </>
                )}
            </div>

        </>
    );
}
export default PickupAddressPaging;