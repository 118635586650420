import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { FaRegCopy } from "react-icons/fa";
import moment from "moment";

const BookingDetailTables = ({ }) => {
    const bookingDetailObj = useSelector(state => state.bookingDetails.bookingDetailObj);

    function openInNewTab(e, url) {
        e.preventDefault();
        e.stopPropagation();
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    }

    function copyToClipboard(e, bookingNo) {
        e.stopPropagation();
        navigator.clipboard.writeText(bookingNo);
    }

    return (
        <div className="text-xs sm:text-sm">
            {bookingDetailObj &&
                bookingDetailObj.Result &&
                bookingDetailObj.Result.length == 0 && (
                    <div className="justify-center flex flex-row">
                        <p>Tidak ada booking</p>
                    </div>
                )}
            {bookingDetailObj && bookingDetailObj.Result && bookingDetailObj.Result.length > 0 &&
                <>
                    <div className="hidden sm:block bg-white">
                        <table className='table table-fixed table-striped text-xs sm:text-sm ' aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th hidden className="text-center">Booking No</th>
                                    <th className="text-left">Info Resi</th>
                                    <th className="text-left">Info Penerima</th>
                                    <th className="text-left">Info Booking</th>
                                    <th className="text-left">Tracking Terakhir</th>
                                    <th className="text-center"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookingDetailObj.Result.map(booking =>
                                    <tr key={booking.CustBookHId}>
                                        <td hidden align='center'>{booking.BookNo}</td>
                                        <td align='left'>
                                            <Link target={"_blank"} to={{ pathname: `/ResiHView?resiHNo=${booking.ResiNo}` }} style={{ color: '#337ab7' }}>
                                                {booking.ResiNo}
                                            </Link>
                                            <br /><b>Pengirim: </b>{booking.CustName}
                                            <br /><b>Alias Pengirim: </b>{booking.AliasName}
                                            <br /><b>Tujuan: </b>{booking.Tujuan}
                                            <br /><b>Jalur: </b>{booking.TransportType}
                                            <br /><b>Jenis Pembayaran: </b>{booking.PaymentType}
                                            <br /><b>Drop Type: </b>{booking.DropType}
                                            <br /><b>Est. Pengiriman: </b>{booking.EstSentDt == null ? "-" : moment(booking.EstSentDt).format("DD-MM-YY HH:mm")}
                                        </td>
                                        <td>
                                            {booking.RecipientName}
                                            <br />{booking.RecipientMblPhoneNo}
                                            <br />{booking.RecipientAddr}
                                        </td>
                                        <td align='left'>
                                            <b>Tanggal Booking: </b> {moment(booking.BookDt).format("DD-MM-YY HH:mm")}
                                            <br /><b>No Referensi: </b> {booking.CustRefNo}
                                            <br /><b>Koli: </b> {booking.TotalItem.toLocaleString("en", { minimumFractionDigits: 0 })}
                                            <br /><b>Est. Berat: </b> {booking.EstTotalWeight.toLocaleString("en", { minimumFractionDigits: 2 })}
                                            {false && (<><br /><b>Est. Tagihan: </b> {booking.EstMustBePaidAmt.toLocaleString("en", { minimumFractionDigits: 0 })}</>)}
                                            <br /><b>Status: </b>{booking.BookStatDescr}
                                        </td>
                                        <td>{moment(booking.InputDt).format("DD-MM-YY HH:mm")} | {booking.TrackNotes}</td>
                                        <td>
                                            <Link target={"_blank"} to={{ pathname: `/booking-viewH?id=${booking.CustBookHId}` }} style={{ color: '#337ab7' }} >
                                                Detail
                                            </Link>
                                            <label className="mx-2">|</label>
                                            <Link target={"_blank"} to={{ pathname: `/booking-print?trxNo=${booking.ResiNo}` }} style={{ color: '#337ab7' }}>
                                                Print label barang
                                            </Link>
                                            {
                                                (booking.BookStat === "ACT") &&
                                                <label className="mx-2">|</label>
                                            }
                                            {
                                                (booking.BookStat === "ACT") &&
                                                <Link target={"_blank"} to={{ pathname: `/booking-details-editv2?trxNo=${booking.CustBookHId}` }} style={{ color: '#337ab7' }}>
                                                    Edit
                                                </Link>
                                            }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="block sm:hidden w-full">
                        <div className="rounded px-3">
                            {bookingDetailObj.Result.map((booking) => (
                                <div
                                    className="h-full w-full bg-white shadow flex rounded items-center my-2"
                                    key={booking.CustBookHId}
                                    onClick={(e) =>
                                        openInNewTab(e, `/booking-viewH?id=${booking.CustBookHId}`)
                                    }
                                >
                                    <div className="relative h-full w-full flex items-center">
                                        <div className="text-sm font-medium w-full pb-1">
                                            <div className="pt-2 flex px-3">
                                                <div className="flex flex-1">
                                                    <p className="flex items-center font-bold pr-1">{booking.ResiNo}</p>
                                                    <div className="flex items-center">
                                                        <FaRegCopy
                                                            onClick={(e) => copyToClipboard(e, booking.ResiNo)}
                                                            style={{ display: "inline-block" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex flex-1 justify-end mr-1">
                                                    <div className="flex items-center">
                                                        <div className={`w-2.5 h-2.5 rounded-full ${booking.BookStat === "ACT" ||
                                                            booking.BookStat === "PCK" ? 'bg-green-600' : 'bg-red-600'}`}></div>
                                                    </div>
                                                    <p className={`flex items-center font-semibold text-xs sm:text-sm ml-1 ${booking.BookStat === "ACT" ||
                                                        booking.BookStat === "PCK" ? 'text-green-600' : 'text-red-600'}`}>
                                                        {booking.BookStatDescr}</p>
                                                </div>
                                            </div>
                                            <div className="flex text-red-500 px-3">
                                                <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                    {booking.RecipientName}
                                                </div>
                                            </div>
                                            <div className="flex text-red-500 px-3">
                                                <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                    {booking.RecipientMblPhoneNo}
                                                </div>
                                            </div>
                                            <div className="flex text-red-500 px-3">
                                                <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                    {booking.RecipientAddr}
                                                </div>
                                            </div>
                                            <div className="flex text-red-500 px-3">
                                                <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                    {booking.Tujuan} (Jalur: {booking.TransportType})
                                                </div>
                                            </div>
                                            <div className="flex px-3">
                                                <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                    No. Referensi : {booking.CustRefNo}
                                                </div>
                                            </div>
                                            <div className="flex px-3">
                                                <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                    Est. Tagihan :  {booking.EstMustBePaidAmt.toLocaleString("en", { minimumFractionDigits: 0 })}
                                                </div>
                                            </div>
                                            <div className="flex px-3">
                                                <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                    {booking.TotalItem.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}{" "}
                                                    Koli,{" "}
                                                    {booking.EstTotalWeight.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 2 }
                                                    )}{" "}
                                                    Kg, {booking.PaymentType}
                                                </div>
                                                <div className="flex-1 text-right text-xs sm:text-sm mr-2">
                                                    Estimasi : {booking.EstSentDt == null ? "-" : moment(booking.EstSentDt).format("DD-MM-YY")}
                                                </div>
                                            </div>
                                            <div className="flex mx-3 my-1 border-t border-gray-400">
                                                <div className="flex-1 text-xs sm:text-sm text-blue-600">
                                                    {moment(booking.InputDt).format("DD-MM-YY HH:mm")} | {booking.TrackNotes}
                                                </div>
                                            </div>
                                            {
                                                (booking.BookStat === "ACT" || booking.BookStat === "PCK") &&
                                                <div className="px-3 mt-1 mb-2 flex justify-around">
                                                    <button type="button" className="text-xs sm:text-sm w-36 mx-1 py-1 px-3 font-medium rounded-full text-yellow-600 bg-white border-2 border-yellow-600" onClick={(e) => openInNewTab(e, `/booking-details-editv2?trxNo=${booking.CustBookHId}`)}>
                                                        Edit
                                                    </button>
                                                    <button type="button" className="text-xs sm:text-sm w-36 mx-1 py-1 px-3 font-medium rounded-full text-green-600 bg-white border-2 border-green-600" onClick={(e) => openInNewTab(e, `/booking-download?trxNo=${booking.ResiNo}`)}>
                                                        Download Label Barang
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                        <span
                                            className={
                                                booking.BookStat === "ACT" || booking.BookStat === "PCK"
                                                    ? "absolute bg-green-600 right-0 h-full rounded-r w-3"
                                                    : "absolute bg-red-600 right-0 h-full rounded-r w-3"
                                            }
                                        ></span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            }
        </div>

    );
}
export default BookingDetailTables;
