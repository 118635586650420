import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ComplaintMessagesTable, Pagination } from "../../components";
import { resiActions } from "../../_actions";

const ResiComplaintForm = (openFrom) => {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const dispatch = useDispatch();
  const [notes, setNotes] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const acc = useSelector((state) => state.authentication.accInfo);
  const resiHInfo = useSelector((state) => state.resiHInfo.resiHInfo);
  const addResiComplaint = useSelector(
    (state) => state.addResiComplaint.addResiComplaint
  );
  const complaintMessages = useSelector(
    (state) => state.getComplaintMessages.getComplaintMessagesObj
  );

  let [cur, setCur] = useState(1);
  let [num, setNum] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [loadMore, setLoadmore] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const _today = new Date();

  function dateParser(value) {
    const date = new Date(value);
    const dateParsed =
      date.getFullYear() +
      "-" +
      date.toLocaleString("en-us", { month: "2-digit" }) +
      "-" +
      date.toLocaleString("en-us", { day: "2-digit" });
    return dateParsed;
  }

  useEffect(() => {
    dispatch(resiActions.getResiHInfo(query.get("resiHId")));
  }, []);

  useEffect(() => {
    if (resiHInfo && resiHInfo.ResiNo) {
      hitGetComplainMessage();
    }
  }, [resiHInfo]);

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    if (notes) {
      let addResiComplaintObj = {
        Notes: notes,
        ResiNo: resiHInfo.ResiNo,
        CommLinkSrc:
          query.get("commLinkSrc") == null || openFrom == "ResiHView"
            ? "CUST"
            : query.get("commLinkSrc"),
        Username: acc.Username,
        CustId: acc.CustId,
        TransportType: resiHInfo.TransportType,
        DestId: resiHInfo.DestId,
        PerwakilanId: resiHInfo.PerwakilanId,
      };
      dispatch(resiActions.addResiComplaint(addResiComplaintObj));

      setNotes("");
      setSubmitted(false);
    }
  }

  useEffect(() => {
    if (addResiComplaint && addResiComplaint.Added == "1") {
      hitGetComplainMessage();
    }
  }, [addResiComplaint]);

  function hitGetComplainMessage() {
    setTotalRecord(0);
    setLoadmore(1);
    setCur(1);
    setNum(1);
    var requestObj = {
      CustId: null,
      ResiNo: resiHInfo.ResiNo,
      CommLinkSrc:
        query.get("commLinkSrc") == null || openFrom == "ResiHView"
          ? "CUST"
          : query.get("commLinkSrc"),
      RowPerPage: rowPerPage,
      PageNo: 1,
    };
    dispatch(resiActions.getComplaintMessages(requestObj));
  }

  useEffect(() => {
    if (totalRecord > 0) {
      var requestObj = {
        CustId: acc.CustId,
        ResiNo: null,
        CommLinkSrc:
          query.get("commLinkSrc") == null || openFrom == "ResiHView"
            ? "CUST"
            : query.get("commLinkSrc"),
        RowPerPage: rowPerPage,
        PageNo: cur,
      };
      dispatch(resiActions.getComplaintMessages(requestObj));
    }
  }, [cur]);

  useEffect(() => {
    if (totalRecord > 0) {
      var requestObj = {
        CustId: acc.CustId,
        ResiNo: null,
        CommLinkSrc:
          query.get("commLinkSrc") == null || openFrom == "ResiHView"
            ? "CUST"
            : query.get("commLinkSrc"),
        RowPerPage: loadMore * rowPerPage,
        PageNo: 1,
      };
      dispatch(resiActions.getComplaintMessages(requestObj));
    }
  }, [loadMore]);

  useEffect(() => {
    if (complaintMessages && complaintMessages.TotalRecord) {
      setTotalRecord(complaintMessages.TotalRecord);
    } else {
      setTotalRecord(0);
    }
  }, [complaintMessages]);

  return (
    <div>
      <div className="hidden sm:block">
        <div className="justify-center flex flex-row">
          <h1 className="font-bold text-lg">FORM KOMPLAIN</h1>
        </div>
        <div className="justify-center pt-3 text-xs sm:text-sm">
          {resiHInfo && (
            <div>
              <div className="row">
                <label className="col-md-3 font-bold">No Resi</label>
                <label className="col-md-3">{resiHInfo.ResiNo}</label>
                <label className="col-md-3 font-bold">Kantor Asal</label>
                <label className="col-md-3">{resiHInfo.OfficeName}</label>
              </div>
              <div className="row">
                <label className="col-md-3 font-bold">Pengirim</label>
                <label className="col-md-3">{resiHInfo.CustName}</label>
                <label className="col-md-3 font-bold">Penerima</label>
                <label className="col-md-3">{resiHInfo.RecipientName}</label>
              </div>
              <div className="row">
                <label className="col-md-3 font-bold">Tujuan</label>
                <label className="col-md-3">{resiHInfo.DestName}</label>
                <label className="col-md-3 font-bold">Jenis Pembayaran</label>
                <label className="col-md-3">{resiHInfo.PaymentType}</label>
              </div>
              <div className="row">
                <label className="col-md-3 font-bold">Status Terakhir</label>
                <label className="col-md-9 font-bold whitespace-normal break-words">
                  {resiHInfo.PODLastResult ? resiHInfo.PODLastResult : "-"}
                </label>
              </div>
            </div>
          )}
        </div>
        <form
          onSubmit={handleSubmit}
          className="justify-center mx-auto text-xs sm:text-sm"
        >
          <div className="row">
            <label className="col-md-3">Catatan</label>
            <label className="col-md-9">
              Harap mencantumkan <b>no handphone </b>
              anda pada catatan untuk kemudahan berkomunikasi
            </label>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-9">
              <textarea
                id="notes"
                name="notes"
                type="text"
                value={notes}
                rows="5"
                onChange={(e) => setNotes(e.target.value)}
                className="col-md-9"
                style={{ boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)" }}
              />
              {submitted && !notes && (
                <div className="block text-sm font-small leading-5 text-red-500">
                  Tidak boleh kosong
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-9">
              <button
                type="submit"
                className="group relative flex justify-center items-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
              >
                Simpan
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="block sm:hidden px-2 mx-2 my-2">
        <form
          onSubmit={handleSubmit}
          className="justify-center mx-auto text-xs sm:text-sm"
        >
          <label>
            Harap mencantumkan <b>no handphone </b>
            anda pada catatan untuk kemudahan berkomunikasi
          </label>
          <textarea
            id="notes"
            name="notes"
            type="text"
            value={notes}
            rows="5"
            onChange={(e) => setNotes(e.target.value)}
            className="col-md-9"
            style={{ boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)" }}
          />
          {submitted && !notes && (
            <div className="block text-sm font-small leading-5 text-red-500">
              Tidak boleh kosong
            </div>
          )}
          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="mx-3 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
            >
              Simpan
            </button>
          </div>
        </form>
      </div>
      <div className="justify-center flex flex-col px-2 mx-2 mt-4">
        <div className="flex items-center">
          <h1>Catatan Pesan/Komplain</h1>
        </div>
      </div>
      <ComplaintMessagesTable isDashboard={false} />
      <Pagination
        cur={cur}
        setCur={setCur}
        loadMore={loadMore}
        setLoadmore={setLoadmore}
        rowPerPage={rowPerPage}
        totalRecord={totalRecord}
        num={num}
        setNum={setNum}
      />
    </div>
  );
};
export default ResiComplaintForm;
