import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import axios from "axios";
import { urlConstants } from "../_constants";

const UploadMonitoringTable = ({ t }) => {
  const [exported, setExported] = useState(false);
  const [exportDataObj, setExportDataObj] = useState();
  const client = axios.create({
    baseURL: urlConstants.URL_EXPORT_UPLOAD_DATA,
  });
  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState({
    Type: "",
    StatusMessage: "",
  });
  const uploadMonitoring = useSelector(
    (state) => state.uploadMonitoring.uploadMonitoring
  );

  function openInNewTab(e, url) {
      e.preventDefault();
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
  }

  function exportData(uploadMonitoringId) {
    setIsError(false);
    var requestObj = {
      UploadMonitoringId: uploadMonitoringId,
      RowPerPage: 99999,
      PageNo: 1,
    };
    exportUploadMonitoring(requestObj);
    setExported(true);
  }

  const exportUploadMonitoring = async (requestObj) => {
    try {
      let result = await client.post("", requestObj);
      if (result.data.IsError) {
        console.log("Export Error");
        setIsError(true);
        setAlert({
          Type: "Error",
          StatusMessage: result.data.StatusMessage,
        });
      } else {
        setExportDataObj({
          exportUploadData: result.data.ObjectResult,
        });
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
      setAlert({
        Type: "Error",
        StatusMessage:
          "An error occure, please contact our admin for further information",
      });
    }
  };

  useEffect(() => {
    if (exportDataObj && exportDataObj.exportUploadData && exported) {
      var a = document.createElement("a");
      a.href =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        exportDataObj.exportUploadData.ExportDataFile;
      a.download = exportDataObj.exportUploadData.Filename;
      a.click();

      setExported(false);
    }
  }, [exportDataObj]);

  return (
    <div className="text-xs sm:text-sm">
      {uploadMonitoring &&
        uploadMonitoring.Result &&
        uploadMonitoring.Result.length == 0 && (
          <div className="justify-center flex flex-row">
            <p>Tidak ada upload</p>
          </div>
        )}
      {uploadMonitoring &&
        uploadMonitoring.Result &&
        uploadMonitoring.Result.length > 0 && (
          <>
            <div className="hidden sm:block max-w-6xl mx-auto">
              <table
                className="table table-auto table-striped"
                aria-labelledby="tabelLabel"
              >
                <thead>
                  <tr>
                    <th className="text-center">Tanggal Upload</th>
                    <th>Upload Type</th>
                    <th>Nama File</th>
                    <th className="text-right">Total Record</th>
                    <th className="text-right">Total Error</th>
                    <th>Upload Status</th>
                    <th>Detail</th>
                  </tr>
                </thead>
                <tbody>
                  {uploadMonitoring.Result.map((um) => (
                    <tr key={um.UploadDt}>
                      <td align="center">
                        {moment(um.UploadDt).format("DD-MM-YY HH:mm")}
                      </td>
                      <td>{um.UploadType}</td>
                      <td>{um.FileName}</td>
                      <td align="right">
                        {um.TotalRecord}
                      </td>
                      <td align="right">
                        {um.TotalError}
                      </td>
                      <td>{um.UploadStatus}</td>
                      <td className="text-sky-600">
                        <Link
                          target={"_blank"}
                          to={{
                            pathname: `/UploadDetail?uploadMonitoringId=${um.UploadMonitoringId}`,
                          }}
                        >
                          Detail
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="block sm:hidden w-full">
              <div className="rounded text-xs px-3">
                {uploadMonitoring.Result.map((um) => (
                  <div
                    className="h-full w-full bg-white shadow flex rounded items-center my-2"
                    key={um.UploadDt}
                    onClick={(e) => exportData(um.UploadMonitoringId)}
                  >
                    <div className="relative h-full w-full flex items-center">
                      <div className="text-xs w-full pb-1">
                        <div className="pt-2 flex px-3">
                          <div className="font-bold">
                            {um.FileName}
                          </div>
                          <div className="flex flex-1 justify-end mr-1">
                            <div className="flex items-center">
                              <div
                                className={`w-2.5 h-2.5 rounded-full ${um.UploadStatus === "EXEC"
                                  ? "bg-green-600"
                                  : um.UploadStatus === "ERR"
                                      ? "bg-red-600"
                                      : "bg-blue-600"
                                  }`}
                              ></div>
                            </div>
                            {um.UploadStatus === "EXEC" ? (
                              <p className="flex items-center font-semibold ml-1 text-green-600">
                                Executed
                              </p>
                            ) : um.UploadStatus === "ERR" ? (
                              <p className="flex items-center font-semibold ml-1 text-red-600">
                                Error
                              </p>
                            ) : (
                              <p className="flex items-center font-semibold ml-1 text-blue-600">
                                Request
                              </p>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="flex px-3">
                          Upload Date
                          <div className="pl-10">: {moment(um.UploadDt).format("DD-MM-YY HH:mm")}</div>
                        </div>
                        <div className="flex px-3">
                          Upload Type
                          <div className="pl-10">: {um.UploadType}</div>
                        </div>
                        <div className="flex px-3">
                          Total Record / Error
                          <div className="pl-1">: {um.TotalRecord} / {um.TotalError}</div>
                        </div>
                      </div>
                      <span
                        className={`absolute right-0 h-full rounded-r w-3 ${um.UploadStatus === "EXEC"
                          ? "bg-green-600"
                          : um.UploadStatus === "ERR"
                              ? "bg-red-600"
                              : "bg-blue-600"
                          }`}
                      ></span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default UploadMonitoringTable;
