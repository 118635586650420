import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { pickupActions } from "../../_actions";
import PickupOrderHInfo from "../../components/PickupOrderHInfo";

const PickupOrderCancelForm = ({ t }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const acc = useSelector((state) => state.authentication.accInfo);

  const [submitted, setSubmitted] = useState(false);
  const [cancelNotes, setCancelNotes] = useState("");

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  function handleBatal() {
    if (
      window.confirm(
        "Apakah proses hendak dibatalkan? Data yang belum disimpan akan hilang"
      )
    ) {
      history.push("/PickupMonitoring");
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    if (cancelNotes) {
      let cancelPickupOrderObj = {
        EmployeeId: 0, //acc.EmployeeId
        Username: "", //acc.Username
        PickupOrderHId: query.get("pickupOrderHId"),
        CancelNotes: cancelNotes,
      };

      dispatch(pickupActions.cancelPickupOrder(cancelPickupOrderObj));
    }
  }

  return (
    <>
      <h1 className="text-lg pt-4 justify-center flex flex-row">
        CANCEL PICKUP ORDER
      </h1>
      <div className="justify-center pt-3 text-xs sm:text-sm">
        <PickupOrderHInfo />
        <form
          onSubmit={handleSubmit}
          className="justify-center mx-auto text-xs sm:text-sm"
        >
          <div className="row">
            <div className="col-md-3 font-bold">Alasan</div>
            <div className="col-md-9">
              <input
                id="cancelNotes"
                name="cancelNotes"
                type="text"
                value={cancelNotes}
                rows="5"
                onChange={(e) => setCancelNotes(e.target.value)}
                className="col-md-9"
                style={{ boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)" }}
              />
              {submitted && !cancelNotes && (
                <div className="block text-sm font-small leading-5 text-red-500">
                  Tidak boleh kosong
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-9 flex flex-row">
              <button
                type="submit"
                className="relative flex justify-center items-center mx-1 py-2 px-4 border border-transparent font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
              >
                Simpan
              </button>
              <button
                type="button"
                onClick={handleBatal}
                className="relative flex justify-center items-center mx-1 py-2 px-4 border border-transparent font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition duration-150 ease-in-out"
              >
                Batal
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default PickupOrderCancelForm;
