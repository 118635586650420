import { httpHelpers } from '../_helpers';
import { urlConstants } from '../_constants';

export const transportTypeService = {
    getTransportType
};

function getTransportType(transportTypeObj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(transportTypeObj)
    };

    return fetch(urlConstants.URL_GET_TRANSPORT_TYPE, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    transportTypeObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('transportTypeObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode != "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}
