import { penerimaanConstants } from "../_constants";

let penerimaanMonitoringObj = JSON.parse(
  localStorage.getItem("penerimaanMonitoring")
);
const initialStateMonitoring = penerimaanMonitoringObj
  ? {
      loggedIn: true,
      penerimaanMonitoring: penerimaanMonitoringObj.penerimaanMonitoring,
      isLoading: true,
    }
  : {};

export function penerimaanMonitoring(state = initialStateMonitoring, action) {
  switch (action.type) {
    case penerimaanConstants.PENERIMAAN_MONITORING_REQUEST:
      return {
        penerimaanMonitoring: action.penerimaanMonitoring.penerimaanMonitoring,
        isLoading: true,
      };
    case penerimaanConstants.PENERIMAAN_MONITORING_SUCCESS:
      return {
        penerimaanMonitoring: action.penerimaanMonitoring.penerimaanMonitoring,
        isLoading: false,
      };
    case penerimaanConstants.PENERIMAAN_MONITORING_FAILURE:
      return { errPenerimaanMonitoring: action.error, isLoading: false };
    default:
      return state;
  }
}
