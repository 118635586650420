import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { pickupActions } from "../../_actions";
import { PickupOrderHInfo, PickupOrderDetailTable } from "../../components";
import "../../styles/tailwind.css";

const PickupOrderDetail = ({ t }) => {
  const dispatch = useDispatch();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    dispatch(pickupActions.pickupOrderDetail(query.get("pickupOrderHId")));
  }, []);

  return (
    <div>
      <div className="justify-center flex">
        <h1 className="text-xl justify-center flex flex-row">
          LIST PICKUP ORDER
        </h1>
      </div>
      <div className="justify-center pt-3 text-xs sm:text-sm">
        <PickupOrderHInfo />
        <div className="pt-4">
          <PickupOrderDetailTable />
        </div>
      </div>
    </div>
  );
};
export default PickupOrderDetail;
