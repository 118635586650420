import { pengirimanConstants } from "../_constants";

let pengirimanMonitoringObj = JSON.parse(
  localStorage.getItem("pengirimanMonitoring")
);
const initialStateMonitoring = pengirimanMonitoringObj
  ? {
      loggedIn: true,
      pengirimanMonitoring: pengirimanMonitoringObj.pengirimanMonitoring,
    }
  : {};

export function pengirimanMonitoring(state = initialStateMonitoring, action) {
  switch (action.type) {
    case pengirimanConstants.PENGIRIMAN_MONITORING_REQUEST:
      return {
        pengirimanMonitoring: action.pengirimanMonitoring.pengirimanMonitoring,
      };
    case pengirimanConstants.PENGIRIMAN_MONITORING_SUCCESS:
      return {
        pengirimanMonitoring: action.pengirimanMonitoring.pengirimanMonitoring,
      };
    case pengirimanConstants.PENGIRIMAN_MONITORING_FAILURE:
      return { errPengirimanMonitoring: action.error };
    default:
      return state;
  }
}

let pengirimanDetailObj = JSON.parse(localStorage.getItem("pengirimanDetail"));
const initialStateDetail = pengirimanDetailObj
  ? { loggedIn: true, pengirimanDetail: pengirimanDetailObj.pengirimanDetail }
  : {};
export function pengirimanDetail(state = initialStateDetail, action) {
  switch (action.type) {
    case pengirimanConstants.PENGIRIMAN_DETAIL_REQUEST:
      return {
        pengirimanDetail: action.pengirimanDetail.pengirimanDetail,
      };
    case pengirimanConstants.PENGIRIMAN_DETAIL_SUCCESS:
      return {
        pengirimanDetail: action.pengirimanDetail.pengirimanDetail,
      };
    case pengirimanConstants.PENGIRIMAN_DETAIL_FAILURE:
      return {};
    default:
      return state;
  }
}

let exportPengirimanDataObj = JSON.parse(
  localStorage.getItem("exportPengirimanData")
);
const initialStateExport = exportPengirimanDataObj
  ? {
      loggedIn: true,
      exportPengirimanData: exportPengirimanDataObj.exportPengirimanData,
    }
  : {};
export function exportPengirimanData(state = initialStateExport, action) {
  switch (action.type) {
    case pengirimanConstants.PENGIRIMAN_DATA_EXPORT_REQUEST:
      return {
        exportPengirimanData: action.exportPengirimanData.exportPengirimanData,
      };
    case pengirimanConstants.PENGIRIMAN_DATA_EXPORT_SUCCESS:
      return {
        exportPengirimanData: action.exportPengirimanData.exportPengirimanData,
      };
    case pengirimanConstants.PENGIRIMAN_DATA_EXPORT_FAILURE:
      return { errExportPengirimanData: action.error };
    default:
      return state;
  }
}
