import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bookingActions } from "../../_actions";
import {
    BookingDetailTables,
    Pagination,
    AlertMessage,
    ShortcutDate,
} from "../../components";
import { useLocation } from "react-router-dom";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { masterSettingConstants } from "../../_constants";
import axios from "axios";
import { urlConstants } from "../../_constants/url.constants";

const BookingDetails = ({ t }) => {
    const dispatch = useDispatch();
    const [enabled, setEnabled] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [shortcut, setShortcut] = useState(false);
    const [exported, setExported] = useState(false);
    let date = new Date();
    const acc = useSelector((state) => state.authentication.accInfo);
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();
    const _dropType = query.get("dropType");
    const _addrType = query.get("addrType");
    const bookingDt =
        query.get("bookingDt") != null ? dateParser(query.get("bookingDt")) : null;
    const noResi = query.get("noResi");

    const [exportDataObj, setExportDataObj] = useState();
    const [totalRecord, setTotalRecord] = useState(0);
    const [loadMore, setLoadmore] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [recipientName, setRecipientName] = useState("");
    const [dropType, setDropType] = useState(_dropType != null ? _dropType : "");
    const [addrType, setAddrType] = useState(_addrType != null ? _addrType : "");
    const [paymentType, setPaymentType] = useState("");
    const [bookStat, setBookStat] = useState("");
    const [resiNo, setResiNo] = useState(noResi ? noResi : "");
    const [transportType, setTransportType] = useState("");
    const [sellerAliasName, setSellerAliasName] = useState("");
    const [custRefNo, setCustRefNo] = useState("");
    const [bookingDtGte, setBookingDtGte] = useState(
        bookingDt
            ? bookingDt
            : dateParser(new Date(date.getFullYear(), date.getMonth(), 1))
    );
    const [bookingDtLte, setBookingDtLte] = useState(
        bookingDt ? bookingDt : dateParser(date)
    );
    let [cur, setCur] = useState(1);
    let [num, setNum] = useState(1);
    const bookingDetailObj = useSelector(
        (state) => state.bookingDetails.bookingDetailObj
    );
    const errBookingObj = useSelector(
        (state) => state.bookingDetails.errBookingDetailObj
    );

    const [alert, setAlert] = useState({
        Type: "",
        StatusMessage: "",
    });

    const client = axios.create({
        baseURL: urlConstants.URL_EXPORT_BOOKING_DETAIL
    });

    const exportBookingDetail = async (bookDetailObj) => {
        try {
            let result = await client.post('', bookDetailObj);
            if (result.data.IsError) {
                console.log('Export Error')
                setIsError(true);
                setAlert({
                    Type: "Error",
                    StatusMessage: result.data.StatusMessage,
                });
            }
            else {
                setExportDataObj({
                    exportBookingDetailObj: result.data.ObjectResult
                });
            }
        }
        catch (error) {
            console.log(error);
            setIsError(true);
            setAlert({
                Type: "Error",
                StatusMessage: "An error occure, please contact our admin",
            });
        }
    };


    function hitBookingDetail(pageNo, rowPerPages) {
        if (
            bookingDtGte != "" &&
            bookingDtGte != undefined &&
            bookingDtLte != "" &&
            bookingDtLte != undefined &&
            acc != undefined
        ) {
            const bookDetailObj = {
                CustId: acc.CustId,
                BookingDtGte: bookingDtGte,
                BookingDtLte: bookingDtLte,
                ResiNo: resiNo,
                SellerAliasName: sellerAliasName,
                RecipientName: recipientName,
                CustRefNo: custRefNo,
                DropType: dropType,
                AddrType: addrType,
                PaymentType: paymentType,
                BookStat: bookStat,
                TransportType: transportType,
                RowPerPage: rowPerPages,
                PageNo: pageNo,
            };
            dispatch(bookingActions.requestBookingDetail(bookDetailObj));
        }
    }

    useEffect(() => {
        setLoadmore(1);
        setTotalRecord(0);
        setCur(1);
        setNum(1);
        setSubmitted(false);
        dispatch(bookingActions.clearBookingDetail());
        hitBookingDetail(1, rowPerPage);
    }, []);

    useEffect(() => {
        setTotalRecord(0);
        if (errBookingObj && errBookingObj.StatusCode !== "200") {
            setIsError(true);
            setAlert({ Type: "Error", StatusMessage: errBookingObj.StatusMessage });
        }
    }, [errBookingObj]);

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        setLoadmore(1);
        setTotalRecord(0);
        setCur(1);
        setNum(1);
        hitBookingDetail(1, rowPerPage);
    }

    useEffect(() => {
        if (totalRecord > 0 && acc.CustId) {
            hitBookingDetail(cur, rowPerPage);
        }
    }, [cur]);

    useEffect(() => {
        if (bookingDetailObj && bookingDetailObj.TotalRecord) {
            setTotalRecord(bookingDetailObj.TotalRecord);
        }
    }, [bookingDetailObj]);

    useEffect(() => {
        if (totalRecord > 0 && acc.CustId) {
            hitBookingDetail(1, loadMore * rowPerPage);
        }
    }, [loadMore]);

    function dateParser(value) {
        const date = new Date(value);
        let dateParsed =
            date.getFullYear() +
            "-" +
            date.toLocaleString("en-us", { month: "2-digit" }) +
            "-" +
            date.toLocaleString("en-us", { day: "2-digit" });
        return dateParsed;
    }

    function exportData() {
        setIsError(false);
        if (
            bookingDtGte != "" &&
            bookingDtGte != undefined &&
            bookingDtLte != "" &&
            bookingDtLte != undefined &&
            acc != undefined
        ) {
            const bookDetailObj = {
                CustId: acc.CustId,
                BookingDtGte: bookingDtGte,
                BookingDtLte: bookingDtLte,
                ResiNo: resiNo,
                SellerAliasName: sellerAliasName,
                RecipientName: recipientName,
                CustRefNo: custRefNo,
                DropType: dropType,
                AddrType: addrType,
                PaymentType: paymentType,
                BookStat: bookStat,
                TransportType: transportType,
                RowPerPage: 99999,
                PageNo: 1,
            };
            exportBookingDetail(bookDetailObj);
            setExported(true);
        }
    }
    useEffect(() => {
        if (exportDataObj && exportDataObj.exportBookingDetailObj.ExportDataFile && exported) {
            var a = document.createElement("a");
            a.href =
                "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
                exportDataObj.exportBookingDetailObj.ExportDataFile;
            a.download = exportDataObj.exportBookingDetailObj.Filename;
            a.click();
            setExported(false);
        }
    }, [exportDataObj]);

    const getDate = (dateObj) => {
        setLoadmore(1);
        setTotalRecord(0);
        setCur(1);
        setNum(1);
        setBookingDtGte(dateObj.dateFrom);
        setBookingDtLte(dateObj.dateTo);
        setShortcut(true);
    };

    useEffect(() => {
        if (shortcut) {
            hitBookingDetail(1, rowPerPage);
            setSubmitted(true);
            setShortcut(false);
        }
    }, [shortcut]);

    return (
        <>
            <div className="hidden sm:block max-w-6xl mx-auto">
                <div className="justify-center py-2 px-4 flex flex-row">
                    <h1 className="font-bold text-xl">Booking Details</h1>
                </div>
                <div>
                    <form onSubmit={handleSubmit} method="POST">
                        <div className="h-full w-full text-xs sm:text-sm font-medium">
                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm">
                                    Tanggal Awal Booking
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <input
                                        aria-label="Tanggal Awal Booking"
                                        id="bookingDtGte"
                                        name="bookingDtGte"
                                        type="date"
                                        value={bookingDtGte}
                                        className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                        placeholder="Tanggal awal booking"
                                        onChange={(e) => setBookingDtGte(e.target.value)}
                                    />
                                    {submitted && !bookingDtGte && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap isi tanggal awal booking
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    Tanggal Akhir Booking
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <input
                                        aria-label="Tanggal Akhir Booking"
                                        id="bookingDtLte"
                                        name="bookingDtLte"
                                        type="date"
                                        value={bookingDtLte}
                                        className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                        placeholder="Tanggal akhir booking"
                                        onChange={(e) => setBookingDtLte(e.target.value)}
                                    />
                                    {submitted && !bookingDtLte && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap isi tanggal akhir booking
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm">No Resi</div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <input
                                        id="resiNo"
                                        type="text"
                                        name="resiNo"
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        placeholder="Isi nomor resi"
                                        value={resiNo}
                                        onChange={(e) => setResiNo(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    Alias Pengirim
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <input
                                        id="sellerAliasName"
                                        type="text"
                                        name="sellerAliasName"
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        placeholder="Isi nama alias pengirim"
                                        value={sellerAliasName}
                                        onChange={(e) => setSellerAliasName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm">Nama Penerima</div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <input
                                        id="recipientName"
                                        type="text"
                                        name="recipientName"
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        placeholder="Isi nama penerima"
                                        value={recipientName}
                                        onChange={(e) => setRecipientName(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">Reference No</div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <input
                                        id="custRefNo"
                                        type="text"
                                        name="custRefNo"
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        placeholder="Isi nomor refensi"
                                        value={custRefNo}
                                        onChange={(e) => setCustRefNo(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm">Pembayaran</div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <select
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        id="paymentType"
                                        name="paymentType"
                                        value={paymentType}
                                        onChange={(e) => setPaymentType(e.target.value)}
                                    >
                                        <option value="">Semua</option>
                                        <option value={masterSettingConstants.PAYTYPE_LUNAS}>
                                            Lunas
                                        </option>
                                        <option value={masterSettingConstants.PAYTYPE_KREDIT}>
                                            Kredit
                                        </option>
                                        <option value={masterSettingConstants.PAYTYPE_TAGIHTUJUAN}>
                                            Tagih Tujuan
                                        </option>
                                        <option
                                            value={masterSettingConstants.PAYTYPE_KREDITPENERIMA}
                                        >
                                            Kredit Penerima
                                        </option>
                                    </select>
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">Pengiriman</div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <select
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        id="transportType"
                                        name="transportType"
                                        value={transportType}
                                        onChange={(e) => setTransportType(e.target.value)}
                                    >
                                        <option value="">Semua</option>
                                        <option value={masterSettingConstants.TRANSPORT_TYPE_DARAT}>
                                            Darat
                                        </option>
                                        <option value={masterSettingConstants.TRANSPORT_TYPE_UDARA}>
                                            Udara
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="row px-2 py-1">
                                {/* <div className="col-md-3 text-xs sm:text-sm">Drop Type</div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <select
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        id="dropType"
                                        name="dropType"
                                        value={dropType}
                                        onChange={(e) => setDropType(e.target.value)}
                                    >
                                        <option value="">Semua</option>
                                        <option value={masterSettingConstants.DROP_TYPE_PICKUP}>
                                            Pickup
                                        </option>
                                        <option value={masterSettingConstants.DROP_TYPE_DROP}>
                                            Drop
                                        </option>
                                    </select>
                                </div> */}
                                <div className="col-md-3 text-xs sm:text-sm">
                                    Status Booking
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <select
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        id="bookStat"
                                        name="bookStat"
                                        value={bookStat}
                                        onChange={(e) => setBookStat(e.target.value)}
                                    >
                                        <option value="">Semua</option>
                                        <option value={masterSettingConstants.BOOK_STAT_ACT}>
                                            Booking Baru
                                        </option>
                                        <option value={masterSettingConstants.BOOK_STAT_EXP}>
                                            Proses Pengiriman
                                        </option>
                                        <option value={masterSettingConstants.BOOK_STAT_PCK}>
                                            Sudah Terpickup
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex justify-end mb-2 -mt-2">
                                <ShortcutDate onSubmit={getDate} />
                            </div>
                            <div className="flex justify-end mb-2 -mt-4">
                                <button
                                    type="submit"
                                    className="relative w-40 flex justify-center items-center mr-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                                >
                                    Search
                                </button>
                                <button
                                    type="button"
                                    onClick={exportData}
                                    className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
                                >
                                    Export
                                </button>
                            </div>
                        </div>
                    </form>
                    {isError == true && (
                        <AlertMessage
                            isError={isError}
                            alert={alert}
                            setAlert={setAlert}
                            setIsError={setIsError}
                        />
                    )}
                    <BookingDetailTables />
                    <Pagination
                        cur={cur}
                        setCur={setCur}
                        loadMore={loadMore}
                        setLoadmore={setLoadmore}
                        rowPerPage={rowPerPage}
                        totalRecord={totalRecord}
                        num={num}
                        setNum={setNum}
                    />
                </div>
            </div>
            <div className="block sm:hidden w-full">
                <div className="bg-blue-600 px-3">
                    <h4 className="flex justify-center text-xl font-semibold text-white py-2">
                        Booking Detail
                    </h4>
                </div>
                <form onSubmit={handleSubmit} method="POST">
                    <div className="flex flex-col text-xs sm:text-sm px-3">
                        <div className="flex flex-row font-bold pt-2">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                Filter dari Tanggal
                            </div>
                            <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                sampai Tanggal
                            </div>
                        </div>
                        <div className="flex flex-row mt-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                <input
                                    aria-label="Tanggal Awal Booking"
                                    id="bookingDtGte"
                                    name="bookingDtGte"
                                    type="date"
                                    value={bookingDtGte}
                                    className={`py-1 px-2 ${submitted && !bookingDtGte
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm outline-none rounded-sm w-100`}
                                    placeholder="Tanggal awal booking"
                                    onChange={(e) => setBookingDtGte(e.target.value)}
                                />
                            </div>
                            <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                <input
                                    aria-label="Tanggal Akhir Booking"
                                    id="bookingDtLte"
                                    name="bookingDtLte"
                                    type="date"
                                    value={bookingDtLte}
                                    className={`py-1 px-2 ${submitted && !bookingDtLte
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } text-xs sm:text-sm outline-none rounded-sm w-100`}
                                    placeholder="Tanggal akhir booking"
                                    onChange={(e) => setBookingDtLte(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                {submitted && !bookingDtGte && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Isi tanggal awal booking
                                    </div>
                                )}
                            </div>
                            <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                {submitted && !bookingDtGte && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Isi tanggal akhir booking
                                    </div>
                                )}
                            </div>
                        </div>
                        {enabled === true && (
                            <>
                                <div className="flex flex-row font-bold mt-1">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        Nomor Resi
                                    </div>
                                    <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                        Alias Pengirim
                                    </div>
                                </div>
                                <div className="flex flex-row mt-1">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        <input
                                            id="resiNo"
                                            name="resiNo"
                                            placeholder="Isi nomor resi"
                                            type="text"
                                            className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                            onChange={(e) => setResiNo(e.target.value)}
                                            value={resiNo}
                                        />
                                    </div>
                                    <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                        <input
                                            id="sellerAliasName"
                                            type="text"
                                            name="sellerAliasName"
                                            className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                            placeholder="Isi nama alias pengirim"
                                            value={sellerAliasName}
                                            onChange={(e) => setSellerAliasName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row mt-1 font-bold">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        Nama Penerima
                                    </div>
                                    <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                        Reference No
                                    </div>
                                </div>
                                <div className="flex flex-row mt-1">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        <input
                                            id="recipientName"
                                            name="recipientName"
                                            type="text"
                                            className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                            placeholder="Isi nama penerima"
                                            value={recipientName}
                                            onChange={(e) => setRecipientName(e.target.value)}
                                        />
                                    </div>
                                    <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                        <input
                                            id="custRefNo"
                                            name="custRefNo"
                                            type="text"
                                            className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                            placeholder="Isi nomor referensi"
                                            value={custRefNo}
                                            onChange={(e) => setCustRefNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row mt-1 font-bold">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        Pembayaran
                                    </div>
                                    <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                        Pengiriman
                                    </div>
                                </div>
                                <div className="flex flex-row mt-1">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        <select
                                            className="py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none rounded-sm w-100"
                                            id="paymentType"
                                            name="paymentType"
                                            value={paymentType}
                                            onChange={(e) => setPaymentType(e.target.value)}
                                        >
                                            <option value="">Semua</option>
                                            <option value={masterSettingConstants.PAYTYPE_LUNAS}>
                                                Lunas
                                            </option>
                                            <option value={masterSettingConstants.PAYTYPE_KREDIT}>
                                                Kredit
                                            </option>
                                            <option
                                                value={masterSettingConstants.PAYTYPE_TAGIHTUJUAN}
                                            >
                                                Tagih Tujuan
                                            </option>
                                            <option
                                                value={masterSettingConstants.PAYTYPE_KREDITPENERIMA}
                                            >
                                                Kredit Penerima
                                            </option>
                                        </select>
                                    </div>
                                    <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                        <select
                                            className="py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none rounded-sm w-100"
                                            id="transportType"
                                            name="transportType"
                                            value={transportType}
                                            onChange={(e) => setTransportType(e.target.value)}
                                        >
                                            <option value="">Semua</option>
                                            <option
                                                value={masterSettingConstants.TRANSPORT_TYPE_DARAT}
                                            >
                                                Darat
                                            </option>
                                            <option
                                                value={masterSettingConstants.TRANSPORT_TYPE_UDARA}
                                            >
                                                Udara
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="flex flex-row mt-1 font-bold">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        Drop Type
                                    </div>
                                    <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                        Status Booking
                                    </div>
                                </div>
                                <div className="flex flex-row mt-1">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        <select
                                            className="py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none rounded-sm w-100"
                                            id="dropType"
                                            name="dropType"
                                            value={dropType}
                                            onChange={(e) => setDropType(e.target.value)}
                                        >
                                            <option value="">Semua</option>
                                            <option value={masterSettingConstants.DROP_TYPE_PICKUP}>
                                                Pickup
                                            </option>
                                            <option value={masterSettingConstants.DROP_TYPE_DROP}>
                                                Drop
                                            </option>
                                        </select>
                                    </div>
                                    <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                        <select
                                            className="py-1 px-2 text-xs sm:text-sm rounded-sm border-gray-300 outline-none w-100"
                                            id="bookStat"
                                            name="bookStat"
                                            value={bookStat}
                                            onChange={(e) => setBookStat(e.target.value)}
                                        >
                                            <option value="">Semua</option>
                                            <option value={masterSettingConstants.BOOK_STAT_CAN}>
                                                Batal
                                            </option>
                                            <option value={masterSettingConstants.BOOK_STAT_ACT}>
                                                Booking Baru
                                            </option>
                                            <option value={masterSettingConstants.BOOK_STAT_EXP}>
                                                Proses Pengiriman
                                            </option>
                                            <option value={masterSettingConstants.BOOK_STAT_PCK}>
                                                Sudah Terpickup
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="flex">
                        <div className="flex-1 text-xs sm:text-sm flex justify-center items-center">
                            <button
                                className="text-red-600 px-3 pt-2"
                                onClick={(e) => setEnabled(!enabled)}
                            >
                                {enabled === true ? (
                                    <>
                                        Hide Search
                                        <FaCaretUp
                                            className="mb-1 w-4 h-4"
                                            style={{ display: "inline-block" }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        Advance Search
                                        <FaCaretDown
                                            className="mb-1 w-4 h-4"
                                            style={{ display: "inline-block" }}
                                        />
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                    <ShortcutDate onSubmit={getDate} />
                    <div className="flex flex-row justify-center -mt-4 mx-3">
                        <div className="flex-1 mr-1">
                            <button
                                type="submit"
                                className="text-xs sm:text-sm w-100 my-2 py-2 border border-transparent font-medium rounded-full text-white bg-red-600"
                            >
                                Search
                            </button>
                        </div>
                        <div className="flex-1 ml-1">
                            <button
                                type="button"
                                onClick={exportData}
                                className="text-xs sm:text-sm w-100 my-2 py-2 border border-transparent font-medium rounded-full text-white bg-green-600"
                            >
                                Export
                            </button>
                        </div>
                    </div>
                </form>
                {isError == true && errBookingObj && (
                    <AlertMessage
                        isError={isError}
                        alert={alert}
                        setAlert={setAlert}
                        setIsError={setIsError}
                    />
                )}
                <BookingDetailTables />
                <Pagination
                    cur={cur}
                    setCur={setCur}
                    loadMore={loadMore}
                    setLoadmore={setLoadmore}
                    rowPerPage={rowPerPage}
                    totalRecord={totalRecord}
                    num={num}
                    setNum={setNum}
                />
            </div>
        </>
    );
};
export default BookingDetails;
