import { resiConstants } from "../_constants";
import { resiService } from "../_services";

export const resiActions = {
    getResiHInfo,
    addResiComplaint,
    getComplaintMessages,
    requestPrintResi,
    clearPrintResi,
    requestPrintResiBulky,
    clearPrintResiBulky,
};

function getResiHInfo(resiHId) {
    return (dispatch) => {
        dispatch(request({ resiHId }));
        resiService.getResiHInfo(resiHId).then(
            (resiHInfo) => {
                dispatch(success(resiHInfo));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(resiHInfo) {
        return {
            type: resiConstants.RESI_INFO_GET_REQUEST,
            resiHInfo,
        };
    }
    function success(resiHInfo) {
        return {
            type: resiConstants.RESI_INFO_GET_SUCCESS,
            resiHInfo,
        };
    }
    function failure(error) {
        return { type: resiConstants.RESI_INFO_GET_FAILURE, error };
    }
}

function addResiComplaint(addResiComplaintObj) {
    return (dispatch) => {
        dispatch(request({ addResiComplaintObj }));
        resiService.addResiComplaint(addResiComplaintObj).then(
            (addResiComplaint) => {
                dispatch(success(addResiComplaint));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(addResiComplaint) {
        return {
            type: resiConstants.RESI_COMPLAINT_ADD_REQUEST,
            addResiComplaint,
        };
    }
    function success(addResiComplaint) {
        return {
            type: resiConstants.RESI_COMPLAINT_ADD_SUCCESS,
            addResiComplaint,
        };
    }
    function failure(error) {
        return { type: resiConstants.RESI_COMPLAINT_ADD_FAILURE, error };
    }
}

function getComplaintMessages(getComplaintMessagesObj) {
    return (dispatch) => {
        dispatch(request({ getComplaintMessagesObj }));
        resiService.getComplaintMessages(getComplaintMessagesObj).then(
            (complaintMessages) => {
                dispatch(success(complaintMessages));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(getComplaintMessagesObj) {
        return {
            type: resiConstants.RESI_COMPLAINT_GET_REQUEST,
            getComplaintMessagesObj,
        };
    }
    function success(getComplaintMessagesObj) {
        return {
            type: resiConstants.RESI_COMPLAINT_GET_SUCCESS,
            getComplaintMessagesObj,
        };
    }
    function failure(error) {
        return { type: resiConstants.RESI_COMPLAINT_GET_FAILURE, error };
    }
}


function requestPrintResi(resiNo, custId) {
    return dispatch => {
        dispatch(request({ resiNo, custId }));
        resiService.requestPrintResiObj(resiNo, custId)
            .then(
                printResiObj => {
                    dispatch(success(printResiObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(printResiObj) { return { type: resiConstants.PRINT_RESI_REQUEST, printResiObj } }
    function success(printResiObj) { return { type: resiConstants.PRINT_RESI_SUCCESS, printResiObj } }
    function failure(error) { return { type: resiConstants.PRINT_RESI_FAILURE, error } }
}

function clearPrintResi() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('printResiObj');
        }
    };

    function clear() { return { type: resiConstants.PRINT_RESI_CLEAR } }
}

function requestPrintResiBulky(printResiBulky) {
    return dispatch => {
        dispatch(request(printResiBulky));
        resiService.requestPrintResiBulkyObj(printResiBulky)
            .then(
                printResiBulkyObj => {
                    dispatch(success(printResiBulkyObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(printResiBulkyObj) { return { type: resiConstants.PRINT_RESI_BULKY_REQUEST, printResiBulkyObj } }
    function success(printResiBulkyObj) { return { type: resiConstants.PRINT_RESI_BULKY_SUCCESS, printResiBulkyObj } }
    function failure(error) { return { type: resiConstants.PRINT_RESI_BULKY_FAILURE, error } }
}

function clearPrintResiBulky() {
    return dispatch => {
        dispatch(clear());
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('printResiBulkyObj');
        }
    };

    function clear() { return { type: resiConstants.PRINT_RESI_BULKY_CLEAR } }
}
