import { history } from "./history";
import { accService } from "../_services";

export const httpHelpers = {
    handleResponse,
    handleError,
};


function handleResponse(response) {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => {
                    if (json.StatusCode == "200")
                        resolve(json)
                    else
                        reject(json)
                });
            } else {
                resolve();
            }
        } else {
            // return error message from response body
            response.text().then(text => reject(text));
        }
    });
}

function handleError(error) {
    return Promise.reject(error && error.message);
}