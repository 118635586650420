import { httpHelpers } from '../_helpers';
import { urlConstants } from '../_constants';

export const districtService = {
    getDistrict
};

function getDistrict() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(urlConstants.URL_GET_DISTRICT, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    districtObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('districtObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

