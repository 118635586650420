import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import moment from "moment";

const PickupReqMonitoringTable = ({ sortConfig, setSortConfig, process, pickupReqGrpId}) => {
    const pickupReqMonitoringObj = useSelector(state => state.pickupReqMonitoring.pickupReqMonitoringObj);
    function sort(key) {
        let direction = "ASC";
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === "ASC"
        ) {
            direction = "DESC";
        }
        setSortConfig({ key, direction });
    }
    var totalPickup = 0;
    var totalOnProcess = 0;
    var totalDone = 0;
    var totalNew = 0;

    if (pickupReqMonitoringObj && pickupReqMonitoringObj.Result) {
        totalPickup = pickupReqMonitoringObj.Result.reduce((n, { TotalPickup }) => n + TotalPickup, 0)
        totalOnProcess = pickupReqMonitoringObj.Result.reduce((n, { OnProcess }) => n + OnProcess, 0)
        totalDone = pickupReqMonitoringObj.Result.reduce((n, { Done }) => n + Done, 0)
        totalNew = pickupReqMonitoringObj.Result.reduce((n, { New }) => n + New, 0)
    }

    function getDonePercentage(belumSelesai, total) {
        if (total > 0)
            return ((total - belumSelesai) / total) * 100;
        else
            return 0;
    }
    function openInNewTab(e, url) {
        e.preventDefault();
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    }

    function openInNewTabWithStatus(e, url) {
        e.stopPropagation();
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    }

    return (
        <div className="text-xs sm:text-sm">
            {pickupReqMonitoringObj &&
                pickupReqMonitoringObj.Result &&
                pickupReqMonitoringObj.Result.length == 0 && (
                    <div className="justify-center flex flex-row">
                        <p>Tidak ada request pickup</p>
                    </div>
                )}
            {pickupReqMonitoringObj && pickupReqMonitoringObj.Result != null && pickupReqMonitoringObj.Result.length > 0 && (
                <>
                    <div className="hidden sm:block text-xs sm:text-sm mx-auto">
                        <table
                            className="table table-striped table-fixed bg-white"
                            aria-labelledby="tabelLabel"
                        >
                            <thead>
                                <tr>
                                    <th className="text-center">
                                        <button
                                            type="button"
                                            color="blue"
                                            onClick={() => sort("RequestDt")}
                                            className="font-bold"
                                        >
                                            Request Pickup
                                        </button>
                                    </th>
                                    <th className="text-right">
                                        <button
                                            type="button"
                                            onClick={() => sort("New")}
                                            className="font-bold"
                                        >
                                            New
                                        </button>
                                    </th>
                                    <th className="text-right">
                                        <button
                                            type="button"
                                            onClick={() => sort("OnProcess")}
                                            className="font-bold"
                                        >
                                            On Process
                                        </button>
                                    </th>
                                    <th className="text-right">
                                        <button
                                            type="button"
                                            onClick={() => sort("Done")}
                                            className="font-bold"
                                        >
                                            Done
                                        </button>
                                    </th>
                                    <th className="text-right">
                                        <button
                                            type="button"
                                            onClick={() => sort("TotalPickup")}
                                            className="font-bold"
                                        >
                                            Total
                                        </button>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {pickupReqMonitoringObj.Result.map((pm) => (
                                    <tr key={pm.RequestDt}>
                                        <td align='center'>{moment(pm.RequestDt).format("DD-MM-YY")}</td>
                                        <td align='right'>
                                            <Link
                                                target={"_blank"}
                                                to={{
                                                    pathname: `/PickupReqDetail?trxDt=${moment(pm.RequestDt).format("YYYY-MM-DD")}&process=NEW&pickupReqGrpId=${pickupReqGrpId}`,
                                                }} style={{ color: '#337ab7' }}
                                            >
                                                {pm.New.toLocaleString("en", { minimumFractionDigits: 0 })}
                                            </Link>
                                        </td>
                                        <td align='right'>
                                            <Link
                                                target={"_blank"}
                                                to={{
                                                    pathname: `/PickupReqDetail?trxDt=${moment(pm.RequestDt).format("YYYY-MM-DD")}&process=ONPROCESS&pickupReqGrpId=${pickupReqGrpId}`,
                                                }} style={{ color: '#337ab7' }}
                                            >
                                                {pm.OnProcess.toLocaleString("en", { minimumFractionDigits: 0 })}
                                            </Link>
                                        </td>
                                        <td align='right'>
                                            <Link
                                                target={"_blank"}
                                                to={{
                                                    pathname: `/PickupReqDetail?trxDt=${moment(pm.RequestDt).format("YYYY-MM-DD")}&process=DONE&pickupReqGrpId=${pickupReqGrpId}`,
                                                }} style={{ color: '#337ab7' }}
                                            >
                                                {pm.Done.toLocaleString("en", { minimumFractionDigits: 0 })}
                                            </Link>
                                        </td>
                                        <td align='right'>{pm.TotalPickup.toLocaleString("en", { minimumFractionDigits: 0 })}</td>
                                        <td align='center'>
                                            <Link
                                                target={"_blank"}
                                                to={{
                                                    pathname: `/PickupReqDetail?trxDt=${moment(pm.RequestDt).format("YYYY-MM-DD")}&process=${process}&pickupReqGrpId=${pickupReqGrpId}`,
                                                }}
                                                style={{ color: '#337ab7' }}
                                            >
                                                Detail
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                {pickupReqMonitoringObj &&
                                    <tr>
                                        <td></td>
                                        <td align='right'>
                                            {<span className="number">{totalNew.toLocaleString("en", { minimumFractionDigits: 0 })}</span>}
                                        </td>
                                        <td align='right'>
                                            {<span className="number">{totalOnProcess.toLocaleString("en", { minimumFractionDigits: 0 })}</span>}
                                        </td>
                                        <td align='right'>
                                            {<span className="number">{totalDone.toLocaleString("en", { minimumFractionDigits: 0 })}</span>}
                                        </td>
                                        <td align='right'>
                                            {<span className="number">{totalPickup.toLocaleString("en", { minimumFractionDigits: 0 })}</span>}
                                        </td>
                                        <td></td>
                                    </tr>
                                }
                            </tfoot>
                        </table>
                    </div>
                    <div className="block sm:hidden w-full">
                        <div className="px-3">
                            {pickupReqMonitoringObj.Result.map((pm) => (
                                <div className="rounded-md h-full w-full bg-white shadow flex rounded items-center my-2" key={pm.RequestDt} onClick={(e) => openInNewTab(e, `/PickupReqDetail?trxDt=${moment(pm.RequestDt).format("YYYY-MM-DD")}`)}>
                                    <div className="h-full w-full flex items-center py-2">
                                        <div className="font-medium w-full">
                                            <div className="flex px-3">
                                                <div className="flex-1 flex items-center">
                                                    <h4 className="w-full text-xl font-semibold">{
                                                        moment(pm.RequestDt).format("DD MMMM YYYY")
                                                    }</h4>
                                                    <div className="text-center justify-end text-sm">
                                                        <div className="text-blue-600">
                                                            {getDonePercentage(pm.New + pm.OnProcess, pm.TotalPickup).toLocaleString("en", { minimumFractionDigits: 0 })}%
                                                        </div> Selesai
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex py-1 px-3">
                                                <div className="flex-1 text-xs sm:text-sm flex items-end text-red-500">
                                                    {pm.TotalPickup.toLocaleString("en", { minimumFractionDigits: 0 })} Pickup Request
                                                </div>
                                            </div>
                                            <div className="flex flex-row py-1">
                                                <div className="flex-1">
                                                    <div className="flex-col">
                                                        <div className="h-2 w-full bg-blue-600"></div>
                                                        <div className="font-bold text-center my-1 text-sm">New</div>
                                                        <div className="text-center my-1 text-sm" onClick={(e) => openInNewTabWithStatus(e, `/PickupReqDetail?trxDt=${moment(pm.RequestDt).format("YYYY-MM-DD")}&pickupReqStat=NEW`)}>
                                                            {pm.New.toLocaleString("en", { minimumFractionDigits: 0 })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1">
                                                    <div className="flex-col">
                                                        <div className="h-2 w-full bg-yellow-500"></div>
                                                        <div className="font-bold text-center my-1 text-sm">On Process</div>
                                                        <div className="text-center my-1 text-sm" onClick={(e) => openInNewTabWithStatus(e, `/PickupReqDetail?trxDt=${moment(pm.RequestDt).format("YYYY-MM-DD")}&pickupReqStat=ONPROCESS`)} >
                                                            {pm.OnProcess.toLocaleString("en", { minimumFractionDigits: 0 })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1">
                                                    <div className="flex-col">
                                                        <div className="h-2 w-full bg-green-600"></div>
                                                        <div className="font-bold text-center my-1 text-sm">Done</div>
                                                        <div className="text-center my-1 text-sm" onClick={(e) => openInNewTabWithStatus(e, `/PickupReqDetail?trxDt=${moment(pm.RequestDt).format("YYYY-MM-DD")}&pickupReqStat=DONE`)}>
                                                            {pm.Done.toLocaleString("en", { minimumFractionDigits: 0 })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))};
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default PickupReqMonitoringTable;
