import React from 'react';
import { Link } from 'react-router-dom';

const TableDetailBarangV2 = ({ arrTempBarang }) => {

    function openInNewTab(e, url) {
        e.preventDefault();
        e.stopPropagation();
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    }

    return (
        <>
            <div className="hidden sm:block w-full">
                <table className='table table-striped text-xs sm:text-sm bg-white'>
                    <thead className="font-bold">
                        <tr>
                            <td>#Koli</td>
                            <td>Item Code No</td>
                            <td>Dimensi Barang (P x L x T)</td>
                            <td>Estimasi Berat (Kg)</td>
                            <td>Estimasi Berat Final (Kg)</td>
                            <td>Sumber Perhitungan</td>
                            <td>Packing</td>
                            <td>Status</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {arrTempBarang && arrTempBarang.map((booking, index) => (
                            <tr key={index}>
                                <td>
                                    {new Intl.NumberFormat("en", {
                                        minimumFractionDigits: 0
                                    }).format(booking.SeqNo)}
                                </td>
                                <td>
                                    {booking.ItemCodeNo}
                                </td>
                                <td>
                                    {new Intl.NumberFormat("en", {
                                        minimumFractionDigits: 0
                                    }).format(booking.DimP)} x {new Intl.NumberFormat("en", {
                                        minimumFractionDigits: 0
                                    }).format(booking.DimL)} x {new Intl.NumberFormat("en", {
                                        minimumFractionDigits: 0
                                    }).format(booking.DimT)} ({new Intl.NumberFormat("en", {
                                        minimumFractionDigits: 2
                                    }).format(booking.VolWeight)} kg)
                                </td>
                                <td>
                                    {new Intl.NumberFormat("en", {
                                        minimumFractionDigits: 2
                                    }).format(booking.ItemWeight)}
                                </td>
                                <td>
                                    {new Intl.NumberFormat("en", {
                                        minimumFractionDigits: 2
                                    }).format(booking.FinalWeight)}
                                </td>
                                <td>
                                    {booking.TotalCalcSrc}
                                </td>
                                <td>
                                    {booking.IsPacking ? `Ya (${booking.PackingKayu ? "Kayu" : ""}${booking.PackingKayu && booking.BubbleWrap ? ", " : ""}${booking.BubbleWrap ? "Bubble Wrap" : ""}${(booking.PackingKayu || booking.BubbleWrap) && booking.Wrapping ? ", " : ""}${booking.Wrapping ? "Wrapping" : ""})` : "Tidak"}
                                </td>
                                <td>
                                    {booking.CustBookDStat}
                                </td>
                                <td>
                                    <Link target={"_blank"} to={{ pathname: `/booking-viewD?id=${booking.CustBookDId}` }} style={{ color: '#337ab7' }}>
                                        Detail
                                    </Link>
                                    {booking.CustBookDStat != "CAN" && (
                                        <>
                                            <label>&nbsp;|&nbsp;</label>
                                            <Link target={"_blank"} to={{ pathname: `/booking-print?trxNo=${booking.ResiNo}&itemCodeNo=${booking.ItemCodeNo}` }} style={{ color: '#337ab7' }}>
                                                Print label
                                            </Link>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="block sm:hidden w-full">
                {
                    arrTempBarang && arrTempBarang.map((booking, index) =>
                        <div className="h-full w-full bg-white shadow flex rounded items-center my-2"
                            key={index}>
                            <div className="relative h-full w-full flex items-center px-2 py-2">
                                <div className="text-sm font-medium w-full mr-3">
                                    <div className="flex px-2 py-1">
                                        <div className="flex-1  flex items-center font-bold">
                                            #Koli {new Intl.NumberFormat("en", {
                                                minimumFractionDigits: 0
                                            }).format(booking.SeqNo)}
                                        </div>
                                        <div className="flex items-center">
                                            <div className={`w-2.5 h-2.5 rounded-full ${booking.CustBookDStat === "ACT" ? 'bg-green-600' :
                                                booking.CustBookDStat === "CAN" ? 'bg-red-600' : 'bg-blue-600'}`}></div>
                                        </div>
                                        <p className={`flex items-center font-semibold text-xs sm:text-sm ml-1 ${booking.CustBookDStat === "ACT" ? 'text-green-600' :
                                            booking.CustBookDStat === "CAN" ? 'text-red-600' : 'text-blue-600'}`}>
                                            {booking.CustBookDStat}</p>
                                    </div>
                                    <div className="flex px-2 py-1">
                                        <div className="flex-1  flex items-center">
                                            Item Code No
                                        </div>
                                        <div className="flex-1 text-right  font-bold">
                                            {booking.ItemCodeNo}
                                        </div>
                                    </div>
                                    <div className="flex px-2 py-1">
                                        <div className="flex-1  flex items-center">
                                            Dimensi Barang (PxLxT)
                                        </div>
                                        <div className="flex-1 text-right  font-bold">
                                            {new Intl.NumberFormat("en", {
                                                minimumFractionDigits: 0
                                            }).format(booking.DimP)} x {new Intl.NumberFormat("en", {
                                                minimumFractionDigits: 0
                                            }).format(booking.DimL)} x {new Intl.NumberFormat("en", {
                                                minimumFractionDigits: 0
                                            }).format(booking.DimT)} ({new Intl.NumberFormat("en", {
                                                minimumFractionDigits: 2
                                            }).format(booking.VolWeight)} kg)
                                        </div>
                                    </div>
                                    <div className="flex px-2 py-1">
                                        <div className="flex-1  flex items-center">
                                            Est. Berat Barang
                                        </div>
                                        <div className="flex-1 text-right  font-bold">
                                            {new Intl.NumberFormat("en", {
                                                minimumFractionDigits: 2
                                            }).format(booking.ItemWeight)} kg
                                        </div>
                                    </div>
                                    <div className="flex px-2 py-1">
                                        <div className="flex-1  flex items-center">
                                            Final Berat Barang
                                        </div>
                                        <div className="flex-1 text-right  font-bold">
                                            {new Intl.NumberFormat("en", {
                                                minimumFractionDigits: 2
                                            }).format(booking.FinalWeight)} kg ({booking.TotalCalcSrc})
                                        </div>
                                    </div>
                                    <div className="flex px-2 py-1">
                                        <div className="flex-1  flex items-top">
                                            Packing
                                        </div>
                                        <div className="flex-1 text-right  font-bold">
                                            {booking.IsPacking ? `Ya (${booking.PackingKayu ? "Kayu" : ""}${booking.PackingKayu && booking.BubbleWrap ? ", " : ""}${booking.BubbleWrap ? "Bubble Wrap" : ""}${(booking.PackingKayu || booking.BubbleWrap) && booking.Wrapping ? ", " : ""}${booking.Wrapping ? "Wrapping" : ""})` : "Tidak"}
                                        </div>
                                    </div>
                                    {booking.CustBookDStat != 'CAN' && (
                                        < div className="flex flex-row justify-around">
                                            <button type="button" className="w-100 py-2 flex-grow mx-1 text-xs font-medium rounded-full text-blue-600 bg-white border-2 border-blue-600" onClick={(e) => openInNewTab(e, `/booking-viewD?id=${booking.CustBookDId}`)}>
                                                Detail
                                            </button>
                                            <button type="button" className="w-100 py-2 px-2 flex-grow mx-1 text-xs font-medium rounded-full text-green-600 bg-white border-2 border-green-600" onClick={(e) => openInNewTab(e, `/booking-download?trxNo=${booking.ResiNo}&itemCodeNo=${booking.ItemCodeNo}`)}>
                                                Download Label Barang
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <span className={`absolute ${booking.CustBookDStat === "ACT" ? 'bg-green-600' :
                                    booking.CustBookDStat === "CAN" ? 'bg-red-600' : 'bg-blue-600'} right-0 h-full rounded-r w-5 h-full text-center item-center`}>
                                </span>
                            </div>
                        </div>
                    )
                }
            </div >
        </>
    );
}

export default TableDetailBarangV2;
