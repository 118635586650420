import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    SCFileBase64,
    AlertMessage
} from "../../components";
import {
    bookingActions
} from "../../_actions";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import TemplateUploadBooking from '../../assets/files/Template_UploadBooking.xlsx'
import KamusDataUploadBooking from '../../assets/files/KamusData_UploadBooking.pdf'
import moment from "moment";

const BookingUploadForm = ({ t }) => {
    const [files, setFiles] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const dispatch = useDispatch();
    const acc = useSelector((state) => state.authentication.accInfo);
    const [alert, setAlert] = useState({
        Type: "",
        StatusMessage: "",
    });
    const [modalShow, setModalShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [isError, setIsError] = useState(false);
    const errSubmitObj = useSelector(
        (state) => state.uploadBooking.errUploadBookingObj
    );
    const history = useHistory();
    const respSubmitObj = useSelector(
        (state) => state.uploadBooking.uploadBookingObj
    );

    useEffect(() => {
        if (respSubmitObj && respSubmitObj.FileName !== "") {
            console.log(respSubmitObj);
            setModalShow(true);
        }
    }, [respSubmitObj]);

    useEffect(() => {
        if (errSubmitObj && errSubmitObj.StatusCode != "200") {
            setIsError(true);
            setSubmitted(false);
            setAlert({ Type: "Error", StatusMessage: errSubmitObj.StatusMessage });
            setFiles([]);
        }
    }, [errSubmitObj]);

    useEffect(() => {
        setSubmitted(false);
        dispatch(bookingActions.clearUploadBooking());
    }, []);

    const inputRef = useRef(null);

    function handleUpload(e) {
        e.preventDefault();
        setSubmitted(true);
        console.log("SUBMIT")
        console.log(files)
        if (files != "") {
            console.log("SUBMIT")
            setFileName(files.name);
            const bookingUploadObj = {
                CustId: acc.CustId,
                CustName: acc.CustName,
                UploadType: "BOOKING",
                FileName: files.name,
                Base64File: files.base64,
                Type: files.type
            }
            console.log(bookingUploadObj);

            const submitUploadBooking = async () => {
                dispatch(bookingActions.requestUploadBooking(bookingUploadObj));
            };
            submitUploadBooking();
        }
    }

    useEffect(() => {
        console.log("Upload Files :")
        console.log(files)
    }, [files]);


    function navigateToHome() {
        setModalShow(false);
        setSubmitted(false);
        clearDatas();
        history.push("/Home");
    }
    function navigateToUploadBooking() {
        setModalShow(false);
        setSubmitted(false);
        clearDatas();
    }
    function navigateToUploadMonitoring() {
        setModalShow(false);
        setSubmitted(false);
        clearDatas();
        history.push("/UploadMonitoring");
    }

    function clearDatas() {
        setFiles([]);
        setSubmitted(false);
        inputRef.current.value = null;
    }

    function downloadTemplate() {
        var a = document.createElement("a");
        a.href = TemplateUploadBooking
        a.download = "Template_UploadBooking";
        a.click();

        var a = document.createElement("a");
        a.href = KamusDataUploadBooking
        a.download = "KamusData_UploadBooking";
        a.click();
    }

    return (
        <>
            <div className="hidden sm:block max-w-6xl mx-auto">
                <div className="justify-center py-2 px-4 flex flex-row">
                    <h1 className="font-bold text-xl">Upload Booking</h1>
                </div>
                <AlertMessage
                    isError={isError}
                    alert={alert}
                    setAlert={setAlert}
                    setIsError={setIsError}
                />
                <form onSubmit={handleUpload} method="POST">
                    <div className="max-w-6xl mx-auto bg-white text-xs sm:text-sm font-medium">
                        <div className="row px-2 py-1 justify-center items-center">
                            <div className="col-md-2 text-xs sm:text-sm">
                                Pilih File
                            </div>
                            <div className="col-md-3 justify-end text-xs sm:text-sm">
                                <SCFileBase64
                                    inputRef={inputRef}
                                    multiple={false}
                                    setFiles={setFiles} />
                                {submitted && files.length === 0 && (
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Tidak boleh kosong
                                    </div>
                                )}
                            </div>
                            <div className="col-md-2">
                                <button
                                    type="submit"
                                    className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                >
                                    Submit
                                </button>
                            </div>
                            <div className="col-md-2">
                                <button
                                    onClick={(e) => clearDatas()}
                                    type="button"
                                    className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                                >
                                    Batal
                                </button>
                            </div>
                        </div>
                        <div className="row px-2 py-1 justify-center">
                            <div className="col-md-2" />
                            <div className="col-md-3 text-blue-500 text-xs sm:text-sm"
                                onClick={() => downloadTemplate()}>
                                Download Template
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="block sm:hidden max-w-6xl mx-auto">
                <div className="flex flex-row bg-blue-600">
                    <h4 className="w-full text-center text-xl font-semibold text-white py-2">
                        Upload Booking
                    </h4>
                </div>
                <AlertMessage
                    isError={isError}
                    alert={alert}
                    setAlert={setAlert}
                    setIsError={setIsError}
                />
                <form onSubmit={handleUpload} method="POST">
                    <div className="h-full w-full bg-white flex items-center">
                        <div className="h-full w-full flex items-center">
                            <div className="text-xs sm:text-sm font-medium w-full">
                                <div className="flex flex-row mx-2 py-1">
                                    <div className="text-xs sm:text-sm flex justify-evenly pl-4">
                                        Pilih File
                                    </div>
                                    <div className="ml-2"
                                    >
                                        <SCFileBase64
                                            inputRef={inputRef}
                                            multiple={false}
                                            setFiles={setFiles} />
                                        {submitted && files.length === 0 && (
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                Tidak boleh kosong
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-row mx-2 py-1">
                                    <div className="pl-4 text-xs sm:text-sm flex items-center">
                                        <div className="pl-5 text-blue-500 text-xs sm:text-sm"
                                            onClick={() => downloadTemplate()}>
                                            Download Template
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-evenly mt-3">
                                    <button
                                        type="submit"
                                        className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                    >
                                        Submit
                                    </button>

                                    <button
                                        onClick={(e) => clearDatas()}
                                        type="button"
                                        className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                                    >
                                        Batal
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form >
            </div >
            <Modal size="lg" isOpen={modalShow}>
                <ModalHeader className="font-bold text-xs sm:text-sm items-center">
                    Summary Upload Booking
                </ModalHeader>
                <ModalBody>
                    {respSubmitObj && submitted == true && (
                        <>
                            <div className="hidden sm:block">
                                <div className="relative h-full w-full flex items-center">
                                    <div className="text-xs sm:text-sm font-medium w-full px-2 py-2">
                                        <div className="flex px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Booking telah sukses di upload, data yang diupload dapat di monitoring pada menu Upload Monitoring
                                            </div>
                                        </div>
                                        <div className="flex px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex font-bold">
                                                SUMMARY
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Nama File
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {fileName}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Tanggal Upload
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {moment(respSubmitObj.UploadDt).format("DD-MM-YY HH:mm")}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Jumlah Record
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {respSubmitObj.TotalRecord}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center">
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToUploadMonitoring()}
                                    >
                                        Upload Monitoring
                                    </button>
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToUploadBooking()}
                                    >
                                        Upload Booking Lain
                                    </button>
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToHome()}
                                    >
                                        Kembali ke Home
                                    </button>
                                </div>
                            </div>
                            <div className="block sm:hidden">
                                <div className="relative h-full w-full flex items-center">
                                    <div className="text-xs sm:text-sm font-medium w-full px-2 pb-1">
                                        <div className="flex px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Booking telah sukses di upload, data yang diupload dapat di monitoring pada menu Upload Monitoring
                                            </div>
                                        </div>
                                        <div className="flex px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex font-bold">
                                                SUMMARY
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Nama File
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {fileName}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Tanggal Upload
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {moment(respSubmitObj.UploadDt).format("DD-MM-YY HH:mm")}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Jumlah Record
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {respSubmitObj.TotalRecord}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center">
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToUploadMonitoring()}
                                    >
                                        Upload Monitoring
                                    </button>
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToUploadBooking()}
                                    >
                                        Upload Booking Lain
                                    </button>
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToHome()}
                                    >
                                        Kembali ke Home
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
}
export default BookingUploadForm;