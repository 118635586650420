import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { accActions, pickupActions, customerActions } from "../_actions";
import {
  SummaryDashboard,
  DashboardBooking,
  CheckOngkirForm,
  ImageSlideshow,
} from "../pages/Dashboard";
import { Link, useHistory } from "react-router-dom";
import background from "../assets/bg.png";

const Home = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const acc = useSelector((state) => state.authentication.accInfo);
  const custObj = useSelector((state) => state.custById.custObj);
  // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  useEffect(() => {
    clearCacheData();
    if (acc == undefined) {
      history.push("/");
    }
    else
    {
      dispatch(customerActions.requestGetCustById(acc.CustId));
    }
  }, [acc]);

  useEffect(() => {
    if (custObj != null) {
      if (!custObj.IsActive) {
        logout();
      }
    }
  }, [custObj]);

  function logout() {
    dispatch(accActions.logout());
    dispatch(customerActions.clearCust());
    dispatch(pickupActions.clearSelectedPickupAddr());
  }

  return (
    <div
      className="absolute top-0 left-0 h-[100vh] z-0 w-full"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="block sm:hidden bg-gradient-to-b from-blue-600 to-blue-700">
        <h4 className="flex justify-center text-xl font-semibold text-white py-2">
          Dashboard
        </h4>
      </div>
      <ImageSlideshow />
      <SummaryDashboard />
      <DashboardBooking />
      <CheckOngkirForm />
    </div>
  );
};
export default Home;
