import React, { useState, useEffect } from 'react';

const Pagination = ({ cur, setCur, loadMore, setLoadmore, rowPerPage, totalRecord, num, setNum }) => {
    let [maxPage, setMaxPage] = useState(0);
    let pages = []
    if (maxPage >= 3) {
        pages = [
            { page: num },
            { page: num + 1 },
            { page: num + 2 },
        ]
    }
    else if (maxPage >= 2) {
        pages = [
            { page: num },
            { page: num + 1 },
        ]
    }
    else {
        pages = [
            { page: num }
        ]
    }

    function Next() {
        if (num + 3 <= maxPage) {
            setNum(++num)
        }
        cur < maxPage && setCur(++cur)
    }
    function back() {
        num > 1 && setNum(--num)
        cur > 1 && setCur(--cur)
    }
    function first() {
        setNum(1)
        setCur(1)
    }
    function last() {
        if (maxPage >= 3) {
            setNum(maxPage - 2)
        }
        setCur(maxPage)
    }

    function getCurrentShowed() {
        let current = loadMore * rowPerPage
        if (current > totalRecord) {
            current = totalRecord
        }
        return current
    }

    useEffect(() => {
        if (totalRecord > 0) {
            setMaxPage(Math.ceil(totalRecord / rowPerPage));
        }
        else {
            setMaxPage(1);
        }
    }, [totalRecord, rowPerPage]);

    function setButtonOnClick(pageNo) {
        if (pageNo + 2 <= maxPage) {
            setNum(pageNo);
        }
        setCur(pageNo);
    }

    return (
        <>
            {totalRecord > 0 &&
                <>
                    <div className="hidden sm:block container mb-2">
                        <div className="flex flex-row justify-center">
                            <button onClick={first} className="h-10 w-12 border-2 border-r-0 border-indigo-600
               rounded-l-full hover:bg-indigo-600 hover:text-white font-bold text-xs sm:text-sm">
                                {"<<"}
                            </button>
                            <button onClick={back} className={`h-10 border-2 border-r-0 border-indigo-600
               w-10 hover:bg-indigo-600 hover:text-white font-bold text-xs sm:text-sm`}>{"<"}</button>
                            {
                                pages.map((pg, i) => (
                                    <button key={i} onClick={() => setButtonOnClick(pg.page)} className={`h-10 border-2 border-r-0 border-indigo-600
               w-10 text-xs sm:text-sm ${cur === pg.page && 'bg-indigo-600 text-white'}`}>{pg.page}</button>
                                ))
                            }
                            <button onClick={Next} className={`h-10 border-2 border-r-0 border-indigo-600
               w-10 hover:bg-indigo-600 hover:text-white font-bold text-xs sm:text-sm`}>{">"}</button>
                            <button onClick={last} className="h-10 w-12 border-2 border-indigo-600
               px-2 rounded-r-full hover:bg-indigo-600 hover:text-white font-bold text-xs sm:text-sm">
                                {">>"}
                            </button>
                        </div>
                    </div>
                    <div className="block sm:hidden w-full mb-2">
                        <div className="flex justify-center text-xs sm:text-sm">
                            {getCurrentShowed()} of {totalRecord}
                        </div>
                        {loadMore < maxPage &&
                            <div className="flex justify-center">
                                <button type="button" className="flex px-2 py-1 justify-center border-2 border-gray-400 shadow-md border-transparent font-medium rounded-md" onClick={() => setLoadmore(loadMore + 1)}>
                                    Show More Results
                                </button>
                            </div>
                        }
                    </div>
                </>
            }
        </>
    )
}

export default Pagination