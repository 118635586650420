export const bookingConstants = {
    BOOKING_MONITOR_REQUEST: 'BOOKING_MONITOR_REQUEST',
    BOOKING_MONITOR_SUCCESS: 'BOOKING_MONITOR_SUCCESS',
    BOOKING_MONITOR_FAILURE: 'BOOKING_MONITOR_FAILURE',
    BOOKING_MONITOR_CLEAR: 'BOOKING_MONITOR_CLEAR',
    BOOKING_DETAIL_REQUEST: 'BOOKING_DETAIL_REQUEST',
    BOOKING_DETAIL_SUCCESS: 'BOOKING_DETAIL_SUCCESS',
    BOOKING_DETAIL_FAILURE: 'BOOKING_DETAIL_FAILURE',
    BOOKING_DETAIL_CLEAR: 'BOOKING_DETAIL_CLEAR',
    BOOKING_VIEW_H_REQUEST: 'BOOKING_VIEW_H_REQUEST',
    BOOKING_VIEW_H_SUCCESS: 'BOOKING_VIEW_H_SUCCESS',
    BOOKING_VIEW_H_FAILURE: 'BOOKING_VIEW_H_FAILURE',
    BOOKING_VIEW_D_REQUEST: 'BOOKING_VIEW_D_REQUEST',
    BOOKING_VIEW_D_SUCCESS: 'BOOKING_VIEW_D_SUCCESS',
    BOOKING_VIEW_D_FAILURE: 'BOOKING_VIEW_D_FAILURE',
    CALCULATE_BOOKING_REQUEST: 'CALCULATE_BOOKING_REQUEST',
    CALCULATE_BOOKING_SUCCESS: 'CALCULATE_BOOKING_SUCCESS',
    CALCULATE_BOOKING_FAILURE: 'CALCULATE_BOOKING_FAILURE',
    CALCULATE_BOOKING_CLEAR: 'CALCULATE_BOOKING_CLEAR',
    SUBMIT_BOOKING_REQUEST: 'SUBMIT_BOOKING_REQUEST',
    SUBMIT_BOOKING_SUCCESS: 'SUBMIT_BOOKING_SUCCESS',
    SUBMIT_BOOKING_FAILURE: 'SUBMIT_BOOKING_FAILURE',
    SUBMIT_BOOKING_CLEAR: 'SUBMIT_BOOKING_CLEAR',
    PRINT_BOOKING_REQUEST: 'PRINT_BOOKING_REQUEST',
    PRINT_BOOKING_SUCCESS: 'PRINT_BOOKING_SUCCESS',
    PRINT_BOOKING_FAILURE: 'PRINT_BOOKING_FAILURE',
    PRINT_BOOKING_CLEAR: 'PRINT_BOOKING_CLEAR',
    EXPORT_BOOKING_DETAIL_REQUEST: 'EXPORT_BOOKING_DETAIL_REQUEST',
    EXPORT_BOOKING_DETAIL_SUCCESS: 'EXPORT_BOOKING_DETAIL_SUCCESS',
    EXPORT_BOOKING_DETAIL_FAILURE: 'EXPORT_BOOKING_DETAIL_FAILURE',
    EXPORT_BOOKING_DETAIL_CLEAR: 'EXPORT_BOOKING_DETAIL_CLEAR',
    UPLOAD_BOOKING_REQUEST: 'UPLOAD_BOOKING_REQUEST',
    UPLOAD_BOOKING_SUCCESS: 'UPLOAD_BOOKING_SUCCESS',
    UPLOAD_BOOKING_FAILURE: 'UPLOAD_BOOKING_FAILURE',
    UPLOAD_BOOKING_CLEAR: 'UPLOAD_BOOKING_CLEAR',
    GET_CUST_H_DETAIL_REQUEST: 'GET_CUST_H_DETAIL_REQUEST',
    GET_CUST_H_DETAIL_SUCCESS: 'GET_CUST_H_DETAIL_SUCCESS',
    GET_CUST_H_DETAIL_FAILURE: 'GET_CUST_H_DETAIL_FAILURE',
    GET_CUST_H_DETAIL_CLEAR: 'GET_CUST_H_DETAIL_CLEAR',
    RECIPIENT_LIST_CLEAR: "RECIPIENT_LIST_CLEAR",
    RECIPIENT_LIST_SUCCESS: "RECIPIENT_LIST_SUCCESS",
    CAN_KOLI_BOOKING_REQUEST: 'CAN_KOLI_BOOKING_REQUEST',
    CAN_KOLI_BOOKING_SUCCESS: 'CAN_KOLI_BOOKING_SUCCESS',
    CAN_KOLI_BOOKING_FAILURE: 'CAN_KOLI_BOOKING_FAILURE',
    CAN_KOLI_BOOKING_CLEAR: 'CAN_KOLI_BOOKING_CLEAR',
}