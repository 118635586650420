import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bookingActions } from "../../_actions";
import { useLocation, Link } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa";
import { TableDetailBarang, TableDetailBarangV2 } from "../../components";

const BookingViewH = ({ t }) => {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const id = query.get("id");

  const dispatch = useDispatch();
  const bookingHObj = useSelector((state) => state.bookingViewH.bookingHObj);
  const acc = useSelector((state) => state.authentication.accInfo);
  const [isShowDetailBarang, setIsShowDetailBarang] = useState(false);
  useEffect(() => {
    if (id && acc != undefined) {
      const getBookHView = async () => {
        dispatch(bookingActions.requestBookingViewH(id, acc.CustId));
      };
      getBookHView();
    }
  }, []);

  function copyToClipboard(e, bookingNo) {
    e.stopPropagation();
    navigator.clipboard.writeText(bookingNo);
  }

  function dateTimeParser(value) {
    const date = new Date(value);
    const dateParsed =
      date.toLocaleString("en-us", { day: "2-digit" }) +
      "-" +
      date.toLocaleString("en-us", { month: "2-digit" }) +
      "-" +
      date.toLocaleString("en-us", { year: "2-digit" }) +
      " " +
      date.toLocaleTimeString("id", { hourCycle: "h23", hour: "2-digit" }) +
      ":" +
      date.toLocaleTimeString("id", { minute: "2-digit" });
    return dateParsed;
  }

  return (
    <>
      <div className="hidden sm:block container">
        <div
          className={`py-2 px-4`}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <h1 className="w-full text-center font-bold text-xl">Booking View</h1>
        </div>
        {bookingHObj && (
          <div className="justify-center mx-4 flex-col text-xs sm:text-sm">
            <div className="container mx-auto rounded-md shadow border my-2 pt-2">
              <div className="row">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  No Booking
                </label>
                <label className="col-md-3">{bookingHObj.BookNo}</label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  No. Resi
                </label>
                <label className="col-md-3">
                  <Link
                    target={"_blank"}
                    to={{
                      pathname: `/ResiHView?resiHNo=${bookingHObj.ResiNo}`,
                    }}
                    style={{ color: "#337ab7" }}
                  >
                    {bookingHObj.ResiNo}
                  </Link>
                </label>
              </div>
              <div className="row">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Kantor Asal
                </label>
                <label className="col-md-3">{bookingHObj.OriginDestCode}</label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Tujuan
                </label>
                <label className="col-md-3">{bookingHObj.Destination}</label>
              </div>
              <div className="row">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Jalur
                </label>
                <label className="col-md-3">{bookingHObj.TransportType}</label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Tanggal Booking
                </label>
                <label className="col-md-3">
                  {dateTimeParser(bookingHObj.BookDt)}
                </label>
              </div>
              <div className="row">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Nama Pengirim
                </label>
                <label className="col-md-3">{bookingHObj.CustName}</label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Jenis Pembayaran
                </label>
                <label className="col-md-3">{bookingHObj.PaymentType}</label>
              </div>
              <div className="row">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Nama Penerima
                </label>
                <label className="col-md-3">{bookingHObj.RecipientName}</label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Status Booking
                </label>
                <label className="col-md-3">{bookingHObj.BookStatDescr}</label>
              </div>
            </div>
            <div className="container mx-auto rounded-md shadow border my-2">
              <div className="row rounded-sm flex flex-row bg-blue-300  px-3 py-1">
                <label className="font-bold pt-1">Main Info</label>
              </div>
              <div className="row pt-2">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Alias Pengirim
                </label>
                <label className="col-md-3">{bookingHObj.AliasName}</label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  No Referensi Pengirim
                </label>
                <label className="col-md-3">{bookingHObj.CustRefNo}</label>
              </div>
              <div className="row">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Nama Pengirim
                </label>
                <label className="col-md-3">{bookingHObj.CustName}</label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  No Handphone
                </label>
                <label className="col-md-3">
                  {bookingHObj.CustMobilePhoneNo}
                </label>
              </div>
              <div className="row">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Tujuan
                </label>
                <label className="col-md-3">{bookingHObj.Destination}</label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Jenis Barang
                </label>
                <label className="col-md-3">
                  {bookingHObj.PackageCategory}
                </label>
              </div>
              <div className="row">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Isi Barang
                </label>
                <label className="col-md-3">{bookingHObj.PackageContent}</label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Catatan Isi Paket
                </label>
                <label className="col-md-3">
                  {bookingHObj.PackageContentNotes}
                </label>
              </div>
              <div className="row">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Insurance
                </label>
                <label className="col-md-3">{bookingHObj.InsInfo}</label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Catatan Booking
                </label>
                <label className="col-md-3">{bookingHObj.BookNotes}</label>
              </div>
              <div className="row">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Last Tracking
                </label>
                <label className="col-md-6">
                  {dateTimeParser(bookingHObj.LastTrackInputDt)} |{" "}
                  {bookingHObj.LastTrackNotes}
                </label>
              </div>
            </div>
            <div className="container mx-auto rounded-md shadow border my-2">
              <div className="row rounded-sm flex flex-row bg-blue-300  px-3 py-1">
                <label className="font-bold pt-1">Info Penerima</label>
              </div>
              <div className="row pt-2">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Penerima
                </label>
                <label className="col-md-3">{bookingHObj.RecipientName}</label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  No Handphone
                </label>
                <label className="col-md-3">
                  {bookingHObj.RecipientMblPhoneNo}
                </label>
              </div>
              <div className="row">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Alamat Penerima
                </label>
                <label className="col-md-6">{bookingHObj.RecipientAddr}</label>
              </div>
            </div>
            <div className="container mx-auto rounded-md shadow border my-2">
              <div className="row rounded-sm flex flex-row bg-blue-300  px-3 py-1">
                <label className="font-bold pt-1">Info Pickup</label>
              </div>
              <div className="row pt-2">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  No Request Pickup
                </label>
                <label className="col-md-3">{bookingHObj.PickupReqNo}</label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Pickup Contact Person
                </label>
                <label className="col-md-3">
                  {bookingHObj.PickupContactPerson}
                </label>
              </div>
              <div className="row">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Alamat Pickup
                </label>
                <label className="col-md-3">{bookingHObj.PickupAddr}</label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  No. HP Pickup
                </label>
                <label className="col-md-3">{bookingHObj.PickupPhoneNo}</label>
              </div>
              <div className="row">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Tanggal Pickup
                </label>
                <label className="col-md-3">
                  {dateTimeParser(bookingHObj.PickupTime)}
                </label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Catatan Pickup
                </label>
                <label className="col-md-3">{bookingHObj.PickupNotes}</label>
              </div>
            </div>
            <div className="container mx-auto rounded-md shadow border my-2">
              <div className="row rounded-sm flex flex-row bg-blue-300  px-3 py-1">
                <label className="font-bold pt-1">Info Barang</label>
              </div>
              <TableDetailBarangV2
                arrTempBarang={bookingHObj.CustBookDs}
              />
            </div>
            <div className="container mx-auto rounded-md shadow border my-2">
              <div className="row rounded-sm flex flex-row bg-blue-300  px-3 py-1">
                <label className="font-bold pt-1">Summary</label>
              </div>
              <div className="row pt-2">
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Total Barang
                </label>
                <label className="col-md-3">
                  {new Intl.NumberFormat("en", {
                    minimumFractionDigits: 0,
                  }).format(bookingHObj.TotalItem)}
                </label>
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Estimasi Berat Asli (Kg)
                </label>
                <label className="col-md-3">
                  {new Intl.NumberFormat("en", {
                    minimumFractionDigits: 2,
                  }).format(bookingHObj.EstTotalWeight)}
                </label>
              </div>
              {false && (
                <>
                  <div className="row">
                    <label className="col-md-3" style={{ fontWeight: "bold" }}>
                      Estimasi Tagihan Awal
                    </label>
                    <label className="col-md-3">
                      {new Intl.NumberFormat("en", {
                        minimumFractionDigits: 0,
                      }).format(bookingHObj.EstMainChargeAmt)}
                    </label>
                    <label className="col-md-3" style={{ fontWeight: "bold" }}>
                      Estimasi Tagihan Tambahan
                    </label>
                    <label className="col-md-3">
                      {new Intl.NumberFormat("en", {
                        minimumFractionDigits: 0,
                      }).format(bookingHObj.EstAddChargeAmt)}
                    </label>
                  </div>
                  <div className="row">
                    <label className="col-md-3" style={{ fontWeight: "bold" }}>
                      Estimasi Tagihan Ke Pengirim
                    </label>
                    <label className="col-md-3">
                      {new Intl.NumberFormat("en", {
                        minimumFractionDigits: 0,
                      }).format(bookingHObj.EstSndrMustBePaidAmt)}
                    </label>
                    <label className="col-md-3" style={{ fontWeight: "bold" }}>
                      Estimasi Tagihan Ke Penerima
                    </label>
                    <label className="col-md-3">
                      {new Intl.NumberFormat("en", {
                        minimumFractionDigits: 0,
                      }).format(bookingHObj.EstRcpntMustBePaidAmt)}
                    </label>
                  </div>
                </>
              )}
              <div className="row">
                {false && (
                  <>
                    <label className="col-md-3" style={{ fontWeight: "bold" }}>
                      Estimasi Total Tagihan
                    </label>
                    <label className="col-md-3">
                      {new Intl.NumberFormat("en", {
                        minimumFractionDigits: 0,
                      }).format(bookingHObj.EstMustBePaidAmt)}
                    </label>
                  </>
                )}
                <label className="col-md-3" style={{ fontWeight: "bold" }}>
                  Estimasi Harga Barang
                </label>
                <label className="col-md-3">
                  {new Intl.NumberFormat("en", {
                    minimumFractionDigits: 0,
                  }).format(bookingHObj.EstItemPriceAmt)}
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="block sm:hidden w-full">
        {bookingHObj && (
          <>
            <div className="flex flex-row bg-blue-600 text-white">
              <h4 className="w-full text-center text-xl font-semibold  py-2">
                Booking View
              </h4>
            </div>
            <div className="h-full w-full flex items-center border-b-2 border-gray-300 px-3 py-2 text-xs sm:text-sm">
              <div className="text-xs sm:text-sm w-full">
                <div className="flex">
                  <div className="flex-1 text-xs sm:text-sm flex items-end">
                    Nomor Resi
                  </div>
                  <div className="flex-1 text-right text-xs sm:text-sm">
                    {dateTimeParser(bookingHObj.BookDt)}
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-1">
                    <p className="flex items-center font-bold pr-1">
                      {bookingHObj.ResiNo}
                    </p>
                    <div className="flex items-center">
                      <FaRegCopy
                        onClick={(e) => copyToClipboard(e, bookingHObj.ResiNo)}
                        style={{ display: "inline-block" }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-1 justify-end">
                    <div className="flex items-center">
                      <div
                        className={`w-2.5 h-2.5 rounded-full ${bookingHObj.BookStat === "ACT" ||
                          bookingHObj.BookStat === "PCK"
                          ? "bg-green-600"
                          : "bg-red-600"
                          }`}
                      ></div>
                    </div>
                    <p
                      className={`flex items-center font-semibold text-xs sm:text-sm ml-1 ${bookingHObj.BookStat === "ACT" ||
                        bookingHObj.BookStat === "PCK"
                        ? "text-green-600"
                        : "text-red-600"
                        }`}
                    >
                      {bookingHObj.BookStatDescr}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded px-2 py-3">
              <div
                className="h-full w-full bg-white shadow flex rounded items-center"
                key={bookingHObj.CustBookHId}
              >
                <div className="relative h-full w-full flex items-center">
                  <div className="text-xs sm:text-sm font-medium w-full px-2 py-2">
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-end">
                        Nomor Resi
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.ResiNo}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-end">
                        Tanggal Booking
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {dateTimeParser(bookingHObj.BookDt)}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-end">
                        Kantor Asal
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.OriginDestCode}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Tujuan
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.Destination}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Jalur
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.TransportType}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Jenis Pembayaran
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.PaymentType}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Reference No
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.CustRefNo}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Last Tracking
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {dateTimeParser(bookingHObj.LastTrackInputDt)} |{" "}
                        {bookingHObj.LastTrackNotes}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Estimasi Tiba
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.EstSentDate != null
                          ? dateTimeParser(bookingHObj.EstSentDate)
                          : "-"}
                      </div>
                    </div>
                    <div className="flex px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Catatan Booking
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.BookNotes}
                      </div>
                    </div>

                    <div className="flex px-2 py-2">
                      <div className="flex-1 text-sm sm:text-md flex items-center font-bold">
                        Informasi Penerima
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Nama
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.RecipientName}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        No Handphone
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.RecipientMblPhoneNo}
                      </div>
                    </div>
                    <div className="flex px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Alamat
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.RecipientAddr}
                      </div>
                    </div>

                    <div className="flex px-2 py-2">
                      <div className="flex-1 text-sm sm:text-md flex items-center font-bold">
                        Informasi Pickup
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Nomor Request Pickup
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.PickupReqNo}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Contact Person
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.PickupContactPerson}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Alamat
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.PickupAddr}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Nomor Ponsel
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.PickupPhoneNo}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Tanggal Pickup
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.PickupTime != null
                          ? dateTimeParser(bookingHObj.PickupTime)
                          : "-"}
                      </div>
                    </div>
                    <div className="flex px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Catatan
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.PickupNotes}
                      </div>
                    </div>

                    <div className="flex px-2 py-2">
                      <div className="flex-1 text-sm sm:text-md flex items-center font-bold">
                        Informasi Barang
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Jenis Barang
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.PackageCategory}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Isi Barang
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.PackageContent}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Catatan Barang
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.PackageContentNotes}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Asuransi
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {bookingHObj.InsInfo}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Total Barang
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {new Intl.NumberFormat("en", {
                          minimumFractionDigits: 0,
                        }).format(bookingHObj.TotalItem)}
                      </div>
                    </div>
                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Estimasi Berat
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {new Intl.NumberFormat("en", {
                          minimumFractionDigits: 2,
                        }).format(bookingHObj.EstTotalWeight)}{" "}
                        kg
                      </div>
                    </div>
                    {false && (
                      <>
                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                          <div className="flex-1 text-xs sm:text-sm flex items-center">
                            Estimasi Tagihan Awal
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                            {new Intl.NumberFormat("en", {
                              minimumFractionDigits: 0,
                            }).format(bookingHObj.EstMainChargeAmt)}
                          </div>
                        </div>
                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                          <div className="flex-1 text-xs sm:text-sm flex items-center">
                            Estimasi Tagihan Tambahan
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                            {new Intl.NumberFormat("en", {
                              minimumFractionDigits: 0,
                            }).format(bookingHObj.EstAddChargeAmt)}
                          </div>
                        </div>
                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                          <div className="flex-1 text-xs sm:text-sm flex items-center">
                            Estimasi Tagihan Ke Pengirim
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                            {new Intl.NumberFormat("en", {
                              minimumFractionDigits: 0,
                            }).format(bookingHObj.EstSndrMustBePaidAmt)}
                          </div>
                        </div>
                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                          <div className="flex-1 text-xs sm:text-sm flex items-center">
                            Estimasi Tagihan Ke Penerima
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                            {new Intl.NumberFormat("en", {
                              minimumFractionDigits: 0,
                            }).format(bookingHObj.EstRcpntMustBePaidAmt)}
                          </div>
                        </div>
                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                          <div className="flex-1 text-xs sm:text-sm flex items-center">
                            Estimasi Total Tagihan
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                            {new Intl.NumberFormat("en", {
                              minimumFractionDigits: 0,
                            }).format(bookingHObj.EstMustBePaidAmt)}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="flex px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex items-center">
                        Estimasi Harga Barang
                      </div>
                      <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                        {new Intl.NumberFormat("en", {
                          minimumFractionDigits: 0,
                        }).format(bookingHObj.EstItemPriceAmt)}
                      </div>
                    </div>
                    <div className="flex py-2 justify-center">
                      <button
                        type="button"
                        className="flex px-2 py-1 justify-center border-2 border-gray-400 shadow-md border-transparent font-medium rounded-md"
                        onClick={() =>
                          setIsShowDetailBarang(!isShowDetailBarang)
                        }
                      >
                        {isShowDetailBarang == true
                          ? "Hide Detail Barang"
                          : "Lihat Detail Barang"}
                      </button>
                    </div>
                    {isShowDetailBarang == true && bookingHObj.CustBookDs && (
                      <>
                        <TableDetailBarangV2
                          arrTempBarang={bookingHObj.CustBookDs}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default BookingViewH;
