import { profileConstants } from "../_constants";
import { profileService } from "../_services";

export const profileActions = {
  getProfileData,
  getListCustPointHistTrx,
  getListCustPointRedemptionTrx,
  getQrCodeMember,
  clearQrCodeMember,
  sendEmailQrCodeCust
};

function getProfileData(custId) {
  return (dispatch) => {
    dispatch(request({ custId }));
    profileService.getProfileData(custId).then(
      (profileData) => {
        dispatch(success(profileData));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(profileData) {
    return {
      type: profileConstants.PROFILE_GET_DATA_REQUEST,
      profileData,
    };
  }
  function success(profileData) {
    return {
      type: profileConstants.PROFILE_GET_DATA_SUCCESS,
      profileData,
    };
  }
  function failure(error) {
    return { type: profileConstants.PROFILE_GET_DATA_FAILURE, error };
  }
} 

function getListCustPointHistTrx(getListCustPointHistTrxObj) {
  return (dispatch) => {
    dispatch(request({ getListCustPointHistTrxObj }));
    profileService.getListCustPointHistTrx(getListCustPointHistTrxObj).then(
      (custPointHistTrx) => {
        dispatch(success(custPointHistTrx));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(custPointHistTrx) {
    return {
      type: profileConstants.CUST_POINT_HIST_GET_REQUEST,
      custPointHistTrx,
    };
  }
  function success(custPointHistTrx) {
    return {
      type: profileConstants.CUST_POINT_HIST_GET_SUCCESS,
      custPointHistTrx,
    };
  }
  function failure(error) {
    return { type: profileConstants.CUST_POINT_HIST_GET_FAILURE, error };
  }
} 

function getListCustPointRedemptionTrx(getListCustPointRedemptionTrxObj) {
  return (dispatch) => {
    dispatch(request({ getListCustPointRedemptionTrxObj }));
    profileService.getListCustPointRedemptionTrx(getListCustPointRedemptionTrxObj).then(
      (custPointRedemptionTrx) => {
        dispatch(success(custPointRedemptionTrx));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(custPointRedemptionTrx) {
    return {
      type: profileConstants.CUST_POINT_REDEMPTION_GET_REQUEST,
      custPointRedemptionTrx,
    };
  }
  function success(custPointRedemptionTrx) {
    return {
      type: profileConstants.CUST_POINT_REDEMPTION_GET_SUCCESS,
      custPointRedemptionTrx,
    };
  }
  function failure(error) {
    return { type: profileConstants.CUST_POINT_REDEMPTION_GET_FAILURE, error };
  }
} 

function getQrCodeMember(getQrCodeMemberObj) {
  return (dispatch) => {
    dispatch(request({ getQrCodeMemberObj }));
    profileService.getQrCodeMember(getQrCodeMemberObj).then(
      (getQrCodeMemberObj) => {
        dispatch(success(getQrCodeMemberObj));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(getQrCodeMemberObj) {
    return {
      type: profileConstants.GET_QR_CODE_REQUEST,
      getQrCodeMemberObj,
    };
  }
  function success(getQrCodeMemberObj) {
    return {
      type: profileConstants.GET_QR_CODE_SUCCESS,
      getQrCodeMemberObj,
    };
  }
  function failure(error) {
    return { type: profileConstants.GET_QR_CODE_FAILURE, error };
  }
} 

function clearQrCodeMember() {
  return dispatch => {
      dispatch(clear());
      if (typeof localStorage !== 'undefined') {
          localStorage.removeItem('getQrCodeMemberObj');
      }
  };

  function clear() { return { type: profileConstants.GET_QR_CODE_CLEAR } }
}

function sendEmailQrCodeCust(sendEmailQrCodeCustObj) {
  return (dispatch) => {
    dispatch(request({ sendEmailQrCodeCustObj }));
    profileService.sendEmailQrCodeCust(sendEmailQrCodeCustObj).then(
      (sendEmailQrCodeCustObj) => {
        dispatch(success(sendEmailQrCodeCustObj));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(sendEmailQrCodeCustObj) {
    return {
      type: profileConstants.SEND_QR_CODE_REQUEST,
      sendEmailQrCodeCustObj,
    };
  }
  function success(sendEmailQrCodeCustObj) {
    return {
      type: profileConstants.SEND_QR_CODE_SUCCESS,
      sendEmailQrCodeCustObj,
    };
  }
  function failure(error) {
    return { type: profileConstants.SEND_QR_CODE_FAILURE, error };
  }
} 