import React, { useState, useEffect } from "react";
import { profileActions } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Pagination
} from "../../components";
import { FaRegCopy } from "react-icons/fa";

const ProfileCustPointHist = ({ t }) => {
  const acc = useSelector((state) => state.authentication.accInfo);
  const dispatch = useDispatch();
  const custPointHistTrx = useSelector(
    (state) => state.custPointHistTrx.custPointHistTrx
  );

  const [totalRecord, setTotalRecord] = useState(0);
  const [loadMore, setLoadmore] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: "TrxDt",
    direction: "Desc",
  });
  let [num, setNum] = useState(1);
  let [cur, setCur] = useState(1);

  useEffect(() => {
    console.log("TEST");
    setCur(1);
    setNum(1);
    setTotalRecord(0);
    setLoadmore(1);
    callAPI(1, rowPerPage);
  }, []);

  function callAPI(pageNo, rowPerPages) {
    var reqObj = {
      CustId: acc.CustId,
      RowPerPage: rowPerPages,
      PageNo: pageNo,
      OrderBy: sortConfig,
    };
    dispatch(profileActions.getListCustPointHistTrx(reqObj));
  }

  useEffect(() => {
    if (totalRecord > 0) {
      callAPI(1, loadMore * rowPerPage);
    }
  }, [loadMore]);

  useEffect(() => {
    if (totalRecord > 0) {
      callAPI(cur, rowPerPage);
    }
  }, [cur]);

  useEffect(() => {
    if (custPointHistTrx && custPointHistTrx.TotalRecord) {
      console.log(custPointHistTrx);
      setTotalRecord(custPointHistTrx.TotalRecord);
    }
  }, [custPointHistTrx]);

  function copyToClipboard(e, value) {
    e.stopPropagation();
    navigator.clipboard.writeText(value);
  }

  return (
    <div>
      {custPointHistTrx && custPointHistTrx.Result && (
        <>
          {custPointHistTrx.Result.length === 0 && (
            <p className="flex justify-center">Tidak ada ada data</p>
          )}
          {custPointHistTrx.Result.length > 0 && (
            <>
              <div className="hidden sm:block px-4 mx-auto">
                <table
                  className="table table-striped table-fixed bg-white"
                  aria-labelledby="tabelLabel"
                >
                  <thead>
                    <tr className="font-bold">
                      <td className="text-center">Resi No</td>
                      <td className="text-right">Points</td>
                      <td className="text-right">Incentive/Kg Amount</td>
                      <td className="text-right">Incentive Amount</td>
                      <td className="text-center">Transaction Date</td>
                      <td>Transaction Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {custPointHistTrx.Result.map((cpht) => (
                      <tr key={cpht.CustPointHistTrxId}>
                        <td className="text-center">
                          <Link
                            target={"_blank"}
                            to={{
                              pathname: `/ResiHView?resiHId=${cpht.ResiHId}`,
                            }}
                          >
                            {cpht.ResiHNo}
                          </Link>
                        </td>
                        <td className="text-right">{cpht.Points}</td>
                        <td className="text-right">{cpht.IncentivePerKgAmt.toLocaleString("en", { minimumFractionDigits: 2 })}</td>
                        <td className="text-right">{cpht.IncentiveAmt.toLocaleString("en", { minimumFractionDigits: 2 })}</td>
                        <td className="text-center">
                          {moment(cpht.TrxDt).format("DD-MM-YY HH:mm")}
                        </td>
                        <td>{cpht.TrxStat}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="block sm:hidden">
                <div className="rounded px-3">
                  {custPointHistTrx.Result.map((cpht) => (
                    <div
                      className="h-full w-full bg-white shadow flex rounded items-center my-2"
                      key={cpht.CustPointHistTrxId}
                    >
                      <div className="relative h-full w-full flex items-center">
                        <div className="text-xs font-medium w-full pt-2">
                          <div className="flex px-3 pb-1">
                            <div className="flex flex-1 justify-end mr-1">
                              <div className="flex items-center">
                                <div className={`w-2.5 h-2.5 rounded-full ${cpht.TrxStat === "ACT" ? 'bg-green-600' : 'bg-red-600'}`}></div>
                              </div>
                              <p className={`flex items-center font-semibold text-xs ml-1 ${cpht.TrxStat === "ACT" ? 'text-green-600' : 'text-red-600'}`}>
                                {cpht.TrxStat === "ACT" ? "Aktif" : "Cancel"}</p>
                            </div>
                          </div>
                          <div className="flex px-3 pb-1">
                            <div className="flex-1 text-xs flex items-end">
                              <div className="flex flex-1">
                                <p className="flex items-center font-bold pr-1">Resi No</p>
                                <div className="flex items-center">
                                  <FaRegCopy
                                    onClick={(e) => copyToClipboard(e, cpht.ResiHNo)}
                                    style={{ display: "inline-block" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 justify-end mr-1 text-right">
                              {cpht.ResiHNo}
                            </div>
                          </div>
                          <div className="flex px-3 pb-1">
                            <div className="flex-1 text-xs flex font-bold items-end">
                              Points
                            </div>
                            <div className="flex flex-1 justify-end mr-1 text-right">
                              {cpht.Points}
                            </div>
                          </div>
                          <div className="flex px-3 pb-1">
                            <div className="flex-1 text-xs flex font-bold items-end">
                              Incentive/Kg Amount
                            </div>
                            <div className="flex flex-1 justify-end mr-1 text-right">
                              {cpht.IncentivePerKgAmt.toLocaleString("en", { minimumFractionDigits: 2 })}
                            </div>
                          </div>
                          <div className="flex px-3 pb-1">
                            <div className="flex-1 text-xs flex font-bold items-end">
                              Incentive Amount
                            </div>
                            <div className="flex flex-1 justify-end mr-1 text-right">
                              {cpht.IncentiveAmt.toLocaleString("en", { minimumFractionDigits: 2 })}
                            </div>
                          </div>
                          <div className="flex px-3 pb-1">
                            <div className="flex-1 text-xs flex font-bold items-end">
                              Transaction Date
                            </div>
                            <div className="flex flex-1 justify-end mr-1 text-right">
                              {moment(cpht.TrxDt).format("DD-MM-YY HH:mm")}
                            </div>
                          </div>
                        </div>
                        <span
                          className={
                            cpht.TrxStat === "ACT"
                              ? "absolute bg-green-600 right-0 h-full rounded-r w-3"
                              : "absolute bg-red-600 right-0 h-full rounded-r w-3"
                          }
                        ></span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <Pagination
                cur={cur}
                setCur={setCur}
                loadMore={loadMore}
                setLoadmore={setLoadmore}
                rowPerPage={rowPerPage}
                totalRecord={totalRecord}
                num={num}
                setNum={setNum}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
export default ProfileCustPointHist;
