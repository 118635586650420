import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pickupActions } from "../../_actions";
import { useLocation } from "react-router-dom";
import {
  Pagination,
  AlertMessage,
  ShortcutDate,
  PickupReqMonitoringTable,
} from "../../components";
import { masterSettingConstants } from "../../_constants";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

const PickupReqMonitoring = ({ t }) => {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  const acc = useSelector((state) => state.authentication.accInfo);

  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [shortcut, setShortcut] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loadMore, setLoadmore] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(99);
  let [num, setNum] = useState(1);
  let [cur, setCur] = useState(1);
  let date = new Date();
  const [sortConfig, setSortConfig] = useState({
    key: "RequestDt",
    direction: "DESC",
  });

  const maxDate = new Date();
  const minDate = new Date();
  const finalMinDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);

  const _trxDtFrom = query.get("trxDtFrom");
  const _trxDtTo = query.get("trxDtTo");
  const _process = query.get("process");
  const _pickupReqGrpId = query.get("pickupReqGrpId");
  const [pickupReqMonitoring, setPickupReqMonitoring] = useState({
    trxDtFrom: _trxDtFrom ? _trxDtFrom : dateParser(finalMinDate),
    trxDtTo: _trxDtTo ? _trxDtTo : dateParser(maxDate),
    process: _process ? _process : "",
    pickupReqGrpId: _pickupReqGrpId ? _pickupReqGrpId : "",
  });
  const [enabled, setEnabled] = useState(false);
  const [alert, setAlert] = useState({
    Type: "",
    StatusMessage: "",
  });

  function dateParser(value) {
    const date = new Date(value);
    const dateParsed =
      date.getFullYear() +
      "-" +
      date.toLocaleString("en-us", { month: "2-digit" }) +
      "-" +
      date.toLocaleString("en-us", { day: "2-digit" });
    return dateParsed;
  }

  const getDate = (dateObj) => {
    setPickupReqMonitoring({
      trxDtFrom: dateObj.dateFrom,
      trxDtTo: dateObj.dateTo,
      process: pickupReqMonitoring.process,
      pickupReqGrpId: pickupReqMonitoring.pickupReqGrpId
    });
    setShortcut(true);
    setCur(1);
    setNum(1);
    setTotalRecord(0);
    setLoadmore(1);
  };

  const pickupReqMonitoringObj = useSelector(
    (state) => state.pickupReqMonitoring.pickupReqMonitoringObj
  );
  const errPickupReqMonitoringObj = useSelector(
    (state) => state.pickupReqMonitoring.errPickupReqMonitoringObj
  );

  function dateParser(value) {
    const date = new Date(value);
    const dateParsed =
      date.getFullYear() +
      "-" +
      date.toLocaleString("en-us", { month: "2-digit" }) +
      "-" +
      date.toLocaleString("en-us", { day: "2-digit" });
    return dateParsed;
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setPickupReqMonitoring((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleSearch(e) {
    e.preventDefault();
    setNum(1);
    setCur(1);
    setTotalRecord(0);
    setLoadmore(1);
    setSubmitted(true);
    callPickupMonitoring(1, rowPerPage);
  }

  function callPickupMonitoring(pageNo, rowPerPages) {
    if (
      pickupReqMonitoring.trxDtFrom &&
      pickupReqMonitoring.trxDtTo &&
      acc != undefined
    ) {
      let pickupReqMonitoringObj = {
        CustId: acc.CustId,
        TrxDtFrom: pickupReqMonitoring.trxDtFrom,
        TrxDtTo: pickupReqMonitoring.trxDtTo,
        Process: pickupReqMonitoring.process,
        PickupReqGrpId: pickupReqMonitoring.pickupReqGrpId,
        RowPerPage: rowPerPages,
        PageNo: pageNo,
        OrderBy: sortConfig,
      };
      dispatch(pickupActions.pickupReqMonitoring(pickupReqMonitoringObj));
    }
  }

  useEffect(() => {
    if (
      totalRecord > 0 &&
      pickupReqMonitoring.trxDtFrom &&
      pickupReqMonitoring.trxDtTo
    ) {
      callPickupMonitoring(cur, rowPerPage);
    }
  }, [cur]);

  useEffect(() => {
    if (sortConfig != null && sortConfig != undefined) {
      if (
        totalRecord > 0 &&
        pickupReqMonitoring.trxDtFrom &&
        pickupReqMonitoring.trxDtTo
      ) {
        callPickupMonitoring(1, rowPerPage);
      }
    }
  }, [sortConfig]);

  useEffect(() => {
    if (shortcut) {
      callPickupMonitoring(cur, rowPerPage);
      setSubmitted(true);
      setShortcut(false);
    }
  }, [shortcut]);

  useEffect(() => {
    setSubmitted(false);
    setShortcut(false);
    setCur(1);
    setNum(1);
    setTotalRecord(0);
    setLoadmore(1);
    dispatch(pickupActions.clearPickupReqMonitoring());
    callPickupMonitoring(1, rowPerPage);
  }, []);

  useEffect(() => {
    if (totalRecord > 0) {
      callPickupMonitoring(1, loadMore * rowPerPage);
    }
  }, [loadMore]);

  useEffect(() => {
    if (
      errPickupReqMonitoringObj &&
      errPickupReqMonitoringObj.StatusCode !== "200"
    ) {
      setIsError(true);
      setAlert({
        Type: "Error",
        StatusMessage: errPickupReqMonitoringObj.StatusMessage,
      });
    }
  }, [errPickupReqMonitoringObj]);

  useEffect(() => {
    if (pickupReqMonitoringObj && pickupReqMonitoringObj.TotalRecord) {
      setTotalRecord(pickupReqMonitoringObj.TotalRecord);
    } else {
      setTotalRecord(0);
    }
  }, [pickupReqMonitoringObj]);

  return (
    <>
      <div className="hidden sm:block w-full max-w-6xl mx-auto">
        <div className="justify-center py-2 px-4 flex flex-row">
          <h1 className="font-bold text-xl">Pickup Monitoring</h1>
        </div>
        <div>
          <form onSubmit={handleSearch} method="POST">
            <div className="max-w-6xl mx-auto text-xs sm:text-sm font-medium">
              <div className="row px-2 py-1">
                <div className="col-md-3 text-xs sm:text-sm">
                  Tanggal Awal Transaksi
                </div>
                <div className="col-md-3 text-xs sm:text-sm">
                  <input
                    aria-label="Tanggal Awal Transaksi"
                    id="trxDtFrom"
                    name="trxDtFrom"
                    type="date"
                    value={pickupReqMonitoring.trxDtFrom}
                    className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                    onChange={handleChange}
                  />
                  {submitted && !pickupReqMonitoring.trxDtFrom && (
                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                      Tidak boleh kosong
                    </div>
                  )}
                </div>
                <div className="col-md-3 text-xs sm:text-sm">
                  Tanggal Akhir Transaksi
                </div>
                <div className="col-md-3 text-xs sm:text-sm">
                  <input
                    aria-label="Tanggal Akhir Transaksi"
                    id="trxDtTo"
                    name="trxDtTo"
                    type="date"
                    value={pickupReqMonitoring.trxDtTo}
                    className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                    onChange={handleChange}
                  />
                  {submitted && !pickupReqMonitoring.trxDtTo && (
                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                      Tidak boleh kosong
                    </div>
                  )}
                </div>
              </div>
              <div className="row px-2 py-1">
                <div className="col-md-3 text-xs sm:text-sm">Process</div>
                <div className="col-md-3 text-xs sm:text-sm">
                  <select
                    className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                    id="process"
                    name="process"
                    value={pickupReqMonitoring.process}
                    onChange={handleChange}
                  >
                    <option value="">Semua</option>
                    <option value={masterSettingConstants.PROCESS_NEW}>
                      New
                    </option>
                    <option value={masterSettingConstants.PROCESS_ONPROCESS}>
                      On Process
                    </option>
                    <option value={masterSettingConstants.PROCESS_DONE}>
                      Done
                    </option>
                  </select>
                </div>
              </div>
              <div className="flex justify-end mb-2 -mt-2">
                <ShortcutDate onSubmit={getDate} />
              </div>
              <div className="flex justify-end mb-2 -mt-4">
                <button
                  type="submit"
                  className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="block sm:hidden w-full">
        <div className="bg-blue-600">
          <h4 className="flex justify-center text-xl font-semibold text-white py-2">
            Pickup Monitoring
          </h4>
        </div>
        <form onSubmit={handleSearch} method="POST">
          <div className="flex flex-col text-xs sm:text-sm px-3">
            <div className="flex flex-row font-bold pt-2">
              <div className="flex-1 text-xs sm:text-sm flex items-center">
                Filter dari Tanggal
              </div>
              <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                sampai Tanggal
              </div>
            </div>
            <div className="flex flex-row mt-1">
              <div className="flex-1 text-xs sm:text-sm flex items-center">
                <input
                  aria-label="Tanggal Awal Transaksi"
                  id="trxDtFrom"
                  name="trxDtFrom"
                  type="date"
                  value={pickupReqMonitoring.trxDtFrom}
                  className={`py-1 px-2 ${submitted && !pickupReqMonitoring.trxDtFrom
                      ? "border-red-500"
                      : "border-gray-300"
                    } text-xs sm:text-sm outline-none rounded-sm w-100`}
                  placeholder="Tanggal Awal Transaksi"
                  onChange={handleChange}
                />
              </div>
              <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                <input
                  aria-label="Tanggal Akhir Transaksi"
                  id="trxDtTo"
                  name="trxDtTo"
                  type="date"
                  value={pickupReqMonitoring.trxDtTo}
                  className={`py-1 px-2 ${submitted && !pickupReqMonitoring.trxDtTo
                      ? "border-red-500"
                      : "border-gray-300"
                    } text-xs sm:text-sm outline-none rounded-sm w-100`}
                  placeholder="Tanggal Akhir Transaksi"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="flex-1 text-xs sm:text-sm flex items-center">
                {submitted && !pickupReqMonitoring.trxDtFrom && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 ml-4 py-1">
                    Isi tanggal awal pickup
                  </div>
                )}
              </div>
              <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                {submitted && !pickupReqMonitoring.trxDtTo && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                    Isi tanggal akhir pickup
                  </div>
                )}
              </div>
            </div>
            {enabled === true && (
              <>
                <div className="flex flex-row mt-1 font-bold">
                  <div className="flex-1 text-xs sm:text-sm flex items-center">
                    Process
                  </div>
                </div>
                <div className="flex flex-row mt-1">
                  <div className="flex-1 text-xs sm:text-sm flex items-center">
                    <select
                      className="py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none rounded-sm w-100"
                      id="process"
                      name="process"
                      value={pickupReqMonitoring.process}
                      onChange={handleChange}
                    >
                      <option value="">Semua</option>
                      <option value={masterSettingConstants.PROCESS_NEW}>
                        New
                      </option>
                      <option value={masterSettingConstants.PROCESS_ONPROCESS}>
                        On Process
                      </option>
                      <option value={masterSettingConstants.PROCESS_DONE}>
                        Done
                      </option>
                    </select>
                  </div>
                  <div className="flex flex-1"></div>
                </div>
              </>
            )}
          </div>

          <div className="flex">
            <div className="flex flex-1 flex-col text-xs sm:text-sm items-center">
              <button
                className="text-red-600 px-3 pt-2"
                onClick={(e) => setEnabled(!enabled)}
              >
                {enabled === true ? (
                  <>
                    Hide Search
                    <FaCaretUp
                      className="mb-1 w-4 h-4"
                      style={{ display: "inline-block" }}
                    />
                  </>
                ) : (
                  <>
                    Advance Search
                    <FaCaretDown
                      className="mb-1 w-4 h-4"
                      style={{ display: "inline-block" }}
                    />
                  </>
                )}
              </button>
            </div>
          </div>
          <ShortcutDate onSubmit={getDate} />
          <div className="flex flex-col justify-center -mt-4">
            <button
              type="submit"
              className="mx-3 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
            >
              Search
            </button>
          </div>
        </form>
      </div>
      {isError == true && errPickupReqMonitoringObj && (
        <AlertMessage
          isError={isError}
          alert={alert}
          setAlert={setAlert}
          setIsError={setIsError}
        />
      )}
      {pickupReqMonitoringObj && pickupReqMonitoringObj.Result && (
        <>
          <PickupReqMonitoringTable
            sortConfig={sortConfig}
            setSortConfig={setSortConfig}
            process={pickupReqMonitoring.process}
            pickupReqGrpId={pickupReqMonitoring.pickupReqGrpId}
          />
        </>
      )}
    </>
  );
};

export default PickupReqMonitoring;
