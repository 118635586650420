import React, { useState, useEffect } from "react";
import { profileActions } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Pagination
} from "../../components";

const ProfileCustPointRedemption = ({ t }) => {
  const acc = useSelector((state) => state.authentication.accInfo);
  const dispatch = useDispatch();
  const custPointRedemptionTrx = useSelector(
    (state) => state.custPointRedemptionTrx.custPointRedemptionTrx
  );

  const [totalRecord, setTotalRecord] = useState(0);
  const [loadMore, setLoadmore] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: "TrxDt",
    direction: "Desc",
  });
  let [num, setNum] = useState(1);
  let [cur, setCur] = useState(1);

  useEffect(() => {
    console.log("TEST");
    setCur(1);
    setNum(1);
    setTotalRecord(0);
    setLoadmore(1);
    callAPI(1, rowPerPage);
  }, []);

  function callAPI(pageNo, rowPerPages) {
    var reqObj = {
      CustId: acc.CustId,
      RowPerPage: rowPerPages,
      PageNo: pageNo,
      OrderBy: sortConfig,
    };
    dispatch(profileActions.getListCustPointRedemptionTrx(reqObj));
  }

  useEffect(() => {
    if (totalRecord > 0) {
      callAPI(1, loadMore * rowPerPage);
    }
  }, [loadMore]);

  useEffect(() => {
    if (totalRecord > 0) {
      callAPI(cur, rowPerPage);
    }
  }, [cur]);

  useEffect(() => {
    if (custPointRedemptionTrx && custPointRedemptionTrx.TotalRecord) {
      console.log(custPointRedemptionTrx);
      setTotalRecord(custPointRedemptionTrx.TotalRecord);
    }
  }, [custPointRedemptionTrx]);

  return (
    <div>
      {custPointRedemptionTrx && custPointRedemptionTrx.Result && (
        <>
          {custPointRedemptionTrx.Result.length === 0 && (
            <p className="flex justify-center">Tidak ada ada data</p>
          )}
          {custPointRedemptionTrx.Result.length > 0 && (
            <>
              <div className="hidden sm:block px-4 mx-auto">
                <table
                  className="table table-striped table-fixed bg-white"
                  aria-labelledby="tabelLabel"
                >
                  <thead>
                    <tr className="font-bold">
                      <td className="text-right">Incentive Amount</td>
                      <td className="text-center">Redeem Date</td>
                      <td>Redeem Office Name</td>
                      <td>Cashier Name</td>
                      <td>Redeem By</td>
                      <td>Redeem Notes</td>
                    </tr>
                  </thead>
                  <tbody>
                    {custPointRedemptionTrx.Result.map((cprt) => (
                      <tr key={cprt.CustPointRedemptionTrxId}>
                        <td className="text-right">{cprt.IncentiveAmt}</td>
                        <td className="text-center">
                          {moment(cprt.RedeemDt).format("DD-MM-YY HH:mm")}
                        </td>
                        <td>{cprt.RedeemOfficeName}</td>
                        <td>{cprt.CashierName}</td>
                        <td>{cprt.RedeemBy}</td>
                        <td>{cprt.RedeemNotes}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="block sm:hidden">
                <div className="rounded px-3">
                  {custPointRedemptionTrx.Result.map((cprt) => (
                    <div
                      className="h-full w-full bg-white shadow flex rounded items-center my-2"
                      key={cprt.CustPointRedemptionTrxId}
                    >
                      <div className="relative h-full w-full flex items-center">
                        <div className="text-xs font-medium w-full pt-2">
                          <div className="flex px-3 pb-1">
                            <div className="flex-1 text-xs flex font-bold items-end">
                              Incentive Amount
                            </div>
                            <div className="flex flex-1 justify-end mr-1 text-right">
                              {cprt.IncentiveAmt}
                            </div>
                          </div>
                          <div className="flex px-3 pb-1">
                            <div className="flex-1 text-xs flex font-bold items-end">
                              Redeem Date
                            </div>
                            <div className="flex flex-1 justify-end mr-1 text-right">
                              {moment(cprt.RedeemDt).format("DD-MM-YY HH:mm")}
                            </div>
                          </div>
                          <div className="flex px-3 pb-1">
                            <div className="flex-1 text-xs flex font-bold items-end">
                              Redeem Office Name
                            </div>
                            <div className="flex flex-1 justify-end mr-1 text-right">
                              {cprt.RedeemOfficeName}
                            </div>
                          </div>
                          <div className="flex px-3 pb-1">
                            <div className="flex-1 text-xs flex font-bold items-end">
                              Cashier Name
                            </div>
                            <div className="flex flex-1 justify-end mr-1 text-right">
                              {cprt.CashierName}
                            </div>
                          </div>
                          <div className="flex px-3 pb-1">
                            <div className="flex-1 text-xs flex font-bold items-end">
                              Redeem By
                            </div>
                            <div className="flex flex-1 justify-end mr-1 text-right">
                              {cprt.RedeemBy}
                            </div>
                          </div>
                          <div className="flex px-3 pb-1">
                            <div className="flex-1 text-xs flex font-bold items-end">
                              Redeem Notes
                            </div>
                            <div className="flex flex-1 justify-end mr-1 text-right">
                              {cprt.RedeemNotes}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <Pagination
                cur={cur}
                setCur={setCur}
                loadMore={loadMore}
                setLoadmore={setLoadmore}
                rowPerPage={rowPerPage}
                totalRecord={totalRecord}
                num={num}
                setNum={setNum}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
export default ProfileCustPointRedemption;
