import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment";
import "../../styles/tailwind.css";
import { FaRegCopy } from "react-icons/fa";
import axios from "axios";
import { urlConstants } from "../../_constants/url.constants";

const PickupRequestView = ({ t }) => {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  const dispatch = useDispatch();
  const [webPickupResultPhotos, setWebPickupResultPhotos] = useState({});
  const [mobilePickupResultPhotos, setMobilePickupResultPhotos] = useState({});
  const [webPickupSummaryPhotos, setWebPickupSummaryPhotos] = useState({});
  const [mobilePickupSummaryPhotos, setMobilePickupSummaryPhotos] = useState({});
  const [webPickupReqPhotos, setWebPickupReqPhotos] = useState({});
  const [mobilePickupReqPhotos, setMobilePickupReqPhotos] = useState({});

  const [pickupReqView, setPikcupReqView] = useState();
  const client = axios.create({
    baseURL: urlConstants.URL_PICKUP_REQUEST_VIEW,
  });
  const [isOpen, setIsOpen] = useState("pickupOrder");

  function viewInfo(view) {
    setIsOpen(view);
  }

  useEffect(() => {
    getPickupReqViewData({
      PickupReqId: query.get("pickupReqId"),
      PickupReqNo: query.get("pickupReqNo")
    });
  }, []);

  const getPickupReqViewData = async (requestObj) => {
    try {
      let result = await client.post("", requestObj);
      setPikcupReqView(result.data.ObjectResult);
    } catch (error) {
      console.log(error);
    }
  };

  function copyToClipboard(e, pickupReqNo) {
    e.stopPropagation();
    navigator.clipboard.writeText(pickupReqNo);
  }

  return (
    <div>
      <div className="hidden sm:block">
        <div className="px-3">
          <div className="justify-center flex flex-row">
            <h1 className="font-bold text-lg">PICKUP REQUEST VIEW</h1>
          </div>
          <div className="justify-center py-3 text-xs sm:text-sm">
            {pickupReqView && (
              <>
                <div className="row">
                  <label className="col-md-2 font-bold">No Pickup</label>
                  <label className="col-md-2">
                    {pickupReqView.PickupReqNo}
                  </label>
                  <label className="col-md-2">
                    {pickupReqView.PickupReqSrc}
                  </label>
                  <label className="col-md-2 font-bold">Requestor</label>
                  <label className="col-md-4">{pickupReqView.Requestor}</label>
                </div>
                <div className="row">
                  <label className="col-md-2 font-bold">Contact Person</label>
                  <label className="col-md-4">
                    {pickupReqView.ContactPerson}
                  </label>
                  <label className="col-md-2 font-bold">No Handphone</label>
                  <label className="col-md-4">{pickupReqView.PhoneNo}</label>
                </div>
                <div className="row">
                  <label className="col-md-2 font-bold">Alamat</label>
                  <label className="col-md-9 whitespace-normal break-words">
                    {pickupReqView.Addr}
                  </label>
                </div>
                <div className="row">
                  <label className="col-md-2 font-bold">
                    Estimasi Berat (Kg)
                  </label>
                  <label className="col-md-4">{pickupReqView.EstWeight}</label>
                  <label className="col-md-2 font-bold">Jumlah Barang</label>
                  <label className="col-md-4">{pickupReqView.TotalItem}</label>
                </div>
                <div className="row">
                  <label className="col-md-2 font-bold">
                    Catatan Request Pickup
                  </label>
                  <label className="col-md-9 whitespace-normal break-words">
                    {pickupReqView.PickupNotes}
                  </label>
                </div>
                <div className="py-3">
                  <hr />
                </div>
                <div className="row">
                  <label className="col-md-2 font-bold">
                    Percobaan Pickup Ke
                  </label>
                  <label className="col-md-4">
                    {pickupReqView.PickupAttempt}
                  </label>
                </div>
                <div className="row">
                  <label className="col-md-2 font-bold">Status Request</label>
                  <label className="col-md-4">
                    {pickupReqView.PickupReqStat}
                  </label>
                  <label className="col-md-2 font-bold">Hasil Pickup</label>
                  <label className="col-md-4"></label>
                </div>
                <div className="row">
                  <label className="col-md-2 font-bold">
                    Catatan Hasil Pickup
                  </label>
                  <label className="col-md-4"></label>
                </div>
                <div className="py-3">
                  <hr />
                </div>
                <div>
                  {pickupReqView.ListPickupResiNo &&
                    pickupReqView.ListPickupResiNo.length > 0 && (
                      <table
                        className="table table-striped"
                        aria-labelledby="tabelLabel"
                      >
                        <thead>
                          <tr>
                            <th className="text-center">No Resi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pickupReqView.ListPickupResiNo.map((lprn) => (
                            <tr key={lprn}>
                              <td align="center">{lprn}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                </div>
              </>
            )}
          </div>
          <div className="justify flex flex-row py-3 text-xs sm:text-sm">
            <button
              type="button"
              onClick={() => viewInfo("pickupOrder")}
              className="flex-grow group relative inline-flex justify-center items-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              Pickup Order
            </button>
            <button
              type="button"
              onClick={() => viewInfo("fotoPickupRequest")}
              className="flex-grow group relative inline-flex justify-center items-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              Foto Pickup Request
            </button>
            <button
              type="button"
              onClick={() => viewInfo("pickupTracking")}
              className="flex-grow group relative inline-flex justify-center items-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              Pickup Tracking
            </button>
          </div>
          <div className="py-3 text-xs sm:text-sm">
            {isOpen === "pickupOrder" && (
              <div>
                <div className="container mx-auto rounded-md shadow border my-2">
                  <div className="row rounded-sm flex flex-row bg-blue-200 px-3">
                    <label className="font-bold pt-1">Info Pickup</label>
                  </div>
                  {pickupReqView && pickupReqView.LastPickupOrder && (
                    <div className="my-2">
                      <div className="row">
                        <label className="col-md-2 font-bold">
                          Waktu Penugasan
                        </label>
                        <label className="col-md-4">
                          {moment(pickupReqView.LastPickupOrder?.OrderDt).format(
                            "DD MMM YY - HH:mm"
                          )}
                        </label>
                        <label className="col-md-2 font-bold">
                          Status Order
                        </label>
                        <label className="col-md-4">
                          {pickupReqView.LastPickupOrder.PickupOrderHStat}
                        </label>
                      </div>
                      <div className="row">
                        <label className="col-md-2 font-bold">PIC Kantor</label>
                        <label className="col-md-4">
                          {pickupReqView.LastPickupOrder.PICOfficeCode}
                        </label>
                        <label className="col-md-2 font-bold">Driver</label>
                        <label className="col-md-4">
                          {pickupReqView.LastPickupOrder.DriverName}
                        </label>
                      </div>
                      <div className="row">
                        <label className="col-md-2 font-bold">
                          Alamat Penugasan
                        </label>
                        <label className="col-md-4">
                          {pickupReqView.LastPickupOrder.Addr}
                        </label>
                        <label className="col-md-2 font-bold">
                          Contact Person
                        </label>
                        <label className="col-md-4">
                          {pickupReqView.LastPickupOrder.ContactPerson}
                        </label>
                      </div>
                      <div className="row">
                        <label className="col-md-2 font-bold">Catatan</label>
                        <label className="col-md-4">
                          {pickupReqView.LastPickupOrder.OrderNotes}
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="container mx-auto rounded-md shadow border my-2">
                  <div className="row rounded-sm flex flex-row bg-blue-200 px-3">
                    <label className="font-bold pt-1">Foto Proses Pickup</label>
                  </div>
                  {pickupReqView &&
                    pickupReqView.PickupResultPhotos &&
                    pickupReqView.PickupResultPhotos.map((prp, idx) => (
                      <div key={`WPickupResultPhotos${idx}`}>
                        <div className="my-2 flex flex-row">
                          <input
                            style={{ width: "30%" }}
                            type="image"
                            onClick={() => {
                              setWebPickupResultPhotos((prevStates) => ({
                                ...prevStates,
                                [idx]: !prevStates[idx]
                              }))
                            }}
                            src={prp.ImageFile}
                            alt="PickupResultPhoto"
                          ></input>
                          <div>
                            <label className="col-md-6 font-bold">
                              Tanggal Foto
                            </label>
                            <label className="col-md-6">:{prp.StoreDt}</label>
                            <label className="col-md-6 font-bold">
                              Diupload Oleh
                            </label>
                            <label className="col-md-6">:{prp.StoreBy}</label>
                          </div>
                        </div>
                        <Modal size="lg"
                          centered={true}
                          isOpen={webPickupResultPhotos[idx]}
                          toggle={() => {
                            setWebPickupResultPhotos((prevStates) => ({
                              ...prevStates,
                              [idx]: !prevStates[idx]
                            }))
                          }}
                        >
                          <ModalHeader toggle={() => {
                            setWebPickupResultPhotos((prevStates) => ({
                              ...prevStates,
                              [idx]: !prevStates[idx]
                            }))
                          }}>Detail</ModalHeader>
                          <ModalBody>
                            <div className="row">
                              <div className="col-md-4 flex flex-row justify-center">
                                <img
                                  src={prp.ImageFile}
                                />
                              </div>
                              <div className="col-md-8">
                                <label className="text-2xl col-md-12 font-bold">
                                  {prp.PickupReqNo}
                                </label>
                                <label className="text-sm col-md-12">
                                  {prp.Requestor}
                                </label>
                                <hr />
                                <label className="col-md-4">Tanggal Foto</label>
                                <label className="col-md-8">
                                  :{prp.StoreDt}
                                </label>
                                <label className="col-md-4">
                                  Diupload Oleh
                                </label>
                                <label className="col-md-8">
                                  :{prp.StoreBy}
                                </label>
                                <hr />
                                <label className="col-md-4">Lokasi Foto</label>
                                <label className="col-md-8">
                                  :{`${prp.Lattitude},${prp.Longitude}`}
                                </label>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </div>
                    ))}
                </div>
                <div className="container mx-auto rounded-md shadow border my-2">
                  <div className="row rounded-sm flex flex-row bg-blue-200 px-3">
                    <label className="font-bold pt-1">
                      Foto Summary Pickup
                    </label>
                  </div>
                  {pickupReqView &&
                    pickupReqView.PickupSummaryPhotos &&
                    pickupReqView.PickupSummaryPhotos.map((psp, idx) => (
                      <div key={`WPickupSummaryPhotos${idx}`}>
                        <div className="pt-3 flex flex-row">
                          <input
                            style={{ width: "30%" }}
                            type="image"
                            onClick={() => {
                              setWebPickupSummaryPhotos((prevStates) => ({
                                ...prevStates,
                                [idx]: !prevStates[idx]
                              }))
                            }}
                            src={psp.ImageFile}
                            alt="PickupSummaryPhoto"
                          ></input>
                          <div>
                            <label className="col-md-6 font-bold">
                              Tanggal Foto
                            </label>
                            <label className="col-md-6">:{psp.StoreDt}</label>
                            <label className="col-md-6 font-bold">
                              Diupload Oleh
                            </label>
                            <label className="col-md-6">:{psp.StoreBy}</label>
                          </div>
                        </div>
                        <Modal size="lg" isOpen={webPickupSummaryPhotos[idx]} toggle={() => {
                          setWebPickupSummaryPhotos((prevStates) => ({
                            ...prevStates,
                            [idx]: !prevStates[idx]
                          }))
                        }}>
                          <ModalHeader toggle={() => {
                            setWebPickupSummaryPhotos((prevStates) => ({
                              ...prevStates,
                              [idx]: !prevStates[idx]
                            }))
                          }}>Detail</ModalHeader>
                          <ModalBody>
                            <div className="row">
                              <div className="col-md-4 flex flex-row justify-center">
                                <img
                                  src={psp.ImageFile}
                                />
                              </div>
                              <div className="col-md-8">
                                <label className="text-2xl col-md-12 font-bold">
                                  {psp.PickupReqNo}
                                </label>
                                <label className="text-sm col-md-12">
                                  {psp.Requestor}
                                </label>
                                <hr />
                                <label className="col-md-4">Tanggal Foto</label>
                                <label className="col-md-8">
                                  :{psp.StoreDt}
                                </label>
                                <label className="col-md-4">
                                  Diupload Oleh
                                </label>
                                <label className="col-md-8">
                                  :{psp.StoreBy}
                                </label>
                                <hr />
                                <label className="col-md-4">Lokasi Foto</label>
                                <label className="col-md-8">
                                  :{`${psp.Lattitude},${psp.Longitude}`}
                                </label>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </div>
                    ))}
                </div>
                <div className="container mx-auto rounded-md shadow border my-2">
                  <div className="row rounded-sm flex flex-row bg-blue-200 px-3">
                    <label className="font-bold pt-1">
                      History Percobaan Pickup
                    </label>
                  </div>
                  {pickupReqView &&
                    pickupReqView.PickupOrderHistories &&
                    pickupReqView.PickupOrderHistories.length > 0 && (
                      <table
                        className="table table-auto table-striped bg-white"
                        aria-labelledby="tabelLabel"
                      >
                        <thead>
                          <tr>
                            <th>Kantor</th>
                            <th className="text-center">Tanggal Order</th>
                            <th>Diassign Oleh</th>
                            <th>Driver</th>
                            <th className="text-center">Tanggal Pickup</th>
                            <th>Status</th>
                            <th>Catatan</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pickupReqView.PickupOrderHistories.map((poh) => (
                            <tr key={poh.OrderDt}>
                              <td>{poh.OfficeCode}</td>
                              <td align="center">
                                {moment(poh.OrderDt).format("DD-MM-YY HH:mm")}
                              </td>
                              <td>{poh.AssignBy}</td>
                              <td>{poh.Driver}</td>
                              <td align="center">
                                {moment(poh.PickupDt).format("DD-MM-YY HH:mm")}
                              </td>
                              <td>{poh.PickupOrderHStat}</td>
                              <td>{poh.WorkNotes}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                </div>
              </div>
            )}
            {isOpen === "fotoPickupRequest" && (
              <div>
                <div className="justify-center py-3 text-xs sm:text-sm">
                  {pickupReqView &&
                    pickupReqView.PickupReqPhotos &&
                    pickupReqView.PickupReqPhotos.map((prp, idx) => (
                      <div key={`WPickupReqPhotos${idx}`}>
                        <div className="pt-3 flex flex-row">
                          <input
                            style={{ width: "30%" }}
                            type="image"
                            onClick={() => {
                              setWebPickupReqPhotos((prevStates) => ({
                                ...prevStates,
                                [idx]: !prevStates[idx]
                              }))
                            }}
                            src={prp.ImageFile}
                            alt="PickupReqPhoto"
                          ></input>
                          <div>
                            <label className="col-md-6 font-bold">
                              Tanggal Foto
                            </label>
                            <label className="col-md-6">:{prp.StoreDt}</label>
                            <label className="col-md-6 font-bold">
                              Diupload Oleh
                            </label>
                            <label className="col-md-6">:{prp.StoreBy}</label>
                          </div>
                        </div>
                        <Modal size="lg" isOpen={webPickupReqPhotos[idx]} toggle={() => {
                          setWebPickupReqPhotos((prevStates) => ({
                            ...prevStates,
                            [idx]: !prevStates[idx]
                          }))
                        }}>
                          <ModalHeader toggle={() => {
                            setWebPickupReqPhotos((prevStates) => ({
                              ...prevStates,
                              [idx]: !prevStates[idx]
                            }))
                          }}>Detail</ModalHeader>
                          <ModalBody>
                            <div className="row">
                              <div className="col-md-4 flex flex-row justify-center">
                                <img
                                  src={prp.ImageFile}
                                />
                              </div>
                              <div className="col-md-8">
                                <label className="text-2xl col-md-12 font-bold">
                                  {prp.PickupReqNo}
                                </label>
                                <label className="text-sm col-md-12">
                                  {prp.Requestor}
                                </label>
                                <hr />
                                <label className="col-md-4">Tanggal Foto</label>
                                <label className="col-md-8">
                                  :{prp.StoreDt}
                                </label>
                                <label className="col-md-4">
                                  Diupload Oleh
                                </label>
                                <label className="col-md-8">
                                  :{prp.StoreBy}
                                </label>
                                <hr />
                                <label className="col-md-4">Lokasi Foto</label>
                                <label className="col-md-8">
                                  :{`${prp.Lattitude},${prp.Longitude}`}
                                </label>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {isOpen === "pickupTracking" && (
              <div>
                <div className="justify-center py-3 text-xs sm:text-sm">
                  {pickupReqView &&
                    pickupReqView.PickupRequestTrackings &&
                    pickupReqView.PickupRequestTrackings.length > 0 && (
                      <table
                        className="table table-auto table-striped bg-white"
                        aria-labelledby="tabelLabel"
                      >
                        <thead>
                          <tr>
                            <th className="text-center">Tanggal</th>
                            <th>Tipe</th>
                            <th>Sumber</th>
                            <th>Catatan Tracking</th>
                            <th>PIC</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pickupReqView.PickupRequestTrackings.map((prt) => (
                            <tr key={prt.InputDt}>
                              <td align="center">
                                {moment(prt.InputDt).format(
                                  "DD MMMM YY - HH:mm"
                                )}
                              </td>
                              <td>{prt.TrackType}</td>
                              <td>{prt.TrackSrc}</td>
                              <td>{prt.TrackNotes}</td>
                              <td>{prt.UsrCrt}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="block sm:hidden">
        <div className="bg-blue-600">
          <h4 className="flex justify-center text-xl font-semibold text-white py-2">
            Pickup Request View
          </h4>
        </div>
        <div className="text-xs sm:text-sm">
          {pickupReqView && (
            <>
              <div>
                <div className="flex flex-col pt-2 px-2">
                  <div className="flex flex-row justify-between">
                    <label>No Pickup</label>
                    <label>{pickupReqView.Requestor}</label>
                  </div>
                  <div className="flex flex-row justify-between">
                    <label className="text-sm font-bold">
                      {`${pickupReqView.PickupReqNo} `}
                      <FaRegCopy
                        onClick={(e) =>
                          copyToClipboard(e, pickupReqView.PickupReqNo)
                        }
                        style={{ display: "inline-block" }}
                      />
                    </label>
                    <label className="text-red-500">{`${pickupReqView.TotalItem} Koli, ${pickupReqView.EstWeight} Kg `}</label>
                  </div>
                </div>
                <hr />
                <div className="flex flex-row pt-2 px-2 justify-between">
                  <label className="font-bold w-24 text-left">
                    Contact Person
                  </label>
                  <label>
                    {`${pickupReqView.ContactPerson} - ${pickupReqView.PhoneNo} `}
                  </label>
                </div>
                <div className="flex flex-row px-2 justify-between">
                  <label className="font-bold w-24 text-left">Address</label>
                  <label className="text-right w-60 break-words">
                    {pickupReqView.Addr}
                  </label>
                </div>
                <div className="flex flex-row px-2 justify-between">
                  <label className="font-bold w-24 text-left">
                    Catatan Request Pickup
                  </label>
                  <label className="text-right w-60 break-words">
                    {pickupReqView.PickupNotes}
                  </label>
                </div>
                <hr />
                <div className="flex flex-row pt-2 px-2 justify-between">
                  <label className="font-bold text-left">
                    Percobaan Pickup ke
                  </label>
                  <label className="text-right w-60 break-words">
                    {pickupReqView.PickupAttempt}
                  </label>
                </div>
                <div className="flex flex-row px-2 justify-between">
                  <label className="font-bold w-24 text-left">
                    Status Request
                  </label>
                  <label className="text-right w-60 break-words">
                    {pickupReqView.PickupReqStat}
                  </label>
                </div>
                <div className="flex flex-row px-2 justify-between">
                  <label className="font-bold w-24 text-left">
                    Catatan Hasil Pickup
                  </label>
                  <label className="text-right w-60 break-words">
                    {pickupReqView.PickupNotes}
                  </label>
                </div>
              </div>
              <div className="justify-evenly flex flex-row ">
                <button
                  type="button"
                  onClick={() => viewInfo("pickupOrder")}
                  className="w-24 group relative inline-flex justify-center items-center py-1 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 active:bg-indigo-700 transition duration-150 ease-in-out"
                >
                  Pickup Order
                </button>
                <button
                  type="button"
                  onClick={() => viewInfo("fotoPickupRequest")}
                  className="w-24 group relative inline-flex justify-center items-center py-1 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 active:bg-indigo-700 transition duration-150 ease-in-out"
                >
                  Foto Pickup Request
                </button>
                <button
                  type="button"
                  onClick={() => viewInfo("percobaanPickup")}
                  className="w-24 group relative inline-flex justify-center items-center py-1 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 active:bg-indigo-700 transition duration-150 ease-in-out"
                >
                  Percobaan Pickup
                </button>
                <button
                  type="button"
                  onClick={() => viewInfo("pickupTracking")}
                  className="w-24 group relative inline-flex justify-center items-center py-1 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 active:bg-indigo-700 transition duration-150 ease-in-out"
                >
                  Pickup Tracking
                </button>
              </div>
              <div className="py-3 text-xs sm:text-sm">
                {isOpen === "pickupOrder" && (
                  <div className="flex flex-col items-center justify-center mx-2">
                    <div className="container rounded-sm shadow border my-2">
                      <div className="row rounded-sm flex flex-row font-bold text-sm bg-blue-200 px-3">
                        Info Pickup
                      </div>
                      {pickupReqView && pickupReqView.LastPickupOrder &&
                        <>
                          <div className="flex flex-row justify-between">
                            <label className="font-bold w-32 text-left">
                              Waktu Penugasan
                            </label>
                            <label className="text-right w-60 break-words">
                              {moment(pickupReqView.LastPickupOrder.OrderDt).format(
                                "DD-MM-YY HH:mm"
                              )}
                            </label>
                          </div>
                          <div className="flex flex-row justify-between">
                            <label className="font-bold w-32 text-left">
                              Status Order
                            </label>
                            <label className="text-right w-60 break-words">
                              {pickupReqView.LastPickupOrder.PickupOrderHStat}
                            </label>
                          </div>
                          <div className="flex flex-row justify-between">
                            <label className="font-bold w-32 text-left">
                              PIC Kantor
                            </label>
                            <label className="text-right w-60 break-words">
                              {pickupReqView.LastPickupOrder.PICOfficeCode}
                            </label>
                          </div>
                          <div className="flex flex-row justify-between">
                            <label className="font-bold w-32 text-left">
                              Driver
                            </label>
                            <label className="text-right w-60 break-words">
                              {pickupReqView.LastPickupOrder.DriverName}
                            </label>
                          </div>
                          <div className="flex flex-row justify-between">
                            <label className="font-bold w-32 text-left">
                              Alamat Penugasan
                            </label>
                            <label className="text-right w-60 break-words">
                              {pickupReqView.LastPickupOrder.Addr}
                            </label>
                          </div>
                          <div className="flex flex-row justify-between">
                            <label className="font-bold w-32 text-left">
                              Contact Person
                            </label>
                            <label className="text-right w-60 break-words">
                              {pickupReqView.LastPickupOrder.ContactPerson}
                            </label>
                          </div>
                          <div className="flex flex-row justify-between">
                            <label className="font-bold w-32 text-left">
                              Catatan
                            </label>
                            <label className="text-right w-60 break-words">
                              {moment(
                                pickupReqView.LastPickupOrder.OrderNotes
                              ).format("DD-MM-YY HH:mm")}
                            </label>
                          </div>
                        </>}
                    </div>
                    <div className="container rounded-sm shadow border my-2">
                      <div className="row rounded-sm flex flex-row font-bold text-sm bg-blue-200 px-3">
                        Foto Proses Pickup
                      </div>
                      {pickupReqView.PickupResultPhotos.map((prp, idx) => (
                        <div key={`MPickupResultPhotos${idx}`}>
                          <div className="py-2 flex flex-row">
                            <input
                              style={{ width: "30%" }}
                              type="image"
                              src={prp.ImageFile}
                              alt="PODImageFile"
                            ></input>
                            <div className="flex flex-col">
                              <div>
                                <label className="col-md-6">
                                  <b>Tanggal Foto: </b>
                                  {prp.StoreDt}
                                </label>
                                <label className="col-md-6">
                                  <b>Diupload Oleh: </b>
                                  {prp.StoreBy}
                                </label>
                              </div>
                              <div className="flex flex-col justify-center">
                                <button
                                  onClick={() => {
                                    setMobilePickupResultPhotos((prevStates) => ({
                                      ...prevStates,
                                      [idx]: !prevStates[idx]
                                    }))
                                  }}
                                  type="button"
                                  className="mx-3 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                                >
                                  Lihat Foto Proses Pickup
                                </button>
                              </div>
                            </div>
                          </div>
                          <Modal
                            size="lg"
                            centered={true}
                            isOpen={mobilePickupResultPhotos[idx]}
                            toggle={() => {
                              setMobilePickupResultPhotos((prevStates) => ({
                                ...prevStates,
                                [idx]: !prevStates[idx]
                              }))
                            }}
                          >
                            <ModalHeader toggle={() => {
                              setMobilePickupResultPhotos((prevStates) => ({
                                ...prevStates,
                                [idx]: !prevStates[idx]
                              }))
                            }}>
                              Foto Proses Pickup
                            </ModalHeader>
                            <ModalBody>
                              <img
                                src={prp.ImageFile}
                              />
                            </ModalBody>
                          </Modal>
                        </div>
                      ))}
                    </div>
                    <div className="container rounded-sm shadow border my-2">
                      <div className="row rounded-sm flex flex-row font-bold text-sm bg-blue-200 px-3">
                        Foto Summary Pickup
                      </div>
                      {pickupReqView.PickupSummaryPhotos.map((psp, idx) => (
                        <div key={`MPickupSummaryPhotos${idx}`}>
                          <div className="py-2 flex flex-row">
                            <input
                              style={{ width: "30%" }}
                              type="image"
                              src={psp.ImageFile}
                              alt="PODImageFile"
                            ></input>
                            <div className="flex flex-col">
                              <div>
                                <label className="col-md-6">
                                  <b>Tanggal Foto: </b>
                                  {psp.StoreDt}
                                </label>
                                <label className="col-md-6">
                                  <b>Diupload Oleh: </b>
                                  {psp.StoreBy}
                                </label>
                              </div>
                              <div className="flex flex-col justify-center">
                                <button
                                  onClick={() => {
                                    setMobilePickupSummaryPhotos((prevStates) => ({
                                      ...prevStates,
                                      [idx]: !prevStates[idx]
                                    }))
                                  }}
                                  type="button"
                                  className="mx-3 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                                >
                                  Lihat Foto Proses Pickup
                                </button>
                              </div>
                            </div>
                          </div>
                          <Modal
                            size="lg"
                            centered={true}
                            isOpen={mobilePickupSummaryPhotos[idx]}
                            toggle={() => {
                              setMobilePickupSummaryPhotos((prevStates) => ({
                                ...prevStates,
                                [idx]: !prevStates[idx]
                              }))
                            }}
                          >
                            <ModalHeader toggle={() => {
                              setMobilePickupSummaryPhotos((prevStates) => ({
                                ...prevStates,
                                [idx]: !prevStates[idx]
                              }))
                            }}>
                              Foto Proses Pickup
                            </ModalHeader>
                            <ModalBody>
                              <img
                                src={psp.ImageFile}
                              />
                            </ModalBody>
                          </Modal>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {isOpen === "fotoPickupRequest" && (
                  <>
                    <div className="flex flex-col items-center justify-center">
                      {pickupReqView.PickupReqPhotos.map((prp, idx) => (
                        <div key={`MPickupReqPhotos${idx}`}>
                          <div className="container shadow-md flex flex-col mx-2 my-1 px-2 w-80">
                            <div className="pt-3 flex flex-row">
                              <input
                                style={{ width: "30%" }}
                                type="image"
                                src={prp.ImageFile}
                                alt="PODImageFile"
                              ></input>
                              <div className="flex flex-col">
                                <div>
                                  <label className="col-md-6">
                                    <b>Tanggal Foto: </b>
                                    {prp.StoreDt}
                                  </label>
                                  <label className="col-md-6">
                                    <b>Diupload Oleh: </b>
                                    {prp.StoreBy}
                                  </label>
                                </div>
                                <div className="flex flex-col justify-center">
                                  <button
                                    onClick={() => {
                                      setMobilePickupReqPhotos((prevStates) => ({
                                        ...prevStates,
                                        [idx]: !prevStates[idx]
                                      }))
                                    }}
                                    type="button"
                                    className="mx-3 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                                  >
                                    Lihat Foto Pickup
                                  </button>
                                </div>
                              </div>
                            </div>
                            <Modal
                              size="lg"
                              centered={true}
                              isOpen={mobilePickupReqPhotos[idx]}
                              toggle={() => {
                                setMobilePickupReqPhotos((prevStates) => ({
                                  ...prevStates,
                                  [idx]: !prevStates[idx]
                                }))
                              }}
                            >
                              <ModalHeader toggle={() => {
                                setMobilePickupReqPhotos((prevStates) => ({
                                  ...prevStates,
                                  [idx]: !prevStates[idx]
                                }))
                              }}>
                                Foto Pickup
                              </ModalHeader>
                              <ModalBody>
                                <img
                                  src={prp.ImageFile}
                                />
                              </ModalBody>
                            </Modal>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {isOpen === "percobaanPickup" && (
                  <>
                    <div className="block sm:hidden w-full">
                      <div className="rounded text-xs px-3">
                        {pickupReqView.PickupOrderHistories &&
                          pickupReqView.PickupOrderHistories.map((poh) => (
                            <div
                              className="h-full w-full bg-white shadow flex rounded items-center my-2"
                              key={poh.OrderDt}
                            >
                              <div className="relative h-full w-full flex items-center">
                                <div className="text-xs w-full pb-1">
                                  <div className="py-2 flex px-3 font-bold text-sm">
                                    <div>
                                      {moment(poh.PickupDt).format(
                                        "DD-MM-YY HH:mm"
                                      )}
                                    </div>
                                    <div className="flex flex-1 justify-end mr-1">
                                      {poh.PickupOrderHStat}
                                    </div>
                                  </div>
                                  <hr className="pb-2" />
                                  <div className="flex px-3">
                                    <label>
                                      <b>Tanggal Order: </b>
                                      {moment(poh.OrderDt).format(
                                        "DD-MM-YY HH:mm"
                                      )}
                                    </label>
                                  </div>
                                  <div className="flex px-3">
                                    <label>
                                      <b>Diassign Oleh: </b>
                                      {poh.AssignBy}
                                    </label>
                                  </div>
                                  <div className="flex px-3">
                                    <label>
                                      <b>Driver: </b>
                                      {poh.Driver}
                                    </label>
                                  </div>
                                  <div className="flex px-3">
                                    <label>
                                      <b>Catatan: </b>
                                      {poh.WorkNotes === null ||
                                        poh.WorkNotes === ""
                                        ? "-"
                                        : poh.WorkNotes}
                                    </label>
                                  </div>
                                  <div className="flex px-3"></div>
                                </div>
                                <span
                                  className={`absolute right-0 h-full rounded-r w-3 ${poh.PickupOrderHStat === "ACT"
                                    ? "bg-green-600"
                                    : poh.PickupOrderHStat === "EXP" ||
                                      poh.PickupOrderHStat === "RJC" ||
                                      poh.PickupOrderHStat === "CAN"
                                      ? "bg-red-600"
                                      : "bg-yellow-600"
                                    }`}
                                ></span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                )}
                {isOpen === "pickupTracking" && (
                  <>
                    <div className="block sm:hidden w-full">
                      <div className="rounded text-xs px-3">
                        {pickupReqView.PickupRequestTrackings &&
                          pickupReqView.PickupRequestTrackings.map((prt) => (
                            <div
                              className="h-full w-full bg-white shadow flex rounded items-center my-2"
                              key={prt.InputDt}
                            >
                              <div className="relative h-full w-full flex items-center">
                                <div className="text-xs w-full pb-1">
                                  <div className="py-2 flex px-3 font-bold text-sm">
                                    <div>
                                      {moment(prt.InputDt).format(
                                        "DD-MM-YY HH:mm"
                                      )}
                                    </div>
                                    <div className="flex flex-1 justify-end mr-1">
                                      {prt.TrackType === "PCK_DONE" || prt.TrackType === "DONE"
                                        ? "Done"
                                        : prt.TrackType === "PCK_OTW" || prt.TrackType === "OTW"
                                          ? "Dalam Perjalanan"
                                          : prt.TrackType === "PCK_PICK" || prt.TrackType === "PICK"
                                            ? "Dalam Pengambilan"
                                            : prt.TrackType === "PCK_ASGN" || prt.TrackType === "ASGN"
                                              ? "Assign Driver"
                                              : "Data Tercatat"}
                                    </div>
                                  </div>
                                  <hr className="pb-2" />
                                  <div className="flex px-3">
                                    {prt.TrackNotes}
                                  </div>
                                </div>
                                <span
                                  className={`absolute right-0 h-full rounded-r w-3 ${prt.TrackType === "PCK_DONE" || prt.TrackType === "DONE"
                                    ? "bg-green-600"
                                    : prt.TrackType === "PCK_PICK" || prt.TrackType === "PICK"
                                      ? "bg-teal-600"
                                      : prt.TrackType === "PCK_OTW" || prt.TrackType === "OTW"
                                        ? "bg-yellow-600"
                                        : prt.TrackType === "PCK_RCV" || prt.TrackType === "RCV"
                                          ? "bg-gray-600"
                                          : "bg-blue-600"
                                    }`}
                                ></span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default PickupRequestView;
