import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bookingActions } from '../../_actions';

const PrintBooking = ({ t }) => {
    const dispatch = useDispatch()
    const acc = useSelector(state => state.authentication.accInfo);
    const printBookingObj = useSelector(state => state.printBookings.printBookingObj);

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();
    const trxNo = query.get("trxNo");
    const itemCodeNo = query.get("itemCodeNo");

    useEffect(() => {
        dispatch(bookingActions.clearPrintBooking());
        if (trxNo && acc != undefined) {
            const printBooking = async () => {
                dispatch(bookingActions.requestPrintBooking(trxNo, itemCodeNo));
            }
            printBooking()
        }
    }, [])

    return (
        <>
            {
                printBookingObj && printBookingObj != '' && printBookingObj != null &&
                <>
                    <embed src={`data:application/pdf;base64,${printBookingObj}`} style={{ width: "100%", height: "100vh" }} />
                </>
            }
        </>
    );
};
export default PrintBooking;