import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const UploadDetailTable = ({ t }) => {
    const uploadDetail = useSelector(
        (state) => state.uploadDetail.uploadDetail
    );

    return (
        <div className="text-xs sm:text-sm max-w-6xl mx-auto">
            {uploadDetail &&
                uploadDetail.Result &&
                uploadDetail.Result.length === 0 && (
                    <div className="justify-center flex flex-row my-1">
                        <p>Tidak ada data</p>
                    </div>
                )}
            {uploadDetail &&
                uploadDetail.Result &&
                uploadDetail.Result.length > 0 && (
                    <>
                    <table
                        className="table table-striped bg-white"
                        aria-labelledby="tabelLabel"
                    >
                        <thead>
                            <tr>
                                <th>Upload Status</th>
                                <th>Error Message</th>
                                <th>Resi No</th>
                                <th>BookSeqNo</th>
                                <th>OriginDestCode</th>
                                <th>DestDistrictCode</th>
                                <th>RecipientAddr</th>
                                <th>RecipientMblPhoneNo</th>
                                <th>RecipientName</th>
                                <th>PaymentType</th>
                                <th>PackageContentCode</th>
                                <th>PackageContentNotes</th>
                                <th>TransportType</th>
                                <th>IsInsurance</th>
                                <th className="text-right">EstItemPriceAmt</th>
                                <th>AliasName</th>
                                <th>SellerNo</th>
                                <th>SellerName</th>
                                <th>SellerAddr</th>
                                <th>SellerMblPhoneNo</th>
                                <th>CustRefNo</th>
                                <th>DropType</th>
                                <th>BookNotes</th>
                                <th>PickupAddr</th>
                                <th>PickupDistrictCode</th>
                                <th>PickupContactPerson</th>
                                <th>PickupPhoneNo</th>
                                <th className="text-center">PickupTime</th>
                                <th>PickupNotes</th>
                                <th className="text-right">ItemWeight</th>
                                <th className="text-right">DimP</th>
                                <th className="text-right">DimL</th>
                                <th className="text-right">DimT</th>
                                <th>PackingType</th>
                                <th>PickupAddrType</th>
                            </tr>
                        </thead>
                        <tbody>
                            {uploadDetail.Result.map((ud) => (
                                <tr key={ud.TempUplCustBookId}>
                                    <td>{ud.UploadStat}</td>
                                    <td>{ud.ErrorMessage}</td>
                                    <td>{ud.ResiNo}</td>
                                    <td className="text-right">{ud.BookSeqNo}</td>
                                    <td>{ud.OriginDestCode}</td>
                                    <td>{ud.DestDistrictCode}</td>
                                    <td>{ud.RecipientAddr}</td>
                                    <td>{ud.RecipientMblPhoneNo}</td>
                                    <td>{ud.RecipientName}</td>
                                    <td>{ud.PaymentType}</td>
                                    <td>{ud.PackageContentCode}</td>
                                    <td>{ud.PackageContentNotes}</td>
                                    <td>{ud.TransportType}</td>
                                    <td>{ud.IsInsurance}</td>
                                    <td className="text-right">{ud.EstItemPriceAmt.toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                    })}</td>
                                    <td>{ud.AliasName}</td>
                                    <td>{ud.SellerNo}</td>
                                    <td>{ud.SellerName}</td>
                                    <td>{ud.SellerAddr}</td>
                                    <td>{ud.SellerMblPhoneNo}</td>
                                    <td>{ud.CustRefNo}</td>
                                    <td>{ud.DropType}</td>
                                    <td>{ud.BookNotes}</td>
                                    <td>{ud.PickupAddr}</td>
                                    <td>{ud.PickupDistrictCode}</td>
                                    <td>{ud.PickupContactPerson}</td>
                                    <td>{ud.PickupPhoneNo}</td>
                                    <td className="text-center">{moment(ud.PickupTime).format("DD-MM-YY HH:mm")}</td>
                                    <td>{ud.PickupNotes}</td>
                                    <td className="text-right">{ud.ItemWeight.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                    })}</td>
                                    <td className="text-right">{ud.DimP.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                    })}</td>
                                    <td className="text-right">{ud.DimL.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                    })}</td>
                                    <td className="text-right">{ud.DimT.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                    })}</td>
                                    <td>{ud.PackingType}</td>
                                    <td>{ud.PickupAddrType}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </>
                )}
        </div>
    );
};

export default UploadDetailTable;
