import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { pengirimanActions } from "../../_actions";
import {
  PengirimanDetailTable,
  Pagination,
  WaktuSampaiFooter,
  ShortcutDate,
  AlertMessage,
} from "../../components";
import { masterSettingConstants } from "../../_constants";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import axios from "axios";
import { urlConstants } from "../../_constants/url.constants";
import moment from "moment";

const PengirimanDetail = ({ t }) => {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  const dispatch = useDispatch();
  const acc = useSelector((state) => state.authentication.accInfo);
  const pengirimanDetail = useSelector(
    (state) => state.pengirimanDetail.pengirimanDetail
  );
  const errPengirimanDetail = useSelector(
    (state) => state.pengirimanDetail.errPengirimanDetail
  );
  const [rowPerPage, setRowPerPage] = useState(10);
  let [cur, setCur] = useState(1);
  let [num, setNum] = useState(1);
  const [loadMore, setLoadmore] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState({
    Type: "",
    StatusMessage: "",
  });
  const [enabled, setEnabled] = useState(false);
  const [exported, setExported] = useState(false);
  const [exportDataObj, setExportDataObj] = useState();

  const maxDate = new Date();
  const minDate = new Date();
  const finalMinDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
  const _trxDt = query.get("trxDt");
  const _process = query.get("process");
  const _podRating = query.get("podRating");
  const _addrType = query.get("addrType");
  const [pengirimanDetailForm, setPengirimanDetailForm] = useState({
    trxDtFrom: dateParser(_trxDt != null ? dateParser(_trxDt) : finalMinDate),
    trxDtTo: dateParser(_trxDt != null ? dateParser(_trxDt) : maxDate),
    process: _process != null ? _process : "",
    addrType: _addrType != null ? _addrType : "",
    PodRating: _podRating != null ? _podRating : "",
    resiNo: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [shortcut, setShortcut] = useState(false);
  const client = axios.create({
    baseURL: urlConstants.URL_EXPORT_PENGIRIMAN_DATA,
  });

  function dateParser(value) {
    const date = new Date(value);
    const dateParsed =
      date.getFullYear() +
      "-" +
      date.toLocaleString("en-us", { month: "2-digit" }) +
      "-" +
      date.toLocaleString("en-us", { day: "2-digit" });
    return dateParsed;
  }

  useEffect(() => {
    setTotalRecord(0);
    setLoadmore(1);
    setNum(1);
    callPengirimanDetail(1, rowPerPage);
    setCur(1);
  }, []);

  useEffect(() => {
    if (pengirimanDetail && pengirimanDetail.TotalRecord) {
      setTotalRecord(pengirimanDetail.TotalRecord);
    }
  }, [pengirimanDetail]);

  useEffect(() => {
    if (totalRecord > 0) {
      callPengirimanDetail(cur, rowPerPage);
    }
  }, [cur]);

  useEffect(() => {
    if (totalRecord > 0) {
      callPengirimanDetail(1, loadMore * rowPerPage);
    }
  }, [loadMore]);

  function handleChange(e) {
    const { name, value } = e.target;
    setPengirimanDetailForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleSearch(e) {
    e.preventDefault();

    setSubmitted(true);
    if (pengirimanDetailForm.trxDtFrom && pengirimanDetailForm.trxDtTo) {
      setLoadmore(1);
      setTotalRecord(0);
      setNum(1);
      callPengirimanDetail(1, rowPerPage);
      setCur(1);
    }
  }

  const getDate = (dateObj) => {
    setPengirimanDetailForm((prevState) => ({
      ...prevState,
      trxDtFrom: dateObj.dateFrom,
      trxDtTo: dateObj.dateTo,
    }));

    setShortcut(true);
  };

  useEffect(() => {
    if (shortcut) {
      setTotalRecord(0);
      setLoadmore(1);
      setNum(1);
      callPengirimanDetail(1, rowPerPage);
      setSubmitted(true);
      setCur(1);
      setShortcut(false);
    }
  }, [shortcut]);

  useEffect(() => {
    if (errPengirimanDetail && errPengirimanDetail.StatusCode !== "200") {
      setIsError(true);
      setAlert({
        Type: "Error",
        StatusMessage: errPengirimanDetail.StatusMessage,
      });
    }
  }, [errPengirimanDetail]);

  function callPengirimanDetail(pageNo, rowPerPages) {
    if (acc && acc.CustId) {
      var requestObj = {
        CustId: acc.CustId,
        TrxDtFrom: pengirimanDetailForm.trxDtFrom,
        TrxDtTo: pengirimanDetailForm.trxDtTo,
        PodRating:
          _podRating != null && _podRating != undefined ? _podRating : "",
        Process: pengirimanDetailForm.process,
        AddrType: pengirimanDetailForm.addrType,
        ResiNo: pengirimanDetailForm.resiNo,
        RowPerPage: rowPerPages,
        PageNo: pageNo,
      };
      dispatch(pengirimanActions.pengirimanDetail(requestObj));
    }
  }

  function exportData() {
    setIsError(false);
    var requestObj = {
      CustId: acc.CustId,
      TrxDtFrom: pengirimanDetailForm.trxDtFrom,
      TrxDtTo: pengirimanDetailForm.trxDtTo,
      Process: pengirimanDetailForm.process,
      AddrType: pengirimanDetailForm.addrType,
      PodRating: pengirimanDetailForm.PodRating,
      ResiNo: pengirimanDetailForm.resiNo,
      RowPerPage: 99999,
      PageNo: 1,
    };
    console.log(requestObj);
    exportPengirimanMonitoring(requestObj);
    setExported(true);
  }

  const exportPengirimanMonitoring = async (requestObj) => {
    try {
      let result = await client.post("", requestObj);
      if (result.data.IsError) {
        console.log("Export Error");
        setIsError(true);
        setAlert({
          Type: "Error",
          StatusMessage: result.data.StatusMessage,
        });
      } else {
        setExportDataObj({
          exportPengirimanData: result.data.ObjectResult,
        });
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
      setAlert({
        Type: "Error",
        StatusMessage:
          "An error occure, please contact our admin for further information",
      });
    }
  };

  useEffect(() => {
    if (exportDataObj && exportDataObj.exportPengirimanData && exported) {
      var a = document.createElement("a");
      a.href =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        exportDataObj.exportPengirimanData.ExportDataFile;
      a.download = exportDataObj.exportPengirimanData.Filename;
      a.click();

      setExported(false);
    }
  }, [exportDataObj]);

  function openInNewTab(e, url) {
    e.preventDefault();
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }


  function printResiBulky(e) {
    // setIsError(false);
    // var requestObj = {
    //   CustId: acc.CustId,
    //   DateFrom: pengirimanDetailForm.trxDtFrom,
    //   DateTo: pengirimanDetailForm.trxDtTo,
    //   Process: pengirimanDetailForm.process,
    //   AddrType: pengirimanDetailForm.addrType,
    //   PodRating: _podRating,
    //   RowPerPage: 99999,
    //   PageNo: 1,
    // };
    // console.log(requestObj);

    let mmtStartDt = moment(pengirimanDetailForm.trxDtFrom);
    let mmtEndDt = moment(pengirimanDetailForm.trxDtTo);
    let totalRequest = mmtEndDt.diff(mmtStartDt, 'days');

    if (totalRequest > 7) {
      setIsError(true);
      setAlert({
        Type: "Error",
        StatusMessage: "Range tanggal tidak boleh melebihi 7 hari.",
      });
    } else {
      openInNewTab(e, `/resi-print-bulky?TrxDtFrom=${pengirimanDetailForm.trxDtFrom}&TrxDtTo=${pengirimanDetailForm.trxDtTo}&Process=${pengirimanDetailForm.process}&AddrType=${pengirimanDetailForm.addrType}&PodRating=${pengirimanDetailForm.PodRating}`)
    }
  }

  return (
    <div>
      <div className="hidden sm:block mx-auto">
        <div className="justify-center py-2 px-4 flex flex-row">
          <h1 className="font-bold text-xl">Pengiriman Detail</h1>
        </div>
        <form onSubmit={handleSearch} method="POST">
          <div className="text-xs sm:text-sm font-medium">
            <div className="row px-2 py-1">
              <div className="col-md-3 text-xs sm:text-sm">
                Tanggal Awal Transaksi
              </div>
              <div className="col-md-3 text-xs sm:text-sm">
                <input
                  aria-label="Tanggal Awal Transaksi"
                  id="trxDtFrom"
                  name="trxDtFrom"
                  type="date"
                  value={pengirimanDetailForm.trxDtFrom}
                  className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                  onChange={handleChange}
                />
                {submitted && !pengirimanDetailForm.trxDtFrom && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                    Tidak boleh kosong
                  </div>
                )}
              </div>
              <div className="col-md-3 text-xs sm:text-sm">
                Tanggal Akhir Transaksi
              </div>
              <div className="col-md-3 text-xs sm:text-sm">
                <input
                  aria-label="Tanggal Akhir Transaksi"
                  id="trxDtTo"
                  name="trxDtTo"
                  type="date"
                  value={pengirimanDetailForm.trxDtTo}
                  className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                  onChange={handleChange}
                />
                {submitted && !pengirimanDetailForm.trxDtTo && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                    Tidak boleh kosong
                  </div>
                )}
              </div>
            </div>
            <div className="row px-2 py-1">
              <div className="col-md-3 text-xs sm:text-sm">Nomor Resi</div>
              <div className="col-md-3 text-xs sm:text-sm">
                <input
                  id="resiNo"
                  type="text"
                  name="resiNo"
                  className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                  placeholder="Isi nomor resi"
                  value={pengirimanDetailForm.resiNo}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-3 text-xs sm:text-sm">Process</div>
              <div className="col-md-3 text-xs sm:text-sm">
                <select
                  className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                  id="process"
                  name="process"
                  value={pengirimanDetailForm.process}
                  onChange={handleChange}
                >
                  <option value="">Semua</option>
                  <option value={masterSettingConstants.PROCESS_RETUR}>
                    Retur
                  </option>
                  <option value={masterSettingConstants.PROCESS_ONPROCESS}>
                    On Process
                  </option>
                  <option value={masterSettingConstants.PROCESS_DELIVERED}>
                    Delivered
                  </option>
                </select>
              </div>
            </div>
            <div className="flex justify-end mb-2 -mt-2">
              <ShortcutDate onSubmit={getDate} />
            </div>
            <div className="flex justify-end mb-2 -mt-4">
              <button
                type="submit"
                className="relative w-40 flex justify-center items-center mx-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
              >
                Search
              </button>
              <button
                type="button"
                onClick={exportData}
                className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
              >
                Export
              </button>
            </div>
            <div className="flex justify-end mb-2">
              <button
                type="button"
                onClick={(e) => { printResiBulky(e) }}
                className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out"
              >
                Print Resi
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="block sm:hidden">
        <div className="bg-blue-600">
          <h4 className="flex justify-center text-xl font-semibold text-white py-2">
            Pengiriman Detail
          </h4>
        </div>
        <form onSubmit={handleSearch} method="POST">
          <div className="grid grid-cols-2 py-2 text-xs sm:text-sm">
            <div className="px-3 font-bold">Filter dari Tanggal</div>
            <div className="px-1 font-bold">sampai Tanggal</div>
          </div>
          <div className="flex px-3">
            <div className="flex-1 flex flex-col text-xs sm:text-sm items-center">
              <input
                id="trxDtFrom"
                name="trxDtFrom"
                type="date"
                value={pengirimanDetailForm.trxDtFrom}
                onChange={handleChange}
                className="rounded-sm px-3 py-1 text-xs sm:text-sm border border-gray-300"
                style={{ width: "98%" }}
              />
            </div>
            <div className="flex-1 flex flex-col text-right text-xs sm:text-sm items-center">
              <input
                id="trxDtTo"
                name="trxDtTo"
                type="date"
                value={pengirimanDetailForm.trxDtTo}
                onChange={handleChange}
                className="rounded-sm px-3 py-1 text-xs sm:text-sm border border-gray-300"
                style={{ width: "98%" }}
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex-1 text-xs sm:text-sm flex items-center">
              {submitted && !pengirimanDetailForm.trxDtFrom && (
                <div className="flex flex-1 text-xs sm:text-sm text-red-500 ml-4 py-1">
                  Isi tanggal awal pengiriman
                </div>
              )}
            </div>
            <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
              {submitted && !pengirimanDetailForm.trxDtTo && (
                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                  Isi tanggal akhir pengiriman
                </div>
              )}
            </div>
          </div>
          {enabled === true && (
            <>
              <div className="flex flex-row px-3 mt-1 font-bold">
                <div className="flex-1 text-xs sm:text-sm flex items-center">
                  Nomor Resi
                </div>
                <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                  Process
                </div>
              </div>
              <div className="flex flex-row px-3 mt-1">
                <div className="flex-1 text-xs sm:text-sm flex items-center">
                  <input
                    id="resiNo"
                    name="resiNo"
                    placeholder="Isi nomor resi"
                    type="text"
                    className="py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none rounded-sm w-100"
                    value={pengirimanDetailForm.resiNo}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                  <select
                    className="py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none rounded-sm w-100"
                    id="process"
                    name="process"
                    value={pengirimanDetailForm.process}
                    onChange={handleChange}
                  >
                    <option value="">Semua</option>
                    <option value={masterSettingConstants.PROCESS_RETUR}>
                      Retur
                    </option>
                    <option value={masterSettingConstants.PROCESS_ONPROCESS}>
                      On Process
                    </option>
                    <option value={masterSettingConstants.PROCESS_DELIVERED}>
                      Delivered
                    </option>
                  </select>
                </div>
              </div>
            </>
          )}
          <div className="flex">
            <div className="flex flex-1 flex-col text-xs sm:text-sm items-center">
              <button
                className="text-red-600 px-3 pt-2"
                onClick={(e) => setEnabled(!enabled)}
              >
                {enabled === true ? (
                  <>
                    Hide Search
                    <FaCaretUp
                      className="mb-1 w-4 h-4"
                      style={{ display: "inline-block" }}
                    />
                  </>
                ) : (
                  <>
                    Advance Search
                    <FaCaretDown
                      className="mb-1 w-4 h-4"
                      style={{ display: "inline-block" }}
                    />
                  </>
                )}
              </button>
            </div>
          </div>
          <ShortcutDate onSubmit={getDate} />
          <div className="flex flex-row justify-center mx-3">
            <div className="flex-1 mr-1">
              <button
                type="submit"
                className="text-xs sm:text-sm relative w-100 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
              >
                Search
              </button>
            </div>
            <div className="flex-1 ml-1">
              <button
                type="button"
                onClick={exportData}
                className="text-xs sm:text-sm relative w-100 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
              >
                Export
              </button>
            </div>
          </div>
        </form>
      </div>
      {isError == true && (
        <AlertMessage
          isError={isError}
          alert={alert}
          setAlert={setAlert}
          setIsError={setIsError}
        />
      )}
      {pengirimanDetail && pengirimanDetail.Result && (
        <div className="justify-center">
          <PengirimanDetailTable />
          {pengirimanDetail.Result.length > 0 && (
            <Pagination
              cur={cur}
              setCur={setCur}
              loadMore={loadMore}
              setLoadmore={setLoadmore}
              rowPerPage={rowPerPage}
              totalRecord={totalRecord}
              num={num}
              setNum={setNum}
            />
          )}
        </div>
      )}
      <div className="hidden sm:block">
        <WaktuSampaiFooter />
      </div>
    </div>
  );
};
export default PengirimanDetail;
