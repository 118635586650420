import React, { useEffect } from "react";
import { packageContentActions } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";

const PackageContentDictionary = ({ t }) => {
    const dispatch = useDispatch();
    const packageContent = useSelector(
        (state) => state.packagecontent.packageContentObj
    );

    useEffect(() => {
        dispatch(packageContentActions.getPackageContent(""));
    }, []);

    return (
        <>
            <div className="hidden sm:block max-w-6xl mx-auto">
                <h1 className="py-6 mx-3 text-xl flex font-bold justify-center">
                    Package Content Dictionary
                </h1>
                <div>
                    {packageContent &&
                        packageContent &&
                        packageContent.length == 0 && (
                            <div className="justify-center flex flex-row">
                                <p className="text-xs sm:text-sm">Tidak ada data</p>
                            </div>
                        )}

                    {packageContent &&
                        packageContent &&
                        packageContent.length > 0 && (
                            <div>
                                <table className="table table-striped text-xs sm:text-sm">
                                    <thead>
                                        <tr>
                                            <th className="text-left">Kode Package Content</th>
                                            <th className="text-left">Nama Package Content</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {packageContent.map((o, i) => (
                                            <tr key={i}>
                                                <td align="left">{o.PackageContentCode}</td>
                                                <td align="left">{o.PackageContentName}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                </div>
            </div >
            <div className="block sm:hidden max-w-6xl mx-auto">
                <div className="flex flex-row bg-blue-600">
                    <h4 className="w-full text-center text-xl font-semibold text-white py-2">
                        Package Content Dictionary
                    </h4>
                </div>
                <div className="flex justify-center content-center text-xs sm:text-sm px-3 my-2">
                    <div className="h-full w-full px-2">
                        {packageContent &&
                            packageContent &&
                            packageContent.length == 0 && (
                                <div className="justify-center flex flex-row">
                                    <p className="text-xs sm:text-sm">Tidak ada data</p>
                                </div>
                            )}
                        {packageContent &&
                            packageContent &&
                            packageContent.length > 0 &&
                            packageContent.map((o, i) => (
                                <>
                                    <div
                                        className="h-full w-full bg-white shadow flex rounded items-center my-2"
                                        key={i}
                                    >
                                        <div className="relative h-full w-full flex items-center py-2">
                                            <div className="text-sm font-medium w-full mr-4 pl-2">
                                                <div className="flex py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Kode Package Content
                                                    </div>
                                                    <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                        {o.PackageContentCode}
                                                    </div>
                                                </div>
                                                <div className="flex py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Nama Package Content
                                                    </div>
                                                    <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                        {o.PackageContentName}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                    </div>
                </div>
            </div >
        </>
    );
};
export default PackageContentDictionary;
