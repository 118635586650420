import React, { Component } from "react";
import { Router, Route, Switch } from "react-router";
import { Layout, Home } from "./components";
import { FetchData } from "./components/FetchData";
import { Counter } from "./components/Counter";
import { Login, ResetPasswordForm, PickupAddress } from "./pages/Login";
import { history, config } from "./_helpers";
import {
  BookingViewH,
  BookingViewD,
  ResiHView,
  PickupRequestView,
} from "./pages/View";
import {
  BookingMonitor,
  BookingDetails,
  BookingAddForm,
  BookingAddFormV2,
  BookingAddFormV3,
  BookingAddFormV4,
  PrintBooking,
  DownloadBookingResi,
  BookingUploadForm,
  BookingDetailEdit,
  BookingDetailEditV2,
} from "./pages/Booking";
import {
  PengirimanMonitoring,
  PengirimanDetail,
  PenerimaanMonitoring,
  ResiComplaintForm,
  ResiComplaintMonitoring,
  PrintResi,
  PrintResiBulky,
} from "./pages/Resi";
import {
  PickupMonitoring,
  PickupOrderCancelForm,
  PickupOrderDetail,
  PickupReqMonitoring,
  PickupReqDetail,
  PickupAddressPaging,
  PickupAddressForm,
} from "./pages/Pickup";
import { Profile } from "./pages/Profile";
import {
  OriginDictionary,
  DistrictDictionary,
  PackageContentDictionary,
} from "./pages/Dictionary";
import { UploadMonitoring, UploadDetail } from "./pages/Upload";
import "./styles/tailwind.css";
import background from "./assets/bg.png";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Login} />

            <Route
              exact
              path="/reset-password-form"
              component={ResetPasswordForm}
            />
            <Route exact path="/pickup-address" component={PickupAddress} />
            <Route exact path="/booking-print" component={PrintBooking} />
            <Route exact path="/resi-print" component={PrintResi} />
            <Route exact path="/resi-print-bulky" component={PrintResiBulky} />
            <Route
              exact
              path="/booking-download"
              component={DownloadBookingResi}
            />
            <div
              className="absolute top-0 left-0 w-full min-h-screen z-0 bg-cover bg-repeat"
              style={{
                backgroundImage: `url(${background})`,
              }}
            >
            <Route>
              <Layout>
                <Route path="/Home" component={Home} />
                <Route path="/counter" component={Counter} />
                <Route path="/fetch-data" component={FetchData} />
                <Route path="/booking-monitor" component={BookingMonitor} />
                <Route
                  path="/BookingUploadForm"
                  component={BookingUploadForm}
                />
                <Route path="/booking-add" component={BookingAddForm} />
                <Route path="/booking-addv2" component={BookingAddFormV2} />
                <Route path="/booking-addv3" component={BookingAddFormV3} />
                <Route path="/booking-addv4" component={BookingAddFormV4} />
                <Route path="/booking-details" component={BookingDetails} />
                <Route
                  path="/booking-details-edit"
                  component={BookingDetailEdit}
                />
                <Route
                  path="/booking-details-editv2"
                  component={BookingDetailEditV2}
                />
                <Route path="/booking-viewH" component={BookingViewH} />
                <Route path="/booking-viewD" component={BookingViewD} />
                <Route
                  path="/PengirimanMonitoring"
                  component={PengirimanMonitoring}
                />
                <Route path="/PengirimanDetail" component={PengirimanDetail} />
                <Route
                  path="/PenerimaanMonitoring"
                  component={PenerimaanMonitoring}
                />
                <Route
                  path="/ResiComplaintForm"
                  component={ResiComplaintForm}
                />
                <Route path="/ResiHView" component={ResiHView} />
                <Route path="/PickupMonitoring" component={PickupMonitoring} />
                <Route
                  path="/PickupReqMonitoring"
                  component={PickupReqMonitoring}
                />
                <Route path="/PickupReqDetail" component={PickupReqDetail} />
                <Route
                  path="/PickupOrderCancelForm"
                  component={PickupOrderCancelForm}
                />
                <Route
                  path="/PickupOrderDetail"
                  component={PickupOrderDetail}
                />
                <Route
                  path="/PickupRequestView"
                  component={PickupRequestView}
                />
                <Route
                  path="/PickupAddressPaging"
                  component={PickupAddressPaging}
                />
                <Route
                  path="/PickupAddressForm"
                  component={PickupAddressForm}
                />
                <Route
                  path="/KomplainMonitoring"
                  component={ResiComplaintMonitoring}
                />
                <Route path="/Profile" component={Profile} />
                <Route path="/OriginDictionary" component={OriginDictionary} />
                <Route
                  path="/DistrictDictionary"
                  component={DistrictDictionary}
                />
                <Route
                  path="/PackageContentDictionary"
                  component={PackageContentDictionary}
                />
                <Route path="/UploadMonitoring" component={UploadMonitoring} />
                <Route path="/UploadDetail" component={UploadDetail} />
              </Layout>
            </Route>
          </div>
        </Switch>
      </Router>
    );
  }
}
