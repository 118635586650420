import React, { useState } from 'react';
import { useSelector } from "react-redux";

const DashboardBooking = () => {
    const acc = useSelector((state) => state.authentication.accInfo);
    const pickupAddrSelectedObj = useSelector((state) => state.selectedPickupAddr.selectedPickupAddrObj);
    function dateParser(value) {
        const date = new Date(value);
        const dateParsed =
            date.getFullYear() +
            "-" +
            date.toLocaleString("en-us", { month: "2-digit" }) +
            "-" +
            date.toLocaleString("en-us", { day: "2-digit" });
        return dateParsed;
    }
    const maxDate = new Date();
    const minDate = new Date();
    const finalMinDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
    const [requestObj, setRequestObj] = useState({
        trxDtFrom: dateParser(finalMinDate),
        trxDtTo: dateParser(maxDate),
    });
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <div>
            <div className="hidden sm:block">
                <div className="flex pt-3 justify-center content-center text-xs sm:text-sm max-w-6xl mx-auto">
                    <div className="flex flex-row justify-center rounded-md h-full w-full px-3 py-2 shadow-md hover:shadow-xl transition-shadow border-2 border-blue-600">
                        <div className="flex flex-col">
                            <div className="flex-1 flex-col">
                                <h1 className="py-6 mx-3 text-xl flex font-bold justify-center">Booking</h1>
                            </div>
                            <div className="flex-1 flex-col mx-3">
                                <div>Dengan booking Anda dapat mengetahui :</div>
                                <div>1. Request pickup barang</div>
                                <div>2. Mendapatkan nomor resi pengiriman langsung</div>
                            </div>
                            <div className="flex-1 mx-3">
                                <div className="flex flex-row justify-center">
                                    <button type="button" className="mx-3 my-2 py-2 px-4 w-40 border border-transparent font-medium rounded-full text-white bg-blue-600" onClick={() => acc.IsSubAccount === true ? openInNewTab(`/booking-addv3`) : openInNewTab(`/booking-addv4`)}>
                                        Booking
                                    </button>
                                    {/* <button type="button" className="mx-3 my-2 py-2 px-4 w-40 border border-transparent font-medium rounded-full text-white bg-green-600" onClick={() => openInNewTab(`/BookingUploadForm`)}>
                                        Upload Booking
                                    </button> */}
                                    <button type="button" className="mx-3 my-2 py-2 px-4 w-40 border border-transparent font-medium rounded-full text-white bg-red-600" onClick={() => acc.IsSubAccount === true ? openInNewTab(`/booking-monitor?trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${pickupAddrSelectedObj && pickupAddrSelectedObj.AddrType}`) : openInNewTab(`/booking-monitor`)}>
                                        Cek Booking
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="block sm:hidden">
                <div className="flex justify-center content-center text-xs sm:text-sm px-3">
                    <div className="rounded-md h-full w-full shadow-md hover:shadow-xl transition-shadow border-2 border-blue-600 px-2">
                        <div className="flex flex-col items-center py-3">
                            <h1 className="text-xl flex font-bold">Booking</h1>
                        </div>
                        <div className="flex flex-col justify-center">
                            <div className="py-1">Dengan booking Anda dapat mengetahui :</div>
                            <div className="py-1">1. Request pickup barang</div>
                            <div className="py-1">2. Mendapatkan nomor resi pengiriman langsung</div>
                        </div>
                        <div className="flex flex-row justify-center">
                            <button type="button" className="my-2 py-2 px-4 mr-1 w-full border border-transparent font-medium rounded-full text-white bg-blue-600" onClick={() => acc.IsSubAccount === true ? openInNewTab(`/booking-addv3`) : openInNewTab(`/booking-addv4`)}>
                                Buat Booking
                            </button>
                            {/* <button type="button" className="my-2 py-2 px-4 ml-1 w-full border border-transparent font-medium rounded-full text-white bg-green-600" onClick={() => openInNewTab(`/BookingUploadForm`)}>
                                Upload Booking
                            </button> */}
                            <button type="button" className="my-2 py-2 px-4 ml-1 w-full border border-transparent font-medium rounded-full text-white bg-red-600" onClick={() => acc.IsSubAccount === true ? openInNewTab(`/booking-monitor?trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${pickupAddrSelectedObj && pickupAddrSelectedObj.AddrType}`) : openInNewTab(`/booking-monitor`)}>
                                Cek Booking
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DashboardBooking;
