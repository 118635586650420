import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { masterSettingConstants } from "../../_constants";
import {
  districtActions,
  bookingActions,
  packageContentActions,
  pickupActions,
  originActions,
  transportTypeActions,
  customerActions,
} from "../../_actions";
import {
  TableDetailBarang,
  AlertMessage,
  TableDetailPenerima,
  TableDetailBarangEdit,
} from "../../components";
import Input from "react-phone-number-input/input";
import { NumericFormat } from "react-number-format";
import { formatPhoneNumber } from "react-phone-number-input";
import "../../custom.css";
import "react-phone-number-input/style.css";
import * as FaIcons from "react-icons/fa";
import Select, { createFilter } from "react-select";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";

const BookingAddFormV4 = ({ t }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const acc = useSelector((state) => state.authentication.accInfo);
  const packageContentsObj = useSelector(
    (state) => state.packagecontent.packageContentObj
  );
  const districtObj = useSelector((state) => state.district.districtObj);
  const respCalcObj = useSelector(
    (state) => state.calculateBookingPrice.calcBookingObj
  );
  const respSubmitObj = useSelector(
    (state) => state.submitBooking.submitBookingObj
  );
  const pickupAddressTypeObj = useSelector(
    (state) => state.pickupAddr.pickupAddrObj
  );
  const errCalcObj = useSelector(
    (state) => state.calculateBookingPrice.errCalcBookingObj
  );
  const errSubmitObj = useSelector(
    (state) => state.submitBooking.errSubmitBookingObj
  );
  const originObj = useSelector((state) => state.origin.origin);
  const transportTypeObj = useSelector(
    (state) => state.transportType.transportTypeObj
  );
  const errTransportTypeObj = useSelector(
    (state) => state.transportType.errTransportTypeObj
  );
  const custObj = useSelector((state) => state.custById.custObj);
  const listPenerimaObj = useSelector(
    (state) => state.penerimaByHint.penerimaByHintObj
  );
  const penerimaObj = useSelector((state) => state.penerimaById.penerimaObj);
  const recipientListObj = useSelector(
    (state) => state.recipientListBooking.recipientListObj
  );
  const custBookHDetailObj = useSelector(
    (state) => state.getCustBookHDetail.custBookHDetailObj
  );
  const cancelKoliBookingObj = useSelector(
    (state) => state.cancelKoliBooking.cancelKoliBookingObj
  );

  const [isError, setIsError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [simpanDataBarang, setSimpanDataBarang] = useState(false);
  const [enableSubmitted, setEnableSubmitted] = useState(false);
  const [newPickupAddr, setNewPickupAddr] = useState(false);
  const [packageCategory, setPackageCategory] = useState("");
  const [sellerName, setSellerName] = useState(acc && acc.CustName);
  const [sellerAliasName, setSellerAliasName] = useState(acc && acc.CustName);
  const [sellerMobilePhnNo, setSellerMobilePhnNo] = useState(
    acc && acc.MobilePhoneNo
  );
  const [originDestId, setOriginDestId] = useState(0);
  const [originDestCode, setOriginDestCode] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [resiNos, setResiNos] = useState("");
  const [recipientId, setRecipientId] = useState(0);
  const [recipientName, setRecipientName] = useState("");
  const [recipientMobilePhnNo, setRecipientMobilePhnNo] = useState("");
  const [recipientAddress, setRecipientAddress] = useState("");
  const [dropType, setDropType] = useState("PICKUP");
  const [paymentType, setPaymentType] = useState("");
  const [transportType, setTransportType] = useState("");
  const [estItemWeight, setEstItemWeight] = useState(0);
  const [packageContent, setPackageContent] = useState("");
  const [packageContentNotes, setPackageContentNotes] = useState("");
  const [pickupContactPerson, setPickupContactPerson] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");
  const [pickupNotes, setPickupNotes] = useState("");
  const [pickupPhnNo, setPickupPhnNo] = useState("");
  const [pickupTime, setPickupTime] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [minPickupTime, setMinPickupTime] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [pickupAddrType, setPickupAddrType] = useState("Default");
  const [bookingNotes, setBookingNotes] = useState("");
  const [isInsurance, setIsInsurance] = useState("0");
  const [nilaiBarang, setNilaiBarang] = useState(0);
  const [totalKoli, setTotalKoli] = useState(1);
  const [totalNonCanKoli, setTotalNonCanKoli] = useState(0);
  const [currStep, setCurrentStep] = useState(1);
  const [arrTempBarang, setArrTempBarang] = useState([]);
  const [arrDetailBarangMbl, setArrDetailBarangMbl] = useState([]);
  const [detailBarangMbl, setDetailBarangMbl] = useState({
    CustBookDId: 0,
    ItemName: "",
    ItemWeight: 0,
    DimP: 0,
    DimL: 0,
    DimT: 0,
    IsPacking: false,
    PackingKayu: false,
    BubbleWrap: false,
    Wrapping: false,
  });
  const [alert, setAlert] = useState({
    Type: "",
    StatusMessage: "",
  });
  const [modalShow, setModalShow] = useState(false);
  const [showPenerimaForm, setShowPenerimaForm] = useState(false);
  const [arrDetailPenerimaMbl, setArrDetailPenerimaMbl] = useState([]);
  const [showKoliForm, setShowKoliForm] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditKoli, setIsEditKoli] = useState(false);
  const [koliData, setKoliData] = useState([]);
  const [arrResiNo, setArrResiNo] = useState([]);
  const [trxNo, setTrxNo] = useState(0);
  const [getData, setGetData] = useState(false);
  const [isPatching, setIsPatching] = useState(false);

  const [options, setOptions] = useState([]);
  const [selectedPckDist, setSelectedPckDist] = useState();
  const [originOptions, setOriginOptions] = useState([]);
  const [selectedDest, setSelectedDest] = useState();
  const [penerimaOptions, setPenerimaOptions] = useState([]);
  const [selectedPenerima, setSelectedPenerima] = useState(null);
  const [hideCOD, setHideCOD] = useState(true);
  const [hideCredit, setHideCredit] = useState(true);
  const [hideReceiverCredit, setHideReceiverCredit] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [refresh, setRefresh] = useState(
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [errors, setErrors] = useState({});

  const style = {
    control: (base, state) => ({
      ...base,
      border: "1px solid rgba(209, 213, 219, var(--tw-border-opacity))",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid rgba(209, 213, 219, var(--tw-border-opacity))",
      },
    }),
  };

  const styleMobile = {
    control: (base, state) => ({
      ...base,
      border: "none",
      boxShadow: "none",
      "&:hover": {
        border: "none",
      },
    }),
  };

  useEffect(() => {
    setModalShow(false);

    async function clear() {
      await clearDatas();
    }
    clear();

    if (acc && acc.IsSubAccount == true) {
      clearDatas();
      history.push("/Home");
    }

    if (acc) {
      dispatch(customerActions.requestGetCustById(acc.CustId));

      var phone = sellerMobilePhnNo.substring(1, 20);
      var area = sellerMobilePhnNo.substring(0, 1);
      if (area === "0") {
        setSellerMobilePhnNo("+62" + phone);
      }

      const pickupAddrTypeObj = {
        CustId: acc.CustId,
        PickupReqGrpStat: "ACT",
      };
      setPickupTime(moment(new Date()).format("YYYY-MM-DD"));
      setMinPickupTime(moment(new Date()).format("YYYY-MM-DD"));
      dispatch(pickupActions.getPickupAddr(pickupAddrTypeObj));
      setSelectedPckDist({ value: 0, label: "Pilih Kecamatan / Kabupaten" });
    }
    if (trxNo !== 0) {
      setShowPenerimaForm(true);
    }
  }, []);

  useEffect(() => {
    if (custObj != null) {
      setHideCredit(!custObj.IsAllowCredit);
    }
  }, [custObj]);

  useEffect(() => {
    setPackageContent("");
    setEnableSubmitted(false);
    setIsInsurance("0");
    if (packageCategory !== null && packageCategory !== "") {
      dispatch(packageContentActions.getPackageContent(packageCategory));
    } else {
      dispatch(packageContentActions.clearPackageContent());
    }
  }, [packageCategory]);

  useEffect(() => {
    if (respSubmitObj && respSubmitObj.ResiNo !== "") {
      async function clear() {
        await clearDataPenerima();
      }
      clear();

      let newArrDetailPenerimaMbl = [];
      newArrDetailPenerimaMbl.push(respSubmitObj);

      if (recipientListObj != undefined) {
        for (let i = 0; i < recipientListObj.length; i++) {
          if (respSubmitObj.ResiNo != recipientListObj[i].ResiNo) {
            newArrDetailPenerimaMbl.push(recipientListObj[i]);
            setResiNos(respSubmitObj.ResiNo);
          }
        }
      }
      dispatch(bookingActions.getListRecipient(newArrDetailPenerimaMbl));
      setShowPenerimaForm(false);
      setIsError(false);
      setIsEditMode(false);
      setAlert({ Type: "", StatusMessage: "" });
    }
  }, [respSubmitObj]);

  useEffect(() => {
    let phone = "";
    if (
      pickupAddrType !== "" &&
      pickupAddressTypeObj &&
      pickupAddressTypeObj.Result &&
      newPickupAddr === false &&
      originObj != null
    ) {
      let pickupAddrTypeObj = pickupAddressTypeObj.Result.filter(
        (rw) => rw.AddrType === pickupAddrType
      );

      if (pickupAddrTypeObj.length > 0) {
        setPickupAddress(pickupAddrTypeObj[0]["Addr"]);
        setPickupContactPerson(pickupAddrTypeObj[0]["ContactPerson"]);
        var pickupPhnNo = pickupAddrTypeObj[0]["PhoneNo"];
        phone = pickupPhnNo.substring(1, 20);
        var area = pickupPhnNo.substring(0, 1);
        if (area === "0") {
          setPickupPhnNo("+62" + phone);
        } else {
          setPickupPhnNo(pickupPhnNo);
        }

        if (districtObj && pickupAddrTypeObj[0]["DistrictId"] !== null && pickupAddrTypeObj[0]["DistrictId"] > 0) {
          let pickupDistrictObj = districtObj.filter(
            (rw) => rw.DistrictId === pickupAddrTypeObj[0]["DistrictId"]
          );

          if (pickupDistrictObj !== null) {
            setSelectedPckDist({
              value: pickupDistrictObj[0]["DistrictId"],
              label: pickupDistrictObj[0]["DistrictDescr"],
            });

            if (pickupDistrictObj[0]["OriginDestId"] > 0) {
              setOriginDestId(pickupDistrictObj[0]["OriginDestId"]);
              if (originObj != null && originObj.Result != null) {
                let selectedOriginObj = originObj?.Result.filter(
                  (rw) => rw.OriginId === pickupDistrictObj[0]["OriginDestId"]
                );
                if (selectedOriginObj.length > 0) {
                  setOriginDestCode(selectedOriginObj[0]["OriginCode"]);
                }
                else {
                  setSelectedPckDist({
                    value: 0,
                    label: "Pilih Kecamatan / Kabupaten",
                  });
                }
              }
            }
          }
        } else {
          setSelectedPckDist({
            value: 0,
            label: "Pilih Kecamatan / Kabupaten",
          });
        }
      } else {
        setPickupAddress(pickupAddressTypeObj.Result[0]["Addr"]);
        setPickupContactPerson(pickupAddressTypeObj.Result[0]["ContactPerson"]);
        var pickupPhnNo = pickupAddressTypeObj.Result[0]["PhoneNo"];
        phone = pickupPhnNo.substring(1, 20);
        var area = pickupPhnNo.substring(0, 1);
        if (area === "0") {
          setPickupPhnNo("+62" + phone);
        } else {
          setPickupPhnNo(pickupPhnNo);
        }

        if (
          districtObj &&
          pickupAddressTypeObj.Result[0]["DistrictId"] !== null
        ) {
          let pickupDistrictObj = districtObj.filter(
            (rw) =>
              rw.DistrictId === pickupAddressTypeObj.Result[0]["DistrictId"]
          );

          if (pickupDistrictObj !== null) {
            setSelectedPckDist({
              value: pickupDistrictObj[0]["DistrictId"],
              label: pickupDistrictObj[0]["DistrictDescr"],
            });

            if (pickupDistrictObj[0]["OriginDestId"] > 0) {
              setOriginDestId(pickupDistrictObj[0]["OriginDestId"]);
              if (originObj != null && originObj.Result != null) {
                let selectedOriginObj = originObj?.Result.filter(
                  (rw) => rw.OriginId === pickupDistrictObj[0]["OriginDestId"]
                );
                setOriginDestCode(selectedOriginObj[0]["OriginCode"]);
              }
            }
          }
        } else {
          setSelectedPckDist({
            value: 0,
            label: "Pilih Kecamatan / Kabupaten",
          });
        }
      }
    } else {
      setPickupAddress("");
      setPickupContactPerson("");
      //   setPickupPhnNo("");
      setSelectedPckDist({ value: 0, label: "Pilih Kecamatan / Kabupaten" });
    }
  }, [pickupAddrType, newPickupAddr, refresh, originObj]);

  useEffect(() => {
    if (districtObj)
      setRefresh(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
  }, [districtObj]);

  useEffect(() => {
    if (!errSubmitObj && !errCalcObj && arrDetailBarangMbl.length > 0) {
      setAlert({ Type: "", StatusMessage: "" });
    }
    if (
      isError == false &&
      selectedDest &&
      selectedDest.value != 0 &&
      paymentType &&
      transportType &&
      packageCategory &&
      packageContent &&
      arrDetailBarangMbl.length > 0 &&
      originDestId > 0 &&
      (isInsurance == "0" || (isInsurance == "1" && nilaiBarang > 0))
    ) {
      let _bookingD = [];

      arrDetailBarangMbl.map((detail, i) => {
        let _arrTempObj = arrTempBarang.find(
          (x) => x.CustBookDId == detail.CustBookDId
        );
        if (_arrTempObj != undefined && _arrTempObj != null) {
          var packingType = "";
          if (detail.PackingKayu) {
            packingType = "KAYU";
          }
          if (detail.BubbleWrap) {
            packingType =
              (packingType != "" ? packingType + ";" : "") + "BUBBLEWRAP";
          }
          if (detail.Wrapping) {
            packingType =
              (packingType != "" ? packingType + ";" : "") + "WRAPPING";
          }

          let newDetailBarang = {
            CustBookDId: detail.CustBookDId,
            ItemCodeNo: "",
            ItemWeight: detail.ItemWeight,
            DimP: detail.DimP,
            DimL: detail.DimL,
            DimT: detail.DimT,
            CustBookDStat: _arrTempObj.CustBookDStat,
            IsPacking: detail.IsPacking,
            PackingType: packingType,
          };
          _bookingD.push(newDetailBarang);
        }
      });

      let selectedPackageContent = packageContentsObj.filter(
        (rw) => rw.PackageContentCode === packageContent
      );

      if (selectedPackageContent.length > 0) {
        if (selectedPackageContent[0].IsInsurance == "1" && nilaiBarang <= 0) {
          setIsError(true);
          setAlert({
            Type: "Error",
            StatusMessage: "Harap isi nilai barang",
          });
        }
        else {
          const bookingHObj = {
            CustId: acc.CustId,
            DestDistrictId: selectedDest.value,
            PaymentType: paymentType,
            TransportType: transportType,
            PackageCategory: packageCategory,
            PackageContentCode: packageContent,
            BookingD: _bookingD,
            EstItemPriceAmt: nilaiBarang,
            OriginDestCode: originDestCode,
          };

          dispatch(bookingActions.requestCalculateBooking(bookingHObj));
          setEnableSubmitted(true);
          if (arrDetailBarangMbl.length == 0 && custBookHDetailObj == 0) {
            setArrTempBarang([]);
          }
        }
      }
    }
  }, [
    arrDetailBarangMbl,
    packageCategory,
    packageContent,
    transportType,
    selectedDest,
    paymentType,
    originDestId,
    isInsurance,
    isError,
    nilaiBarang,
  ]);

  useEffect(() => {
    districtObj &&
      setOptions(
        districtObj.map((dist, i) => ({
          value: dist.DistrictId,
          label: dist.DistrictDescr,
        }))
      );
  }, [districtObj]);

  useEffect(() => {
    if (custBookHDetailObj && packageContentsObj) {
      let selectedPackageContent = packageContentsObj.filter(
        (rw) => rw.PackageContentCode === custBookHDetailObj.PackageContentCode
      );

      setPackageContentNotes(custBookHDetailObj.PackageContentNotes);
      if (selectedPackageContent.length > 0) {
        setPackageContent(selectedPackageContent[0].PackageContentCode);
        setIsInsurance(selectedPackageContent[0].IsInsurance);
      }
    }
  }, [packageContentsObj, custBookHDetailObj]);

  useEffect(() => {
    if (isEditMode) {
      if (isPatching && custBookHDetailObj) {
        setTrxNo(custBookHDetailObj.CustBookHId);
        setSelectedDest({
          value: custBookHDetailObj.DistrictId,
          label: custBookHDetailObj.DistrictDescr,
        });
        setPackageCategory(custBookHDetailObj.PackageCategory);
        setTransportType(custBookHDetailObj.TransportType);
        setPaymentType(custBookHDetailObj.PaymentType);
        setOriginDestCode(custBookHDetailObj.OriginDestCode);
        setRecipientName(custBookHDetailObj.RecipientName);
        let PhoneNo = custBookHDetailObj.RecipientMblPhoneNo;
        if (PhoneNo && PhoneNo.length > 1) {
          var phone = PhoneNo.substring(1, 20);
          var area = PhoneNo.substring(0, 1);
          if (area === "0") {
            PhoneNo = "+62" + phone;
          }
          setRecipientMobilePhnNo(PhoneNo);
        }
        setRecipientAddress(custBookHDetailObj.RecipientAddr);
        setReferenceNo(custBookHDetailObj.CustRefNo);
        setBookingNotes(custBookHDetailObj.BookNotes);
        setTotalKoli(custBookHDetailObj.ActualTotalItem);
        setArrTempBarang(
          custBookHDetailObj.CustBookDs.sort((a, b) => a.SeqNo - b.SeqNo)
        );
        setArrDetailBarangMbl(
          custBookHDetailObj.CustBookDs.map((detail, i) => {
            var packingType = [];

            packingType = detail.PackingType.split(";");
            detail.PackingKayu =
              packingType.findIndex((x) => x == "KAYU") != -1;
            detail.BubbleWrap =
              packingType.findIndex((x) => x == "BUBBLEWRAP") != -1;
            detail.Wrapping =
              packingType.findIndex((x) => x == "WRAPPING") != -1;

            return detail;
          })
        );

        setIsInsurance(custBookHDetailObj.IsInsurance);
        setNilaiBarang(custBookHDetailObj.EstItemPriceAmt);
        setPackageContentNotes(custBookHDetailObj.PackageContentNotes);
        setShowPenerimaForm(true);
      } else if (
        custBookHDetailObj == undefined &&
        custBookHDetailObj == null
      ) {
        setGetData(true);
      }
    }
  }, [custBookHDetailObj]);

  useEffect(() => {
    if (getData) {
      setIsPatching(true);
      const custBookHDetailObj = {
        CustId: acc.CustId,
        CustBookHId: trxNo,
      };
      dispatch(bookingActions.getCustBookHDetail(custBookHDetailObj));
      setGetData(false);
    }
  }, [getData]);

  useEffect(() => {
    getTransportType();
  }, [selectedDest]);

  useEffect(() => {
    if (
      listPenerimaObj != null &&
      listPenerimaObj.Result &&
      listPenerimaObj.Result.length > 0
    ) {
      setPenerimaOptions(
        listPenerimaObj.Result.map((penerima, i) => ({
          value: penerima.CustId,
          label: penerima.CustName,
        }))
      );
    }
  }, [listPenerimaObj]);

  useEffect(() => {
    if (selectedPenerima != null && selectedPenerima.value != 0) {
      dispatch(customerActions.requestGetPenerimaById(selectedPenerima.value));
      setRecipientId(selectedPenerima.value);
    }
  }, [selectedPenerima]);

  useEffect(() => {
    if (recipientName != "" && recipientName.length >= 3) {
      dispatch(customerActions.requestGetPenerimaByHint(recipientName));
    }
  }, [recipientName]);

  useEffect(() => {
    if (penerimaObj != null) {
      setHideReceiverCredit(!penerimaObj.IsAllowReceiverCredit);
    }
  }, [penerimaObj]);

  useEffect(() => {
    if (errCalcObj && errCalcObj.StatusCode != "200") {
      window.scrollTo(0, 0);
      setIsError(true);
      setSubmitted(false);
      setAlert({ Type: "Error", StatusMessage: errCalcObj.StatusMessage });
      setArrDetailBarangMbl((arrDetailBarangMbl) => [
        ...arrDetailBarangMbl.filter(
          (rw) => rw.custBookDId != arrDetailBarangMbl.length
        ),
      ]);
    }
  }, [errCalcObj]);

  useEffect(() => {
    if (respCalcObj && respCalcObj.BookingD.length > 0) {
      setArrTempBarang(
        respCalcObj.BookingD.map((bookingD) => {
          let splitString = bookingD.PackingType.split(";");
          let kayu =
            splitString.findIndex((x) => x == "KAYU") != -1 ? true : false;
          let bubbleWrap =
            splitString.findIndex((x) => x == "BUBBLEWRAP") != -1
              ? true
              : false;
          let wrapping =
            splitString.findIndex((x) => x == "WRAPPING") != -1 ? true : false;

          return {
            ...bookingD,
            IsPacking: kayu || bubbleWrap || wrapping,
            PackingKayu: kayu,
            BubbleWrap: bubbleWrap,
            Wrapping: wrapping,
          };
        }).sort((a, b) => a.SeqNo - b.SeqNo)
      );
      setDetailBarangMbl({
        CustBookDId: 0,
        ItemName: "",
        ItemWeight: 0,
        DimP: 0,
        DimL: 0,
        DimT: 0,
        IsPacking: false,
        PackingKayu: false,
        BubbleWrap: false,
        Wrapping: false,
      });
    } else {
      setArrTempBarang([]);
    }
  }, [respCalcObj]);

  useEffect(() => {
    if (errSubmitObj && errSubmitObj.StatusCode != "200") {
      window.scrollTo(0, 0);
      setIsError(true);
      setSubmitted(false);
      setAlert({ Type: "Error", StatusMessage: errSubmitObj.StatusMessage });
    }
  }, [errSubmitObj]);

  useEffect(() => {
    if (errTransportTypeObj && errTransportTypeObj.StatusCode != "200") {
      window.scrollTo(0, 0);
      setIsError(true);
      setSubmitted(false);
      setAlert({
        Type: "Error",
        StatusMessage: errTransportTypeObj.StatusMessage,
      });
    }
  }, [errTransportTypeObj]);

  useEffect(() => {
    if (detailBarangMbl.CustBookDId != 0) {
      setShowKoliForm(false);
    } else {
      setShowKoliForm(true);
    }
  }, [detailBarangMbl]);

  useEffect(() => {
    if (
      cancelKoliBookingObj &&
      cancelKoliBookingObj.CustBookDs &&
      cancelKoliBookingObj.CustBookDs.length > 0
    ) {
      const newArrTempBarang = [];
      cancelKoliBookingObj.CustBookDs.map((atb) => {
        if (atb.CustBookDStat == "CAN") {
          let newTempBarang = {
            ...atb,
            CustBookDId: atb.CustBookDId,
            SeqNo: atb.SeqNo,
          };
          newArrTempBarang.push(newTempBarang);
        } else {
          arrDetailBarangMbl.map((x, i) => {
            if (x.CustBookDId == atb.CustBookDId) {
              let newTempBarang = {
                ...x,
                CustBookDId: x.CustBookDId,
                SeqNo: x.SeqNo,
              };
              newArrTempBarang.push(newTempBarang);
            }
          });
        }
      });
      let idx = newArrTempBarang.length;
      arrTempBarang.map((atb) => {
        if (atb.CustBookDStat == "NEW") {
          idx++;
          let newTempBarang = {
            ...atb,
            CustBookDId: atb.CustBookDId,
            SeqNo: idx,
          };
          newArrTempBarang.push(newTempBarang);
        }
      });
      setArrTempBarang(newArrTempBarang.sort((a, b) => a.SeqNo - b.SeqNo));
      setArrDetailBarangMbl(newArrTempBarang.sort((a, b) => a.SeqNo - b.SeqNo));
    }
  }, [cancelKoliBookingObj]);

  useEffect(() => {
    if (isEditMode && arrTempBarang && arrTempBarang.length > 0) {
      setTotalNonCanKoli(
        arrTempBarang.filter((x) => x.CustBookDStat != "CAN").length
      );
    }
  }, [arrTempBarang]);

  function handlePackageContentChange(_packageContent) {
    setPackageContent(_packageContent);
    if (
      _packageContent !==
      masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN &&
      _packageContent !==
      masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN
    ) {
      setPackageContentNotes("");
    }
    handleIsInsurance(_packageContent);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    let isValid = true;

    if (recipientName == "") {
      setAlert({
        Type: "Error",
        StatusMessage: "Harap input setidaknya 1 detail penerima",
      });
    }

    if (isInsurance == "1") {
      let isValidNilaiBarang = true;
      let isValidPackageContentNotes = true;

      if (nilaiBarang <= 0) {
        isValidNilaiBarang = false;
      }
      if (packageContentNotes == "") {
        isValidPackageContentNotes = false;
      }

      if (!isValidNilaiBarang || !isValidPackageContentNotes) {
        isValid = false;
        setAlert({
          Type: "Error",
          StatusMessage: "Harap isi form dengan benar.",
        });
      }
    }

    if (isEditMode) {
      if (totalNonCanKoli == 0) {
        setAlert({
          Type: "Error",
          StatusMessage: "Harap input setidaknya 1 detail barang",
        });
        isValid = false;
      }

      if (totalKoli != totalNonCanKoli) {
        isValid = false;
      }
    } else {
      if (arrDetailBarangMbl.length == 0) {
        setAlert({
          Type: "Error",
          StatusMessage: "Harap input setidaknya 1 detail barang",
        });
        isValid = false;
      }

      if (totalKoli != arrDetailBarangMbl.length) {
        isValid = false;
      }
    }

    if (
      isValid &&
      enableSubmitted &&
      sellerName &&
      sellerMobilePhnNo &&
      recipientName &&
      paymentType &&
      packageCategory &&
      packageContent &&
      dropType &&
      transportType &&
      originDestId > 0 &&
      selectedDest &&
      selectedDest.value !== 0
    ) {
      if (
        ((packageContent ===
          masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN ||
          packageContent ===
          masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN) &&
          packageContentNotes) ||
        (packageContent !==
          masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN &&
          packageContent !==
          masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN)
      ) {
        if (
          ((dropType === masterSettingConstants.DROP_TYPE_PICKUP &&
            pickupTime &&
            pickupPhnNo &&
            pickupContactPerson &&
            selectedPckDist &&
            selectedPckDist.value != 0 &&
            originDestId > 0) ||
            dropType === masterSettingConstants.DROP_TYPE_DROP) &&
          ((newPickupAddr === true && pickupAddrType) || !newPickupAddr)
        ) {
          if (isInsurance == "0" || (isInsurance == "1" && nilaiBarang > 0)) {
            let selectedPackageContent = packageContentsObj.filter(
              (rw) => rw.PackageContentCode === packageContent
            );
            setIsInsurance(selectedPackageContent[0].IsInsurance);
          }

          if (
            !errSubmitObj &&
            !errCalcObj &&
            arrDetailBarangMbl.length > 0 &&
            totalKoli == arrDetailBarangMbl.length
          ) {
            setAlert({ Type: "", StatusMessage: "" });
          }

          let newArrDetailBarangMbl = arrDetailBarangMbl;
          newArrDetailBarangMbl.map((newDetail, i) => {
            var packingType = "";
            if (newDetail.PackingKayu) {
              packingType = "KAYU";
            }
            if (newDetail.BubbleWrap) {
              packingType =
                (packingType != "" ? packingType + ";" : "") + "BUBBLEWRAP";
            }
            if (newDetail.Wrapping) {
              packingType =
                (packingType != "" ? packingType + ";" : "") + "WRAPPING";
            }
            newDetail.PackingType = packingType;

            return newDetail;
          });

          const bookingHObj = {
            CustId: acc.CustId,
            SellerName: sellerName,
            AliasName: sellerAliasName,
            SellerMblPhoneNo: formatPhoneNumber(sellerMobilePhnNo),
            Username: acc.Username,
            CustRefNo: referenceNo,
            RecipientAddr: recipientAddress,
            RecipientMblPhoneNo: formatPhoneNumber(recipientMobilePhnNo),
            RecipientName: recipientName,
            PaymentType: paymentType,
            PackageCategory: packageCategory,
            PackageContentCode: packageContent,
            EstTotalWeight: estItemWeight,
            PackageContentNotes: packageContentNotes,
            PickupNotes: pickupNotes,
            PickupTime: pickupTime,
            PickupPhoneNo: pickupPhnNo,
            PickupContactPerson: pickupContactPerson,
            PickupDistrictId: selectedPckDist.value,
            PickupAddr: pickupAddress,
            DropType: dropType,
            TotalItem: isEditMode
              ? totalNonCanKoli
              : newArrDetailBarangMbl.length,
            BookNotes: bookingNotes,
            PickupAddrType: pickupAddrType,
            EstItemPriceAmt: nilaiBarang,
            DestDistrictId: selectedDest.value,
            DestDistrictName: selectedDest.label,
            TransportType: transportType,
            BookDetails: newArrDetailBarangMbl,
            OriginDestCode: originDestCode,
            CustBookHId: trxNo !== 0 ? trxNo : 0,
          };

          const submitBooking = async () => {
            dispatch(bookingActions.requestSubmitBooking(bookingHObj));
          };
          submitBooking();
        }
      }
    } else {
      window.scrollTo(0, 0);
    }
  }

  function handleNextStep(e) {
    e.preventDefault();
    setSubmitted(true);
    if (currStep == 1) {
      if (sellerName && sellerMobilePhnNo && dropType) {
        if (
          dropType === masterSettingConstants.DROP_TYPE_PICKUP &&
          pickupTime &&
          pickupPhnNo &&
          pickupContactPerson &&
          selectedPckDist &&
          selectedPckDist.value != 0 &&
          pickupAddress &&
          ((newPickupAddr === true && pickupAddrType) || !newPickupAddr)
        ) {
          let pickupDistrictObj = districtObj.filter(
            (rw) => rw.DistrictId === selectedPckDist.value
          );
          setOriginDestId(pickupDistrictObj[0].OriginDestId);
          if (originObj != null && originObj.Result != null) {
            let selectedOriginObj = originObj.Result.filter(
              (rw) => rw.OriginId === pickupDistrictObj[0]["OriginDestId"]
            );
            if (selectedOriginObj.length > 0) {
              setOriginDestCode(selectedOriginObj[0]["OriginCode"]);
            }
          }
          if (pickupDistrictObj.length > 0 && pickupDistrictObj[0].IsPickup === "1") {
            setIsError(false);
            setCurrentStep(currStep + 1);
            setSubmitted(false);
            dispatch(bookingActions.clearRecipientList());
          } else {
            setIsError(true);
            setSubmitted(false);
            setAlert({
              Type: "Info",
              StatusMessage: "Maaf, Kecamatan Pickup Belum Tercover",
            });
          }
        }
      }
    } else if (currStep == 2) {
      if (
        recipientName &&
        recipientAddress &&
        recipientMobilePhnNo &&
        transportType &&
        paymentType &&
        packageCategory &&
        packageContent &&
        selectedDest &&
        selectedDest.value != 0 &&
        (isInsurance == "0" || (isInsurance == "1" && nilaiBarang > 0))
      ) {
        if (
          ((packageContent ===
            masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN ||
            packageContent ===
            masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN) &&
            packageContentNotes) ||
          (packageContent !==
            masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN &&
            packageContent !==
            masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN)
        ) {
          setCurrentStep(currStep + 1);
          setSubmitted(false);

          let selectedPackageContent = packageContentsObj.filter(
            (rw) => rw.PackageContentCode === packageContent
          );
          setIsInsurance(selectedPackageContent[0].IsInsurance);
        }
      }
    }

    if (!errSubmitObj && !errCalcObj && arrDetailBarangMbl.length > 0) {
      setAlert({ Type: "", StatusMessage: "" });
    }
  }

  function handlePrevStep(e) {
    e.preventDefault();
    setCurrentStep(currStep - 1);
  }

  function handleSelesai(e) {
    if (recipientListObj && recipientListObj.length > 0) {
      e.preventDefault();
      setSubmitted(true);
      setModalShow(true);
    }
  }

  async function clearDatas() {
    setReferenceNo("");
    setRecipientId(0);
    setRecipientName("");
    setRecipientMobilePhnNo("");
    setRecipientAddress("");
    setDropType("PICKUP");
    setPaymentType("");
    setTransportType("");
    setEstItemWeight(0);
    setPackageContent("");
    setPackageContentNotes("");
    setPickupContactPerson("");
    setPickupAddress("");
    setPickupNotes("");
    setPickupPhnNo("");
    setPickupTime(moment(new Date()).format("YYYY-MM-DD"));
    setMinPickupTime(moment(new Date()).format("YYYY-MM-DD"));
    setBookingNotes("");
    setPackageCategory("");
    setCurrentStep(1);
    setArrTempBarang([]);
    setArrDetailBarangMbl([]);
    setArrDetailPenerimaMbl([]);
    setIsInsurance("0");
    setSelectedPckDist({ value: 0, label: "Pilih Kecamatan / Kabupaten" });
    setSelectedDest({ value: 0, label: "Pilih Tujuan" });
    setSelectedPenerima({ value: 0, label: "Pilih Penerima" });
    const clearData = async () => {
      dispatch(packageContentActions.clearPackageContent());
      dispatch(bookingActions.clearCalculateBooking());
      dispatch(districtActions.getDistrict());
      dispatch(bookingActions.clearSubmitBooking());
      dispatch(originActions.getOrigin());
      dispatch(transportTypeActions.clearTransportType());
    };
    clearData();
    setSubmitted(false);
    setEnableSubmitted(false);
    setResiNos("");
    setOriginDestId(0);
    setOriginDestCode("");
  }

  function clearDataPenerima() {
    const clearData = async () => {
      dispatch(bookingActions.clearCustBookHDetail());
      dispatch(packageContentActions.clearPackageContent());
      dispatch(bookingActions.clearCalculateBooking());
      dispatch(bookingActions.clearSubmitBooking());
      dispatch(originActions.getOrigin());
    };
    clearData();
    setReferenceNo("");
    setRecipientId(0);
    setRecipientName("");
    setRecipientMobilePhnNo("");
    setRecipientAddress("");
    setDropType("PICKUP");
    setPaymentType("");
    setTransportType("");
    setEstItemWeight(0);
    setPackageContent("");
    setPackageContentNotes("");
    setBookingNotes("");
    setPackageCategory("");
    setArrTempBarang([]);
    setArrDetailBarangMbl([]);
    setIsInsurance("0");
    setNilaiBarang(0);
    setSelectedDest({ value: 0, label: "Pilih Tujuan" });
    setSelectedPenerima({ value: 0, label: "Pilih Penerima" });
    setSubmitted(false);
    setEnableSubmitted(false);
    setTotalKoli(1);
    setTrxNo(0);
  }

  function detailKoliOnChange(event, isNumeric = true) {
    const { name, value, type, checked } = event.target;
    let val = type === "checkbox" ? checked : value;

    if (isNumeric) {
      let num = 0;
      if (val != "") {
        const str = val.replace(/,/g, "");
        num = parseFloat(str);
      }

      setDetailBarangMbl((prevState) => ({
        ...prevState,
        [name]: num,
      }));
    } else {
      setDetailBarangMbl((prevState) => ({
        ...prevState,
        [name]: val,
      }));
    }
  }

  function handleNilaiBarang(event) {
    if (event.target.value != "") {
      const str = event.target.value.replace(/,/g, "");
      setNilaiBarang(parseFloat(str));
    }
  }

  function handleIsInsurance(_packageContent = "") {
    if (_packageContent == "") {
      _packageContent = packageContent;
    }

    if (packageContentsObj) {
      let selectedPackageContent = packageContentsObj.find(
        (rw) => rw.PackageContentCode === _packageContent
      );

      setIsInsurance(selectedPackageContent.IsInsurance);
      setNilaiBarang(0);
      setPackageContentNotes('');
    }
  }

  function getTransportType() {
    if (selectedDest && selectedDest.value != 0) {
      const transportTypeObj = {
        OriginDestId: originDestId,
        DestDistrictId: selectedDest.value,
      };

      dispatch(transportTypeActions.getTransportType(transportTypeObj));
    } else {
      dispatch(transportTypeActions.clearTransportType());
    }
  }

  function onSubmitDetailBarang() {
    setEnableSubmitted(false);
    setSimpanDataBarang(true);
    setIsError(false);

    setArrDetailBarangMbl(
      arrDetailBarangMbl.map((detail, i) => {
        if (detail.CustBookDId == detailBarangMbl.CustBookDId) {
          detail.ItemWeight = detailBarangMbl.ItemWeight;
          detail.DimP = detailBarangMbl.DimP;
          detail.DimL = detailBarangMbl.DimL;
          detail.DimT = detailBarangMbl.DimT;
          detail.IsPacking =
            detailBarangMbl.PackingKayu ||
            detailBarangMbl.BubbleWrap ||
            detailBarangMbl.Wrapping;
          detail.PackingKayu = detailBarangMbl.PackingKayu;
          detail.BubbleWrap = detailBarangMbl.BubbleWrap;
          detail.Wrapping = detailBarangMbl.Wrapping;
        }
        return detail;
      })
    );

    setSimpanDataBarang(false);
  }

  const handleChangeDistrictPickup = (selectedOption) => {
    setSelectedPckDist(selectedOption);
    setIsError(false);
  };

  const handleChangeDest = (selectedOption) => {
    setSelectedDest(selectedOption);
    handleTagihTujuan(selectedOption.value);
  };

  function navigateToHome() {
    setModalShow(false);
    setSubmitted(false);
    clearDatas();
    history.push("/Home");
  }

  function navigateToCreateBooking() {
    setModalShow(false);
    setSubmitted(false);
    clearDatas();
  }

  function navigateToBookingDetail() {
    async function clear() {
      await clearDataPenerima();
    }
    clear();

    setModalShow(false);
    setSubmitted(false);
    if (arrResiNo.length > 0) {
      const myArray = arrResiNo;
      const arrayToString = myArray.join("|");
      window.open(`/booking-print?trxNo=${arrayToString}`, "_blank");
    } else if (resiNos != "") {
      window.open(`/booking-print?trxNo=${resiNos}`, "_blank");
    } else {
      setIsError(true);
      setAlert({
        Type: "Info",
        StatusMessage: "Harap pilih data penerima terlebih dahulu",
      });
    }
  }

  function handleTagihTujuan(districtId) {
    let _districtObj = districtObj.find((x) => x.DistrictId == districtId);
    let custCOD = custObj.IsAllowCOD;
    let tujuanCOD = false;
    let isAllowRcvPayment = "1";

    if (_districtObj != null) {
      isAllowRcvPayment = _districtObj.IsAllowRcvPayment;
      tujuanCOD = isAllowRcvPayment == "1" ? true : false;
      if (custCOD && tujuanCOD) setHideCOD(false);
      else setHideCOD(true);
    } else {
      setHideCOD(!custCOD);
    }
  }

  function onAddDetailBarang() {
    if (
      recipientName &&
      // recipientAddress && 26Okt
      // recipientMobilePhnNo &&
      transportType &&
      paymentType &&
      packageCategory &&
      packageContent &&
      selectedDest &&
      selectedDest.value != 0 &&
      (isInsurance == "0" || (isInsurance == "1" && nilaiBarang > 0))
    ) {
      setIsError(false);
      return true;
    } else {
      setIsError(true);
      if (isInsurance == "1" && nilaiBarang <= 0) {
        setAlert({
          Type: "Error",
          StatusMessage: "Harap isi nilai barang",
        });
      }
      else {
        setAlert({
          Type: "Info",
          StatusMessage: "Harap lengkapi data pengirim terlebih dahulu",
        });
      }
      return false;
    }
  }

  function generateKoli() {
    let newArrTempBarang = [...arrTempBarang];
    let validation = onAddDetailBarang();

    if (!validation) {
      window.scrollTo(0, 0);
      return;
    }

    if (isEditMode) {
      let filterArrDetail = [];

      arrTempBarang.map((detail, i) => {
        let newDetailBarang = {
          CustBookDId: detail.CustBookDId,
          ItemCodeNo: "",
          ItemWeight: detail.ItemWeight,
          DimP: detail.DimP,
          DimL: detail.DimL,
          DimT: detail.DimT,
          CustBookDStat: detail.CustBookDStat,
          IsPacking: detail.IsPacking,
          PackingKayu: detail.PackingKayu,
          BubbleWrap: detail.BubbleWrap,
          Wrapping: detail.Wrapping,
        };
        filterArrDetail.push(newDetailBarang);
      });
      let arrTempBarangLength = arrTempBarang.length;

      if (totalKoli <= totalNonCanKoli) return;
      else {
        const newTempArrDetailBarangMbl = [...filterArrDetail];

        const countToAdd = totalKoli - totalNonCanKoli;
        for (let i = 0; i < countToAdd; i++) {
          let newObj = {
            CustBookDId: arrTempBarangLength + (i + 1),
            ItemCodeNo: "",
            ItemWeight: 0,
            DimP: 0,
            DimL: 0,
            DimT: 0,
            CustBookDStat: "NEW",
            IsPacking: false,
            PackingKayu: false,
            BubbleWrap: false,
            Wrapping: false,
          };
          newTempArrDetailBarangMbl.push(newObj);

          newObj.SeqNo = arrTempBarangLength + (i + 1);
          newObj.VolWeight = 0;
          newObj.FinalWeight = 0;
          newObj.TotalCalcSrc = "Item";
          newArrTempBarang.push(newObj);
        }
        setArrDetailBarangMbl(newTempArrDetailBarangMbl);
        setArrTempBarang(newArrTempBarang.sort((a, b) => a.SeqNo - b.SeqNo));
      }
    } else {
      const newKoliData = [...koliData];
      const newTempArrDetailBarangMbl = [...arrDetailBarangMbl];
      if (arrDetailBarangMbl.length > 0) {
        koliData.length = arrDetailBarangMbl.length;
      }

      if (totalKoli > koliData.length) {
        for (let i = arrDetailBarangMbl.length; i < totalKoli; i++) {
          let newObj = {
            CustBookDId: i + 1,
            ItemCodeNo: "",
            ItemWeight: 0,
            DimP: 0,
            DimL: 0,
            DimT: 0,
            CustBookDStat: "NEW",
            IsPacking: false,
            PackingKayu: false,
            BubbleWrap: false,
            Wrapping: false,
          };
          newTempArrDetailBarangMbl.push(newObj);

          newObj.SeqNo = i + 1;
          newObj.VolWeight = 0;
          newObj.FinalWeight = 0;
          newObj.TotalCalcSrc = "Item";
          newArrTempBarang.push(newObj);
        }
      } else if (totalKoli < koliData.length) {
        const countToRemove = koliData.length - totalKoli;
        newKoliData.splice(-countToRemove, countToRemove);
        newTempArrDetailBarangMbl.splice(totalKoli);
      }
      setKoliData(newKoliData);
      setArrDetailBarangMbl(newTempArrDetailBarangMbl);
      setArrTempBarang(newArrTempBarang.sort((a, b) => a.SeqNo - b.SeqNo));
    }
  }

  function handleCancel(ev) {
    if (ev.CustBookDStat == "ACT") {
      let reqObj = {
        Id: ev.CustBookDId,
        username: acc.Username,
      };
      dispatch(bookingActions.cancelKoliBooking(reqObj));
    } else if (ev.CustBookDStat == "NEW") {
      const newArrTempBarang = [];
      let idx = 1;
      arrTempBarang.map((atb) => {
        if (ev.CustBookDId != atb.CustBookDId) {
          let newTempBarang = {
            ...atb,
            CustBookDId: atb.CustBookDId,
            SeqNo: idx,
          };
          newArrTempBarang.push(newTempBarang);
          idx++;
        }
      });
      setArrTempBarang(newArrTempBarang);
      setArrDetailBarangMbl(newArrTempBarang);
    }
  }

  return (
    <>
      <div className="hidden sm:block max-w-6xl mx-auto">
        <div className="flex flex-row">
          <h4 className="w-full text-center text-xl font-semibold py-2">
            Buat Booking
          </h4>
        </div>
        <div className="flex items-center border-b-2 border-gray-300 px-3 py-2 text-xs sm:text-sm">
          <div className="flex items-center h-full w-full ">
            <div className="flex items-center">
              <div className="rounded-full text-center transition duration-500 ease-in-out h-8 w-8 py-1 border-2 bg-red-600 border-red-600 text-white">
                1
              </div>
              <div className="mx-2 text-center items-center text-xs font-medium">
                Pengirim
              </div>
            </div>
            <div
              className={`flex-auto border-t-2 mr-2 transition duration-500 ease-in-out ${currStep > 1 ? "border-red-600" : "border-gray-500"
                } `}
            ></div>
            <div className="flex items-center">
              <div
                className={`rounded-full text-center transition duration-500 ease-in-out h-8 w-8 py-1 border-2 border-red-600 ${currStep > 1 ? "bg-red-600 text-white" : "text-red-600"
                  } `}
              >
                2
              </div>
              <div className="mx-2 text-center items-center text-xs font-medium">
                Penerima
              </div>
            </div>
          </div>
        </div>
        <AlertMessage
          isError={isError}
          alert={alert}
          setAlert={setAlert}
          setIsError={setIsError}
        />
        <form onSubmit={handleSubmit} method="POST">
          {currStep === 1 ? (
            <div className="h-full w-full text-xs sm:text-sm font-medium">
              <div className="row px-2 py-1">
                <div className="col-md-2 text-xs sm:text-sm font-bold">
                  Daftar Alamat
                </div>
                <div className="col-md-1 text-xs sm:text-sm">
                  {newPickupAddr === false ? (
                    <button
                      type="button"
                      className="px-4 py-1 -ml-10 justify-center items-center border border-transparent font-medium rounded-full text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:border-blue-500 focus:shadow-outline-blue active:bg-blue-500 transition duration-150 ease-in-out"
                      onClick={() => {
                        setNewPickupAddr(true);
                        setPickupAddrType("");
                      }}
                    >
                      Tambah
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="px-4 py-1 -ml-10 justify-center items-center border border-transparent font-medium rounded-full text-white bg-yellow-500 hover:bg-yellow-400 focus:outline-none focus:border-yellow-500 focus:shadow-outline-yellow active:bg-yellow-500 transition duration-150 ease-in-out"
                      onClick={() => {
                        setNewPickupAddr(false);
                        setPickupAddrType("");
                      }}
                    >
                      Batal
                    </button>
                  )}
                </div>
                <div className="col-md-3 text-xs sm:text-sm">
                  {newPickupAddr === false ? (
                    <select
                      className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                      id="pickupAddressType"
                      name="pickupAddressType"
                      value={pickupAddrType}
                      onChange={(e) => setPickupAddrType(e.target.value)}
                    >
                      <option value="" disabled hidden>
                        Pilih daftar alamat
                      </option>
                      {pickupAddressTypeObj &&
                        pickupAddressTypeObj.Result &&
                        pickupAddressTypeObj.Result.map(
                          (pickupAddressTypeObj, i) => (
                            <option
                              key={i}
                              value={pickupAddressTypeObj.AddrType}
                            >
                              {pickupAddressTypeObj.AddrType}
                            </option>
                          )
                        )}
                    </select>
                  ) : (
                    <input
                      id="pickupAddressTypeTxt"
                      type="text"
                      name="pickupAddressTypeTxt"
                      className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                      onChange={(e) => setPickupAddrType(e.target.value)}
                      value={pickupAddrType}
                      placeholder="Isi daftar alamat"
                    />
                  )}
                  {submitted && !pickupAddrType && newPickupAddr === true && (
                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                      Harap isi daftar alamat
                    </div>
                  )}
                </div>
              </div>
              <div className="row px-2 py-1">
                <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                  Contact Person<p className="text-red-500">&nbsp;*</p>
                </div>
                <div className="col-md-3 text-xs sm:text-sm">
                  <input
                    id="pickupContactPerson"
                    type="text"
                    name="pickupContactPerson"
                    placeholder="Isi contact person"
                    className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                    onChange={(e) => setPickupContactPerson(e.target.value)}
                    value={pickupContactPerson}
                  />
                  {submitted && !pickupContactPerson && (
                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                      Harap isi contact person
                    </div>
                  )}
                </div>
                <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                  Nomor Ponsel Contact Person
                  <p className="text-red-500">&nbsp;*</p>
                </div>
                <div className="col-md-3 text-xs sm:text-sm">
                  {pickupAddressTypeObj ? (
                    <Input
                      id="pickupPhnNo"
                      placeholder="Isi nomor ponsel contact person "
                      className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                      country="ID"
                      value={pickupPhnNo}
                      onChange={setPickupPhnNo}
                    />
                  ) : (
                    <div>Loading pickup address data...</div>
                  )}

                  {submitted && !pickupPhnNo && (
                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                      Harap isi nomor ponsel contact person
                    </div>
                  )}
                </div>
              </div>
              <div className="row px-2 py-1">
                <div className="col-md-3 text-xs sm:text-sm font-bold">
                  Type
                </div>
                <div className="col-md-3 text-xs sm:text-sm">
                  <select
                    className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                    id="dropType"
                    name="dropType"
                    value={dropType}
                    onChange={(e) => setDropType(e.target.value)}
                  >
                    <option value="" disabled hidden>
                      Pilih type
                    </option>
                    <option value={masterSettingConstants.DROP_TYPE_PICKUP}>
                      Pickup
                    </option>
                  </select>
                  {submitted && !dropType && (
                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                      Harap pilih type
                    </div>
                  )}
                </div>
              </div>
              {dropType != null &&
                dropType === masterSettingConstants.DROP_TYPE_PICKUP && (
                  <>
                    <div className="row px-2 py-1">
                      <div className="col-md-3 text-xs sm:text-sm font-bold">
                        Tanggal Pickup
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <input
                          aria-label="Tanggal Pickup"
                          id="pickupTimes"
                          name="pickupTimes"
                          type="date"
                          value={pickupTime}
                          className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          onChange={(e) => setPickupTime(e.target.value)}
                          min={minPickupTime}
                        />
                        {submitted && !pickupTime && (
                          <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                            Harap isi tanggal pickup
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row px-2 py-1">
                      <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                        Alamat Pickup<p className="text-red-500">&nbsp;*</p>
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <textarea
                          id="pickupAddress"
                          name="pickupAddress"
                          rows="3"
                          className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          style={{
                            boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)",
                          }}
                          value={pickupAddress}
                          disabled={!newPickupAddr}
                          onChange={(e) => setPickupAddress(e.target.value)}
                          placeholder="Isi alamat pickup"
                        />
                        {submitted && !pickupAddress && (
                          <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                            Harap isi alamat pickup
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                        Kecamatan / Kabupaten Pickup
                        <p className="text-red-500">&nbsp;*</p>
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <Select
                          filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                          value={selectedPckDist}
                          options={options}
                          onChange={handleChangeDistrictPickup}
                          className="rounded-sm text-xs sm:text-sm border-gray-300"
                          styles={style}
                          disabled={!newPickupAddr}
                        />
                        {submitted &&
                          (!selectedPckDist || selectedPckDist.value <= 0) && (
                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                              Harap isi kecamatan / kabupaten pickup
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="row px-2 py-1">
                      <div className="col-md-3 text-xs sm:text-sm font-bold">
                        Catatan
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <textarea
                          id="pickupNotes"
                          name="pickupNotes"
                          rows="3"
                          className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          style={{
                            boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)",
                          }}
                          value={pickupNotes}
                          onChange={(e) => setPickupNotes(e.target.value)}
                          placeholder="Isi catatan pickup"
                        />
                      </div>
                    </div>
                  </>
                )}
            </div>
          ) : (
            <>
              {showPenerimaForm && (
                <>
                  <div className="h-full w-full text-xs sm:text-sm font-medium">
                    <div className="row px-2 py-1">
                      <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                        Alias Pengirim<p className="text-red-500">&nbsp;*</p>
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <input
                          id="sellerAliasName"
                          type="text"
                          name="sellerAliasName"
                          className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          placeholder="Isi nama alias pengirim"
                          value={sellerAliasName}
                          onChange={(e) => setSellerAliasName(e.target.value)}
                        />
                        {submitted && !sellerAliasName && (
                          <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                            Harap isi alias pengirim
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                        Nomor Ponsel Pengirim
                        <p className="text-red-500">&nbsp;*</p>
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <Input
                          className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          country="ID"
                          value={sellerMobilePhnNo}
                          onChange={setSellerMobilePhnNo}
                          placeholder="Isi nomor ponsel pengirim"
                        />
                        {submitted && !sellerMobilePhnNo && (
                          <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                            Harap isi nomor ponsel pengirim
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row px-2 py-1">
                      <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                        Nama Penerima<p className="text-red-500">&nbsp;*</p>
                      </div>
                      {!isMember && (
                        <div className="col-md-3 text-xs sm:text-sm">
                          <input
                            id="recipientName"
                            type="text"
                            name="recipientName"
                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                            placeholder="Isi nama penerima"
                            value={recipientName}
                            onChange={(e) => setRecipientName(e.target.value)}
                          />
                          {submitted && !recipientName && (
                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                              Harap isi nama penerima
                            </div>
                          )}
                        </div>
                      )}
                      <div className="col-md-3 text-xs sm:text-sm font-bold">
                        Nomor Ponsel Penerima
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <Input
                          id="recipientMobilePhnNo"
                          className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          country="ID"
                          value={recipientMobilePhnNo}
                          onChange={setRecipientMobilePhnNo}
                          placeholder="Isi nomor ponsel penerima"
                        />
                      </div>
                    </div>
                    <div className="row px-2 py-1">
                      <div className="col-md-3 text-xs sm:text-sm font-bold">
                        Alamat Penerima
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <textarea
                          id="recipientAddress"
                          name="recipientAddress"
                          rows="3"
                          className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          style={{
                            boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)",
                          }}
                          value={recipientAddress}
                          onChange={(e) => setRecipientAddress(e.target.value)}
                          placeholder="Isi alamat penerima"
                        />
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                        Nomor Referensi
                        {acc.IsCustRefNoMandatory && (
                          <p className="text-red-500">&nbsp;*</p>
                        )}
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <input
                          id="referenceNo"
                          type="text"
                          name="referenceNo"
                          placeholder="Isi customer reference no"
                          maxLength="50"
                          className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          onChange={(e) => setReferenceNo(e.target.value)}
                          value={referenceNo}
                        />
                        {acc.IsCustRefNoMandatory &&
                          submitted &&
                          !referenceNo && (
                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                              Harap isi Nomor Referensi.
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="row px-2 py-1">
                      <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                        Tujuan<p className="text-red-500">&nbsp;*</p>
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        {districtObj && selectedDest && (
                          <Select
                            filterOption={createFilter({
                              ignoreAccents: false,
                            })}
                            value={selectedDest}
                            options={options}
                            onChange={handleChangeDest}
                            className="rounded-sm text-xs sm:text-sm border-gray-300 w-100"
                            styles={style}
                          />
                        )}
                        {submitted &&
                          (!selectedDest || selectedDest.value === 0) && (
                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                              Harap isi tujuan
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="row px-2 py-1">
                      <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                        Jalur<p className="text-red-500">&nbsp;*</p>
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <select
                          className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          id="transportType"
                          name="transportType"
                          value={transportType}
                          onChange={(e) => setTransportType(e.target.value)}
                        >
                          <option value="" disabled hidden>
                            Pilih jalur
                          </option>
                          {transportTypeObj &&
                            transportTypeObj.TransportTypes.map(
                              (transportTypeObj) => (
                                <option
                                  key={transportTypeObj.TransportTypeCode}
                                  value={transportTypeObj.TransportTypeCode}
                                >
                                  {transportTypeObj.TransportTypeName}
                                </option>
                              )
                            )}
                        </select>
                        {submitted && !transportType && (
                          <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                            Harap pilih metode pengiriman
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                        Pembayaran<p className="text-red-500">&nbsp;*</p>
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <select
                          className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          id="paymentType"
                          name="paymentType"
                          value={paymentType}
                          onChange={(e) => setPaymentType(e.target.value)}
                        >
                          <option value="" disabled hidden>
                            Pilih metode pembayaran
                          </option>
                          <option value={masterSettingConstants.PAYTYPE_LUNAS}>
                            Lunas
                          </option>
                          <option
                            value={masterSettingConstants.PAYTYPE_KREDIT}
                            hidden={hideCredit}
                          >
                            Kredit
                          </option>
                          <option
                            value={masterSettingConstants.PAYTYPE_TAGIHTUJUAN}
                            hidden={hideCOD}
                          >
                            Tagih Tujuan
                          </option>
                          <option
                            value={
                              masterSettingConstants.PAYTYPE_KREDITPENERIMA
                            }
                            hidden={hideReceiverCredit}
                          >
                            Kredit Penerima
                          </option>
                        </select>

                        {submitted && !paymentType && (
                          <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                            Harap pilih metode pembayaran
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row px-2 py-1">
                      <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                        Jenis Barang<p className="text-red-500">&nbsp;*</p>
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <select
                          className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          id="packageCategory"
                          name="packageCategory"
                          value={packageCategory}
                          onChange={(e) => setPackageCategory(e.target.value)}
                        >
                          <option value="" disabled hidden>
                            Pilih jenis barang
                          </option>
                          <option
                            value={
                              masterSettingConstants.PACKAGE_CATEGORY_ELEKTRONIK
                            }
                          >
                            ELEKTRONIK
                          </option>
                          <option
                            value={
                              masterSettingConstants.PACKAGE_CATEGORY_NON_ELEKTRONIK
                            }
                          >
                            NON ELEKTRONIK
                          </option>
                        </select>
                        {(submitted || simpanDataBarang) &&
                          !packageCategory && (
                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                              Harap pilih jenis barang
                            </div>
                          )}
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                        Tipe Barang<p className="text-red-500">&nbsp;*</p>
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <select
                          className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          id="packageContent"
                          name="packageContent"
                          value={packageContent}
                          onChange={(e) => handlePackageContentChange(e.target.value)}
                        >
                          <option value="" disabled hidden>
                            Pilih tipe barang
                          </option>
                          {packageContentsObj &&
                            packageContentsObj.map((packageContentsObj) => (
                              <option
                                key={packageContentsObj.PackageContentCode}
                                value={packageContentsObj.PackageContentCode}
                              >
                                {packageContentsObj.PackageContentName}
                              </option>
                            ))}
                        </select>
                        {(submitted || simpanDataBarang) && !packageContent && (
                          <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                            Harap pilih tipe barang
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row px-2 py-1">
                      <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                        Total Koli<p className="text-red-500">&nbsp;*</p>
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <NumericFormat
                          id="totalKoli"
                          name="totalKoli"
                          thousandSeparator={true}
                          allowLeadingZeros={false}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          onChange={(e) => setTotalKoli(e.target.value)}
                          value={totalKoli}
                        />
                        {submitted &&
                          totalKoli != arrDetailBarangMbl.length &&
                          totalKoli != totalNonCanKoli && (
                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                              Total Koli tidak sama dengan jumlah detail barang
                            </div>
                          )}
                      </div>
                      {isInsurance && isInsurance === "1" && (
                        <>
                          <div className="col-md-3 text-xs sm:text-sm font-bold flex flex-row">
                            Nilai Barang <p className="text-red-500">&nbsp;*</p>
                          </div>
                          <div className="col-md-3 text-xs sm:text-sm">
                            <NumericFormat
                              id="estItemPriceAmt"
                              name="estItemPriceAmt"
                              thousandSeparator={true}
                              decimalScale={0}
                              fixedDecimalScale={true}
                              className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                              onChange={(e) => handleNilaiBarang(e)}
                              value={nilaiBarang}
                              placeholder="Isi Nilai barang"
                            />
                            {(submitted || simpanDataBarang) &&
                              nilaiBarang <= 0 &&
                              isInsurance == "1" && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                  Harap isi nilai barang
                                </div>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row px-2 py-1">
                      <div className="col-md-3 text-xs sm:text-sm font-bold">
                        Catatan Order
                      </div>
                      <div className="col-md-3 text-xs sm:text-sm">
                        <textarea
                          id="bookNotes"
                          name="bookNotes"
                          rows="3"
                          className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                          style={{
                            boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)",
                          }}
                          value={bookingNotes}
                          onChange={(e) => setBookingNotes(e.target.value)}
                          placeholder="Isi catatan order"
                        />
                      </div>
                      {(packageContent ===
                        masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN ||
                        packageContent ===
                        masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN ||
                        isInsurance === "1") && (
                          <>
                            <div className="col-md-3 text-xs sm:text-sm font-bold flex">
                              Catatan Paket<p className="text-red-500">&nbsp;*</p>
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                              <textarea
                                id="packageContentNotes"
                                name="packageContentNotes"
                                rows="3"
                                className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                style={{
                                  boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)",
                                }}
                                value={packageContentNotes}
                                onChange={(e) =>
                                  setPackageContentNotes(e.target.value)
                                }
                                placeholder="Isi catatan paket"
                              />
                              {submitted && !packageContentNotes && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                  Harap isi catatan paket
                                </div>
                              )}
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                  <div className="h-full w-full text-xs sm:text-sm font-medium">
                    {!showKoliForm && (
                      <>
                        <div className="row px-2 py-1 vertical-center">
                          <div className="col-md-6 text-sm sm:text-md font-bold vertical-center">
                            <p style={{ fontSize: 30 }}>Detail Koli</p>
                          </div>
                        </div>
                        <div className="row px-2 py-1 vertical-center">
                          <div className="col-md-3 text-xs sm:text-sm font-bold">
                            Estimasi Berat Barang (kg)
                          </div>
                          <div className="col-md-3 text-xs sm:text-sm">
                            <NumericFormat
                              id="ItemWeight"
                              name="ItemWeight"
                              thousandSeparator={true}
                              allowLeadingZeros={false}
                              decimalScale={2}
                              fixedDecimalScale={false}
                              className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                              onChange={(e) => detailKoliOnChange(e, true)}
                              value={detailBarangMbl.ItemWeight}
                              placeholder="Isi estimasi berat barang"
                            />
                          </div>
                        </div>
                        <div className="row px-2 py-1 vertical-center">
                          <div className="col-md-3 text-xs sm:text-sm font-bold">
                            Panjang (cm)
                          </div>
                          <div className="col-md-3 text-xs sm:text-sm">
                            <NumericFormat
                              id="DimP"
                              name="DimP"
                              thousandSeparator={true}
                              allowLeadingZeros={false}
                              decimalScale={0}
                              fixedDecimalScale={true}
                              className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                              onChange={(e) => detailKoliOnChange(e, true)}
                              value={detailBarangMbl.DimP}
                              placeholder="Isi dimensi panjang barang"
                            />
                          </div>
                        </div>
                        <div className="row px-2 py-1 vertical-center">
                          <div className="col-md-3 text-xs sm:text-sm font-bold">
                            Lebar (cm)
                          </div>
                          <div className="col-md-3 text-xs sm:text-sm">
                            <NumericFormat
                              id="DimL"
                              name="DimL"
                              thousandSeparator={true}
                              allowLeadingZeros={false}
                              decimalScale={0}
                              fixedDecimalScale={true}
                              className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                              onChange={(e) => detailKoliOnChange(e, true)}
                              value={detailBarangMbl.DimL}
                              placeholder="Isi dimensi lebar barang"
                            />
                          </div>
                        </div>
                        <div className="row px-2 py-1 vertical-center">
                          <div className="col-md-3 text-xs sm:text-sm font-bold">
                            Tinggi (cm)
                          </div>
                          <div className="col-md-3 text-xs sm:text-sm">
                            <NumericFormat
                              id="DimT"
                              name="DimT"
                              thousandSeparator={true}
                              allowLeadingZeros={false}
                              decimalScale={0}
                              fixedDecimalScale={true}
                              className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                              onChange={(e) => detailKoliOnChange(e, true)}
                              value={detailBarangMbl.DimT}
                              placeholder="Isi dimensi tinggi barang"
                            />
                          </div>
                        </div>
                        <div className="row px-2 py-1 justify-center">
                          <div className="col-md-3 text-xs sm:text-sm font-bold items-top">
                            Packing
                          </div>
                          <div className="col-md-3 text-xs sm:text-sm flex flex-col">
                            <div>
                              <input
                                type="checkbox"
                                id="PackingKayu"
                                name="PackingKayu"
                                checked={detailBarangMbl.PackingKayu}
                                onChange={(e) => detailKoliOnChange(e, false)}
                              />
                              &nbsp;Kayu
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                id="BubbleWrap"
                                name="BubbleWrap"
                                checked={detailBarangMbl.BubbleWrap}
                                onChange={(e) => detailKoliOnChange(e, false)}
                              />
                              &nbsp;Bubble Wrap
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                id="Wrapping"
                                name="Wrapping"
                                checked={detailBarangMbl.Wrapping}
                                onChange={(e) => detailKoliOnChange(e, false)}
                              />
                              &nbsp;Wrapping
                            </div>
                          </div>
                        </div>
                        <div className="px-2 py-1">
                          <div className="row px-2 py-1 vertical-center">
                            <div className="col-md-3 text-xs sm:text-sm font-bold">
                              <button
                                type="button"
                                className="w-100 mx-2 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-yellow-600"
                                onClick={() => {
                                  setShowKoliForm(true);
                                  setShowPenerimaForm(true);
                                }}
                              >
                                Batal
                              </button>
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm font-bold">
                              <button
                                type="button"
                                className="w-100 mx-2 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                                onClick={(e) => {
                                  onSubmitDetailBarang();
                                  setShowKoliForm(true);
                                  setShowPenerimaForm(true);
                                }}
                              >
                                Simpan Data Koli
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {showKoliForm && (
                      <>
                        <div className="px-2 py-1">
                          <div
                            className="vertical-center text-xs sm:text-sm font-bold"
                            style={{
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              type="button"
                              className="w-25 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                              onClick={(e) => {
                                generateKoli();
                              }}
                            >
                              Generate Koli
                            </button>
                          </div>
                        </div>
                        <div className="px-2 py-1">
                          {isEditMode ? (
                            <TableDetailBarangEdit
                              arrTempBarang={arrTempBarang}
                              setDetailBarangMbl={setDetailBarangMbl}
                              setIsEditKoli={setIsEditKoli}
                              handleCancel={handleCancel}
                            />
                          ) : (
                            <TableDetailBarang
                              arrTempBarang={arrTempBarang}
                              setArrDetailBarangMbl={setArrDetailBarangMbl}
                              setDetailBarangMbl={setDetailBarangMbl}
                              setIsEditKoli={setIsEditKoli}
                              isDeletable={true}
                              setArrTempBarang={setArrTempBarang}
                            />
                          )}
                        </div>
                        <div className="px-2 py-1">
                          <div className="row px-2 py-1">
                            <label
                              className="col-md-3"
                              style={{ fontWeight: "bold" }}
                            >
                              Total Barang
                            </label>
                            <label className="col-md-3">
                              {respCalcObj && arrDetailBarangMbl.length > 0
                                ? new Intl.NumberFormat("en", {
                                  minimumFractionDigits: 2,
                                }).format(respCalcObj.TotalItem)
                                : new Intl.NumberFormat("en", {
                                  minimumFractionDigits: 2,
                                }).format(0)}
                            </label>
                            <label
                              className="col-md-3"
                              style={{ fontWeight: "bold" }}
                            >
                              Estimasi Berat Final (Kg)
                            </label>
                            <label className="col-md-3">
                              {respCalcObj && arrDetailBarangMbl.length > 0
                                ? new Intl.NumberFormat("en", {
                                  minimumFractionDigits: 2,
                                }).format(respCalcObj.TotalFinalWeight)
                                : new Intl.NumberFormat("en", {
                                  minimumFractionDigits: 2,
                                }).format(0)}
                            </label>
                          </div>

                          {false && (
                            <>
                              <div className="row px-2 py-1">
                                <label
                                  className="col-md-3"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Estimasi Ongkir
                                </label>
                                <label className="col-md-3">
                                  {respCalcObj && arrDetailBarangMbl.length > 0
                                    ? new Intl.NumberFormat(
                                      "en",
                                      {
                                        minimumFractionDigits: 0,
                                      }
                                    ).format(respCalcObj.EstMainChargeAmt)
                                    : 0}
                                </label>
                                <label
                                  className="col-md-3"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Estimasi Asuransi
                                </label>
                                <label className="col-md-3">
                                  {respCalcObj && arrDetailBarangMbl.length > 0
                                    ? new Intl.NumberFormat(
                                      "en",
                                      {
                                        minimumFractionDigits: 0,
                                      }
                                    ).format(respCalcObj.EstAddChargeAmt)
                                    : 0}
                                </label>
                              </div>
                              <div className="row px-2 py-1">
                                <label
                                  className="col-md-3"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Estimasi Tagih Pengirim
                                </label>
                                <label className="col-md-3">
                                  {respCalcObj && arrDetailBarangMbl.length > 0
                                    ? new Intl.NumberFormat(
                                      "en",
                                      {
                                        minimumFractionDigits: 0,
                                      }
                                    ).format(respCalcObj.EstSndrMustBePaidAmt)
                                    : 0}
                                </label>
                                <label
                                  className="col-md-3"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Estimasi Tagih Penerima
                                </label>
                                <label className="col-md-3">
                                  {respCalcObj && arrDetailBarangMbl.length > 0
                                    ? new Intl.NumberFormat(
                                      "en",
                                      {
                                        minimumFractionDigits: 0,
                                      }
                                    ).format(
                                      respCalcObj.EstRcpntMustBePaidAmt
                                    )
                                    : 0}
                                </label>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {!showPenerimaForm && (
                <>
                  <div className="float-right px-2 py-1">
                    <div className="text-xs sm:text-sm font-bold">
                      <button
                        type="button"
                        className="w-100 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                        onClick={(e) => {
                          setShowPenerimaForm(true);
                          clearDataPenerima();
                          setIsEditMode(false);
                        }}
                      >
                        Tambah Data Penerima
                      </button>
                    </div>
                  </div>
                  <div className="float-right px-2 py-1">
                    <div className="text-xs sm:text-sm font-bold">
                      <button
                        type="button"
                        className="w-100 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                        onClick={(e) => navigateToBookingDetail()}
                      >
                        Cetak Label Barang
                      </button>
                    </div>
                  </div>
                  <div className="px-2 py-1">
                    <TableDetailPenerima
                      setArrResiNo={setArrResiNo}
                      setIsEditMode={setIsEditMode}
                    />
                  </div>
                </>
              )}
            </>
          )}
          <div className="flex justify-end">
            {currStep > 1 && (
              <>
                {showPenerimaForm && showKoliForm && (
                  <button
                    type="button"
                    className="mx-3 w-40 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-yellow-600"
                    onClick={(e) => {
                      setShowPenerimaForm(false);
                      dispatch(bookingActions.clearCustBookHDetail());
                      setIsEditMode(false);
                    }}
                  >
                    Batal
                  </button>
                )}
                {showPenerimaForm && showKoliForm && (
                  <button
                    type="submit"
                    className="mx-3 w-40 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                  >
                    Submit
                  </button>
                )}
              </>
            )}
            {currStep < 2 ? (
              <button
                type="button"
                className="mx-3 w-40 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                onClick={(e) => handleNextStep(e)}
              >
                Lanjutkan
                <FaIcons.FaArrowCircleRight className="float-right mt-1" />
              </button>
            ) : (
              <>
                {showPenerimaForm && !showKoliForm && (
                  <button
                    type="submit"
                    className="mx-3 w-40 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                  >
                    Submit
                  </button>
                )}
                {!showPenerimaForm && (
                  <button
                    type="submit"
                    className="mx-3 w-40 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                    onClick={(e) => handleSelesai(e)}
                  >
                    Selesai
                  </button>
                )}
              </>
            )}
          </div>
        </form>
      </div>
      <div className="block sm:hidden w-full">
        <div className="flex flex-row bg-blue-600">
          <h4 className="w-full text-center text-xl font-semibold text-white py-2">
            Buat Booking
          </h4>
        </div>
        <div className="flex items-center border-b-2 border-gray-300 px-3 py-2 text-xs sm:text-sm">
          <div className="flex items-center h-full w-full ">
            <div className="flex items-center">
              <div className="rounded-full text-center transition duration-500 ease-in-out h-8 w-8 py-1 border-2 bg-red-600 border-red-600 text-white">
                1
              </div>
              <div className="mx-2 text-center items-center text-xs font-medium">
                Pengirim
              </div>
            </div>
            <div
              className={`flex-auto border-t-2 mr-2 transition duration-500 ease-in-out ${currStep > 1 ? "border-red-600" : "border-gray-500"
                } `}
            ></div>
            <div className="flex items-center">
              <div
                className={`rounded-full text-center transition duration-500 ease-in-out h-8 w-8 py-1 border-2 border-red-600 ${currStep > 1 ? "bg-red-600 text-white" : "text-red-600"
                  } `}
              >
                2
              </div>
              <div className="mx-2 text-center items-center text-xs font-medium">
                Penerima
              </div>
            </div>
          </div>
        </div>
        <AlertMessage
          isError={isError}
          alert={alert}
          setAlert={setAlert}
          setIsError={setIsError}
        />
        <div className="px-2">
          <form onSubmit={handleSubmit} method="POST">
            <div className="h-full w-full flex items-center">
              <div className="h-full w-full flex items-center">
                <div className="text-xs sm:text-sm font-medium w-full">
                  {currStep === 1 ? (
                    <>
                      <div className="flex flex-1 text-xs sm:text-sm mx-2 py-1">
                        Daftar Alamat
                      </div>
                      <div
                        className={`flex border-b-2 ${submitted && !pickupAddrType && newPickupAddr === true
                          ? "border-red-500"
                          : "border-gray-300"
                          } mx-2 py-1`}
                      >
                        {newPickupAddr === false ? (
                          <>
                            <select
                              className="py-1 px-2 text-xs sm:text-sm  border-none outline-none w-100"
                              id="pickupAddressType"
                              name="pickupAddressType"
                              value={pickupAddrType}
                              onChange={(e) =>
                                setPickupAddrType(e.target.value)
                              }
                            >
                              <option value="" disabled hidden>
                                Pilih daftar alamat
                              </option>
                              {pickupAddressTypeObj &&
                                pickupAddressTypeObj.Result &&
                                pickupAddressTypeObj.Result.map(
                                  (pickupAddressTypeObj, i) => (
                                    <option
                                      key={i}
                                      value={pickupAddressTypeObj.AddrType}
                                    >
                                      {pickupAddressTypeObj.AddrType}
                                    </option>
                                  )
                                )}
                            </select>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                              onClick={() => {
                                setNewPickupAddr(true);
                                setPickupAddrType("");
                              }}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6v12m6-6H6"
                              />
                            </svg>
                          </>
                        ) : (
                          <>
                            <input
                              id="pickupAddressTypeTxt"
                              type="text"
                              name="pickupAddressTypeTxt"
                              placeholder="Isi tipe alamat pickup"
                              className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                              onChange={(e) =>
                                setPickupAddrType(e.target.value)
                              }
                              value={pickupAddrType}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                              onClick={() => {
                                setNewPickupAddr(false);
                                setPickupAddrType("");
                              }}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </>
                        )}
                      </div>
                      {submitted &&
                        !pickupAddrType &&
                        newPickupAddr === true && (
                          <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                            Harap masukkan daftar alamat
                          </div>
                        )}
                      <div className="flex flex-col mx-2 py-1">
                        <div className="flex-1 text-xs sm:text-sm flex items-center">
                          Contact Person<p className="text-red-500">&nbsp;*</p>
                        </div>
                        <div
                          className={`flex-1 border-b-2 ${submitted && !pickupContactPerson
                            ? "border-red-500"
                            : "border-gray-300"
                            } text-xs sm:text-sm py-1`}
                        >
                          <input
                            id="pickupContactPersons"
                            type="text"
                            name="pickupContactPersons"
                            placeholder="Isi contact person"
                            className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                            onChange={(e) =>
                              setPickupContactPerson(e.target.value)
                            }
                            value={pickupContactPerson}
                          />
                        </div>
                        {submitted && !pickupContactPerson && (
                          <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                            Harap masukkan contact person
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col mx-2 py-1">
                        <div className="flex-1 text-xs sm:text-sm flex items-center">
                          Nomor Ponsel Contact Person
                          <p className="text-red-500">&nbsp;*</p>
                        </div>
                        <div
                          className={`flex-1 border-b-2 ${submitted && !pickupPhnNo
                            ? "border-red-500"
                            : "border-gray-300"
                            } text-xs sm:text-sm py-1`}
                        >
                          <Input
                            id="pickupPhnNo"
                            placeholder="Isi nomor ponsel contact person "
                            className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                            country="ID"
                            value={pickupPhnNo}
                            onChange={setPickupPhnNo}
                          />
                        </div>
                        {submitted && !pickupPhnNo && (
                          <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                            Harap masukkan nomor ponsel contact person
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col mx-2 py-1">
                        <div className="flex-1 text-xs sm:text-sm flex items-center">
                          Type
                        </div>
                        <div
                          className={`flex-1 border-b-2 ${submitted && !dropType
                            ? "border-red-500"
                            : "border-gray-300"
                            } text-xs sm:text-sm py-1`}
                        >
                          <select
                            className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                            id="dropType"
                            name="dropType"
                            value={dropType}
                            onChange={(e) => setDropType(e.target.value)}
                          >
                            <option value="" disabled hidden>
                              Pilih drop type
                            </option>
                            <option
                              value={masterSettingConstants.DROP_TYPE_PICKUP}
                            >
                              Pickup
                            </option>
                          </select>
                        </div>
                        {submitted && !dropType && (
                          <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                            Harap pilih drop type
                          </div>
                        )}
                      </div>
                      {dropType !== "" &&
                        dropType ===
                        masterSettingConstants.DROP_TYPE_PICKUP && (
                          <>
                            <div className="flex flex-col mx-2 py-1">
                              <div className="flex-1 text-xs sm:text-sm flex items-center">
                                Tanggal Pickup
                              </div>
                              <div
                                className={`flex-1 border-b-2 ${submitted && !pickupTime
                                  ? "border-red-500"
                                  : "border-gray-300"
                                  } text-xs sm:text-sm py-1`}
                              >
                                <input
                                  id="pickupTimes"
                                  name="pickupTimes"
                                  type="date"
                                  value={pickupTime}
                                  className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                  onChange={(e) =>
                                    setPickupTime(e.target.value)
                                  }
                                  min={minPickupTime}
                                />
                              </div>
                              {submitted && !pickupTime && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                  Harap masukkan tanggal dan jam pickup
                                </div>
                              )}
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                              <div className="flex-1 text-xs sm:text-sm flex items-center">
                                Alamat Pickup
                                <p className="text-red-500">&nbsp;*</p>
                              </div>
                              <div
                                className={`flex-1 border-b-2 ${submitted && !pickupAddress
                                  ? "border-red-500"
                                  : "border-gray-300"
                                  } text-xs sm:text-sm py-1`}
                              >
                                <input
                                  id="pickupAddress"
                                  name="pickupAddress"
                                  placeholder="Isi alamat pickup"
                                  disabled={!newPickupAddr}
                                  type="text"
                                  className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                  onChange={(e) =>
                                    setPickupAddress(e.target.value)
                                  }
                                  value={pickupAddress}
                                />
                              </div>
                              {submitted && !pickupAddress && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                  Harap masukkan alamat pickup
                                </div>
                              )}
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                              <div className="flex-1 text-xs sm:text-sm flex items-center">
                                Kecamatan / Kabupaten Pickup
                                <p className="text-red-500">&nbsp;*</p>
                              </div>
                              <div
                                className={`flex-1 border-b-2 ${submitted &&
                                  (!selectedPckDist ||
                                    selectedPckDist.value === 0)
                                  ? "border-red-500"
                                  : "border-gray-300"
                                  } text-xs sm:text-sm py-1`}
                              >
                                <Select
                                  filterOption={createFilter({
                                    ignoreAccents: false,
                                  })} // this makes all the difference!
                                  value={selectedPckDist}
                                  options={options}
                                  onChange={handleChangeDistrictPickup}
                                  className="text-xs sm:text-sm"
                                  styles={styleMobile}
                                  disabled={!newPickupAddr}
                                />
                              </div>
                              {submitted &&
                                (!selectedPckDist ||
                                  selectedPckDist.value <= 0) && (
                                  <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                    Harap masukkan kecamatan / kabupaten
                                  </div>
                                )}
                            </div>
                            <div className="flex flex-col mx-2 py-1">
                              <div className="flex-1 text-xs sm:text-sm flex items-center">
                                Catatan
                              </div>
                              <div className="flex-1 border-b-2 border-gray-300 text-xs sm:text-sm py-1">
                                <input
                                  id="pickupNotes"
                                  name="pickupNotes"
                                  placeholder="Isi catatan pickup"
                                  type="text"
                                  className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                  onChange={(e) =>
                                    setPickupNotes(e.target.value)
                                  }
                                  value={pickupNotes}
                                />
                              </div>
                            </div>
                          </>
                        )}
                    </>
                  ) : (
                    <>
                      {showPenerimaForm && (
                        <div className="flex flex-col text-xs sm:text-sm">
                          <div className="flex flex-col mx-2 py-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                              Alias Pengirim
                              <p className="text-red-500">&nbsp;*</p>
                            </div>
                            <div
                              className={`flex-1 border-b-2 ${submitted && !sellerAliasName
                                ? "border-red-500"
                                : "border-gray-300"
                                } text-xs sm:text-sm py-1`}
                            >
                              <input
                                id="sellerAliasName"
                                type="text"
                                name="sellerAliasName"
                                className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                placeholder="Isi nama alias pengirim"
                                value={sellerAliasName}
                                onChange={(e) =>
                                  setSellerAliasName(e.target.value)
                                }
                              />
                            </div>
                            {submitted && !sellerAliasName && (
                              <div className="flex flex-1 text-xs sm:text-sm text-red-500 py-1">
                                Harap isi alias pengirim
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col mx-2 py-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                              Nomor Ponsel Pengirim
                              <p className="text-red-500">&nbsp;*</p>
                            </div>
                            <div
                              className={`flex-1 border-b-2 ${submitted && !sellerMobilePhnNo
                                ? "border-red-500"
                                : "border-gray-300"
                                } text-xs sm:text-sm py-1`}
                            >
                              <Input
                                className="py-1 text-xs sm:text-sm w-100 border-none outline-none rounded-sm border-gray-300"
                                country="ID"
                                value={sellerMobilePhnNo}
                                onChange={setSellerMobilePhnNo}
                                placeholder="Isi nomor ponsel pengirim"
                              />
                            </div>
                            {submitted && !sellerMobilePhnNo && (
                              <div className="flex flex-1 text-xs sm:text-sm text-red-500 py-1">
                                Harap isi nomor ponsel pengirim
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col mx-2 py-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                              Nama Penerima
                              <p className="text-red-500">&nbsp;*</p>
                            </div>
                            <div
                              className={`flex-1 border-b-2 py-1 ${submitted && !recipientName
                                ? "border-red-500"
                                : "border-gray-300"
                                } text-xs sm:text-sm`}
                            >
                              <input
                                id="recipientName"
                                type="text"
                                name="recipientName"
                                className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                placeholder="Isi nama penerima"
                                value={recipientName}
                                onChange={(e) =>
                                  setRecipientName(e.target.value)
                                }
                              />
                            </div>
                            {submitted && !recipientName && (
                              <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                Harap isi nama penerima
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col mx-2 py-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                              Nomor Ponsel Penerima
                            </div>
                            <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs sm:text-sm">
                              <Input
                                id="recipientMobilePhnNo"
                                className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                country="ID"
                                value={recipientMobilePhnNo}
                                onChange={setRecipientMobilePhnNo}
                                placeholder="Isi nomor ponsel penerima"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col mx-2 py-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                              Alamat Penerima
                            </div>
                            <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs sm:text-sm">
                              <input
                                id="recipientAddress"
                                name="recipientAddress"
                                placeholder="Isi alamat penerima"
                                type="text"
                                className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                value={recipientAddress}
                                onChange={(e) =>
                                  setRecipientAddress(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="flex flex-col mx-2 py-1">
                            <div className="flex-1 text-xs sm:text-sm flex flex-row items-center">
                              Nomor Referensi
                              {acc.IsCustRefNoMandatory && (
                                <p className="text-red-500">&nbsp;*</p>
                              )}
                            </div>
                            <div
                              className={`flex-1 border-b-2 ${acc.IsCustRefNoMandatory &&
                                submitted &&
                                !referenceNo
                                ? "border-red-500"
                                : "border-gray-300"
                                } text-xs sm:text-sm py-1`}
                            >
                              <input
                                id="referenceNo"
                                type="text"
                                name="referenceNo"
                                placeholder="Isi customer reference no"
                                maxLength="50"
                                className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                onChange={(e) => setReferenceNo(e.target.value)}
                                value={referenceNo}
                              />
                            </div>
                            {acc.IsCustRefNoMandatory &&
                              submitted &&
                              !referenceNo && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 py-1">
                                  Harap isi Nomor Referensi.
                                </div>
                              )}
                          </div>
                          <div className="flex flex-col mx-2 py-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                              Tujuan<p className="text-red-500">&nbsp;*</p>
                            </div>
                            <div
                              className={`flex-1 border-b-2 ${submitted &&
                                (!selectedDest || selectedDest.value === 0)
                                ? "border-red-500"
                                : "border-gray-300"
                                } text-xs sm:text-sm py-1`}
                            >
                              {districtObj && selectedDest && (
                                <Select
                                  filterOption={createFilter({
                                    ignoreAccents: false,
                                  })}
                                  value={selectedDest}
                                  options={options}
                                  onChange={handleChangeDest}
                                  className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                  styles={styleMobile}
                                />
                              )}
                            </div>
                            {submitted &&
                              (!selectedDest || selectedDest.value === 0) && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                  Harap isi tujuan
                                </div>
                              )}
                          </div>
                          <div className="flex flex-col mx-2 py-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                              Jalur<p className="text-red-500">&nbsp;*</p>
                            </div>
                            <div
                              className={`flex-1 border-b-2 ${submitted && !transportType
                                ? "border-red-500"
                                : "border-gray-300"
                                } text-xs sm:text-sm py-1`}
                            >
                              <select
                                className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                id="transportType"
                                name="transportType"
                                value={transportType}
                                onChange={(e) =>
                                  setTransportType(e.target.value)
                                }
                              >
                                <option value="" disabled hidden>
                                  Pilih jalur
                                </option>
                                {transportTypeObj &&
                                  transportTypeObj.TransportTypes.map(
                                    (transportTypeObj) => (
                                      <option
                                        key={transportTypeObj.TransportTypeCode}
                                        value={
                                          transportTypeObj.TransportTypeCode
                                        }
                                      >
                                        {transportTypeObj.TransportTypeName}
                                      </option>
                                    )
                                  )}
                              </select>
                            </div>
                            {submitted && !transportType && (
                              <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                Harap pilih metode pengiriman
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col mx-2 py-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                              Pembayaran<p className="text-red-500">&nbsp;*</p>
                            </div>
                            <div
                              className={`flex-1 border-b-2 ${submitted && !paymentType
                                ? "border-red-500"
                                : "border-gray-300"
                                } text-xs sm:text-sm py-1`}
                            >
                              <select
                                className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                id="paymentType"
                                name="paymentType"
                                value={paymentType}
                                onChange={(e) => setPaymentType(e.target.value)}
                              >
                                <option value="" disabled hidden>
                                  Pilih metode pembayaran
                                </option>
                                <option
                                  value={masterSettingConstants.PAYTYPE_LUNAS}
                                >
                                  Lunas
                                </option>
                                <option
                                  value={masterSettingConstants.PAYTYPE_KREDIT}
                                  hidden={hideCredit}
                                >
                                  Kredit
                                </option>
                                <option
                                  value={
                                    masterSettingConstants.PAYTYPE_TAGIHTUJUAN
                                  }
                                  hidden={hideCOD}
                                >
                                  Tagih Tujuan
                                </option>
                                <option
                                  value={
                                    masterSettingConstants.PAYTYPE_KREDITPENERIMA
                                  }
                                  hidden={hideReceiverCredit}
                                >
                                  Kredit Penerima
                                </option>
                              </select>
                            </div>
                            {submitted && !paymentType && (
                              <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                Harap pilih metode pembayaran
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col mx-2 py-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                              Jenis Barang
                              <p className="text-red-500">&nbsp;*</p>
                            </div>
                            <div
                              className={`flex-1 border-b-2 ${(submitted || simpanDataBarang) &&
                                !packageCategory
                                ? "border-red-500"
                                : "border-gray-300"
                                } text-xs sm:text-sm py-1`}
                            >
                              <select
                                className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                id="packageCategory"
                                name="packageCategory"
                                value={packageCategory}
                                onChange={(e) =>
                                  setPackageCategory(e.target.value)
                                }
                              >
                                <option value="" disabled hidden>
                                  Pilih jenis barang
                                </option>
                                <option
                                  value={
                                    masterSettingConstants.PACKAGE_CATEGORY_ELEKTRONIK
                                  }
                                >
                                  ELEKTRONIK
                                </option>
                                <option
                                  value={
                                    masterSettingConstants.PACKAGE_CATEGORY_NON_ELEKTRONIK
                                  }
                                >
                                  NON ELEKTRONIK
                                </option>
                              </select>
                            </div>
                            {(submitted || simpanDataBarang) &&
                              !packageCategory && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                  Harap pilih jenis barang
                                </div>
                              )}
                          </div>
                          <div className="flex flex-col mx-2 py-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                              Tipe Barang<p className="text-red-500">&nbsp;*</p>
                            </div>
                            <div
                              className={`flex-1 border-b-2 ${(submitted || simpanDataBarang) &&
                                !packageContent
                                ? "border-red-500"
                                : "border-gray-300"
                                } text-xs sm:text-sm py-1`}
                            >
                              <select
                                className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                id="packageContent"
                                name="packageContent"
                                value={packageContent}
                                onChange={(e) => handlePackageContentChange(e.target.value)}>
                                <option value="" disabled hidden>
                                  Pilih tipe barang
                                </option>
                                {packageContentsObj &&
                                  packageContentsObj.map(
                                    (packageContentsObj) => (
                                      <option
                                        key={
                                          packageContentsObj.PackageContentCode
                                        }
                                        value={
                                          packageContentsObj.PackageContentCode
                                        }
                                      >
                                        {packageContentsObj.PackageContentName}
                                      </option>
                                    )
                                  )}
                              </select>
                            </div>
                            {(submitted || simpanDataBarang) &&
                              !packageContent && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                  Harap pilih tipe barang
                                </div>
                              )}
                          </div>
                          <div className="flex flex-col mx-2 py-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                              Total Koli<p className="text-red-500">&nbsp;*</p>
                            </div>
                            <div
                              className={`flex-1 border-b-2 py-1 ${submitted &&
                                totalKoli != arrDetailBarangMbl.length &&
                                totalKoli != totalNonCanKoli
                                ? "border-red-500"
                                : "border-gray-300"
                                } text-xs sm:text-sm`}
                            >
                              <NumericFormat
                                id="totalKoli"
                                name="totalKoli"
                                thousandSeparator={true}
                                allowLeadingZeros={false}
                                decimalScale={0}
                                fixedDecimalScale={true}
                                className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                onChange={(e) => setTotalKoli(e.target.value)}
                                value={totalKoli}
                              />
                            </div>
                            {submitted &&
                              totalKoli != arrDetailBarangMbl.length &&
                              totalKoli != totalNonCanKoli && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                  Total Koli tidak sama dengan jumlah detail
                                  barang
                                </div>
                              )}
                          </div>
                          {isInsurance && isInsurance === "1" && (
                            <div className="flex flex-col mx-2 py-1">
                              <div className="flex-1 text-xs sm:text-sm flex items-center">
                                Nilai Barang <p className="text-red-500">&nbsp;*</p>
                              </div>
                              <div
                                className={`flex-1 border-b-2 py-1 ${(submitted || simpanDataBarang) &&
                                  nilaiBarang <= 0 &&
                                  isInsurance == "1"
                                  ? "border-red-500"
                                  : "border-gray-300"
                                  } text-xs sm:text-sm`}
                              >
                                <NumericFormat
                                  id="estItemPriceAmt"
                                  name="estItemPriceAmt"
                                  thousandSeparator={true}
                                  decimalScale={0}
                                  fixedDecimalScale={true}
                                  className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                  onChange={(e) => handleNilaiBarang(e)}
                                  value={nilaiBarang}
                                  placeholder="Isi Nilai barang"
                                />
                              </div>
                              {(submitted || simpanDataBarang) &&
                                nilaiBarang <= 0 &&
                                isInsurance == "1" && (
                                  <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                    Harap isi nilai barang
                                  </div>
                                )}
                            </div>
                          )}
                          <div className="flex flex-col mx-2 py-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                              Catatan Order
                            </div>
                            <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs sm:text-sm">
                              <input
                                id="bookNotes"
                                name="bookNotes"
                                placeholder="Isi catatan order"
                                type="text"
                                className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                value={bookingNotes}
                                onChange={(e) =>
                                  setBookingNotes(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          {(packageContent ===
                            masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN ||
                            packageContent ===
                            masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN ||
                            isInsurance === "1") && (
                              <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs sm:text-sm items-center flex">
                                  Catatan Paket<p className="text-red-500">&nbsp;*</p>
                                </div>
                                <div
                                  className={`flex-1 border-b-2 py-1 ${submitted && !packageContentNotes
                                    ? "border-red-500"
                                    : "border-gray-300"
                                    } text-xs sm:text-sm`}
                                >
                                  <input
                                    id="packageContentNotes"
                                    name="packageContentNotes"
                                    placeholder="Isi catatan paket"
                                    type="text"
                                    className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                    value={packageContentNotes}
                                    onChange={(e) =>
                                      setPackageContentNotes(e.target.value)
                                    }
                                  />
                                </div>
                                {submitted && !packageContentNotes && (
                                  <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                    Harap isi catatan paket
                                  </div>
                                )}
                              </div>
                            )}
                          {!showKoliForm && (
                            <>
                              <div className="row px-2 py-1 vertical-center">
                                <div className="col-md-6 text-xs font-semibold vertical-center">
                                  <p style={{ fontSize: 20 }}>Detail Koli</p>
                                </div>
                              </div>

                              <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs flex items-center">
                                  Estimasi Berat Barang (kg)
                                </div>
                                <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs">
                                  <NumericFormat
                                    id="ItemWeight"
                                    name="ItemWeight"
                                    thousandSeparator={true}
                                    allowLeadingZeros={false}
                                    decimalScale={2}
                                    fixedDecimalScale={false}
                                    className="py-1 text-xs border-none outline-none w-100"
                                    onChange={(e) =>
                                      detailKoliOnChange(e, true)
                                    }
                                    value={detailBarangMbl.ItemWeight}
                                    placeholder="Isi estimasi berat barang"
                                  />
                                </div>
                              </div>
                              <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs flex items-center">
                                  Panjang (cm)
                                </div>
                                <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs">
                                  <NumericFormat
                                    id="DimP"
                                    name="DimP"
                                    thousandSeparator={true}
                                    allowLeadingZeros={false}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    className="py-1 text-xs border-none outline-none w-100"
                                    onChange={(e) =>
                                      detailKoliOnChange(e, true)
                                    }
                                    value={detailBarangMbl.DimP}
                                    placeholder="Isi dimensi panjang barang"
                                  />
                                </div>
                              </div>
                              <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs flex items-center">
                                  Lebar (cm)
                                </div>
                                <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs">
                                  <NumericFormat
                                    id="DimL"
                                    name="DimL"
                                    thousandSeparator={true}
                                    allowLeadingZeros={false}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    className="py-1 text-xs border-none outline-none w-100"
                                    onChange={(e) =>
                                      detailKoliOnChange(e, true)
                                    }
                                    value={detailBarangMbl.DimL}
                                    placeholder="Isi dimensi lebar barang"
                                  />
                                </div>
                              </div>
                              <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs flex items-center">
                                  Tinggi (cm)
                                </div>
                                <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs">
                                  <NumericFormat
                                    id="DimT"
                                    name="DimT"
                                    thousandSeparator={true}
                                    allowLeadingZeros={false}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    className="py-1 text-xs border-none outline-none w-100"
                                    onChange={(e) =>
                                      detailKoliOnChange(e, true)
                                    }
                                    value={detailBarangMbl.DimT}
                                    placeholder="Isi dimensi tinggi barang"
                                  />
                                </div>
                              </div>
                              <div className="flex flex-col mx-2 py-1">
                                <div className="flex-1 text-xs flex items-center font-medium items-top">
                                  Packing
                                </div>
                                <div className="flex-1 flex flex-row border-b-2 py-1 border-gray-300 text-xs">
                                  <div>
                                    <input
                                      type="checkbox"
                                      id="PackingKayu"
                                      name="PackingKayu"
                                      className="py-1 text-xs"
                                      checked={detailBarangMbl.PackingKayu}
                                      onChange={(e) =>
                                        detailKoliOnChange(e, false)
                                      }
                                    />
                                    &nbsp;Kayu
                                  </div>
                                  &nbsp;&nbsp;&nbsp;
                                  <div>
                                    <input
                                      type="checkbox"
                                      id="BubbleWrap"
                                      name="BubbleWrap"
                                      className="py-1 text-xs"
                                      checked={detailBarangMbl.BubbleWrap}
                                      onChange={(e) =>
                                        detailKoliOnChange(e, false)
                                      }
                                    />
                                    &nbsp;Bubble Wrap
                                  </div>
                                  &nbsp;&nbsp;&nbsp;
                                  <div>
                                    <input
                                      type="checkbox"
                                      id="Wrapping"
                                      name="Wrapping"
                                      className="py-1 text-xs"
                                      checked={detailBarangMbl.Wrapping}
                                      onChange={(e) =>
                                        detailKoliOnChange(e, false)
                                      }
                                    />
                                    &nbsp;Wrapping
                                  </div>
                                </div>
                              </div>
                              <div className="row py-1 vertical-center justify-around">
                                <div className="w-40 mx-1 text-xs font-bold">
                                  <button
                                    type="button"
                                    className="w-100 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-yellow-600"
                                    onClick={() => {
                                      setShowKoliForm(true);
                                      setShowPenerimaForm(true);
                                    }}
                                  >
                                    Batal
                                  </button>
                                </div>
                                <div className="w-40 mx-1 text-xs font-bold">
                                  <button
                                    type="button"
                                    className="w-100 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                                    onClick={(e) => {
                                      onSubmitDetailBarang();
                                      setShowKoliForm(true);
                                      setShowPenerimaForm(true);
                                    }}
                                  >
                                    Simpan Data Koli
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                          {showKoliForm && (
                            <>
                              <div className="justify-center items-center flex flex-row py-3 text-xs">
                                <button
                                  type="button"
                                  className="w-100 mx-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                                  onClick={(e) => {
                                    generateKoli();
                                  }}
                                >
                                  Generate Koli
                                </button>
                              </div>
                              <div className="px-2 py-1">
                                {isEditMode ? (
                                  <TableDetailBarangEdit
                                    arrTempBarang={arrTempBarang}
                                    setDetailBarangMbl={setDetailBarangMbl}
                                    setIsEditKoli={setIsEditKoli}
                                    handleCancel={handleCancel}
                                  />
                                ) : (
                                  <TableDetailBarang
                                    arrTempBarang={arrTempBarang}
                                    setArrDetailBarangMbl={
                                      setArrDetailBarangMbl
                                    }
                                    setDetailBarangMbl={setDetailBarangMbl}
                                    setIsEditKoli={setIsEditKoli}
                                    isDeletable={true}
                                    setArrTempBarang={setArrTempBarang}
                                  />
                                )}
                              </div>
                              <div className="flex flex-col px-2 pt-2">
                                <div className="flex flex-row">
                                  <label
                                    className="w-28"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Total Barang
                                  </label>
                                  <label className="w-28">
                                    {respCalcObj &&
                                      arrDetailBarangMbl.length > 0
                                      ? new Intl.NumberFormat(
                                        "en",
                                        {
                                          minimumFractionDigits: 2,
                                        }
                                      ).format(respCalcObj.TotalItem)
                                      : new Intl.NumberFormat(
                                        "en",
                                        {
                                          minimumFractionDigits: 2,
                                        }
                                      ).format(0)}
                                  </label>
                                </div>
                                <div className="flex flex-row">
                                  <label
                                    className="w-28"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Estimasi Berat Final (Kg)
                                  </label>
                                  <label className="w-28">
                                    {respCalcObj &&
                                      arrDetailBarangMbl.length > 0
                                      ? new Intl.NumberFormat(
                                        "en",
                                        {
                                          minimumFractionDigits: 2,
                                        }
                                      ).format(respCalcObj.TotalFinalWeight)
                                      : new Intl.NumberFormat(
                                        "en",
                                        {
                                          minimumFractionDigits: 2,
                                        }
                                      ).format(0)}
                                  </label>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {!showPenerimaForm && (
                        <>
                          <div className="justify-center items-center flex flex-row py-3 text-xs -mt-3">
                            <button
                              type="button"
                              className="w-100 mx-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                              onClick={(e) => navigateToBookingDetail()}
                            >
                              Cetak Label Barang
                            </button>
                            <button
                              type="button"
                              className="w-100 mx-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                              onClick={(e) => {
                                setShowPenerimaForm(true);
                                clearDataPenerima();
                                setIsEditMode(false);
                              }}
                            >
                              Tambah Data Penerima
                            </button>
                          </div>
                          <div className="px-2 py-1">
                            <TableDetailPenerima setArrResiNo={setArrResiNo} />
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div className="flex flex-row mx-2 mb-2 mt-1 justify-center">
                    {currStep < 2 ? (
                      <button
                        type="button"
                        className={`w-100 ${currStep > 1 && "mx-1"
                          } py-2 border border-transparent font-medium rounded-full text-white bg-red-600`}
                        onClick={(e) => handleNextStep(e)}
                      >
                        Lanjutkan
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="w-100 mx-1 py-2 border border-transparent font-medium rounded-full text-white bg-red-600"
                      >
                        Simpan
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal size="lg" isOpen={modalShow}>
        <ModalHeader className="font-bold text-xs sm:text-sm items-center">
          Summary Booking
        </ModalHeader>
        <ModalBody>
          {
            <>
              <div className="hidden sm:block">
                <div className="relative h-full w-full flex items-center">
                  <div className="text-xs sm:text-sm font-medium w-full px-2 py-2">
                    <div className="flex px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex">
                        Booking telah sukses dibuat
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-center">
                  <button
                    type="button"
                    className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                    onClick={(e) => navigateToBookingDetail()}
                  >
                    Cetak Label Barang
                  </button>
                  <button
                    type="button"
                    className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                    onClick={(e) => navigateToCreateBooking()}
                  >
                    Buat Booking Lain
                  </button>
                  <button
                    type="button"
                    className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                    onClick={(e) => navigateToHome()}
                  >
                    Kembali ke Home
                  </button>
                </div>
              </div>
              <div className="block sm:hidden">
                <div className="relative h-full w-full flex items-center">
                  <div className="text-xs sm:text-sm font-medium w-full px-2 py-2">
                    <div className="flex px-2 py-1">
                      <div className="flex-1 text-xs sm:text-sm flex">
                        Booking telah sukses dibuat
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center">
                  <button
                    type="button"
                    className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                    onClick={(e) => navigateToBookingDetail()}
                  >
                    Cetak Label Barang
                  </button>
                  <button
                    type="button"
                    className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                    onClick={(e) => navigateToCreateBooking()}
                  >
                    Buat Booking Lain
                  </button>
                  <button
                    type="button"
                    className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                    onClick={(e) => navigateToHome()}
                  >
                    Kembali ke Home
                  </button>
                </div>
              </div>
            </>
          }
        </ModalBody>
      </Modal>
    </>
  );
};
export default BookingAddFormV4;
