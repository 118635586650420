import { httpHelpers } from "../_helpers";
import { urlConstants } from "../_constants";

export const dashboardService = {
  summaryPengirimanDashboard,
  summaryPenerimaanDashboard,
  summaryKomplainDashboard,
  summaryBookingDashboard,
  summaryPickupReqDashboard,
    checkOngkir,
    getBanner
};

function summaryPengirimanDashboard(summaryPengirimanDashboardObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(summaryPengirimanDashboardObj),
  };

  return fetch(urlConstants.URL_SUMMARY_DASHBOARD, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          summaryPengirimanDashboard: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem(
            "summaryPengirimanDashboard",
            JSON.stringify(_result)
          );
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}

function summaryPenerimaanDashboard(summaryPenerimaanDashboardObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(summaryPenerimaanDashboardObj),
  };

  return fetch(urlConstants.URL_SUMMARY_DASHBOARD, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          summaryPenerimaanDashboard: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem(
            "summaryPenerimaanDashboard",
            JSON.stringify(_result)
          );
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}

function summaryKomplainDashboard(summaryKomplainDashboardObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(summaryKomplainDashboardObj),
  };

  return fetch(urlConstants.URL_SUMMARY_DASHBOARD, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          summaryKomplainDashboard: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem(
            "summaryKomplainDashboard",
            JSON.stringify(_result)
          );
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}

function summaryBookingDashboard(summaryBookingDashboardObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(summaryBookingDashboardObj),
  };

  return fetch(urlConstants.URL_SUMMARY_DASHBOARD, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          summaryBookingDashboard: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem(
            "summaryBookingDashboard",
            JSON.stringify(_result)
          );
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}

function summaryPickupReqDashboard(summaryPickupReqDashboardObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(summaryPickupReqDashboardObj),
  };

  return fetch(urlConstants.URL_SUMMARY_DASHBOARD, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          summaryPickupReqDashboard: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem(
            "summaryPickupReqDashboard",
            JSON.stringify(_result)
          );
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}

function checkOngkir(checkOngkirObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(checkOngkirObj),
  };

  return fetch(urlConstants.URL_CHECK_ONGKIR, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          checkOngkir: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem("checkOngkir", JSON.stringify(_result));
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}


function getBanner() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    };

    return fetch(urlConstants.URL_GET_BANNER, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log("result: ", result);
            if (result) {
                var _result = {
                    banner: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem("banner", JSON.stringify(_result));
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}
