export const customerConstants = {
    GET_CUST_BY_ID_REQUEST: 'GET_CUST_BY_ID_REQUEST',
    GET_CUST_BY_ID_SUCCESS: 'GET_CUST_BY_ID_SUCCESS',
    GET_CUST_BY_ID_FAILURE: 'GET_CUST_BY_ID_FAILURE',
    GET_CUST_BY_ID_CLEAR: 'GET_CUST_BY_ID_CLEAR',
    GET_PENERIMA_BY_HINT_REQUEST: 'GET_PENERIMA_BY_HINT_REQUEST',
    GET_PENERIMA_BY_HINT_SUCCESS: 'GET_PENERIMA_BY_HINT_SUCCESS',
    GET_PENERIMA_BY_HINT_FAILURE: 'GET_PENERIMA_BY_HINT_FAILURE',
    GET_PENERIMA_BY_HINT_CLEAR: 'GET_PENERIMA_BY_HINT_CLEAR',
    GET_PENERIMA_BY_ID_REQUEST: 'GET_PENERIMA_BY_ID_REQUEST',
    GET_PENERIMA_BY_ID_SUCCESS: 'GET_PENERIMA_BY_ID_SUCCESS',
    GET_PENERIMA_BY_ID_FAILURE: 'GET_PENERIMA_BY_ID_FAILURE',
    GET_PENERIMA_BY_ID_CLEAR: 'GET_PENERIMA_BY_ID_CLEAR'
}