import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import "../styles/tailwind.css";

const PengirimanMonitoringTable = ({ process, addrType }) => {
  const pengirimanMonitoring = useSelector(
    (state) => state.pengirimanMonitoring.pengirimanMonitoring
  );

  const history = useHistory();

  function handleDirect(ValueDt) {
    history.push(`/PengirimanDetail?trxDt=${ValueDt}&addrType=${addrType}`);
  }

  return (
    <div className="text-xs sm:text-sm">
      {pengirimanMonitoring &&
        pengirimanMonitoring.Result &&
        pengirimanMonitoring.Result.length === 0 && (
          <div className="justify-center flex flex-row">
            <p>Tidak ada pengiriman</p>
          </div>
        )}
      {pengirimanMonitoring &&
        pengirimanMonitoring.Result &&
        pengirimanMonitoring.Result.length > 0 && (
          <>
            <div className="hidden sm:block mx-auto">
              <table
                className="table table-striped bg-white"
                aria-labelledby="tabelLabel"
              >
                <thead>
                  <tr>
                    <th className="text-center">Tanggal Kirim</th>
                    <th className="text-right">Total Resi</th>
                    <th className="text-right">Koli</th>
                    <th className="text-right">Berat</th>
                    <th className="text-right">Lunas</th>
                    <th className="text-right">Kredit</th>
                    <th className="text-right">Tagih Tujuan</th>
                    <th className="text-right">Tagih Tujuan Tolak</th>
                    <th className="text-right">Sisa Tagihan</th>
                    <th className="text-right">Cepat</th>
                    <th className="text-right">Standar</th>
                    <th className="text-right">Lambat</th>
                    <th className="text-right">Belum POD</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {pengirimanMonitoring.Result.map((pm) => (
                    <tr key={pm.ValueDt}>
                      <td align="center">
                        {moment(pm.ValueDt).format("DD-MM-YYYY")}
                      </td>
                      <td align="right">
                        {pm.TotalResiCount.toLocaleString("en", {
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td align="right">
                        {pm.totalitem.toLocaleString("en", {
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td align="right">
                        {pm.finalWeight.toLocaleString("en", {
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td align="right">
                        {pm.totalLunas.toLocaleString("en", {
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td align="right">
                        {pm.totalKredit.toLocaleString("en", {
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td align="right">
                        {pm.totalTTKP.toLocaleString("en", {
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td align="right">
                        {pm.totalTagihTujuan.toLocaleString("en", {
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td align="right">
                        {pm.OsAr.toLocaleString("en", {
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td align="right" className="text-sky-600">
                        <Link
                          target={"_blank"}
                          to={{
                            pathname: `/PengirimanDetail?trxDt=${moment(
                              pm.ValueDt
                            ).format(
                              "YYYY-MM-DD"
                            )}&podRating=GOOD&process=${process}&addrType=${addrType}`,
                          }}
                        >
                          {_.ceil(pm.goodDelivery)}%
                        </Link>
                      </td>
                      <td align="right" className="text-sky-600">
                        <Link
                          target={"_blank"}
                          to={{
                            pathname: `/PengirimanDetail?trxDt=${moment(
                              pm.ValueDt
                            ).format(
                              "YYYY-MM-DD"
                            )}&podRating=STANDARD&process=${process}&addrType=${addrType}`,
                          }}
                        >
                          {_.ceil(pm.stdDelivery)}%
                        </Link>
                      </td>
                      <td align="right" className="text-sky-600">
                        <Link
                          target={"_blank"}
                          to={{
                            pathname: `/PengirimanDetail?trxDt=${moment(
                              pm.ValueDt
                            ).format(
                              "YYYY-MM-DD"
                            )}&podRating=SLOW&process=${process}&addrType=${addrType}`,
                          }}
                        >
                          {_.ceil(pm.badDelivery)}%
                        </Link>
                      </td>
                      <td align="right" className="text-sky-600">
                        <Link
                          target={"_blank"}
                          to={{
                            pathname: `/PengirimanDetail?trxDt=${moment(
                              pm.ValueDt
                            ).format(
                              "YYYY-MM-DD"
                            )}&podRating=UNKNOWN&process=${process}&addrType=${addrType}`,
                          }}
                        >
                          {_.ceil(pm.unknownDelivery)}%
                        </Link>
                      </td>
                      <td className="text-sky-600">
                        <Link
                          target={"_blank"}
                          to={{
                            pathname: `/PengirimanDetail?trxDt=${moment(
                              pm.ValueDt
                            ).format("YYYY-MM-DD")}&process=${process}&addrType=${addrType}`,
                          }}
                        >
                          Detail
                        </Link>
                      </td>
                    </tr>
                  ))}
                  <tr className="font-bold">
                    <td></td>
                    <td align="right">
                      {
                        <span className="number">
                          {_.sum(
                            _.map(pengirimanMonitoring.Result, (pm) => pm.TotalResiCount)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 0,
                          })}
                        </span>
                      }
                    </td>
                    <td align="right">
                      {
                        <span className="number">
                          {_.sum(
                            _.map(pengirimanMonitoring.Result, (pm) => pm.totalitem)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 0,
                          })}
                        </span>
                      }
                    </td>
                    <td align="right">
                      {
                        <span className="number">
                          {_.sum(
                            _.map(pengirimanMonitoring.Result, (pm) => pm.finalWeight)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 0,
                          })}
                        </span>
                      }
                    </td>
                    <td align="right">
                      {
                        <span className="number">
                          {_.sum(
                            _.map(pengirimanMonitoring.Result, (pm) => pm.totalLunas)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 0,
                          })}
                        </span>
                      }
                    </td>
                    <td align="right">
                      {
                        <span className="number">
                          {_.sum(
                            _.map(pengirimanMonitoring.Result, (pm) => pm.totalKredit)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 0,
                          })}
                        </span>
                      }
                    </td>
                    <td align="right">
                      {
                        <span className="number">
                          {_.sum(
                            _.map(pengirimanMonitoring.Result, (pm) => pm.totalTTKP)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 0,
                          })}
                        </span>
                      }
                    </td>
                    <td align="right">
                      {
                        <span className="number">
                          {_.sum(
                            _.map(pengirimanMonitoring.Result, (pm) => pm.totalTagihTujuan)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 0,
                          })}
                        </span>
                      }
                    </td>
                    <td align="right">
                      {
                        <span className="number">
                          {_.sum(
                            _.map(pengirimanMonitoring.Result, (pm) => pm.OsAr)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 0,
                          })}
                        </span>
                      }
                    </td>
                    <td align="right">
                      {_.ceil(
                        _.mean(
                          _.map(
                            pengirimanMonitoring.Result,
                            (pm) => pm.goodDelivery
                          )
                        )
                      )}
                      %
                    </td>
                    <td align="right">
                      {_.ceil(
                        _.mean(
                          _.map(
                            pengirimanMonitoring.Result,
                            (pm) => pm.stdDelivery
                          )
                        )
                      )}
                      %
                    </td>
                    <td align="right">
                      {_.ceil(
                        _.mean(
                          _.map(
                            pengirimanMonitoring.Result,
                            (pm) => pm.badDelivery
                          )
                        )
                      )}
                      %
                    </td>
                    <td align="right">
                      {_.ceil(
                        _.mean(
                          _.map(
                            pengirimanMonitoring.Result,
                            (pm) => pm.unknownDelivery
                          )
                        )
                      )}
                      %
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="block sm:hidden w-full">
              <div className="rounded px-3">
                {pengirimanMonitoring.Result.map((pm) => (
                  <div
                    className="h-full w-full bg-white shadow flex rounded items-center my-2 text-xs sm:text-sm"
                    key={pm.ValueDt}
                    onClick={() => handleDirect(pm.ValueDt)}
                  >
                    <div className="h-full w-full flex items-center px-3 py-3">
                      <div className="font-medium w-full">
                        <div className="flex">
                          <div className="flex-1 flex items-end">
                            <h4 className="w-full text-xl font-semibold">
                              {moment(pm.ValueDt).format("DD MMMM YYYY")}
                            </h4>
                          </div>
                        </div>
                        <div className="flex py-1">
                          <div className="flex-1 flex items-end text-red-500">
                            {`${pm.TotalResiCount} Resi, ${pm.totalitem
                              } Koli, ${pm.finalWeight.toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )} Kg`}
                          </div>
                        </div>
                        <div className="flex py-1">
                          <div className="flex-1 font-semibold text-gray-600">
                            {`Sisa tagihan : Rp. ${pm.totalKredit.toLocaleString(
                              "en",
                              {
                                minimumFractionDigits: 0,
                              }
                            )}`}
                          </div>
                        </div>
                        <div className="flex py-1">
                          <div className="flex-grow">
                            <div className="h-2 w-auto bg-green-500"></div>
                            <Link
                              target={"_blank"}
                              to={{
                                pathname: `/PengirimanDetail?trxDt=${moment(
                                  pm.ValueDt
                                ).format(
                                  "YYYY-MM-DD"
                                )}&podRating=GOOD&process=${process}&addrType=${addrType}`,
                              }}
                              style={{ color: "#000000" }}
                            >
                              <div className="flex flex-col justify-center items-center">
                                <b>Cepat</b>
                                {pm.goodDelivery}%
                              </div>
                            </Link>
                          </div>
                          <div className="flex-grow">
                            <div className="h-2 w-auto bg-yellow-500"></div>
                            <Link
                              target={"_blank"}
                              to={{
                                pathname: `/PengirimanDetail?trxDt=${moment(
                                  pm.ValueDt
                                ).format(
                                  "YYYY-MM-DD"
                                )}&podRating=STANDARD&process=${process}&addrType=${addrType}`,
                              }}
                              style={{ color: "#000000" }}
                            >
                              <div className="flex flex-col justify-center items-center">
                                <b>Standar</b>
                                {pm.stdDelivery}%
                              </div>
                            </Link>
                          </div>
                          <div className="flex-grow">
                            <div className="h-2 w-auto bg-red-500"></div>
                            <Link
                              target={"_blank"}
                              to={{
                                pathname: `/PengirimanDetail?trxDt=${moment(
                                  pm.ValueDt
                                ).format(
                                  "YYYY-MM-DD"
                                )}&podRating=SLOW&process=${process}&addrType=${addrType}`,
                              }}
                              style={{ color: "#000000" }}
                            >
                              <div className="flex flex-col justify-center items-center">
                                <b>Lambat</b>
                                {pm.badDelivery}%
                              </div>
                            </Link>
                          </div>
                          <div className="flex-grow">
                            <div className="h-2 w-auto bg-blue-500"></div>
                            <Link
                              target={"_blank"}
                              to={{
                                pathname: `/PengirimanDetail?trxDt=${moment(
                                  pm.ValueDt
                                ).format(
                                  "YYYY-MM-DD"
                                )}&podRating=UNKNOWN&process=${process}&addrType=${addrType}`,
                              }}
                              style={{ color: "#000000" }}
                            >
                              <div className="flex flex-col justify-center items-center">
                                <b>Proses</b>
                                {pm.unknownDelivery}%
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default PengirimanMonitoringTable;
