export const pengirimanConstants = {
  PENGIRIMAN_MONITORING_REQUEST: "PENGIRIMAN_MONITORING_REQUEST",
  PENGIRIMAN_MONITORING_SUCCESS: "PENGIRIMAN_MONITORING_SUCCESS",
  PENGIRIMAN_MONITORING_FAILURE: "PENGIRIMAN_MONITORING_FAILURE",

  PENGIRIMAN_DETAIL_REQUEST: "PENGIRIMAN_DETAIL_REQUEST",
  PENGIRIMAN_DETAIL_SUCCESS: "PENGIRIMAN_DETAIL_SUCCESS",
  PENGIRIMAN_DETAIL_FAILURE: "PENGIRIMAN_DETAIL_FAILURE",

  PENGIRIMAN_DATA_EXPORT_REQUEST: "PENGIRIMAN_DATA_EXPORT_REQUEST",
  PENGIRIMAN_DATA_EXPORT_SUCCESS: "PENGIRIMAN_DATA_EXPORT_SUCCESS",
  PENGIRIMAN_DATA_EXPORT_FAILURE: "PENGIRIMAN_DATA_EXPORT_FAILURE",
};
