import { resiConstants } from "../_constants";

let resiHInfoObj = JSON.parse(localStorage.getItem("resiHInfo"));
const initialStateResiHInfo = resiHInfoObj
  ? { loggedIn: true, resiHInfo: resiHInfoObj.resiHInfo }
  : {};

export function resiHInfo(state = initialStateResiHInfo, action) {
  switch (action.type) {
    case resiConstants.RESI_INFO_GET_REQUEST:
      return {
        resiHInfo: action.resiHInfo.resiHInfo,
      };
    case resiConstants.RESI_INFO_GET_SUCCESS:
      return {
        resiHInfo: action.resiHInfo.resiHInfo,
      };
    case resiConstants.RESI_INFO_GET_FAILURE:
      return {};
    default:
      return state;
  }
}

let addResiComplaintObj = JSON.parse(localStorage.getItem("addResiComplaint"));
const initialStateAddComplaintMessages = addResiComplaintObj
  ? {
      loggedIn: true,
      complaintMessages: addResiComplaintObj.addResiComplaint,
    }
  : {};
export function addResiComplaint(
  state = initialStateAddComplaintMessages,
  action
) {
  switch (action.type) {
    case resiConstants.RESI_COMPLAINT_ADD_REQUEST:
      return {
        addResiComplaint: action.addResiComplaint.addResiComplaint,
      };
    case resiConstants.RESI_COMPLAINT_ADD_SUCCESS:
      return {
        addResiComplaint: action.addResiComplaint.addResiComplaint,
      };
    case resiConstants.RESI_COMPLAINT_ADD_FAILURE:
      return {};
    default:
      return state;
  }
}

let getComplaintMessagesObj = JSON.parse(
  localStorage.getItem("getComplaintMessagesObj")
);
const initialStateGetComplaintMessages = getComplaintMessagesObj
  ? {
      loggedIn: true,
      getComplaintMessagesObj: getComplaintMessagesObj.getComplaintMessagesObj,
    }
  : {};

export function getComplaintMessages(
  state = initialStateGetComplaintMessages,
  action
) {
  switch (action.type) {
    case resiConstants.RESI_COMPLAINT_GET_REQUEST:
      return {
        getComplaintMessagesObj:
          action.getComplaintMessagesObj.getComplaintMessagesObj,
      };
    case resiConstants.RESI_COMPLAINT_GET_SUCCESS:
      return {
        getComplaintMessagesObj:
          action.getComplaintMessagesObj.getComplaintMessagesObj,
      };
    case resiConstants.RESI_COMPLAINT_GET_FAILURE:
      return {};
    default:
      return state;
  }
}


let printResiObj = JSON.parse(localStorage.getItem('printResiObj'));
const initialStatePrintResiObj = printResiObj
    ? {
        loggedIn: true,
        printResiObj: printResiObj.printResiObj,
    }
    : {};

export function printResi(state = initialStatePrintResiObj, action) {
    switch (action.type) {
        case resiConstants.PRINT_RESI_REQUEST:
            return {
                printResiObj: action.printResiObj.printResiObj
            };
        case resiConstants.PRINT_RESI_SUCCESS:
            return {
                printResiObj: action.printResiObj.printResiObj
            };
        case resiConstants.PRINT_RESI_FAILURE:
            return {};
        case resiConstants.PRINT_RESI_CLEAR:
            return {};
        default:
            return state
    }
}


let printResiBulkyObj = JSON.parse(localStorage.getItem('printResiBulkyObj'));
const initialStatePrintResiBulkyObj = printResiBulkyObj
    ? {
        loggedIn: true,
        printResiBulkyObj: printResiBulkyObj.printResiBulkyObj,
    }
    : {};

export function printResiBulky(state = initialStatePrintResiBulkyObj, action) {
    switch (action.type) {
        case resiConstants.PRINT_RESI_BULKY_REQUEST:
            return {
              printResiBulkyObj: action.printResiBulkyObj.printResiBulkyObj
            };
        case resiConstants.PRINT_RESI_BULKY_SUCCESS:
            return {
              printResiBulkyObj: action.printResiBulkyObj.printResiBulkyObj
            };
        case resiConstants.PRINT_RESI_BULKY_FAILURE:
            return {};
        case resiConstants.PRINT_RESI_BULKY_CLEAR:
            return {};
        default:
            return state
    }
}