import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bookingActions } from '../../_actions';

const DownloadBookingResi = ({ t }) => {
    const dispatch = useDispatch()
    const acc = useSelector(state => state.authentication.accInfo);
    const printBookingObj = useSelector(state => state.printBookings.printBookingObj);

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();
    const trxNo = query.get("trxNo");
    const itemCodeNo = query.get("itemCodeNo");

    useEffect(() => {
        dispatch(bookingActions.clearPrintBooking());
        if (trxNo && acc != undefined) {
            const printBooking = async () => {
                dispatch(bookingActions.requestPrintBooking(trxNo, itemCodeNo));
            }
            printBooking()
        }
    }, [])

    function downloadPDF(printBookingObj) {
        console.log('Masuk Download Pdf')
        const linkSource = `data:application/pdf;base64,${printBookingObj}`;
        const downloadLink = document.createElement("a");
        const fileName = "label_" + trxNo + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        dispatch(bookingActions.clearPrintBooking());
        window.close();
    }

    useEffect(() => {
        if (trxNo && acc != undefined && printBookingObj && printBookingObj != '' && printBookingObj != null) {
            downloadPDF(printBookingObj);
        }
    }, [printBookingObj])

    return (
        <>
        </>
    );
};
export default DownloadBookingResi;