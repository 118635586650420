import { httpHelpers } from '../_helpers';
import { urlConstants } from '../_constants';

export const bookingService = {
    requestMonitoring,
    requestDetail,
    requestViewHObj,
    requestViewDObj,
    requestCalculateBooking,
    requestSubmitBooking,
    requestPrintBooking,
    requestExportDetail,
    requestUploadBooking,
    getCustBookHDetail,
    cancelKoliBooking
};

function requestMonitoring(monitoringObj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(monitoringObj)
    };

    return fetch(urlConstants.URL_BOOKING_MONITOR, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    bookingObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('bookingMonitor', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function requestDetail(bookDetailObj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bookDetailObj)
    };

    return fetch(urlConstants.URL_BOOKING_DETAIL, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    bookingDetailObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('bookingDetail', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function requestViewHObj(bookingHId, custId) {
    const custBookObj = { Id: bookingHId, CustId: custId };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(custBookObj)
    };

    return fetch(urlConstants.URL_BOOKING_VIEW_H, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    bookingHObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('bookingHObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function requestViewDObj(bookingDId, custId) {
    const custBookDObj = { Id: bookingDId, CustId: custId };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(custBookDObj)
    };

    return fetch(urlConstants.URL_BOOKING_VIEW_D, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    bookingDObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('bookingDObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function requestCalculateBooking(bookingObj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bookingObj)
    };

    return fetch(urlConstants.URL_CALCULATE_BOOKING, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    calcBookingObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('calcBookingObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function requestSubmitBooking(bookingObj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bookingObj)
    };

    return fetch(urlConstants.URL_SUBMIT_BOOKING, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    submitBookingObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('submitBookingObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function requestPrintBooking(resiNo, itemCodeNo) {
    const printObj = { TrxNo: resiNo, ItemCodeNo: itemCodeNo };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(printObj)
    };

    return fetch(urlConstants.URL_PRINT_BOOKING, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    printBookingObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('printBookingObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function requestExportDetail(exportBookingDetailObj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(exportBookingDetailObj)
    };

    return fetch(urlConstants.URL_EXPORT_BOOKING_DETAIL, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    exportBookingDetailObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('exportBookingDetail', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function requestUploadBooking(uploadBookingObj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(uploadBookingObj)
    };

    return fetch(urlConstants.URL_UPLOAD_BOOKING, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('MASUK SERVICE')
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    uploadBookingObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('uploadBooking', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function getCustBookHDetail(custBookHDetailObj) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(custBookHDetailObj),
    };

    return fetch(urlConstants.URL_CUST_BOOK_EDIT, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log('MASUK SERVICE 2')
            console.log('result: ', result)
            if (result) {
                var _result = {
                    custBookHDetailObj: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem("custBookHDetailObj", JSON.stringify(_result));
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function cancelKoliBooking(cancelKoliBookingObj) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(cancelKoliBookingObj),
    };

    return fetch(urlConstants.URL_CAN_KOLI_BOOKING, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            if (result) {
                var _result = {
                    cancelKoliBookingObj: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem("cancelKoliBookingObj", JSON.stringify(_result));
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}
