import React, { useState, useEffect } from "react";
import * as FaIcons from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import "./NavMenu.css";
import { IconContext } from "react-icons";
import { NavItem, NavLink } from "reactstrap";
import control from "../assets/control.png";
import { accActions, pickupActions } from "../_actions";
import { useDispatch, useSelector } from "react-redux";
import scLogo from "../assets/NewSentralCargo_Wht_320.png";
import "../styles/tailwind.css";

const NavMenu = (prop) => {
    const [sidebar, setSidebar] = useState(false);
    const dispatch = useDispatch();
    const acc = useSelector((state) => state.authentication.accInfo);
    const pickupAddrSelectedObj = useSelector((state) => state.selectedPickupAddr.selectedPickupAddrObj);

    function dateParser(value) {
        const date = new Date(value);
        const dateParsed =
            date.getFullYear() +
            "-" +
            date.toLocaleString("en-us", { month: "2-digit" }) +
            "-" +
            date.toLocaleString("en-us", { day: "2-digit" });
        return dateParsed;
    }
    const maxDate = new Date();
    const minDate = new Date();
    const finalMinDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
    const [requestObj, setRequestObj] = useState({
        trxDtFrom: dateParser(finalMinDate),
        trxDtTo: dateParser(maxDate),
    });

    const Menus = [,
        // {
        //     title: "Dictionary",
        //     subMenu: [
        //         { title: "Origin", path: "/OriginDictionary" },
        //         { title: "District", path: "/DistrictDictionary" },
        //         { title: "Package Content", path: "/PackageContentDictionary" },
        //     ],
        // },
        {
            title: "Booking",
            subMenu: [
                { title: "Booking Monitoring", path: `/booking-monitor?custId=${acc && acc.CustId}&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${pickupAddrSelectedObj && pickupAddrSelectedObj.AddrType}` },
                { title: "Booking Detail", path: `/booking-details?custId=${acc && acc.CustId}&trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&addrType=${pickupAddrSelectedObj && pickupAddrSelectedObj.AddrType}` },
                // { title: "Buat Booking", path: "/booking-add" },
                // { title: "Buat Booking V2", path: "/booking-addv2" },
                { title: "Buat Booking", path: "/booking-addv3" },
                // { title: "Upload Booking", path: "/BookingUploadForm" },
            ],
        },
        {
            title: "Pickup",
            subMenu: [
                { title: "Pickup Monitoring", path: `/PickupReqMonitoring?custId=${acc && acc.CustId}trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&&pickupReqGrpId=${pickupAddrSelectedObj && pickupAddrSelectedObj.PickupReqGrpId}` },
                { title: "Pickup Detail", path: `/PickupReqDetail?custId=${acc && acc.CustId}trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&&pickupReqGrpId=${pickupAddrSelectedObj && pickupAddrSelectedObj.PickupReqGrpId}` },
                // { title: "Pickup Address", path: "/PickupAddressPaging" },
                // { title: "Buat Pickup Address", path: "/PickupAddressForm" },
            ],
        },
        {
            title: "Resi",
            subMenu: [
                { title: "Pengiriman Monitoring", path: `/PengirimanMonitoring?custId=${acc && acc.CustId}trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&&addrType=${pickupAddrSelectedObj && pickupAddrSelectedObj.AddrType}` },
                { title: "Pengiriman Detail", path: `/PengirimanDetail?custId=${acc && acc.CustId}trxDtFrom=${requestObj.trxDtFrom}&trxDtTo=${requestObj.trxDtTo}&&addrType=${pickupAddrSelectedObj && pickupAddrSelectedObj.AddrType}` },
                //{ title: "Penerimaan Monitoring", path: "/PenerimaanMonitoring" },
            ],
        }
        //  ,{
        //      title: "Upload",
        //      subMenu: [
        //          { title: "Upload Monitoring", path: "/UploadMonitoring" }
        //      ],
        //  }
    ];

    const MasterMenus = [,
        {
            title: "Booking",
            subMenu: [
                { title: "Booking Monitoring", path: "/booking-monitor" },
                { title: "Booking Detail", path: "/booking-details" },
                { title: "Buat Booking", path: "/booking-addv4" },
            ],
        },
        {
            title: "Pickup",
            subMenu: [
                { title: "Pickup Monitoring", path: "/PickupReqMonitoring" },
                { title: "Pickup Detail", path: "/PickupReqDetail" },
                { title: "Pickup Address", path: "/PickupAddressPaging" },
                { title: "Buat Pickup Address", path: "/PickupAddressForm" },
            ],
        },
        {
            title: "Resi",
            subMenu: [
                { title: "Pengiriman Monitoring", path: "/PengirimanMonitoring" },
                { title: "Pengiriman Detail", path: "/PengirimanDetail" },
                { title: "Penerimaan Monitoring", path: "/PenerimaanMonitoring" },
            ],
        }
        //  ,{
        //      title: "Upload",
        //      subMenu: [
        //          { title: "Upload Monitoring", path: "/UploadMonitoring" }
        //      ],
        //  }
    ];

    const history = useHistory();
    const showSidebar = (data) => {
        setSidebar(data);
        prop.onSubmit(data);
    };

    function logout(e) {
        e.preventDefault();
        dispatch(accActions.logout());
        dispatch(pickupActions.clearSelectedPickupAddr());
    }

    function getToday() {
        const date = new Date();
        let dateParsed = null;
        dateParsed =
            date.toLocaleString("en-us", { day: "2-digit" }) +
            " " +
            date.toLocaleString("en-us", { month: "short" }) +
            " " +
            date.getFullYear();
        return dateParsed;
    }

    useEffect(() => {
        if (acc == undefined) {
            dispatch(accActions.logout());
            history.push("/");
        }
        console.log("DATA ACC", acc)
    }, []);

    function directToProfile() {
        history.push(`/Profile`);
    }

    return (
        <>
            <div className="hidden sm:block">
                <IconContext.Provider value={{ color: "#fff" }}>
                    <div className="flex navbar z-10 bg-blue-600">
                        <div className="menu-bars">
                            <div className="flex flex-row">
                                <div className="flex-1">
                                    <FaIcons.FaBars onClick={(event) => showSidebar(!sidebar)} />
                                </div>
                                <div className="flex-2">
                                    <Link to="/Home">
                                        <img src={scLogo} className="scIcon" />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="menu-logout">
                            <div className="flex flex-row">
                                <div className="flex-2 text-sm text-white mr-3 mt-1 tems-center" onClick={() => directToProfile()}>
                                    {acc && acc.CustName} {pickupAddrSelectedObj && acc.IsSubAccount === true && (` - ${pickupAddrSelectedObj.AddrType} - ${pickupAddrSelectedObj.Kecamatan}`)} - {getToday()}
                                </div>
                                <div className="flex-1">
                                    <FaIcons.FaSignOutAlt onClick={(e) => logout(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav
                        className={
                            sidebar ? "nav-menu text-xs sm:text-sm active overflow-y-auto" : "nav-menu"
                        }
                    >
                        <ul
                            className="nav-menu-items"
                            onClick={(event) => showSidebar(!sidebar)}
                        >
                            <div className="border-b-2 border-gray-300">
                                <NavItem>
                                    <NavLink
                                        tag={Link}
                                        className="text-dark text-sm sm:text-md font-bold"
                                        to="/Home"
                                    >
                                        Dashboard
                                    </NavLink>
                                </NavItem>
                            </div>

                            {pickupAddrSelectedObj && acc && acc.IsSubAccount === true && Menus && Menus.map((Menu, index) => (
                                <div className="border-b-2 border-gray-300" key={index}>
                                    <NavItem>
                                        <NavLink className="text-dark font-bold text-sm sm:text-md">
                                            {Menu.title}
                                        </NavLink>
                                    </NavItem>
                                    {acc && Menu && Menu.subMenu.map((sub, index) => (
                                        <NavItem key={index}>
                                            { sub &&
                                                <NavLink tag={Link} className="text-dark" to={sub.path}>
                                                    {sub.title}
                                                </NavLink>
                                            }
                                        </NavItem>
                                    ))}
                                </div>
                            ))}

                            {acc && acc.IsSubAccount === false && MasterMenus.map((Menu, index) => (
                                <div className="border-b-2 border-gray-300" key={index}>
                                    <NavItem>
                                        <NavLink className="text-dark font-bold text-sm sm:text-md">
                                            {Menu.title}
                                        </NavLink>
                                    </NavItem>
                                    {Menu.subMenu.map((sub, index) => (
                                        <NavItem key={index}>
                                            <NavLink tag={Link} className="text-dark" to={sub.path}>
                                                {sub.title}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </div>
                            ))}

                            <NavItem>
                                <NavLink
                                    className="text-dark font-bold text-sm sm:text-md"
                                    onClick={(e) => logout(e)}
                                >
                                    Sign Out
                                    <FaIcons.FaSignOutAlt
                                        className="text-dark ml-2 mb-1"
                                        style={{ display: "inline-block" }}
                                    />
                                </NavLink>
                            </NavItem>
                        </ul>
                    </nav>
                </IconContext.Provider>
            </div>
            <div className="block sm:hidden">
                <IconContext.Provider value={{ color: "#fff" }}>
                    <div className="flex navbar z-10 bg-blue-600">
                        <div className="menu-bars-mobile">
                            <div className="flex flex-row">
                                <div className="flex-1">
                                    <FaIcons.FaBars onClick={(event) => showSidebar(!sidebar)} />
                                </div>
                                <div className="flex-2">
                                    <Link to="/Home">
                                        <img src={scLogo} className="scIcon-mobile" />
                                    </Link>
                                </div>
                                <div className="menu-logout">
                                    <div className="flex flex-row">
                                        {acc && acc.IsSubAccount === true ?
                                            <div className="flex-2 text-sm text-white mr-2 mt--2 tems-center" onClick={() => directToProfile()} style={{ fontSize: 7, alignItems: "center", justifyContent: "center" }}>
                                                <p>{acc && acc.CustName}</p>
                                                <p>{pickupAddrSelectedObj && acc && acc.IsSubAccount === true && (`${pickupAddrSelectedObj.Kecamatan}`)} - {getToday()}</p>
                                            </div> :
                                            <div className="flex-2 text-sm text-white mr-2 mt-0.5 tems-center" onClick={() => directToProfile()} style={{ fontSize: 8, alignItems: "center", justifyContent: "center" }}>
                                                {acc && acc.CustName} - {getToday()}
                                            </div>
                                        }
                                        <div className="flex-1" >
                                            <FaIcons.FaSignOutAlt onClick={(e) => logout(e)} style={{ fontSize: '0.5em' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav
                        className={
                            sidebar ? "nav-menu text-xs sm:text-sm active" : "nav-menu"
                        }
                    >
                        <ul
                            className="nav-menu-items"
                            onClick={(event) => showSidebar(!sidebar)}
                        >
                            <img
                                src={control}
                                className="absolute cursor-pointer -right-3 top-9 w-7 border-none bg-white border-2 rounded-full"
                                onClick={() => showSidebar(!sidebar)}
                            />
                            <NavItem className="bg-gradient-to-b from-blue-600 to-blue-700">
                                <NavLink tag={Link} className="text-white font-bold" to="/Profile">
                                    <div className="flex items-center text-xs">
                                        <svg
                                            className="w-5 h-5 mr-2"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            ></path>
                                        </svg>
                                        {acc && acc.CustName}
                                    </div>
                                </NavLink>
                            </NavItem>
                            <div className="border-b-2 border-gray-300">
                                <NavItem>
                                    <NavLink
                                        tag={Link}
                                        className="text-dark text-xs sm:text-sm font-bold"
                                        to="/Home"
                                    >
                                        Dashboard
                                    </NavLink>
                                </NavItem>
                            </div>

                            {/* {MasterMenus.map((Menu, index) => (
                                <div className="border-b-2 border-gray-300" key={index}>
                                    <NavItem>
                                        <NavLink className="text-dark font-bold text-xs sm:text-sm">
                                            {Menu.title}
                                        </NavLink>
                                    </NavItem>
                                    {Menu.subMenu.map((sub, index) => (
                                        <NavItem key={index}>
                                            <NavLink tag={Link} className="text-dark" to={sub.path}>
                                                {sub.title}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </div>
                            ))} */}

                            {pickupAddrSelectedObj && acc.IsSubAccount === true && Menus.map((Menu, index) => (
                                <div className="border-b-2 border-gray-300" key={index}>
                                    <NavItem>
                                        <NavLink className="text-dark font-bold text-sm sm:text-md">
                                            {Menu.title}
                                        </NavLink>
                                    </NavItem>
                                    {Menu.subMenu.map((sub, index) => (
                                        <NavItem key={index}>
                                            <NavLink tag={Link} className="text-dark" to={sub.path}>
                                                {sub.title}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </div>
                            ))}

                            {acc && acc.IsSubAccount === false && MasterMenus.map((Menu, index) => (
                                <div className="border-b-2 border-gray-300" key={index}>
                                    <NavItem>
                                        <NavLink className="text-dark font-bold text-sm sm:text-md">
                                            {Menu.title}
                                        </NavLink>
                                    </NavItem>
                                    {Menu.subMenu.map((sub, index) => (
                                        <NavItem key={index}>
                                            <NavLink tag={Link} className="text-dark" to={sub.path}>
                                                {sub.title}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </div>
                            ))}
                            <NavItem>
                                <NavLink
                                    className="text-dark font-bold text-xs sm:text-sm"
                                    onClick={(e) => logout(e)}
                                >
                                    Sign Out
                                    <FaIcons.FaSignOutAlt
                                        className="text-dark ml-2 mb-1"
                                        style={{ display: "inline-block" }}
                                    />
                                </NavLink>
                            </NavItem>
                        </ul>
                    </nav>
                </IconContext.Provider>
            </div>
        </>
    );
};

export default NavMenu;
