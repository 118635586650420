import { authHeader, config, history, httpHelpers } from '../_helpers';
import { urlConstants } from '../_constants';


export const accService = {
    login,
    impersonateLogin,
    logout,
    resetPassword
};

function login(username, password) {
    const loginObj = { username: username, password: password };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginObj)
    };

    return fetch(urlConstants.URL_LOGIN, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    accInfo: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('acc', JSON.stringify(_result));
                }
            }
            else if (result.statusCode != "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function impersonateLogin(reqImpersonateLogin) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqImpersonateLogin)
    };
// console.log("==========",JSON.stringify(reqImpersonateLogin))
    return fetch(urlConstants.URL_IMPERSONATE_LOGIN, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    accInfo: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('acc', JSON.stringify(_result));
                }
            }
            else if (result.statusCode != "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function resetPassword(username, newpassword, isSubAccount) {
    const resetPasswordObj = { username: username, newpassword: newpassword, isResetPassword: true, isSubAccount: isSubAccount };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(resetPasswordObj)
    };

    return fetch(urlConstants.URL_CHANGE_PASSWORD, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    pwdObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('pwdObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function logout() {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('acc');
        localStorage.clear();
        history.push('/');
    }

}

