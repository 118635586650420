import { httpHelpers } from "../_helpers";
import { urlConstants } from "../_constants";

export const penerimaanService = {
  penerimaanMonitoring,
};

async function penerimaanMonitoring(penerimaanMonitoringObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(penerimaanMonitoringObj),
  };

  return fetch(urlConstants.URL_PENERIMAAN_MONITORING, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          penerimaanMonitoring: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem("penerimaanMonitoring", JSON.stringify(_result));
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}
