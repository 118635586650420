import { dashboardConstants } from "../_constants";
import { dashboardService } from "../_services";

export const dashboardActions = {
  summaryPengirimanDashboard,
  summaryPenerimaanDashboard,
  summaryKomplainDashboard,
  summaryBookingDashboard,
  summaryPickupReqDashboard,
  checkOngkir,
    clearCheckOngkir,
    getBanner
};

function summaryPengirimanDashboard(summaryPengirimanDashboardObj) {
  return (dispatch) => {
    dispatch(request({ summaryPengirimanDashboardObj }));
    dashboardService
      .summaryPengirimanDashboard(summaryPengirimanDashboardObj)
      .then(
        (summaryPengirimanDashboard) => {
          dispatch(success(summaryPengirimanDashboard));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request(summaryPengirimanDashboard) {
    return {
      type: dashboardConstants.SUMMARY_PENGIRIMAN_DASHBOARD_REQUEST,
      summaryPengirimanDashboard,
    };
  }
  function success(summaryPengirimanDashboard) {
    return {
      type: dashboardConstants.SUMMARY_PENGIRIMAN_DASHBOARD_SUCCESS,
      summaryPengirimanDashboard,
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants.SUMMARY_PENGIRIMAN_DASHBOARD_FAILURE,
      error,
    };
  }
}

function summaryPenerimaanDashboard(summaryPenerimaanDashboardObj) {
  return (dispatch) => {
    dispatch(request({ summaryPenerimaanDashboardObj }));
    dashboardService
      .summaryPenerimaanDashboard(summaryPenerimaanDashboardObj)
      .then(
        (summaryPenerimaanDashboard) => {
          dispatch(success(summaryPenerimaanDashboard));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request(summaryPenerimaanDashboard) {
    return {
      type: dashboardConstants.SUMMARY_PENERIMAAN_DASHBOARD_REQUEST,
      summaryPenerimaanDashboard,
    };
  }
  function success(summaryPenerimaanDashboard) {
    return {
      type: dashboardConstants.SUMMARY_PENERIMAAN_DASHBOARD_SUCCESS,
      summaryPenerimaanDashboard,
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants.SUMMARY_PENERIMAAN_DASHBOARD_FAILURE,
      error,
    };
  }
}

function summaryKomplainDashboard(summaryKomplainDashboardObj) {
  return (dispatch) => {
    dispatch(request({ summaryKomplainDashboardObj }));
    dashboardService.summaryKomplainDashboard(summaryKomplainDashboardObj).then(
      (summaryKomplainDashboard) => {
        dispatch(success(summaryKomplainDashboard));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(summaryKomplainDashboard) {
    return {
      type: dashboardConstants.SUMMARY_KOMPLAIN_DASHBOARD_REQUEST,
      summaryKomplainDashboard,
    };
  }
  function success(summaryKomplainDashboard) {
    return {
      type: dashboardConstants.SUMMARY_KOMPLAIN_DASHBOARD_SUCCESS,
      summaryKomplainDashboard,
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants.SUMMARY_KOMPLAIN_DASHBOARD_FAILURE,
      error,
    };
  }
}

function summaryBookingDashboard(summaryBookingDashboardObj) {
  return (dispatch) => {
    dispatch(request({ summaryBookingDashboardObj }));
    dashboardService.summaryBookingDashboard(summaryBookingDashboardObj).then(
      (summaryBookingDashboard) => {
        dispatch(success(summaryBookingDashboard));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(summaryBookingDashboard) {
    return {
      type: dashboardConstants.SUMMARY_BOOKING_DASHBOARD_REQUEST,
      summaryBookingDashboard,
    };
  }
  function success(summaryBookingDashboard) {
    return {
      type: dashboardConstants.SUMMARY_BOOKING_DASHBOARD_SUCCESS,
      summaryBookingDashboard,
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants.SUMMARY_BOOKING_DASHBOARD_FAILURE,
      error,
    };
  }
}

function summaryPickupReqDashboard(summaryPickupReqDashboardObj) {
  return (dispatch) => {
    dispatch(request({ summaryPickupReqDashboardObj }));
    dashboardService
      .summaryPickupReqDashboard(summaryPickupReqDashboardObj)
      .then(
        (summaryPickupReqDashboard) => {
          dispatch(success(summaryPickupReqDashboard));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request(summaryPickupReqDashboard) {
    return {
      type: dashboardConstants.SUMMARY_PICKUP_REQ_DASHBOARD_REQUEST,
      summaryPickupReqDashboard,
    };
  }
  function success(summaryPickupReqDashboard) {
    return {
      type: dashboardConstants.SUMMARY_PICKUP_REQ_DASHBOARD_SUCCESS,
      summaryPickupReqDashboard,
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants.SUMMARY_PICKUP_REQ_DASHBOARD_FAILURE,
      error,
    };
  }
}

function checkOngkir(checkOngkirObj) {
  return (dispatch) => {
    dispatch(request({ checkOngkirObj }));
    dashboardService.checkOngkir(checkOngkirObj).then(
      (checkOngkir) => {
        dispatch(success(checkOngkir));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(checkOngkir) {
    return {
      type: dashboardConstants.CHECK_ONGKIR_REQUEST,
      checkOngkir,
    };
  }
  function success(checkOngkir) {
    return {
      type: dashboardConstants.CHECK_ONGKIR_SUCCESS,
      checkOngkir,
    };
  }
  function failure(error) {
    return { type: dashboardConstants.CHECK_ONGKIR_FAILURE, error };
  }
}


function getBanner() {
    return (dispatch) => {
        dispatch(request());
        dashboardService.getBanner().then(
            (banner) => {
                dispatch(success(banner));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return {
            type: dashboardConstants.BANNER_DASHBOARD_REQUEST,
        };
    }
    function success(banner) {
        return {
            type: dashboardConstants.BANNER_DASHBOARD_SUCCESS,
            banner,
        };
    }
    function failure(error) {
        return { type: dashboardConstants.BANNER_DASHBOARD_FAILURE, error };
    }
}

function clearCheckOngkir() {
  return (dispatch) => {
    dispatch(clear());
    if (typeof localStorage !== "undefined") {
      localStorage.removeItem("checkOngkir");
    }
  };

  function clear() {
    return { type: dashboardConstants.CHECK_ONGKIR_CLEAR };
  }
}
