export const dashboardConstants = {
    SUMMARY_PENGIRIMAN_DASHBOARD_REQUEST: "SUMMARY_PENGIRIMAN_DASHBOARD_REQUEST",
    SUMMARY_PENGIRIMAN_DASHBOARD_SUCCESS: "SUMMARY_PENGIRIMAN_DASHBOARD_SUCCESS",
    SUMMARY_PENGIRIMAN_DASHBOARD_FAILURE: "SUMMARY_PENGIRIMAN_DASHBOARD_FAILURE",

    SUMMARY_PENERIMAAN_DASHBOARD_REQUEST: "SUMMARY_PENERIMAAN_DASHBOARD_REQUEST",
    SUMMARY_PENERIMAAN_DASHBOARD_SUCCESS: "SUMMARY_PENERIMAAN_DASHBOARD_SUCCESS",
    SUMMARY_PENERIMAAN_DASHBOARD_FAILURE: "SUMMARY_PENERIMAAN_DASHBOARD_FAILURE",

    SUMMARY_KOMPLAIN_DASHBOARD_REQUEST: "SUMMARY_KOMPLAIN_DASHBOARD_REQUEST",
    SUMMARY_KOMPLAIN_DASHBOARD_SUCCESS: "SUMMARY_KOMPLAIN_DASHBOARD_SUCCESS",
    SUMMARY_KOMPLAIN_DASHBOARD_FAILURE: "SUMMARY_KOMPLAIN_DASHBOARD_FAILURE",

    SUMMARY_BOOKING_DASHBOARD_REQUEST: "SUMMARY_BOOKING_DASHBOARD_REQUEST",
    SUMMARY_BOOKING_DASHBOARD_SUCCESS: "SUMMARY_BOOKING_DASHBOARD_SUCCESS",
    SUMMARY_BOOKING_DASHBOARD_FAILURE: "SUMMARY_BOOKING_DASHBOARD_FAILURE",

    SUMMARY_PICKUP_REQ_DASHBOARD_REQUEST: "SUMMARY_PICKUP_REQ_DASHBOARD_REQUEST",
    SUMMARY_PICKUP_REQ_DASHBOARD_SUCCESS: "SUMMARY_PICKUP_REQ_DASHBOARD_SUCCESS",
    SUMMARY_PICKUP_REQ_DASHBOARD_FAILURE: "SUMMARY_PICKUP_REQ_DASHBOARD_FAILURE",

    BANNER_DASHBOARD_REQUEST: "BANNER_DASHBOARD_REQUEST",
    BANNER_DASHBOARD_SUCCESS: "BANNER_DASHBOARD_SUCCESS",
    BANNER_DASHBOARD_FAILURE: "BANNER_DASHBOARD_FAILURE",

    CHECK_ONGKIR_REQUEST: "CHECK_ONGKIR_REQUEST",
    CHECK_ONGKIR_SUCCESS: "CHECK_ONGKIR_SUCCESS",
    CHECK_ONGKIR_FAILURE: "CHECK_ONGKIR_FAILURE",
    CHECK_ONGKIR_CLEAR: "CHECK_ONGKIR_CLEAR",
};
