import { urlConstants } from "../_constants";
import { httpHelpers } from "../_helpers";

export const profileService = {
  getProfileData,
  getListCustPointHistTrx,
  getListCustPointRedemptionTrx,
  getQrCodeMember,
  sendEmailQrCodeCust
};

function getProfileData(custId) {
  const getProfileDataObj = {
    id: custId,
  };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(getProfileDataObj),
  };

  return fetch(urlConstants.URL_GET_PROFILE_DATA, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          profileData: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem("profileData", JSON.stringify(_result));
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}

function getListCustPointHistTrx(getListCustPointHistTrxObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(getListCustPointHistTrxObj),
  };

  return fetch(urlConstants.URL_GET_LIST_CUST_POINT_HIST, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          custPointHistTrx: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem("custPointHistTrx", JSON.stringify(_result));
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}

function getListCustPointRedemptionTrx(getListCustPointRedemptionTrxObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(getListCustPointRedemptionTrxObj),
  };

  return fetch(urlConstants.URL_GET_LIST_CUST_POINT_REDEMPTION, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          custPointRedemptionTrx: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem("custPointRedemptionTrx", JSON.stringify(_result));
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}

function getQrCodeMember(custId) {
  const getQRCodeObj = {
    CustId: custId,
  };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(getQRCodeObj),
  };

  return fetch(urlConstants.URL_GET_QR_CODE, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          getQrCodeMemberObj: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem("getQrCodeMemberObj", JSON.stringify(_result));
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}

function sendEmailQrCodeCust(sendEmailQrCodeCustObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(sendEmailQrCodeCustObj),
  };

  return fetch(urlConstants.URL_SEND_EMAIL_QR_CODE, requestOptions)
    .then(httpHelpers.handleResponse, httpHelpers.handleError)
    .then((result) => {
      console.log("result: ", result);
      if (result) {
        var _result = {
          sendEmailQrCodeCust: result.ObjectResult,
        };
        if (typeof localStorage !== "undefined") {
          localStorage.setItem("sendEmailQrCodeCust", JSON.stringify(_result));
        }
      } else if (result.statusCode !== "200") {
        throw new Error(result.message);
      }
      return _result;
    });
}