import React from "react";

const ShortcutDate = (prop) => {
    function dateParser(value) {
        const date = new Date(value);
        const dateParsed =
            date.getFullYear() +
            "-" +
            date.toLocaleString("en-us", { month: "2-digit" }) +
            "-" +
            date.toLocaleString("en-us", { day: "2-digit" });
        return dateParsed;
    }

    function handleDate(type) {
        let date = new Date();
        let dateFrom = new Date();
        let dateTo = new Date();

        switch (type) {
            case "today":
                dateFrom = dateParser(date);
                dateTo = dateParser(date);
                break;
            case "h-1":
                dateFrom = dateParser(
                    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
                );
                dateTo = dateParser(
                    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
                );
                break;
            case "h-2":
                dateFrom = dateParser(
                    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 2)
                );
                dateTo = dateParser(
                    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 2)
                );
                break;
            case "mtd":
                dateFrom = dateParser(new Date(date.getFullYear(), date.getMonth(), 1));
                dateTo = dateParser(date);
                break;
            case "m-1":
                dateFrom = dateParser(
                    new Date(date.getFullYear(), date.getMonth() - 1, 1)
                );
                dateTo = dateParser(new Date(date.getFullYear(), date.getMonth(), 0));
                break;
        }
        var returnObj = {
            dateFrom: dateFrom,
            dateTo: dateTo,
        };
        prop.onSubmit(returnObj);
    }

    return (
        <div className="justify-center items-center flex flex-row py-3 text-xs sm:text-sm -mt-2">
            <button
                type="button"
                onClick={() => handleDate("today")}
                className="group relative inline-flex justify-center items-center py-1 px-2 w-16 mx-1 border border-transparent text-xs sm:text-sm rounded-full leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
            >
                today
            </button>
            <button
                type="button"
                onClick={() => handleDate("h-1")}
                className="group relative inline-flex justify-center items-center py-1 px-2 w-16 mx-1 border border-transparent text-xs sm:text-sm rounded-full leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
            >
                h-1
            </button>
            <button
                type="button"
                onClick={() => handleDate("h-2")}
                className="group relative inline-flex justify-center items-center py-1 px-2 w-16 mx-1 border border-transparent text-xs sm:text-sm rounded-full leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
            >
                h-2
            </button>
            <button
                type="button"
                onClick={() => handleDate("mtd")}
                className="group relative inline-flex justify-center items-center py-1 px-2 w-16 mx-1 border border-transparent text-xs sm:text-sm rounded-full leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
            >
                mtd
            </button>
            <button
                type="button"
                onClick={() => handleDate("m-1")}
                className="group relative inline-flex justify-center items-center py-1 px-2 w-16 mx-1 border border-transparent text-xs sm:text-sm rounded-full leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
            >
                m-1
            </button>
        </div>
    );
};

export default ShortcutDate;
