import React, { useState, useEffect } from "react";
import { profileActions } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ProfileCustPointHist from "./ProfileCustPointHist";
import ProfileCustPointRedemption from "./ProfileCustPointRedemption";
import QRCodeMember from "./QRCodeMember";

const Profile = ({ t }) => {
  const dispatch = useDispatch();
  const acc = useSelector((state) => state.authentication.accInfo);
  const profileData = useSelector((state) => state.profileData.profileData);

  const [submitted, setSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useState("1");
  const [modalShow, setModalShow] = useState({
    WChangePassword: false,
    MChangePassword: false,
  });
  const [changePassForm, setChangePassForm] = useState({
    OldPassword: "",
    NewPassword: "",
    ConfirmPassword: ""
  });

  useEffect(() => {
    dispatch(profileActions.getProfileData(acc.CustId));
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (
      changePassForm.OldPassword &&
      changePassForm.NewPassword &&
      changePassForm.ConfirmPassword &&
      acc
    ) {
      const changePasswordObj = {
        CustId: acc.CustId,
        OldPassword: changePassForm.OldPassword,
        NewPassword: changePassForm.NewPassword,
        ConfirmPassword: changePassForm.ConfirmPassword,
      };
      //dispatch(profileActions.changePassword(changePasswordObj));
      console.log(changePasswordObj);
      clearForm();
    }
  }

  function clearForm() {
    setChangePassForm({
      OldPassword: "",
      NewPassword: "",
      ConfirmPassword: ""
    });
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setChangePassForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function toggle(name, value) {
    setModalShow((prevState) => ({
      ...prevState,
      [name]: !value,
    }));
  }

  function closeModal(name, value) {
    toggle(name, value);
    clearForm();
    setSubmitted(false);
  }

  return (
    <div>
      <div className="hidden sm:block mx-auto">
        <div className="justify-center -ml-56 py-2 px-4 flex flex-row ">
          <h1 className="font-bold text-xl">
            {isOpen === "1"
              ? "My Account"
              : isOpen === "2"
                ? "Point History Transaction"
                : isOpen === "3"
                  ? "Point Redemption Transaction"
                  : "QR Code Member"}
          </h1>
        </div>
        <div className="h-full flex pt-2">
          <div className="w-full">
            {isOpen === "1" && (
              <div className="pl-16 max-w-6xl mx-auto">
                <div className="row">
                  <label className="col-md-3 font-bold">Nama</label>
                  <label className="col-md-9">
                    {profileData && profileData.CustName}
                  </label>
                </div>
                <div className="row">
                  <label className="col-md-3 font-bold">
                    Tanggal Bergabung
                  </label>
                  <label className="col-md-9">
                    {profileData &&
                      moment(profileData.JoinDt).format("DD MMMM YYYY")}
                  </label>
                </div>
                <div className="row">
                  <label className="col-md-3 font-bold">Main Contact Person</label>
                  <label className="col-md-9">
                    {profileData && (
                      <>{`${profileData.ContactPerson} - ${profileData.MobilePhoneNo}`}</>
                    )}
                  </label>
                </div>
                <div className="row">
                  <label className="col-md-3 font-bold">Email</label>
                  <label className="col-md-9">
                    {profileData && profileData.Email}
                  </label>
                </div>
                <div className="row">
                  <label className="col-md-3 font-bold">Sales</label>
                  <label className="col-md-9">
                    {profileData && profileData.SalesName}
                  </label>
                </div>
                <div className="row">
                  <label className="col-md-3 font-bold">CRO</label>
                  <label className="col-md-9">
                    {profileData && profileData.CROName}
                  </label>
                </div>
                <div className="row">
                  <label className="col-md-3 font-bold">Point</label>
                  <label className="col-md-9">
                    {profileData &&
                      profileData.PointAmt.toLocaleString("en", {
                        minimumFractionDigits: 0,
                      })}
                  </label>
                </div>
                {/* <div className="row">
                  <label className="col-md-3 font-bold">Kode Alamat</label>
                  <label className="col-md-9">
                    {pickupAddrSelectedObj && pickupAddrSelectedObj.AddrType}
                  </label>
                </div>
                <div className="row">
                  <label className="col-md-3 font-bold">Alamat</label>
                  <label className="col-md-9">
                    {pickupAddrSelectedObj && pickupAddrSelectedObj.Addr}
                  </label>
                </div>
                <div className="row">
                  <label className="col-md-3 font-bold">Contact Person</label>
                  <label className="col-md-9">
                    {pickupAddrSelectedObj && pickupAddrSelectedObj.ContactPerson}
                  </label>
                </div>
                <div className="row">
                  <label className="col-md-3 font-bold">No HP Contact Person</label>
                  <label className="col-md-9">
                    {pickupAddrSelectedObj && pickupAddrSelectedObj.PhoneNo}
                  </label>
                </div>
                <div className="row">
                  <label className="col-md-3 font-bold">Kecamatan</label>
                  <label className="col-md-9">
                    {pickupAddrSelectedObj && pickupAddrSelectedObj.Kecamatan}
                  </label>
                </div> */}
                {/* <div className="row">
                  <label className="col-md-3 font-bold">Password</label>
                  <div className="col-md-9">
                    <button
                      type="button"
                      className="sm:text-sm py-1 px-2 font-medium rounded-full text-red-600 bg-white border-2 border-red-600"
                      onClick={() =>
                        toggle("WChangePassword", modalShow.WChangePassword)
                      }
                    >
                      Change Password
                    </button>
                  </div>
                  <Modal
                    size="md"
                    centered={true}
                    isOpen={modalShow.WChangePassword}
                  >
                    <ModalHeader
                      toggle={() =>
                        closeModal("WChangePassword", modalShow.WChangePassword)
                      }
                    >
                      Change Password Form
                    </ModalHeader>
                    <ModalBody>
                      <form onSubmit={handleSubmit} method="POST">
                        <div className="max-w-6xl mx-auto bg-white text-xs sm:text-sm font-medium">
                          <div className="row px-2 py-1">
                            <div className="col-md-4 text-xs sm:text-sm">
                              Old Password
                            </div>
                            <div className="col-md-8 text-xs sm:text-sm">
                              <input
                                aria-label="Old Password"
                                id="OldPassword"
                                name="OldPassword"
                                type="password"
                                value={changePassForm.OldPassword}
                                className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                onChange={handleChange}
                              />
                              {submitted && !changePassForm.OldPassword && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                  Tidak boleh kosong
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row px-2 py-1">
                            <div className="col-md-4 text-xs sm:text-sm">
                              New Password
                            </div>
                            <div className="col-md-8 text-xs sm:text-sm">
                              <input
                                aria-label="New Password"
                                id="NewPassword"
                                name="NewPassword"
                                type="password"
                                value={changePassForm.NewPassword}
                                className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                onChange={handleChange}
                              />
                              {submitted && !changePassForm.NewPassword && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                  Tidak boleh kosong
                                </div>
                              )}
                              {changePassForm.NewPassword != "" &&
                                changePassForm.OldPassword ==
                                  changePassForm.NewPassword && (
                                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                    New Password tidak boleh sama dengan Old
                                    Password
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="row px-2 py-1">
                            <div className="col-md-4 text-xs sm:text-sm">
                              Old Password
                            </div>
                            <div className="col-md-8 text-xs sm:text-sm">
                              <input
                                aria-label="Old Password"
                                id="ConfirmPassword"
                                name="ConfirmPassword"
                                type="password"
                                value={changePassForm.ConfirmPassword}
                                className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                onChange={handleChange}
                              />
                              {submitted && !changePassForm.ConfirmPassword && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                  Tidak boleh kosong
                                </div>
                              )}
                              {changePassForm.ConfirmPassword != "" &&
                                changePassForm.ConfirmPassword !=
                                  changePassForm.NewPassword && (
                                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                    Confirm Password berbeda dengan New Password
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex justify-end my-2">
                            <button
                              type="submit"
                              className="relative w-40 flex justify-center items-center mr-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </ModalBody>
                  </Modal>
                </div> */}
              </div>
            )}
            {isOpen === "2" && <ProfileCustPointHist />}
            {isOpen === "3" && <ProfileCustPointRedemption />}
            {isOpen === "4" && <QRCodeMember />}
          </div>
          <div
            className="sidew-80 flex right-0 top-0 flex-col px-2 border-l-2"
            style={{ height: "80vh" }}
          >
            <button
              className="whitespace-nowrap mb-2 text-left hover:underline"
              onClick={() => setIsOpen("1")}
            >
              My Account
            </button>
            <button
              className="whitespace-nowrap mb-2 text-left hover:underline"
              onClick={() => setIsOpen("2")}
            >
              Point History Transaction
            </button>
            <button
              className="whitespace-nowrap mb-2 text-left hover:underline"
              onClick={() => setIsOpen("3")}
            >
              Point Redemption Transaction
            </button>
            <button
              className="whitespace-nowrap mb-2 text-left hover:underline"
              onClick={() => setIsOpen("4")}
            >
              QR Code Member
            </button>
          </div>
        </div>
      </div>
      <div className="block sm:hidden text-xs">
        <div className="bg-blue-600">
          <h4 className="flex justify-center text-xl font-semibold text-white py-2">
            {isOpen === "1"
              ? "My Account"
              : isOpen === "2"
                ? "Point History Transaction"
                : isOpen === "3"
                  ? "Point Redemption Transaction"
                  : "QR Code Member"}
          </h4>
        </div>
        <div className="h-full flex pt-2">
          <div className="w-full">
            <div className="justify px-2">
              <button
                type="button"
                onClick={() => setIsOpen("1")}
                className="flex-grow group relative inline-block justify-center items-center py-2 px-4 border border-transparent text-xs leading-5 font-medium rounded-md text-white bg-indigo-600"
              >
                My Account
              </button>
              <button
                type="button"
                onClick={() => setIsOpen("2")}
                className="flex-grow group relative inline-block justify-center items-center py-2 px-4 border border-transparent text-xs leading-5 font-medium rounded-md text-white bg-indigo-600"
              >
                Point History Transaction
              </button>
              <button
                type="button"
                onClick={() => setIsOpen("3")}
                className="flex-grow group relative inline-block justify-center items-center py-2 px-4 border border-transparent text-xs leading-5 font-medium rounded-md text-white bg-indigo-600"
              >
                Point Redemption Transaction
              </button>
              <button
                type="button"
                onClick={() => setIsOpen("4")}
                className="flex-grow group relative inline-block justify-center items-center py-2 px-4 border border-transparent text-xs leading-5 font-medium rounded-md text-white bg-indigo-600"
              >
                QR Code Member
              </button>
            </div>
            <div className="pt-4">
              {isOpen === "1" && (
                <div className="px-2">
                  <div className="flex justify-between">
                    <label className="font-bold">Nama</label>
                    <label>
                      {profileData && profileData.CustName}
                    </label>
                  </div>
                  <div className="flex justify-between">
                    <label className="font-bold">
                      Tanggal Bergabung
                    </label>
                    <label>
                      {profileData &&
                        moment(profileData.JoinDt).format("DD MMMM YYYY")}
                    </label>
                  </div>
                  <div className="flex justify-between">
                    <label className="font-bold">Contact Person</label>
                    <label>
                      {profileData && (
                        <>{`${profileData.ContactPerson} - ${profileData.MobilePhoneNo}`}</>
                      )}
                    </label>
                  </div>
                  <div className="flex justify-between">
                    <label className="font-bold flex-1" >Email</label>
                    <label className=" flex-1 text-right">
                      {profileData && profileData.Email}
                    </label>
                  </div>
                  <div className="flex justify-between">
                    <label className="font-bold">Sales</label>
                    <label>
                      {profileData && profileData.SalesName}
                    </label>
                  </div>
                  <div className="flex justify-between">
                    <label className="font-bold">CRO</label>
                    <label>
                      {profileData && profileData.CROName}
                    </label>
                  </div>
                  <div className="flex justify-between">
                    <label className="font-bold">Point</label>
                    <label>
                      {profileData &&
                        profileData.PointAmt.toLocaleString("en", {
                          minimumFractionDigits: 0,
                        })}
                    </label>
                  </div>
                  {/* <div className="row">
                  <label className="col-md-3 font-bold">Password</label>
                  <div className="col-md-9">
                    <button
                      type="button"
                      className="sm:text-sm py-1 px-2 font-medium rounded-full text-red-600 bg-white border-2 border-red-600"
                      onClick={() =>
                        toggle("WChangePassword", modalShow.WChangePassword)
                      }
                    >
                      Change Password
                    </button>
                  </div>
                  <Modal
                    size="md"
                    centered={true}
                    isOpen={modalShow.WChangePassword}
                  >
                    <ModalHeader
                      toggle={() =>
                        closeModal("WChangePassword", modalShow.WChangePassword)
                      }
                    >
                      Change Password Form
                    </ModalHeader>
                    <ModalBody>
                      <form onSubmit={handleSubmit} method="POST">
                        <div className="max-w-6xl mx-auto bg-white text-xs sm:text-sm font-medium">
                          <div className="row px-2 py-1">
                            <div className="col-md-4 text-xs sm:text-sm">
                              Old Password
                            </div>
                            <div className="col-md-8 text-xs sm:text-sm">
                              <input
                                aria-label="Old Password"
                                id="OldPassword"
                                name="OldPassword"
                                type="password"
                                value={changePassForm.OldPassword}
                                className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                onChange={handleChange}
                              />
                              {submitted && !changePassForm.OldPassword && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                  Tidak boleh kosong
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row px-2 py-1">
                            <div className="col-md-4 text-xs sm:text-sm">
                              New Password
                            </div>
                            <div className="col-md-8 text-xs sm:text-sm">
                              <input
                                aria-label="New Password"
                                id="NewPassword"
                                name="NewPassword"
                                type="password"
                                value={changePassForm.NewPassword}
                                className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                onChange={handleChange}
                              />
                              {submitted && !changePassForm.NewPassword && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                  Tidak boleh kosong
                                </div>
                              )}
                              {changePassForm.NewPassword != "" &&
                                changePassForm.OldPassword ==
                                  changePassForm.NewPassword && (
                                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                    New Password tidak boleh sama dengan Old
                                    Password
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="row px-2 py-1">
                            <div className="col-md-4 text-xs sm:text-sm">
                              Old Password
                            </div>
                            <div className="col-md-8 text-xs sm:text-sm">
                              <input
                                aria-label="Old Password"
                                id="ConfirmPassword"
                                name="ConfirmPassword"
                                type="password"
                                value={changePassForm.ConfirmPassword}
                                className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                onChange={handleChange}
                              />
                              {submitted && !changePassForm.ConfirmPassword && (
                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                  Tidak boleh kosong
                                </div>
                              )}
                              {changePassForm.ConfirmPassword != "" &&
                                changePassForm.ConfirmPassword !=
                                  changePassForm.NewPassword && (
                                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                    Confirm Password berbeda dengan New Password
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex justify-end my-2">
                            <button
                              type="submit"
                              className="relative w-40 flex justify-center items-center mr-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </ModalBody>
                  </Modal>
                </div> */}
                </div>
              )}
              {isOpen === "2" && <ProfileCustPointHist />}
              {isOpen === "3" && <ProfileCustPointRedemption />}
              {isOpen === "4" && <QRCodeMember />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
