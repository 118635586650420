import React, { useState, useEffect } from "react";
import { originActions } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment'

const OriginDictionary = ({ t }) => {
    const dispatch = useDispatch();
    const origin = useSelector((state) => state.origin.origin);

    const [originList, setOriginList] = useState([]);
    const [txtOrigin, setTxtOrigin] = useState('');

    useEffect(() => {
        dispatch(originActions.getOrigin());
    }, []);

    useEffect(() => {
        if (origin != undefined && origin.Result.length > 0) {
            setOriginList(
                origin.Result.map(e => ({
                    OriginCode: e.OriginCode,
                    OriginName: e.OriginName,
                })),
            );
        }
    }, [origin]);

    function handleOriginChange(value) {
        setTxtOrigin(value)
        if (value != "") {
            var filteredList = origin.Result.map(e => ({
                OriginCode: e.OriginCode,
                OriginName: e.OriginName,
            })).filter(z => z.OriginName.toLowerCase().includes(value.toLowerCase()))
            setOriginList(filteredList);
        } else {
            setOriginList(
                origin.Result.map(e => ({
                    OriginCode: e.OriginCode,
                    OriginName: e.OriginName,
                })),
            );
        }
    }

    function download() {
        var table_elt = document.getElementById("table");
        var workbook = XLSX.utils.table_to_book(table_elt);
        XLSX.writeFile(workbook, `OriginDictionary_${moment(new Date()).format("DD-MM-YY_HH:mm:ss")}.xlsx`);
    }

    return (
        <>
            <div className="hidden sm:block max-w-6xl mx-auto">
                <h1 className="py-6 mx-3 text-xl flex font-bold justify-center">
                    Origin Dictionary
                </h1>
                <div className="row py-1 px-3 mb-2">
                    <div className="col-md-2 text-xs sm:text-sm font-bold">
                        Nama Origin
                    </div>
                    <div className="col-md-4 text-xs sm:text-sm">
                        <input
                            aria-label="Origin"
                            id="txtOrigin"
                            name="txtOrigin"
                            type="text"
                            value={txtOrigin}
                            className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                            onChange={e => handleOriginChange(e.target.value)}
                        />
                    </div>
                    <div className="col-md-3">
                        <button
                            type="submit"
                            onClick={() => download()}
                            className="relative w-40 flex justify-center items-center mr-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                        >
                            Export to Excel
                        </button>
                    </div>
                </div>

                {originList &&
                    originList &&
                    originList.length == 0 && (
                        <div className="justify-center flex flex-row">
                            <p className="text-xs sm:text-sm">Tidak ada data</p>
                        </div>
                    )}

                {originList &&
                    originList &&
                    originList.length > 0 && (
                        <div>
                            <table className="table table-striped text-xs sm:text-sm" id="table">
                                <thead>
                                    <tr>
                                        <th className="text-left">Kode Origin</th>
                                        <th className="text-left">Nama Origin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {originList.map((o, i) => (
                                        <tr key={i}>
                                            <td align="left">{o.OriginCode}</td>
                                            <td align="left">{o.OriginName}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
            </div >
            <div className="block sm:hidden max-w-6xl mx-auto">
                <div className="flex flex-row bg-blue-600">
                    <h4 className="w-full text-center text-xl font-semibold text-white py-2">
                        Origin Dictionary
                    </h4>
                </div>
                <div className="flex justify-center content-center text-xs sm:text-sm px-3 my-2">
                    <div className="h-full w-full px-2">
                        <div className="h-full w-full bg-white flex items-center">
                            <div className="h-full w-full flex items-center">
                                <div className="text-xs sm:text-sm font-medium w-full">
                                    <div className="flex flex-col py-1">
                                        <div className="text-xs sm:text-sm items-center font-bold">
                                            Nama Origin
                                        </div>
                                        <div>
                                            <input
                                                aria-label="Origin"
                                                id="txtOrigin"
                                                name="txtOrigin"
                                                type="text"
                                                value={txtOrigin}
                                                className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                                                onChange={e => handleOriginChange(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center -my-1">
                                        <button
                                            type="submit"
                                            onClick={() => download()}
                                            className="mx-3 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                                        >
                                            Export to Excel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {originList &&
                            originList &&
                            originList.length == 0 && (
                                <div className="justify-center flex flex-row">
                                    <p className="text-xs sm:text-sm">Tidak ada data</p>
                                </div>
                            )}
                        {originList &&
                            originList &&
                            originList.length > 0 &&
                            originList.map((o, i) => (
                                <>
                                    <div
                                        className="h-full w-full bg-white shadow flex rounded items-center my-2"
                                        key={i}
                                    >
                                        <div className="relative h-full w-full flex items-center py-2">
                                            <div className="text-sm font-medium w-full mr-4 pl-2">
                                                <div className="flex py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Kode Origin
                                                    </div>
                                                    <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                        {o.OriginCode}
                                                    </div>
                                                </div>
                                                <div className="flex py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Nama Origin
                                                    </div>
                                                    <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                        {o.OriginName}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                    </div>
                </div>
            </div >
        </>
    );
};
export default OriginDictionary;
