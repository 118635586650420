let apiUrl = process.env.REACT_APP_API_END_POINT;
export const urlConstants = {
    URL_LOGIN: apiUrl + "/api/PortalVerifyUser",
    URL_IMPERSONATE_LOGIN: apiUrl + "/api/PortalImpersonateLogin",
    URL_BOOKING_MONITOR: apiUrl + "/api/PortalBookingMonitoring",
    URL_BOOKING_DETAIL: apiUrl + "/api/PortalBookingDetail",
    URL_BOOKING_VIEW_H: apiUrl + "/api/PortalBookingViewH",
    URL_BOOKING_VIEW_D: apiUrl + "/api/PortalBookingViewD",
    URL_CALCULATE_BOOKING: apiUrl + "/api/PortalCalculateBooking",
    URL_SUBMIT_BOOKING: apiUrl + "/api/PortalCustBookingSubmit",
    URL_GET_DESTINATION: apiUrl + "/api/PortalDestination",
    URL_GET_PACKAGE_CONTENT: apiUrl + "/api/PortalPackageContent",
    URL_GET_DISTRICT: apiUrl + "/api/PortalGetDistrict",
    URL_GET_PICKUP_ADDR: apiUrl + "/api/PortalPickupAddressType",
    URL_PENERIMAAN_MONITORING: apiUrl + "/api/PortalPenerimaanMonitoring",
    URL_PENGIRIMAN_MONITORING: apiUrl + "/api/PortalPengirimanMonitoring",
    URL_PENGIRIMAN_DETAIL: apiUrl + "/api/PortalPengirimanDetail",
    URL_EXPORT_PENGIRIMAN_DATA: apiUrl + "/api/PortalExportPengirimanData",
    URL_PICKUP_MONITORING: apiUrl + "/api/PortalPickupMonitoring",
    URL_GET_PICKUP_ORDER_H_INFO_BY_ID:
        apiUrl + "/api/PortalGetPickupOrderHInfoById",
    URL_PICKUP_ORDER_DETAIL: apiUrl + "/api/PortalPickupOrderDetail",
    URL_CANCEL_PICKUP_ORDER: apiUrl + "/api/PortalCancelPickupOrder",
    URL_PICKUP_REQUEST_VIEW: apiUrl + "/api/PortalPickupRequestView",
    URL_GET_RESI_H_INFO_BY_ID: apiUrl + "/api/PortalGetResiHInfoById",
    URL_ADD_RESI_COMPLAINT: apiUrl + "/api/PortalAddResiComplaint",
    URL_GET_LIST_COMM_LINK: apiUrl + "/api/PortalGetListCommLink",
    URL_RESI_H_VIEW: apiUrl + "/api/PortalResiHView",
    URL_PRINT_BOOKING: apiUrl + "/api/PortalPrintBooking",
    URL_SUMMARY_DASHBOARD: apiUrl + "/api/PortalSummaryDashboard",
    URL_GET_ORIGIN: apiUrl + "/api/PortalGetOrigin",
    URL_CHECK_ONGKIR: apiUrl + "/api/PortalCheckOngkir",
    URL_GET_TRANSPORT_TYPE: apiUrl + "/api/PortalTransportType",
    URL_PICKUP_REQ_MONITORING: apiUrl + "/api/PortalPickupReqMonitoring",
    URL_PICKUP_REQ_DETAIL: apiUrl + "/api/PortalPickupReqDetail",
    URL_PICKUP_REQ_GRP_ADD_EDIT: apiUrl + "/api/PortalAddEditPickupReqGrp",
    URL_EXPORT_BOOKING_DETAIL: apiUrl + "/api/PortalExportBookingDetail",
    URL_EXPORT_PICKUP_REQ_DETAIL: apiUrl + "/api/PortalExportPickupReqDetail",
    URL_EXPORT_PENERIMAAN_DETAIL: apiUrl + "/api/PortalExportPenerimaan",
    URL_GET_PROFILE_DATA: apiUrl + "/api/PortalGetProfileData",
    URL_GET_LIST_CUST_POINT_HIST: apiUrl + "/api/PortalGetListCustPointHist",
    URL_GET_LIST_CUST_POINT_REDEMPTION: apiUrl + "/api/PortalGetListCustPointRedemption",
    URL_UPLOAD_MONITORING: apiUrl + "/api/PortalUploadMonitoring",
    URL_UPLOAD_DETAIL: apiUrl + "/api/PortalUploadDetail",
    URL_EXPORT_UPLOAD_DATA: apiUrl + "/api/PortalExportUploadDetail",
    URL_UPLOAD_BOOKING: apiUrl + "/api/PortalUploadBooking",
    URL_CHANGE_PASSWORD: apiUrl + "/api/PortalCustChangePassword",
    URL_GET_LIST_CUST_BY_HINT: apiUrl + "/api/PortalGetListCustByHint",
    URL_GET_CUST_BY_ID: apiUrl + "/api/PortalGetCustById",
    URL_GET_BANNER: apiUrl + "/api/PortalGetBanners",
    URL_PRINT_RESI_DEPAN: apiUrl + "/api/PortalPrintResiDepan",
    URL_PICKUP_REQ_GRP: apiUrl + "/api/PortalGetListPickupReqGrp",
    URL_CUST_BOOK_EDIT: apiUrl + "/api/PortalCustBookingHEdit",
    URL_CAN_KOLI_BOOKING: apiUrl + "/api/PortalCancelKoliBooking",
    URL_GET_QR_CODE: apiUrl + "/api/PortalGetQrCode",
    URL_SEND_EMAIL_QR_CODE: apiUrl + "/api/PortalSendQrCodeToEmail",
    URL_PRINT_RESI_BULKY: apiUrl + "/api/PortalPrintResiDepanBulky",
    URL_GET_IMAGES: apiUrl + "/api/PortalGetImages",
};
