import { originConstants } from "../_constants";
import { originService } from "../_services";

export const originActions = {
  getOrigin,
};

function getOrigin() {
  return (dispatch) => {
    dispatch(request());
    originService.getOrigin().then(
      (origin) => {
        dispatch(success(origin));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: originConstants.GET_ORIGIN_REQUEST };
  }
  function success(origin) {
    return { type: originConstants.GET_ORIGIN_SUCCESS, origin };
  }
  function failure(error) {
    return { type: originConstants.GET_ORIGIN_FAILURE, error };
  }
}
