import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { FaRegCopy } from "react-icons/fa";

const PengirimanDetailTable = ({ t }) => {
    const pengirimanDetail = useSelector(
        (state) => state.pengirimanDetail.pengirimanDetail
    );

    function openInNewTab(e, url) {
        e.preventDefault();
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    }

    function copyToClipboard(e, resiNo) {
        e.stopPropagation();
        navigator.clipboard.writeText(resiNo);
    }

    return (
        <div className="text-xs sm:text-sm mx-auto">
            {pengirimanDetail &&
                pengirimanDetail.Result &&
                pengirimanDetail.Result.length === 0 && (
                    <div className="justify-center flex flex-row my-1">
                        <p>Tidak ada pengiriman</p>
                    </div>
                )}
            {pengirimanDetail &&
                pengirimanDetail.Result &&
                pengirimanDetail.Result.length > 0 && (
                    <>
                        <div className="hidden sm:block">
                            <table
                                className="table table-fixed table-striped bg-white"
                                aria-labelledby="tabelLabel"
                            >
                                <thead>
                                    <tr>
                                        <th className="text-center">Tanggal</th>
                                        <th className="text-center">No Resi</th>
                                        <th>Referensi</th>
                                        <th>Penerima</th>
                                        <th>Tujuan</th>
                                        <th>Jenis Pembayaran</th>
                                        <th className="text-right">Koli</th>
                                        <th className="text-right">Berat</th>
                                        <th className="text-right">Tagihan</th>
                                        <th className="text-right">Sisa Tagihan</th>
                                        <th className="text-center">Est. Sampai</th>
                                        <th className="text-center">Tanggal Tracking</th>
                                        <th>Status</th>
                                        <th className="text-center">Komplain</th>
                                        <th className="text-center">Waktu Sampai</th>
                                        <th className="text-center">Detail</th>
                                        <th className="text-center">Print Resi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pengirimanDetail.Result.map((pd) => (
                                        <tr key={pd.resihid}>
                                            <td align="center">
                                                {moment(pd.valuedt).format("DD-MM-YY HH:mm")}
                                            </td>
                                            <td align="center" className="text-sky-600">
                                                <Link
                                                    target={"_blank"}
                                                    to={{
                                                        pathname: `/ResiHView?resiHId=${pd.resihid}`,
                                                    }}
                                                >
                                                    {pd.resino}
                                                </Link>
                                            </td>
                                            <td></td>
                                            <td>{pd.recipientname}</td>
                                            <td
                                                style={{ maxWidth: "9vw", overflowWrap: "break-word" }}
                                            >
                                                {pd.DestName}
                                            </td>
                                            <td>{pd.PaymentType}</td>
                                            <td align="right">{pd.totalitem}</td>
                                            <td align="right">
                                                {pd.finaltotalweight.toLocaleString(
                                                    "en",
                                                    {
                                                        minimumFractionDigits: 2,
                                                    }
                                                )}
                                            </td>
                                            <td align="right">
                                                {
                                                    <span className="number">
                                                        {pd.finalAccReceivableAmt.toLocaleString(
                                                            "en",
                                                            { minimumFractionDigits: 0 }
                                                        )}
                                                    </span>
                                                }
                                            </td>
                                            <td align="right">
                                                {
                                                    <span className="number">
                                                        {pd.OsAR.toLocaleString("en", {
                                                            minimumFractionDigits: 0,
                                                        })}
                                                    </span>
                                                }
                                            </td>
                                            <td align="center">
                                                {pd.estsentdt &&
                                                    moment(pd.estsentdt).format("DD-MM-YYYY")}
                                            </td>
                                            <td align="center">
                                                {pd.InputDt &&
                                                    moment(pd.InputDt).format("DD-MM-YY HH:mm")}
                                            </td>
                                            <td>{pd.tracknotes}</td>
                                            <td align="center" className="text-sky-600">
                                                <Link
                                                    target={"_blank"}
                                                    to={{
                                                        pathname: `/ResiComplaintForm?resiHId=${pd.resihid}&commLinkSrc=CUST`,
                                                    }}
                                                >
                                                    Komplain
                                                </Link>
                                            </td>
                                            <td align="center">
                                                <div
                                                    className={
                                                        pd.podRating === "GOOD"
                                                            ? "circle-green"
                                                            : pd.podRating === "STANDARD"
                                                                ? "circle-yellow"
                                                                : pd.podRating === "SLOW"
                                                                    ? "circle-red"
                                                                    : "circle-black"
                                                    }
                                                ></div>
                                            </td>
                                            <td align="center" className="text-sky-600">
                                                <Link
                                                    target={"_blank"}
                                                    to={{
                                                        pathname: `/ResiHView?resiHId=${pd.resihid}`,
                                                    }}
                                                >
                                                    Detail
                                                </Link>
                                            </td>
                                            <td align="center" className="text-sky-600">
                                                <Link target={"_blank"} to={{ pathname: `/resi-print?trxNo=${pd.resino}` }} style={{ color: '#337ab7' }}>
                                                    Print Resi
                                                </Link>
                                            </td>


                                        </tr>
                                    ))}
                                    <tr className="font-bold">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td align="right">
                                            {_.sum(
                                                _.map(pengirimanDetail.Result, (pd) => pd.totalitem)
                                            )}
                                        </td>
                                        <td align="right">
                                            {_.sum(
                                                _.map(
                                                    pengirimanDetail.Result,
                                                    (pd) => pd.finaltotalweight
                                                )
                                            ).toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                            })}
                                        </td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {_.sum(
                                                        _.map(
                                                            pengirimanDetail.Result,
                                                            (pd) => pd.finalAccReceivableAmt
                                                        )
                                                    ).toLocaleString("en", {
                                                        minimumFractionDigits: 0,
                                                    })}
                                                </span>
                                            }
                                        </td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {_.sum(
                                                        _.map(pengirimanDetail.Result, (pd) => pd.OsAR)
                                                    ).toLocaleString("en", {
                                                        minimumFractionDigits: 0,
                                                    })}
                                                </span>
                                            }
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="block sm:hidden w-full">
                            <div className="rounded text-xs px-3">
                                {pengirimanDetail.Result.map((pd) => (
                                    <div
                                        className="h-full w-full bg-white shadow flex rounded items-center my-2"
                                        key={pd.resihid}
                                        onClick={(e) =>
                                            openInNewTab(e, `/ResiHView?resiHId=${pd.resihid}`)
                                        }
                                    >
                                        <div className="relative h-full w-full flex items-center">
                                            <div className="text-xs w-full pb-1">
                                                <div className="pt-2 flex px-3">
                                                    <div className="flex flex-1">
                                                        <p className="flex items-center font-bold pr-1">{pd.resino}</p>
                                                        <div className="flex items-center">
                                                            <FaRegCopy
                                                                onClick={(e) => copyToClipboard(e, pd.resino)}
                                                                style={{ display: "inline-block" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-1 justify-end mr-1">
                                                        <div className="flex items-center">
                                                            <div
                                                                className={`w-2.5 h-2.5 rounded-full ${pd.podRating === "GOOD"
                                                                    ? "bg-green-600"
                                                                    : pd.podRating === "STANDARD"
                                                                        ? "bg-yellow-600"
                                                                        : pd.podRating === "SLOW"
                                                                            ? "bg-red-600"
                                                                            : "bg-blue-600"
                                                                    }`}
                                                            ></div>
                                                        </div>
                                                        {pd.podRating === "GOOD" ? (
                                                            <p className="flex items-center font-semibold ml-1 text-green-600">
                                                                Cepat
                                                            </p>
                                                        ) : pd.podRating === "STANDARD" ? (
                                                            <p className="flex items-center font-semibold ml-1 text-yellow-600">
                                                                Standar
                                                            </p>
                                                        ) : pd.podRating === "SLOW" ? (
                                                            <p className="flex items-center font-semibold ml-1 text-red-600">
                                                                Lambat
                                                            </p>
                                                        ) : (
                                                            <p className="flex items-center font-semibold ml-1 text-blue-600">
                                                                On Process
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex px-3">
                                                    <div className="text-red-600">{`${pd.Kecamatan} - ${pd.custname}`}</div>
                                                </div>
                                                <div className="flex px-3">
                                                    {`${pd.totalitem
                                                        } Koli, ${pd.finaltotalweight.toLocaleString(
                                                            "en",
                                                            { minimumFractionDigits: 2 }
                                                        )} Kg`}
                                                </div>
                                                <div className="flex px-3 mb-1">
                                                    {pd.transporttype}
                                                    <div className="flex flex-1 font-medium justify-end mr-1">
                                                        {`Kredit: Rp.${pd.finalAccReceivableAmt.toLocaleString(
                                                            "en",
                                                            { minimumFractionDigits: 0 }
                                                        )}`}
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="flex px-3 text-blue-600 my-1">
                                                    {`${moment(pd.podDt).format("DD-MM-YY HH:mm")} - ${pd.tracknotes
                                                        }`}
                                                </div>
                                            </div>
                                            <span
                                                className={`absolute right-0 h-full rounded-r w-3 ${pd.podRating === "GOOD"
                                                    ? "bg-green-600"
                                                    : pd.podRating === "STANDARD"
                                                        ? "bg-yellow-600"
                                                        : pd.podRating === "SLOW"
                                                            ? "bg-red-600"
                                                            : "bg-blue-600"
                                                    }`}
                                            ></span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
        </div>
    );
};

export default PengirimanDetailTable;
