import { pickupConstants } from "../_constants";
import { pickupService } from "../_services";
import { history } from '../_helpers';

export const pickupActions = {
    getPickupAddr,
    pickupMonitoring,
    getPickupOrderHInfoById,
    cancelPickupOrder,
    pickupOrderDetail,
    clearPickupReqMonitoring,
    pickupReqMonitoring,
    pickupReqDetail,
    clearPickupAddr,
    addEditPickupReqGrp,
    clearAddEditPickupReqGrp,
    getPickupAddrById,
    clearPickupAddrById,
    getPickupAddrList,
    getSelectedPickupAddr,
    clearSelectedPickupAddr
};

function getPickupAddr(pickupAddrTypeObj) {
    return (dispatch) => {
        dispatch(request(pickupAddrTypeObj));
        pickupService.getPickupAddr(pickupAddrTypeObj).then(
            (pickupAddrObj) => {
                dispatch(success(pickupAddrObj));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(pickupAddrObj) {
        return { type: pickupConstants.GET_PICKUP_ADDR_REQUEST, pickupAddrObj };
    }
    function success(pickupAddrObj) {
        return { type: pickupConstants.GET_PICKUP_ADDR_SUCCESS, pickupAddrObj };
    }
    function failure(error) {
        return { type: pickupConstants.GET_PICKUP_ADDR_FAILURE, error };
    }
}

function clearPickupAddr() {
    return (dispatch) => {
        dispatch(clear());
        if (typeof localStorage !== "undefined") {
            localStorage.removeItem("pickupAddrObj");
        }
    };

    function clear() {
        return { type: pickupConstants.GET_PICKUP_ADDR_CLEAR };
    }
}

function pickupMonitoring(pickupMonitoringObj) {
    return (dispatch) => {
        dispatch(request({ pickupMonitoringObj }));
        pickupService.pickupMonitoring(pickupMonitoringObj).then(
            (pickupMonitoring) => {
                dispatch(success(pickupMonitoring));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(pickupMonitoring) {
        return {
            type: pickupConstants.PICKUP_MONITORING_REQUEST,
            pickupMonitoring,
        };
    }
    function success(pickupMonitoring) {
        return {
            type: pickupConstants.PICKUP_MONITORING_SUCCESS,
            pickupMonitoring,
        };
    }
    function failure(error) {
        return { type: pickupConstants.PICKUP_MONITORING_FAILURE, error };
    }
}

function getPickupOrderHInfoById(pickupOrderHId) {
    return (dispatch) => {
        dispatch(request({ pickupOrderHId }));
        pickupService.getPickupOrderHInfoById(pickupOrderHId).then(
            (pickupOrderHInfo) => {
                dispatch(success(pickupOrderHInfo));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(pickupOrderHInfo) {
        return {
            type: pickupConstants.PICKUP_ORDER_H_INFO_REQUEST,
            pickupOrderHInfo,
        };
    }
    function success(pickupOrderHInfo) {
        return {
            type: pickupConstants.PICKUP_ORDER_H_INFO_SUCCESS,
            pickupOrderHInfo,
        };
    }
    function failure(error) {
        return { type: pickupConstants.PICKUP_ORDER_H_INFO_FAILURE, error };
    }
}

function cancelPickupOrder(cancelPickupOrderObj) {
    return (dispatch) => {
        dispatch(request({ cancelPickupOrderObj }));
        pickupService.cancelPickupOrder(cancelPickupOrderObj).then(
            (cancelPickupOrder) => {
                dispatch(success(cancelPickupOrder));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(cancelPickupOrder) {
        return {
            type: pickupConstants.CANCEL_PICKUP_ORDER_REQUEST,
            cancelPickupOrder,
        };
    }
    function success(cancelPickupOrder) {
        return {
            type: pickupConstants.CANCEL_PICKUP_ORDER_SUCCESS,
            cancelPickupOrder,
        };
    }
    function failure(error) {
        alert(error.StatusMessage);
        return { type: pickupConstants.CANCEL_PICKUP_ORDER_FAILURE, error };
    }
}

function pickupOrderDetail(pickupOrderHId) {
    return (dispatch) => {
        dispatch(request({ pickupOrderHId }));
        pickupService.pickupOrderDetail(pickupOrderHId).then(
            (pickupOrderDetail) => {
                dispatch(success(pickupOrderDetail));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(pickupOrderDetail) {
        return {
            type: pickupConstants.PICKUP_ORDER_DETAIL_REQUEST,
            pickupOrderDetail,
        };
    }
    function success(pickupOrderDetail) {
        return {
            type: pickupConstants.PICKUP_ORDER_DETAIL_SUCCESS,
            pickupOrderDetail,
        };
    }
    function failure(error) {
        return { type: pickupConstants.PICKUP_ORDER_DETAIL_FAILURE, error };
    }
}

function pickupReqMonitoring(pickupReqMonitoringObj) {
    return (dispatch) => {
        dispatch(request(pickupReqMonitoringObj));
        pickupService.pickupReqMonitoring(pickupReqMonitoringObj).then(
            (pickupReqMonitoringObj) => {
                dispatch(success(pickupReqMonitoringObj));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(pickupReqMonitoringObj) {
        return {
            type: pickupConstants.PICKUP_REQ_MONITORING_REQUEST,
            pickupReqMonitoringObj,
        };
    }
    function success(pickupReqMonitoringObj) {
        return {
            type: pickupConstants.PICKUP_REQ_MONITORING_SUCCESS,
            pickupReqMonitoringObj,
        };
    }
    function failure(error) {
        return { type: pickupConstants.PICKUP_REQ_MONITORING_FAILURE, error };
    }
}

function clearPickupReqMonitoring() {
    return (dispatch) => {
        dispatch(clear());
        if (typeof localStorage !== "undefined") {
            localStorage.removeItem("pickupReqMonitoringObj");
        }
    };

    function clear() {
        return { type: pickupConstants.PICKUP_REQ_MONITORING_CLEAR };
    }
}

function pickupReqDetail(pickupReqDetailObj) {
    return (dispatch) => {
        dispatch(request({ pickupReqDetailObj }));
        pickupService.pickupReqDetail(pickupReqDetailObj).then(
            (pickupReqDetail) => {
                dispatch(success(pickupReqDetail));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(pickupReqDetail) {
        return {
            type: pickupConstants.PICKUP_REQ_DETAIL_REQUEST,
            pickupReqDetail,
        };
    }
    function success(pickupReqDetail) {
        return {
            type: pickupConstants.PICKUP_REQ_DETAIL_SUCCESS,
            pickupReqDetail,
        };
    }
    function failure(error) {
        return { type: pickupConstants.PICKUP_REQ_DETAIL_FAILURE, error };
    }
}

function addEditPickupReqGrp(pickupReqGrpObj) {
    return (dispatch) => {
        dispatch(request(pickupReqGrpObj));
        pickupService.addEditPickupReqGrp(pickupReqGrpObj).then(
            (pickupReqGrpObj) => {
                dispatch(success(pickupReqGrpObj));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(pickupReqGrpObj) {
        return {
            type: pickupConstants.PICKUP_REQ_GRP_ADD_EDIT_REQUEST,
            pickupReqGrpObj,
        };
    }
    function success(pickupReqGrpObj) {
        return {
            type: pickupConstants.PICKUP_REQ_GRP_ADD_EDIT_SUCCESS,
            pickupReqGrpObj,
        };
    }
    function failure(error) {
        return { type: pickupConstants.PICKUP_REQ_GRP_ADD_EDIT_FAILURE, error };
    }
}

function clearAddEditPickupReqGrp() {
    return (dispatch) => {
        dispatch(clear());
        if (typeof localStorage !== "undefined") {
            localStorage.removeItem("pickupReqGrpObj");
        }
    };

    function clear() {
        return { type: pickupConstants.PICKUP_REQ_GRP_ADD_EDIT_CLEAR };
    }
}

function getPickupAddrById(pickupAddrByIdObj) {
    return (dispatch) => {
        dispatch(request(pickupAddrByIdObj));
        pickupService.getPickupAddrById(pickupAddrByIdObj).then(
            (pickupAddrByIdObj) => {
                dispatch(success(pickupAddrByIdObj));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(pickupAddrByIdObj) {
        return { type: pickupConstants.GET_PICKUP_ADDR_BY_ID_REQUEST, pickupAddrByIdObj };
    }
    function success(pickupAddrByIdObj) {
        return { type: pickupConstants.GET_PICKUP_ADDR_BY_ID_SUCCESS, pickupAddrByIdObj };
    }
    function failure(error) {
        return { type: pickupConstants.GET_PICKUP_ADDR_BY_ID_FAILURE, error };
    }
}

function clearPickupAddrById() {
    return (dispatch) => {
        dispatch(clear());
        if (typeof localStorage !== "undefined") {
            localStorage.removeItem("pickupAddrByIdObj");
        }
    };

    function clear() {
        return { type: pickupConstants.GET_PICKUP_ADDR_BY_ID_CLEAR };
    }
}

function getPickupAddrList(pickupAddrListObj) {
    return (dispatch) => {
        dispatch(request(pickupAddrListObj));
        pickupService.getListPickUpAddr(pickupAddrListObj).then(
            (pickupAddrListObj) => {
                dispatch(success(pickupAddrListObj));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(pickupAddrListObj) {
        return { type: pickupConstants.GET_LIST_PICKUP_ADDR_REQUEST, pickupAddrListObj };
    }
    function success(pickupAddrListObj) {
        return { type: pickupConstants.GET_LIST_PICKUP_ADDR_SUCCESS, pickupAddrListObj };
    }
    function failure(error) {
        return { type: pickupConstants.GET_LIST_PICKUP_ADDR_FAILURE, error };
    }
}

function getSelectedPickupAddr(selectedPickupAddrObj) {
    return (dispatch) => {
        localStorage.setItem("selectedPickupAddrObj", JSON.stringify(selectedPickupAddrObj));
        dispatch(success(selectedPickupAddrObj));
    };

    function success(selectedPickupAddrObj) {
        return { type: pickupConstants.SELECT_PICKUP_ADDR_SUCCESS, selectedPickupAddrObj };
    }
}

function clearSelectedPickupAddr() {
    return (dispatch) => {
        dispatch(clear());
        if (typeof localStorage !== "undefined") {
            localStorage.removeItem("selectedPickupAddrObj");
        }
    };

    function clear() {
        return { type: pickupConstants.SELECT_PICKUP_ADDR_CLEAR };
    }
}