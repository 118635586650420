import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const BookingTables = ({ sortConfig, setSortConfig, dropType, addrType }) => {
    const bookingObj = useSelector((state) => state.bookings.bookingObj);
    var totalBooking = 0;
    var totalBerat = 0;
    var totalKoli = 0;
    var totalACT = 0;
    var totalEXP = 0;
    var totalPCK = 0;
    var totalTagihTujuan = 0;
    if (bookingObj && bookingObj.Result) {
        totalBooking = bookingObj.Result.reduce(
            (n, { TotalBooking }) => n + TotalBooking,
            0
        );
        totalBerat = bookingObj.Result.reduce(
            (n, { TotalBerat }) => n + TotalBerat,
            0
        );
        totalKoli = bookingObj.Result.reduce(
            (n, { TotalKoli }) => n + TotalKoli,
            0
        );
        totalACT = bookingObj.Result.reduce(
            (n, { ACT }) => n + ACT,
            0
        );
        totalEXP = bookingObj.Result.reduce(
            (n, { EXP }) => n + EXP,
            0
        );
        totalPCK = bookingObj.Result.reduce(
            (n, { PCK }) => n + PCK,
            0
        );
    }

    function sort(key) {
        let direction = "ASC";
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === "ASC"
        ) {
            direction = "DESC";
        }
        setSortConfig({ key, direction });
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div className="text-xs sm:text-sm">
            {bookingObj && bookingObj.Result && bookingObj.Result.length == 0 && (
                <div className="justify-center flex flex-row">
                    <p>Tidak ada booking</p>
                </div>
            )}
            {bookingObj && bookingObj.Result && bookingObj.Result.length > 0 && (
                <>
                    <div className="hidden sm:block mx-auto bg-white">
                        <table
                            className="table table-striped text-xs sm:text-sm"
                            aria-labelledby="tabelLabel"
                        >
                            <thead>
                                <tr>
                                    <th className="text-center">
                                        <button
                                            type="button"
                                            onClick={() => sort("BookDt")}
                                            className="font-bold"
                                        >
                                            Date
                                        </button>
                                    </th>
                                    <th className="text-right">
                                        <button
                                            type="button"
                                            onClick={() => sort("TotalBooking")}
                                            className="font-bold"
                                        >
                                            Total Booking
                                        </button>
                                    </th>
                                    <th className="text-right">
                                        <button
                                            type="button"
                                            onClick={() => sort("TotalBerat")}
                                            className="font-bold"
                                        >
                                            Estimasi Total Berat
                                        </button>
                                    </th>
                                    <th className="text-right">
                                        <button
                                            type="button"
                                            onClick={() => sort("TotalKoli")}
                                            className="font-bold"
                                        >
                                            Total Koli
                                        </button>
                                    </th>
                                    <th className="text-right">
                                        <button
                                            type="button"
                                            onClick={() => sort("PCK")}
                                            className="font-bold"
                                        >
                                            Sudah Terpickup
                                        </button>
                                    </th>
                                    <th className="text-right">
                                        <button
                                            type="button"
                                            onClick={() => sort("ACT")}
                                            className="font-bold"
                                        >
                                            Booking Baru
                                        </button>
                                    </th>
                                    <th className="text-right">
                                        <button
                                            type="button"
                                            onClick={() => sort("EXP")}
                                            className="font-bold"
                                        >
                                            Proses Pengiriman
                                        </button>
                                    </th>
                                    <th className="text-center">Detail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookingObj && bookingObj.Result && bookingObj.Result.map((booking) => (
                                    <tr key={booking.BookDt}>
                                        <td align="center">
                                            {moment(booking.BookDt).format("DD-MM-YY")}
                                        </td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {booking.TotalBooking.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}
                                                </span>
                                            }
                                        </td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {booking.TotalBerat.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 2 }
                                                    )}
                                                </span>
                                            }
                                        </td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {booking.TotalKoli.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 2 }
                                                    )}
                                                </span>
                                            }
                                        </td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {booking.PCK.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 2 }
                                                    )}
                                                </span>
                                            }
                                        </td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {booking.ACT.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}
                                                </span>
                                            }
                                        </td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {booking.EXP.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}
                                                </span>
                                            }
                                        </td>
                                        <td align="center">
                                            <Link
                                                target={"_blank"}
                                                to={{
                                                    pathname: `/booking-details?bookingDt=${moment(
                                                        booking.BookDt
                                                    ).format("YYYY-MM-DD")}&dropType=${dropType}&addrType=${addrType}`,
                                                }}
                                                style={{ color: "#337ab7" }}
                                            >
                                                Detail
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                {bookingObj && (
                                    <tr>
                                        <td></td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {totalBooking.toLocaleString("en", {
                                                        minimumFractionDigits: 0,
                                                    })}
                                                </span>
                                            }
                                        </td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {totalBerat.toLocaleString("en", {
                                                        minimumFractionDigits: 2,
                                                    })}
                                                </span>
                                            }
                                        </td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {totalKoli.toLocaleString("en", {
                                                        minimumFractionDigits: 0,
                                                    })}
                                                </span>
                                            }
                                        </td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {totalPCK.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}
                                                </span>
                                            }
                                        </td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {totalACT.toLocaleString("en", {
                                                        minimumFractionDigits: 0,
                                                    })}
                                                </span>
                                            }
                                        </td>
                                        <td align="right">
                                            {
                                                <span className="number">
                                                    {totalEXP.toLocaleString("en", {
                                                        minimumFractionDigits: 0,
                                                    })}
                                                </span>
                                            }
                                        </td>
                                        {/* <td align="right">
                                            {
                                                <span className="number">
                                                    {totalTagihTujuan.toLocaleString("en", {
                                                        minimumFractionDigits: 0,
                                                    })}
                                                </span>
                                            }
                                        </td> */}
                                        <td></td>
                                    </tr>
                                )}
                            </tfoot>
                        </table>
                    </div>
                    <div className="block sm:hidden w-full">
                        <div className="rounded px-3">
                            {bookingObj && bookingObj.Result && bookingObj.Result.map((booking) => (
                                <div
                                    className="h-full w-full bg-white shadow flex rounded items-center my-2"
                                    key={booking.BookDt}
                                    onClick={() =>
                                        openInNewTab(
                                            `/booking-details?bookingDt=${moment(
                                                booking.BookDt
                                            ).format("YYYY-MM-DD")}&dropType=${dropType}&addrType=${addrType}`
                                        )
                                    }
                                >
                                    <div className="h-full w-full flex items-center px-3 py-3">
                                        <div className="text-xs sm:text-sm font-medium w-full">
                                            <div className="flex">
                                                <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                    <h4 className="w-full text-xl font-semibold">
                                                        {moment(booking.BookDt).format("DD MMMM YYYY")}
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="flex py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-end text-red-500">
                                                    {booking.TotalBooking.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}{" "}
                                                    Booking
                                                </div>
                                            </div>
                                            <div className="flex py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                    Estimasi Total Berat
                                                </div>
                                                <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                    {booking.TotalBerat.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}{" Kg"}
                                                </div>
                                            </div>
                                            <div className="flex py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                    Total Koli
                                                </div>
                                                <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                    {booking.TotalKoli.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3">
                                                <div className="font-bold bg-green-200 text-center text-xs sm:text-sm ">
                                                    Sudah Terpickup
                                                </div>
                                                <div className="font-bold bg-red-200 text-center text-xs sm:text-sm ">
                                                    Booking Baru
                                                </div>
                                                <div className="font-bold bg-gray-200 text-center text-xs sm:text-sm ">
                                                    Proses Pengiriman
                                                </div>
                                                <div className="text-center bg-green-200 text-xs sm:text-sm ">
                                                    {booking.PCK.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}
                                                </div>
                                                <div className="text-center bg-red-200 text-xs sm:text-sm ">
                                                    {booking.ACT.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}
                                                </div>
                                                <div className="text-center bg-gray-200 text-xs sm:text-sm ">
                                                    {booking.EXP.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}
                                                </div>
                                            </div>
                                            {/* <div className="flex py-1">
                                                <div className="flex-1 text-sm sm:text-lg flex items-end font-bold">
                                                    Total Tagihan
                                                </div>
                                            </div> */}
                                            {/* <div className="grid grid-cols-3">
                                                <div className="font-bold bg-green-200 text-center text-xs sm:text-sm ">
                                                    Lunas
                                                </div>
                                                <div className="font-bold bg-red-200 text-center text-xs sm:text-sm ">
                                                    Kredit
                                                </div>
                                                <div className="font-bold bg-gray-200 text-center text-xs sm:text-sm ">
                                                    Tagih Tujuan
                                                </div>
                                                <div className="text-center bg-green-200 text-xs sm:text-sm ">
                                                    Rp{" "}
                                                    {booking.TotalLunas.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}
                                                </div>
                                                <div className="text-center bg-red-200 text-xs sm:text-sm ">
                                                    Rp{" "}
                                                    {booking.TotalKredit.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}
                                                </div>
                                                <div className="text-center bg-gray-200 text-xs sm:text-sm ">
                                                    Rp{" "}
                                                    {booking.TotalTagihTujuan.toLocaleString(
                                                        "en",
                                                        { minimumFractionDigits: 0 }
                                                    )}
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
export default BookingTables;
