import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment";
import "../../styles/tailwind.css";
import { FaRegCopy } from "react-icons/fa";
import { ResiComplaintForm } from "../Resi";
import axios from "axios";
import { urlConstants } from "../../_constants/url.constants";

const ResiHView = ({ t }) => {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  const acc = useSelector((state) => state.authentication.accInfo);
  const [isOpen, setIsOpen] = useState("1");
  const [webModalStates, setWebModalStates] = useState({});
  const [mobileModalStates, setMobileModalStates] = useState({});
  const [resiHView, setResiHView] = useState();
  const [resiScanImg, setResiScanImg] = useState();
  const client = axios.create({
    baseURL: urlConstants.URL_RESI_H_VIEW,
  });
  const clientResiScan = axios.create({
    baseURL: urlConstants.URL_GET_IMAGES,
  });

  useEffect(() => {
    getResiHViewData({
      CustId: acc.CustId,
      ResiHId: query.get("resiHId"),
      ResiHNo: query.get("resiHNo"),
    });
  }, []);

  useEffect(() => {
    if (resiHView) {
      getResiScan({
        RefNo: resiHView.ResiNo,
        DocPathType: "RESI",
      });
    }
  }, [resiHView]);

  const getResiHViewData = async (requestObj) => {
    try {
      let result = await client.post("", requestObj);
      setResiHView(
        result.data.ObjectResult);
    } catch (error) {
      console.log(error);
    }
  };

  const getResiScan = async (requestObj) => {
    try {
      let result = await clientResiScan.post("", requestObj);
      setResiScanImg(
        result.data.ObjectResult);
    } catch (error) {
      console.log(error);
    }
  };

  function copyToClipboard(e, resiNo) {
    e.stopPropagation();
    navigator.clipboard.writeText(resiNo);
  }

  return (
    <>
      <div className="hidden sm:block">
        <div className="justify-center py-2 px-4 flex flex-row">
          <h1 className="font-bold text-xl">Resi Info Detail</h1>
        </div>
        <div className="justify-center py-3 text-xs sm:text-sm">
          {resiHView && (
            <>
              <div className="row">
                <label className="col-md-3 font-bold">No Resi</label>
                <label className="col-md-3">{resiHView.ResiNoDescr}</label>
                <label className="col-md-3 font-bold">Kantor Asal</label>
                <label className="col-md-3">{resiHView.OriginalOffice}</label>
              </div>
              <div className="row">
                <label className="col-md-3 font-bold">Pengirim</label>
                <label className="col-md-3">{resiHView.CustName}</label>
                <label className="col-md-3 font-bold">Penerima</label>
                <label className="col-md-3">{resiHView.RecipientName}</label>
              </div>
              <div className="row">
                <label className="col-md-3 font-bold">Mode Timbang</label>
                <label
                  className={
                    resiHView.ResiHSrc !== "COMPLETE" &&
                      resiHView.ResiHSrc !== ""
                      ? "col-md-1"
                      : "col-md-3"
                  }
                >
                  {resiHView.ResiHSrc}
                </label>
                {resiHView.ResiHSrc !== "COMPLETE" &&
                  resiHView.ResiHSrc !== "" && (
                    <>
                      <label className="col-md-1 font-bold">Session</label>
                      <label className="col-md-1">
                        {resiHView.SessionName}
                      </label>
                    </>
                  )}
                <label className="col-md-3 font-bold">Jenis Pembayaran</label>
                <label className="col-md-3">{resiHView.PaymentType}</label>
              </div>
              <div className="row">
                <label className="col-md-3 font-bold">Tujuan</label>
                <label className="col-md-3">{resiHView.Dest}</label>
                <label className="col-md-3 font-bold">Status Resi</label>
                <label className="col-md-3">{resiHView.ResiHStat}</label>
              </div>
              <div className="row">
                <label className="col-md-3 font-bold">Tracking Terakhir</label>
                <label className="col-md-9 whitespace-normal break-words">
                  {resiHView.PODResult}
                </label>
              </div>
              <div className="row">
                <label className="col-md-3 font-bold">Estimasi Sampai</label>
                <label className="col-md-9">{resiHView.EstDlv}</label>
              </div>
            </>
          )}
        </div>
        <div className="justify flex flex-row py-3 text-xs sm:text-sm">
          <button
            type="button"
            onClick={() => setIsOpen("1")}
            className="flex-grow group relative inline-flex justify-center items-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
          >
            Main Info
          </button>
          <button
            type="button"
            onClick={() => setIsOpen("2")}
            className="flex-grow group relative inline-flex justify-center items-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
          >
            Resi Tracking
          </button>
          <button
            type="button"
            onClick={() => setIsOpen("3")}
            className="flex-grow group relative inline-flex justify-center items-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
          >
            Payment Info
          </button>
          <button
            type="button"
            onClick={() => setIsOpen("4")}
            className="flex-grow group relative inline-flex justify-center items-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
          >
            Resi Scan
          </button>
          <button
            type="button"
            onClick={() => setIsOpen("5")}
            className="flex-grow group relative inline-flex justify-center items-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
          >
            Foto POD
          </button>
        </div>
        <div className="py-2 text-xs sm:text-sm">
          {/* main info */}
          {isOpen === "1" && resiHView && (
            <>
              <div className="lg-container rounded-md shadow border">
                <div className="mx-auto rounded-md shadow border py-2 px-3">
                  <div className="row">
                    <label className="col-md-3 font-bold">
                      ID Card Transaksi
                    </label>
                    <label className="col-md-3"></label>
                    <label className="col-md-3 font-bold">
                      Target Maks. Pengiriman
                    </label>
                    <label className="col-md-3">{resiHView.EstSentDt}</label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Alias Pengirim</label>
                    <label className="col-md-3">{resiHView.SenderAlias}</label>
                    <label className="col-md-3 font-bold">
                      No Referensi Pengirim
                    </label>
                    <label className="col-md-3">{resiHView.CustRefNo}</label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Nama Pengirim</label>
                    <label className="col-md-3">{resiHView.CustName}</label>
                    <label className="col-md-3 font-bold">No Handphone</label>
                    <label className="col-md-3">
                      {resiHView.CustMobilePhoneNo}
                    </label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Nama Penerima</label>
                    <label className="col-md-3">{resiHView.RecipientName}</label>
                    <label className="col-md-3 font-bold">
                      No Handphone Penerima
                    </label>
                    <label className="col-md-3">
                      {resiHView.RecipientMblPhoneNo}
                    </label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Alamat Penerima</label>
                    <label className="col-md-9 whitespace-normal break-words">
                      {resiHView.RecipientAddr}
                    </label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Catatan Order</label>
                    <label className="col-md-9 whitespace-normal break-words">
                      {resiHView.OrderNotes}
                    </label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Tujuan</label>
                    <label className="col-md-3">{resiHView.Dest}</label>
                    <label className="col-md-3 font-bold">Jalur</label>
                    <label className="col-md-3">{resiHView.TransportType}</label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Jenis Barang</label>
                    <label className="col-md-3">
                      {resiHView.PackageCategory}
                    </label>
                    <label className="col-md-3 font-bold">Isi Barang</label>
                    <label className="col-md-3">{resiHView.PackageContent}</label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Powerbank</label>
                    <label className="col-md-3">{resiHView.PowerbankInfo}</label>
                    <label className="col-md-3 font-bold">Insurance</label>
                    <label className="col-md-3">{resiHView.InsInfo}</label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Catatan</label>
                    <label className="col-md-3">{resiHView.ResiHNotes}</label>
                    <label className="col-md-3 font-bold">User Input</label>
                    <label className="col-md-3">{resiHView.InputBy}</label>
                  </div>
                  <div className="py-2">
                    <hr />
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Total Barang</label>
                    <label className="col-md-3">{resiHView.TotalItem}</label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Berat Asli(kg)</label>
                    <label className="col-md-3">
                      {new Intl.NumberFormat("en", {
                        minimumFractionDigits: 2,
                      }).format(resiHView.OriginalTotalWeight)}
                    </label>
                    <label className="col-md-3 font-bold">Berat Akhir(kg)</label>
                    <label className="col-md-3">
                      {new Intl.NumberFormat("en", {
                        minimumFractionDigits: 2,
                      }).format(resiHView.FinalTotalWeight)}
                    </label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Tagihan Awal</label>
                    <label className="col-md-3">
                      {resiHView.OriginTotalAmt.toLocaleString(
                        "en",
                        {
                          minimumFractionDigits: 0,
                        }
                      )}
                    </label>
                    <label className="col-md-3 font-bold">
                      Total Additional Charge
                    </label>
                    <label className="col-md-3">
                      {resiHView.TotalAddChargeAmt.toLocaleString(
                        "en",
                        {
                          minimumFractionDigits: 0,
                        }
                      )}
                    </label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Akun Diskon</label>
                    <label className="col-md-3">{resiHView.CustDiscAmt}</label>
                    <label className="col-md-3 font-bold">Akun Diskon/Kg</label>
                    <label className="col-md-3">
                      {resiHView.CustDiscAmtPerKg}
                    </label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Diskon</label>
                    <label className="col-md-3">{resiHView.DiscAmt}</label>
                    <label className="col-md-3 font-bold">Biaya Lain</label>
                    <label className="col-md-3">{resiHView.OthChargeAmt}</label>
                  </div>
                  <div className="row">
                    <label className="col-md-3 font-bold">Total Tagihan</label>
                    <label className="col-md-1">
                      {resiHView.MustBePaidAmt.toLocaleString(
                        "en",
                        {
                          minimumFractionDigits: 0,
                        }
                      )}
                    </label>
                    <label className="col-md-1 font-bold">Revisi</label>
                    <label className="col-md-1">
                      {resiHView.TotalRevAmt.toLocaleString("en", {
                        minimumFractionDigits: 0,
                      })}
                    </label>
                    <label className="col-md-3 font-bold">
                      Total Tagihan Terbayar
                    </label>
                    <label className="col-md-3">
                      {resiHView.TotalPaidAmt.toLocaleString("en", {
                        minimumFractionDigits: 0,
                      })}
                    </label>
                  </div>
                </div>
              </div>
              <div className="lg-container mx-1 rounded-lg text-xs sm:text-sm">
                <div className="mx-auto rounded-md shadow border my-2 px-3">
                  <div className="row rounded-sm flex flex-row bg-blue-300 text-white px-3 py-1">
                    <label className="font-bold pt-1">Info Barang</label>
                  </div>
                  <div className="py-2">
                    <table
                      className="table table-striped bg-white"
                      aria-labelledby="tabelLabel"
                    >
                      <thead>
                        <tr>
                          <th className="text-center">No</th>
                          <th className="text-center">No Barang</th>
                          <th className="text-right">Berat Asli</th>
                          <th className="text-right">Berat Final</th>
                          <th className="text-center">Status</th>
                          <th className="text-left">Catatan</th>
                          <th className="text-center">Waktu Input</th>
                          <th className="text-center">Update Terakhir</th>
                        </tr>
                      </thead>
                      <tbody>
                        {resiHView.ResiDs &&
                          resiHView.ResiDs.map((rd) => (
                            <tr key={rd.ResiDId}>
                              <td align="center">{rd.SeqNo}</td>
                              <td align="center">{rd.ItemCodeNo}</td>
                              <td align="right">
                                {new Intl.NumberFormat("en", {
                                  minimumFractionDigits: 2,
                                }).format(rd.OriginalWeight)}
                              </td>
                              <td align="right">
                                {new Intl.NumberFormat("en", {
                                  minimumFractionDigits: 2,
                                }).format(rd.FinalWeight)}
                              </td>
                              <td align="center">{rd.ResiDStat}</td>
                              <td align="left">{rd.ResiDNotes}</td>
                              <td align="center">
                                {moment(rd.DtmCrt).format("DD-MM-YY HH:mm")}
                              </td>
                              <td align="center">
                                {moment(rd.DtmUpd).format("DD-MM-YY HH:mm")}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* resi tracking */}
          {isOpen === "2" && resiHView && (
            <>
              <div className="my-1">
                <table
                  className="table table-striped bg-white"
                  aria-labelledby="tabelLabel"
                >
                  <thead>
                    <tr>
                      <th className="text-center">Tanggal</th>
                      <th>Catatan Tracking</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resiHView.ResiHTracks &&
                      resiHView.ResiHTracks.map((rht) => (
                        <tr key={rht.InputDt}>
                          <td align="center">
                            {moment(rht.InputDt).format("DD-MM-YY HH:mm")}
                          </td>
                          <td>{rht.TrackNotes}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <hr className="py-2" />
              <div className="my-1">
                <table
                  className="table table-striped bg-white"
                  aria-labelledby="tabelLabel"
                >
                  <thead>
                    <tr>
                      <th>Barang</th>
                      <th className="text-center">Tanggal</th>
                      <th>Kantor</th>
                      <th>Catatan Tracking</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resiHView.ResiDTracks &&
                      resiHView.ResiDTracks.map((rdt) => (
                        <tr key={rdt.ReceiveDt}>
                          <td>{rdt.ItemCodeNo}</td>
                          <td align="center">
                            {moment(rdt.ReceiveDt).format("DD-MM-YY HH:mm")}
                          </td>
                          <td>{rdt.OfficeCode}</td>
                          <td>{rdt.TrackNotes}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {/* payment info */}
          {isOpen === "3" && resiHView && (
            <>
              <div>
                <table
                  className="table table-striped bg-white"
                  aria-labelledby="tabelLabel"
                >
                  <thead>
                    <tr className="font-bold">
                      <th>Sumber</th>
                      <th>PIC Kantor</th>
                      <th>Tertagih</th>
                      <th>Status Tertagih</th>
                      <th>Status Tagihan</th>
                      <th className="text-right">Tagihan</th>
                      <th className="text-right">Jumlah Bayar</th>
                      <th>Kasir</th>
                      <th className="text-center">Tanggal Bayar</th>
                      <th className="text-center">Tanggal Input</th>
                      <th>PIC</th>
                      <th className="text-center">Tanggal Terbit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resiHView.PaymentRcvTrxs &&
                      resiHView.PaymentRcvTrxs.length > 0 &&
                      resiHView.PaymentRcvTrxs.map((prt, idx) => (
                        <tr key={idx}>
                          <td>{prt.AccReceivableSrc}</td>
                          <td>{prt.KantorPenagih}</td>
                          <td>{prt.CustName}</td>
                          <td>{prt.AccReceivableType}</td>
                          <td>{prt.AccReceivableStat}</td>
                          <td align="right">
                            {prt.FinalAccReceivableAmt.toLocaleString("en", { minimumFractionDigits: 0 })}
                          </td>
                          <td align="right">
                            {prt.RcvAmt?.toLocaleString("en", { minimumFractionDigits: 0 })}
                          </td>
                          <td>{prt.PayCashierName}</td>
                          <td align="center">
                            {prt.ValueDt != null && moment(prt.ValueDt).format("DD MMM YY HH:mm:ss")}
                          </td>
                          <td align="center">
                            {prt.PostingDt != null && moment(prt.PostingDt).format("DD MMM YY HH:mm:ss")}
                          </td>
                          <td>{prt.UsrCrt}</td>
                          <td align="center">
                            {moment(prt.DtmCrt).format("DD MMM YY HH:mm:ss")}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="lg-container mx-1 rounded-lg text-xs sm:text-sm">
                <div className="mx-auto rounded-md shadow border my-2 px-3">
                  <div className="row rounded-sm flex flex-row bg-blue-300 text-white px-3 py-1">
                    <label className="font-bold pt-1">History Pembayaran Cabang</label>
                  </div>
                  {resiHView.PaymentReconTrxHs &&
                    resiHView.PaymentReconTrxHs.length > 0 && (
                      <table
                        className="table table-striped bg-white"
                        aria-labelledby="tabelLabel"
                      >
                        <thead>
                          <tr className="font-bold">
                            <th>PIC Kantor</th>
                            <th>Status Bayar</th>
                            <th className="text-right">Tagihan</th>
                            <th className="text-right">Charge</th>
                            <th className="text-center">Tanggal Bayar</th>
                            <th>Pelapor</th>
                            <th className="text-center">Tanggal lapor</th>
                            <th>Verifikasi Oleh</th>
                            <th className="text-center">Tanggal Verifikasi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resiHView.PaymentReconTrxHs.map((prth) => (
                            <tr key={prth.ValueDt}>
                              <td>{prth.KantorPenagih}</td>
                              <td>{prth.PaymentReconStat}</td>
                              <td align="right">{prth.PaymentAmt.toLocaleString("en", { minimumFractionDigits: 0 })}</td>
                              <td align="right">{prth.ChargeAmt.toLocaleString("en", { minimumFractionDigits: 0 })}</td>
                              <td>{moment(prth.ValueDt).format("DD MMM YY HH:mm:ss")}</td>
                              <td>{prth.EmployeeName}</td>
                              <td align="center">{moment(prth.PostingDt).format("DD MMM YY HH:mm:ss")}</td>
                              <td>{prth.ReconBy}</td>
                              <td align="center">{moment(prth.ReconDt).format("DD MMM YY HH:mm:ss")}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                </div>
              </div>

              <div className="lg-container mx-1 bg-white rounded-lg text-xs sm:text-sm">
                <div className="mx-auto rounded-md shadow border my-2 px-3">
                  <div className="row rounded-sm flex flex-row bg-blue-300 text-white px-3 py-1">
                    <label className="font-bold pt-1">Tagih Tujuan Tolak</label>
                  </div>
                  {resiHView.UpdCustPayTrxs &&
                    resiHView.UpdCustPayTrxs.length > 0 && (
                      <table
                        className="table table-striped bg-white"
                        aria-labelledby="tabelLabel"
                      >
                        <thead>
                          <tr className="font-bold">
                            <th>Id</th>
                            <th className="text-center">Tanggal Tolak</th>
                            <th>User Tolak</th>
                            <th>Keterangan</th>
                            <th className="text-center">Tanggal Konfirmasi</th>
                            <th>User Konfirmasi</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resiHView.UpdCustPayTrxs.map((ucpt) => (
                            <tr key={ucpt.ApproveDt}>
                              <td>{ucpt.UpdCustPayTrxId}</td>
                              <td align="center">{moment(ucpt.UpdDt).format("DD MMM YY HH:mm:ss")}</td>
                              <td>{ucpt.UserTolak}</td>
                              <td>{ucpt.UpdNotes}</td>
                              <td align="center">{moment(ucpt.ApproveDt).format("DD MMM YY HH:mm:ss")}</td>
                              <td>{ucpt.UserKonfirmasi}</td>
                              <td>{ucpt.UpdCustPayTrxStat}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                </div>
              </div>
              <div className="lg-container mx-1 bg-white rounded-lg text-xs sm:text-sm">
                <div className="mx-auto rounded-md shadow border my-2 px-3">
                  <div className="row rounded-sm flex flex-row bg-blue-300 text-white px-3 py-1">
                    <label className="font-bold pt-1">History Update Tagihan Customer</label>
                  </div>
                  {resiHView.CustInvoiceInfos &&
                    resiHView.CustInvoiceInfos.length > 0 && (
                      <table
                        className="table table-striped bg-white"
                        aria-labelledby="tabelLabel"
                      >
                        <thead>
                          <tr className="font-bold">
                            <th className="text-center">Tanggal Update</th>
                            <th>Karyawan</th>
                            <th className="text-right">Tagihan Lama</th>
                            <th className="text-right">Tagihan Baru</th>
                            <th>Keterangan</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resiHView.CustInvoiceInfos.map((cii) => (
                            <tr key={cii.PostingDt}>
                              <td align="center">{moment(cii.PostingDt).format("DD MMM YY HH:mm:ss")}</td>
                              <td>{cii.EmployeeName}</td>
                              <td align="right">{cii.OldMustBePaidAmt.toLocaleString("en", { minimumFractionDigits: 0 })}</td>
                              <td align="right">{cii.MustBePaidAmt.toLocaleString("en", { minimumFractionDigits: 0 })}</td>
                              <td>{cii.UpdNotes}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                </div>
              </div>
              <div className="lg-container mx-1 bg-white rounded-lg text-xs sm:text-sm">
                <div className="mx-auto rounded-md shadow border my-2 px-3">
                  <div className="row rounded-sm flex flex-row bg-blue-300 text-white px-3 py-1">
                    <label className="font-bold pt-1">History Update Tagihan Cabang</label>
                  </div>

                  {resiHView.OfficeInvoiceTrxs &&
                    resiHView.OfficeInvoiceTrxs.length > 0 && (
                      <table
                        className="table table-striped bg-white"
                        aria-labelledby="tabelLabel"
                      >
                        <thead>
                          <tr className="font-bold">
                            <th>Kantor Tertagih</th>
                            <th className="text-center">Tanggal Update</th>
                            <th>Karyawan</th>
                            <th className="text-right">Tagihan Lama</th>
                            <th className="text-right">Charge Lama</th>
                            <th className="text-right">Tagihan Baru</th>
                            <th className="text-right">Charge Baru</th>
                            <th>Keterangan</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resiHView.OfficeInvoiceTrxs.map((oit) => (
                            <tr key={oit.PostingDt}>
                              <td>{oit.KantorPenagih}</td>
                              <td align="center">{moment(oit.PostingDt).format("DD MMM YY HH:mm:ss")}</td>
                              <td>{oit.EmployeeName}</td>
                              <td align="right">{oit.OldPaymentAmt.toLocaleString("en", { minimumFractionDigits: 0 })}</td>
                              <td align="right">{oit.OldChargeAmt.toLocaleString("en", { minimumFractionDigits: 0 })}</td>
                              <td align="right">{oit.PaymentAmt.toLocaleString("en", { minimumFractionDigits: 0 })}</td>
                              <td align="right">{oit.ChargeAmt.toLocaleString("en", { minimumFractionDigits: 0 })}</td>
                              <td>{oit.AdjustNotes}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                </div>
              </div>
            </>
          )}
          {/* resi scan */}
          {isOpen === "4" && resiScanImg && (
            <div className="py-3 justify-center flex flex-row">
              {resiScanImg.Images.length == 0 && <p>Resi Scan (BELUM ADA)</p>}
              {resiScanImg.Images.length > 0 && (
                <embed
                  width="100%"
                  height="500px"
                  src={`data:application/pdf;base64,${resiScanImg.Images[0].Images}`}
                />
              )}
            </div>
          )}
          {/* foto pod */}
          {isOpen === "5" && resiHView && resiHView.PODImageFiles && (
            <div className="lg-container mx-2 py-2 px-2 my-2 shadow-md bg-white rounded-lg text-xs sm:text-sm">
              {resiHView.PODImageFiles.map((pif, idx) => (
                <div key={`wView${idx}`}>
                  <div className="flex flex-row">
                    <input
                      style={{ width: "30%" }}
                      type="image"
                      onClick={() => {
                        setWebModalStates((prevStates) => ({
                          ...prevStates,
                          [idx]: !prevStates[idx]
                        }));
                      }}
                      src={pif.ImageFile}
                      alt="PODImageFile"
                    ></input>
                    <div>
                      <label className="col-md-6 font-bold">Tanggal Foto</label>
                      <label className="col-md-6">{pif.StoreDt}</label>
                      <label className="col-md-6 font-bold">
                        Diupload Oleh
                      </label>
                      <label className="col-md-6">{pif.StoreBy}</label>
                    </div>
                  </div>
                  <Modal
                    size="lg"
                    isOpen={webModalStates[idx]}
                    toggle={() => {
                      setWebModalStates((prevStates) => ({
                        ...prevStates,
                        [idx]: !prevStates[idx]
                      }));
                    }}
                    centered={true}
                  >
                    <ModalHeader toggle={() => {
                      setWebModalStates((prevStates) => ({
                        ...prevStates,
                        [idx]: !prevStates[idx]
                      }));
                    }}
                    >POD Detail</ModalHeader>
                    <ModalBody>
                      <div className="row">
                        <div className="col-md-4 flex flex-row justify-center">
                          <img
                            src={pif.ImageFile}
                          />
                        </div>
                        <div className="col-md-8">
                          <label className="text-2xl col-md-12 font-bold">
                            {pif.ResiNo}
                          </label>
                          <label className="text-sm col-md-12">
                            {pif.RecipientName}
                          </label>
                          <hr />
                          <label className="col-md-4">Tanggal Foto</label>
                          <label className="col-md-8">:{pif.StoreDt}</label>
                          <label className="col-md-4">Diupload Oleh</label>
                          <label className="col-md-8">:{pif.StoreBy}</label>
                          <hr />
                          <label className="col-md-4">Lokasi Foto</label>
                          <label className="col-md-8">
                            :{`${pif.Lattitude},${pif.Longitude}`}
                          </label>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="block sm:hidden text-xs sm:text-sm">
        <div className="bg-blue-600">
          <h4 className="flex justify-center text-xl font-semibold text-white py-2">
            Resi View
          </h4>
        </div>
        <div className="pt-2 px-2">
          <div className="flex justify-between">
            <label className="font-bold">No Resi</label>
            <label>{resiHView && resiHView.ResiNoDescr}</label>
          </div>
          <div className="flex justify-between">
            <label className="font-bold">Kantor Asal</label>
            <label>{resiHView && resiHView.OriginalOffice}</label>
          </div>
          <div className="flex justify-between">
            <label className="font-bold">Pengirim</label>
            <label>{resiHView && resiHView.CustName}</label>
          </div>
          <div className="flex justify-between">
            <label className="font-bold">Penerima</label>
            <label>{resiHView && resiHView.RecipientName}</label>
          </div>
          <div className="flex justify-between">
            <label className="font-bold">Mode Timbang</label>
            <label>{`${resiHView && resiHView.ResiHSrc} ${resiHView && resiHView.ResiHSrc !== "" && resiHView.ResiHSrc !== "COMPLETE" ? `(Session ${resiHView.SessionName})` : ``}`}</label>
          </div>
          <div className="flex justify-between">
            <label className="font-bold">Jenis Pembayaran</label>
            <label>{resiHView && resiHView.PaymentType}</label>
          </div>
          <div className="flex justify-between">
            <label className="font-bold">Tujuan</label>
            <label>{resiHView && resiHView.Dest}</label>
          </div>
          <div className="flex justify-between">
            <label className="font-bold">Status Resi</label>
            <label>{resiHView && resiHView.ResiHStat}</label>
          </div>
          <div className="flex justify-between">
            <label className="font-bold items-top">Tracking Terakhir</label>
            <label className="text-right whitespace-normal break-words">{resiHView && resiHView.PODResult}</label>
          </div>
          <div className="flex justify-between">
            <label className="font-bold">Estimasi Sampai</label>
            <label>{resiHView && resiHView.EstDlv}</label>
          </div>
        </div>
        {resiHView && (
          <>
            <div className="justify-evenly flex flex-row mx-2 text-xs overflow-x-auto">
              <button
                type="button"
                onClick={() => setIsOpen("1")}
                className="w-20 group relative inline-flex justify-center items-center py-1 px-4 border border-transparent text-xs leading-5 font-medium rounded-md text-white bg-indigo-600 active:bg-indigo-700 transition duration-150 ease-in-out"
              >
                Main Info
              </button>
              <button
                type="button"
                onClick={() => setIsOpen("2")}
                className="w-20 group relative inline-flex justify-center items-center py-1 px-4 border border-transparent text-xs leading-5 font-medium rounded-md text-white bg-indigo-600 active:bg-indigo-700 transition duration-150 ease-in-out"
              >
                Resi Tracking
              </button>
              <button
                type="button"
                onClick={() => setIsOpen("3")}
                className="w-20 group relative inline-flex justify-center items-center py-1 px-4 border border-transparent  text-xs leading-5 font-medium rounded-md text-white bg-indigo-600 active:bg-indigo-700 transition duration-150 ease-in-out"
              >
                Payment Info
              </button>
              <button
                type="button"
                onClick={() => setIsOpen("4")}
                className="w-20 group relative inline-flex justify-center items-center py-1 px-4 border border-transparent text-xs leading-5 font-medium rounded-md text-white bg-indigo-600 active:bg-indigo-700 transition duration-150 ease-in-out"
              >
                Resi Scan
              </button>
              <button
                type="button"
                onClick={() => setIsOpen("5")}
                className="w-20 group relative inline-flex justify-center items-center py-1 px-4 border border-transparent text-xs leading-5 font-medium rounded-md text-white bg-indigo-600 active:bg-indigo-700 transition duration-150 ease-in-out"
              >
                Foto POD
              </button>
              {/* <button
                type="button"
                onClick={() => setIsOpen("4")}
                className="w-20 group relative inline-flex justify-center items-center py-1 px-4 border border-transparent text-xs leading-5 font-medium rounded-md text-white bg-indigo-600 active:bg-indigo-700 transition duration-150 ease-in-out"
              >
                Komplain
              </button> */}
            </div>
            {/* Main Info */}
            {isOpen === "1" && (
              <>
                <div className="block sm:hidden w-full">
                  <div className="lg-container mx-2 bg-white rounded-lg text-xs sm:text-sm">
                    <div className="mx-auto rounded-md shadow border my-2 px-3">
                      <div className="py-2">
                        <div className="flex justify-between">
                          <label className="font-bold">ID Card Transaksi</label>
                          <label></label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Target Maks. Pengiriman</label>
                          <label>{resiHView && resiHView.EstSentDt}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Alias Pengirim</label>
                          <label>{resiHView && resiHView.SenderAlias}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">No Referensi Pengirim</label>
                          <label>{resiHView && resiHView.CustRefNo}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Nama Pengirim</label>
                          <label>{resiHView && resiHView.CustName}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">No Handphone</label>
                          <label>{resiHView && resiHView.CustMobilePhoneNo}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Nama Penerima</label>
                          <label>{resiHView && resiHView.RecipientName}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">No Handphone Penerima</label>
                          <label>{resiHView && resiHView.RecipientMblPhoneNo}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold items-top">Alamat Penerima</label>
                          <label className="text-right whitespace-normal break-words">{resiHView && resiHView.RecipientAddr}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold items-top">Catatan Order</label>
                          <label className="text-right whitespace-normal break-words">{resiHView && resiHView.OrderNotes}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Tujuan</label>
                          <label>{resiHView && resiHView.Dest}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Jalur</label>
                          <label>{resiHView && resiHView.TransportType}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Jenis Barang</label>
                          <label>{resiHView && resiHView.PackageCategory}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Isi Barang</label>
                          <label>{resiHView && resiHView.PackageContent}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Powerbank</label>
                          <label>{resiHView && resiHView.PowerbankInfo}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Insurance</label>
                          <label>{resiHView && resiHView.InsInfo}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Catatan</label>
                          <label>{resiHView && resiHView.ResiHNotes}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">User Input</label>
                          <label>{resiHView && resiHView.InputBy}</label>
                        </div>
                        <div className="py-2">
                          <hr />
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Total Barang</label>
                          <label>{resiHView && resiHView.TotalItem}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Berat Asli(kg)</label>
                          <label>{new Intl.NumberFormat("en", { minimumFractionDigits: 2, }).format(resiHView.OriginalTotalWeight)}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Berat Askhir(kg)</label>
                          <label>{new Intl.NumberFormat("en", { minimumFractionDigits: 2, }).format(resiHView.FinalTotalWeight)}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Tagihan Awal</label>
                          <label>{resiHView.OriginTotalAmt.toLocaleString("en", { minimumFractionDigits: 0, })}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Total Additional Charge</label>
                          <label>{resiHView.TotalAddChargeAmt.toLocaleString("en", { minimumFractionDigits: 0, })}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Akun Diskon</label>
                          <label>{resiHView && resiHView.CustDiscAmt}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Akun Diskon/kg</label>
                          <label>{resiHView && resiHView.CustDiscAmtPerKg}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Diskon</label>
                          <label>{resiHView && resiHView.DiscAmt}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Biaya Lain</label>
                          <label>{resiHView && resiHView.OthChargeAmt}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Total Tagihan</label>
                          <label>{resiHView.MustBePaidAmt.toLocaleString("en", { minimumFractionDigits: 0, })}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Revisi</label>
                          <label>{resiHView.TotalRevAmt.toLocaleString("en", { minimumFractionDigits: 0, })}</label>
                        </div>
                        <div className="flex justify-between">
                          <label className="font-bold">Total Tagihan Terbayar</label>
                          <label>{resiHView.TotalPaidAmt.toLocaleString("en", { minimumFractionDigits: 0, })}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg-container mx-2 bg-white rounded-lg text-xs sm:text-sm">
                    <div className="mx-auto rounded-md shadow border my-2 px-3">
                      <div className="row rounded-sm flex flex-row bg-blue-300 text-white px-3 py-1">
                        <label className="font-bold pt-1">Info Barang</label>
                      </div>
                      {resiHView.ResiDs &&
                        resiHView.ResiDs.map((rd, idx) => (
                          <div
                            className="h-full w-full bg-white shadow flex rounded items-center my-2"
                            key={rd.ResiDId}
                          >
                            <div className="relative h-full w-full flex items-center px-2 py-2">
                              <div className="w-full">
                                <div className="flex justify-between">
                                  <label className="font-bold">No Barang</label>
                                  <label>{rd.ItemCodeNo}</label>
                                </div>
                                <div className="flex justify-between">
                                  <label className="font-bold">Berat Asli</label>
                                  <label>{new Intl.NumberFormat("en", { minimumFractionDigits: 2, }).format(rd.OriginalWeight)}</label>
                                </div>
                                <div className="flex justify-between">
                                  <label className="font-bold">Berat Final</label>
                                  <label>{new Intl.NumberFormat("en", { minimumFractionDigits: 2, }).format(rd.FinalWeight)}</label>
                                </div>
                                <div className="flex justify-between">
                                  <label className="font-bold">Status</label>
                                  <label>{rd.ResiDStat}</label>
                                </div>
                                <div className="flex justify-between">
                                  <label className="font-bold">Catatan</label>
                                  <label>{rd.ResiDNotes}</label>
                                </div>
                                <div className="flex justify-between">
                                  <label className="font-bold">Waktu Input</label>
                                  <label>{moment(rd.DtmCrt).format("DD-MM-YY HH:mm")}</label>
                                </div>
                                <div className="flex justify-between">
                                  <label className="font-bold">Update Terakhir</label>
                                  <label>{moment(rd.DtmUpd).format("DD-MM-YY HH:mm")}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* Resi Tracking */}
            {isOpen === "2" && (
              <>
                <div className="block sm:hidden w-full">
                  <div className="lg-container mx-2 bg-white rounded-lg text-xs sm:text-sm">
                    <div className="mx-auto rounded-md shadow border my-2 px-3">
                      <div className="row rounded-sm flex flex-row bg-blue-300 text-white px-3 py-1">
                        <label className="font-bold pt-1">Tracking Resi</label>
                      </div>
                      {resiHView.ResiHTracks &&
                        resiHView.ResiHTracks.map((rht, idx) => (
                          <div
                            className="h-full w-full bg-white shadow flex rounded items-center my-2"
                            key={rht.InputDt}
                          >
                            <div className="relative h-full w-full flex items-center">
                              <div className="text-xs w-full pb-1">
                                <div className="pt-2 flex px-3">
                                  <div className="font-bold text-medium">
                                    {moment(rht.InputDt).format(
                                      "DD-MM-YY HH:mm"
                                    )}
                                  </div>
                                </div>
                                <hr />
                                <div className="flex px-3">
                                  {rht.TrackNotes}
                                </div>
                              </div>
                              <span
                                className={
                                  idx === 0
                                    ? "absolute bg-green-600 right-0 h-full rounded-r w-3"
                                    : "absolute bg-gray-600 right-0 h-full rounded-r w-3"
                                }
                              ></span>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="lg-container mx-2 bg-white rounded-lg text-xs sm:text-sm">
                    <div className="mx-auto rounded-md shadow border my-2 px-3">
                      <div className="row rounded-sm flex flex-row bg-blue-300 text-white px-3 py-1">
                        <label className="font-bold pt-1">Tracking Barang</label>
                      </div>
                      {resiHView.ResiDTracks &&
                        resiHView.ResiDTracks.map((rdt, i) => (
                          <div key={rdt.ReceiveDt}>
                            <div className="flex flex-row justify-evenly items-center">
                              <div className="lg-container shadow-md flex flex-col mx-2 my-2 py-2 px-2">
                                <b>
                                  {`${rdt.ItemCodeNo} `}
                                  <FaRegCopy
                                    onClick={(e) =>
                                      copyToClipboard(e, resiHView.ResiNo)
                                    }
                                    style={{ display: "inline-block" }}
                                  />
                                </b>
                                <b>
                                  {moment(rdt.ReceiveDt).format(
                                    "DD-MM-YY HH:mm"
                                  )}
                                </b>
                                <div>{rdt.TrackNotes}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* Payment Info*/}
            {isOpen === "3" && (
              <>
                {resiHView.PaymentRcvTrxs &&
                  resiHView.PaymentRcvTrxs.length > 0 &&
                  resiHView.PaymentRcvTrxs.map((prt, idx) => (
                    <div
                      className="rounded rounded-md h-full w-full bg-white mt-2 px-2"
                      key={idx}
                    >
                      <div className={`flex flex-col px-2 py-1 rounded-md border-2 ${prt.AccReceivableStat === "Batal" ? 'border-red-300' : 'border-blue-300'}`}>
                        <div className="flex flex-row w-full pt-1">
                          <div className="flex-1 text-xs flex items-center font-bold">
                            Sumber
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                            {prt.AccReceivableSrc}
                          </div>
                        </div>
                        <div className="flex flex-row w-full pt-1">
                          <div className="flex-1 text-xs flex items-center font-bold">
                            PIC Kantor
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                            {prt.KantorPenagih}
                          </div>
                        </div>
                        <div className="flex flex-row w-full pt-1">
                          <div className="flex-1 text-xs flex items-center font-bold">
                            Tertagih
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                            {prt.CustName}
                          </div>
                        </div>
                        <div className="flex flex-row w-full pt-1">
                          <div className="flex-1 text-xs flex items-center font-bold">
                            Status Tertagih
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                            {prt.AccReceivableType}
                          </div>
                        </div>
                        <div className="flex flex-row w-full pt-1">
                          <div className="flex-1 text-xs flex items-center font-bold">
                            Status Tagihan
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                            {prt.AccReceivableStat}
                          </div>
                        </div>
                        <div className="flex flex-row w-full pt-1">
                          <div className="flex-1 text-xs flex items-center font-bold">
                            Tagihan
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                            {prt.FinalAccReceivableAmt.toLocaleString("en", { minimumFractionDigits: 0 })}
                          </div>
                        </div>
                        <div className="flex flex-row w-full pt-1">
                          <div className="flex-1 text-xs flex items-center font-bold">
                            Jumlah Bayar
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                            {prt.RcvAmt?.toLocaleString("en", { minimumFractionDigits: 0 })}
                          </div>
                        </div>
                        <div className="flex flex-row w-full pt-1">
                          <div className="flex-1 text-xs flex items-center font-bold">
                            Kasir
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                            {prt.PayCashierName}
                          </div>
                        </div>
                        <div className="flex flex-row w-full pt-1">
                          <div className="flex-1 text-xs flex items-center font-bold">
                            Tanggal Bayar
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                            {prt.ValueDt != null && moment(prt.ValueDt).format("DD MMM YY HH:mm:ss")}
                          </div>
                        </div>
                        <div className="flex flex-row w-full pt-1">
                          <div className="flex-1 text-xs flex items-center font-bold">
                            Tanggal Input
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                            {prt.PostingDt != null && moment(prt.PostingDt).format("DD MMM YY HH:mm:ss")}
                          </div>
                        </div>
                        <div className="flex flex-row w-full pt-1">
                          <div className="flex-1 text-xs flex items-center font-bold">
                            PIC
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                            {prt.UsrCrt}
                          </div>
                        </div>
                        <div className="flex flex-row w-full pt-1">
                          <div className="flex-1 text-xs flex items-center font-bold">
                            Tanggal Terbit
                          </div>
                          <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                            {moment(prt.DtmCrt).format("DD MMM YY HH:mm:ss")}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                <div className="lg-container mx-1 bg-white rounded-lg text-xs sm:text-sm">
                  <div className="mx-auto rounded-md shadow border my-2 px-3">
                    <div className="row rounded-sm flex flex-row bg-blue-300 text-white px-3 py-1">
                      <label className="font-bold pt-1">History Pembayaran Cabang</label>
                    </div>
                    {resiHView.PaymentReconTrxHs &&
                      resiHView.PaymentReconTrxHs.length > 0 && resiHView.PaymentReconTrxHs.map((prth, idx) => (
                        <div
                          className="rounded rounded-md h-full w-full bg-white my-2"
                          key={idx}
                        >
                          <div className={`flex flex-col px-2 py-1 rounded-md border-2 ${prth.PaymentReconStat === "Batal" || prth.PaymentReconStat == "Tolak" ? 'border-red-300' : 'border-blue-300'}`}>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                PIC Kantor
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {prth.KantorPenagih}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Status Bayar
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {prth.PaymentReconStat}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Tagihan
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {prth.PaymentAmt.toLocaleString("en", { minimumFractionDigits: 0 })}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Charge
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {prth.ChargeAmt.toLocaleString("en", { minimumFractionDigits: 0 })}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Tanggal Bayar
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {moment(prth.ValueDt).format("DD MMM YY HH:mm:ss")}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Pelapor
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {prth.EmployeeName}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Tanggal Lapor
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {moment(prth.PostingDt).format("DD MMM YY HH:mm:ss")}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Verifikasi Oleh
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {prth.ReconBy}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Tanggal Verifikasi
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {moment(prth.ReconDt).format("DD MMM YY HH:mm:ss")}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="lg-container mx-1 bg-white rounded-lg text-xs sm:text-sm">
                  <div className="mx-auto rounded-md shadow border my-2 px-3">
                    <div className="row rounded-sm flex flex-row bg-blue-300 text-white px-3 py-1">
                      <label className="font-bold pt-1">Tagih Tujuan Tolak</label>
                    </div>
                    {resiHView.UpdCustPayTrxs &&
                      resiHView.UpdCustPayTrxs.length > 0 && resiHView.UpdCustPayTrxs.map((ucpt, idx) => (
                        <div
                          className="rounded rounded-md h-full w-full bg-white my-2"
                          key={idx}
                        >
                          <div className={`flex flex-col px-2 py-1 rounded-md border-2 border-red-300`}>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Tanggal Tolak
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {moment(ucpt.UpdDt).format("DD MMM YY HH:mm:ss")}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                User Tolak
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {ucpt.UserTolak}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Keterangan
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {ucpt.UpdNotes}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Tanggal Konfirmasi
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {moment(ucpt.ApproveDt).format("DD MMM YY HH:mm:ss")}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                User Konfirmasi
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {ucpt.UserKonfirmasi}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Status
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {ucpt.UpdCustPayTrxStat}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="lg-container mx-1 bg-white rounded-lg text-xs sm:text-sm">
                  <div className="mx-auto rounded-md shadow border my-2 px-3">
                    <div className="row rounded-sm flex flex-row bg-blue-300 text-white px-3 py-1">
                      <label className="font-bold pt-1">History Update Tagihan Customer</label>
                    </div>
                    {resiHView.CustInvoiceInfos &&
                      resiHView.CustInvoiceInfos.length > 0 && resiHView.CustInvoiceInfos.map((cii, idx) => (
                        <div
                          className="rounded rounded-md h-full w-full bg-white my-2"
                          key={idx}
                        >
                          <div className={`flex flex-col px-2 py-1 rounded-md border-2 border-green-300`}>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Tanggal Update
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {moment(cii.PostingDt).format("DD MMM YY HH:mm:ss")}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Karyawan
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {cii.EmployeeName}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Tagihan Lama
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {cii.OldMustBePaidAmt.toLocaleString("en", { minimumFractionDigits: 0 })}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Tagihan Baru
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {cii.MustBePaidAmt.toLocaleString("en", { minimumFractionDigits: 0 })}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Keterangan
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {cii.UpdNotes}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="lg-container mx-1 bg-white rounded-lg text-xs sm:text-sm">
                  <div className="mx-auto rounded-md shadow border my-2 px-3">
                    <div className="row rounded-sm flex flex-row bg-blue-300 text-white px-3 py-1">
                      <label className="font-bold pt-1">History Update Tagihan Cabang</label>
                    </div>
                    {resiHView.OfficeInvoiceTrxs &&
                      resiHView.OfficeInvoiceTrxs.length > 0 && resiHView.OfficeInvoiceTrxs.map((oit, idx) => (
                        <div
                          className="rounded rounded-md h-full w-full bg-white my-2"
                          key={idx}
                        >
                          <div className={`flex flex-col px-2 py-1 rounded-md border-2 border-green-300`}>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Kantor Tertagih
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {oit.KantorPenagih}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Tanggal Update
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {moment(oit.PostingDt).format("DD MMM YY HH:mm:ss")}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Karyawan
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {oit.EmployeeName}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Tagihan Lama
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {oit.OldPaymentAmt.toLocaleString("en", { minimumFractionDigits: 0 })}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Charge Lama
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {oit.OldChargeAmt.toLocaleString("en", { minimumFractionDigits: 0 })}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Tagihan Baru
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {oit.PaymentAmt.toLocaleString("en", { minimumFractionDigits: 0 })}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Charge Baru
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {oit.ChargeAmt.toLocaleString("en", { minimumFractionDigits: 0 })}
                              </div>
                            </div>
                            <div className="flex flex-row w-full pt-1">
                              <div className="flex-1 text-xs flex items-center font-bold">
                                Keterangan
                              </div>
                              <div className="flex-1 text-right text-xs sm:text-sm font-medium">
                                {oit.AdjustNotes}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {/* {resiHView.OfficeInvoiceTrxs &&
                          resiHView.OfficeInvoiceTrxs.length > 0 && (
                            <table
                              className="table table-striped"
                              aria-labelledby="tabelLabel"
                            >
                              <thead>
                                <tr className="font-bold">
                                  <th>Kantor Tertagih</th>
                                  <th className="text-center">Tanggal Update</th>
                                  <th>Karyawan</th>
                                  <th className="text-right">Tagihan Lama</th>
                                  <th className="text-right">Charge Lama</th>
                                  <th className="text-right">Tagihan Baru</th>
                                  <th className="text-right">Charge Baru</th>
                                  <th>Keterangan</th>
                                </tr>
                              </thead>
                              <tbody>
                                {resiHView.OfficeInvoiceTrxs.map((oit) => (
                                  <tr key={oit.PostingDt}>
                                    <td>{oit.KantorPenagih}</td>
                                    <td align="center">{moment(oit.PostingDt).format("DD MMM YY HH:mm:ss")}</td>
                                    <td>{oit.EmployeeName}</td>
                                    <td align="right">{oit.OldPaymentAmt.toLocaleString("en", { minimumFractionDigits: 0 })}</td>
                                    <td align="right">{oit.OldChargeAmt.toLocaleString("en", { minimumFractionDigits: 0 })}</td>
                                    <td align="right">{oit.PaymentAmt.toLocaleString("en", { minimumFractionDigits: 0 })}</td>
                                    <td align="right">{oit.ChargeAmt.toLocaleString("en", { minimumFractionDigits: 0 })}</td>
                                    <td>{oit.AdjustNotes}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )} */}
                  </div>
                </div>
              </>
            )}
            {/* Resi Scan */}
            {isOpen === "4" && resiScanImg && (
              <div className="pt-3 flex flex-col items-center">
                {resiScanImg.Images.length == 0 && <p>Resi Scan (BELUM ADA)</p>}
                {resiScanImg.Images.length > 0 && (
                  <>
                    {/* <embed
                        width="100%"
                        height="500px"
                        src={`data:application/pdf;base64,${resiScanImg.Images[0].Images}`}
                      /> */}
                    <p>Does not support PDFs. <a href={`data:application/pdf;base64,${resiScanImg.Images[0].Images}`} download={`ResiScan_${resiHView.ResiNo}.pdf`}>Download the PDF</a>.</p>
                  </>
                )}
              </div>
            )}
            {/* Foto POD*/}
            {isOpen === "5" && (
              <>
                <div className="flex flex-col items-center">
                  {resiHView.PODImageFiles.map((pif, idx) => (
                    <div key={`mView${idx}`}>
                      <div className="lg-container mx-2 py-2 px-2 my-2 shadow-md bg-white rounded-lg text-xs sm:text-sm">
                        <div className="pt-3 flex flex-row">
                          <input
                            style={{ width: "30%" }}
                            type="image"
                            src={pif.ImageFile}
                            alt="PODImageFile"
                          ></input>
                          <div className="flex flex-col">
                            <div>
                              <label className="col-md-6 font-bold">
                                {`Tanggal Foto: ${pif.StoreDt}`}
                              </label>
                              <label className="col-md-6">
                                {`Diupload Oleh: ${pif.StoreBy}`}
                              </label>
                            </div>
                            <div className="flex flex-col justify-center">
                              <button
                                onClick={() => {
                                  setMobileModalStates((prevStates) => ({
                                    ...prevStates,
                                    [idx]: !prevStates[idx]
                                  }));
                                }}
                                type="button"
                                className="mx-3 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                              >
                                Lihat Foto POD
                              </button>
                            </div>
                          </div>
                        </div>
                        <Modal
                          size="lg"
                          isOpen={mobileModalStates[idx]}
                          toggle={() => {
                            setMobileModalStates((prevStates) => ({
                              ...prevStates,
                              [idx]: !prevStates[idx]
                            }));
                          }}
                          centered={true}
                        >
                          <ModalHeader toggle={() => {
                            setMobileModalStates((prevStates) => ({
                              ...prevStates,
                              [idx]: !prevStates[idx]
                            }));
                          }}
                          >
                            POD Detail
                          </ModalHeader>
                          <ModalBody>
                            <img
                              src={pif.ImageFile}
                            />
                          </ModalBody>
                        </Modal>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {/* Komplain*/}
            {isOpen === "6" && <ResiComplaintForm openFrom={"ResiHView"} />}
          </>
        )}
      </div >
    </>
  );
};
export default ResiHView;
