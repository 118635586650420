import { urlConstants } from "../_constants";
import { httpHelpers } from "../_helpers";

export const resiService = {
    getResiHInfo,
    addResiComplaint,
    getComplaintMessages,
    requestPrintResiObj,
    requestPrintResiBulkyObj
};

function getResiHInfo(resiHId) {
    const getResiHInfoObj = {
        id: resiHId,
    };

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(getResiHInfoObj),
    };

    return fetch(urlConstants.URL_GET_RESI_H_INFO_BY_ID, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log("result: ", result);
            if (result) {
                var _result = {
                    resiHInfo: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem("resiHInfo", JSON.stringify(_result));
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function addResiComplaint(addResiComplaintObj) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(addResiComplaintObj),
    };

    return fetch(urlConstants.URL_ADD_RESI_COMPLAINT, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log("result: ", result);
            if (result) {
                var _result = {
                    addResiComplaint: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem("addResiComplaint", JSON.stringify(_result));
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function getComplaintMessages(getComplaintMessagesObj) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(getComplaintMessagesObj),
    };

    return fetch(urlConstants.URL_GET_LIST_COMM_LINK, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log("result: ", result);
            if (result) {
                var _result = {
                    getComplaintMessagesObj: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem(
                        "getComplaintMessagesObj",
                        JSON.stringify(_result)
                    );
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function requestPrintResiObj(resiNo, custId) {
    const printObj = { TrxNo: resiNo, CustId: custId };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(printObj)
    };

    return fetch(urlConstants.URL_PRINT_RESI_DEPAN, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    printResiObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('printResiObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function requestPrintResiBulkyObj(resiBulkyObj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(resiBulkyObj)
    };

    return fetch(urlConstants.URL_PRINT_RESI_BULKY, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    printResiBulkyObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('printResiBulkyObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}
