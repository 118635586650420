import React from 'react';

const AlertMessage = ({ isError, alert, setIsError, setAlert }) => {

    function close() {
        setIsError(false);
        setAlert({ Type: '', StatusMessage: '' })
    }

    return (
        <div>
            {
                alert &&
                <div className="text-center text-sm sm:text-md py-2 lg:px-4">
                    {
                        alert.Type == "Error" &&
                        <div className="border-2 border-red-500 p-2 items-center justify-center text-red-500 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                            <span className="flex rounded-full text-white bg-red-500 uppercase px-2 py-1 text-xs sm:text-sm font-bold">Error</span>
                            <span className="font-semibold mr-2 text-left flex-auto">{alert.StatusMessage}</span>
                            <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" data-bs-dismiss="alert" onClick={close} >
                                <title>Close</title>
                                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                            </svg>
                        </div>
                    }
                    {
                        alert.Type == "Warning" &&
                        <div className="border-2 border-yellow-500 p-2 items-center justify-center text-yellow-500 leading-none lg:rounded-full flex lg:inline-flex" role="alert" onClick={close} >
                            <span className="flex rounded-full text-white bg-yellow-500 uppercase px-2 py-1 text-xs sm:text-sm font-bold">Warning</span>
                            <span className="font-semibold mr-2 text-left flex-auto">{alert.StatusMessage}</span>
                            <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" data-bs-dismiss="alert">
                                <title>Close</title>
                                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                            </svg>
                        </div>
                    }
                    {
                        alert.Type == "Info" &&
                        <div className="border-2 border-blue-500 p-2 items-center justify-center text-blue-500 leading-none lg:rounded-full flex lg:inline-flex" role="alert" onClick={close} >
                                    <span className="flex rounded-full text-white bg-blue-500 uppercase px-2 py-1 text-xs sm:text-sm font-bold">Info</span>
                            <span className="font-semibold mr-2 text-left flex-auto">{alert.StatusMessage}</span>
                            <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" data-bs-dismiss="alert">
                                <title>Close</title>
                                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                            </svg>
                        </div>
                    }
                </div>
            }
        </div>

    );
}
export default AlertMessage;
