import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { urlConstants } from "../../_constants/url.constants";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  justifyContent: "center",
  alignItems: "center",
  p: 4,
  display: "flex",
  flexDirection: "column",
};
const PrintResiBulky = ({ t }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const dispatch = useDispatch();
  const acc = useSelector((state) => state.authentication.accInfo);
  const printResiObj = useSelector(
    (state) => state.printResiBulky.printResiBulkyObj
  );
  const [exportDataObj, setExportDataObj] = useState();
  const [exported, setExported] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState({
    Type: "",
    StatusMessage: "",
  });

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const TrxDtFrom = query.get("TrxDtFrom");
  const TrxDtTo = query.get("TrxDtTo");
  const Process = query.get("Process");
  const AddrType = query.get("AddrType");
  const PodRating = query.get("PodRating");

  const client = axios.create({
    baseURL: urlConstants.URL_PRINT_RESI_BULKY,
  });

  useEffect(() => {
    setIsError(false);
    if (TrxDtFrom && TrxDtTo && acc != undefined) {
      var requestObj = {
        CustId: acc.CustId,
        TrxDtFrom: TrxDtFrom,
        TrxDtTo: TrxDtTo,
        Process: Process,
        AddrType: AddrType,
        PodRating: PodRating,
        RowPerPage: 99999,
        PageNo: 1,
      };
      console.log(requestObj);
      exportResiDepanBulky(requestObj);
      setExported(true);
    }
  }, []);

  const exportResiDepanBulky = async (requestObj) => {
    setOpenModal(true);
    try {
      let result = await client.post("", requestObj);
      if (result.data.IsError) {
        console.log("Export Error");
        setIsError(true);
        setAlert({
          Type: "Error",
          StatusMessage: result.data.StatusMessage,
        });
      } else {
        setExportDataObj({
          exportResiDepanBulky: result.data.ObjectResult,
        });
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
      setAlert({
        Type: "Error",
        StatusMessage: "An error occure, please contact our admin",
      });
    } finally {
      setOpenModal(false);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        disableEnforceFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CircularProgress />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Downloading data. Please kindly wait
          </Typography>
        </Box>
      </Modal>
      {exportDataObj && exportDataObj.exportResiDepanBulky && (
        <>
          <embed
            src={`data:application/pdf;base64,${exportDataObj.exportResiDepanBulky}`}
            style={{ width: "100%", height: "100vh" }}
          />
        </>
      )}
    </>
  );
};
export default PrintResiBulky;
