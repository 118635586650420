import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { masterSettingConstants } from "../../_constants";
import {
    districtActions,
    bookingActions,
    packageContentActions,
    pickupActions,
    originActions,
    transportTypeActions,
    customerActions,
} from "../../_actions";
import { TableDetailBarang, AlertMessage } from "../../components";
import Input from "react-phone-number-input/input";
import { NumericFormat } from "react-number-format";
import { formatPhoneNumber } from "react-phone-number-input";
import "../../custom.css";
import { pickupAddr } from "../../_reducers/pickup.reducer";
import "react-phone-number-input/style.css";
import * as FaIcons from "react-icons/fa";
import Select, { createFilter } from "react-select";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";

const BookingAddForm = ({ t }) => {
    const dispatch = useDispatch();
    const acc = useSelector((state) => state.authentication.accInfo);
    const packageContentsObj = useSelector(
        (state) => state.packagecontent.packageContentObj
    );
    const districtObj = useSelector((state) => state.district.districtObj);
    const respCalcObj = useSelector(
        (state) => state.calculateBookingPrice.calcBookingObj
    );
    const respSubmitObj = useSelector(
        (state) => state.submitBooking.submitBookingObj
    );
    const pickupAddressTypeObj = useSelector(
        (state) => state.pickupAddr.pickupAddrObj
    );
    const errCalcObj = useSelector(
        (state) => state.calculateBookingPrice.errCalcBookingObj
    );
    const errSubmitObj = useSelector(
        (state) => state.submitBooking.errSubmitBookingObj
    );
    const originObj = useSelector((state) => state.origin.origin);
    const transportTypeObj = useSelector(
        (state) => state.transportType.transportTypeObj
    );
    const errTransportTypeObj = useSelector(
        (state) => state.transportType.errTransportTypeObj
    );
    const custObj = useSelector(
        (state) => state.custById.custObj
    );
    const listPenerimaObj = useSelector(
        (state) => state.penerimaByHint.penerimaByHintObj
    );
    const penerimaObj = useSelector(
        (state) => state.penerimaById.penerimaObj
    );

    const [isError, setIsError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [simpanDataBarang, setSimpanDataBarang] = useState(false);
    const [enableSubmitted, setEnableSubmitted] = useState(false);
    const [newPickupAddr, setNewPickupAddr] = useState(false);
    const [packageCategory, setPackageCategory] = useState("");
    const [sellerName, setSellerName] = useState(acc && acc.CustName);
    const [sellerAliasName, setSellerAliasName] = useState(acc && acc.CustName);
    const [sellerMobilePhnNo, setSellerMobilePhnNo] = useState(
        acc && acc.MobilePhoneNo
    );
    const [referenceNo, setReferenceNo] = useState("");
    const [recipientId, setRecipientId] = useState(0);
    const [recipientName, setRecipientName] = useState("");
    const [recipientMobilePhnNo, setRecipientMobilePhnNo] = useState("");
    const [recipientAddress, setRecipientAddress] = useState("");
    const [dropType, setDropType] = useState("PICKUP");
    const [paymentType, setPaymentType] = useState("");
    const [transportType, setTransportType] = useState("");
    const [estItemWeight, setEstItemWeight] = useState(0);
    const [packageContent, setPackageContent] = useState("");
    const [packageContentNotes, setPackageContentNotes] = useState("");
    const [pickupContactPerson, setPickupContactPerson] = useState("");
    const [pickupAddress, setPickupAddress] = useState("");
    const [pickupNotes, setPickupNotes] = useState("");
    const [pickupPhnNo, setPickupPhnNo] = useState("");
    const [pickupTime, setPickupTime] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [minPickupTime, setMinPickupTime] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [pickupAddrType, setPickupAddrType] = useState("Default");
    const [bookingNotes, setBookingNotes] = useState("");
    const [isInsurance, setIsInsurance] = useState("0");
    const [nilaiBarang, setNilaiBarang] = useState(0);
    const [currStep, setCurrentStep] = useState(1);
    const [arrTempBarang, setArrTempBarang] = useState([]);
    const [arrDetailBarangMbl, setArrDetailBarangMbl] = useState([]);
    const [detailBarangMbl, SetDetailBarangMbl] = useState({
        custBookDId: 0,
        itemName: "",
        itemWeight: 0,
        dimP: 0,
        dimL: 0,
        dimT: 0,
    });
    const [alert, setAlert] = useState({
        Type: "",
        StatusMessage: "",
    });
    const [modalShow, setModalShow] = useState(false);
    const [showKoliForm, setShowKoliForm] = useState(false);

    //React Select
    const [options, setOptions] = useState([]);
    const [selectedPckDist, setSelectedPckDist] = useState();
    const [originOptions, setOriginOptions] = useState([]);
    const [selectedOrigin, setSelectedOrigin] = useState();
    const [selectedDest, setSelectedDest] = useState();
    const [penerimaOptions, setPenerimaOptions] = useState([]);
    const [selectedPenerima, setSelectedPenerima] = useState(null);
    const [hideCOD, setHideCOD] = useState(true);
    const [hideCredit, setHideCredit] = useState(true);
    const [hideReceiverCredit, setHideReceiverCredit] = useState(true);
    const [isMember, setIsMember] = useState(false);
    const [refresh, setRefresh] = useState(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));

    useEffect(() => {
        if (errCalcObj && errCalcObj.StatusCode != "200") {
            setIsError(true);
            setSubmitted(false);
            setAlert({ Type: "Error", StatusMessage: errCalcObj.StatusMessage });
            setArrDetailBarangMbl((arrDetailBarangMbl) => [
                ...arrDetailBarangMbl.filter(
                    (rw) => rw.custBookDId != arrDetailBarangMbl.length
                ),
            ]);
        }
    }, [errCalcObj]);

    useEffect(() => {
        if (respCalcObj && respCalcObj.BookingD.length > 0) {
            console.log("********RESPONSE*****", respCalcObj.BookingD)
            setArrTempBarang(respCalcObj.BookingD);
            SetDetailBarangMbl({
                itemName: "",
                itemWeight: 0,
                dimP: 0,
                dimL: 0,
                dimT: 0
            });
        } else {
            setArrTempBarang([]);
        }
    }, [respCalcObj]);

    useEffect(() => {
        if (errSubmitObj && errSubmitObj.StatusCode != "200") {
            setIsError(true);
            setSubmitted(false);
            setAlert({ Type: "Error", StatusMessage: errSubmitObj.StatusMessage });
        }
    }, [errSubmitObj]);

    useEffect(() => {
        if (errTransportTypeObj && errTransportTypeObj.StatusCode != "200") {
            setIsError(true);
            setSubmitted(false);
            setAlert({
                Type: "Error",
                StatusMessage: errTransportTypeObj.StatusMessage,
            });
        }
    }, [errTransportTypeObj]);

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);

        if (arrDetailBarangMbl.length == 0) {
            setAlert({
                Type: "Error",
                StatusMessage: "Harap input setidaknya 1 detail barang",
            });
        }
        if (
            enableSubmitted &&
            sellerName &&
            sellerMobilePhnNo &&
            recipientAddress &&
            recipientMobilePhnNo &&
            recipientName &&
            paymentType &&
            packageCategory &&
            packageContent &&
            dropType &&
            transportType &&
            arrDetailBarangMbl.length > 0 &&
            selectedOrigin &&
            selectedOrigin.value != "" &&
            selectedDest &&
            selectedDest.value !== 0
        ) {
            if (
                ((packageContent ===
                    masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN ||
                    packageContent ===
                    masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN) &&
                    packageContentNotes) ||
                (packageContent !==
                    masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN &&
                    packageContent !==
                    masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN)
            ) {
                if (
                    ((dropType === masterSettingConstants.DROP_TYPE_PICKUP &&
                        pickupTime &&
                        pickupPhnNo &&
                        pickupContactPerson &&
                        selectedPckDist &&
                        selectedPckDist.value != 0 &&
                        pickupAddr) ||
                        dropType === masterSettingConstants.DROP_TYPE_DROP) &&
                    ((newPickupAddr === true && pickupAddrType) || !newPickupAddr)
                ) {
                    const bookingHObj = {
                        CustId: acc.CustId,
                        SellerName: sellerName,
                        AliasName: sellerAliasName,
                        SellerMblPhoneNo: formatPhoneNumber(sellerMobilePhnNo),
                        Username: acc.Username,
                        CustRefNo: referenceNo,
                        // RecipientId: recipientId != 0 ? recipientId : null,
                        RecipientAddr: recipientAddress,
                        RecipientMblPhoneNo: formatPhoneNumber(recipientMobilePhnNo),
                        RecipientName: recipientName,
                        PaymentType: paymentType,
                        PackageCategory: packageCategory,
                        PackageContentCode: packageContent,
                        EstTotalWeight: estItemWeight,
                        PackageContentNotes: packageContentNotes,
                        PickupNotes: pickupNotes,
                        PickupTime: pickupTime,
                        PickupPhoneNo: formatPhoneNumber(pickupPhnNo),
                        PickupContactPerson: pickupContactPerson,
                        PickupDistrictId: selectedPckDist.value,
                        PickupAddr: pickupAddress,
                        DropType: dropType,
                        TotalItem: arrDetailBarangMbl.length,
                        BookNotes: bookingNotes,
                        PickupAddrType: pickupAddrType,
                        EstItemPriceAmt: nilaiBarang,
                        DestDistrictId: selectedDest.value,
                        DestDistrictName: selectedDest.label,
                        TransportType: transportType,
                        BookDetails: arrDetailBarangMbl,
                        OriginDestCode: selectedOrigin.value,
                    };

                    const submitBooking = async () => {
                        dispatch(bookingActions.requestSubmitBooking(bookingHObj));
                    };
                    submitBooking();
                }
            }
        }
    }

    function handleNextStep(e) {
        e.preventDefault();
        setSubmitted(true);
        if (currStep == 1) {
            if (sellerName && sellerMobilePhnNo && dropType) {
                if (
                    (dropType === masterSettingConstants.DROP_TYPE_PICKUP &&
                        pickupTime &&
                        pickupPhnNo &&
                        pickupContactPerson &&
                        selectedPckDist &&
                        selectedPckDist.value != 0 &&
                        pickupAddr) &&
                    ((newPickupAddr === true && pickupAddrType) || !newPickupAddr)
                ) {
                    let pickupDistrictObj = districtObj.filter(
                        (rw) => rw.DistrictId === selectedPckDist.value
                    );
                    if (pickupDistrictObj[0].IsPickup === '1') {
                        setIsError(false);
                        setCurrentStep(currStep + 1);
                        setSubmitted(false);
                    }
                    else {
                        setIsError(true);
                        setSubmitted(false);
                        setAlert({
                            Type: "Info",
                            StatusMessage: "Maaf, Kecamatan Pickup Belum Tercover",
                        });
                    }
                }
            }
        } else if (currStep == 2) {
            if (recipientName && recipientAddress && recipientMobilePhnNo && transportType && paymentType && packageCategory && packageContent &&
                selectedOrigin && selectedOrigin.value != "" && selectedDest && selectedDest.value != 0 && (isInsurance == "0" || (isInsurance == "1" && nilaiBarang > 0))) {
                if (((packageContent === masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN ||
                    packageContent === masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN) &&
                    packageContentNotes) ||
                    (packageContent !== masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN &&
                        packageContent !== masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN)) {
                    setCurrentStep(currStep + 1);
                    setSubmitted(false);

                    let selectedPackageContent = packageContentsObj.filter(
                        (rw) => rw.PackageContentCode === packageContent
                    );
                    setIsInsurance(selectedPackageContent[0].IsInsurance);
                }
            }
        }

        if (!errSubmitObj && !errCalcObj && arrDetailBarangMbl.length > 0) {
            setAlert({ Type: "", StatusMessage: "" });
        }
    }

    function handlePrevStep(e) {
        e.preventDefault();
        setCurrentStep(currStep - 1);
    }

    function clearDatas() {
        setReferenceNo("");
        setRecipientId(0);
        setRecipientName("");
        setRecipientMobilePhnNo("");
        setRecipientAddress("");
        setDropType("PICKUP");
        setPaymentType("");
        setTransportType("");
        setEstItemWeight(0);
        setPackageContent("");
        setPackageContentNotes("");
        setPickupContactPerson("");
        setPickupAddress("");
        setPickupNotes("");
        setPickupPhnNo("");
        setPickupTime(moment(new Date()).format("YYYY-MM-DD"));
        setMinPickupTime(moment(new Date()).format("YYYY-MM-DD"));
        setBookingNotes("");
        setPackageCategory("");
        setCurrentStep(1);
        setArrTempBarang([]);
        setArrDetailBarangMbl([]);
        setIsInsurance("0");
        setSelectedOrigin({ value: "", label: "Pilih Asal" });
        setSelectedPckDist({ value: 0, label: "Pilih Kecamatan / Kabupaten" });
        setSelectedDest({ value: 0, label: "Pilih Tujuan" });
        setSelectedPenerima({ value: 0, label: "Pilih Penerima" });
        const clearData = async () => {
            dispatch(packageContentActions.clearPackageContent());
            dispatch(bookingActions.clearCalculateBooking());
            dispatch(districtActions.getDistrict());
            dispatch(bookingActions.clearSubmitBooking());
            dispatch(originActions.getOrigin());
            // dispatch(pickupActions.clearPickupAddr());
        };
        clearData();
        setSubmitted(false);
        setEnableSubmitted(false);
    }

    function onChange(event, isNumeric = true) {
        //const value = event.target.value;
        SetDetailBarangMbl((prevState) => ({
            ...prevState,
            ["custBookDId"]: arrDetailBarangMbl.length + 1,
        }));

        if (isNumeric) {
            let num = 0;
            if (event.target.value != "") {
                const str = event.target.value.replace(/,/g, "");
                num = parseFloat(str);
            }

            const { name, value } = event.target;
            SetDetailBarangMbl((prevState) => ({
                ...prevState,
                [name]: num,
            }));
        } else {
            const { name, value } = event.target;
            SetDetailBarangMbl((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    }

    function handleNilaiBarang(event) {
        if (event.target.value != "") {
            const str = event.target.value.replace(/,/g, "");
            setNilaiBarang(parseFloat(str));
        }
    }

    function handleIsInsurance() {
        if (packageContentsObj) {
            let selectedPackageContent = packageContentsObj.filter(
                (rw) => rw.PackageContentCode === packageContent
            );
            if (selectedPackageContent.length > 0) {
                setIsInsurance(selectedPackageContent[0].IsInsurance);
            }
        }
    }

    useEffect(() => {
        setModalShow(false);
        clearDatas();
        history.push("/Home");
        if (acc) {
            dispatch(customerActions.requestGetCustById(acc.CustId));

            var phone = sellerMobilePhnNo.substring(1, 20);
            var area = sellerMobilePhnNo.substring(0, 1);
            if (area === "0") {
                setSellerMobilePhnNo("+62" + phone);
            }

            const pickupAddrTypeObj = {
                CustId: acc.CustId,
                PickupReqGrpStat: "ACT",
            };
            setPickupTime(
                moment(new Date()).format("YYYY-MM-DD")
            );
            setMinPickupTime(
                moment(new Date()).format("YYYY-MM-DD")
            );
            dispatch(pickupActions.getPickupAddr(pickupAddrTypeObj));
            setSelectedPckDist({ value: 0, label: "Pilih Kecamatan / Kabupaten" });
        }
    }, []);

    useEffect(() => {
        if (custObj != null) {
            setHideCredit(!custObj.IsAllowCredit);
        }
    }, [custObj]);

    useEffect(() => {
        setPackageContent("");
        setEnableSubmitted(false);
        setIsInsurance("0");
        if (packageCategory !== null && packageCategory !== "") {
            dispatch(packageContentActions.getPackageContent(packageCategory));
        } else {
            dispatch(packageContentActions.clearPackageContent());
        }
    }, [packageCategory]);

    useEffect(() => {
        if (
            packageContent !==
            masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN &&
            packageContent !==
            masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN
        ) {
            setPackageContentNotes("");
        }
        handleIsInsurance();
    }, [packageContent]);

    useEffect(() => {
        if (respSubmitObj && respSubmitObj.ResiNo !== "") {
            setModalShow(true);
        }
    }, [respSubmitObj]);

    useEffect(() => {
        if (
            pickupAddrType !== "" &&
            pickupAddressTypeObj &&
            pickupAddressTypeObj.Result &&
            newPickupAddr === false
        ) {
            let pickupAddrTypeObj = pickupAddressTypeObj.Result.filter(
                (rw) => rw.AddrType === pickupAddrType
            );
            if (pickupAddrTypeObj.length > 0) {
                setPickupAddress(pickupAddrTypeObj[0]["Addr"]);
                setPickupContactPerson(pickupAddrTypeObj[0]["ContactPerson"]);
                var pickupPhnNo = pickupAddrTypeObj[0]["PhoneNo"];
                var phone = pickupPhnNo.substring(1, 20);
                var area = pickupPhnNo.substring(0, 1);
                if (area === "0") {
                    setPickupPhnNo("+62" + phone);
                } else {
                    setPickupPhnNo(pickupPhnNo);
                }

                if (districtObj && pickupAddrTypeObj[0]["DistrictId"] !== null && pickupAddrTypeObj[0]["DistrictId"] > 0) {
                    let pickupDistrictObj = districtObj.filter(
                        (rw) => rw.DistrictId === pickupAddrTypeObj[0]["DistrictId"]
                    );
                    if (pickupDistrictObj !== null) {
                        setSelectedPckDist({
                            value: pickupDistrictObj[0]["DistrictId"],
                            label: pickupDistrictObj[0]["DistrictDescr"],
                        });
                    }
                } else {
                    setSelectedPckDist({
                        value: 0,
                        label: "Pilih Kecamatan / Kabupaten",
                    });
                }
            }
        } else {
            setPickupAddress("");
            setPickupContactPerson("");
            setPickupPhnNo("");
            setSelectedPckDist({ value: 0, label: "Pilih Kecamatan / Kabupaten" });
        }
    }, [pickupAddrType, pickupAddressTypeObj, newPickupAddr, refresh]);

    useEffect(() => {
        if (districtObj)
            setRefresh(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    }, [districtObj])

    useEffect(() => {
        if (!errSubmitObj && !errCalcObj && arrDetailBarangMbl.length > 0) {
            setAlert({ Type: "", StatusMessage: "" });
        }
        if (
            isError == false &&
            selectedOrigin &&
            selectedDest &&
            selectedDest.value != 0 &&
            paymentType &&
            transportType &&
            packageCategory &&
            packageContent &&
            arrDetailBarangMbl.length > 0
        ) {
            const bookingHObj = {
                CustId: acc.CustId,
                DestDistrictId: selectedDest.value,
                PaymentType: paymentType,
                TransportType: transportType,
                PackageCategory: packageCategory,
                PackageContentCode: packageContent,
                BookingD: arrDetailBarangMbl,
                OriginDestCode: selectedOrigin.value,
                EstItemPriceAmt: nilaiBarang,
            };

            dispatch(bookingActions.requestCalculateBooking(bookingHObj));
            setEnableSubmitted(true);
        }

        if (arrDetailBarangMbl.length == 0) {
            setArrTempBarang([]);
        }
    }, [
        arrDetailBarangMbl,
        packageCategory,
        packageContent,
        transportType,
        selectedDest,
        paymentType,
    ]);

    useEffect(() => {
        districtObj &&
            setOptions(
                districtObj.map((dist, i) => ({
                    value: dist.DistrictId,
                    label: dist.DistrictDescr,
                }))
            );
    }, [districtObj]);

    useEffect(() => {
        originObj &&
            setOriginOptions(
                originObj.Result.map((or, i) => ({
                    value: or.OriginCode,
                    label: or.OriginName,
                }))
            );
        if (originObj != null && originObj != undefined && acc.OriginDestCode) {
            let selectedOriginObj = originObj.Result.filter(
                (rw) => rw.OriginCode === acc.OriginDestCode
            );
            setSelectedOrigin({
                value: selectedOriginObj[0]["OriginCode"],
                label: selectedOriginObj[0]["OriginName"],
            });
        }
    }, [originObj]);

    useEffect(() => {
        getTransportType();
    }, [selectedDest, selectedOrigin]);

    useEffect(() => {
        if (listPenerimaObj != null && listPenerimaObj.Result.length > 0) {
            setPenerimaOptions(
                listPenerimaObj.Result.map((penerima, i) => ({
                    value: penerima.CustId,
                    label: penerima.CustName,
                }))
            );
        }
    }, [listPenerimaObj]);

    useEffect(() => {
        if (selectedPenerima != null && selectedPenerima.value != 0) {
            dispatch(customerActions.requestGetPenerimaById(selectedPenerima.value));
            setRecipientId(selectedPenerima.value);
        }
    }, [selectedPenerima]);

    useEffect(() => {
        if (recipientName != "" && recipientName.length >= 3) {
            dispatch(customerActions.requestGetPenerimaByHint(recipientName));
        }
    }, [recipientName])

    useEffect(() => {
        if (penerimaObj != null) {
            setHideReceiverCredit(!penerimaObj.IsAllowReceiverCredit)
        }
    }, [penerimaObj])

    function getTransportType() {
        if (
            selectedOrigin &&
            selectedDest &&
            selectedOrigin.value != "" &&
            selectedDest.value != 0
        ) {
            const transportTypeObj = {
                OriginDestCode: selectedOrigin.value,
                DestDistrictId: selectedDest.value,
            };

            dispatch(transportTypeActions.getTransportType(transportTypeObj));
        } else {
            dispatch(transportTypeActions.clearTransportType());
        }
    }

    function onSubmitDetailBarang() {
        setEnableSubmitted(false);
        setSimpanDataBarang(true);
        setSubmitted(true);
        setIsError(false);
        if (arrDetailBarangMbl.length > 0) {
            setArrDetailBarangMbl((prevData) => [...prevData, detailBarangMbl]);
        } else {
            setArrDetailBarangMbl([detailBarangMbl]);
        }
        setSimpanDataBarang(false);
    }

    const handleChangeDistrictPickup = (selectedOption) => {
        setSelectedPckDist(selectedOption);
        setIsError(false);
    };

    const handleChangeOrigin = (selectedOption) => {
        setSelectedOrigin(selectedOption);
    };

    const handleChangeDest = (selectedOption) => {
        setSelectedDest(selectedOption);
        handleTagihTujuan(selectedOption.value);
    };

    const handleChangePenerima = (selectedOption) => {
        setSelectedPenerima(selectedOption);
    };

    const handleChangeMember = (event) => {
        setIsMember(event.target.checked);
        setRecipientId(0);
        setRecipientName("");
        setSelectedPenerima({ value: 0, label: "Pilih Penerima" });
        setPenerimaOptions([]);
    };

    const style = {
        control: (base, state) => ({
            ...base,
            border: "1px solid rgba(209, 213, 219, var(--tw-border-opacity))",
            boxShadow: "none",
            "&:hover": {
                border: "1px solid rgba(209, 213, 219, var(--tw-border-opacity))",
            },
        }),
    };

    const styleMobile = {
        control: (base, state) => ({
            ...base,
            border: "none",
            boxShadow: "none",
            "&:hover": {
                border: "none",
            },
        }),
    };

    const history = useHistory();

    function navigateToHome() {
        setModalShow(false);
        setSubmitted(false);
        clearDatas();
        history.push("/Home");
    }

    function navigateToCreateBooking() {
        setModalShow(false);
        setSubmitted(false);
        clearDatas();
    }

    function navigateToBookingDetail() {
        setModalShow(false);
        setSubmitted(false);
        clearDatas();
        let param = "";
        if (respSubmitObj != undefined) {
            param =
                "?bookingDt=" +
                moment(respSubmitObj.BookDt).format("YYYY-MM-DD") +
                "&noResi=" +
                respSubmitObj.ResiNo;
        }

        history.push("/booking-details" + param);
    }

    function handleTagihTujuan(districtId) {
        let _districtObj = districtObj.find(x => x.DistrictId == districtId);
        let custCOD = custObj.IsAllowCOD;
        let tujuanCOD = false;
        let isAllowRcvPayment = "1";

        if (_districtObj != null) {
            isAllowRcvPayment = _districtObj.IsAllowRcvPayment;
            tujuanCOD = isAllowRcvPayment == "1" ? true : false;
            if (custCOD && tujuanCOD) setHideCOD(false);
            else setHideCOD(true);
        } else {
            setHideCOD(!custCOD);
        }
    }

    return (
        <>
            <div className="hidden sm:block max-w-6xl mx-auto">
                <div className="flex flex-row">
                    <h4 className="w-full text-center text-xl font-semibold py-2">
                        Buat Booking
                    </h4>
                </div>
                <div className="flex items-center border-b-2 border-gray-300 px-3 py-2 text-xs sm:text-sm">
                    <div className="flex items-center h-full w-full ">
                        <div className="flex items-center">
                            <div className="rounded-full text-center transition duration-500 ease-in-out h-8 w-8 py-1 border-2 bg-red-600 border-red-600 text-white">
                                1
                            </div>
                            <div className="mx-2 text-center items-center text-xs font-medium">
                                Pengirim
                            </div>
                        </div>
                        <div
                            className={`flex-auto border-t-2 mr-2 transition duration-500 ease-in-out ${currStep > 1 ? "border-red-600" : "border-gray-500"
                                } `}
                        ></div>
                        <div className="flex items-center">
                            <div
                                className={`rounded-full text-center transition duration-500 ease-in-out h-8 w-8 py-1 border-2 border-red-600 ${currStep > 1 ? "bg-red-600 text-white" : "text-red-600"
                                    } `}
                            >
                                2
                            </div>
                            <div className="mx-2 text-center items-center text-xs font-medium">
                                Penerima
                            </div>
                        </div>
                        <div
                            className={`flex-auto border-t-2 mr-2 transition duration-500 ease-in-out ${currStep > 2 ? "border-red-600" : "border-gray-500"
                                } `}
                        ></div>
                        <div className="flex items-center">
                            <div
                                className={`rounded-full text-center transition duration-500 ease-in-out h-8 w-8 py-1 border-2 border-red-600 ${currStep > 2 ? "bg-red-600 text-white" : "text-red-600"
                                    } `}
                            >
                                3
                            </div>
                            <div className="mx-2 text-center items-center text-xs font-medium">
                                Barang
                            </div>
                        </div>
                    </div>
                </div>
                <AlertMessage
                    isError={isError}
                    alert={alert}
                    setAlert={setAlert}
                    setIsError={setIsError}
                />
                <form onSubmit={handleSubmit} method="POST">
                    {currStep === 1 ? (
                        <div className="h-full w-full bg-white text-xs sm:text-sm font-medium">

                            <div className="row px-2 py-1">
                                <div className="col-md-2 text-xs sm:text-sm font-bold">
                                    Daftar Alamat
                                </div>
                                <div className="col-md-1 text-xs sm:text-sm">
                                    {newPickupAddr === false ? (
                                        <button
                                            type="button"
                                            className="px-4 py-1 -ml-10 justify-center items-center border border-transparent font-medium rounded-full text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:border-blue-500 focus:shadow-outline-blue active:bg-blue-500 transition duration-150 ease-in-out"
                                            onClick={() => {
                                                setNewPickupAddr(true);
                                                setPickupAddrType("");
                                            }}
                                        >
                                            Tambah
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="px-4 py-1 -ml-10 justify-center items-center border border-transparent font-medium rounded-full text-white bg-yellow-500 hover:bg-yellow-400 focus:outline-none focus:border-yellow-500 focus:shadow-outline-yellow active:bg-yellow-500 transition duration-150 ease-in-out"
                                            onClick={() => {
                                                setNewPickupAddr(false);
                                                setPickupAddrType("");
                                            }}
                                        >
                                            Batal
                                        </button>
                                    )}
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    {newPickupAddr === false ? (
                                        <select
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            id="pickupAddressType"
                                            name="pickupAddressType"
                                            value={pickupAddrType}
                                            onChange={(e) => setPickupAddrType(e.target.value)}
                                        >
                                            <option value="" disabled hidden>
                                                Pilih daftar alamat
                                            </option>
                                            {pickupAddressTypeObj &&
                                                pickupAddressTypeObj.Result &&
                                                pickupAddressTypeObj.Result.map(
                                                    (pickupAddressTypeObj, i) => (
                                                        <option
                                                            key={i}
                                                            value={pickupAddressTypeObj.AddrType}
                                                        >
                                                            {pickupAddressTypeObj.AddrType}
                                                        </option>
                                                    )
                                                )}
                                        </select>
                                    ) : (
                                        <input
                                            id="pickupAddressTypeTxt"
                                            type="text"
                                            name="pickupAddressTypeTxt"
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            onChange={(e) => setPickupAddrType(e.target.value)}
                                            value={pickupAddrType}
                                            placeholder="Isi daftar alamat"
                                        />
                                    )}
                                    {submitted &&
                                        !pickupAddrType &&
                                        newPickupAddr === true && (
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                Harap isi daftar alamat
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Nomor Ponsel Pengirim
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <Input
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        country="ID"
                                        value={sellerMobilePhnNo}
                                        onChange={setSellerMobilePhnNo}
                                        placeholder="Isi nomor ponsel pengirim"
                                    />
                                    {submitted && !sellerMobilePhnNo && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap isi nomor ponsel pengirim
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Alias Pengirim
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <input
                                        id="sellerAliasName"
                                        type="text"
                                        name="sellerAliasName"
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        placeholder="Isi nama alias pengirim"
                                        value={sellerAliasName}
                                        onChange={(e) => setSellerAliasName(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Contact Person
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <input
                                        id="pickupContactPerson"
                                        type="text"
                                        name="pickupContactPerson"
                                        placeholder="Isi contact person"
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        onChange={(e) =>
                                            setPickupContactPerson(e.target.value)
                                        }
                                        value={pickupContactPerson}
                                    />
                                    {submitted && !pickupContactPerson && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap isi contact person
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Nomor Ponsel Contact Person
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <Input
                                        id="pickupPhnNo"
                                        placeholder="Isi nomor ponsel contact person "
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        country="ID"
                                        value={pickupPhnNo}
                                        onChange={setPickupPhnNo}
                                    />
                                    {submitted && !pickupPhnNo && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap isi nomor ponsel contact person
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Type
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <select
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        id="dropType"
                                        name="dropType"
                                        value={dropType}
                                        onChange={(e) => setDropType(e.target.value)}
                                    >
                                        <option value="" disabled hidden>
                                            Pilih type
                                        </option>
                                        <option value={masterSettingConstants.DROP_TYPE_PICKUP}>
                                            Pickup
                                        </option>
                                    </select>
                                    {submitted && !dropType && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap pilih type
                                        </div>
                                    )}
                                </div>
                            </div>
                            {dropType != null &&
                                dropType === masterSettingConstants.DROP_TYPE_PICKUP && (
                                    <>
                                        <div className="row px-2 py-1">
                                            <div className="col-md-3 text-xs sm:text-sm font-bold">
                                                Tanggal Pickup
                                            </div>
                                            <div className="col-md-3 text-xs sm:text-sm">
                                                <input
                                                    aria-label="Tanggal Pickup"
                                                    id="pickupTimes"
                                                    name="pickupTimes"
                                                    type="date"
                                                    value={pickupTime}
                                                    className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                                    onChange={(e) => setPickupTime(e.target.value)}
                                                    min={minPickupTime}
                                                />
                                                {submitted && !pickupTime && (
                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                        Harap isi tanggal pickup
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row px-2 py-1">
                                            <div className="col-md-3 text-xs sm:text-sm font-bold">
                                                Alamat Pickup
                                            </div>
                                            <div className="col-md-3 text-xs sm:text-sm">
                                                <textarea
                                                    id="pickupAddress"
                                                    name="pickupAddress"
                                                    rows="3"
                                                    className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                                    style={{
                                                        boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)",
                                                    }}
                                                    value={pickupAddress}
                                                    disabled={!newPickupAddr}
                                                    onChange={(e) => setPickupAddress(e.target.value)}
                                                    placeholder="Isi alamat pickup"
                                                />
                                                {submitted && !pickupAddress && (
                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                        Harap isi alamat pickup
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-3 text-xs sm:text-sm font-bold">
                                                Kecamatan / Kabupaten Pickup
                                            </div>
                                            <div className="col-md-3 text-xs sm:text-sm">
                                                <Select
                                                    filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                                                    value={selectedPckDist}
                                                    options={options}
                                                    onChange={handleChangeDistrictPickup}
                                                    className="rounded-sm text-xs sm:text-sm border-gray-300"
                                                    styles={style}
                                                    disabled={!newPickupAddr}
                                                />
                                                {submitted &&
                                                    (!selectedPckDist || selectedPckDist.value <= 0) && (
                                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                            Harap isi kecamatan / kabupaten pickup
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="row px-2 py-1">
                                            <div className="col-md-3 text-xs sm:text-sm font-bold">
                                                Catatan
                                            </div>
                                            <div className="col-md-3 text-xs sm:text-sm">
                                                <textarea
                                                    id="pickupNotes"
                                                    name="pickupNotes"
                                                    rows="3"
                                                    className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                                    style={{
                                                        boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)",
                                                    }}
                                                    value={pickupNotes}
                                                    onChange={(e) => setPickupNotes(e.target.value)}
                                                    placeholder="Isi catatan pickup"
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                        </div>
                    ) : currStep === 2 ? (
                        <div className="h-full w-full bg-white text-xs sm:text-sm font-medium">
                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Nama
                                </div>
                                {/* {isMember &&

                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <Select
                                            filterOption={createFilter({ ignoreAccents: false })}
                                            value={selectedPenerima}
                                            options={penerimaOptions}
                                            onChange={handleChangePenerima}
                                            onInputChange={setRecipientName}
                                            className="rounded-sm text-xs sm:text-sm border-gray-300 w-100"
                                            styles={style}
                                        />
                                        {submitted && (!selectedPenerima || selectedPenerima.value === 0) && (
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                Harap isi nama penerima
                                            </div>
                                        )}
                                    </div>
                                } */}
                                {!isMember &&
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <input
                                            id="recipientName"
                                            type="text"
                                            name="recipientName"
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            placeholder="Isi nama penerima"
                                            value={recipientName}
                                            onChange={(e) => setRecipientName(e.target.value)}
                                        />
                                        {submitted && !recipientName && (
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                Harap isi nama penerima
                                            </div>
                                        )}
                                    </div>
                                }
                                {/* <div className="col-md-2 flex flex-row text-xs sm:text-sm font-bold">
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={isMember}
                                            onChange={handleChangeMember}
                                        />
                                    </div>
                                    <div className="ml-2">
                                        Member
                                    </div>
                                    <div className="ml-2">
                                        <FaIcons.FaInfoCircle className="float-right mt-1" id="memberInfo" />
                                        <UncontrolledTooltip
                                            placement="top"
                                            target="memberInfo"
                                        >
                                            Hanya member yang dapat melakukan pembayaran Kredit Penerima.
                                        </UncontrolledTooltip>
                                    </div>
                                </div> */}

                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Nomor Ponsel
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <Input
                                        id="recipientMobilePhnNo"
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        country="ID"
                                        value={recipientMobilePhnNo}
                                        onChange={setRecipientMobilePhnNo}
                                        placeholder="Isi nomor ponsel penerima"
                                    />
                                    {submitted && !recipientMobilePhnNo && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap isi nomor ponsel penerima
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Alamat
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <textarea
                                        id="recipientAddress"
                                        name="recipientAddress"
                                        rows="3"
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        style={{ boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)" }}
                                        value={recipientAddress}
                                        onChange={(e) => setRecipientAddress(e.target.value)}
                                        placeholder="Isi alamat penerima"
                                    />
                                    {submitted && !recipientAddress && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap isi alamat penerima
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Nomor Referensi
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <input
                                        id="referenceNo"
                                        type="text"
                                        name="referenceNo"
                                        placeholder="Isi customer reference no"
                                        maxLength="50"
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        onChange={(e) => setReferenceNo(e.target.value)}
                                        value={referenceNo}
                                    />
                                </div>
                            </div>

                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Asal
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <Select
                                        filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                                        value={selectedOrigin}
                                        options={originOptions}
                                        onChange={handleChangeOrigin}
                                        className="rounded-sm text-xs sm:text-sm border-gray-300 w-100"
                                        styles={style}
                                    />
                                    {submitted &&
                                        (!selectedOrigin || selectedOrigin.value === "") && (
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                Harap isi asal pengiriman
                                            </div>
                                        )}
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Tujuan
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <Select
                                        filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                                        value={selectedDest}
                                        options={options}
                                        onChange={handleChangeDest}
                                        className="rounded-sm text-xs sm:text-sm border-gray-300 w-100"
                                        styles={style}
                                    />
                                    {submitted && (!selectedDest || selectedDest.value === 0) && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap isi tujuan
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Jalur
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <select
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        id="transportType"
                                        name="transportType"
                                        value={transportType}
                                        onChange={(e) => setTransportType(e.target.value)}
                                    >
                                        <option value="" disabled hidden>
                                            Pilih jalur
                                        </option>
                                        {transportTypeObj &&
                                            transportTypeObj.TransportTypes.map(
                                                (transportTypeObj) => (
                                                    <option
                                                        key={transportTypeObj.TransportTypeCode}
                                                        value={transportTypeObj.TransportTypeCode}
                                                    >
                                                        {transportTypeObj.TransportTypeName}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                    {submitted && !transportType && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap pilih metode pengiriman
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Pembayaran
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <select
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        id="paymentType"
                                        name="paymentType"
                                        value={paymentType}
                                        onChange={(e) => setPaymentType(e.target.value)}
                                    >
                                        <option value="" disabled hidden>
                                            Pilih metode pembayaran
                                        </option>
                                        <option value={masterSettingConstants.PAYTYPE_LUNAS}>
                                            Lunas
                                        </option>
                                        <option value={masterSettingConstants.PAYTYPE_KREDIT} hidden={hideCredit}>
                                            Kredit
                                        </option>
                                        <option value={masterSettingConstants.PAYTYPE_TAGIHTUJUAN} hidden={hideCOD}>
                                            Tagih Tujuan
                                        </option>
                                        <option value={masterSettingConstants.PAYTYPE_KREDITPENERIMA} hidden={hideReceiverCredit}>
                                            Kredit Penerima
                                        </option>
                                    </select>

                                    {submitted && !paymentType && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap pilih metode pembayaran
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Jenis Barang
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <select
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        id="packageCategory"
                                        name="packageCategory"
                                        value={packageCategory}
                                        onChange={(e) => setPackageCategory(e.target.value)}
                                    >
                                        <option value="" disabled hidden>
                                            Pilih jenis barang
                                        </option>
                                        <option
                                            value={masterSettingConstants.PACKAGE_CATEGORY_ELEKTRONIK}
                                        >
                                            ELEKTRONIK
                                        </option>
                                        <option
                                            value={
                                                masterSettingConstants.PACKAGE_CATEGORY_NON_ELEKTRONIK
                                            }
                                        >
                                            NON ELEKTRONIK
                                        </option>
                                    </select>
                                    {(submitted || simpanDataBarang) && !packageCategory && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap pilih jenis barang
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Tipe Barang
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <select
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        id="packageContent"
                                        name="packageContent"
                                        value={packageContent}
                                        onChange={(e) => setPackageContent(e.target.value)}
                                    >
                                        <option value="" disabled hidden>
                                            Pilih tipe barang
                                        </option>
                                        {packageContentsObj &&
                                            packageContentsObj.map((packageContentsObj) => (
                                                <option
                                                    key={packageContentsObj.PackageContentCode}
                                                    value={packageContentsObj.PackageContentCode}
                                                >
                                                    {packageContentsObj.PackageContentName}
                                                </option>
                                            ))}
                                    </select>
                                    {(submitted || simpanDataBarang) && !packageContent && (
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap pilih tipe barang
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm font-bold">
                                    Catatan Order
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <textarea
                                        id="bookNotes"
                                        name="bookNotes"
                                        rows="3"
                                        className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                        style={{ boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)" }}
                                        value={bookingNotes}
                                        onChange={(e) => setBookingNotes(e.target.value)}
                                        placeholder="Isi catatan order"
                                    />
                                </div>

                                {isInsurance && isInsurance === '1' &&
                                    <>
                                        <div className="col-md-3 text-xs sm:text-sm font-bold">
                                            Nilai Barang
                                        </div>
                                        <div className="col-md-3 text-xs sm:text-sm">
                                            <NumericFormat
                                                id="estItemPriceAmt"
                                                name="estItemPriceAmt"
                                                thousandSeparator={true}
                                                decimalScale={0}
                                                fixedDecimalScale={true}
                                                className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                                onChange={(e) => handleNilaiBarang(e)}
                                                value={nilaiBarang}
                                                placeholder="Isi Nilai barang"
                                            />
                                            {(submitted || simpanDataBarang) && nilaiBarang <= 0 && isInsurance == "1" && (
                                                <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                    Harap isi nilai barang
                                                </div>
                                            )}
                                        </div>
                                    </>
                                }
                                {(packageContent ===
                                    masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN ||
                                    packageContent ===
                                    masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN) && (
                                        <>
                                            <div className="col-md-3 text-xs sm:text-sm font-bold">
                                                Catatan Paket
                                            </div>
                                            <div className="col-md-3 text-xs sm:text-sm">
                                                <textarea
                                                    id="packageContentNotes"
                                                    name="packageContentNotes"
                                                    rows="3"
                                                    className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                                    style={{
                                                        boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)",
                                                    }}
                                                    value={packageContentNotes}
                                                    onChange={(e) => setPackageContentNotes(e.target.value)}
                                                    placeholder="Isi catatan paket"
                                                />
                                                {submitted && !packageContentNotes && (
                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                        Harap isi catatan paket
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                            </div>
                        </div>
                    ) : (
                        <div className="h-full w-full bg-white text-xs sm:text-sm font-medium">
                            {showKoliForm && (<>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-sm sm:text-md font-bold">
                                        Detail Koli
                                    </div>
                                </div>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-xs sm:text-sm font-bold">
                                        Nama Barang
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <input
                                            id="itemName"
                                            name="itemName"
                                            placeholder="Isi nama barang"
                                            type="text"
                                            className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            onChange={(e) => onChange(e, false)}
                                            value={detailBarangMbl.itemName}
                                        />
                                    </div>
                                </div>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-xs sm:text-sm font-bold">
                                        Estimasi Berat Barang (kg)
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <NumericFormat
                                            id="itemWeight"
                                            name="itemWeight"
                                            thousandSeparator={true}
                                            allowLeadingZeros={false}
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            onChange={(e) => onChange(e, true)}
                                            value={detailBarangMbl.itemWeight}
                                            placeholder="Isi estimasi berat barang"
                                        />
                                    </div>
                                </div>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-xs sm:text-sm font-bold">
                                        Panjang (cm)
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <NumericFormat
                                            id="dimP"
                                            name="dimP"
                                            thousandSeparator={true}
                                            allowLeadingZeros={false}
                                            decimalScale={0}
                                            fixedDecimalScale={true}
                                            className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            onChange={(e) => onChange(e, true)}
                                            value={detailBarangMbl.dimP}
                                            placeholder="Isi dimensi panjang barang"
                                        />
                                    </div>
                                </div>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-xs sm:text-sm font-bold">
                                        Lebar (cm)
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <NumericFormat
                                            id="dimL"
                                            name="dimL"
                                            thousandSeparator={true}
                                            allowLeadingZeros={false}
                                            decimalScale={0}
                                            fixedDecimalScale={true}
                                            className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            onChange={(e) => onChange(e, true)}
                                            value={detailBarangMbl.dimL}
                                            placeholder="Isi dimensi lebar barang"
                                        />
                                    </div>
                                </div>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3 text-xs sm:text-sm font-bold">
                                        Tinggi (cm)
                                    </div>
                                    <div className="col-md-3 text-xs sm:text-sm">
                                        <NumericFormat
                                            id="dimT"
                                            name="dimT"
                                            thousandSeparator={true}
                                            allowLeadingZeros={false}
                                            decimalScale={0}
                                            fixedDecimalScale={true}
                                            className="currency py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                            onChange={(e) => onChange(e, true)}
                                            value={detailBarangMbl.dimT}
                                            placeholder="Isi dimensi tinggi barang"
                                        />
                                    </div>
                                </div>
                                <div className="row px-2 py-1">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-3 text-xs sm:text-sm font-bold">
                                        <button
                                            type="button"
                                            className="w-100 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                                            onClick={(e) => {
                                                onSubmitDetailBarang();
                                                setShowKoliForm(false);
                                            }}
                                        >
                                            Simpan Data Koli
                                        </button>
                                    </div>
                                </div>
                            </>)
                            }
                            {!showKoliForm && (<>
                                <div className="float-right px-2 py-1">
                                    <div className="text-xs sm:text-sm font-bold">
                                        <button
                                            type="button"
                                            className="w-100 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                                            onClick={(e) =>
                                                setShowKoliForm(true)
                                            }
                                        >
                                            Tambah Data Koli
                                        </button>
                                    </div>
                                </div>
                                <div className="px-2 py-1">
                                    <TableDetailBarang
                                        arrTempBarang={arrTempBarang}
                                        setArrDetailBarangMbl={setArrDetailBarangMbl}
                                        isDeletable={true}
                                    />
                                </div>
                                <div className="px-2 py-1">
                                    <div className="row px-2 py-1">
                                        <label className="col-md-3" style={{ fontWeight: "bold" }}>
                                            Total Barang
                                        </label>
                                        <label className="col-md-3">
                                            {respCalcObj && arrDetailBarangMbl.length > 0
                                                ? new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 2,
                                                }).format(respCalcObj.TotalItem)
                                                : new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 2,
                                                }).format(0)}
                                        </label>
                                        <label className="col-md-3" style={{ fontWeight: "bold" }}>
                                            Estimasi Berat Final (Kg)
                                        </label>
                                        <label className="col-md-3">
                                            {respCalcObj && arrDetailBarangMbl.length > 0
                                                ? new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 2,
                                                }).format(respCalcObj.TotalFinalWeight)
                                                : new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 2,
                                                }).format(0)}
                                        </label>
                                    </div>

                                    {false && (
                                        <>
                                            <div className="row px-2 py-1">
                                                <label
                                                    className="col-md-3"
                                                    style={{ fontWeight: "bold" }}
                                                >
                                                    Estimasi Ongkir
                                                </label>
                                                <label className="col-md-3">
                                                    {respCalcObj && arrDetailBarangMbl.length > 0
                                                        ? new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 0,
                                                        }).format(respCalcObj.EstMainChargeAmt)
                                                        : 0}
                                                </label>
                                                <label
                                                    className="col-md-3"
                                                    style={{ fontWeight: "bold" }}
                                                >
                                                    Estimasi Asuransi
                                                </label>
                                                <label className="col-md-3">
                                                    {respCalcObj && arrDetailBarangMbl.length > 0
                                                        ? new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 0,
                                                        }).format(respCalcObj.EstAddChargeAmt)
                                                        : 0}
                                                </label>
                                            </div>
                                            <div className="row px-2 py-1">
                                                <label
                                                    className="col-md-3"
                                                    style={{ fontWeight: "bold" }}
                                                >
                                                    Estimasi Tagih Pengirim
                                                </label>
                                                <label className="col-md-3">
                                                    {respCalcObj && arrDetailBarangMbl.length > 0
                                                        ? new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 0,
                                                        }).format(respCalcObj.EstSndrMustBePaidAmt)
                                                        : 0}
                                                </label>
                                                <label
                                                    className="col-md-3"
                                                    style={{ fontWeight: "bold" }}
                                                >
                                                    Estimasi Tagih Penerima
                                                </label>
                                                <label className="col-md-3">
                                                    {respCalcObj && arrDetailBarangMbl.length > 0
                                                        ? new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 0,
                                                        }).format(respCalcObj.EstRcpntMustBePaidAmt)
                                                        : 0}
                                                </label>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>)}
                        </div>
                    )}
                    <div className="float-right">
                        {currStep > 1 && (
                            <button
                                type="button"
                                className="mx-3 w-40 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-gray-600"
                                onClick={(e) => handlePrevStep(e)}
                            >
                                <FaIcons.FaArrowCircleLeft className="float-left mt-1" />
                                Balik
                            </button>
                        )}
                        {currStep < 3 ? (
                            <button
                                type="button"
                                className="mx-3 w-40 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                                onClick={(e) => handleNextStep(e)}
                            >
                                Lanjutkan
                                <FaIcons.FaArrowCircleRight className="float-right mt-1" />
                            </button>
                        ) : (
                            <>
                                {!showKoliForm && (
                                    <button
                                        type="submit"
                                        className="mx-3 w-40 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
                                    >
                                        Simpan
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </form >
            </div >
            <div className="block sm:hidden w-full">
                <div className="flex flex-row bg-blue-600">
                    <h4 className="w-full text-center text-xl font-semibold text-white py-2">
                        Buat Booking
                    </h4>
                </div>
                <div className="flex items-center border-b-2 border-gray-300 px-3 py-2 text-xs sm:text-sm">
                    <div className="flex items-center h-full w-full ">
                        <div className="flex items-center">
                            <div className="rounded-full text-center transition duration-500 ease-in-out h-8 w-8 py-1 border-2 bg-red-600 border-red-600 text-white">
                                1
                            </div>
                            <div className="mx-2 text-center items-center text-xs font-medium">
                                Pengirim
                            </div>
                        </div>
                        <div
                            className={`flex-auto border-t-2 mr-2 transition duration-500 ease-in-out ${currStep > 1 ? "border-red-600" : "border-gray-500"
                                } `}
                        ></div>
                        <div className="flex items-center">
                            <div
                                className={`rounded-full text-center transition duration-500 ease-in-out h-8 w-8 py-1 border-2 border-red-600 ${currStep > 1 ? "bg-red-600 text-white" : "text-red-600"
                                    } `}
                            >
                                2
                            </div>
                            <div className="mx-2 text-center items-center text-xs font-medium">
                                Penerima
                            </div>
                        </div>
                        <div
                            className={`flex-auto border-t-2 mr-2 transition duration-500 ease-in-out ${currStep > 2 ? "border-red-600" : "border-gray-500"
                                } `}
                        ></div>
                        <div className="flex items-center">
                            <div
                                className={`rounded-full text-center transition duration-500 ease-in-out h-8 w-8 py-1 border-2 border-red-600 ${currStep > 2 ? "bg-red-600 text-white" : "text-red-600"
                                    } `}
                            >
                                3
                            </div>
                            <div className="mx-2 text-center items-center text-xs font-medium">
                                Barang
                            </div>
                        </div>
                    </div>
                </div>
                <AlertMessage
                    isError={isError}
                    alert={alert}
                    setAlert={setAlert}
                    setIsError={setIsError}
                />
                <div className="px-2">
                    <form onSubmit={handleSubmit} method="POST">
                        <div className="h-full w-full bg-white flex items-center">
                            <div className="h-full w-full flex items-center">
                                <div className="text-xs sm:text-sm font-medium w-full">
                                    {currStep === 1 ? (
                                        <>

                                            <div className="flex flex-1 text-xs sm:text-sm mx-2 py-1">
                                                Daftar Alamat
                                            </div>
                                            <div
                                                className={`flex border-b-2 ${submitted &&
                                                    !pickupAddrType &&
                                                    newPickupAddr === true
                                                    ? "border-red-500"
                                                    : "border-gray-300"
                                                    } mx-2 py-1`}
                                            >
                                                {newPickupAddr === false ? (
                                                    <>
                                                        <select
                                                            className="py-1 px-2 text-xs sm:text-sm  border-none outline-none w-100"
                                                            id="pickupAddressType"
                                                            name="pickupAddressType"
                                                            value={pickupAddrType}
                                                            onChange={(e) =>
                                                                setPickupAddrType(e.target.value)
                                                            }
                                                        >
                                                            <option value="" disabled hidden>
                                                                Pilih daftar alamat
                                                            </option>
                                                            {pickupAddressTypeObj &&
                                                                pickupAddressTypeObj.Result &&
                                                                pickupAddressTypeObj.Result.map(
                                                                    (pickupAddressTypeObj, i) => (
                                                                        <option
                                                                            key={i}
                                                                            value={
                                                                                pickupAddressTypeObj.AddrType
                                                                            }
                                                                        >
                                                                            {pickupAddressTypeObj.AddrType}
                                                                        </option>
                                                                    )
                                                                )}
                                                        </select>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6"
                                                            onClick={() => {
                                                                setNewPickupAddr(true);
                                                                setPickupAddrType("");
                                                            }}
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M12 6v12m6-6H6"
                                                            />
                                                        </svg>
                                                    </>
                                                ) : (
                                                    <>
                                                        <input
                                                            id="pickupAddressTypeTxt"
                                                            type="text"
                                                            name="pickupAddressTypeTxt"
                                                            placeholder="Isi tipe alamat pickup"
                                                            className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                            onChange={(e) =>
                                                                setPickupAddrType(e.target.value)
                                                            }
                                                            value={pickupAddrType}
                                                        />
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6"
                                                            onClick={() => {
                                                                setNewPickupAddr(false);
                                                                setPickupAddrType("");
                                                            }}
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M6 18L18 6M6 6l12 12"
                                                            />
                                                        </svg>
                                                    </>
                                                )}
                                            </div>
                                            {submitted &&
                                                !pickupAddrType &&
                                                newPickupAddr === true && (
                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                        Harap masukkan daftar alamat
                                                    </div>
                                                )}

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Nomor Ponsel Pengirim
                                                </div>
                                                <div
                                                    className={`flex-1 border-b-2 ${submitted && !sellerMobilePhnNo
                                                        ? "border-red-500"
                                                        : "border-gray-300"
                                                        } text-xs sm:text-sm py-1`}
                                                >
                                                    <Input
                                                        className="py-1 text-xs sm:text-sm w-100 border-none outline-none rounded-sm border-gray-300"
                                                        country="ID"
                                                        value={sellerMobilePhnNo}
                                                        onChange={setSellerMobilePhnNo}
                                                        placeholder="Isi nomor ponsel pengirim"
                                                    />
                                                    {submitted && !sellerMobilePhnNo && (
                                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                            Harap isi nomor ponsel pengirim
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Alias Pengirim
                                                </div>
                                                <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs sm:text-sm">
                                                    <input
                                                        id="sellerAliasName"
                                                        type="text"
                                                        name="sellerAliasName"
                                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                        placeholder="Isi nama alias pengirim"
                                                        value={sellerAliasName}
                                                        onChange={(e) => setSellerAliasName(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Type
                                                </div>
                                                <div
                                                    className={`flex-1 border-b-2 ${submitted && !dropType
                                                        ? "border-red-500"
                                                        : "border-gray-300"
                                                        } text-xs sm:text-sm py-1`}
                                                >
                                                    <select
                                                        className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                                        id="dropType"
                                                        name="dropType"
                                                        value={dropType}
                                                        onChange={(e) => setDropType(e.target.value)}
                                                    >
                                                        <option value="" disabled hidden>
                                                            Pilih drop type
                                                        </option>
                                                        <option
                                                            value={masterSettingConstants.DROP_TYPE_PICKUP}
                                                        >
                                                            Pickup
                                                        </option>
                                                    </select>
                                                </div>
                                                {submitted && !dropType && (
                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                        Harap pilih drop type
                                                    </div>
                                                )}
                                            </div>

                                            {dropType !== "" &&
                                                dropType ===
                                                masterSettingConstants.DROP_TYPE_PICKUP && (
                                                    <>
                                                        <div className="flex flex-col mx-2 py-1">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                                Tanggal Pickup
                                                            </div>
                                                            <div
                                                                className={`flex-1 border-b-2 ${submitted && !pickupTime
                                                                    ? "border-red-500"
                                                                    : "border-gray-300"
                                                                    } text-xs sm:text-sm py-1`}
                                                            >
                                                                <input
                                                                    id="pickupTimes"
                                                                    name="pickupTimes"
                                                                    type="date"
                                                                    value={pickupTime}
                                                                    className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                                    onChange={(e) => setPickupTime(e.target.value)}
                                                                    min={minPickupTime}
                                                                />
                                                            </div>
                                                            {submitted && !pickupTime && (
                                                                <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                                    Harap masukkan tanggal dan jam pickup
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="flex flex-col mx-2 py-1">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                                Alamat Pickup
                                                            </div>
                                                            <div
                                                                className={`flex-1 border-b-2 ${submitted && !pickupAddress
                                                                    ? "border-red-500"
                                                                    : "border-gray-300"
                                                                    } text-xs sm:text-sm py-1`}
                                                            >
                                                                <input
                                                                    id="pickupAddress"
                                                                    name="pickupAddress"
                                                                    placeholder="Isi alamat pickup"
                                                                    disabled={!newPickupAddr}
                                                                    type="text"
                                                                    className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                                    onChange={(e) =>
                                                                        setPickupAddress(e.target.value)
                                                                    }
                                                                    value={pickupAddress}
                                                                />
                                                            </div>
                                                            {submitted && !pickupAddress && (
                                                                <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                                    Harap masukkan alamat pickup
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="flex flex-col mx-2 py-1">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                                Kecamatan / Kabupaten Pickup
                                                            </div>
                                                            <div
                                                                className={`flex-1 border-b-2 ${submitted &&
                                                                    (!selectedPckDist ||
                                                                        selectedPckDist.value === 0)
                                                                    ? "border-red-500"
                                                                    : "border-gray-300"
                                                                    } text-xs sm:text-sm py-1`}
                                                            >
                                                                <Select
                                                                    filterOption={createFilter({
                                                                        ignoreAccents: false,
                                                                    })} // this makes all the difference!
                                                                    value={selectedPckDist}
                                                                    options={options}
                                                                    onChange={handleChangeDistrictPickup}
                                                                    className="text-xs sm:text-sm"
                                                                    styles={styleMobile}
                                                                    disabled={!newPickupAddr}
                                                                />
                                                            </div>
                                                            {submitted &&
                                                                (!selectedPckDist ||
                                                                    selectedPckDist.value <= 0) && (
                                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                                        Harap masukkan kecamatan / kabupaten
                                                                    </div>
                                                                )}
                                                        </div>

                                                        <div className="flex flex-col mx-2 py-1">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                                Contact Person
                                                            </div>
                                                            <div
                                                                className={`flex-1 border-b-2 ${submitted && !pickupContactPerson
                                                                    ? "border-red-500"
                                                                    : "border-gray-300"
                                                                    } text-xs sm:text-sm py-1`}
                                                            >
                                                                <input
                                                                    id="pickupContactPersons"
                                                                    type="text"
                                                                    name="pickupContactPersons"
                                                                    placeholder="Isi contact person"
                                                                    className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                                    onChange={(e) =>
                                                                        setPickupContactPerson(e.target.value)
                                                                    }
                                                                    value={pickupContactPerson}
                                                                />
                                                            </div>
                                                            {submitted && !pickupContactPerson && (
                                                                <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                                    Harap masukkan contact person
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="flex flex-col mx-2 py-1">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                                Nomor Ponsel Contact Person
                                                            </div>
                                                            <div
                                                                className={`flex-1 border-b-2 ${submitted && !pickupPhnNo
                                                                    ? "border-red-500"
                                                                    : "border-gray-300"
                                                                    } text-xs sm:text-sm py-1`}
                                                            >
                                                                <Input
                                                                    id="pickupPhnNo"
                                                                    placeholder="Isi nomor ponsel contact person "
                                                                    className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                                    country="ID"
                                                                    value={pickupPhnNo}
                                                                    onChange={setPickupPhnNo}
                                                                />
                                                            </div>
                                                            {submitted && !pickupPhnNo && (
                                                                <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                                    Harap masukkan nomor ponsel contact person
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="flex flex-col mx-2 py-1">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                                Catatan
                                                            </div>
                                                            <div className="flex-1 border-b-2 border-gray-300 text-xs sm:text-sm py-1">
                                                                <input
                                                                    id="pickupNotes"
                                                                    name="pickupNotes"
                                                                    placeholder="Isi catatan pickup"
                                                                    type="text"
                                                                    className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                                    onChange={(e) =>
                                                                        setPickupNotes(e.target.value)
                                                                    }
                                                                    value={pickupNotes}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                        </>
                                    ) : currStep === 2 ? (
                                        <>
                                            {/* {isMember &&
                                                <div className="flex flex-col mx-2 py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Nama
                                                    </div>
                                                    <div
                                                        className={`flex-1 border-b-2 ${submitted &&
                                                            (!selectedPenerima || selectedPenerima.value === 0)
                                                            ? "border-red-500"
                                                            : "border-gray-300"
                                                            } text-xs sm:text-sm py-1`}
                                                    >
                                                        <Select
                                                            filterOption={createFilter({
                                                                ignoreAccents: false,
                                                            })} // this makes all the difference!
                                                            value={selectedPenerima}
                                                            options={options}
                                                            onChange={handleChangePenerima}
                                                            className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                            styles={styleMobile}
                                                        />
                                                    </div>
                                                    {submitted &&
                                                        (!selectedPenerima || selectedPenerima.value === 0) && (
                                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                                Harap masukkan nama penerima
                                                            </div>
                                                        )}
                                                </div>
                                            } */}
                                            {!isMember &&
                                                <div className="flex flex-col mx-2 py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Nama
                                                    </div>
                                                    <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs sm:text-sm">
                                                        <input
                                                            id="recipientName"
                                                            type="text"
                                                            name="recipientName"
                                                            placeholder="Isi nama penerima"
                                                            className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                            value={recipientName}
                                                            onChange={(e) => setRecipientName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {/* <div className="col-md-2 flex flex-row text-xs sm:text-sm font-bold">
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        checked={isMember}
                                                        onChange={handleChangeMember}
                                                    />
                                                </div>
                                                <div className="ml-2">
                                                    Member
                                                </div>
                                                <div className="ml-2">
                                                    <FaIcons.FaInfoCircle className="float-right mt-1" id="memberInfoMobile" />
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="memberInfoMobile"
                                                    >
                                                        Hanya member yang dapat melakukan pembayaran Kredit Penerima.
                                                    </UncontrolledTooltip>
                                                </div>
                                            </div> */}
                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Nomor Ponsel
                                                </div>
                                                <div
                                                    className={`flex-1 border-b-2 ${submitted && !recipientMobilePhnNo
                                                        ? "border-red-500"
                                                        : "border-gray-300"
                                                        } text-xs sm:text-sm py-1`}
                                                >
                                                    <Input
                                                        id="recipientMobilePhnNo"
                                                        placeholder="Isi nomor ponsel penerima "
                                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                        country="ID"
                                                        value={recipientMobilePhnNo}
                                                        onChange={setRecipientMobilePhnNo}
                                                    />
                                                </div>
                                                {submitted && !recipientMobilePhnNo && (
                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                        Harap masukkan nomor ponsel penerima
                                                    </div>
                                                )}
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Alamat
                                                </div>
                                                <div
                                                    className={`flex-1 border-b-2 ${submitted && !recipientAddress
                                                        ? "border-red-500"
                                                        : "border-gray-300"
                                                        } text-xs sm:text-sm py-1`}
                                                >
                                                    <input
                                                        id="recipientAddress"
                                                        name="recipientAddress"
                                                        placeholder="Isi alamat penerima"
                                                        type="text"
                                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                        onChange={(e) =>
                                                            setRecipientAddress(e.target.value)
                                                        }
                                                        value={recipientAddress}
                                                    />
                                                </div>
                                                {submitted && !recipientAddress && (
                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                        Harap masukkan alamat penerima
                                                    </div>
                                                )}
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Nomor Referensi
                                                </div>
                                                <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs sm:text-sm">
                                                    <input
                                                        id="referenceNo"
                                                        type="text"
                                                        name="referenceNo"
                                                        placeholder="Isi customer reference no"
                                                        maxLength="50"
                                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                        onChange={(e) => setReferenceNo(e.target.value)}
                                                        value={referenceNo}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Asal
                                                </div>
                                                <div
                                                    className={`flex-1 border-b-2 ${submitted &&
                                                        (!selectedOrigin || selectedOrigin.value == "")
                                                        ? "border-red-500"
                                                        : "border-gray-300"
                                                        } text-xs sm:text-sm`}
                                                >
                                                    <Select
                                                        filterOption={createFilter({
                                                            ignoreAccents: false,
                                                        })} // this makes all the difference!
                                                        value={selectedOrigin}
                                                        options={originOptions}
                                                        onChange={handleChangeOrigin}
                                                        className="text-xs sm:text-sm border-none outline-none w-100"
                                                        styles={styleMobile}
                                                    />
                                                </div>
                                                {submitted &&
                                                    (!selectedOrigin || selectedOrigin.value == "") && (
                                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                            Harap isi asal pengiriman
                                                        </div>
                                                    )}
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Tujuan
                                                </div>
                                                <div
                                                    className={`flex-1 border-b-2 ${submitted &&
                                                        (!selectedDest || selectedDest.value === 0)
                                                        ? "border-red-500"
                                                        : "border-gray-300"
                                                        } text-xs sm:text-sm py-1`}
                                                >
                                                    <Select
                                                        filterOption={createFilter({
                                                            ignoreAccents: false,
                                                        })} // this makes all the difference!
                                                        value={selectedDest}
                                                        options={options}
                                                        onChange={handleChangeDest}
                                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                        styles={styleMobile}
                                                    />
                                                </div>
                                                {submitted &&
                                                    (!selectedDest || selectedDest.value === 0) && (
                                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                            Harap masukkan tujuan
                                                        </div>
                                                    )}
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Pengiriman
                                                </div>
                                                <div
                                                    className={`flex-1 border-b-2 ${submitted && !transportType
                                                        ? "border-red-500"
                                                        : "border-gray-300"
                                                        } text-xs sm:text-sm py-1`}
                                                >
                                                    <select
                                                        className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                                        id="transportType"
                                                        name="transportType"
                                                        value={transportType}
                                                        onChange={(e) => setTransportType(e.target.value)}
                                                    >
                                                        <option value="" disabled hidden>
                                                            Pilih metode pengiriman
                                                        </option>
                                                        {transportTypeObj &&
                                                            transportTypeObj.TransportTypes.map(
                                                                (transportTypeObj) => (
                                                                    <option
                                                                        key={transportTypeObj.TransportTypeCode}
                                                                        value={transportTypeObj.TransportTypeCode}
                                                                    >
                                                                        {transportTypeObj.TransportTypeName}
                                                                    </option>
                                                                )
                                                            )}
                                                    </select>
                                                </div>
                                                {submitted && !transportType && (
                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                        Harap pilih metode pengiriman
                                                    </div>
                                                )}
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Pembayaran
                                                </div>
                                                <div
                                                    className={`flex-1 border-b-2 ${submitted && !paymentType
                                                        ? "border-red-500"
                                                        : "border-gray-300"
                                                        } text-xs sm:text-sm py-1`}
                                                >
                                                    <select
                                                        className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                                        id="paymentType"
                                                        name="paymentType"
                                                        value={paymentType}
                                                        onChange={(e) => setPaymentType(e.target.value)}
                                                    >

                                                        <option value="" disabled hidden>
                                                            Pilih metode pembayaran
                                                        </option>
                                                        <option value={masterSettingConstants.PAYTYPE_LUNAS}>
                                                            Lunas
                                                        </option>
                                                        <option value={masterSettingConstants.PAYTYPE_KREDIT} hidden={hideCredit}>
                                                            Kredit
                                                        </option>
                                                        <option value={masterSettingConstants.PAYTYPE_TAGIHTUJUAN} hidden={hideCOD}>
                                                            Tagih Tujuan
                                                        </option>
                                                        <option value={masterSettingConstants.PAYTYPE_KREDITPENERIMA} hidden={hideReceiverCredit}>
                                                            Kredit Penerima
                                                        </option>
                                                    </select>
                                                </div>
                                                {submitted && !paymentType && (
                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                        Harap pilih metode pembayaran
                                                    </div>
                                                )}
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Catatan Order
                                                </div>
                                                <div className="flex-1 border-b-2 py-1 border-gray-300 text-xs sm:text-sm">
                                                    <input
                                                        id="bookingNotes"
                                                        name="bookingNotes"
                                                        placeholder="Isi catatan order"
                                                        type="text"
                                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                        onChange={(e) => setBookingNotes(e.target.value)}
                                                        value={bookingNotes}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Jenis Barang
                                                </div>
                                                <div
                                                    className={`flex-1 border-b-2 ${submitted && !packageCategory
                                                        ? "border-red-500"
                                                        : "border-gray-300"
                                                        } text-xs sm:text-sm py-1`}
                                                >
                                                    <select
                                                        className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                                        id="packageCategory"
                                                        name="packageCategory"
                                                        value={packageCategory}
                                                        onChange={(e) => setPackageCategory(e.target.value)}
                                                    >
                                                        <option value="" disabled hidden>
                                                            Pilih jenis barang
                                                        </option>
                                                        <option
                                                            value={
                                                                masterSettingConstants.PACKAGE_CATEGORY_ELEKTRONIK
                                                            }
                                                        >
                                                            ELEKTRONIK
                                                        </option>
                                                        <option
                                                            value={
                                                                masterSettingConstants.PACKAGE_CATEGORY_NON_ELEKTRONIK
                                                            }
                                                        >
                                                            NON ELEKTRONIK
                                                        </option>
                                                    </select>
                                                </div>
                                                {submitted && !packageCategory && (
                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                        Harap pilih jenis barang
                                                    </div>
                                                )}
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Tipe Barang
                                                </div>
                                                <div
                                                    className={`flex-1 border-b-2 ${submitted && !packageContent
                                                        ? "border-red-500"
                                                        : "border-gray-300"
                                                        } text-xs sm:text-sm py-1`}
                                                >
                                                    <select
                                                        className="py-1 px-2 text-xs sm:text-sm border-none outline-none w-100"
                                                        id="packageContent"
                                                        name="packageContent"
                                                        value={packageContent}
                                                        onChange={(e) => setPackageContent(e.target.value)}
                                                    >
                                                        <option value="" disabled hidden>
                                                            Pilih tipe barang
                                                        </option>
                                                        {packageContentsObj &&
                                                            packageContentsObj.map((packageContentsObj) => (
                                                                <option
                                                                    key={packageContentsObj.PackageContentCode}
                                                                    value={packageContentsObj.PackageContentCode}
                                                                >
                                                                    {packageContentsObj.PackageContentName}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                                {submitted && !packageContent && (
                                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                        Harap pilih tipe barang
                                                    </div>
                                                )}
                                            </div>

                                            {isInsurance && isInsurance === '1' &&
                                                <div className="flex flex-col mx-2 py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                        Nilai Barang
                                                    </div>
                                                    <div className={`flex-1 border-b-2 ${submitted && nilaiBarang <= 0
                                                        ? "border-red-500"
                                                        : "border-gray-300"
                                                        } text-xs sm:text-sm py-1`}>
                                                        <NumericFormat
                                                            id="estItemPriceAmt"
                                                            name="estItemPriceAmt"
                                                            thousandSeparator={true}
                                                            decimalScale={0}
                                                            fixedDecimalScale={true}
                                                            className="currency py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                            onChange={(e) => handleNilaiBarang(e)}
                                                            value={nilaiBarang}
                                                            placeholder="Isi Nilai barang"
                                                            min="0"
                                                        />
                                                    </div>
                                                    {submitted && nilaiBarang <= 0 && isInsurance == "1" && (
                                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                            Harap isi nilai barang
                                                        </div>
                                                    )}
                                                </div>
                                            }

                                            {(packageContent ===
                                                masterSettingConstants.PACKAGE_CONTENT_ELEKTRONIK_LAIN_LAIN ||
                                                packageContent ===
                                                masterSettingConstants.PACKAGE_CONTENT_NON_ELEKTRONIK_LAIN_LAIN) && (
                                                    <>
                                                        <div className="flex flex-col mx-2 py-1">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                                Catatan Paket
                                                            </div>
                                                            <div
                                                                className={`flex-1 border-b-2 ${submitted && !packageContentNotes
                                                                    ? "border-red-500"
                                                                    : "border-gray-300"
                                                                    } text-xs sm:text-sm py-1`}
                                                            >
                                                                <input
                                                                    id="packageContentNotes"
                                                                    name="packageContentNotes"
                                                                    placeholder="Isi catatan paket"
                                                                    type="text"
                                                                    className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                                    onChange={(e) =>
                                                                        setPackageContentNotes(e.target.value)
                                                                    }
                                                                    value={packageContentNotes}
                                                                />
                                                            </div>
                                                            {submitted && !packageContentNotes && (
                                                                <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                                                    Harap isi catatan paket
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                )}

                                        </>
                                    ) : (
                                        <>
                                            <div className="flex flex-1 text-sm sm:text-md items-center font-bold mx-2 py-1">
                                                Detail Barang
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Nama Barang
                                                </div>
                                                <div className="flex-1 border-b-2 border-gray-300 text-xs sm:text-sm py-1">
                                                    <input
                                                        id="itemName"
                                                        name="itemName"
                                                        placeholder="Isi nama barang"
                                                        type="text"
                                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                        onChange={(e) => onChange(e, false)}
                                                        value={detailBarangMbl.itemName}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Estimasi Berat Barang (kg)
                                                </div>
                                                <div
                                                    className={`flex border-b-2 "border-gray-300" py-1`}
                                                >
                                                    <NumericFormat
                                                        id="itemWeight"
                                                        name="itemWeight"
                                                        thousandSeparator={true}
                                                        allowLeadingZeros={false}
                                                        decimalScale={2}
                                                        fixedDecimalScale={false}
                                                        className="py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                        onChange={(e) => onChange(e, true)}
                                                        value={detailBarangMbl.itemWeight}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Panjang (cm)
                                                </div>
                                                <div className="flex border-b-2 border-gray-300 py-1">
                                                    <NumericFormat
                                                        id="dimP"
                                                        name="dimP"
                                                        thousandSeparator={true}
                                                        allowLeadingZeros={false}
                                                        decimalScale={0}
                                                        fixedDecimalScale={true}
                                                        className="currency py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                        onChange={(e) => onChange(e, true)}
                                                        value={detailBarangMbl.dimP}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Lebar (cm)
                                                </div>
                                                <div className="flex border-b-2 border-gray-300 py-1">
                                                    <NumericFormat
                                                        id="dimL"
                                                        name="dimL"
                                                        thousandSeparator={true}
                                                        allowLeadingZeros={false}
                                                        decimalScale={0}
                                                        fixedDecimalScale={true}
                                                        className="currency py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                        onChange={(e) => onChange(e, true)}
                                                        value={detailBarangMbl.dimL}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col mx-2 py-1">
                                                <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                    Tinggi (cm)
                                                </div>
                                                <div className="flex border-b-2 border-gray-300 py-1">
                                                    <NumericFormat
                                                        id="dimT"
                                                        name="dimT"
                                                        thousandSeparator={true}
                                                        allowLeadingZeros={false}
                                                        decimalScale={0}
                                                        fixedDecimalScale={true}
                                                        className="currency py-1 text-xs sm:text-sm border-none outline-none w-100"
                                                        onChange={(e) => onChange(e, true)}
                                                        value={detailBarangMbl.dimT}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-row justify-center">
                                                <button
                                                    type="button"
                                                    className="mx-3 w-50 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-blue-600"
                                                    onClick={(e) => onSubmitDetailBarang()}
                                                >
                                                    Simpan Data Barang
                                                </button>
                                            </div>

                                            <TableDetailBarang
                                                arrTempBarang={arrTempBarang}
                                                setArrDetailBarangMbl={setArrDetailBarangMbl}
                                                isDeletable={true}
                                            />

                                            <div className="rounded-md h-full px-2 py-2 mb-2 shadow-md hover:shadow-xl transition-shadow border-2 border-blue-600">
                                                <div className="flex mx-2 py-1 font-bold">
                                                    <div className="flex-1 text-xs sm:text-sm flex font-bold">
                                                        Summary
                                                    </div>
                                                </div>
                                                <div className="flex mx-2 py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                        Total Barang
                                                    </div>
                                                    <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                        {respCalcObj && arrDetailBarangMbl.length > 0
                                                            ? new Intl.NumberFormat("en", {
                                                                minimumFractionDigits: 0,
                                                            }).format(respCalcObj.TotalItem)
                                                            : new Intl.NumberFormat("en", {
                                                                minimumFractionDigits: 0,
                                                            }).format(0)}
                                                    </div>
                                                </div>
                                                <div className="flex mx-2 py-1">
                                                    <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                        Estimasi Berat Final
                                                    </div>
                                                    <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                        {respCalcObj && arrDetailBarangMbl.length > 0
                                                            ? new Intl.NumberFormat("en", {
                                                                minimumFractionDigits: 2,
                                                            }).format(respCalcObj.TotalFinalWeight)
                                                            : new Intl.NumberFormat("en", {
                                                                minimumFractionDigits: 2,
                                                            }).format(0)}{" "}
                                                        kg
                                                    </div>
                                                </div>
                                                {false && (
                                                    <>
                                                        <div className="flex mx-2 py-1">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                                Estimasi Ongkir
                                                            </div>
                                                            <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                                Rp{" "}
                                                                {respCalcObj && arrDetailBarangMbl.length > 0
                                                                    ? new Intl.NumberFormat("en", {
                                                                        minimumFractionDigits: 0,
                                                                    }).format(respCalcObj.EstMainChargeAmt)
                                                                    : 0}
                                                            </div>
                                                        </div>
                                                        <div className="flex mx-2 py-1">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                                Estimasi Asuransi
                                                            </div>
                                                            <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                                Rp{" "}
                                                                {respCalcObj && arrDetailBarangMbl.length > 0
                                                                    ? new Intl.NumberFormat("en", {
                                                                        minimumFractionDigits: 0,
                                                                    }).format(respCalcObj.EstAddChargeAmt)
                                                                    : 0}
                                                            </div>
                                                        </div>
                                                        <div className="flex mx-2 py-1">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                                Estimasi Tagih Pengirim
                                                            </div>
                                                            <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                                Rp{" "}
                                                                {respCalcObj && arrDetailBarangMbl.length > 0
                                                                    ? new Intl.NumberFormat("en", {
                                                                        minimumFractionDigits: 0,
                                                                    }).format(respCalcObj.EstSndrMustBePaidAmt)
                                                                    : 0}
                                                            </div>
                                                        </div>
                                                        <div className="flex mx-2 py-1">
                                                            <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                                Estimasi Tagih Penerima
                                                            </div>
                                                            <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                                Rp{" "}
                                                                {respCalcObj && arrDetailBarangMbl.length > 0
                                                                    ? new Intl.NumberFormat("en", {
                                                                        minimumFractionDigits: 0,
                                                                    }).format(respCalcObj.EstRcpntMustBePaidAmt)
                                                                    : 0}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    <div className="flex flex-row mx-2 mb-2 mt-1 justify-center">
                                        {currStep > 1 && (
                                            <button
                                                type="button"
                                                className="w-100 mx-1 py-2 border border-transparent font-medium rounded-full text-white bg-gray-600"
                                                onClick={(e) => handlePrevStep(e)}
                                            >
                                                Balik
                                            </button>
                                        )}
                                        {currStep < 3 ? (
                                            <button
                                                type="button"
                                                className={`w-100 ${currStep > 1 && "mx-1"
                                                    } py-2 border border-transparent font-medium rounded-full text-white bg-red-600`}
                                                onClick={(e) => handleNextStep(e)}
                                            >
                                                Lanjutkan
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="w-100 mx-1 py-2 border border-transparent font-medium rounded-full text-white bg-red-600"
                                            >
                                                Simpan
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Modal size="lg" isOpen={modalShow}>
                <ModalHeader className="font-bold text-xs sm:text-sm items-center">
                    Summary Booking
                </ModalHeader>
                <ModalBody>
                    {respSubmitObj && (
                        <>
                            <div className="hidden sm:block">
                                <div className="relative h-full w-full flex items-center">
                                    <div className="text-xs sm:text-sm font-medium w-full px-2 py-2">
                                        <div className="flex px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Booking telah sukses dibuat dengan nomor resi [
                                                {respSubmitObj.ResiNo}]
                                            </div>
                                        </div>
                                        <div className="flex px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex font-bold">
                                                SUMMARY
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Nomor Referensi
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {respSubmitObj.CustRefNo}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Alias Pengirim
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {respSubmitObj.AliasName}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Tujuan
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {respSubmitObj.DestDistrictName}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Nama Penerima
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {respSubmitObj.RecipientName}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Alamat Penerima
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {respSubmitObj.RecipientAddr}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Total Koli
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 0,
                                                }).format(respSubmitObj.TotalItem)}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Total Berat
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 2,
                                                }).format(respSubmitObj.TotalFinalWeight)}
                                            </div>
                                        </div>
                                        {respSubmitObj.DropType ===
                                            masterSettingConstants.DROP_TYPE_DROP && (
                                                <>
                                                    <div className="flex px-2 py-1">
                                                        <div className="flex-1 text-xs sm:text-sm flex">
                                                            Drop / Pickup
                                                        </div>
                                                        <div className="flex-1 text-xs sm:text-sm font-bold">
                                                            {respSubmitObj.DropType}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        {respSubmitObj.DropType ===
                                            masterSettingConstants.DROP_TYPE_PICKUP && (
                                                <>
                                                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                                        <div className="flex-1 text-xs sm:text-sm flex">
                                                            Drop / Pickup
                                                        </div>
                                                        <div className="flex-1 text-xs sm:text-sm font-bold">
                                                            {respSubmitObj.DropType}
                                                        </div>
                                                    </div>
                                                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                                        <div className="flex-1 text-xs sm:text-sm flex">
                                                            Alamat Pickup
                                                        </div>
                                                        <div className="flex-1 text-xs sm:text-sm font-bold">
                                                            {respSubmitObj.PickupAddr}
                                                        </div>
                                                    </div>
                                                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                                        <div className="flex-1 text-xs sm:text-sm flex">
                                                            Kecamatan / Kabupaten
                                                        </div>
                                                        <div className="flex-1 text-xs sm:text-sm font-bold">
                                                            {respSubmitObj.PickupDistrictDescr}
                                                        </div>
                                                    </div>
                                                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                                        <div className="flex-1 text-xs sm:text-sm flex">
                                                            Contact Person
                                                        </div>
                                                        <div className="flex-1 text-xs sm:text-sm font-bold">
                                                            {respSubmitObj.PickupContactPerson}
                                                        </div>
                                                    </div>
                                                    <div className="flex px-2 py-1">
                                                        <div className="flex-1 text-xs sm:text-sm flex">
                                                            Nomor Ponsel Contact Person
                                                        </div>
                                                        <div className="flex-1 text-xs sm:text-sm font-bold">
                                                            {respSubmitObj.PickupPhoneNo}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center">
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToBookingDetail()}
                                    >
                                        Cetak Label Barang
                                    </button>
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToCreateBooking()}
                                    >
                                        Buat Booking Lain
                                    </button>
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToHome()}
                                    >
                                        Kembali ke Home
                                    </button>
                                </div>
                            </div>
                            <div className="block sm:hidden">
                                <div className="relative h-full w-full flex items-center">
                                    <div className="text-xs sm:text-sm font-medium w-full px-2 py-2">
                                        <div className="flex px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Booking telah sukses dibuat dengan nomor resi [
                                                {respSubmitObj.ResiNo}]
                                            </div>
                                        </div>
                                        <div className="flex px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex font-bold">
                                                SUMMARY
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                Nomor Referensi
                                            </div>
                                            <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                {respSubmitObj.CustRefNo}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                                Alias Pengirim
                                            </div>
                                            <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                {respSubmitObj.AliasName}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Tujuan
                                            </div>
                                            <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                {respSubmitObj.DestDistrictName}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Nama Penerima
                                            </div>
                                            <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                {respSubmitObj.RecipientName}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                Alamat Penerima
                                            </div>
                                            <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                {respSubmitObj.RecipientAddr}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Total Koli
                                            </div>
                                            <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                {new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 0,
                                                }).format(respSubmitObj.TotalItem)}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Total Berat
                                            </div>
                                            <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                {new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 2,
                                                }).format(respSubmitObj.TotalFinalWeight)}
                                            </div>
                                        </div>
                                        {respSubmitObj.DropType ===
                                            masterSettingConstants.DROP_TYPE_DROP && (
                                                <>
                                                    <div className="flex px-2 py-1">
                                                        <div className="flex-1 text-xs sm:text-sm flex">
                                                            Drop / Pickup
                                                        </div>
                                                        <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                            {respSubmitObj.DropType}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        {respSubmitObj.DropType ===
                                            masterSettingConstants.DROP_TYPE_PICKUP && (
                                                <>
                                                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                                        <div className="flex-1 text-xs sm:text-sm flex">
                                                            Drop / Pickup
                                                        </div>
                                                        <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                            {respSubmitObj.DropType}
                                                        </div>
                                                    </div>
                                                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                                        <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                            Alamat Pickup
                                                        </div>
                                                        <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                            {respSubmitObj.PickupAddr}
                                                        </div>
                                                    </div>
                                                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                                        <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                            Kecamatan / Kabupaten
                                                        </div>
                                                        <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                            {respSubmitObj.PickupDistrictDescr}
                                                        </div>
                                                    </div>
                                                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                                        <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                            Contact Person
                                                        </div>
                                                        <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                            {respSubmitObj.PickupContactPerson}
                                                        </div>
                                                    </div>
                                                    <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                                        <div className="flex-1 text-xs sm:text-sm flex items-end">
                                                            Nomor Ponsel Contact Person
                                                        </div>
                                                        <div className="flex-1 text-right text-xs sm:text-sm font-bold">
                                                            {respSubmitObj.PickupPhoneNo}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center">
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToBookingDetail()}
                                    >
                                        Cetak Label Barang
                                    </button>
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToCreateBooking()}
                                    >
                                        Buat Booking Lain
                                    </button>
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToHome()}
                                    >
                                        Kembali ke Home
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};
export default BookingAddForm;
