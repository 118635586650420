import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bookingActions } from "../../_actions";
import {
  BookingTables,
  Pagination,
  AlertMessage,
  ShortcutDate,
} from "../../components";
import { useLocation } from "react-router-dom";
import { masterSettingConstants } from "../../_constants";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

const BookingMonitor = ({ t }) => {
  //const { trxDtGte, trxDtLte } = useParams();
  let [cur, setCur] = useState(1);
  let [num, setNum] = useState(1);
  const dispatch = useDispatch();
  let date = new Date();
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const trxDtGte = query.get("trxDtFrom");
  const trxDtLte = query.get("trxDtTo");
  const _dropType = query.get("dropType");
  const _addrType = query.get("addrType");

  const [shortcut, setShortcut] = useState(false);
  const [bookingMonitor, setBookingMonitor] = useState({
    bookingDtGte: trxDtGte
      ? trxDtGte
      : dateParser(new Date(date.getFullYear(), date.getMonth(), 1)),
    bookingDtLte: trxDtLte ? trxDtLte : dateParser(date),
    dropType: _dropType != undefined ? _dropType : "",
    addrType: _addrType != undefined ? _addrType : "",
  });
  const [enabled, setEnabled] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loadMore, setLoadmore] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(99);
  const [sortConfig, setSortConfig] = useState({
    key: "BookDt",
    direction: "DESC",
  });
  const acc = useSelector((state) => state.authentication.accInfo);
  const bookingObj = useSelector((state) => state.bookings.bookingObj);
  const errBookingObj = useSelector(
    (state) => state.bookings.errBookingMonitoringObj
  );
  const [alert, setAlert] = useState({
    Type: "",
    StatusMessage: "",
  });

  function dateParser(value) {
    const date = new Date(value);
    let dateParsed =
      date.getFullYear() +
      "-" +
      date.toLocaleString("en-us", { month: "2-digit" }) +
      "-" +
      date.toLocaleString("en-us", { day: "2-digit" });
    return dateParsed;
  }

  function hitBookingMonitor(pageNo, rowPerPages) {
    setSubmitted(true);
    if (
      bookingMonitor.bookingDtGte &&
      bookingMonitor.bookingDtLte &&
      acc &&
      acc.CustId
    ) {
      const monitoringObj = {
        CustId: acc.CustId,
        BookingDtGte: bookingMonitor.bookingDtGte,
        BookingDtLte: bookingMonitor.bookingDtLte,
        DropType: bookingMonitor.dropType,
        AddrType: bookingMonitor.addrType,
        RowPerPage: rowPerPages,
        PageNo: pageNo,
        OrderBy: sortConfig,
      };
      dispatch(bookingActions.requestMonitoring(monitoringObj));
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setBookingMonitor((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setSubmitted(false);
  }

  function handleSearch(e) {
    e.preventDefault();
    setSubmitted(true);
    setCur(1);
    setNum(1);
    setTotalRecord(0);
    setLoadmore(1);
    hitBookingMonitor(1, rowPerPage);
  }

  useEffect(() => {
    if (totalRecord > 0 && acc.CustId) {
      hitBookingMonitor(1, loadMore * rowPerPage);
    }
  }, [loadMore]);

  useEffect(() => {
    setTotalRecord(0);
    setLoadmore(1);
    setCur(1);
    setNum(1);
    dispatch(bookingActions.clearBookingMonitoring());
    hitBookingMonitor(1, rowPerPage);
    setSubmitted(true);
  }, []);

  useEffect(() => {
    if (errBookingObj && errBookingObj.StatusCode !== "200") {
      setIsError(true);
      setAlert({ Type: "Error", StatusMessage: errBookingObj.StatusMessage });
    }
  }, [errBookingObj]);

  useEffect(() => {
    if (bookingObj && bookingObj.TotalRecord) {
      setTotalRecord(bookingObj.TotalRecord);
    } else {
      setTotalRecord(0);
    }
  }, [bookingObj]);

  useEffect(() => {
    if (totalRecord > 0 && acc.CustId) {
      hitBookingMonitor(cur, rowPerPage);
    }
  }, [cur]);

  useEffect(() => {
    if (sortConfig != null && sortConfig != undefined) {
      if (
        totalRecord > 0 &&
        bookingMonitor.bookingDtGte &&
        bookingMonitor.bookingDtLte
      ) {
        hitBookingMonitor(cur, rowPerPage);
      }
    }
  }, [sortConfig]);

  const getDate = (dateObj) => {
    setBookingMonitor({
      bookingDtGte: dateObj.dateFrom,
      bookingDtLte: dateObj.dateTo,
      dropType: bookingMonitor.dropType,
      addrType: bookingMonitor.addrType,
    });

    setCur(1);
    setTotalRecord(0);
    setLoadmore(1);
    setShortcut(true);
  };

  useEffect(() => {
    if (shortcut) {
      setNum(1);
      hitBookingMonitor(1, rowPerPage);
      setSubmitted(true);
      setShortcut(false);
    }
  }, [shortcut]);

  return (
    <>
      <div className="hidden sm:block mx-auto">
        <div className="justify-center py-2 px-4 flex flex-row">
          <h1 className="font-bold text-xl">Booking Monitoring</h1>
        </div>
        <form onSubmit={handleSearch} method="POST">
          <div className="mx-auto text-xs sm:text-sm font-medium">
            <div className="row px-2 py-1">
              <div className="col-md-3 text-xs sm:text-sm">
                Tanggal Awal Transaksi
              </div>
              <div className="col-md-3 text-xs sm:text-sm">
                <input
                  aria-label="Tanggl Awal Transaksi"
                  id="bookingDtGte"
                  name="bookingDtGte"
                  type="date"
                  value={bookingMonitor.bookingDtGte}
                  className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                  onChange={handleChange}
                />
                {submitted && !bookingMonitor.bookingDtGte && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                    Tidak boleh kosong
                  </div>
                )}
              </div>
              <div className="col-md-3 text-xs sm:text-sm">
                Tanggal Akhir Transaksi
              </div>
              <div className="col-md-3 text-xs sm:text-sm">
                <input
                  aria-label="Tanggal Akhir Transaksi"
                  id="bookingDtLte"
                  name="bookingDtLte"
                  type="date"
                  value={bookingMonitor.bookingDtLte}
                  className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100"
                  onChange={handleChange}
                />
                {submitted && !bookingMonitor.bookingDtLte && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                    Tidak boleh kosong
                  </div>
                )}
              </div>
            </div>
            <div className="row px-2 py-1">
              <div className="col-md-3 text-xs sm:text-sm">Drop Type</div>
              <div className="col-md-3 text-xs sm:text-sm">
                <select
                  className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                  id="dropType"
                  name="dropType"
                  value={bookingMonitor.dropType}
                  onChange={handleChange}
                >
                  <option value="">Semua</option>
                  <option value={masterSettingConstants.DROP_TYPE_DROP}>
                    Drop
                  </option>
                  <option value={masterSettingConstants.DROP_TYPE_PICKUP}>
                    Pickup
                  </option>
                </select>
              </div>
            </div>
            <div className="flex justify-end mb-2 -mt-2">
              <ShortcutDate onSubmit={getDate} />
            </div>
            <div className="flex justify-end mb-2 -mt-4">
              <button
                type="submit"
                className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
              >
                Search
              </button>
            </div>
          </div>
        </form>
        {isError == true && errBookingObj && (
          <AlertMessage
            isError={isError}
            alert={alert}
            setAlert={setAlert}
            setIsError={setIsError}
          />
        )}
        {bookingObj && bookingObj.Result && (
          <>
            <BookingTables
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
              dropType={bookingMonitor.dropType}
              addrType={bookingMonitor.addrType}
            />
          </>
        )}
      </div>
      <div className="block sm:hidden w-full">
        <div className="bg-blue-600">
          <h4 className="flex justify-center text-xl font-semibold text-white py-2">
            Booking Monitoring
          </h4>
        </div>
        <form onSubmit={handleSearch} method="POST">
          <div className="flex flex-col text-xs sm:text-sm px-3">
            <div className="flex flex-row font-bold pt-2">
              <div className="flex-1 text-xs sm:text-sm flex items-center">
                Filter dari Tanggal
              </div>
              <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                sampai Tanggal
              </div>
            </div>
            <div className="flex flex-row mt-1">
              <div className="flex-1 text-xs sm:text-sm flex items-center">
                <input
                  aria-label="Tanggal Awal Transaksi"
                  id="bookingDtGte"
                  name="bookingDtGte"
                  type="date"
                  value={bookingMonitor.bookingDtGte}
                  className={`py-1 px-2 ${submitted && !bookingMonitor.bookingDtGte
                    ? "border-red-500"
                    : "border-gray-300"
                    } text-xs sm:text-sm outline-none rounded-sm w-100`}
                  placeholder="Tanggal Awal Transaksi"
                  onChange={handleChange}
                />
              </div>
              <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                <input
                  aria-label="Tanggal Akhir Transaksi"
                  id="bookingDtLte"
                  name="bookingDtLte"
                  type="date"
                  value={bookingMonitor.bookingDtLte}
                  className={`py-1 px-2 ${submitted && !bookingMonitor.bookingDtLte
                    ? "border-red-500"
                    : "border-gray-300"
                    } text-xs sm:text-sm outline-none rounded-sm w-100`}
                  placeholder="Tanggal Akhir Transaksi"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="flex-1 text-xs sm:text-sm flex items-center">
                {submitted && !bookingMonitor.bookingDtGte && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 ml-4 py-1">
                    Isi tanggal awal
                  </div>
                )}
              </div>
              <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                {submitted && !bookingMonitor.bookingDtLte && (
                  <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                    Isi tanggal akhir
                  </div>
                )}
              </div>
            </div>
            {enabled === true && (
              <>
                <div className="flex flex-row mt-1 font-bold">
                  <div className="flex-1 text-xs sm:text-sm flex items-center">
                    Drop Type
                  </div>
                </div>
                <div className="flex flex-row mt-1">
                  <div className="flex-1 text-xs sm:text-sm flex items-center">
                    <select
                      className="py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none rounded-sm w-100"
                      id="dropType"
                      name="dropType"
                      value={bookingMonitor.dropType}
                      onChange={handleChange}
                    >
                      <option value="">Semua</option>
                      <option value={masterSettingConstants.DROP_TYPE_DROP}>
                        Drop
                      </option>
                      <option value={masterSettingConstants.DROP_TYPE_PICKUP}>
                        Pickup
                      </option>
                    </select>
                  </div>
                  <div className="flex flex-1"></div>
                </div>
              </>
            )}
          </div>

          <div className="flex">
            <div className="flex flex-1 flex-col text-xs sm:text-sm items-center">
              <button
                className="text-red-600 px-3 pt-2"
                onClick={(e) => setEnabled(!enabled)}
              >
                {enabled === true ? (
                  <>
                    Hide Search
                    <FaCaretUp
                      className="mb-1 w-4 h-4"
                      style={{ display: "inline-block" }}
                    />
                  </>
                ) : (
                  <>
                    Advance Search
                    <FaCaretDown
                      className="mb-1 w-4 h-4"
                      style={{ display: "inline-block" }}
                    />
                  </>
                )}
              </button>
            </div>
          </div>
          <ShortcutDate onSubmit={getDate} />
          <div className="flex flex-col justify-center -mt-4">
            <button
              type="submit"
              className="mx-3 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600"
            >
              Search
            </button>
          </div>
        </form>
        {isError == true && errBookingObj && (
          <AlertMessage
            isError={isError}
            alert={alert}
            setAlert={setAlert}
            setIsError={setIsError}
          />
        )}
        {bookingObj && bookingObj.Result && (
          <>
            <BookingTables
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
              dropType={bookingMonitor.dropType}
              addrType={bookingMonitor.addrType}
            />
          </>
        )}
      </div>
    </>
  );
};
export default BookingMonitor;
