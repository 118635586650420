import { httpHelpers } from "../_helpers";
import { urlConstants } from "../_constants";

export const pickupService = {
    getPickupAddr,
    pickupMonitoring,
    getPickupOrderHInfoById,
    cancelPickupOrder,
    pickupOrderDetail,
    pickupReqMonitoring,
    pickupReqDetail,
    addEditPickupReqGrp,
    getPickupAddrById,
    getListPickUpAddr
};

function getPickupAddr(pickupAddrTypeObj) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(pickupAddrTypeObj),
    };

    return fetch(urlConstants.URL_GET_PICKUP_ADDR, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log("result: ", result);
            if (result) {
                var _result = {
                    pickupAddrObj: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem("pickupAddrObj", JSON.stringify(_result));
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function pickupMonitoring(pickupMonitoringObj) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(pickupMonitoringObj),
    };

    return fetch(urlConstants.URL_PICKUP_MONITORING, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log("result: ", _result);
            if (result) {
                var _result = {
                    pickupMonitoring: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem("pickupMonitoring", JSON.stringify(_result));
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function getPickupOrderHInfoById(pickupOrderHId) {
    const getPickupOrderHInfoObj = {
        id: pickupOrderHId,
    };

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(getPickupOrderHInfoObj),
    };

    return fetch(urlConstants.URL_GET_PICKUP_ORDER_H_INFO_BY_ID, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log("result: ", _result);
            if (result) {
                var _result = {
                    pickupOrderHInfo: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem("pickupOrderHInfo", JSON.stringify(_result));
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function cancelPickupOrder(cancelPickupOrderObj) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(cancelPickupOrderObj),
    };

    return fetch(urlConstants.URL_CANCEL_PICKUP_ORDER, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log("result: ", _result);
            if (result) {
                var _result = {
                    cancelPickupOrder: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem("cancelPickupOrder", JSON.stringify(_result));
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function pickupOrderDetail(pickupOrderHId) {
    const pickupOrderDetailObj = {
        Id: pickupOrderHId,
    };

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(pickupOrderDetailObj),
    };

    return fetch(urlConstants.URL_PICKUP_ORDER_DETAIL, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log("result: ", _result);
            if (result) {
                var _result = {
                    pickupOrderDetail: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem("pickupOrderDetail", JSON.stringify(_result));
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function pickupReqMonitoring(pickupReqMonitoringObj) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(pickupReqMonitoringObj),
    };

    return fetch(urlConstants.URL_PICKUP_REQ_MONITORING, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log("result: ", _result);
            if (result) {
                var _result = {
                    pickupReqMonitoringObj: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem(
                        "pickupReqMonitoringObj",
                        JSON.stringify(_result)
                    );
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function pickupReqDetail(pickupReqDetailObj) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(pickupReqDetailObj),
    };

    return fetch(urlConstants.URL_PICKUP_REQ_DETAIL, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log("result: ", _result);
            if (result) {
                var _result = {
                    pickupReqDetail: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem("pickupReqDetail", JSON.stringify(_result));
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function addEditPickupReqGrp(pickupReqGrpObj) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(pickupReqGrpObj),
    };

    return fetch(urlConstants.URL_PICKUP_REQ_GRP_ADD_EDIT, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log("result: ", result);
            if (result) {
                var _result = {
                    pickupReqGrpObj: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem("pickupReqGrpObj", JSON.stringify(_result));
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function getPickupAddrById(pickupAddrTypeObj) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(pickupAddrTypeObj),
    };

    return fetch(urlConstants.URL_GET_PICKUP_ADDR, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then((result) => {
            console.log("result: ", result);
            if (result) {
                var _result = {
                    pickupAddrByIdObj: result.ObjectResult,
                };
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem("pickupAddrByIdObj", JSON.stringify(_result));
                }
            } else if (result.statusCode !== "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}

function getListPickUpAddr(pickUpAddrListObj) {
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(pickUpAddrListObj)
    };

    return fetch(urlConstants.URL_PICKUP_REQ_GRP, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    pickupAddrListObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('pickupAddrListObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode != "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}
