import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadActions } from "../../_actions";
import { useLocation } from "react-router-dom";
import {
  Pagination,
  UploadDetailTable,
} from "../../components";
import axios from "axios";
import { urlConstants } from "../../_constants/url.constants";

const UploadDetail = ({ t }) => {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();
  const dispatch = useDispatch();

  const uploadDetail = useSelector(
    (state) => state.uploadDetail.uploadDetail
  );
  const [rowPerPage, setRowPerPage] = useState(10);
  let [num, setNum] = useState(1);
  let [cur, setCur] = useState(1);
  const [loadMore, setLoadmore] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [exported, setExported] = useState(false);
  const [exportDataObj, setExportDataObj] = useState();
  const [isError, setIsError] = useState(false);
  const client = axios.create({
    baseURL: urlConstants.URL_EXPORT_UPLOAD_DATA,
  });
  const [alert, setAlert] = useState({
    Type: "",
    StatusMessage: "",
  });

  useEffect(() => {
    setTotalRecord(0);
    setLoadmore(1);
    setNum(1);
    callUploadDetail(1, rowPerPage);
    setCur(1);
  }, []);

  function callUploadDetail(pageNo, rowPerPages) {
    var id = query.get("uploadMonitoringId");
    if (id) {
      var requestObj = {
        UploadMonitoringId: id,
        RowPerPage: rowPerPages,
        PageNo: pageNo,
      };
      dispatch(uploadActions.uploadDetail(requestObj));
    }
  }

  useEffect(() => {
    if (uploadDetail && uploadDetail.TotalRecord) {
      console.log(uploadDetail)
      setTotalRecord(uploadDetail.TotalRecord);
    }
  }, [uploadDetail]);

  useEffect(() => {
    if (totalRecord > 0) {
      callUploadDetail(cur, rowPerPage);
    }
  }, [cur]);

  useEffect(() => {
    if (totalRecord > 0) {
      callUploadDetail(1, loadMore * rowPerPage);
    }
  }, [loadMore]);

  function exportData() {
    setIsError(false);
    var requestObj = {
      UploadMonitoringId: query.get("uploadMonitoringId"),
      RowPerPage: 99999,
      PageNo: 1,
    };
    exportUploadMonitoring(requestObj);
    setExported(true);
  }

  const exportUploadMonitoring = async (requestObj) => {
    try {
      let result = await client.post("", requestObj);
      if (result.data.IsError) {
        console.log("Export Error");
        setIsError(true);
        setAlert({
          Type: "Error",
          StatusMessage: result.data.StatusMessage,
        });
      } else {
        setExportDataObj({
          exportUploadData: result.data.ObjectResult,
        });
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
      setAlert({
        Type: "Error",
        StatusMessage:
          "An error occure, please contact our admin for further information",
      });
    }
  };

  useEffect(() => {
    if (exportDataObj && exportDataObj.exportUploadData && exported) {
      var a = document.createElement("a");
      a.href =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        exportDataObj.exportUploadData.ExportDataFile;
      a.download = exportDataObj.exportUploadData.Filename;
      a.click();

      setExported(false);
    }
  }, [exportDataObj]);

  return (
    <>
      <div className="hidden sm:block">
        <div className="justify-center py-2 px-4 flex flex-row">
          <h1 className="font-bold text-xl">Upload Detail</h1>
        </div>
        <div className="flex justify-end mb-2 -mt-4">
          <button
            type="button"
            onClick={exportData}
            className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
          >
            Export
          </button>
        </div>
      </div>
      <div className="block sm:hidden">
        <div className="bg-blue-600">
          <h4 className="flex justify-center text-xl font-semibold text-white py-2">
            Upload Detail
          </h4>
        </div>
      </div>
      {uploadDetail && uploadDetail.Result && (
        <div className="justify-center overflow-x-auto">
          <UploadDetailTable />
          {uploadDetail.Result.length > 0 && (
            <Pagination
              cur={cur}
              setCur={setCur}
              loadMore={loadMore}
              setLoadmore={setLoadmore}
              rowPerPage={rowPerPage}
              totalRecord={totalRecord}
              num={num}
              setNum={setNum}
            />
          )}
        </div>
      )}
    </>
  );
};
export default UploadDetail;
