import { districtConstants } from '../_constants';
import { districtService } from '../_services';

export const districtActions = {
    getDistrict
};

function getDistrict() {
    return dispatch => {
        dispatch(request());
        districtService.getDistrict()
            .then(
                districtObj => {
                    dispatch(success(districtObj));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request() { return { type: districtConstants.GET_DISTRICT_REQUEST } }
    function success(districtObj) { return { type: districtConstants.GET_DISTRICT_SUCCESS, districtObj } }
    function failure(error) { return { type: districtConstants.GET_DISTRICT_FAILURE, error } }
}
