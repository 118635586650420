import { districtConstants } from '../_constants';

let districtObj = JSON.parse(localStorage.getItem('districtObj'));;
const initialState = districtObj ? { loggedIn: true, districtObj: districtObj.districtObj } : {};

export function district(state = initialState, action) {
    switch (action.type) {
        case districtConstants.GET_DISTRICT_REQUEST:
            return {
            };
        case districtConstants.GET_DISTRICT_SUCCESS:
            return {
                districtObj: action.districtObj.districtObj
            };
        case districtConstants.GET_DISTRICT_FAILURE:
            return {};
        default:
            return state
    }
}
