import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pickupActions } from "../../_actions";
import { masterSettingConstants } from "../../_constants";
import { PickupMonitoringTable, Pagination, AlertMessage, ShortcutDate } from "../../components";
import { useParams, useLocation } from "react-router-dom";

const PickupMonitoring = ({ t }) => {
    const acc = useSelector((state) => state.authentication.accInfo);
    const pickupMonitoringState = useSelector(
        (state) => state.pickupMonitoring.pickupMonitoring
    );

    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [shortcut, setShortcut] = useState(false);
    const [sortConfig, setSortConfig] = useState({
        key: "PickupDt",
        direction: "Desc",
    });
    const { noResi } = useParams();
    const [totalRecord, setTotalRecord] = useState(0);
    const [loadMore, setLoadmore] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(99);
    let [num, setNum] = useState(1);
    let [cur, setCur] = useState(1);
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
      }

    const query = useQuery();
    const _pickupReqGrpId = query.get("pickupReqGrpId");

    const [pickupMonitoring, setPickupMonitoring] = useState({
        trxDtFrom: dateParser(new Date()),
        trxDtTo: dateParser(new Date()),
        pickupProcessStat: masterSettingConstants.PICKUP_STAT_ACT,
        pickupReqGrpId: _pickupReqGrpId != undefined ? _pickupReqGrpId : ""
    });

    function dateParser(value) {
        const date = new Date(value);
        const dateParsed =
            date.getFullYear() +
            "-" +
            date.toLocaleString("en-us", { month: "2-digit" }) +
            "-" +
            date.toLocaleString("en-us", { day: "2-digit" });
        return dateParsed;
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setPickupMonitoring((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    function handleSearch(e) {
        e.preventDefault();

        setNum(1);
        setSubmitted(true);
        callPickupMonitoring(1, rowPerPage);
    }

    function callPickupMonitoring(pageNo, rowPerPages) {
        if (acc != undefined) {
            let custName = "RatriWidyaningrum|Ratri Widyaningrum"; //acc.CustNo + "|" + acc.CustName
            let pickupMonitoringObj = {
                custName: custName,
                pickupProcessStat: pickupMonitoring.pickupProcessStat,
                trxDtFrom: pickupMonitoring.trxDtFrom,
                trxDtTo: pickupMonitoring.trxDtTo,
                PickupReqGrpId: pickupMonitoring.pickupReqGrpId,
                RowPerPage: rowPerPages,
                PageNo: pageNo,
                OrderBy: sortConfig,
            };
            dispatch(pickupActions.pickupMonitoring(pickupMonitoringObj));
        }
    }

    useEffect(() => {
        if (totalRecord > 0 && pickupMonitoring.trxDtFrom && pickupMonitoring.trxDtTo) {
            callPickupMonitoring(cur, rowPerPage);
        }
    }, [cur]);

    useEffect(() => {
        if (pickupMonitoringState && pickupMonitoringState.TotalRecord) {
            setTotalRecord(pickupMonitoringState.TotalRecord);
        }
    }, [pickupMonitoringState]);

    useEffect(() => {
        if (sortConfig != null && sortConfig != undefined) {
            if (
                totalRecord > 0 &&
                pickupMonitoring.trxDtFrom &&
                pickupMonitoring.trxDtTo
            ) {
                callPickupMonitoring(1, rowPerPage);
            }
        }
    }, [sortConfig]);

    const getDate = (dateObj) => {
        setPickupMonitoring({
            trxDtFrom: dateObj.dateFrom,
            trxDtTo: dateObj.dateTo,
            pickupProcessStat: pickupMonitoring.pickupProcessStat
        });

        setShortcut(true);
    };

    useEffect(() => {
        if (shortcut) {
            callPickupMonitoring(cur, rowPerPage);
            setSubmitted(true);
            setShortcut(false);
        }
    }, [shortcut]);


    useEffect(() => {
        setSubmitted(false);
        setShortcut(false);
        setCur(1);
        setNum(1);
        setTotalRecord(0);
        setLoadmore(1);
    }, []);

    useEffect(() => {
        if (totalRecord > 0) {
            callPickupMonitoring(1, loadMore * rowPerPage);
        }
    }, [loadMore]);

    return (
        <>
            <div className="hidden sm:block w-full mx-auto">
                <div className="justify-center py-2 px-4 flex flex-row">
                    <h1 className="font-bold text-xl">PICKUP MONITORING</h1>
                </div>
                <div>
                    <form onSubmit={handleSearch} method="POST">
                        <div className="h-full w-full bg-white text-xs sm:text-sm font-medium">
                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm">
                                    Waktu Request Awal
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <input aria-label="Waktu Request Awal" id="trxDtFrom" name="trxDtFrom" type="date" value={pickupMonitoring.trxDtFrom} className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100" onChange={handleChange} />
                                    {
                                        submitted && !pickupMonitoring.trxDtFrom &&
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap isi waktu request awal
                                        </div>
                                    }
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    Waktu Request Akhir
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <input aria-label="Waktu Request Akhir" id="trxDtTo" name="trxDtTo" type="date" value={pickupMonitoring.trxDtTo} className="py-1 px-2 border-gray-300 text-xs sm:text-sm outline-none rounded-sm w-100" onChange={handleChange} />
                                    {
                                        submitted && !pickupMonitoring.trxDtTo &&
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                            Harap isi waktu request akhir
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row px-2 py-1">
                                <div className="col-md-3 text-xs sm:text-sm">
                                    Status Proses Pickup
                                </div>
                                <div className="col-md-3 text-xs sm:text-sm">
                                    <select className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300" id="pickupProcessStat" name="pickupProcessStat" value={pickupMonitoring.pickupProcessStat} onChange={handleChange} >
                                        <option value="">Semua</option>
                                        <option value={masterSettingConstants.PICKUP_STAT_ACT}>Belum Selesai</option>
                                        <option value={masterSettingConstants.PICKUP_STAT_EXP}>Sudah Selesai</option>
                                        <option value={masterSettingConstants.PICKUP_STAT_RJC}>Tolak</option>
                                        <option value={masterSettingConstants.PICKUP_STAT_CAN}>Batal</option>
                                    </select>
                                </div>
                            </div>

                            <div className="flex justify-end mb-2 -mt-2">
                                <ShortcutDate onSubmit={getDate} />
                            </div>
                            <div className="flex justify-end mb-2 -mt-4">
                                <button type="submit" className="w-40 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600">
                                    Search
                                </button>
                            </div>
                        </div>
                    </form>
                    {submitted && pickupMonitoringState && (
                        <div className="justify-center pt-3">
                            <PickupMonitoringTable
                                sortConfig={sortConfig}
                                setSortConfig={setSortConfig}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="block sm:hidden w-full">
                <div className="bg-red-600 px-3">
                    <h4 className="flex justify-center text-xl font-semibold text-white py-2">
                        PICKUP MONITORING
                    </h4>
                </div>
                <form onSubmit={handleSearch} method="POST">
                    <div className="flex flex-col text-xs sm:text-sm px-3">
                        <div className="flex flex-row font-bold pt-2">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                Filter dari Tanggal
                            </div>
                            <div className="flex-1 ml-1 text-xs sm:text-sm flex items-center">
                                sampai Tanggal
                            </div>
                        </div>
                        <div className="flex flex-row mt-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                <input aria-label="Waktu Request Awal" id="trxDtFrom" name="trxDtFrom" type="date" value={pickupMonitoring.trxDtFrom} className={`py-1 px-2 ${submitted && !pickupMonitoring.trxDtFrom ? 'border-red-500' : 'border-gray-300'} text-xs sm:text-sm outline-none rounded-sm w-100`} onChange={handleChange} />
                            </div>
                            <div className="flex-1 ml-2 text-xs sm:text-sm flex items-center">
                                <input aria-label="Waktu Request Akhir" id="trxDtTo" name="trxDtTo" type="date" value={pickupMonitoring.trxDtTo} className={`py-1 px-2 ${submitted && !pickupMonitoring.trxDtTo ? 'border-red-500' : 'border-gray-300'} text-xs sm:text-sm outline-none rounded-sm w-100`} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                {
                                    submitted && !pickupMonitoring.trxDtFrom &&
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Harap isi waktu request awal
                                    </div>
                                }
                            </div>
                            <div className="flex-1 ml-2 text-xs sm:text-sm flex items-center">
                                {
                                    submitted && !pickupMonitoring.trxDtTo &&
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Harap isi waktu request akhir
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="flex flex-row font-bold mt-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                Status Proses Pickup
                            </div>
                            <div className="flex-1">
                            </div>
                        </div>
                        <div className="flex flex-row mt-1">
                            <div className="flex-1 text-xs sm:text-sm flex items-center">
                                <select className="py-1 px-2 text-xs sm:text-sm border-gray-300 outline-none rounded-sm w-100" id="pickupProcessStat" name="pickupProcessStat" value={pickupMonitoring.pickupProcessStat} onChange={handleChange}  >
                                    <option value="">Semua</option>
                                    <option value={masterSettingConstants.PICKUP_STAT_ACT}>Belum Selesai</option>
                                    <option value={masterSettingConstants.PICKUP_STAT_EXP}>Sudah Selesai</option>
                                    <option value={masterSettingConstants.PICKUP_STAT_RJC}>Tolak</option>
                                    <option value={masterSettingConstants.PICKUP_STAT_CAN}>Batal</option>
                                </select>
                            </div>
                            <div className="flex-1">
                            </div>
                        </div>
                    </div>
                    <ShortcutDate onSubmit={getDate} />
                    <div className="flex flex-col justify-center -mt-4">
                        <button type="submit" className="mx-3 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600">
                            Search
                        </button>
                    </div>
                </form>
                {submitted && pickupMonitoringState && (
                    <div className="justify-center pt-3">
                        <PickupMonitoringTable
                            sortConfig={sortConfig}
                            setSortConfig={setSortConfig}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
export default PickupMonitoring;
