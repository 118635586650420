import { customerConstants } from '../_constants';

let custObj = JSON.parse(localStorage.getItem('cust'));
let penerimaByHintObj = JSON.parse(localStorage.getItem('penerimaByHint'));
let penerimaObj = JSON.parse(localStorage.getItem('penerima'));

const initialStateCustObj = custObj ? { loggedIn: true, custObj: custObj.custObj } : {};
const initialStatePenerimaByHintObj = penerimaByHintObj ? { loggedIn: true, penerimaByHintObj: penerimaByHintObj.penerimaByHintObj } : {};
const initialStatePenerimaOj = penerimaObj ? { loggedIn: true, penerimaObj: penerimaObj.penerimaObj } : {};

export function custById(state = initialStateCustObj, action) {
    switch (action.type) {
        case customerConstants.GET_CUST_BY_ID_REQUEST:
            return {
                custObj: action.custObj.custObj
            };
        case customerConstants.GET_CUST_BY_ID_SUCCESS:
            return {
                custObj: action.custObj.custObj
            };
        case customerConstants.GET_CUST_BY_ID_FAILURE:
            return { errCustObj: action.error };
        case customerConstants.GET_CUST_BY_ID_CLEAR:
            return {};
        default:
            return state
    }
}

export function penerimaByHint(state = initialStatePenerimaByHintObj, action) {
    switch (action.type) {
        case customerConstants.GET_PENERIMA_BY_HINT_REQUEST:
            return {
                penerimaByHintObj: action.penerimaByHintObj.penerimaByHintObj
            };
        case customerConstants.GET_PENERIMA_BY_HINT_SUCCESS:
            return {
                penerimaByHintObj: action.penerimaByHintObj.penerimaByHintObj
            };
        case customerConstants.GET_PENERIMA_BY_HINT_FAILURE:
            return { errPenerimaByHintObj: action.error };
        case customerConstants.GET_PENERIMA_BY_HINT_CLEAR:
            return {};
        default:
            return state
    }
}

export function penerimaById(state = initialStatePenerimaOj, action) {
    switch (action.type) {
        case customerConstants.GET_PENERIMA_BY_ID_REQUEST:
            return {
                penerimaObj: action.penerimaObj.penerimaObj
            };
        case customerConstants.GET_PENERIMA_BY_ID_SUCCESS:
            return {
                penerimaObj: action.penerimaObj.penerimaObj
            };
        case customerConstants.GET_PENERIMA_BY_ID_FAILURE:
            return { errPenerimaObj: action.error };
        case customerConstants.GET_PENERIMA_BY_ID_CLEAR:
            return {};
        default:
            return state
    }
}