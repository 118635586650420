import { httpHelpers } from '../_helpers';
import { urlConstants } from '../_constants';

export const packagecontentService = {
    getPackageContent
};

function getPackageContent(packageCategory) {
    const packageContentObj = { PackageCategory: packageCategory };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(packageContentObj)
    };

    return fetch(urlConstants.URL_GET_PACKAGE_CONTENT, requestOptions)
        .then(httpHelpers.handleResponse, httpHelpers.handleError)
        .then(result => {
            console.log('result: ', result)
            if (result) {
                var _result = ({
                    packageContentObj: result.ObjectResult
                })
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('packageContentObj', JSON.stringify(_result));
                }
            }
            else if (result.statusCode != "200") {
                throw new Error(result.message);
            }
            return _result;
        });
}
