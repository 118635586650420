import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { bookingActions } from '../_actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { FaRegCopy, FaPen } from "react-icons/fa";

const TableDetailPenerima = ({ setArrResiNo, setIsEditMode }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const acc = useSelector((state) => state.authentication.accInfo);

    function openInNewTab(e, url) {
        e.preventDefault();
        e.stopPropagation();
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    }

    const [selectedDetailPenerimaMbl, setDetailPenerimaMbl] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const recipientListObj = useSelector((state) => state.recipientListBooking.recipientListObj);

    const selectAll = () => {
        if (!isAllSelected) {
            const allIds = recipientListObj.map((penerima) => penerima.ResiNo);
            setDetailPenerimaMbl(allIds);
            setArrResiNo(allIds);
        } else {
            setDetailPenerimaMbl([]);
            setArrResiNo([]);
        }
        setIsAllSelected(!isAllSelected);
    };

    const handleDetailPenerimaSelect = (ResiNo) => {
        if (selectedDetailPenerimaMbl.includes(ResiNo)) {
            setDetailPenerimaMbl(selectedDetailPenerimaMbl.filter((id) => id !== ResiNo));
            setArrResiNo(selectedDetailPenerimaMbl.filter((id) => id !== ResiNo));
        } else {
            setDetailPenerimaMbl([...selectedDetailPenerimaMbl, ResiNo]);
            setArrResiNo([...selectedDetailPenerimaMbl, ResiNo]);
        }
    };

    const isUserSelected = (ResiNo) => {
        return selectedDetailPenerimaMbl.includes(ResiNo);
    };

    function selectCustBookDetail(e) {
        getCustBookHDetail(e.CustBookHId)
    }

    function getCustBookHDetail(_trxNo) {
        if (acc && acc.CustId && _trxNo !== 0) {
            setIsEditMode(true);
            const custBookHDetailObj = {
                CustId: acc.CustId,
                CustBookHId: _trxNo
            };
            dispatch(bookingActions.getCustBookHDetail(custBookHDetailObj));
        }
    }

    function copyToClipboard(e, bookingNo) {
        e.stopPropagation();
        navigator.clipboard.writeText(bookingNo);
    }

    return (
        <>
            <div className="hidden sm:block w-full">
                <table className='table table-striped text-xs sm:text-sm bg-white'>
                    <thead className="font-bold">
                        <tr>
                            <td><input type="checkbox" checked={isAllSelected} onChange={selectAll} /></td>
                            <td>No</td>
                            <td>No Resi</td>
                            <td>Alias Pengirim</td>
                            <td>Info Penerima</td>
                            <td>Tujuan</td>
                            <td>Jalur - Pembayaran</td>
                            <td>Info Barang</td>
                            <td>Detail</td>
                            <td>Print</td>
                            <td align='center'>Edit</td>
                        </tr>
                    </thead>
                    <tbody>
                        {recipientListObj && recipientListObj.map((penerima, index) => (
                            <tr key={index}>
                                <td><input
                                    type="checkbox"
                                    checked={isUserSelected(penerima.ResiNo)}
                                    onChange={() => handleDetailPenerimaSelect(penerima.ResiNo)}
                                />
                                </td>
                                <td>
                                    {new Intl.NumberFormat("en", {
                                        minimumFractionDigits: 0
                                    }).format(index + 1)}
                                </td>
                                <td>
                                    {penerima.ResiNo}
                                </td>
                                <td>
                                    {penerima.AliasName}
                                </td>
                                <td>

                                    <p style={{ fontWeight: 'bold' }}>{penerima.RecipientName} </p>
                                    {penerima.RecipientAddr}
                                    <br />
                                    {penerima.RecipientMblPhoneNo}
                                </td>
                                <td>
                                    {penerima.DestDistrictName}
                                </td>
                                <td>
                                    {penerima.TransportType} - {penerima.PaymentType}
                                </td>
                                <td>
                                    {penerima.PackageCategory} - {penerima.PackageContentCode} {penerima.PackageContentNotes}
                                    <br />
                                    {penerima.TotalItem} Koli ({penerima.TotalFinalWeight} Kg)
                                </td>
                                <td>
                                    <Link target={"_blank"} to={{ pathname: `/booking-viewH?id=${penerima.CustBookHId}` }} style={{ color: '#337ab7' }} >
                                        Detail
                                    </Link>
                                </td>
                                <td>
                                    <Link target={"_blank"} to={{ pathname: `/booking-print?trxNo=${penerima.ResiNo}` }} style={{ color: '#337ab7' }}>
                                        Print label barang
                                    </Link>
                                </td>
                                <td>
                                    <Button onClick={() => { selectCustBookDetail(penerima); }}
                                        style={{
                                            color: '#337ab7',
                                            backgroundColor: 'transparent',
                                            borderColor: 'transparent',
                                            fontSize: 14,
                                            float: 'left'
                                        }}>Edit</Button>

                                    {/* <Link target={"_blank"} to={{ }} style={{ color: '#337ab7' }}>
                                    <span onClick={() => {selectCustBookDetail(penerima);}}
                                            >Edit</span>
                                    </Link> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="block sm:hidden w-full">
                {
                    recipientListObj && recipientListObj.map((penerima, index) =>
                        <div className="h-full w-full bg-white shadow flex rounded items-center my-2" key={index}>
                            <div className="relative h-full w-full flex items-center px-2 py-2">
                                <div className="text-sm font-medium w-full">
                                    <div className="flex flex-row px-2 py-1 items-center">
                                        <input
                                            type="checkbox"
                                            checked={isUserSelected(penerima.ResiNo)}
                                            onChange={() => { handleDetailPenerimaSelect(penerima.ResiNo) }}
                                        />
                                        <div className="flex items-center font-bold divr-1"
                                            onClick={() => { handleDetailPenerimaSelect(penerima.ResiNo) }}>&nbsp;{penerima.ResiNo}&nbsp;</div>
                                        <div className="flex items-center">
                                            <FaRegCopy
                                                onClick={(e) => copyToClipboard(e, penerima.ResiNo)}
                                                style={{ display: "inline-block" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="px-2 pt-1">
                                        <div className="flex flex-row">
                                            <div className="flex-1 text-xs sm:text-sm items-center font-bold">
                                                Alias Pengirim
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm items-center font-bold">
                                                Info Penerima
                                            </div>
                                        </div>
                                        <div className="flex flex-row">
                                            <div className="flex-1 text-xs sm:text-sm">
                                                {penerima.AliasName}
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm">
                                                {penerima.RecipientName}
                                                <br />
                                                {penerima.RecipientAddr}
                                                <br />
                                                {penerima.RecipientMblPhoneNo}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="px-2 pt-1">
                                        <div className="flex flex-row">
                                            <div className="flex-1 text-xs sm:text-sm items-center font-bold">
                                                Jalur - Pembayaran
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm items-center font-bold">
                                                Tujuan
                                            </div>
                                        </div>
                                        <div className="flex flex-row">
                                            <div className="flex-1 text-xs sm:text-sm">
                                                {penerima.TransportType} - {penerima.PaymentType}
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm">
                                                {penerima.DestDistrictName}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="px-2 pt-1">
                                        <div className="flex-1 text-xs sm:text-sm items-center font-bold">
                                            Info Barang
                                        </div>
                                        <div className="flex-1 text-xs sm:text-sm">
                                            {penerima.PackageCategory} - {penerima.PackageContentCode} {penerima.PackageContentNotes}
                                            <br />
                                            {penerima.TotalItem} Koli ({penerima.TotalFinalWeight} Kg)
                                        </div>
                                    </div>
                                    <br />
                                    <div className="px-2 pt-1 flex flex-row items-center justify-around">
                                        <button type="button" className="w-100 py-3 flex-grow mx-1 text-xs font-medium rounded-full text-blue-600 bg-white border-2 border-blue-600" onClick={(e) => openInNewTab(e, `/booking-viewH?id=${penerima.CustBookHId}`)}>
                                            Detail
                                        </button>
                                        <button type="button" className="w-100 py-3 flex-grow mx-1 text-xs font-medium rounded-full text-yellow-600 bg-white border-2 border-yellow-600" onClick={(e) => selectCustBookDetail(penerima)}>
                                            Edit
                                        </button>
                                        <button type="button" className="w-100 py-2 px-2 flex-grow mx-1 text-xs font-medium rounded-full text-green-600 bg-white border-2 border-green-600" onClick={(e) => openInNewTab(e, `/booking-download?trxNo=${penerima.ResiNo}`)}>
                                            Download Label Barang
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    !recipientListObj && (
                        <>
                            <div className="relative h-full w-full flex items-center text-center px-2 py-2">
                                <div className="text-sm font-medium w-full">
                                    <p>Tidak ada data</p></div>
                            </div>
                        </>
                    )
                }
            </div>
        </>
    );
}

export default TableDetailPenerima;
