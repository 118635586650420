import { bookingConstants } from '../_constants';

let bookingObj = JSON.parse(localStorage.getItem('bookingMonitor'));
let bookingDetailObj = JSON.parse(localStorage.getItem('bookingDetail'));
let bookingHObj = JSON.parse(localStorage.getItem('bookingHObj'));
let bookingDObj = JSON.parse(localStorage.getItem('bookingDObj'));
let calcBookingObj = JSON.parse(localStorage.getItem('calcBookingObj'));
let submitBookingObj = JSON.parse(localStorage.getItem('submitBookingObj'));
let printBookingObj = JSON.parse(localStorage.getItem('printBookingObj'));
let exportBookingDetailObj = JSON.parse(localStorage.getItem('exportBookingDetail'));
let uploadBookingObj = JSON.parse(localStorage.getItem('uploadBooking'));
let custBookHDetailObj = JSON.parse(localStorage.getItem('custBookHDetailObj'));
let cancelKoliBookingObj = JSON.parse(localStorage.getItem('cancelKoliBookingObj'));

const initialState = bookingObj ? { loggedIn: true, bookingObj: bookingObj.bookingObj } : {};
const initialStateDetail = bookingDetailObj ? { loggedIn: true, bookingDetailObj: bookingDetailObj.bookingDetailObj } : {};
const initialStateHObj = bookingHObj ? { loggedIn: true, bookingHObj: bookingHObj.bookingHObj } : {};
const initialStateDObj = bookingDObj ? { loggedIn: true, bookingDObj: bookingDObj.bookingDObj } : {};
const initialStateCalcObj = calcBookingObj ? { loggedIn: true, calcBookingObj: calcBookingObj.calcBookingObj } : {};
const initialStateSubmitBookingObj = submitBookingObj ? { loggedIn: true, submitBookingObj: submitBookingObj.submitBookingObj } : {};
const initialStatePrintBookingObj = printBookingObj ? { loggedIn: true, printBookingObj: printBookingObj.printBookingObj } : {};
const initialStateExportDetail = exportBookingDetailObj ? { loggedIn: true, exportBookingDetailObj: exportBookingDetailObj.exportBookingDetailObj } : {};
const initialStateUploadBooking = uploadBookingObj ? { loggedIn: true, uploadBookingObj: uploadBookingObj.uploadBookingObj } : {};
const initialStateCustBookHDetailObj = custBookHDetailObj ? { loggedIn: true, custBookHDetailObj: custBookHDetailObj.custBookHDetailObj } : {};
const initialStateCancelKoliBookingObj = cancelKoliBookingObj ? { loggedIn: true, cancelKoliBookingObj: cancelKoliBookingObj.cancelKoliBookingObj } : {};

export function bookings(state = initialState, action) {
    switch (action.type) {
        case bookingConstants.BOOKING_MONITOR_REQUEST:
            return {
                bookingObj: action.bookingObj.bookingObj
            };
        case bookingConstants.BOOKING_MONITOR_SUCCESS:
            return {
                bookingObj: action.bookingObj.bookingObj
            };
        case bookingConstants.BOOKING_MONITOR_FAILURE:
            return { errBookingMonitoringObj: action.error };
        case bookingConstants.BOOKING_MONITOR_CLEAR:
            return {};
        default:
            return state
    }
}

export function bookingDetails(state = initialStateDetail, action) {
    switch (action.type) {
        case bookingConstants.BOOKING_DETAIL_REQUEST:
            return {
                bookingDetailObj: action.bookingDetailObj.bookingDetailObj
            };
        case bookingConstants.BOOKING_DETAIL_SUCCESS:
            return {
                bookingDetailObj: action.bookingDetailObj.bookingDetailObj
            };
        case bookingConstants.BOOKING_DETAIL_FAILURE:
            return { errBookingDetailObj: action.error };
        case bookingConstants.BOOKING_DETAIL_CLEAR:
            return {};
        default:
            return state
    }
}

export function bookingViewH(state = initialStateHObj, action) {
    switch (action.type) {
        case bookingConstants.BOOKING_VIEW_H_REQUEST:
            return {
                bookingHObj: action.bookingHObj.bookingHObj
            };
        case bookingConstants.BOOKING_VIEW_H_SUCCESS:
            return {
                bookingHObj: action.bookingHObj.bookingHObj
            };
        case bookingConstants.BOOKING_VIEW_H_FAILURE:
            return {};
        default:
            return state
    }
}

export function bookingViewD(state = initialStateDObj, action) {
    switch (action.type) {
        case bookingConstants.BOOKING_VIEW_D_REQUEST:
            return {
                bookingDObj: action.bookingDObj.bookingDObj
            };
        case bookingConstants.BOOKING_VIEW_D_SUCCESS:
            return {
                bookingDObj: action.bookingDObj.bookingDObj
            };
        case bookingConstants.BOOKING_VIEW_D_FAILURE:
            return {};
        default:
            return state
    }
}

export function calculateBookingPrice(state = initialStateCalcObj, action) {
    switch (action.type) {
        case bookingConstants.CALCULATE_BOOKING_REQUEST:
            return {
                calcBookingObj: action.calcBookingObj.calcBookingObj
            };
        case bookingConstants.CALCULATE_BOOKING_SUCCESS:
            return {
                calcBookingObj: action.calcBookingObj.calcBookingObj
            };
        case bookingConstants.CALCULATE_BOOKING_FAILURE:
            return { errCalcBookingObj: action.error };
        case bookingConstants.CALCULATE_BOOKING_CLEAR:
            return {};
        default:
            return state
    }
}

export function submitBooking(state = initialStateSubmitBookingObj, action) {
    switch (action.type) {
        case bookingConstants.SUBMIT_BOOKING_REQUEST:
            return {
                submitBookingObj: action.submitBookingObj.submitBookingObj
            };
        case bookingConstants.SUBMIT_BOOKING_SUCCESS:
            return {
                submitBookingObj: action.submitBookingObj.submitBookingObj
            };
        case bookingConstants.SUBMIT_BOOKING_FAILURE:
            return { errSubmitBookingObj: action.error };
        case bookingConstants.SUBMIT_BOOKING_CLEAR:
            return {};
        default:
            return state
    }
}

export function printBookings(state = initialStatePrintBookingObj, action) {
    switch (action.type) {
        case bookingConstants.PRINT_BOOKING_REQUEST:
            return {
                printBookingObj: action.printBookingObj.printBookingObj
            };
        case bookingConstants.PRINT_BOOKING_SUCCESS:
            return {
                printBookingObj: action.printBookingObj.printBookingObj
            };
        case bookingConstants.PRINT_BOOKING_FAILURE:
            return {};
        case bookingConstants.PRINT_BOOKING_CLEAR:
            return {};
        default:
            return state
    }
}

export function exportBookingDetails(state = initialStateExportDetail, action) {
    switch (action.type) {
        case bookingConstants.EXPORT_BOOKING_DETAIL_REQUEST:
            return {
                exportBookingDetailObj: action.exportBookingDetailObj.exportBookingDetailObj
            };
        case bookingConstants.EXPORT_BOOKING_DETAIL_SUCCESS:
            return {
                exportBookingDetailObj: action.exportBookingDetailObj.exportBookingDetailObj
            };
        case bookingConstants.EXPORT_BOOKING_DETAIL_FAILURE:
            return { errExportBookingDetailObj: action.error };
        case bookingConstants.EXPORT_BOOKING_DETAIL_CLEAR:
            return {};
        default:
            return state
    }
}


export function uploadBooking(state = initialStateUploadBooking, action) {
    switch (action.type) {
        case bookingConstants.UPLOAD_BOOKING_REQUEST:
            return {
                uploadBookingObj: action.uploadBookingObj.uploadBookingObj
            };
        case bookingConstants.UPLOAD_BOOKING_SUCCESS:
            return {
                uploadBookingObj: action.uploadBookingObj.uploadBookingObj
            };
        case bookingConstants.UPLOAD_BOOKING_FAILURE:
            return { errUploadBookingObj: action.error };
        case bookingConstants.UPLOAD_BOOKING_CLEAR:
            return {};
        default:
            return state
    }
}

export function getCustBookHDetail(state = initialStateCustBookHDetailObj, action) {
    switch (action.type) {
        case bookingConstants.GET_CUST_H_DETAIL_REQUEST:
            return {
                custBookHDetailObj: action.custBookHDetailObj.custBookHDetailObj
            };
        case bookingConstants.GET_CUST_H_DETAIL_SUCCESS:
            return {
                custBookHDetailObj: action.custBookHDetailObj.custBookHDetailObj
            };
        case bookingConstants.GET_CUST_H_DETAIL_FAILURE:
            return { errCustBookHDetailObj: action.error };
        case bookingConstants.GET_CUST_H_DETAIL_CLEAR:
            return {};
        default:
            return state
    }
}

let recipientListObj = JSON.parse(localStorage.getItem("recipientListObj"));
const initialStateRecipientListObj = recipientListObj
    ? { loggedIn: true, recipientListObj: recipientListObj }
    : {};
export function recipientListBooking(state = initialStateRecipientListObj, action) {

    switch (action.type) {
        case bookingConstants.RECIPIENT_LIST_SUCCESS:

            return {
                recipientListObj: action.recipientListObj,
            };
        case bookingConstants.RECIPIENT_LIST_CLEAR:
            return {};
        default:
            return state;
    }
}

export function cancelKoliBooking(state = initialStateCancelKoliBookingObj, action) {
    switch (action.type) {
        case bookingConstants.CAN_KOLI_BOOKING_REQUEST:
            return {
                cancelKoliBookingObj: action.cancelKoliBookingObj.cancelKoliBookingObj
            };
        case bookingConstants.CAN_KOLI_BOOKING_SUCCESS:
            return {
                cancelKoliBookingObj: action.cancelKoliBookingObj.cancelKoliBookingObj
            };
        case bookingConstants.CAN_KOLI_BOOKING_FAILURE:
            return { errCancelKoliBookingObj: action.error };
        case bookingConstants.CAN_KOLI_BOOKING_CLEAR:
            return {};
        default:
            return state
    }
}