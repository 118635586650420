export const resiConstants = {
    RESI_INFO_GET_REQUEST: "RESI_INFO_GET_REQUEST",
    RESI_INFO_GET_SUCCESS: "RESI_INFO_GET_SUCCESS",
    RESI_INFO_GET_FAILURE: "RESI_INFO_GET_FAILURE",

    RESI_COMPLAINT_ADD_REQUEST: "RESI_COMPLAINT_ADD_REQUEST",
    RESI_COMPLAINT_ADD_SUCCESS: "RESI_COMPLAINT_ADD_SUCCESS",
    RESI_COMPLAINT_ADD_FAILURE: "RESI_COMPLAINT_ADD_FAILURE",

    RESI_COMPLAINT_GET_REQUEST: "RESI_COMPLAINT_GET_REQUEST",
    RESI_COMPLAINT_GET_SUCCESS: "RESI_COMPLAINT_GET_SUCCESS",
    RESI_COMPLAINT_GET_FAILURE: "RESI_COMPLAINT_GET_FAILURE",

    PRINT_RESI_REQUEST: 'PRINT_RESI_REQUEST',
    PRINT_RESI_SUCCESS: 'PRINT_RESI_SUCCESS',
    PRINT_RESI_FAILURE: 'PRINT_RESI_FAILURE',
    PRINT_RESI_CLEAR: 'PRINT_RESI_CLEAR',
    
    PRINT_RESI_BULKY_REQUEST: 'PRINT_RESI_BULKY_REQUEST',
    PRINT_RESI_BULKY_SUCCESS: 'PRINT_RESI_BULKY_SUCCESS',
    PRINT_RESI_BULKY_FAILURE: 'PRINT_RESI_BULKY_FAILURE',
    PRINT_RESI_BULKY_CLEAR: 'PRINT_RESI_BULKY_CLEAR',
};
