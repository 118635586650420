import { profileConstants } from "../_constants";

let profileDataObj = JSON.parse(localStorage.getItem("profileData"));
const initialStateProfileData = profileDataObj
  ? { loggedIn: true, profileData: profileDataObj.profileData }
  : {};

export function profileData(state = initialStateProfileData, action) {
  switch (action.type) {
    case profileConstants.PROFILE_GET_DATA_REQUEST:
      return {
        profileData: action.profileData.profileData,
      };
    case profileConstants.PROFILE_GET_DATA_SUCCESS:
      return {
        profileData: action.profileData.profileData,
      };
    case profileConstants.PROFILE_GET_DATA_FAILURE:
      return {};
    default:
      return state;
  }
}

let custPointHistTrxObj = JSON.parse(localStorage.getItem("custPointHistTrx"));
const initialStateCustPointHistTrx = custPointHistTrxObj
  ? { loggedIn: true, profileData: profileDataObj.profileData }
  : {};

export function custPointHistTrx(state = initialStateCustPointHistTrx, action) {
  switch (action.type) {
    case profileConstants.CUST_POINT_HIST_GET_REQUEST:
      return {
        custPointHistTrx: action.custPointHistTrx.custPointHistTrx,
      };
    case profileConstants.CUST_POINT_HIST_GET_SUCCESS:
      return {
        custPointHistTrx: action.custPointHistTrx.custPointHistTrx,
      };
    case profileConstants.CUST_POINT_HIST_GET_FAILURE:
      return {};
    default:
      return state;
  }
}


let custPointRedemptionTrxObj = JSON.parse(localStorage.getItem("custPointRedemptionTrx"));
const initialStateCustPointRedemptionTrx = custPointRedemptionTrxObj
  ? { loggedIn: true, profileData: profileDataObj.profileData }
  : {};

export function custPointRedemptionTrx(state = initialStateCustPointRedemptionTrx, action) {
  switch (action.type) {
    case profileConstants.CUST_POINT_REDEMPTION_GET_REQUEST:
      return {
        custPointRedemptionTrx: action.custPointRedemptionTrx.custPointRedemptionTrx,
      };
    case profileConstants.CUST_POINT_REDEMPTION_GET_SUCCESS:
      return {
        custPointRedemptionTrx: action.custPointRedemptionTrx.custPointRedemptionTrx,
      };
    case profileConstants.CUST_POINT_REDEMPTION_GET_FAILURE:
      return {};
    default:
      return state;
  }
}

let getQrCodeMemberObj = JSON.parse(localStorage.getItem("getQrCodeMemberObj"));
const initialStategetQrCodeMember = getQrCodeMemberObj
  ? { loggedIn: true, getQrCodeMemberObj: getQrCodeMemberObj.getQrCodeMemberObj }
  : {};

export function getQrCodeMember(state = initialStategetQrCodeMember, action) {
  switch (action.type) {
    case profileConstants.GET_QR_CODE_REQUEST:
      return {
        getQrCodeMemberObj: action.getQrCodeMemberObj.getQrCodeMemberObj,
      };
    case profileConstants.GET_QR_CODE_SUCCESS:
      return {
        getQrCodeMemberObj: action.getQrCodeMemberObj.getQrCodeMemberObj,
      };
    case profileConstants.GET_QR_CODE_FAILURE:
      return {};
    default:
      return state;
  }
}

let sendEmailQrCodeCustObj = JSON.parse(localStorage.getItem("sendEmailQrCodeCustObj"));
const initialStateSendEmailQrCodeCust = sendEmailQrCodeCustObj
  ? { loggedIn: true, sendEmailQrCodeCustObj: sendEmailQrCodeCustObj.sendEmailQrCodeCustObj }
  : {};

export function sendEmailQrCodeCust(state = initialStateSendEmailQrCodeCust, action) {
  switch (action.type) {
    case profileConstants.SEND_QR_CODE_REQUEST:
      return {
        sendEmailQrCodeCustObj: action.sendEmailQrCodeCustObj.sendEmailQrCodeCustObj,
      };
    case profileConstants.SEND_QR_CODE_SUCCESS:
      return {
        sendEmailQrCodeCustObj: action.sendEmailQrCodeCustObj.sendEmailQrCodeCustObj,
      };
    case profileConstants.SEND_QR_CODE_FAILURE:
      return  { errSendEmailQrCodeCustObj: action.error };
    default:
      return state;
  }
}