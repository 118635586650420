import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { accActions, pickupActions } from "../../_actions";
import scLogo from "../../assets/NewSentralCargo_320.png";
import indonesia_1280 from "../../assets/indonesia_1280.png"
import { AlertMessage } from "../../components";
import { history } from "../../_helpers";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const Login = ({ t }) => {
  const dispatch = useDispatch();
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const [accLogin, setAccLogin] = useState({
    username: "",
    password: "",
    partnerCode: "",
  });
  const [custId, setCustId] = useState(null);
  const [username, setUsername] = useState(null);

  const [alert, setAlert] = useState({
    Type: "",
    StatusMessage: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const errLoginObj = useSelector((state) => state.authentication.errLoginObj);
  const accInfo = useSelector((state) => state.authentication.accInfo);
  const [icon, setIcon] = useState("");
  const [isVisible, setVisible] = useState(false);
  const [type, setType] = useState("password");

  const toggle = () => {
    setVisible(!isVisible);
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  let _custId = "";
  let _username = "";
  useEffect(() => {
    dispatch(accActions.clearResetPassword());
    const fetchData = async () => {
      try {
        _custId = query.get("K");
        _username = query.get("U");
        return Promise.resolve();
      } catch (error) {
        return Promise.reject();
      }
    };

    fetchData().then(() => {
      try {
        dispatch(accActions.clearResetPassword());
        if (_custId && _username) {
          dispatch(
            accActions.impersonateLogin({
              CustId: _custId,
              Username: _username,
            })
          );
        }
      } catch (error) {
        console.error("Dispatch error:", error);
      }
    });
  }, [custId, username]);

  useEffect(() => {
    if (errLoginObj && errLoginObj.StatusCode !== "200") {
      setIsError(true);
      setAlert({ Type: "Error", StatusMessage: errLoginObj.StatusMessage });
    }
  }, [errLoginObj]);

  useEffect(() => {
    console.log("acc", accInfo);
    if (accInfo != undefined) {
      console.log("****ACCINFO****", accInfo);
      if (accInfo.IsNeedChangePassword) {
        history.push("/reset-password-form");
      } else {
        if (accInfo.IsSubAccount && accInfo.IsSubAccount === true) {
          selectPickupReq(accInfo.PortalPickupReqGrps[0]);
        }
        history.push("/Home");
      }
    }
  }, [accInfo]);

  function handleChange(e) {
    const { name, value } = e.target;
    setAccLogin((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    if (accLogin.username && accLogin.password) {
      dispatch(accActions.login(accLogin.username, accLogin.password));
    }
  }

  useEffect(() => {
    if (errLoginObj && errLoginObj.StatusCode !== "200") {
      console.log(errLoginObj);
      setIsError(true);
      setAlert({ Type: "Error", StatusMessage: errLoginObj.StatusMessage });
    }
  }, [errLoginObj]);

  useEffect(() => {
    if (accInfo) {
      if (accInfo.IsNeedChangePassword) {
        history.push("/reset-password-form");
      } else {
        if (accInfo.IsSubAccount && accInfo.IsSubAccount === true) {
          selectPickupReq(accInfo.PortalPickupReqGrps[0]);
        }
        history.push("/Home");
      }
    }
  }, [accInfo]);

  useEffect(() => {
    dispatch(accActions.clearResetPassword());
  }, []);

  function selectPickupReq(e) {
    dispatch(pickupActions.getSelectedPickupAddr(e));
  }

  const myStyle = {
    backgroundImage: `url(${indonesia_1280})`,
    height: "100vh",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "repeat-y",
};

const myStyleMobile = {
  backgroundImage: `url(${indonesia_1280})`,
  height: "100vh",
  backgroundSize: "cover",
  backgroundRepeat: "repeat-y",
};
  return (
    <form onSubmit={handleSubmit} method="POST">
      <div className={`hidden sm:block`} style={myStyle}>
        <div
          className={`flex items-center justify-center py-12 px-8`}
          style={{ height: "100vh" }}
        >
          <div
            className="max-w-sm w-full bg-white border-2 border-red-400"
            style={{ borderRadius: 10 }}
          >
            <div className="sm:mt-6">
              <input type="hidden" name="remember" value="true" />
              <div className="text-xs sm:text-sm">
                <div className="-mt-px">
                  <div className="flex justify-center">
                    <img src={scLogo} />
                  </div>
                </div>
                <div
                  className="block"
                  style={{
                    fontSize: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  Customer Portal
                </div>
                <div className="-mt-px mx-4 mt-2 mb-4">
                  {isError == true && errLoginObj && (
                    <AlertMessage
                      isError={isError}
                      alert={alert}
                      setAlert={setAlert}
                      setIsError={setIsError}
                    />
                  )}
                  <div className="-mt-px">
                    <div className="block text-xs sm:text-sm font-small leading-5">
                      Username:
                    </div>
                  </div>
                  <div className="-mt-px">
                    <input
                      aria-label="Username"
                      id="username"
                      name="username"
                      type="text"
                      value={accLogin.username}
                      onChange={handleChange}
                      className={`appearance-none rounded-none relative block w-full text-xs sm:text-sm px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 text-sm sm:leading-5 ${
                        submitted && !accLogin.username && "border-b-2"
                      }`}
                      placeholder="username"
                    />
                    {submitted && !accLogin.username && (
                      <div className="block text-xs sm:text-sm font-small leading-5 text-red-500">
                        Username Required
                      </div>
                    )}
                  </div>
                  <div className="-mt-px mt-1">
                    <div className="block text-xs sm:text-sm font-small leading-5">
                      Password:
                    </div>
                  </div>
                  <div className="-mt-px flex">
                    <input
                      aria-label="Password"
                      id="password"
                      name="password"
                      type={type}
                      value={accLogin.password}
                      onChange={handleChange}
                      className={`appearance-none rounded-none relative block w-full text-xs sm:text-sm px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 -mr-4 text-sm sm:leading-5 ${
                        submitted && !accLogin.password && "border-t-2"
                      }`}
                      placeholder="password"
                    />
                    <span className="flex justify-around items-center">
                      {!isVisible ? (
                        <FaRegEye className="absolute mr-10" onClick={toggle} />
                      ) : (
                        <FaRegEyeSlash
                          className="absolute mr-10"
                          onClick={toggle}
                        />
                      )}
                    </span>
                    {submitted && !accLogin.password && (
                      <div className="block text-xs sm:text-sm font-small leading-5 text-red-500">
                        Password Required
                      </div>
                    )}
                  </div>

                  <div className="mt-2 sm:mt-4">
                    <button
                      type="submit"
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <svg
                          className="h-5 w-5 text-white group-hover:text-white transition ease-in-out duration-150"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`block sm:hidden flex items-center justify-center`}
        style={myStyleMobile}
      >
        <input type="hidden" name="remember" value="true" />
        <div
          className="text-xs sm:text-sm bg-white border-2 border-red-400 mx-4"
          style={{ borderRadius: 15 }}
        >
          <div className="-mt-px mt-2">
            <div className="flex justify-center">
              <img src={scLogo} />
            </div>
          </div>
          <div className="justify-center py-2 px-4 flex flex-row">
            <h1 className="font-bold text-xl">Customer Portal</h1>
          </div>
          <div className="-mt-px mx-4 my-2">
            {isError == true && errLoginObj && (
              <AlertMessage
                isError={isError}
                alert={alert}
                setAlert={setAlert}
                setIsError={setIsError}
              />
            )}
            <div className="-mt-px">
              <div className="block text-xs sm:text-sm font-small leading-5">
                Username:
              </div>
            </div>
            <div className="-mt-px">
              <input
                aria-label="Username"
                id="username"
                name="username"
                type="text"
                value={accLogin.username}
                onChange={handleChange}
                className={`appearance-none rounded-none relative block w-full text-xs sm:text-sm px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 text-sm sm:leading-5 ${
                  submitted && !accLogin.username && "border-b-2"
                }`}
                placeholder="username"
              />
              {submitted && !accLogin.username && (
                <div className="block text-xs sm:text-sm font-small leading-5 text-red-500">
                  Username Required
                </div>
              )}
            </div>
            <div className="-mt-px mt-1">
              <div className="block text-xs sm:text-sm font-small leading-5">
                Password:
              </div>
            </div>
            <div className="-mt-px flex">
              <input
                aria-label="Password"
                id="password"
                name="password"
                type={type}
                value={accLogin.password}
                onChange={handleChange}
                className={`appearance-none rounded-none relative block w-full text-xs sm:text-sm px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md -mr-4 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 text-sm sm:leading-5 ${
                  submitted && !accLogin.password && "border-t-2"
                }`}
                placeholder="password"
              />
              <span className="flex justify-around items-center">
                {!isVisible ? (
                  <FaRegEye className="absolute mr-10" onClick={toggle} />
                ) : (
                  <FaRegEyeSlash className="absolute mr-10" onClick={toggle} />
                )}
              </span>
              {submitted && !accLogin.password && (
                <div className="block text-xs sm:text-sm font-small leading-5 text-red-500">
                  Password Required
                </div>
              )}
            </div>
          </div>
          <div className="mt-2 sm:mt-4 m-4">
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg
                  className="h-5 w-5 text-white group-hover:text-white transition ease-in-out duration-150"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Login
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
export default Login;
