import { transportTypeConstants } from '../_constants';

let transportTypeObj = JSON.parse(localStorage.getItem('transportTypeObj'));;
const initialState = transportTypeObj ? { transportTypeObj: transportTypeObj.transportTypeObj } : {};

export function transportType(state = initialState, action) {
    switch (action.type) {
        case transportTypeConstants.GET_TRANSPORT_TYPE_REQUEST:
            return {
                transportTypeObj: action.transportTypeObj.transportTypeObj
            };
        case transportTypeConstants.GET_TRANSPORT_TYPE_SUCCESS:
            return {
                transportTypeObj: action.transportTypeObj.transportTypeObj
            };
        case transportTypeConstants.GET_TRANSPORT_TYPE_FAILURE:
            return { errTransportTypeObj: action.error };
        case transportTypeConstants.GET_TRANSPORT_TYPE_CLEAR:
            return {};
        default:
            return state
    }
}
