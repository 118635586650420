import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FaAddressBook } from "react-icons/fa";
import "../styles/tailwind.css";
import { useDispatch, useSelector } from 'react-redux';
import { pickupActions } from '../_actions';
import { history } from '../_helpers';
import { convertToObject } from "typescript";

const PickupAddressListTable = ({ t }) => {
    const dispatch = useDispatch();
    const acc = useSelector((state) => state.authentication.accInfo);
    const pickupAddrListObj = useSelector((state) => state.pickupAddrList.pickupAddrListObj);

    function selectPickupReq(e) {
        dispatch(pickupActions.getSelectedPickupAddr(e));
    }

    return (
        <div className="text-xs sm:text-sm">
            <div className="hidden sm:block">
                <table
                    className="table table-striped table-fixed border border-2 bg-white"
                    aria-labelledby="tabelLabel"
                >
                    <thead>
                        <tr>
                            <th>
                                Nama Alamat
                            </th>
                            <th>
                                Alamat
                            </th>
                            <th>
                                Kecamatan
                            </th>

                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {pickupAddrListObj && pickupAddrListObj.length > 0 && pickupAddrListObj.map((pa) => (
                            <tr key={pa.PickupReqGrpId}>
                                <td>{pa.AddrType}</td>
                                <td>{pa.Addr}</td>
                                <td>{pa.Kecamatan}</td>
                                <td>
                                    <Link to="" onClick={(e) => selectPickupReq(pa)} style={{ color: '#337ab7' }}>
                                        Pilih
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="block sm:hidden">
                {pickupAddrListObj && pickupAddrListObj.length > 0 && pickupAddrListObj.map((pa) => (
                    <div className="h-full w-full bg-white shadow flex rounded items-center my-2"
                        key={pa.PickupReqGrpId}
                        onClick={(e) => {
                            selectPickupReq(pa);
                            history.push("/Home");
                        }}>
                        <div className="relative h-full w-full flex items-center px-2 py-2">
                            <div className="flex flex-col px-2 py-1">
                                <div className="flex-1 font-bold">
                                    {pa.AddrType}
                                </div>
                                <div className="flex-1">
                                    {pa.Addr}
                                </div>
                                <div className="flex-1">
                                    {pa.Kecamatan}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PickupAddressListTable;
