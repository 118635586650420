import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import "../styles/tailwind.css";

const PickupMonitoringTable = ({ sortConfig, setSortConfig }) => {
  const pickupMonitoring = useSelector(
    (state) => state.pickupMonitoring.pickupMonitoring
  );

  const history = useHistory();

  function handleBatal(pickupMonitoringObj) {
    history.push(
      `/PickupOrderCancelForm?pickupOrderHId=${pickupMonitoringObj.PickupOrderHId}`
    );
  }

  function sort(key) {
    let direction = "ASC";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ASC"
    ) {
      direction = "DESC";
    }
    setSortConfig({ key, direction });
  }

  return (
    <>
      {pickupMonitoring &&
        pickupMonitoring.Result != null &&
        pickupMonitoring.Result.length > 0 && (
          <>
            <div className="hidden sm:block text-xs sm:text-sm">
              <table
                className="table table-striped table-fixed bg-white"
                aria-labelledby="tabelLabel"
              >
                <thead>
                  <tr>
                    <th>
                      <button
                        type="button"
                        color="blue"
                        onClick={() => sort("PickupDt")}
                        className="font-bold"
                      >
                        Waktu Pickup
                      </button>
                    </th>
                    <th className="w-12">
                      <button
                        type="button"
                        onClick={() => sort("GrpType")}
                        className="font-bold"
                      >
                        Jenis
                      </button>
                    </th>
                    <th className="w-14">
                      <button
                        type="button"
                        onClick={() => sort("PickupORderHStatDesc")}
                        className="font-bold"
                      >
                        Status
                      </button>
                    </th>
                    <th className="w-8"></th>
                    <th>
                      <button
                        type="button"
                        onClick={() => sort("ReqCount")}
                        className="font-bold"
                      >
                        Total Request
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => sort("OrderBy")}
                        className="font-bold"
                      >
                        Diassign Oleh
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => sort("OfficeCode")}
                        className="font-bold"
                      >
                        PIC Kantor
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => sort("EmployeeName")}
                        className="font-bold"
                      >
                        Driver
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => sort("ReadByEmMblDt")}
                        className="font-bold"
                      >
                        Dibaca Driver
                      </button>
                    </th>
                    <th>Tujuan Pickup</th>
                    <th>Alamat Pickup</th>
                    <th>Detail</th>
                    <th>Batal</th>
                  </tr>
                </thead>
                <tbody>
                  {pickupMonitoring.Result.map((pm) => (
                    <tr key={pm.PickupOrderHId}>
                      <td>{moment(pm.PickupDt).format("DD-MM-YY HH:mm")}</td>
                      <td>{pm.GrpType}</td>
                      <td>{pm.PickupORderHStatDesc}</td>
                      <td align="center">
                        <span
                          className={
                            moment(pm.PickupDt).isSame(new Date())
                              ? "circle-black"
                              : moment(
                                  moment(pm.PickupDt).subtract(30, "minutes")
                                ).isSame(new Date())
                              ? "circle-red"
                              : moment(
                                  moment(pm.PickupDt).subtract(60, "minutes")
                                ).isSame(new Date())
                              ? "circle-yellow"
                              : "circle-green"
                          }
                        ></span>
                      </td>
                      <td align="center">{pm.ReqCount.toLocaleString("en", {
                          minimumFractionDigits: 0,
                        })}</td>
                      <td>{`${pm.OrderBy} [${moment(pm.OrderDt).format(
                        "DD-MM-YY HH:mm"
                      )}]`}</td>
                      <td>{pm.OfficeCode}</td>
                      <td>{`${pm.EmployeeName} - ${pm.EmpMobilePhoneNo}`}</td>
                      <td>
                        {`${
                          pm.IsReadByEmpMbl === null
                            ? "Belum"
                            : pm.IsReadByEmpMbl === "1"
                            ? ""
                            : "Belum"
                        } `}
                        {`${
                          pm.ReadByEmMblDt === null
                            ? ""
                            : moment(pm.ReadByEmMblDt).format("DD-MM-YY HH:mm")
                        }`}
                      </td>
                      <td className="whitespace-normal break-words">
                        {pm.GrpName}
                      </td>
                      <td>{pm.Addr}</td>
                      <td className="text-sky-600">
                        <Link
                          target={"_blank"}
                          to={{
                            pathname: `/PickupOrderDetail?pickupOrderHId=${pm.PickupOrderHId}`,
                          }}
                        >
                          Detail
                        </Link>
                      </td>
                      <td className="text-sky-600">
                        <button
                          type="button"
                          onClick={() => handleBatal(pm)}
                          style={{
                            visibility:
                              pm.PickupOrderHStat === "ACT"
                                ? "visible"
                                : pm.PickupOrderHStat === "INP"
                                ? "visible"
                                : pm.PickupOrderHStat === "OTW"
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          Batal
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="block sm:hidden">
              <div className="rounded px-3">
                {pickupMonitoring.Result.map((pm) => (
                  <div
                    className="h-full w-full bg-white shadow flex rounded items-center my-2"
                    key={pm.PickupOrderHId}
                  >
                    <div className="relative h-full w-full flex items-center">
                      <div className="text-sm font-medium w-full pb-1">
                        <div className="flex float-right pt-2 mr-4">
                          <span
                            className={`w-2.5 h-2.5 mt-1 rounded-full items-center ${
                              pm.PickupOrderHStat === "ACT"
                                ? "bg-green-600"
                                : "bg-red-600"
                            }`}
                          ></span>
                          <span
                            className={`font-semibold text-xs sm:text-sm ${
                              pm.PickupOrderHStat === "ACT"
                                ? "text-green-600"
                                : "text-red-600"
                            }`}
                          >
                            {pm.PickupORderHStatDesc}
                          </span>
                        </div>
                        <div className="flex text-red-500 px-3">
                          <div className="flex-1 text-xs sm:text-sm flex items-end">
                            {moment(pm.PickupDt).format("DD-MM-YY HH:mm")}
                          </div>
                        </div>
                        <div className="flex text-red-500 px-3">
                          <div className="flex-1 text-xs sm:text-sm flex items-end">
                            {pm.GrpType}
                          </div>
                        </div>
                        <div className="flex text-red-500 px-3">
                          <div className="flex-1 text-xs sm:text-sm flex items-end">
                            {pm.ReqCount.toLocaleString("en", {
                          minimumFractionDigits: 0,
                        })}
                          </div>
                        </div>
                        <div className="flex px-3">
                          <div className="flex-1 text-xs sm:text-sm flex items-end">
                            {pm.OfficeCode}
                          </div>
                        </div>
                      </div>
                      <span
                        className={`absolute right-0 h-full rounded-r w-3 ${
                          pm.PickupOrderHStat === "ACT"
                            ? "bg-green-600"
                            : "bg-red-600"
                        }`}
                      ></span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default PickupMonitoringTable;
