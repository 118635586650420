export const accConstants = {
    LOGIN_REQUEST: 'ACC_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'ACC_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'ACC_LOGIN_FAILURE',
    IMPERSONATE_LOGIN_REQUEST: 'ACC_IMPERSONATE_LOGIN_REQUEST',
    IMPERSONATE_LOGIN_SUCCESS: 'ACC_IMPERSONATE_LOGIN_SUCCESS',
    IMPERSONATE_LOGIN_FAILURE: 'ACC_IMPERSONATE_LOGIN_FAILURE',
    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
    RESET_PASSWORD_CLEAR: 'RESET_PASSWORD_CLEAR',

    LOGOUT: 'ACC_LOGOUT'
};