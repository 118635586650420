import { destinationConstants } from '../_constants';

let destObj = JSON.parse(localStorage.getItem('destObj'));;
const initialState = destObj ? { loggedIn: true, destObj: destObj.destObj } : {};

export function destination(state = initialState, action) {
    switch (action.type) {
        case destinationConstants.GET_DESTINATION_REQUEST:
            return {
            };
        case destinationConstants.GET_DESTINATION_SUCCESS:
            return {
                destObj: action.destObj.destObj
            };
        case destinationConstants.GET_DESTINATION_FAILURE:
            return {};
        default:
            return state
    }
}
