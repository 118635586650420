import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accActions, pickupActions } from '../../_actions';
import scLogo from '../../assets/NewSentralCargo_320.png';
import { AlertMessage } from '../../components';
import { history } from '../../_helpers';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const ResetPasswordFrom = ({ t }) => {
    const dispatch = useDispatch()
    const acc = useSelector((state) => state.authentication.accInfo);
    const [accLogin, setAccLogin] = useState({
        newpassword: '',
        confirmpassword: '',
    });
    const [alert, setAlert] = useState({
        Type: '',
        StatusMessage: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const errPwdObj = useSelector(state => state.resetPassword.errPwdObj);
    const pwdObj = useSelector(state => state.resetPassword.pwdObj);
    const [isVisible, setVisible] = useState(false);
    const [type, setType] = useState('password');
    const [isVisibleConfirm, setVisibleConfirm] = useState(false);
    const [typeConfirm, setTypeConfirm] = useState('password');

    const toggle = () => {
        setVisible(!isVisible);
        if (type === 'password'){
            setType('text')
         } else {
            setType('password')
         }
    };
    const toggleConfirm = () => {
        setVisibleConfirm(!isVisibleConfirm);
        if (typeConfirm === 'password'){
            setTypeConfirm('text')
         } else {
            setTypeConfirm('password')
         }
    };

    function handleChange(e) {
        const { name, value } = e.target;
        setAccLogin(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (accLogin.newpassword && accLogin.confirmpassword) {
            if (accLogin.newpassword !== accLogin.confirmpassword) {
                setIsError(true);
                setAlert(
                    { Type: "Error", StatusMessage: "Password Baru & Konfirmasi Password Tidak Sama" }
                );
            }
            else {
                let isStrong = validate_password(accLogin.newpassword);
                if (isStrong) {
                    dispatch(accActions.resetPassword(acc.Username, accLogin.newpassword, acc.IsSubAccount));
                }
                else {
                    setIsError(true);
                    setAlert(
                        { Type: "Error", StatusMessage: "Password minimal 8 karakter, kombinasi huruf besar dan kecil, angka dan karakter" }
                    );
                }
            }
        }
    }

    useEffect(() => {
        if (errPwdObj && errPwdObj.StatusCode !== "200") {
            setIsError(true);
            setAlert({ Type: "Error", StatusMessage: errPwdObj.StatusMessage })
        }

    }, [errPwdObj])

    useEffect(() => {
        if (pwdObj && pwdObj.pwdObj && pwdObj.pwdObj.Username) {            
            if (acc.IsSubAccount && acc.IsSubAccount === true) {
                selectPickupReq(acc.PortalPickupReqGrps[0]);
            }
            history.push('/Home');
        }

    }, [pwdObj])

    function validate_password(password) {
        let check = /^(?=.*[a-z])(?=.*\d)(?=.*[@#$%.^&+=!*]).{8,}$/;
        if (password.match(check)) {
            console.log("MATCH")
            return true
        } else {
            console.log("GAK MATCH")
            return false
        }
    }
    
    function selectPickupReq(e) {
        dispatch(pickupActions.getSelectedPickupAddr(e));
    }

    return (
        <form onSubmit={handleSubmit} method="POST">
            <div className={`min-h-screen flex items-center justify-center py-12 px-8 bg-gray-50`}>
                <div className="max-w-md w-full">
                    <div className="sm:mt-6">
                        <input type="hidden" name="remember" value="true" />
                        <div className="text-xs sm:text-sm">
                            <div className="-mt-px">
                                <div className="flex justify-center">
                                    <img
                                        src={scLogo}
                                    />
                                </div>
                            </div>
                            <div className="justify-center py-2 px-4 flex flex-row">
                                <h1 className="font-bold text-xl">Reset Password</h1>
                            </div>
                            {
                                isError == true && 
                                <AlertMessage isError={isError} alert={alert} setAlert={setAlert} setIsError={setIsError} />
                            }
                            <div className="-mt-px flex">
                                <input aria-label="Password Baru" id="newpassword" name="newpassword" type={type} value={accLogin.newpassword} onChange={handleChange} className={`appearance-none rounded-none relative block w-full text-xs sm:text-sm px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md -mr-4 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 text-sm sm:leading-5 ${submitted && !accLogin.newpassword && 'border-t-2'}`} placeholder='Password Baru' />
                                <span className="flex justify-around items-center">
                                    {
                                        !isVisible ? <FaRegEye className='absolute mr-10' onClick={toggle}/> : <FaRegEyeSlash className='absolute mr-10' onClick={toggle}/>}
                                </span>
                            </div>
                            {
                                submitted && !accLogin.newpassword &&
                                <div className="block text-xs sm:text-sm font-small leading-5 text-red-500">Password Required</div>
                            }
                            {
                                submitted && accLogin.newpassword != accLogin.confirmpassword &&
                                <div className="block text-xs sm:text-sm font-small leading-5 text-red-500">Password Baru & Konfirmasi Password Tidak Sama</div>
                            }
                            <div className="-mt-px flex">
                                <input aria-label="Konfirmasi Password Baru" id="confirmpassword" name="confirmpassword" type={typeConfirm} value={accLogin.confirmpassword} onChange={handleChange} className={`appearance-none rounded-none relative block w-full text-xs sm:text-sm px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 -mr-4 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 text-sm sm:leading-5 ${submitted && !accLogin.confirmpassword && 'border-t-2'}`} placeholder='Konfirmasi Password Baru' />
                                <span className="flex justify-around items-center">
                                    {
                                        !isVisibleConfirm ? <FaRegEye className='absolute mr-10' onClick={toggleConfirm}/> : <FaRegEyeSlash className='absolute mr-10' onClick={toggleConfirm}/>}
                                </span>
                            </div>
                                {
                                    submitted && !accLogin.confirmpassword &&
                                    <div className="block text-xs sm:text-sm font-small leading-5 text-red-500">Konfirmasi Password Required</div>
                                }
                                {
                                    submitted && accLogin.newpassword != accLogin.confirmpassword &&
                                    <div className="block text-xs sm:text-sm font-small leading-5 text-red-500">Password Baru & Konfirmasi Password Tidak Sama</div>
                                }
                        </div>
                        <div className="mt-2 sm:mt-4">
                            <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20">
                                        <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                Reset Password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
export default ResetPasswordFrom;