import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertMessage } from '../../components';
import { pickupActions } from '../../_actions';
import { useLocation, useHistory } from "react-router-dom";
import Select, { createFilter } from "react-select";
import { isValidPhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css'
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import background from "../../assets/bg.png";

const PickupAddressForm = ({ t }) => {
    const dispatch = useDispatch();
    const acc = useSelector((state) => state.authentication.accInfo);
    const pickupAddressTypeObj = useSelector(state => state.pickupAddrById.pickupAddrByIdObj);
    const pickupAddEditObj = useSelector(state => state.pickupReqGrpAddEdit.pickupReqGrpObj);
    const errPickupReqAddEditGrpObj = useSelector(state => state.pickupReqGrpAddEdit.errPickupReqGrpObj);
    const districtObj = useSelector(state => state.district.districtObj);
    const [options, setOptions] = useState([]);
    const [selectedPckDist, setSelectedPckDist] = useState();
    const [phoneNo, setPhoneNo] = useState('');
    const [modalShow, setModalShow] = useState(false);

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();
    const _addrType = query.get("AddrType");

    const [alert, setAlert] = useState({
        Type: '',
        StatusMessage: ''
    });

    const [pickupAddr, setPickupAddr] = useState({
        ContactPerson: '',
        PhoneNo: '',
        PickupReqGrpStat: 'ACT',
        PickupReqGrpId: 0,
        AddrType: '',
        Addr: '',
        CustId: acc ? acc.CustId : 0,
        CustName: acc ? acc.CustName : '',
        GrpType: 'CUS'
    });

    const [submitted, setSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'AddrType', direction: 'ASC' });
    const history = useHistory();

    function handleChange(e) {
        const { name, value } = e.target;
        setPickupAddr((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    function getPickupAddrType() {
        if (acc && acc.CustId) {
            const pickupAddrTypeObj = {
                CustId: acc.CustId,
                AddrType: _addrType,
                OrderBy: sortConfig,
            };
            dispatch(pickupActions.getPickupAddrById(pickupAddrTypeObj));
        }
    }

    useEffect(() => {

        if (acc && acc.IsSubAccount == true) {
            history.push("/Home");
        }
        setSubmitted(false);
        setModalShow(false);
        dispatch(pickupActions.clearPickupAddrById());
        dispatch(pickupActions.clearAddEditPickupReqGrp());
        clearData();
        if (_addrType != undefined) {
            getPickupAddrType();
        }
    }, []);


    useEffect(() => {
        if (pickupAddressTypeObj && pickupAddressTypeObj.Result) {
            setSelectedPckDist({ value: pickupAddressTypeObj.Result[0].DistrictId, label: pickupAddressTypeObj.Result[0].DistrictName });

            let PhoneNo = pickupAddressTypeObj.Result[0].PhoneNo;
            if (PhoneNo && PhoneNo.length > 1) {
                var phone = PhoneNo.substring(1, 20);
                var area = PhoneNo.substring(0, 1);
                if (area === "0") {
                    PhoneNo = "+62" + phone;
                }
                setPhoneNo(PhoneNo);
            }

            setPickupAddr({
                ContactPerson: pickupAddressTypeObj.Result[0].ContactPerson,
                PhoneNo: pickupAddressTypeObj.Result[0].PhoneNo,
                PickupReqGrpStat: pickupAddressTypeObj.Result[0].PickupReqGrpStat,
                DistrictId: pickupAddressTypeObj.Result[0].DistrictId,
                DistrictName: pickupAddressTypeObj.Result[0].DistrictName,
                PickupReqGrpId: pickupAddressTypeObj.Result[0].PickupReqGrpId,
                AddrType: pickupAddressTypeObj.Result[0].AddrType,
                Addr: pickupAddressTypeObj.Result[0].Addr,
                CustId: acc ? acc.CustId : 0,
                CustName: acc ? acc.CustName : '',
                GrpType: 'CUS'
            })
        }
    }, [pickupAddressTypeObj]);


    useEffect(() => {
        districtObj && setOptions(districtObj.map((dist, i) => ({ value: dist.DistrictId, label: dist.DistrictDescr })));
    }, [districtObj]);


    const handleChangeDistrictPickup = (selectedOption) => {
        setSelectedPckDist(selectedOption);
    };

    const style = {
        control: (base, state) => ({
            ...base,
            border: '1px solid rgba(209, 213, 219, var(--tw-border-opacity))',
            boxShadow: 'none',
            '&:hover': {
                border: '1px solid rgba(209, 213, 219, var(--tw-border-opacity))',
            }
        })
    };
    const styleMobile = {
        control: (base, state) => ({
            ...base,
            border: 'none',
            boxShadow: 'none',
            '&:hover': {
                border: 'none',
            }
        })
    };

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (pickupAddr.AddrType && (selectedPckDist || selectedPckDist.value > 0) && pickupAddr.Addr && pickupAddr.ContactPerson && phoneNo && acc) {
            const pickupAddrTypeObj = {
                ContactPerson: pickupAddr.ContactPerson,
                PhoneNo: phoneNo,
                PickupReqGrpStat: pickupAddr.PickupReqGrpStat,
                DistrictId: selectedPckDist.value,
                DistrictName: selectedPckDist.label,
                PickupReqGrpId: pickupAddr.PickupReqGrpId,
                AddrType: pickupAddr.AddrType,
                Addr: pickupAddr.Addr,
                CustId: pickupAddr.CustId,
                CustName: pickupAddr.CustName,
                GrpType: pickupAddr.GrpType,
                Username: acc.Username,
                Mode: pickupAddr.PickupReqGrpId > 0 ? "EDIT" : "ADD"
            }
            dispatch(pickupActions.addEditPickupReqGrp(pickupAddrTypeObj));
        }
    };

    useEffect(() => {
        if (errPickupReqAddEditGrpObj && errPickupReqAddEditGrpObj.StatusCode !== "200") {
            setIsError(true);
            setAlert({ Type: "Error", StatusMessage: errPickupReqAddEditGrpObj.StatusMessage })
        }

    }, [errPickupReqAddEditGrpObj])


    useEffect(() => {
        if (submitted && pickupAddEditObj && pickupAddEditObj.PickupReqGrpId != 0) {
            setModalShow(true)
        }
    }, [pickupAddEditObj])

    function navigateToHome() {
        setModalShow(false);
        setSubmitted(false);
        clearData();
        history.push("/Home");
    }
    function navigateToCreatePickupAddr() {
        setModalShow(false);
        setSubmitted(false);
        clearData();
        history.push("/PickupAddressForm");
    }
    function navigateToPickupAddr() {
        setModalShow(false);
        setSubmitted(false);
        clearData();
        history.push("/PickupAddressPaging");
    }

    function clearData() {
        console.log('Masuk Clear');
        setSubmitted(false);
        setModalShow(false);
        dispatch(pickupActions.clearPickupAddrById());
        dispatch(pickupActions.clearAddEditPickupReqGrp());
        setSelectedPckDist({ value: 0, label: 'Pilih Kecamatan / Kabupaten' });
        let PhoneNo = acc.MobilePhoneNo;
        if (acc && PhoneNo.length > 1) {
            var phone = PhoneNo.substring(1, 20);
            var area = PhoneNo.substring(0, 1);
            if (area === "0") {
                console.log(phone);
                PhoneNo = "+62" + phone;
            }
            setPhoneNo(PhoneNo);
        }
        setPickupAddr({
            ContactPerson: acc && acc.CustName,
            PhoneNo: '',
            PickupReqGrpStat: 'ACT',
            DistrictId: 0,
            DistrictName: '',
            PickupReqGrpId: 0,
            AddrType: '',
            Addr: '',
            CustId: acc ? acc.CustId : 0,
            CustName: acc ? acc.CustName : '',
            GrpType: 'CUS'
        })
    }

    return (
        <>

            <div className="hidden sm:block max-w-6xl mx-auto">
                <div className="justify-center py-2 px-4 flex flex-row">
                    <h1 className="font-bold text-xl">Pickup Address</h1>
                </div>
                {
                    isError == true &&
                    <AlertMessage isError={isError} alert={alert} setAlert={setAlert} setIsError={setIsError} />
                }
                <form onSubmit={handleSubmit} method="POST">
                    <div className="h-full w-full text-xs sm:text-sm font-medium">
                        <div className="row px-2 py-1">
                            <div className="col-md-3 text-xs sm:text-sm">
                                Nama Alamat
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                <input id="AddrType" type="text" name="AddrType" className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300" placeholder="Isi Nama Alamat" value={pickupAddr.AddrType} onChange={handleChange} disabled={pickupAddr.PickupReqGrpId > 0 ? true : false} />

                                {
                                    submitted && !pickupAddr.AddrType &&
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Harap isi nama alamat
                                    </div>
                                }
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                Kecamatan / Kabupaten
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                <Select
                                    filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!   
                                    value={selectedPckDist}
                                    options={options}
                                    onChange={handleChangeDistrictPickup}
                                    className="rounded-sm text-xs sm:text-sm border-gray-300"
                                    styles={style}
                                />
                                {
                                    submitted && (!selectedPckDist || selectedPckDist.value <= 0) &&
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Harap isi kecamatan / kabupaten
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row px-2 py-1">
                            <div className="col-md-3 text-xs sm:text-sm">
                                Alamat
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                <textarea
                                    id="Addr"
                                    name="Addr"
                                    rows="3"
                                    className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                    style={{ boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.03)" }}
                                    value={pickupAddr.Addr}
                                    onChange={handleChange}
                                    placeholder="Isi alamat pickup"
                                />
                                {
                                    submitted && !pickupAddr.Addr &&
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Harap isi alamat pickup
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row px-2 py-1">
                            <div className="col-md-3 text-xs sm:text-sm">
                                Contact Person
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                <input id="ContactPerson" type="text" name="ContactPerson" className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300" placeholder="Isi Contact Person" value={pickupAddr.ContactPerson} onChange={handleChange} />

                                {
                                    submitted && !pickupAddr.ContactPerson &&
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Harap isi contact person
                                    </div>
                                }
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                Nomor Ponsel
                            </div>
                            <div className="col-md-3 text-xs sm:text-sm">
                                <Input
                                    className="py-1 px-2 text-xs sm:text-sm w-100 rounded-sm border-gray-300"
                                    country="ID"
                                    value={phoneNo}
                                    onChange={setPhoneNo}
                                    placeholder="Isi nomor ponsel"
                                />
                                {
                                    submitted && (!phoneNo || !isValidPhoneNumber) &&
                                    <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                        Harap isi nomor ponsel
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="flex justify-end mb-2 mx-2">
                            <button type="submit" className="relative w-40 flex justify-center items-center py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out">
                                Simpan
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="block sm:hidden w-full">
                <div className="bg-blue-600">
                    <h4 className="flex justify-center text-xl font-semibold text-white py-2">
                        Pickup Address
                    </h4>
                </div>
                <div className="px-2 py-2">
                    <form onSubmit={handleSubmit} method="POST">
                        <div className="h-full w-full bg-white flex items-center">
                            <div className="text-xs sm:text-sm font-medium w-full">
                                <div className="flex flex-col mx-2 py-1">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        Nama Alamat
                                    </div>
                                    <div className={`flex-1 border-b-2 ${submitted && !pickupAddr.AddrType ? 'border-red-500' : 'border-gray-300'} text-xs sm:text-sm py-1`}>
                                        <input id="AddrType" type="text" name="AddrType" className="py-1 text-xs sm:text-sm border-none outline-none w-100" placeholder="Isi nama alamat" value={pickupAddr.AddrType} onChange={handleChange} disabled={pickupAddr.PickupReqGrpId > 0 ? true : false} />
                                        {
                                            submitted && !pickupAddr.AddrType &&
                                            <div className="flex flex-1 text-xs sm:text-sm text-red-500 mx-2 py-1">
                                                Harap masukkan nama alamat
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="flex flex-col mx-2 py-1">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        Kecamatan / Kabupaten
                                    </div>
                                    <div className={`flex-1 border-b-2 ${submitted && (!selectedPckDist || selectedPckDist.value === 0) ? 'border-red-500' : 'border-gray-300'} text-xs sm:text-sm py-1`}>
                                        <Select
                                            filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!   
                                            value={selectedPckDist}
                                            options={options}
                                            onChange={handleChangeDistrictPickup}
                                            className="text-xs sm:text-sm"
                                            styles={styleMobile}
                                        />
                                    </div>
                                    {
                                        submitted && (!selectedPckDist || selectedPckDist.value <= 0) &&
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                            Harap masukkan kecamatan / kabupaten
                                        </div>
                                    }
                                </div>
                                <div className="flex flex-col mx-2 py-1">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        Alamat
                                    </div>
                                    <div className={`flex-1 border-b-2 ${submitted && !pickupAddr.Addr ? 'border-red-500' : 'border-gray-300'} text-xs sm:text-sm py-1`}>
                                        <input id="Addr" name="Addr" placeholder="Isi alamat" type="text" className="py-1 text-xs sm:text-sm border-none outline-none w-100" onChange={handleChange} value={pickupAddr.Addr} />
                                    </div>
                                    {
                                        submitted && !pickupAddr.Addr &&
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                            Harap masukkan alamat
                                        </div>
                                    }
                                </div>
                                <div className="flex flex-col mx-2 py-1">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        Contact Person
                                    </div>
                                    <div className={`flex-1 border-b-2 ${submitted && !pickupAddr.ContactPerson ? 'border-red-500' : 'border-gray-300'} text-xs sm:text-sm py-1`}>
                                        <input id="ContactPerson" type="text" name="ContactPerson" placeholder="Isi contact person" className="py-1 text-xs sm:text-sm border-none outline-none w-100" onChange={handleChange} value={pickupAddr.ContactPerson} />
                                    </div>
                                    {
                                        submitted && !pickupAddr.ContactPerson &&
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                            Harap masukkan contact person
                                        </div>
                                    }
                                </div>

                                <div className="flex flex-col mx-2 py-1">
                                    <div className="flex-1 text-xs sm:text-sm flex items-center">
                                        Nomor Ponsel
                                    </div>
                                    <div className={`flex-1 border-b-2 ${submitted && !phoneNo ? 'border-red-500' : 'border-gray-300'} text-xs sm:text-sm py-1`}>
                                        <Input id="PhnNo" placeholder="Isi nomor ponsel " className="py-1 text-xs sm:text-sm border-none outline-none w-100" country="ID" value={phoneNo} onChange={setPhoneNo} />
                                    </div>
                                    {
                                        submitted && !phoneNo &&
                                        <div className="flex flex-1 text-xs sm:text-sm text-red-500">
                                            Harap masukkan nomor ponsel contact person
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="flex flex-col justify-center">
                            <button type="submit" className="mx-2 my-2 py-2 px-4 border border-transparent font-medium rounded-full text-white bg-red-600">
                                Simpan
                            </button>
                        </div>
                    </form>
                </div>
                {
                    isError == true &&
                    <AlertMessage isError={isError} alert={alert} setAlert={setAlert} setIsError={setIsError} />
                }
            </div>

            <Modal size="lg" isOpen={modalShow}>
                <ModalHeader className="font-bold text-xs sm:text-sm items-center">Summary Pickup Address</ModalHeader>
                <ModalBody>
                    {pickupAddEditObj && selectedPckDist && modalShow && submitted &&
                        <>
                            <div className="hidden sm:block">
                                <div className="relative h-full w-full flex items-center">
                                    <div className="text-xs sm:text-sm font-medium w-full px-2 py-2">
                                        <div className="flex px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                {pickupAddr.PickupReqGrpId > 0 ?
                                                    `Ubah alamat pickup dengan nama alamat ${pickupAddr.AddrType} telah sukses` :
                                                    `Buat alamat pickup dengan nama alamat ${pickupAddr.AddrType} telah sukses`
                                                }
                                            </div>
                                        </div>
                                        <div className="flex px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex font-bold">
                                                SUMMARY
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Nama Alamat
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {pickupAddr.AddrType}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Kecamatan / Kabupaten
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {selectedPckDist.label}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Alamat
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {pickupAddr.Addr}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Contact Person
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {pickupAddr.ContactPerson}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Nomor Ponsel
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold">
                                                {phoneNo}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center">
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToPickupAddr()}
                                    >
                                        Lihat Alamat Pickup
                                    </button>
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToCreatePickupAddr()}
                                    >
                                        Buat Pickup Address Lain
                                    </button>
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToHome()}
                                    >
                                        Kembali ke Home
                                    </button>
                                </div>
                            </div>
                            <div className="block sm:hidden">
                                <div className="relative h-full w-full flex items-center">
                                    <div className="text-xs sm:text-sm font-medium w-full px-2 py-2">
                                        <div className="flex px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                {pickupAddr.PickupReqGrpId > 0 ?
                                                    `Ubah alamat pickup dengan nama alamat ${pickupAddr.AddrType} telah sukses` :
                                                    `Buat alamat pickup dengan nama alamat ${pickupAddr.AddrType} telah sukses`
                                                }
                                            </div>
                                        </div>
                                        <div className="flex px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex font-bold">
                                                SUMMARY
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Nama Alamat
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold text-right">
                                                {pickupAddr.AddrType}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Kecamatan / Kabupaten
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold text-right">
                                                {selectedPckDist.label}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Alamat
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold text-right">
                                                {pickupAddr.Addr}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Contact Person
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold text-right">
                                                {pickupAddr.ContactPerson}
                                            </div>
                                        </div>
                                        <div className="flex border-b-2 border-gray-300 px-2 py-1">
                                            <div className="flex-1 text-xs sm:text-sm flex">
                                                Nomor Ponsel
                                            </div>
                                            <div className="flex-1 text-xs sm:text-sm font-bold text-right">
                                                {phoneNo}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center">
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToPickupAddr()}
                                    >
                                        Lihat Alamat Pickup
                                    </button>
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToCreatePickupAddr()}
                                    >
                                        Buat Pickup Address Lain
                                    </button>
                                    <button
                                        type="button"
                                        className="group relative inline-flex justify-center items-center py-2 px-3 mx-1 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        onClick={(e) => navigateToHome()}
                                    >
                                        Kembali ke Home
                                    </button>
                                </div>
                            </div>
                        </>}
                </ModalBody>
            </Modal>
        </>
    );
}
export default PickupAddressForm;