import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaPen, FaTrash } from "react-icons/fa";

const TableDetailBarang = ({ arrTempBarang, setArrDetailBarangMbl, isDeletable, setDetailBarangMbl, setIsEditKoli, setArrTempBarang }) => {

    function handleRemoveSelectedRow(booking) {
        const newArrTempBarang = [];
        let idx = 1;
        arrTempBarang.map((atb) => {
            if (booking.CustBookDId != atb.CustBookDId) {
                let newTempBarang = {
                    ...atb,
                    CustBookDId: idx,
                    SeqNo: idx
                }
                newArrTempBarang.push(newTempBarang);
                idx++;
            }
        });
        setArrTempBarang(newArrTempBarang);
        setArrDetailBarangMbl(newArrTempBarang);
    };

    function handleEditSelectedRow(id) {
        let editObj = arrTempBarang.find(x => x.CustBookDId == id);

        console.log("editObj**", editObj);
        setDetailBarangMbl({
            CustBookDId: id,
            ItemName: editObj.ItemName,
            ItemWeight: editObj.ItemWeight,
            DimP: editObj.DimP,
            DimL: editObj.DimL,
            DimT: editObj.DimT,
            IsPacking: editObj.IsPacking,
            PackingKayu: editObj.PackingKayu,
            BubbleWrap: editObj.BubbleWrap,
            Wrapping: editObj.Wrapping
        });

        setIsEditKoli(true);
    }

    return (
        <div className='text-xs sm:text-sm'>
            {arrTempBarang &&
                arrTempBarang.length == 0 && (
                    <div className="justify-center flex flex-row">
                        <p>Tidak ada data</p>
                    </div>
                )}
            {arrTempBarang &&
                arrTempBarang.length > 0 && (
                    <>
                        <div className="hidden sm:block w-full">
                            <table className='table table-striped bg-white'>
                                <thead className="font-bold">
                                    <tr>
                                        <td>#Koli</td>
                                        <td>Dimensi Barang (P x L x T)</td>
                                        <td>Estimasi Berat (Kg)</td>
                                        <td>Estimasi Berat Final (Kg)</td>
                                        <td>Sumber Perhitungan</td>
                                        <td>Packing</td>
                                        <td>Status</td>
                                        {isDeletable === true &&
                                            <td></td>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {arrTempBarang && arrTempBarang.map((booking, index) => (
                                        <tr key={index}>
                                            <td>
                                                {new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 0
                                                }).format(booking.SeqNo)}
                                            </td>
                                            <td>
                                                {new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 0
                                                }).format(booking.DimP)} x {new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 0
                                                }).format(booking.DimL)} x {new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 0
                                                }).format(booking.DimT)} ({new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 2
                                                }).format(booking.VolWeight)} kg)
                                            </td>
                                            <td>
                                                {new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 2
                                                }).format(booking.ItemWeight)}
                                            </td>
                                            <td>
                                                {new Intl.NumberFormat("en", {
                                                    minimumFractionDigits: 2
                                                }).format(booking.FinalWeight)}
                                            </td>
                                            <td>
                                                {booking.TotalCalcSrc}
                                            </td>
                                            <td>
                                                {booking.IsPacking ? `Ya (${booking.PackingKayu ? "Kayu" : ""}${booking.PackingKayu && booking.BubbleWrap ? ", " : ""}${booking.BubbleWrap ? "Bubble Wrap" : ""}${(booking.PackingKayu || booking.BubbleWrap) && booking.Wrapping ? ", " : ""}${booking.Wrapping ? "Wrapping" : ""})` : "Tidak"}
                                            </td>
                                            <td>
                                                {booking.CustBookDStat}
                                            </td>
                                            {isDeletable === true ?
                                                <td>
                                                    <label onClick={(e) => handleEditSelectedRow(booking.CustBookDId)}>Edit &nbsp;</label>
                                                    <label onClick={(e) => handleRemoveSelectedRow(booking)}>| Remove</label>
                                                </td> :
                                                <td>
                                                    <Link target={"_blank"} to={{ pathname: `/booking-viewD?id=${booking.CustBookDId}` }} style={{ color: '#337ab7' }}>
                                                        Detail
                                                    </Link>
                                                </td>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="block sm:hidden w-full">
                            {
                                arrTempBarang && arrTempBarang.map((booking, index) =>
                                    <div className="h-full w-full bg-white shadow flex rounded items-center my-2"
                                        key={index}>
                                        <div className="relative h-full w-full flex items-center px-2 py-2">
                                            <div className="text-sm font-medium w-full mr-3">
                                                <div className="flex px-2 py-1">
                                                    <div className="flex-1  flex items-center font-bold">
                                                        #Koli {new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 0
                                                        }).format(booking.SeqNo)}
                                                    </div>
                                                    <div className="flex items-center">
                                                        <div className={`w-2.5 h-2.5 rounded-full ${booking.CustBookDStat === "ACT" ? 'bg-green-600' :
                                                            booking.CustBookDStat === "CAN" ? 'bg-red-600' : 'bg-blue-600'}`}></div>
                                                    </div>
                                                    <p className={`flex items-center font-semibold text-xs sm:text-sm ml-1 ${booking.CustBookDStat === "ACT" ? 'text-green-600' :
                                                        booking.CustBookDStat === "CAN" ? 'text-red-600' : 'text-blue-600'}`}>
                                                        {booking.CustBookDStat}</p>
                                                </div>
                                                <div className="flex px-2 py-1">
                                                    <div className="flex-1  flex items-center">
                                                        Nama Barang
                                                    </div>
                                                    <div className="flex-1 text-right  font-bold">
                                                        {booking.ItemName}
                                                    </div>
                                                </div>
                                                <div className="flex px-2 py-1">
                                                    <div className="flex-1  flex items-center">
                                                        Dimensi Barang (PxLxT)
                                                    </div>
                                                    <div className="flex-1 text-right  font-bold">
                                                        {new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 0
                                                        }).format(booking.DimP)} x {new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 0
                                                        }).format(booking.DimL)} x {new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 0
                                                        }).format(booking.DimT)} ({new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 2
                                                        }).format(booking.VolWeight)} kg)
                                                    </div>
                                                </div>
                                                <div className="flex px-2 py-1">
                                                    <div className="flex-1  flex items-center">
                                                        Est. Berat Barang
                                                    </div>
                                                    <div className="flex-1 text-right  font-bold">
                                                        {new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 2
                                                        }).format(booking.ItemWeight)} kg
                                                    </div>
                                                </div>
                                                <div className="flex px-2 py-1">
                                                    <div className="flex-1  flex items-center">
                                                        Final Berat Barang
                                                    </div>
                                                    <div className="flex-1 text-right  font-bold">
                                                        {new Intl.NumberFormat("en", {
                                                            minimumFractionDigits: 2
                                                        }).format(booking.FinalWeight)} kg ({booking.TotalCalcSrc})
                                                    </div>
                                                </div>
                                                <div className="flex px-2 py-1">
                                                    <div className="flex-1  flex items-top">
                                                        Packing
                                                    </div>
                                                    <div className="flex-1 text-right  font-bold">
                                                        {booking.IsPacking ? `Ya (${booking.PackingKayu ? "Kayu" : ""}${booking.PackingKayu && booking.BubbleWrap ? ", " : ""}${booking.BubbleWrap ? "Bubble Wrap" : ""}${(booking.PackingKayu || booking.BubbleWrap) && booking.Wrapping ? ", " : ""}${booking.Wrapping ? "Wrapping" : ""})` : "Tidak"}
                                                    </div>
                                                </div>
                                                <div className="flex flex-row justify-around">
                                                    <button
                                                        type="button"
                                                        className="w-100 group relative inline-flex justify-center items-center py-2 px-3 mx-1 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-green-600"
                                                        onClick={(e) => handleEditSelectedRow(booking.CustBookDId)}
                                                    >
                                                        <FaPen
                                                            style={{ display: "inline-block" }}
                                                        />
                                                        &nbsp;Edit
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="w-100 group relative inline-flex justify-center items-center py-2 px-3 mx-1 my-1 rounded-full border border-transparent text-xs sm:text-sm leading-5 font-medium rounded-md text-white bg-red-600"
                                                        onClick={(e) => handleRemoveSelectedRow(booking)}
                                                    >
                                                        <FaTrash
                                                            style={{ display: "inline-block" }}
                                                        />
                                                        &nbsp;Remove
                                                    </button>
                                                </div>
                                            </div>
                                            <span className={`absolute ${booking.CustBookDStat === "ACT" ? 'bg-green-600' :
                                                booking.CustBookDStat === "CAN" ? 'bg-red-600' : 'bg-blue-600'} right-0 h-full rounded-r w-5 h-full text-center item-center`}>
                                            </span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </>
                )}
        </div>
    );
}

export default TableDetailBarang;
