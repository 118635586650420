import { packagecontentConstants } from '../_constants';

let packageContentObj = JSON.parse(localStorage.getItem('packageContentObj'));;
const initialState = packageContentObj ? { packageContentObj: packageContentObj.packageContentObj } : {};

export function packagecontent(state = initialState, action) {
    switch (action.type) {
        case packagecontentConstants.GET_PACKAGE_CONTENT_REQUEST:
            return {
                packageContentObj: action.packageContentObj.packageContentObj
            };
        case packagecontentConstants.GET_PACKAGE_CONTENT_SUCCESS:
            return {
                packageContentObj: action.packageContentObj.packageContentObj
            };
        case packagecontentConstants.GET_PACKAGE_CONTENT_FAILURE:
            return {};
        case packagecontentConstants.GET_PACKAGE_CONTENT_CLEAR:
            return {};
        default:
            return state
    }
}
