import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { pickupActions } from "../_actions";
import moment from "moment";

const PickupOrderHInfo = ({ t }) => {
  const dispatch = useDispatch();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const pickupOrderHInfo = useSelector(
    (state) => state.pickupOrderHInfo.pickupOrderHInfo
  );

  useEffect(() => {
    dispatch(
      pickupActions.getPickupOrderHInfoById(query.get("pickupOrderHId"))
    );
  }, []);

  return (
    <>
      {pickupOrderHInfo && (
        <div>
          <div className="row">
            <label className="col-md-3 font-bold">Tanggal Pickup</label>
            <label className="col-md-3">
              {moment(pickupOrderHInfo.PickupDt).format("DD MMMM YY - HH:mm")}
            </label>
            <label className="col-md-3 font-bold">Diassign Oleh</label>
            <label className="col-md-3">{pickupOrderHInfo.AssignBy}</label>
          </div>
          <div className="row">
            <label className="col-md-3 font-bold">PIC Kantor</label>
            <label className="col-md-3">-</label>
            <label className="col-md-3 font-bold">Driver</label>
            <label className="col-md-3">{pickupOrderHInfo.Driver}</label>
          </div>
          <div className="row">
            <label className="col-md-3 font-bold">Alamat</label>
            <label className="col-md-9 whitespace-normal break-words">
              {pickupOrderHInfo.Addr}
            </label>
          </div>
          <div className="row">
            <label className="col-md-3 font-bold">Contact Person</label>
            <label className="col-md-3">{pickupOrderHInfo.ContactPerson}</label>
            <label className="col-md-3 font-bold">No Handphone</label>
            <label className="col-md-3">{pickupOrderHInfo.PhoneNo}</label>
          </div>
          <div className="row">
            <label className="col-md-3 font-bold">Catatan Order</label>
            <label className="col-md-3">{pickupOrderHInfo.OrderNotes}</label>
          </div>
        </div>
      )}
    </>
  );
};

export default PickupOrderHInfo;
