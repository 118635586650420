import { originConstants } from "../_constants";

let originObj = JSON.parse(localStorage.getItem("origin"));
const initialState = originObj
  ? { loggedIn: true, originObj: originObj.origin }
  : {};

export function origin(state = initialState, action) {
  switch (action.type) {
    case originConstants.GET_ORIGIN_REQUEST:
      return {};
    case originConstants.GET_ORIGIN_SUCCESS:
      return {
        origin: action.origin.origin,
      };
    case originConstants.GET_ORIGIN_FAILURE:
      return {};
    default:
      return state;
  }
}
